import {
  Button as BaseButton,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react'
import React from 'react'

export type ButtonProps = {
  primary?: boolean
  secondary?: boolean
} & ChakraButtonProps

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      primary,
      secondary,
      colorScheme: colorSchemeProp,
      variant: variantProp,
      ...restProps
    },
    ref,
  ) => {
    const colorScheme = primary
      ? 'primary'
      : secondary
        ? 'gray'
        : colorSchemeProp
    // Lift primary buttons by default unless `variant` prop is explicitly set.
    const variant =
      primary && variantProp === undefined ? 'lifted' : variantProp
    return (
      <BaseButton
        ref={ref}
        colorScheme={colorScheme}
        variant={variant}
        {...restProps}
      >
        {children}
      </BaseButton>
    )
  },
)

Button.displayName = 'Button'
export default Button
