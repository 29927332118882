import React from 'react'

import { useAtom } from 'jotai'
import { speechPhraseFieldsAtom } from '../../state/speechPhrases'

export interface UseOrphanedQuotesResult {
  hasOrphanedQuote: (phrase: string) => boolean
  hasPhraseWithOrphanedQuote: boolean
}

const toPhraseArray = (input: string | string[]): string[] =>
  typeof input === 'string' ? [input] : input

const hasOddNumberOfQuotes = (phrase: string): boolean => {
  const quotes = phrase.match(/"/g)
  return (quotes && quotes.length % 2 !== 0) ?? false
}

export function useOrphanedQuotes(): UseOrphanedQuotesResult {
  const [phrases] = useAtom(speechPhraseFieldsAtom)

  const wordsWithOrphanedQuotes = React.useCallback(
    (phraseOrPhrases: string | string[]): string[] => {
      const phrases = toPhraseArray(phraseOrPhrases)
      return phrases.filter((phrase) => hasOddNumberOfQuotes(phrase))
    },
    [],
  )

  const hasOrphanedQuote = React.useCallback(
    (phraseOrPhrases: string | string[]): boolean => {
      const phrases = Array.isArray(phraseOrPhrases)
        ? phraseOrPhrases
        : [phraseOrPhrases]
      return wordsWithOrphanedQuotes(phrases).length > 0
    },
    [wordsWithOrphanedQuotes],
  )

  const hasPhraseWithOrphanedQuote = phrases.some((phrase) =>
    hasOrphanedQuote(phrase.value),
  )

  return { hasOrphanedQuote, hasPhraseWithOrphanedQuote }
}
