import {
  Button,
  DurationInput,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import Player from 'components/SimpleAudioPlayer'
import clamp from 'lodash/clamp'
import React, { useState } from 'react'

import { useToggle } from 'react-use'
import { LibraryAnalyticsEvent, logEvent } from '../analytics'
import { CreateSnippetResultType, NewFolder, NewSnippet } from '../types'
import {
  PlaylistSelector,
  PlaylistSelectorContent,
  PlaylistSelectorResult,
} from './PlaylistSelector'

export interface CreateSnippetDialogOptions {
  conversationUid: string
  timestamp: number
  audioDuration: number
  initialTitle?: string
}

type CreateSnippetDialogProps = CreateSnippetDialogOptions &
  BaseModalComponentProps<CreateSnippetResultType>

const DEFAULT_LENGTH = 60

export const CreateSnippetDialog: React.FC<CreateSnippetDialogProps> = ({
  onClose,
  onSubmit,
  conversationUid,
  timestamp,
  audioDuration,
  initialTitle = '',
}) => {
  // Refs
  const formRef = React.useRef<HTMLFormElement>(null)
  // State
  const [title, setTitle] = React.useState(initialTitle)
  const [description, setDescription] = React.useState<string>('')
  const [sendNotifications, toggleSendNotifications] = useToggle(false)
  const [startTime, setStartTime] = React.useState(timestamp)
  const [endTime, setEndTime] = React.useState(
    clamp(timestamp + DEFAULT_LENGTH, audioDuration),
  )
  const [playlistState, setPlaylistState] =
    useState<PlaylistSelectorResult | null>(null)

  const handleSubmitChanges = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (formRef.current?.checkValidity() === false) {
      return
    }

    if (playlistState == null) {
      return
    }

    const folder: NewFolder | undefined = playlistState.isNew
      ? {
          name: playlistState.name,
          description: playlistState.description,
          permissions: playlistState.permissions ?? null,
        }
      : undefined

    const snippet: NewSnippet = {
      conversationUid,
      title: title,
      description,
      folderUid: playlistState.uid,
      from: startTime,
      to: endTime,
    }
    onSubmit?.({ snippet, folder, sendNotifications })
  }

  const handleSelectEntireConversation = (): void => {
    setStartTime(0)
    setEndTime(audioDuration)
  }

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay>
        <ModalContent
          onWheel={(e) => {
            e.stopPropagation()
          }}
          onTouchMove={(e) => {
            e.stopPropagation()
          }}
        >
          <form ref={formRef} onSubmit={handleSubmitChanges}>
            <ModalHeader>
              <Trans>Add to library</Trans>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={4}>
                <FormControl isRequired>
                  <PlaylistSelector
                    id="playlist"
                    isRequired
                    onChange={setPlaylistState}
                  >
                    {({ isCreateMode, setIsCreateMode }) => (
                      <>
                        <Flex>
                          <FormLabel htmlFor="playlist">
                            <Trans>Playlist</Trans>
                          </FormLabel>
                          <Button
                            color="brand.primary"
                            variant="link"
                            size="sm"
                            onClick={() => setIsCreateMode(!isCreateMode)}
                            mb={2}
                            ml="auto"
                          >
                            {isCreateMode ? (
                              <Trans>Select existing</Trans>
                            ) : (
                              <Trans>Create new</Trans>
                            )}
                          </Button>
                        </Flex>
                        <PlaylistSelectorContent />
                      </>
                    )}
                  </PlaylistSelector>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="snippetTitle">
                    <Trans>Title</Trans>
                  </FormLabel>
                  <Input
                    id="snippetTitle"
                    placeholder={t`Title`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setTitle(e.target.value)
                    }}
                    value={title}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="description">
                    <Trans>Description</Trans>
                  </FormLabel>
                  <Textarea
                    id="description"
                    placeholder={t`Description ...`}
                    onChange={(
                      e: React.ChangeEvent<HTMLTextAreaElement>,
                    ): void => setDescription(e.target.value)}
                    value={description}
                    height="20"
                    display="block"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    <Trans>Adjust audio snippet</Trans>
                  </FormLabel>
                  <Button
                    display="block"
                    color="brand.primary"
                    variant="link"
                    size="sm"
                    onClick={handleSelectEntireConversation}
                    mb="4"
                  >
                    <Trans>Select entire conversation</Trans>
                  </Button>
                  <Flex>
                    <Box marginRight="2">
                      <FormLabel fontWeight="normal" htmlFor="startTime">
                        <Trans>Start</Trans>
                      </FormLabel>
                      <DurationInput
                        id="startTime"
                        onChange={setStartTime}
                        value={startTime}
                        min={0}
                        max={endTime - 5}
                      />
                    </Box>
                    <Box>
                      <FormLabel fontWeight="normal" htmlFor="endTime">
                        <Trans>End</Trans>
                      </FormLabel>
                      <DurationInput
                        id="endTime"
                        onChange={setEndTime}
                        value={endTime}
                        min={startTime + 5}
                        max={audioDuration}
                      />
                    </Box>
                  </Flex>
                  <Box mt={4}>
                    <Player
                      audioDuration={audioDuration}
                      conversationUid={conversationUid}
                      start={startTime}
                      end={endTime}
                      onPlay={() =>
                        logEvent(
                          LibraryAnalyticsEvent.CreateSnippetDialog_PlayAudio,
                        )
                      }
                      onStop={() =>
                        logEvent(
                          LibraryAnalyticsEvent.CreateSnippetDialog_StopAudio,
                        )
                      }
                    />
                  </Box>
                </FormControl>
                <Divider my={0} />
                <FormControl>
                  <Checkbox
                    isChecked={sendNotifications}
                    onChange={() => toggleSendNotifications()}
                  >
                    <Trans>Notify all users in your organization</Trans>
                  </Checkbox>
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Stack direction="row" spacing={4} justify="flex-end">
                <Button secondary type="button" onClick={onClose}>
                  <Trans>Cancel</Trans>
                </Button>
                <Button primary type="submit">
                  <Trans>Add</Trans>
                </Button>
              </Stack>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
