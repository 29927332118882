import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

interface IntersectionTrackerOptions {
  onIntersect: () => void
  threshold?: number
}

export const useIntersectionTracking = ({
  onIntersect,
  threshold = 0.1,
}: IntersectionTrackerOptions): {
  ref: RefObject<HTMLDivElement>
  reset: () => void
} => {
  const ref = useRef<HTMLDivElement>(null)
  const [hasIntersected, setHasIntersected] = useState(false)

  const reset = useCallback(() => {
    setHasIntersected(false)
  }, [])

  useEffect(() => {
    if (!ref.current || hasIntersected) return

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onIntersect()

          setHasIntersected(true)
          observer.disconnect()
        }
      },
      { threshold },
    )

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [onIntersect, hasIntersected, threshold])

  return { ref, reset }
}
