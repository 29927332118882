import {
  Box,
  Flex,
  Skeleton,
  SkeletonCircle,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import React from 'react'

const HeaderSkeleton: React.FC = () => {
  return (
    <Flex justifyContent="space-between" width="100%">
      <Box flex={1}>
        <Box>
          <Skeleton borderRadius="md" h={6} w="30%" />
        </Box>
        <Wrap spacing={1} mt={2} maxW="70%">
          <WrapItem>
            <Skeleton borderRadius="md" h={5} w={14} />
          </WrapItem>
          <WrapItem>
            <Skeleton borderRadius="md" h={5} w={24} />
          </WrapItem>
          <WrapItem>
            <Skeleton borderRadius="md" h={5} w={10} />
          </WrapItem>
          <WrapItem>
            <Skeleton borderRadius="md" h={5} w={40} />
          </WrapItem>
          <WrapItem>
            <Skeleton borderRadius="md" h={5} w={20} />
          </WrapItem>
          <WrapItem>
            <Skeleton borderRadius="md" h={5} w={16} />
          </WrapItem>
        </Wrap>
      </Box>
      <Box ml={4} flex="0 0 auto">
        <Flex direction="row" alignItems="center" gap={2} mr={8}>
          <Box textAlign="right">
            <Flex align="center" justify="flex-end">
              <Skeleton borderRadius="md" h={5} w={20} />
            </Flex>
            <Flex align="center" justify="flex-end">
              <Skeleton borderRadius="md" h={4} w={40} />
            </Flex>
          </Box>
          <SkeletonCircle w={12} h={12} />
        </Flex>
      </Box>
    </Flex>
  )
}

export default HeaderSkeleton
