import { GrantedLevel } from '@capturi/core'
import { Box, Tooltip } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'

import { TagsTooltipLabel } from './TagsTooltipLabel'

export type Accumulator = {
  [key in GrantedLevel]: string[]
}

export const TagsTooltipPlural: React.FC<{
  children: React.ReactNode
  entries: Accumulator
}> = ({ entries, children }) => {
  return (
    <Tooltip
      placement="top"
      hasArrow
      label={
        <Box>
          <TagsTooltipLabel entries={entries.Edit} title={t`Edit`} />
          <TagsTooltipLabel entries={entries.View} title={t`See`} />
          <TagsTooltipLabel entries={entries.Block} title={t`Can't see`} />
        </Box>
      }
    >
      {children}
    </Tooltip>
  )
}
