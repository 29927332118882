import { OnChangeValue, Select, SelectOption } from '@capturi/ui-select'
import {
  Box,
  Center,
  Flex,
  HStack,
  Icon,
  Switch,
  Text,
  Tooltip,
  chakra,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { type FC, type ReactElement } from 'react'
import isEqual from 'react-fast-compare'
import { MdCloudUpload, MdFolder, MdInfoOutline, MdShare } from 'react-icons/md'

type MasterColumnProps = {
  initialOrgValue?: SelectOption[]
  orgOptions?: SelectOption[]
  presetOptions?: SelectOption[]
  selectedPreset: SelectOption | undefined
  handleSelectOrg?: (option: OnChangeValue<SelectOption, true>) => void
  handleSelectPreset?: (newValue: OnChangeValue<SelectOption, false>) => void
  setToggledMaster: (toggled: boolean) => void
  renderPreset: (preset: SelectOption | undefined) => string | undefined
  MasterFolderContent?: ReactElement
  toggledMaster: boolean
  orgTargets?: string[]
}

export const MasterColumn: FC<MasterColumnProps> = ({
  initialOrgValue,
  orgOptions,
  presetOptions,
  selectedPreset,

  toggledMaster,
  orgTargets,
  handleSelectOrg,
  handleSelectPreset,
  setToggledMaster,
  renderPreset,
  MasterFolderContent,
}) => {
  function showOrgWarning(): boolean | undefined {
    const currentOrgIds = initialOrgValue?.map((x) => x.value)
    const areEqual = isEqual(currentOrgIds, orgTargets)
    if (currentOrgIds && orgTargets) {
      const showWarning =
        !areEqual && currentOrgIds?.length < orgTargets?.length
      return showWarning
    }

    return undefined
  }

  const title = initialOrgValue ? t`Master tracker` : t`Master tracker folder`
  const description = initialOrgValue
    ? t`Use this tracker in other organisations. This tracker can only be edited in this organisation.`
    : t`Enable this folder to be shown along with its content in other organisations. This folder can only be edited in this organisation.`

  return (
    <Flex
      backgroundColor="gray.50"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="4px"
      direction="column"
      px={4}
      py={2}
    >
      <HStack alignItems="center" w="100%">
        <Center>
          <Text fontWeight="500">{title}</Text>
        </Center>
        <Center pos="relative">
          <Tooltip
            pos="relative"
            hasArrow
            label={t`Enabling this will make the tracker a Master tracker.`}
            placement="top"
          >
            <chakra.span display="inherit">
              <Icon as={MdInfoOutline} />
            </chakra.span>
          </Tooltip>
        </Center>
        <Center flex={1}>
          <Switch
            id="master-tracker-toggle"
            size="sm"
            ml="auto"
            onChange={(e) => setToggledMaster(e.target.checked)}
            isChecked={toggledMaster}
          />
        </Center>
      </HStack>
      <Text mt={2} color="gray.600">
        {description}
      </Text>
      {initialOrgValue && toggledMaster && (
        <>
          <Center justifyContent="flex-start" my={3}>
            <MdCloudUpload />
            <Text fontWeight="500" mx={2}>
              <Trans>Organisations</Trans>
            </Text>
            <Tooltip
              hasArrow
              label={t`Choose which organisations this tracker should be displayed in`}
              placement="top"
            >
              <chakra.span display="inherit">
                <Icon as={MdInfoOutline} />
              </chakra.span>
            </Tooltip>
          </Center>
          <Box mb={3}>
            <Select
              isMulti
              value={initialOrgValue}
              options={orgOptions}
              onChange={handleSelectOrg}
            />
          </Box>
          {orgTargets !== null && showOrgWarning() && (
            <Text color="warning" fontSize="xs">
              <Trans>
                *You have deselected some organisations where this tracker is
                present. This tracker will not be deleted in these organisations
                but lose any updates you make to this tracker going forward.
              </Trans>
            </Text>
          )}
        </>
      )}
      {toggledMaster && (
        <>
          <Center justifyContent="flex-start" my={3}>
            <MdShare />
            <Text fontWeight="500" mx={2}>
              <Trans>View rights in external organisations</Trans>
            </Text>
            <Tooltip
              hasArrow
              label={t`Who can see the tracker in the external organisations`}
              placement="top"
            >
              <chakra.span display="inherit">
                <Icon as={MdInfoOutline} />
              </chakra.span>
            </Tooltip>
          </Center>
          <Box mb={2}>
            <Select
              value={selectedPreset}
              onChange={handleSelectPreset}
              options={presetOptions}
            />
          </Box>
          <Text color="gray.600">
            <Trans>View: {renderPreset(selectedPreset)}</Trans>
          </Text>
        </>
      )}
      {initialOrgValue && toggledMaster && (
        <>
          <Center justifyContent="flex-start" mt={4} mb={2}>
            <MdFolder />
            <Text fontWeight="500" mx={2}>
              <Trans>Folder in external organisations</Trans>
            </Text>
            <Tooltip
              hasArrow
              label={t`Select a tracker folder in the menu to the left`}
              placement="top"
            >
              <chakra.span display="inherit">
                <Icon as={MdInfoOutline} />
              </chakra.span>
            </Tooltip>
          </Center>
          <Box maxWidth="320px">{MasterFolderContent}</Box>
        </>
      )}
    </Flex>
  )
}
