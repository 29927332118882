import { Flex, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { ReactElement } from 'react'

import Tip_da from './images/drawing-da.svg'
import Tip_en from './images/drawing-en.svg'
import Tip_se from './images/drawing-se.svg'

function languageImage(lang: string): string | ReactElement {
  switch (lang) {
    case 'da':
      return <Tip_da />
    case 'se':
      return <Tip_se />

    default:
      return <Tip_en />
  }
}

const TeamFilterRequiredMessage: React.FC = () => {
  const { i18n } = useLingui()

  return (
    <Flex justify="center" mt="10">
      <Text color="textMuted" textAlign="center" my={8} maxW={600}>
        <Text mb="2">
          <Trans>
            To see statistics, add your team as a filter. As a team leader, you
            only have access to see statistics for your team.
          </Trans>
        </Text>
        <Flex justify="center" mt="6">
          {languageImage(i18n.locale)}
        </Flex>
      </Text>
    </Flex>
  )
}

export default TeamFilterRequiredMessage
