import { TimeResolution } from '@capturi/core'
import { RequestOptions } from '@capturi/request'

import { baseQuery, controller } from '../constants'

const basePath = `${controller}/speaker-trackerhits`

export const trackerHitsInsightsAPI = {
  getSpeakerTrackerHits: (): RequestOptions => ({
    url: `${basePath}/both`,
    query: baseQuery,
    method: 'post',
  }),
  getSpeakerTrackerUserHits: (trackerUid: string): RequestOptions => ({
    url: `${basePath}/both/${trackerUid}/user-hits`,
    query: baseQuery,
    method: 'post',
  }),
  getSpeakerTrackerTeamHits: (trackerUid: string): RequestOptions => ({
    url: `${basePath}/both/${trackerUid}/team-hits`,
    query: baseQuery,
    method: 'post',
  }),
  getSpeakerTrackerWordHits: (trackerUid: string): RequestOptions => ({
    url: `${basePath}/both/${trackerUid}/word-hits`,
    query: baseQuery,
    method: 'post',
  }),
  getSpeakerTrackerHitsTimeSeries: (
    trackerUid: string,
    resolution: TimeResolution,
    offset: number,
  ): RequestOptions => ({
    url: `${basePath}/both/time-series`,
    query: {
      ...baseQuery,
      trackerUid,
      resolution,
      offset,
    },
    method: 'post',
  }),
  getWordHitsTimeSeries: (
    trackerUid: string,
    word: string,
    resolution: TimeResolution,
    offset: number,
  ): RequestOptions => ({
    url: `${basePath}/both/${trackerUid}/word-hits/${word}/time-series`,
    query: {
      ...baseQuery,
      resolution,
      offset,
    },
    method: 'post',
  }),
  getUserHitsTimeSeries: (
    trackerUid: string,
    userUid: string,
    resolution: TimeResolution,
    offset: number,
  ): RequestOptions => ({
    url: `${basePath}/both/${trackerUid}/user-hits/${userUid}/time-series`,
    query: {
      ...baseQuery,
      resolution,
      offset,
    },
    method: 'post',
  }),
  getTeamHitsTimeSeries: (
    trackerUid: string,
    teamUid: string,
    resolution: TimeResolution,
    offset: number,
  ): RequestOptions => ({
    url: `${basePath}/both/${trackerUid}/team-hits/${teamUid}/time-series`,
    query: {
      ...baseQuery,
      resolution,
      offset,
    },
    method: 'post',
  }),
}
