import { useState } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSet = <T>(initial: T[]) => {
  const [set, setSet] = useState<Set<T>>(new Set(initial))

  return {
    add: (el: T) =>
      setSet((set) => {
        set.add(el)
        return new Set(set)
      }),
    bulkAdd: (els: T[]) => {
      setSet((set) => {
        els.forEach((el) => {
          set.add(el)
        })
        return new Set(set)
      })
    },
    delete: (el: T) => {
      setSet((set) => {
        set.delete(el)
        return new Set(set)
      })
    },
    has: (el: T) => set.has(el),
    clear: () => setSet(new Set()),
    [Symbol.iterator]: () => set.values(),
    forEach: (
      callbackfn: (value: T, value2: T, set: Set<T>) => void,
      thisArg?: unknown,
    ) => set.forEach(callbackfn, thisArg),
    keys: () => set.keys(),
    values: () => set.values(),
    get size() {
      return set.size
    },
    toArray: () => Array.from(set),
  }
}
