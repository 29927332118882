import { TimeResolution } from '@capturi/core'
import { useTheme } from '@capturi/ui-theme'
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { getColor } from '@chakra-ui/theme-tools'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { addDays } from 'date-fns'
import React from 'react'

import { DateBucket } from '../types'
import { ColorLabel } from './ColorLabel'

const pctFormat: Intl.NumberFormatOptions = {
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
}

const LineLabel: React.FC<{
  stroke: string
  strokeWidth?: number
  strokeDasharray?: string
  width?: number
}> = ({ stroke, strokeWidth = 2, strokeDasharray, width = 40 }) => {
  const halfWidth = strokeWidth / 2
  return (
    <svg
      width={width}
      height={strokeWidth}
      viewBox={`0 0 ${width} ${strokeWidth}`}
      version="1.1"
    >
      <path
        fill="none"
        d={`M0,${halfWidth} L${width},${halfWidth}`}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
      />
    </svg>
  )
}

type TooltipProps = {
  // Injected props
  active?: boolean
  label?: Date
  payload?: {
    id: string
    name: string
    color: string
    dataKey: (d: DateBucket) => number
    payload: DateBucket
    stroke: string
    strokeDasharray: string | undefined
    strokeWidth: number
  }[]
  // Manual props
  resolution: TimeResolution
  secondaryType?: 'words' | 'users' | 'teams'
  formatValue?: (value: number, name: string, d: DateBucket) => string
  formatSecondaryValue?: (value: number, d: DateBucket) => string
}
// biome-ignore lint/suspicious/noExplicitAny: legacy
type TooltipLineType<T = any> = {
  name: string
  label: string
  color: string
  value: number
  data?: T
  payload: DateBucket
}

const formatDate = (date: Date, resolution: TimeResolution): string => {
  if (resolution === 'Hours') {
    return i18n.date(date, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  }
  if (resolution === 'Weeks') {
    const weekStartDate = i18n.date(date, {
      day: 'numeric',
      month: '2-digit',
    })
    const weekEndDate = i18n.date(addDays(date, 6), {
      day: 'numeric',
      month: '2-digit',
      year: 'numeric',
    })
    return `${weekStartDate} - ${weekEndDate}`
  }
  if (resolution === 'Months') {
    return i18n.date(date, {
      month: 'long',
      year: 'numeric',
    })
  }
  return i18n.date(date, {
    weekday: 'long',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })
}

export const TimeSeriesTooltipContent: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  return (
    <Box
      bg="white"
      border="1px"
      borderColor="gray.200"
      borderRadius="md"
      boxShadow="sm"
      p={4}
    >
      <VStack spacing={4} align="stretch">
        {children}
      </VStack>
    </Box>
  )
}

export const TimeSeriesTooltip: React.FC<TooltipProps> = ({
  active,
  label,
  payload,
  resolution,
  secondaryType = 'words',
  formatValue = (value) => i18n.number(value, pctFormat),
  formatSecondaryValue = (value) => i18n.number(value, pctFormat),
}) => {
  const theme = useTheme()

  const primaryLines = React.useMemo(() => {
    if (!active) return []
    return (payload ?? []).reduce<
      TooltipLineType<{
        total: number
        hits: number
      }>[]
    >((memo, p) => {
      if (p.id.startsWith('primary:')) {
        memo.push({
          name: p.name,
          label: p.name,
          color: p.payload[p.name].segmentColor,
          value: p.dataKey(p.payload),
          payload: p.payload,
        })
      }
      return memo
    }, [])
  }, [payload, active])

  const secondaryData = React.useMemo(() => {
    if (!active)
      return {
        rows: [],
        columns: [],
      }
    const groups = (payload ?? []).reduce<{
      [key: string]: {
        name: string
        lineDefinition: {
          stroke: string
          strokeWidth?: number
          strokeDasharray?: string
        }
        segments: TooltipLineType[]
      }
    }>((memo, p) => {
      const {
        id,
        name,
        color,
        dataKey,
        payload,
        stroke,
        strokeDasharray,
        strokeWidth,
      } = p
      if (id.startsWith('secondary:')) {
        return {
          // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
          ...memo,
          [name]: {
            ...(memo[name] ?? {}),
            name,
            lineDefinition: {
              stroke,
              strokeDasharray,
              strokeWidth,
            },
            segments: [
              ...(memo[name]?.segments ?? []),
              {
                name,
                label: name,
                color: color,
                value: dataKey(payload),
                payload,
              },
            ],
          },
        }
      }
      return memo
    }, {})

    const rows = Object.values(groups).sort(
      (a, b) => b.segments[0].value - a.segments[0].value,
    )
    const columns = (rows[0]?.segments ?? []).map((x) => ({ color: x.color }))
    return {
      rows,
      columns,
    }
  }, [payload, active])

  if (!active) {
    return null
  }
  if (!(label instanceof Date)) {
    // For some reason label can be the string "auto" initially
    return null
  }
  let secondaryTypeLabel: string
  if (secondaryType === 'teams') {
    secondaryTypeLabel = t`Teams`
  } else if (secondaryType === 'users') {
    secondaryTypeLabel = t`Users`
  } else {
    secondaryTypeLabel = t`Words`
  }

  return (
    <TimeSeriesTooltipContent>
      <Text fontWeight="medium">{formatDate(label, resolution)}</Text>
      <Box>
        {primaryLines.map((x) => (
          <Flex key={x.color} align="center">
            <ColorLabel color={x.color} mr={2} />
            <Box>{formatValue(x.value, x.name, x.payload)}</Box>
          </Flex>
        ))}
      </Box>
      {secondaryData.rows.length && (
        <Table>
          <Thead>
            <Tr>
              <Th
                p={1}
                textAlign="left"
                maxW="10em"
                noOfLines={1}
                wordBreak="break-all"
              >
                {secondaryTypeLabel}
              </Th>
              {secondaryData.columns.map((x) => (
                <Th p={1} textAlign="center" width="3.5em" key={x.color}>
                  <ColorLabel hex={x.color} />
                </Th>
              ))}
              <Th p={1}>
                <Trans>Line</Trans>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {secondaryData.rows.map((d) => (
              <Tr key={d.name}>
                <Td p={1} noOfLines={1} wordBreak="break-all">
                  {d.name}
                </Td>
                {d.segments.map((x) => (
                  <Td key={x.color} isNumeric py={1} px={4}>
                    {formatSecondaryValue(x.value, x.payload)}
                  </Td>
                ))}
                <Td p={1}>
                  <LineLabel
                    {...d.lineDefinition}
                    stroke={getColor(theme, 'gray.500')}
                    strokeWidth={2}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </TimeSeriesTooltipContent>
  )
}

type MultiChannelTooltipProps = TooltipProps & {
  segmentStates?: { label: string; color: string }[]
}

export const MultiChannelTimeSeriesTooltip: React.FC<
  MultiChannelTooltipProps
> = ({
  active,
  label,
  payload,
  resolution,
  segmentStates,
  secondaryType = 'words',
  formatValue = (value) => i18n.number(value, pctFormat),
  formatSecondaryValue = (value) => i18n.number(value, pctFormat),
}) => {
  const theme = useTheme()

  const primaryLines = React.useMemo(() => {
    if (!active) return []
    return (payload ?? []).reduce<
      TooltipLineType<{
        total: number
        hits: number
      }>[]
    >((memo, p) => {
      if (p.id.startsWith('primary:')) {
        memo.push({
          name: p.name,
          label: p.name,
          color: p.payload[p.name].segmentColor,
          value: p.dataKey(p.payload),
          payload: p.payload,
        })
      }
      return memo
    }, [])
  }, [payload, active])

  const secondaryData: {
    rows: {
      name: string
      lineDefinition: {
        stroke: string
        strokeDasharray?: string
        strokeWidth?: number
      }
    }[]
    segmentData: Record<string, Record<string, TooltipLineType>>
  } = React.useMemo(() => {
    if (!active) {
      return {
        rows: [],
        segmentData: {},
      }
    }

    const secondaryPayloads = (payload ?? []).filter((p) =>
      p.id.startsWith('secondary:'),
    )
    const rows = secondaryPayloads.reduce<
      {
        name: string
        lineDefinition: {
          stroke: string
          strokeDasharray?: string
          strokeWidth?: number
        }
      }[]
    >((memo, p) => {
      if (!memo.some((x) => x.name === p.name)) {
        memo.push({
          name: p.name,
          lineDefinition: {
            stroke: p.stroke,
            strokeDasharray: p.strokeDasharray,
            strokeWidth: p.strokeWidth,
          },
        })
      }
      return memo
    }, [])

    // { segment_key: { row_name: data } }
    // { A: { hej: { name: 'hej', value: 0.5, payload: {...} }
    const segmentData = secondaryPayloads.reduce<
      Record<string, Record<string, TooltipLineType>>
    >((memo, p) => {
      const segmentKey = p.id.split(':')[2]
      memo[segmentKey] = {
        ...memo[segmentKey],
        [p.name]: {
          name: p.name,
          label: p.name,
          color: p.color,
          value: p.dataKey(p.payload),
          payload: p.payload,
        },
      }
      return memo
    }, {})

    return {
      rows,
      segmentData,
    }
  }, [active, payload])

  if (!active) {
    return null
  }
  if (!(label instanceof Date)) {
    // For some reason label can be the string "auto" initially
    return null
  }
  let secondaryTypeLabel: string
  if (secondaryType === 'teams') {
    secondaryTypeLabel = t`Teams`
  } else if (secondaryType === 'users') {
    secondaryTypeLabel = t`Users`
  } else {
    secondaryTypeLabel = t`Words`
  }

  return (
    <TimeSeriesTooltipContent>
      <Text fontWeight="medium">{formatDate(label, resolution)}</Text>
      <Box>
        {primaryLines.map((x) => (
          <Flex key={x.color} align="center">
            <ColorLabel color={x.color} mr={2} />
            <Box>{formatValue(x.value, x.name, x.payload)}</Box>
          </Flex>
        ))}
      </Box>
      {secondaryData.rows?.length && (
        <Table>
          <Thead>
            <Tr>
              <Th
                p={1}
                textAlign="left"
                maxW="10em"
                noOfLines={1}
                wordBreak="break-all"
              >
                {secondaryTypeLabel}
              </Th>
              {segmentStates?.map((s) => (
                <Th p={1} textAlign="center" width="3.5em" key={s.color}>
                  <ColorLabel color={s.color} />
                </Th>
              ))}
              <Th p={1}>
                <Trans>Line</Trans>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {secondaryData.rows.map((row) => (
              <Tr key={row.name}>
                <Td p={1} noOfLines={1} wordBreak="break-all">
                  {row.name}
                </Td>
                {segmentStates?.map((s) => {
                  const segmentData = secondaryData.segmentData[s.label]
                  const rowData = segmentData?.[row.name]
                  if (rowData) {
                    return (
                      <Td key={rowData.color} isNumeric py={1} px={4}>
                        {formatSecondaryValue(rowData.value, rowData.payload)}
                      </Td>
                    )
                  }
                  return (
                    <Td key={s.label} isNumeric py={1} px={4}>
                      0%
                    </Td>
                  )
                })}
                <Td p={1}>
                  <LineLabel
                    {...row.lineDefinition}
                    stroke={getColor(theme, 'gray.500')}
                    strokeWidth={2}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </TimeSeriesTooltipContent>
  )
}
