import { Role, User } from '@capturi/core'
import { LibraryFolderPermissions } from '../types'

export function isPublic(
  permissions: LibraryFolderPermissions | null | undefined,
): permissions is null {
  return permissions == null
}

export function hasAccess(
  permissions: LibraryFolderPermissions | null,
  user: User,
): boolean {
  if (isPublic(permissions)) return true
  const hasRequiredRole = (permissions.roles ?? []).includes(
    user.role ?? Role.user,
  )
  const hasRequiredUserId = (permissions.users ?? []).includes(user.id)
  return hasRequiredRole || hasRequiredUserId
}
