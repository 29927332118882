import { i18n } from '@lingui/core'

export default function getPercentage(
  count: number,
  totalCount: number,
): string | null {
  const percentage = count / totalCount
  if (Number.isNaN(percentage)) return null

  const formattedPercent = i18n.number(percentage, {
    style: 'percent',
    minimumFractionDigits: 1,
  })
  return formattedPercent
}
