import { useAuthContext } from '@capturi/auth'
import { useCurrentUser } from '@capturi/core'
import { PageLoader } from '@capturi/ui-components'
import { Box } from '@chakra-ui/react'
import { useLanguage } from 'i18n'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import LoginPage from '../LoginPage'
import { orgIdStore } from '../state'
import AddOrganization from './AddOrganization'

const WithCurrentUser: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const currentUser = useCurrentUser({ suspense: true })
  const { setLanguage } = useLanguage()

  useEffect(() => {
    setLanguage(currentUser.language)
    orgIdStore.setState(() => currentUser.organizationId)
  }, [currentUser, setLanguage])
  return children as React.ReactElement
}

const AuthManager: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [searchParams] = useSearchParams()

  const { isAuthenticated, isAuthenticating } = useAuthContext()

  if (isAuthenticated && searchParams.get('invite')) {
    return <AddOrganization />
  }

  if (isAuthenticated)
    return (
      <React.Suspense fallback={<PageLoader />}>
        <WithCurrentUser>{children}</WithCurrentUser>
      </React.Suspense>
    )
  if (isAuthenticating) return <PageLoader />
  return (
    <Box pos="fixed" w="100vw" h="100vh">
      <LoginPage />
    </Box>
  )
}

export default AuthManager
