import { i18n } from '@lingui/core'
import React from 'react'
import { DayPicker, DayPickerProps } from 'react-day-picker'

const LocalizedDayPicker: React.FC<DayPickerProps> = (props) => {
  const formatWeekdayName = (date: Date): React.ReactNode => {
    return i18n.date(date, { weekday: 'short' })
  }
  const formatMonthCaption = (date: Date): React.ReactNode => {
    return i18n.date(date, { month: 'long', year: 'numeric' })
  }

  return (
    <DayPicker
      formatters={{
        formatWeekdayName,
        formatMonthCaption,
      }}
      {...props}
    />
  )
}

export default LocalizedDayPicker
