import request, { ResponseError } from '@capturi/request'
import { useQuery } from '@tanstack/react-query'

type UseKeyTopicsDigestProps = {
  keyTopicsSessionUid: string | undefined
  keyTopicClusterId: string | undefined
  keySubTopicClusterIds: string[]
}

export type KeyTopicsDigestTopDriversResponseModel = {
  digests:
    | {
        keySubTopicId: string
        digest: string | null
      }[]
    | null
}

export const useKeyTopicsDigest = ({
  keyTopicsSessionUid,
  keyTopicClusterId,
  keySubTopicClusterIds,
}: UseKeyTopicsDigestProps) =>
  useQuery<KeyTopicsDigestTopDriversResponseModel, ResponseError>({
    queryKey: [
      'keyTopics',
      'session',
      keyTopicsSessionUid,
      'digest',
      'cluster',
      keyTopicClusterId,
      { keySubTopicClusterIds },
    ],
    queryFn: async () =>
      await request.get<KeyTopicsDigestTopDriversResponseModel>(
        `keytopics/${keyTopicsSessionUid}/${keyTopicClusterId}/digest/topDrivers`,
        {
          query: {
            'api-version': '3.3',
            keySubTopicClusterIds,
          },
          retryOnNoContent: true,
        },
      ),
    enabled:
      keyTopicClusterId !== undefined &&
      keyTopicsSessionUid !== undefined &&
      keySubTopicClusterIds.length > 0,
    staleTime: 1000 * 60 * 60 * 24, // 1 day
    refetchOnWindowFocus: false,
  })
