import { useOrganization } from '@capturi/stores'
import { t } from '@lingui/macro'
import React from 'react'

import { scoreToString, speakerToString } from '@capturi/ui-components'
import { FilterValueLabel } from '../../components/FilterValueLabel'
import { SentimentState } from '../../types'

export const SentimentValueLabel: React.FC<{ value?: SentimentState }> = ({
  value,
}) => {
  const { organizationType } = useOrganization()
  const score = scoreToString(value?.score) ?? '?'
  const speaker = speakerToString(organizationType, value?.speaker)
  const strValue = score + (speaker ? ` (${speaker})` : '')
  return <FilterValueLabel name={t`Sentiment`} value={strValue} />
}
