import { Button } from '@capturi/ui-components'
import { ButtonGroup, FlexProps } from '@chakra-ui/react'
import { DEFAULT_LANGUAGE, useLanguage } from 'i18n'
import React from 'react'

export const LanguagesList: React.FC<FlexProps> = (props) => {
  const {
    language = DEFAULT_LANGUAGE,
    setLanguage,
    languageOptions,
  } = useLanguage()
  return (
    <ButtonGroup spacing={4} {...props}>
      {languageOptions.map((option) => (
        <Button
          variant="link"
          key={option.code}
          color="gray.800"
          onClick={() => setLanguage(option.code)}
          textDecoration={language === option.code ? 'underline' : 'none'}
        >
          {option.label}
        </Button>
      ))}
    </ButtonGroup>
  )
}
