import {
  Box,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
} from '@chakra-ui/react'
import React from 'react'
import { IconType } from 'react-icons'

import { FilterCriteriaButton } from '../PhoneFilter/components/PhoneSegmentBuilder'
import {
  TextFilterComponentProps,
  TextFilterPopoverComponentProps,
} from './Filters'
import { TextFilterItem } from './useTextFilter'

type TextFilterPopoverProps<T extends TextFilterItem> =
  TextFilterComponentProps<T> & {
    hasValue: boolean
    filterCriteriaButtonProps: Partial<
      React.ComponentProps<typeof FilterCriteriaButton>
    >
    PopoverComponent: React.ComponentType<TextFilterPopoverComponentProps<T>>
  }

export const FilterCriteriaButtonIcon: React.FC<{
  icon: IconType
}> = ({ icon }) => <Icon boxSize="20px !important" as={icon} />

export function TextFilterPopover<T extends TextFilterItem>({
  isOpen,
  item,
  hasValue,
  onChangeValue,
  onClose,
  onOpen,
  onRemove,
  filterCriteriaButtonProps,
  PopoverComponent,
}: TextFilterPopoverProps<T>): React.ReactElement {
  return (
    <Popover placement="bottom-start" isOpen={isOpen} onClose={onClose} isLazy>
      {({ isOpen, onClose }) => (
        <>
          <Box display="inline-block">
            <PopoverTrigger>
              <FilterCriteriaButton
                maxWidth={'20rem'}
                {...filterCriteriaButtonProps}
                size="sm"
                hasValue={hasValue}
                onReset={() => onRemove(item.uid)}
                onClick={() => onOpen(item.uid)}
                isActive={isOpen}
              />
            </PopoverTrigger>
          </Box>

          <PopoverContent w={400}>
            <React.Suspense
              fallback={<Spinner display="block" m="1rem auto" />}
            >
              <PopoverComponent
                item={item}
                onChangeValue={onChangeValue}
                onClose={onClose}
                onRemove={onRemove}
              />
            </React.Suspense>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
