import { trackersAPI } from '@capturi/api-trackers'
import request from '@capturi/request'
import constate from 'constate'
import uniq from 'lodash/uniq'
import React from 'react'
import { useMountedState } from 'react-use'

type WordStatusMap = {
  [word: string]: boolean
}

export type DictionaryWordStatusReturn = {
  wordStatus: WordStatusMap
  validateWords: (phraseOrPhrases: string | string[]) => void
  hasUnknownWords: (phraseOrPhrases: string | string[]) => boolean
  getUnknownWords: (phraseOrPhrases: string | string[]) => string[]
  isUnknownWord: (word: string) => boolean
}

const splitOnWhitespaceOrQuote = (str: string): string[] => str.split(/ |"/)
const toPhraseArray = (input: string | string[]): string[] =>
  typeof input === 'string' ? [input] : input

const checkWord = async (word: string): Promise<boolean | undefined> => {
  try {
    return await request<boolean>(trackersAPI.getIsWordInDictionary(word))
  } catch (_error) {
    //Doesn't matter
  }
}

export function useDictionaryWordStatus(): DictionaryWordStatusReturn {
  const isMounted = useMountedState()
  const [state, setState] = React.useState<WordStatusMap>({})

  const isUnknownWord = React.useCallback(
    (word: string): boolean => state[word] === false,
    [state],
  )

  const validateSingleWord = React.useCallback(
    async (word: string): Promise<void> => {
      const trimmedWord = word.trim()
      if (trimmedWord.length === 0) {
        return
      }
      // Only look up words we don't already know the dictionary status about
      if (state[trimmedWord] !== undefined) {
        return
      }
      const isKnownWord = await checkWord(trimmedWord)
      if (isKnownWord !== undefined && isMounted()) {
        setState((state) => ({
          ...state,
          [trimmedWord]: isKnownWord,
        }))
      }
    },
    [state, isMounted],
  )

  const validateWords = React.useCallback(
    async (phraseOrPhrases: string | string[]): Promise<void> => {
      const phrases = toPhraseArray(phraseOrPhrases)
      /**
       * Check all words to see if they exist in our dictionary.
       * TODO: instead of creating a http request for each and every word, we should instead
       * create an endpoint that accepts a list of words so we can do this validation in a single request.
       */
      uniq(phrases.flatMap(splitOnWhitespaceOrQuote)).forEach(
        await validateSingleWord,
      )
    },
    [validateSingleWord],
  )

  const getUnknownWords = React.useCallback(
    (phraseOrPhrases: string | string[]): string[] => {
      const phrases = toPhraseArray(phraseOrPhrases)
      return phrases.flatMap(splitOnWhitespaceOrQuote).filter(isUnknownWord)
    },
    [isUnknownWord],
  )

  const hasUnknownWords = React.useCallback(
    (phraseOrPhrases: string | string[]): boolean => {
      return getUnknownWords(phraseOrPhrases).length > 0
    },
    [getUnknownWords],
  )

  return {
    wordStatus: state,
    validateWords,
    getUnknownWords,
    hasUnknownWords,
    isUnknownWord,
  }
}

const [DictionaryWordProvider, useDictionaryWordContext] = constate(
  useDictionaryWordStatus,
)

export { DictionaryWordProvider, useDictionaryWordContext }
