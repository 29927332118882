import { IconButton, Tooltip } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { MdClose } from 'react-icons/md'
import { CellProps } from 'react-table'

import { PhraseState } from '../../../../types'

export function DeletePhraseCell({
  rows,
  row,
  removePhraseField,
}: CellProps<PhraseState, string>): React.ReactElement | null {
  if (rows.length <= 1) return null
  return (
    <Tooltip label={t`Delete phrase`} hasArrow placement="top">
      <IconButton
        variant="ghost"
        icon={<MdClose />}
        aria-label={t`Delete phrase`}
        onClick={() => removePhraseField(row.index)}
        fontSize="lg"
        size="xs"
        tabIndex={-1}
      />
    </Tooltip>
  )
}
