import * as React from 'react'

import { useAtom } from 'jotai'
import { createPhraseField } from './createFormModel'
import {
  selectDuplicateSpeechPhrasesAtom,
  speechPhraseFieldsAtom,
} from './state/speechPhrases'
import { selectDuplicateTextPhrasesAtom } from './state/textPhrases'
import { PhraseState } from './types'

interface UsePhraseFieldsReturn {
  phrases: PhraseState[]
  addPhraseFields: (...values: string[]) => void
  removePhraseField: (index: number) => void
  emptyPhrasesField: () => void
  removeLatestPhraseFieldsDuplicates: () => string[]
  updatePhraseField: (index: number, phraseState: Partial<PhraseState>) => void
  setPhraseFields: (phrases: PhraseState[]) => void
}

export function usePhraseFields(): UsePhraseFieldsReturn {
  const [phrases, setPhrases] = useAtom(speechPhraseFieldsAtom)
  /**
   * Add one or more phrases.
   * If no arguments are provided then a single empty phrase field will be added.
   * If arguments are added then arguments.length phrases will be added with the argument as value.
   * @param values phrases to added
   */
  const addPhraseFields = (...values: string[]): void => {
    setPhrases((state) => {
      const phraseValues = values.length === 0 ? [''] : values
      const newFields = phraseValues.map((value) =>
        createPhraseField({ value }),
      )
      return state.concat(newFields)
    })
  }

  const removePhraseField = (index: number): void => {
    setPhrases((state) => {
      const newArray = [...state]
      newArray.splice(index, 1)
      return newArray
    })
  }

  const emptyPhrasesField = (): void => {
    setPhrases([])
  }

  function removeLatestPhraseFieldsDuplicates(): string[] {
    const set = new Set()

    const newValuesWithoutDuplicates = phrases.filter((phraseState) => {
      if (set.has(phraseState.value)) return false
      set.add(phraseState.value)
      return true
    })

    setPhrases(newValuesWithoutDuplicates)

    return newValuesWithoutDuplicates.map((phrase) => phrase.value)
  }

  /**
   * Making a partial update to a phrase field
   * @param index index of row to update
   * @param phraseState partial state to update row with
   */
  const updatePhraseField = (
    index: number,
    phraseState: Partial<PhraseState>,
  ): void => {
    if (phraseState.value) {
      phraseState.value = phraseState.value.toLowerCase()
      phraseState.value = phraseState.value.trim()
    }
    if (phraseState.settings?.near?.phrases) {
      phraseState.settings.near.phrases = phraseState.settings.near.phrases.map(
        (phrase) => phrase.trim(),
      )
    }
    if (phraseState.settings?.notNear?.phrases) {
      phraseState.settings.notNear.phrases =
        phraseState.settings.notNear.phrases.map((phrase) => phrase.trim())
    }
    setPhrases((state) => {
      const newArray = [...state]
      newArray[index] = {
        ...newArray[index],
        ...phraseState,
        settings: {
          ...newArray[index].settings,
          ...(phraseState.settings ?? {}),
        },
      }
      return newArray
    })
  }

  /**
   * Override entire phrase field state
   * @param phrases phrases to set
   */
  const setPhraseFields = (phrases: PhraseState[]): void => {
    setPhrases(phrases)
  }

  return {
    phrases,
    addPhraseFields,
    removePhraseField,
    emptyPhrasesField,
    removeLatestPhraseFieldsDuplicates,
    updatePhraseField,
    setPhraseFields,
  }
}

interface UseDuplicatePhrasesReturn {
  duplicatePhrases: Set<string>
  isDuplicate: (phrase: string) => boolean
  hasDuplicates: boolean
}

function useDuplicatePhrases(
  duplicatePhrases: Set<string>,
): UseDuplicatePhrasesReturn {
  return React.useMemo(() => {
    return {
      duplicatePhrases,
      isDuplicate: (phrase: string) => duplicatePhrases.has(phrase),
      hasDuplicates: duplicatePhrases.size > 0,
    }
  }, [duplicatePhrases])
}

export function useDuplicateSpeechPhrases(): UseDuplicatePhrasesReturn {
  const [duplicatePhrases] = useAtom(selectDuplicateSpeechPhrasesAtom)
  return useDuplicatePhrases(duplicatePhrases)
}

export function useDuplicateTextPhrases(): UseDuplicatePhrasesReturn {
  const [duplicatePhrases] = useAtom(selectDuplicateTextPhrasesAtom)
  return useDuplicatePhrases(duplicatePhrases)
}
