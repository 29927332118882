import { useSingleUser } from '@capturi/stores'
import { Avatar, Box, Flex } from '@chakra-ui/react'
import React from 'react'

import UserAvatar from '../../UserAvatar'
import UserCoachingLink from '../../UserCoachingLink'

type Props = {
  uid?: string
}

const AvatarLabel = React.memo<Props>(function AvatarLabelMemo({ uid }) {
  const { profileImage, name } = useSingleUser(uid)

  if (uid === undefined) {
    return (
      <Flex align="center">
        <Avatar size="xs" mr={2} />
        <Box display="inline">N/A</Box>
      </Flex>
    )
  }
  return (
    <Flex align="center" minW={0}>
      <UserAvatar name={name} profileImage={profileImage} size="xs" mr={2} />
      <UserCoachingLink uid={uid} noOfLines={1} wordBreak="break-all">
        {name}
      </UserCoachingLink>
    </Flex>
  )
})

export default AvatarLabel
