import { Global, css } from '@emotion/react'

function EmptyStateIcon(): JSX.Element {
  return (
    <>
      <Global
        styles={css`
          #dashStroke {
            animation: dashStrokeAnimate 4000ms forwards ease-in-out;
            transform-origin: center;
          }
          @keyframes dashStrokeAnimate {
            0% {
              stroke-dashoffset: -170;
            }

            30% {
              stroke-dashoffset: -330;
            }

            60% {
              stroke-dashoffset: 35;
            }

            100% {
              stroke-dashoffset: -330;
            }
          }
          #dashStrokeGroup {
            animation: dashStrokeOpacityAnimate 4000ms forwards ease-in-out;
            transform-origin: center;
          }

          @keyframes dashStrokeOpacityAnimate {
            0% {
              opacity: 0;
            }

            5% {
              opacity: 0;
            }

            10% {
              opacity: 1;
            }

            80% {
              opacity: 1;
            }

            100% {
              opacity: 0;
            }
          }

          #frontLine {
            animation: frontLineAnimate 1000ms forwards ease-in-out;
            transform-origin: center;
            animation-delay: 3000ms;
          }

          @keyframes frontLineAnimate {
            0% {
              opacity: 0.1;
            }

            100% {
              opacity: 0.3;
            }
          }
        `}
      />
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 405 127">
        <path
          fill="url(#paint0_radial)"
          d="M109.401 52.314C97.011 50.25 88.938 71.73 72.746 73.997c-7.121.998-18.413 0-18.413 0V0h296.333v73.997h-14.971c-11.186 0-25.125 4.13-37.515 14.456-12.735 10.325-25.125 12.208-37.515 1.882-12.734-10.325-25.125-43.365-37.859-53.69-12.39-10.326-24.781 2.064-37.515 18.585-12.39 16.52-24.78 37.17-37.515 37.17-12.39 0-25.641-38.02-38.375-40.086z"
          opacity="0.3"
        />
        <path
          fill="url(#paint1_radial)"
          d="M109.401 52.314C97.011 50.25 88.938 71.73 72.746 73.997c-7.121.998-18.413 0-18.413 0V0h296.333v73.997h-14.971c-11.186 0-25.125 4.13-37.515 14.456-12.735 10.325-25.125 12.208-37.515 1.882-12.734-10.325-25.125-43.365-37.859-53.69-12.39-10.326-24.781 2.064-37.515 18.585-12.39 16.52-24.78 37.17-37.515 37.17-12.39 0-25.641-38.02-38.375-40.086z"
          opacity="0.3"
        />
        <path
          fill="url(#paint2_radial)"
          d="M109.401 52.142c-12.39-2.065-20.463 19.416-36.655 21.683-7.121.998-18.413 0-18.413 0v53.003h296.333V73.825h-14.971c-11.186 0-25.125 4.13-37.515 14.456-12.735 10.325-25.125 12.208-37.515 1.882-12.734-10.325-25.125-43.365-37.859-53.69-12.39-10.326-24.781 2.064-37.515 18.585-12.39 16.52-24.78 37.17-37.515 37.17-12.39 0-25.641-38.02-38.375-40.086z"
          opacity="0.3"
        />
        <path
          stroke="#9E9E9E"
          strokeWidth="1.377"
          d="M350.322 73.997s-9.757-.207-16.004 0c-13.848.46-21.981 8.312-35.278 3.786-12.805-4.359-11.874-20.786-27.706-20.786-24.298 0-27.534 28.3-55.412 28.3-18.827 0-23.852-24.762-36.201-31.838-12.692-7.075-25.041-7.075-37.733 3.538-12.349 10.613-25.232 19.415-44.29 20.786-11.029.794-17.324-3.877-28.05-3.877H54.334"
          opacity="0.3"
        />
        <path
          id="frontLine"
          stroke="#424242"
          strokeMiterlimit="2.437"
          strokeWidth="1.377"
          d="M350.666 73.825h-14.971c-11.186 0-25.125 4.13-37.515 14.456-12.735 10.325-25.125 12.208-37.515 1.882-12.734-10.325-25.125-43.365-37.859-53.69-12.39-10.326-24.781 2.064-37.515 18.585-12.39 16.52-24.78 37.17-37.515 37.17-12.39 0-25.641-38.02-38.375-40.086-12.39-2.065-20.463 19.416-36.655 21.683-7.121.998-18.413 0-18.413 0"
          opacity="0.1"
        />
        <g id="dashStrokeGroup" filter="url(#dropshadow)">
          <path
            id="dashStroke"
            stroke="#e0e0e0"
            strokeDasharray="100 400"
            strokeLinecap="round"
            strokeMiterlimit="2.437"
            strokeWidth="5"
            d="M350.666 73.825h-14.971c-11.186 0-25.125 4.13-37.515 14.456-12.735 10.325-25.125 12.208-37.515 1.882-12.734-10.325-25.125-43.365-37.859-53.69-12.39-10.326-24.781 2.064-37.515 18.585-12.39 16.52-24.78 37.17-37.515 37.17-12.39 0-25.641-38.02-38.375-40.086-12.39-2.065-20.463 19.416-36.655 21.683-7.121.998-18.413 0-18.413 0"
          />
        </g>
        <defs>
          <radialGradient
            id="paint0_radial"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="matrix(0 -41.8171 101.261 0 148.809 91.722)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C4C4C4" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="matrix(0 -38.5474 93.343 0 274.604 97.057)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C4C4C4" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint2_radial"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="matrix(0 54.0352 -164.734 0 202.5 32.697)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C4C4C4" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </radialGradient>
          <filter id="dropshadow" height="130%">
            <feGaussianBlur in="#2f96d0" stdDeviation="3" />
            <feOffset dy="2" result="offsetblur" />
            <feComponentTransfer>
              <feFuncA slope="0.2" type="linear" />
            </feComponentTransfer>
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
      </svg>
    </>
  )
}

export default EmptyStateIcon
