import isEmpty from 'lodash/isEmpty'
import { useCallback, useState } from 'react'

import { FilterDefinitions } from './types'
import { createIdIncrementer } from './utils'

type FilterId = number

export type FilterCriteria = {
  stateKey: string
  id: FilterId
  index: number
}

/**
 * Extract a list of all state keys with values.
 * Each entry is a list itself representing muliple values
 * Example:
 * ```
 * [
 *  ['duration'],
 *  ['labels'],
 *  ['trackers', 'trackers']
 * ]
 * ```
 * @param state
 */
function getFilterStateKeysWithValues<TValues extends object>(
  state: TValues,
  filterDefinitions: FilterDefinitions,
): string[][] {
  return Object.keys(state).reduce<string[][]>((memo, key) => {
    const value = state[key as keyof TValues]
    const filter = filterDefinitions.get(key)
    if (filter === undefined || filter.isAvailable?.() === false) {
      return memo
    }
    if (
      filter?.allowMultiple &&
      Array.isArray(value) &&
      (value as []).length > 0
    ) {
      memo.push(new Array((value as []).length).fill(key))
      return memo
    }
    if (!isEmpty(value) || typeof value === 'boolean') {
      memo.push([key])
      return memo
    }
    return memo
  }, [])
}

// Simple number id generator used to give each filter criteria an id when added
const Ids = createIdIncrementer()

/**
 * Generate a list of filter entries based on state
 * Used initially to populate `filterEntries` and when resetting filters.
 * @param state
 */
function initialiseFilterEntries<TValues extends object>(
  state: TValues,
  filterDefinitions: FilterDefinitions,
): FilterCriteria[] {
  const stateKeysWithValues = getFilterStateKeysWithValues(
    state,
    filterDefinitions,
  )
  return stateKeysWithValues.flatMap((arr) => {
    return arr.map<FilterCriteria>((stateKey, index) => {
      return {
        id: Ids.next(),
        index,
        stateKey,
      }
    })
  })
}

export function useFilterCriterias<TValues extends object>(
  state: TValues,
  filterDefinitions: FilterDefinitions,
): {
  filters: FilterCriteria[]
  addFilter: (stateKey: string) => FilterCriteria | undefined
  removeFilter: (id: FilterId) => void
  removeAllFilters: () => void
  initFilters: (state: Partial<TValues>) => void
} {
  const [filterCriterias, setFilterCriterias] = useState(() =>
    initialiseFilterEntries(state, filterDefinitions),
  )

  /**
   * Add a filter
   * @param stateKey
   * @returns new filter criteria with id or `undefined` if filter exists and filter definition does not allow multiple
   */
  const addFilter = useCallback(
    (stateKey: string): FilterCriteria | undefined => {
      const filterDefinition = filterDefinitions.get(stateKey)
      // Look up existing filter of type
      const existingFilter = filterCriterias.find(
        (x) => x.stateKey === stateKey,
      )
      if (existingFilter && !filterDefinition?.allowMultiple) {
        return undefined
      }

      // Create new filter criteria
      const newFilterCriteria = {
        id: Ids.next(),
        index: filterCriterias.filter((x) => x.stateKey === stateKey).length,
        stateKey,
      }

      setFilterCriterias(filterCriterias.concat(newFilterCriteria))
      return newFilterCriteria
    },
    [filterCriterias, filterDefinitions],
  )

  /**
   * Remove filter criteria with id
   * @param filterId
   */
  const removeFilter = useCallback((filterId: FilterId) => {
    setFilterCriterias((state) => {
      // Look up filter to remove
      const removedFilter = state.find((x) => x.id === filterId)
      if (!removedFilter) {
        return state
      }
      // Remove filter
      const newState = state.filter((x) => x.id !== filterId)
      // Reindex remaining entries of same type
      return newState.reduce<{
        index: number
        state: FilterCriteria[]
      }>(
        (memo, filter) => {
          if (filter.stateKey !== removedFilter.stateKey) {
            memo.state.push(filter)
            return memo
          }
          memo.state.push({
            ...filter,
            index: memo.index,
          })
          memo.index++
          return memo
        },
        {
          index: 0,
          state: [],
        },
      ).state
    })
  }, [])

  const removeAllFilters = useCallback(() => {
    setFilterCriterias([])
  }, [])

  const initFilters = useCallback(
    (state: Partial<TValues>) => {
      setFilterCriterias(initialiseFilterEntries(state, filterDefinitions))
    },
    [filterDefinitions],
  )

  return {
    filters: filterCriterias,
    addFilter,
    removeFilter,
    removeAllFilters,
    initFilters,
  }
}
