import { AdversusStatus } from '@capturi/api-conversations'
import { t } from '@lingui/macro'
import React from 'react'

import { FilterValueLabel } from '../../components/FilterValueLabel'
import translateAdversusStatus from './adversusStatus'

export const StatusValueLabel: React.FC<{ value?: AdversusStatus }> = ({
  value,
}) => {
  return (
    <FilterValueLabel name={t`Status`} value={translateAdversusStatus(value)} />
  )
}
