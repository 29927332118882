import { useTrackerFolders } from '@capturi/api-trackers'
import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { Button, FormLabel } from '@capturi/ui-components'
import {
  MenuProps,
  OnChangeValue,
  PopoutSelect,
  SelectOption,
} from '@capturi/ui-select'
import { useModal } from '@capturi/use-modal'
import {
  Flex,
  FormControl,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  chakra,
  useDisclosure,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { CreateUpdateTrackerFolderDialog } from 'pages/analytics/trackers/components/TrackerFolderForm'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { MdFolder, MdInfoOutline } from 'react-icons/md'

import { Event, logEvent } from '../../../utils/events'
import { TrackerFormModel } from '../../types'
import renderIcons from '../RenderIcons'
import { CreateFolderMenu } from './CreateFolderMenu'

const NoOptionsMessage: React.FC = () => {
  return (
    <Flex direction="column" p={4}>
      <Text>
        <Trans>No folders have been created yet.</Trans>
      </Text>
    </Flex>
  )
}

type FolderFieldProps = {
  isMasterTrackerToggled: boolean
}

export const FolderField: React.FC<FolderFieldProps> = ({
  isMasterTrackerToggled,
}) => {
  const currentUser = useCurrentUser()
  const { enableMasterTracker } = useFeatureFlags()

  const { data: folders } = useTrackerFolders('Edit')

  const { isOpen, onToggle, onClose } = useDisclosure()
  const { setValue, getValues } = useFormContext<TrackerFormModel>()

  const options = React.useMemo(() => {
    return (folders ?? []).map((f) => ({
      value: f.uid,
      label: f.title,
    }))
  }, [folders])

  const folderUid = getValues().folderUid
  const selectedFolder = folders?.find(
    (folder) => folderUid && folder.uid === folderUid,
  )
  const changeFolder = React.useCallback(
    (folderUid: string | null) => {
      setValue('folderUid', folderUid)

      if (folderUid === null) {
        if (getValues().permissionPreset === 'Inherit') {
          setValue('permissionPresetUserUid', currentUser.id)
          setValue('permissionPreset', 'Private')
          setValue('acl', null)
        }
      } else {
        setValue('permissionPresetUserUid', null)
        setValue('permissionPreset', 'Inherit')
        setValue('acl', null)
      }
    },
    [currentUser.id, getValues, setValue],
  )

  const handleClear = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      changeFolder(null)
      logEvent(Event.TrackerFolderCleared)
    },
    [changeFolder],
  )

  const [openCreateTrackerFolderDialog] = useModal(
    CreateUpdateTrackerFolderDialog,
  )

  const handleCreateFolder = React.useCallback(async () => {
    openCreateTrackerFolderDialog({
      onSubmit: ({ folder }) => {
        if (folder?.accessLevel === 'Edit') {
          changeFolder(folder.uid)
          logEvent(Event.TrackerFolderCreated, {
            permissionPreset: folder.permissionPreset,
          })
        }
        onClose()
      },
      enableMasterTracker: enableMasterTracker,
    })
  }, [
    changeFolder,
    onClose,
    openCreateTrackerFolderDialog,
    enableMasterTracker,
  ])

  const handleFolderSelection = React.useCallback(
    (option: OnChangeValue<SelectOption, false>) => {
      changeFolder(option?.value ?? null)
      onClose()
      logEvent(Event.TrackerFolderSelected)
    },
    [changeFolder, onClose],
  )

  const Menu = React.useCallback(
    (props: MenuProps<SelectOption, boolean>) => (
      <CreateFolderMenu {...props} onCreateFolder={handleCreateFolder} />
    ),
    [handleCreateFolder],
  )

  return (
    <FormControl>
      <FormLabel htmlFor="tracker_folder" leftIcon={<MdFolder />}>
        <chakra.span>
          <Trans>Folder</Trans>
          <Tooltip
            hasArrow
            label={t`Add tracker to a folder, thus making it easier to find a specific tracker when having many.`}
          >
            <chakra.span ml={1}>
              <Icon as={MdInfoOutline} />
            </chakra.span>
          </Tooltip>
        </chakra.span>
      </FormLabel>
      <Controller
        name="folderUid"
        render={({ field }) => {
          const selectedFolder = folders?.find(
            (folder) => folder.uid === field.value,
          )

          return (
            <Popover placement="bottom-start" isOpen={isOpen} onClose={onClose}>
              <PopoverTrigger>
                <Button
                  maxW="350px"
                  alignItems="center"
                  textOverflow="ellipsis"
                  iconSpacing={0}
                  justifyContent="space-between"
                  onClick={onToggle}
                  px="8px"
                  rightIcon={renderIcons(selectedFolder, handleClear)}
                  variant="form-select-field"
                  minW="100px"
                  w="100%"
                >
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    overflow="hidden"
                  >
                    {selectedFolder?.title ? (
                      <>{selectedFolder?.title}</>
                    ) : (
                      <Text className="placeholder">
                        <Trans>Add to folder</Trans>
                      </Text>
                    )}
                  </Flex>
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <React.Suspense>
                  <PopoutSelect
                    id="tracker_folder"
                    components={{
                      NoOptionsMessage,
                      Menu: Menu,
                    }}
                    isMulti={false}
                    isSearchable={false}
                    hideSelectedOptions={false}
                    maxMenuHeight={328}
                    onChange={handleFolderSelection}
                    options={options}
                    placeholder={t`Add to folder`}
                    value={{
                      value: field.value ?? '',
                      label: selectedFolder?.title ?? field.value ?? '',
                    }}
                  />
                </React.Suspense>
              </PopoverContent>
            </Popover>
          )
        }}
      />
      {!selectedFolder?.masterSettings &&
        folderUid &&
        isMasterTrackerToggled && (
          <Text color="danger" mt={2}>
            <Trans>Chosen folder is not a master folder</Trans>
          </Text>
        )}
    </FormControl>
  )
}
