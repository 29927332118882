import { TimeFilterKind } from '@capturi/api-trackers'
import { Speaker } from '@capturi/core'
import { t } from '@lingui/macro'
import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  uid: yup.string(),
  name: yup.string().required(() => t`Please add a name`),
  // words: yup
  //   .array()
  //   .of(
  //     yup.object().shape({
  //       value: yup.string(),
  //     }),
  //   )
  //   .test(
  //     'atleast-1-phrase',
  //     () => t`Please add at leasts one phrase`,
  //     (value) => {
  //       if (value == null) return false
  //       // At least one of the word fields should have a value
  //       return value.some((x) => x.value && x.value.length > 0)
  //     },
  //   ),

  tags: yup.array().of(yup.string().required()),
  speech: yup.object().shape({
    timeFilter: yup.object().shape({
      kind: yup.mixed<TimeFilterKind>().oneOf(Object.values(TimeFilterKind)),
    }),
    speakerId: yup
      .mixed<Speaker>()
      .oneOf(Object.values(Speaker) as number[])
      .required(),
  }),

  // .required(),
  // seconds: yup.number().test(
  //   'gt-0-when-not-entire-conversation',
  //   () => t`Must be greater than 0`,
  //   (value, context) => {
  //     if (
  //       (context.parent as BaseTracker['timeFilter']).kind ===
  //       TimeFilterKind.ENTIRECONVERSATION
  //     ) {
  //       return true
  //     }
  //     return value !== undefined && value > 0
  //   },
  // ),
  // }),
})
