import { theme } from '@capturi/ui-theme'
import { Box, Flex, useToken } from '@chakra-ui/react'
import clamp from 'lodash/clamp'
import React, { useState } from 'react'

import { DecrementButton, IncrementButton } from './IncDecButton'
import Input, { InputProps } from './Input'

const MAX_VALUE_MINUTES = 99
const MAX_VALUE_SECONDS = MAX_VALUE_MINUTES * 60 + 59 // 99:59

type DurationInputProps = {
  value?: number | undefined | null
  onChange?: (value: number) => void
  min?: number
  max?: number
  id?: string
  isDisabled?: boolean
  size?: InputProps['size']
  step?: number
}

const DurationInput = React.forwardRef<HTMLInputElement, DurationInputProps>(
  function DurationInputWithRef(
    {
      value,
      onChange,
      min = 0,
      max = MAX_VALUE_SECONDS,
      id,
      isDisabled,
      size = 'md',
      step = 5,
    },
    ref,
  ) {
    // Extract minutes and seconds from value (seconds)
    const minutesValue = value != null ? Math.floor(value / 60) : undefined
    const secondsValue = value != null ? Math.floor(value) % 60 : undefined

    const setValue = (duration: { m?: number; s?: number }): void => {
      const { m = minutesValue, s = secondsValue } = duration
      const minutesInSeconds = m === undefined ? 0 : m * 60
      const totalSeconds = minutesInSeconds + (s ?? 0)
      onChange?.(clamp(totalSeconds, 0, MAX_VALUE_SECONDS))
    }

    const [minutesInputHasFocus, setMinutesInputHasFocus] = useState(false)
    const [secondsInputHasFocus, setSecondsInputHasFocus] = useState(false)
    const isFocused = minutesInputHasFocus || secondsInputHasFocus

    const [primary500] = useToken('colors', ['primary.500'])

    // onBlur: clamp and constrain value. This makes free text input behave nicer
    const onBlurClampValue = (): void => {
      if (value !== undefined && value !== null) {
        onChange?.(clamp(value, Math.max(0, min), Math.max(0, max)))
      }
    }

    // When clicking increment button.
    const increment = (amount = step): void => {
      onChange?.(
        clamp((value ?? 0) + amount, Math.max(0, min), Math.max(0, max)),
      )
    }

    // When clicking decrement button.
    const decrement = (amount = step): void => {
      onChange?.(
        clamp((value ?? 0) - amount, Math.max(0, min), Math.max(0, max)),
      )
    }

    const onMinutesChange = (val: number): void => {
      setValue({ m: val })
    }

    const onSecondsChange = (val: number): void => {
      if (val >= 0 && val < 60) {
        setValue({ s: val })
        return
      }
      if (val >= 60) {
        // seconds overflow
        setValue({
          m: (minutesValue ?? 0) + 1,
          s: val % 60,
        })
        return
      }
      if (val < 0) {
        if (minutesValue === undefined || minutesValue === 0) {
          setValue({
            s: 0,
          })
          return
        }
        if (minutesValue > 0) {
          // seconds underflow
          setValue({
            m: minutesValue - 1,
            s: 60 + val,
          })
          return
        }
      }
    }

    return (
      <Box
        display="inline-flex"
        alignItems="center"
        height={theme.components.Input.sizes[size].field.h}
        borderRadius={theme.components.Input.sizes[size].field.borderRadius}
        overflow="hidden"
        border="1px"
        borderColor={isFocused ? primary500 : 'inherit'}
        zIndex={isFocused ? 1 : 0}
        boxShadow={isFocused ? `0 0 0 1px ${primary500}` : 'none'}
        position="relative"
        pr={6}
      >
        <Input
          value={minutesValue}
          step={1}
          onChange={onMinutesChange}
          onFocus={() => setMinutesInputHasFocus(true)}
          onBlur={() => {
            onBlurClampValue()
            setMinutesInputHasFocus(false)
          }}
          textAlign="right"
          id={id}
          disabled={isDisabled}
          size={size}
        />
        <Box
          alignSelf="center"
          fontWeight="medium"
          color={value != null ? 'inherit' : 'gray.400'}
        >
          :
        </Box>
        <Input
          ref={ref}
          value={secondsValue}
          step={5}
          onChange={onSecondsChange}
          onFocus={() => setSecondsInputHasFocus(true)}
          onBlur={() => {
            onBlurClampValue()
            setSecondsInputHasFocus(false)
          }}
          textAlign="left"
          disabled={isDisabled}
          size={size}
        />
        <Flex
          direction="column"
          position="absolute"
          top={0}
          right={0}
          height="100%"
          zIndex={1}
          width={6}
        >
          <IncrementButton
            aria-label="increment"
            onClick={() => increment()}
            isDisabled={isDisabled}
            size={size}
          />
          <DecrementButton
            aria-label="decrement"
            onClick={() => decrement()}
            isDisabled={isDisabled}
            borderTop="1px"
            borderTopColor="inherit"
            size={size}
          />
        </Flex>
      </Box>
    )
  },
)

export default DurationInput
