import { Score } from '@capturi/api-scoring'
import { t } from '@lingui/macro'

export const isWithinGoalRange = (
  value: number,
  goal?: Score['goal'],
): boolean => {
  const { min, max } = goal ?? {}
  if (min != null && max != null) {
    return min <= value && value <= max
  }
  if (min != null) {
    return min <= value
  }
  if (max != null) {
    return value <= max
  }
  return false
}

export const formatGoalRange = (
  min: number | undefined | null,
  max: number | undefined | null,
): string => {
  if (min != null && min === max) {
    return String(min)
  }
  if (min != null && max != null) {
    return t`Between ${min} and ${max}`
  }
  if (min != null) {
    return t`Minimum ${min}`
  }
  if (max != null) {
    return t`Maximum ${max}`
  }
  return ''
}
