import { Box, Checkbox, Icon, IconButton } from '@chakra-ui/react'
import { css } from '@emotion/react'
import React, { useState } from 'react'
import { MdClose, MdSearch } from 'react-icons/md'
import {
  ActionMeta,
  GroupBase,
  InputActionMeta,
  OnChangeValue,
  OptionProps,
  Props as ReactSelectProps,
  SelectComponentsConfig,
  SelectInstance,
  components,
} from 'react-select'

import { MultiSelectFooter } from './MultiSelectFooter'
import { Select } from './Select'

const DropdownIndicator = (): React.ReactElement => (
  <Box as={MdSearch} boxSize="20px" mx={2} />
)

type PopoutSelectOptions<Option> = {
  submitText?: string
  resetText?: string
  selectAllText?: string
  onSubmit?: (options: readonly Option[]) => void
  onReset?: () => void
  isSubmitDisabled?: boolean
  formatSelectedCount?: (count: number) => React.ReactElement
  showFooter?: boolean
}

const getSelectedCount = (value: unknown): number => {
  if (value == null) return 0
  if (Array.isArray(value)) return value.length
  return 1
}

export function CheckboxOption(
  props: React.PropsWithChildren<{ isSelected: boolean }>,
): React.ReactElement {
  const { isSelected, children } = props
  return (
    <Checkbox
      width="100%"
      pointerEvents="none"
      userSelect="none"
      isChecked={isSelected}
      css={css`
        & > .chakra-checkbox__label {
          whitespace: nowrap;
          overflow: hidden;
          flex-grow: 1;
        }
      `}
    >
      <Box textOverflow="ellipsis" overflow="hidden">
        {children}
      </Box>
    </Checkbox>
  )
}

function DefaultCheckBoxOptions<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: OptionProps<Option, IsMulti, Group>): React.ReactElement {
  return (
    <components.Option {...props}>
      <CheckboxOption isSelected={props.isSelected}>
        {props.label}
      </CheckboxOption>
    </components.Option>
  )
}

export function PopoutSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  components,
  isMulti,
  value,
  onChange,
  submitText,
  resetText,
  isSubmitDisabled = false,
  selectAllText,
  onSubmit,
  onReset,
  formatSelectedCount,
  options,
  noOptionsMessage,
  isSearchable = true,
  showFooter = isMulti,
  ...props
}: ReactSelectProps<Option, IsMulti, Group> &
  PopoutSelectOptions<Option>): React.ReactElement {
  const [selectedCount, setSelectedCount] = useState(() =>
    getSelectedCount(value),
  )
  const selectRef = React.createRef<SelectInstance<Option, IsMulti, Group>>()
  const [inputValue, setInputValue] = useState('')

  const handleSubmit = (): void => {
    if (selectRef.current == null) return
    const value = selectRef.current.getCommonProps().getValue()
    onSubmit?.(value)
  }

  const selectAllOptions = (): void => {
    if (Array.isArray(options) && 'options' in options[1]) {
      selectRef.current?.setValue(options[1].options, 'select-option')
    }
  }

  const handleReset = (): void => {
    selectRef.current?.clearValue()
    onReset?.()
  }

  const handleOnChange = (
    newValue: OnChangeValue<Option, IsMulti>,
    action: ActionMeta<Option>,
  ): void => {
    setSelectedCount(getSelectedCount(newValue))
    onChange?.(newValue, action)
  }

  const baseSelectComponents = {
    DropdownIndicator,
    IndicatorSeparator: null,
    IndicatorsContainer: () => {
      if (inputValue !== '') {
        return (
          <IconButton
            aria-label="clear search"
            size="xs"
            variant="ghost"
            icon={<Icon as={MdClose} boxSize={4} />}
            mx={1}
            onClick={() => setInputValue('')}
          />
        )
      }
      return null
    },
  }

  const selectComponents: SelectComponentsConfig<Option, IsMulti, Group> = {
    ...baseSelectComponents,
    ...(!isSearchable ? { Control: () => null } : {}),
    ...(isMulti ? { Option: DefaultCheckBoxOptions } : {}),
    ...(components ?? {}),
  }

  return (
    <>
      <Select
        mRef={selectRef}
        backspaceRemovesValue={false}
        components={selectComponents}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        defaultMenuIsOpen={false}
        menuIsOpen
        options={options}
        styles={{
          control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
          menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
        }}
        noOptionsMessage={noOptionsMessage}
        tabSelectsValue={false}
        menuShouldScrollIntoView={false}
        maxMenuHeight={400}
        isSearchable={isSearchable}
        isMulti={isMulti}
        onChange={handleOnChange}
        value={value}
        inputValue={inputValue}
        onInputChange={(newValue: string, { action }: InputActionMeta) => {
          if (action === 'input-change') setInputValue(newValue)
        }}
        {...props}
      />
      {showFooter && (
        <MultiSelectFooter
          onReset={handleReset}
          onSubmit={handleSubmit}
          onSelectAllOptions={selectAllOptions}
          resetText={resetText}
          submitText={submitText}
          isSubmitDisabled={isSubmitDisabled}
          selectAllText={selectAllText}
          selectedCount={selectedCount}
          formatSelectedCount={formatSelectedCount}
        />
      )}
    </>
  )
}

export type PopoutSelectProps = React.ComponentProps<typeof PopoutSelect>
