import { useTheme } from '@capturi/ui-theme'
import { Box, Flex } from '@chakra-ui/react'
import { css } from '@emotion/react'
import React, { PropsWithChildren } from 'react'

export const ConversationCardBody: React.FC<
  PropsWithChildren<{ borderRadius: string }>
> = ({ children, borderRadius }) => {
  const theme = useTheme()
  return (
    <Flex
      minH={10}
      css={css`&:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        opacity: 0.5;
        border-bottom-left-radius: ${borderRadius};
        border-bottom-right-radius: ${borderRadius};
        background: ${theme.colors.aiGradient};
    }`}
      borderBottomLeftRadius={borderRadius}
      borderBottomRightRadius={borderRadius}
    >
      <Box
        bg="white"
        pl={4}
        pr={2}
        pb={3}
        pt={2}
        m="1px"
        fontSize="sm"
        w="100%"
        borderBottomLeftRadius={borderRadius}
        borderBottomRightRadius={borderRadius}
      >
        {children}
      </Box>
    </Flex>
  )
}
