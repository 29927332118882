import { User } from '@capturi/stores'
import { Flex, HStack, Spacer, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import { RoleView } from '../RoleView'
import { UserListView } from '../UserListView'
import { UserView } from '../UserView'
import { RoleItem, SearchResult, TeamItem } from './domain'

const UserRow: React.FC<{
  user: User
}> = ({ user }) => {
  return (
    <HStack spacing={2} w="100%">
      <UserView user={user} />
      <Spacer />
      <Text color="gray.600" fontSize="sm">
        {(user.role === 'user' && <Trans>User</Trans>) ||
          (user.role === 'teamlead' && <Trans>Teamlead</Trans>) ||
          (user.role === 'administrator' && <Trans>Administrator</Trans>)}
      </Text>
    </HStack>
  )
}

const RoleItemRow: React.FC<{
  roleItem: RoleItem
}> = ({ roleItem }) => {
  return (
    <Flex align="center" w="100%">
      <RoleView role={roleItem.role} />
      <Spacer />
      <Text color="gray.600" fontSize="sm">
        <Trans>Role</Trans>
      </Text>
    </Flex>
  )
}

const TeamItemRow: React.FC<{
  teamItem: TeamItem
}> = ({ teamItem }) => {
  return (
    <Flex align="center" w="100%">
      <UserListView
        title={teamItem.team.name}
        description={t`Everyone with this team assigned`}
        users={teamItem.users}
      />
      <Spacer />
      <Text color="gray.600" fontSize="sm">
        <Trans>Team</Trans>
      </Text>
    </Flex>
  )
}

export const SearchResultRow: React.FC<{
  searchResult: SearchResult
}> = ({ searchResult }) => {
  switch (searchResult.type) {
    case 'User':
      return <UserRow user={searchResult.item as User} />
    case 'Team':
      return <TeamItemRow teamItem={searchResult.item as TeamItem} />
    case 'Role':
      return <RoleItemRow roleItem={searchResult.item as RoleItem} />
  }
}
