export const basePath = '/coaching/library'

const routes = {
  root: basePath,
  folder: (uid?: string): string =>
    `${basePath}/playlists${uid ? `/${uid}` : ''}`,
  audioSnippet: (uid?: string): string =>
    `${basePath}${uid ? `?snippet=${uid}` : ''}`,
}

export default routes
