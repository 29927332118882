import { ConversationResponseModel, Tracker } from '@capturi/api-conversations'
import { useMemo } from 'react'
import TrackerMetadata from '../Trackers/TrackerMetadata'
import useTrackerTableContents, {
  TrackerTableContentsResult,
} from '../Trackers/useTrackerTableContents'

type TrackerHitMapResponse = {
  trackerHits: Tracker[]
  trackerHitMap: Map<string, TrackerMetadata>
  contents: TrackerTableContentsResult
}

export const useTrackerHits = (
  conversation: ConversationResponseModel | undefined,
): TrackerHitMapResponse => {
  const contents = useTrackerTableContents(
    conversation?.trackerHits ?? [],
    null,
    conversation?.ignoredPhrases,
  )
  return useMemo(() => {
    const trackerHits = conversation?.trackerHits ?? []
    const trackerHitMap = trackerHits.reduce<Map<string, TrackerMetadata>>(
      (memo, trackerHit) => {
        memo.set(trackerHit.uid, {
          count: contents.groupedTrackerHits.get(trackerHit.uid)?.length ?? 0,
          color: contents.trackersShapeColors?.[trackerHit.uid]?.color,
          shape: contents.trackersShapeColors?.[trackerHit.uid]?.shape,
        })
        return memo
      },
      new Map<string, TrackerMetadata>(),
    )
    return { trackerHitMap, trackerHits, contents }
  }, [contents, conversation?.trackerHits])
}
