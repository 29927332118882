import { RequestOptions } from '@capturi/request'

import { baseQuery, controller } from '../constants'

export const coachingInsightsAPI = {
  getSpeakingRateStatsForUser: (userUid: string): RequestOptions => ({
    url: `${controller}/speak_rate/user/${userUid}`,
    query: baseQuery,
    method: 'post',
  }),
  getLongestMonologueStatsForUser: (userUid: string): RequestOptions => ({
    url: `${controller}/longest_monologue/user/${userUid}`,
    query: baseQuery,
    method: 'post',
  }),
}
