import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { Button, ContentPlaceholder } from '@capturi/ui-components'
import { Center } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

const SessionNotFound: React.FC<{ startNewSession: () => void }> = ({
  startNewSession,
}) => {
  return (
    <ContentPlaceholder.Container mt={20}>
      <ContentPlaceholder.Image as={Icon_EmptyState} />
      <ContentPlaceholder.Heading>
        <Trans>Session not found or expired</Trans>
      </ContentPlaceholder.Heading>
      <ContentPlaceholder.Body>
        <Trans>Please, try to update to get a new session</Trans>
      </ContentPlaceholder.Body>
      <ContentPlaceholder.Footer>
        <Center>
          <Button primary onClick={startNewSession}>
            <Trans>Update</Trans>
          </Button>
        </Center>
      </ContentPlaceholder.Footer>
    </ContentPlaceholder.Container>
  )
}

export default SessionNotFound
