import {
  Acl,
  GrantedLevel,
  Role,
  roleToGrantedRole,
  useTeams,
} from '@capturi/core'
import { useUsers } from '@capturi/stores'
import { useMemo } from 'react'

import { groupUsers, localizePluralRole } from '../../utils'
import { SearchSelectOption } from './domain'

interface UseSharingScopeSearchResponse {
  maps: {
    roleMap: Map<Role, SearchSelectOption>
    teamMap: Map<string, SearchSelectOption>
    userMap: Map<string, SearchSelectOption>
  }
  selectOptions: SearchSelectOption[]
  isLoading: boolean
}

const existingRoleLevel = (role: Role, acl: Acl): GrantedLevel | undefined => {
  const grantedRole = roleToGrantedRole(role)
  return acl.find(
    (aclEntry) => aclEntry.type === 'role' && aclEntry.role === grantedRole,
  )?.level
}

const existingTeamLevel = (
  teamUid: string,
  acl: Acl,
): GrantedLevel | undefined => {
  return acl.find(
    (aclEntry) => aclEntry.type === 'team' && aclEntry.teamUid === teamUid,
  )?.level
}

const existingUserLevel = (
  userUid: string,
  acl: Acl,
): GrantedLevel | undefined => {
  return acl.find(
    (aclEntry) => aclEntry.type === 'user' && aclEntry.userUid === userUid,
  )?.level
}

export function useSharingScopeOptions(
  acl: Acl,
): UseSharingScopeSearchResponse {
  const { users, isLoading: isLoadingUsers } = useUsers()
  const { teams, isLoading: isLoadingTeams } = useTeams()

  const mapsAndOptions = useMemo(() => {
    const { usersByRole, usersByTeamUid } = groupUsers(users)

    const roleItems = [
      {
        role: Role.administrator,
        name: localizePluralRole(Role.administrator),
        users: usersByRole[Role.administrator],
      },
      {
        role: Role.teamlead,
        name: localizePluralRole(Role.teamlead),
        users: usersByRole[Role.teamlead],
      },
      {
        role: Role.user,
        name: localizePluralRole(Role.user),
        users: usersByRole[Role.user],
      },
    ]

    // Traverse through roles once
    const roleMap = new Map<Role, SearchSelectOption>()
    const roleOptions = new Array<SearchSelectOption>()
    roleItems.forEach((roleItem) => {
      const roleOption: SearchSelectOption = {
        searchResult: {
          item: roleItem,
          type: 'Role',
          level: existingRoleLevel(roleItem.role, acl) ?? 'Edit',
        },
        label: localizePluralRole(roleItem.role),
        value: roleItem.name,
      }
      roleMap.set(roleItem.role, roleOption)
      roleOptions.push(roleOption)
    })

    // Traverse through teams once
    const teamMap = new Map<string, SearchSelectOption>()
    const teamOptions = new Array<SearchSelectOption>()
    teams.forEach((team) => {
      const teamOption: SearchSelectOption = {
        searchResult: {
          item: {
            uid: team.uid,
            team,
            name: team.name,
            users: usersByTeamUid[team.uid],
          },
          type: 'Team',
          level: existingTeamLevel(team.uid, acl) ?? 'Edit',
        },
        label: team.name,
        value: team.uid,
      }
      teamMap.set(team.uid, teamOption)
      teamOptions.push(teamOption)
    })

    // Traverse through users once
    const userMap = new Map<string, SearchSelectOption>()
    const userOptions = new Array<SearchSelectOption>()
    users.forEach((user) => {
      if (!user.isDeleted && user.isAuthenticated) {
        const userOption: SearchSelectOption = {
          searchResult: {
            item: user,
            type: 'User',
            level: existingUserLevel(user.uid, acl) ?? 'Edit',
          },
          label: user.name,
          value: user.uid,
        }
        userMap.set(user.uid, userOption)
        userOptions.push(userOption)
      }
    })

    return { roleMap, roleOptions, teamMap, teamOptions, userMap, userOptions }
  }, [acl, teams, users])

  return useMemo(() => {
    return {
      maps: {
        roleMap: mapsAndOptions.roleMap,
        teamMap: mapsAndOptions.teamMap,
        userMap: mapsAndOptions.userMap,
      },
      selectOptions: [
        ...mapsAndOptions.roleOptions,
        ...mapsAndOptions.teamOptions,
        ...mapsAndOptions.userOptions,
      ],
      isLoading: isLoadingUsers || isLoadingTeams,
    }
  }, [mapsAndOptions, isLoadingUsers, isLoadingTeams])
}
