import { Box } from '@chakra-ui/react'
import React from 'react'

const Workspace: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <Box
      bg="workspace.bodyBackground"
      my={0}
      overflow="auto"
      width="100%"
      display="flex"
      justifyContent="center"
    >
      <Box
        h="auto"
        height="100vh"
        w="100%"
        minW="375px" //should come from our theme
        maxW="1600px" //should come from our theme
      >
        <Box
          overflow="hidden"
          backdropFilter="blur(8px)"
          minH="100vh"
          pr={8}
          pt={8}
          pl={[12, 12, 12, 12, 8]}
          bg="workspace.contentBackground"
          css={{
            '@supports selector(:has(.dashboard-container))': {
              '&:has(.dashboard-container)': {
                paddingBottom: 0, // required for proper padding in read-only dashboards
                height: '100%', // required for proper scroll behavior in read-only dashboards
              },
            },
            '@supports selector(:has(.key-topics-container))': {
              '&:has(.key-topics-container)': {
                paddingLeft: 32,
                paddingRight: 16,
                height: '100%',
              },
            },
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default Workspace
