import { SavedPhoneFilter } from '@capturi/api-filters'
import { ErrorBoundary } from '@capturi/react-utils'
import { Button } from '@capturi/ui-components'
import { Box } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useCallback } from 'react'
import { useEffectOnce } from 'react-use'

import { PhoneFilter } from '../../components/PhoneFilter'
import { FilterDefinitions } from '../../components/PhoneFilter/components/PhoneSegmentBuilder'
import { TextFilter } from '../../components/TextFilter'
import { configurations } from '../../segmentConfigurations'
import {
  DEFAULT_STATE,
  DEFAULT_TEXT_STATE,
  useSegmentStatesContext,
} from '../../state/segment-state/useSegmentStates'
import {
  Channel,
  PhoneSegmentBuilderState,
  TextSegmentBuilderState,
} from '../../types'
import SkeletonLoader from './SkeletonLoader'
import { FilterEvent, logEvent } from './events'

export type SegmentContainerOptions = {
  showResetButton?: boolean
  showNotificationButton?: boolean
  showSaveButton?: boolean
  showExportButton?: boolean
  showRandomizerButton?: boolean
  onToggleRandomizer?: (state: 'on' | 'off') => void
  isRandomizerEnabled?: boolean
  initiallyOpened?: boolean
  resetSavedFilterOnValuesChange?: boolean
  /**
   * Is the builder in read-only mode, i.e. can any changes be made
   */
  isReadOnly?: boolean
  allowReselectionWhenReadOnly?: boolean
  placeholderText?: string
}

export type MultiSegmentContainerProps = {
  /**
   * Possible filters and their definitions
   */
  filterDefinitions: FilterDefinitions
  /**
   * Color/label profiles of all possible segments that can be added.
   */
  segmentProfiles?: { color: string; label: string }[]
  /**
   * A callback fired when the state changes
   */
  onStateChange?: (
    state: PhoneSegmentBuilderState | TextSegmentBuilderState,
    index: number,
  ) => void
  /**
   * A callback fired when the saved filter state changes
   */
  onSavedFilterChange?: (
    savedFilter: SavedPhoneFilter | undefined,
    index: number,
  ) => void
  /**
   * A callback fired when the current state is persisted as a saved filter
   */
  onStateSaved?: (
    savedFilter: SavedPhoneFilter,
    isNew: boolean,
    index: number,
  ) => void
  /**
   * A callback fired when state is reset
   */
  onStateReset?: (state: PhoneSegmentBuilderState, index: number) => void

  disabledChannels?: Channel[]
} & SegmentContainerOptions

export const MultiSegmentContainer: React.FC<MultiSegmentContainerProps> = ({
  filterDefinitions,
  segmentProfiles = configurations,
  onStateChange,
  onSavedFilterChange,
  onStateSaved,
  onStateReset,
  showExportButton = true,
  showNotificationButton = true,
  showSaveButton = true,
  showResetButton,
  initiallyOpened = false,
  isReadOnly,
  allowReselectionWhenReadOnly = false,
  resetSavedFilterOnValuesChange = false,
  placeholderText,
  disabledChannels = ['email'],
  onToggleRandomizer,
  isRandomizerEnabled,
  showRandomizerButton,
}) => {
  const maxSegments = segmentProfiles.length

  const { states, addState, removeState, hasValues, updateState } =
    useSegmentStatesContext()

  // On pages where some channels are disabled, we reset states with disabled channels
  useEffectOnce(() => {
    states.forEach((state, index) => {
      if (disabledChannels.includes(state.channel)) {
        updateState({
          state: { channel: 'phone', values: DEFAULT_STATE },
          index,
        })
      }
    })
  })

  const handleAddState = (): void => {
    if (isReadOnly) return
    const newIndex = states.length
    addState()
    logEvent(FilterEvent.AddSegment, {
      segmentLabel: segmentProfiles[newIndex].label,
    })
  }

  const handleRemoveState = (index: number): void => {
    if (isReadOnly) return
    removeState(index)
    onToggleRandomizer?.('off')
    logEvent(FilterEvent.RemoveSegment, {
      segmentLabel: segmentProfiles[index].label,
    })
  }

  const handleUpdatePhoneSegmentState = useCallback(
    ({
      state,
      index,
    }: { state: PhoneSegmentBuilderState; index: number }): void => {
      if (isReadOnly && !allowReselectionWhenReadOnly) return

      updateState({ state, index })
      if (
        state.values.trackers?.length !== 0 ||
        state.values.notTrackers?.length !== 0
      ) {
        logEvent(FilterEvent.useTracker, {
          amountOfTrackers: state.values.trackers?.length,
          amountOfNotTrackers: state.values.notTrackers?.length,
        })
      }
      onStateChange?.(state, index)
    },
    [allowReselectionWhenReadOnly, isReadOnly, onStateChange, updateState],
  )

  const handleUpdateTextSegmentState = useCallback(
    ({
      state,
      index,
    }: { state: TextSegmentBuilderState; index: number }): void => {
      if (isReadOnly && !allowReselectionWhenReadOnly) return

      updateState({ state, index })
      onStateChange?.(state, index)
    },
    [allowReselectionWhenReadOnly, isReadOnly, onStateChange, updateState],
  )

  const handleChannelChange = useCallback(
    (channel: Channel, index: number): void => {
      if (channel === 'phone') {
        handleUpdatePhoneSegmentState({
          state: {
            channel: 'phone',
            values: {},
          },
          index,
        })
      } else {
        handleUpdateTextSegmentState({
          state: {
            channel: channel,
            values: { ...DEFAULT_TEXT_STATE },
          },
          index,
        })
      }
    },
    [handleUpdatePhoneSegmentState, handleUpdateTextSegmentState],
  )

  return (
    <ErrorBoundary>
      <React.Suspense fallback={<SkeletonLoader comparable />}>
        <Box data-stonly="Multi filter">
          {states.map((s, i) => {
            if (i < maxSegments)
              return s.channel === 'phone' ? (
                <PhoneFilterWithIndex
                  key={s.id}
                  index={i}
                  label={segmentProfiles[i].label}
                  color={segmentProfiles[i].color}
                  filterDefinitions={filterDefinitions}
                  onSavedFilterChange={onSavedFilterChange}
                  onStateReset={onStateReset}
                  onStateChange={handleUpdatePhoneSegmentState}
                  onChannelChange={handleChannelChange}
                  onStateSaved={onStateSaved}
                  state={s}
                  showExportButton={showExportButton}
                  showNotificationButton={showNotificationButton}
                  showSaveButton={showSaveButton}
                  showResetButton={
                    showResetButton ?? (states.length === 1 && hasValues(i))
                  }
                  initiallyOpened={initiallyOpened}
                  isReadOnly={isReadOnly}
                  allowReselectionWhenReadOnly={allowReselectionWhenReadOnly}
                  resetSavedFilterOnValuesChange={
                    resetSavedFilterOnValuesChange
                  }
                  placeholderText={placeholderText}
                  disabledChannels={disabledChannels}
                  onToggleRandomizer={onToggleRandomizer}
                  isRandomizerEnabled={isRandomizerEnabled}
                  showRandomizerButton={showRandomizerButton}
                >
                  {states.length > 1 && (
                    <Button
                      variant="ghost"
                      colorScheme="gray"
                      onClick={() => handleRemoveState(i)}
                      size="sm"
                      px={2}
                    >
                      <Trans>Remove</Trans>
                    </Button>
                  )}
                </PhoneFilterWithIndex>
              ) : (
                <TextFilterWithIndex
                  key={s.id}
                  index={i}
                  initialValue={s.values}
                  segmentState={s}
                  configuration={segmentProfiles[i]}
                  showSaveButton={showSaveButton}
                  onSegmentStateChange={handleUpdateTextSegmentState}
                  onChannelChange={handleChannelChange}
                  disabledChannels={disabledChannels}
                  resetSavedFilterOnValuesChange={
                    resetSavedFilterOnValuesChange
                  }
                >
                  {states.length > 1 && (
                    <Button
                      variant="ghost"
                      colorScheme="gray"
                      onClick={() => handleRemoveState(i)}
                      size="sm"
                      px={2}
                    >
                      <Trans>Remove</Trans>
                    </Button>
                  )}
                </TextFilterWithIndex>
              )
          })}
          {!isReadOnly && states.length < maxSegments && (
            <Button
              variant="link"
              size="xs"
              colorScheme="primary"
              my={2}
              onClick={handleAddState}
              data-stonly="Add comparison"
            >
              <Trans>Add comparison</Trans>
            </Button>
          )}
        </Box>
      </React.Suspense>
    </ErrorBoundary>
  )
}

export const TextFilterWithIndex: React.FC<
  Omit<
    React.ComponentProps<typeof TextFilter>,
    'onSegmentStateChange' | 'onChannelChange'
  > & {
    index: number
    onSegmentStateChange: ({
      state,
      index,
    }: {
      state: TextSegmentBuilderState
      index: number
    }) => void
    onChannelChange: (channel: Channel, index: number) => void
  }
> = ({ index, onSegmentStateChange, onChannelChange, ...props }) => {
  const handleSegmentStateChange = React.useCallback(
    (state: TextSegmentBuilderState) => {
      onSegmentStateChange({ state, index })
    },
    [index, onSegmentStateChange],
  )
  const handleChannelChange = React.useCallback(
    (channel: Channel) => {
      onChannelChange(channel, index)
    },
    [index, onChannelChange],
  )
  return (
    <TextFilter
      {...props}
      onSegmentStateChange={handleSegmentStateChange}
      onChannelChange={handleChannelChange}
    />
  )
}

export const PhoneFilterWithIndex: React.FC<
  Omit<
    React.ComponentProps<typeof PhoneFilter>,
    | 'onSavedFilterChange'
    | 'onStateSaved'
    | 'onStateChange'
    | 'onChannelChange'
    | 'onStateReset'
  > & {
    index: number
    onSavedFilterChange?: (
      savedFilter: SavedPhoneFilter | undefined,
      index: number,
    ) => void
    onStateChange?: ({
      state,
      index,
    }: { state: PhoneSegmentBuilderState; index: number }) => void
    onChannelChange?: (channel: Channel, index: number) => void
    onStateReset?: (state: PhoneSegmentBuilderState, index: number) => void
    onStateSaved?: (
      savedFilter: SavedPhoneFilter,
      isNew: boolean,
      index: number,
    ) => void
  }
> = ({
  index,
  onSavedFilterChange,
  onStateChange,
  onChannelChange,
  onStateReset,
  onStateSaved,
  ...props
}) => {
  const handleSavedFilterChange = React.useCallback(
    (savedFilter: SavedPhoneFilter | undefined) => {
      onSavedFilterChange?.(savedFilter, index)
    },
    [index, onSavedFilterChange],
  )
  const handleStateChange = React.useCallback(
    (state: PhoneSegmentBuilderState) => {
      onStateChange?.({ state, index })
    },
    [index, onStateChange],
  )
  const handleStateReset = React.useCallback(
    (state: PhoneSegmentBuilderState) => {
      onStateReset?.(state, index)
    },
    [index, onStateReset],
  )
  const handleStateSaved = React.useCallback(
    (savedFilter: SavedPhoneFilter, isNew: boolean) => {
      onStateSaved?.(savedFilter, isNew, index)
    },
    [index, onStateSaved],
  )
  const handleChannelChange = React.useCallback(
    (channel: Channel) => {
      onChannelChange?.(channel, index)
    },
    [index, onChannelChange],
  )
  return (
    <PhoneFilter
      {...props}
      onSavedFilterChange={handleSavedFilterChange}
      onStateChange={handleStateChange}
      onChannelChange={handleChannelChange}
      onStateReset={handleStateReset}
      onStateSaved={handleStateSaved}
    />
  )
}
