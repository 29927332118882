import {
  ChipItem,
  ChipLabel,
  ChipRemoveButton,
  ChipsInput,
  ChipsItemRenderer,
  FormLabel,
} from '@capturi/ui-components'
import {
  Box,
  Flex,
  HStack,
  ListItem,
  Popover,
  PopoverTrigger,
  Text,
  Tooltip,
  UnorderedList,
  chakra,
  useToast,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans, t } from '@lingui/macro'
import NearnessPopoverContent from 'components/ConversationDetails/components/NearnessPopoverContent'
import React, { ReactElement } from 'react'
import { MdTrackChanges } from 'react-icons/md'
import { useEffectOnce } from 'react-use'

import hasNearnessSettings from '../../../../../../utils/hasNearnessSettings'
import {
  duplicatePhraseTooltipMsg,
  orphanedQuoteTooltipMsg,
  phraseIsTooShortTooltipMsg,
} from '../../../messages'
import {
  useDuplicateSpeechPhrases,
  usePhraseFields,
} from '../../../usePhraseFields'
import { UnknownWordsMessage } from '../UnknownWordsMessage'
import { logEvent } from '../logEvent'
import { useDictionaryWordContext } from '../useDictionaryWordStatus'
import { usePhraseValidation } from '../usePhraseValidation'
import { useSimilarWordsContext } from '../useSimilarWords'

const SpeechPhrasesSimpleView: React.FC = () => {
  const {
    phrases: state,
    addPhraseFields,
    removePhraseField,
    emptyPhrasesField,
    removeLatestPhraseFieldsDuplicates,
    setPhraseFields,
  } = usePhraseFields()

  const validatePhrase = usePhraseValidation()
  const { hasDuplicates } = useDuplicateSpeechPhrases()

  useEffectOnce(() => {
    // The advanced view allows empty fields, but the simple view doesn't
    // Remove any empty fields on mount
    const filteredState = state.filter((x) => x.value.trim().length > 0)
    setPhraseFields(filteredState)
  })

  const toast = useToast()

  const { similarWords } = useSimilarWordsContext()
  const { validateWords, isUnknownWord } = useDictionaryWordContext()

  const handleValuesAdded = (newValues: string[]): void => {
    newValues.forEach((value) => {
      logEvent('addPhraseField', false, {
        numberOfWords: value.split(' ').length,
      })
    })
    addPhraseFields(...newValues)
    validateWords(newValues)
  }

  const handleValueRemoved = (_value: string, index: number): void => {
    removePhraseField(index)
  }

  const renderSuggestion = (word: string): ReactElement => {
    const isDisabled = state.some((s) => s.value === word)
    return (
      <Text
        as="button"
        textDecoration={isDisabled ? 'none' : 'underline'}
        cursor={isDisabled ? 'default' : 'pointer'}
        color={isDisabled ? 'textMuted' : 'text'}
        p="1"
        key={word}
        onClick={(e) => {
          e.preventDefault()
          if (!isDisabled) {
            logEvent('addedSuggestion', false)
            addPhraseFields(word)
          }
        }}
      >
        {word}
      </Text>
    )
  }

  const renderItem: ChipsItemRenderer = React.useCallback(
    (value, words, onRemove, index) => {
      const {
        hasUnknownWords,
        isDuplicatePhrase,
        hasOrphanedQuote,
        isShortPhrase,
      } = validatePhrase(value)

      const hasNearness = hasNearnessSettings(state[index].settings)

      let colorScheme = 'gray'
      if (isDuplicatePhrase || hasOrphanedQuote) {
        colorScheme = 'red'
      } else if (hasUnknownWords) {
        colorScheme = 'primary'
      } else if (isShortPhrase) {
        colorScheme = 'orange'
      }

      const tooltipMessages = []
      if (isDuplicatePhrase) {
        tooltipMessages.push(duplicatePhraseTooltipMsg())
      }
      if (hasOrphanedQuote) {
        tooltipMessages.push(orphanedQuoteTooltipMsg())
      }
      if (hasUnknownWords) {
        tooltipMessages.push(
          t`This phrase contains a word unknown to our dictionary`,
        )
      }
      if (isShortPhrase) {
        tooltipMessages.push(phraseIsTooShortTooltipMsg())
      }
      if (hasNearness) {
        tooltipMessages.push(t`This phrase has advanced settings.`)
      }

      const tooltipLabel =
        tooltipMessages.length === 1 ? (
          tooltipMessages[0]
        ) : (
          <UnorderedList>
            {tooltipMessages.map((x, i) => (
              <ListItem key={i}>{x}</ListItem>
            ))}
          </UnorderedList>
        )

      const nearnessObject = state[index].settings

      return (
        <ChipItem colorScheme={colorScheme} variant="subtle">
          {hasNearness && (
            <Popover trigger="hover">
              <PopoverTrigger>
                <Box mr={1}>
                  <MdTrackChanges />
                </Box>
              </PopoverTrigger>
              <NearnessPopoverContent phrasesSetting={nearnessObject} />
            </Popover>
          )}
          <Tooltip
            label={tooltipLabel}
            isDisabled={tooltipMessages.length === 0}
            hasArrow
            openDelay={300}
          >
            <Box>
              <ChipLabel>
                {words.map((word, i) => (
                  <chakra.span
                    key={i}
                    fontWeight={isUnknownWord(word) ? 'medium' : 'inherit'}
                    css={css`
                      :not(:last-child) {
                        ::after {
                          content: ' ';
                        }
                      }
                    `}
                  >
                    {word}
                  </chakra.span>
                ))}
              </ChipLabel>
            </Box>
          </Tooltip>
          <ChipRemoveButton onClick={() => onRemove()} />
        </ChipItem>
      )
    },
    [isUnknownWord, state, validatePhrase],
  )

  return (
    <Box>
      <HStack spacing={8}>
        <Box width="70%">
          <FormLabel data-stonly="tracker_phrases--simple-view" p={0} m={0}>
            <Trans>Words/sentences</Trans>
          </FormLabel>
          <Box minH="5em">
            <ChipsInput
              value={state.map((x) => x.value)}
              onValuesAdded={handleValuesAdded}
              onValueRemoved={handleValueRemoved}
              onClearValue={emptyPhrasesField}
              hasDuplicates={hasDuplicates}
              removeLatestDuplicates={removeLatestPhraseFieldsDuplicates}
              onClipboardCopy={() => {
                toast({
                  title: t`All phrases copied to clipboard`,
                })
              }}
              inputPlaceholderText={t`Add word/phrase`}
              renderItem={renderItem}
            />
          </Box>
        </Box>
        <Box width="30%">
          <FormLabel data-stonly="tracker_phrases--suggestions">
            <Trans>Suggestions</Trans>
          </FormLabel>
          <Flex flexWrap="wrap">{similarWords.map(renderSuggestion)}</Flex>
        </Box>
      </HStack>
      <Box mt={4}>
        <UnknownWordsMessage fields={state} />
      </Box>
    </Box>
  )
}

export default SpeechPhrasesSimpleView
