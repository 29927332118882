import { TimeFilterKind } from '@capturi/api-trackers'
import {
  DurationInput,
  FormLabel,
  RadioButton,
  RadioButtonGroup,
} from '@capturi/ui-components'
import { Box, Collapse, Flex, FormControl, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { MdOutlineTimer } from 'react-icons/md'

import { TimeFilter } from '../types'

type Props = {
  value: TimeFilter
  onTimeFilterChange: (o: TimeFilter) => void
}

export const TimeFilterField: React.FC<Props> = ({
  value,
  onTimeFilterChange,
}: Props) => {
  return (
    <FormControl>
      <FormLabel
        htmlFor="tracker_time-filter"
        leftIcon={<MdOutlineTimer />}
        data-stonly="tracker_time-filter"
      >
        <Trans>Active in conversation</Trans>
      </FormLabel>
      <RadioButtonGroup
        id="tracker_time-filter"
        value={value.kind}
        onChange={(v) => {
          onTimeFilterChange({ ...value, kind: v as TimeFilterKind })
        }}
      >
        <RadioButton
          value={TimeFilterKind.ENTIRECONVERSATION}
          onClick={() => {
            onTimeFilterChange({
              ...value,
              seconds: undefined,
              secondsEnd: null,
            })
          }}
        >
          <Trans>Entire conversation</Trans>
        </RadioButton>
        <RadioButton
          value={TimeFilterKind.FROMSTART}
          onClick={() => {
            onTimeFilterChange({ ...value, seconds: 0, secondsEnd: null })
          }}
        >
          <Trans>At the beginning</Trans>
        </RadioButton>
        <RadioButton
          value={TimeFilterKind.FROMENDING}
          onClick={() => {
            onTimeFilterChange({ ...value, seconds: 0, secondsEnd: null })
          }}
        >
          <Trans>At the end</Trans>
        </RadioButton>
        <RadioButton
          value={TimeFilterKind.RANGE}
          onClick={() => {
            onTimeFilterChange({ ...value, seconds: 0, secondsEnd: 0 })
          }}
        >
          <Trans>Part of conversation</Trans>
        </RadioButton>
      </RadioButtonGroup>
      <>
        <Collapse
          in={
            value.kind === TimeFilterKind.FROMSTART ||
            value.kind === TimeFilterKind.FROMENDING
          }
          animateOpacity={false}
        >
          <Flex align="center" p={2}>
            <DurationInput
              value={value.seconds}
              onChange={(s) => {
                onTimeFilterChange({ ...value, seconds: s })
              }}
              size="sm"
            />
            <Text ml={4} fontSize="sm">
              {value.kind === TimeFilterKind.FROMSTART
                ? t`Start of conversation`
                : t`End of conversation`}
            </Text>
          </Flex>
        </Collapse>
        <Collapse
          in={value.kind === TimeFilterKind.RANGE}
          animateOpacity={false}
        >
          <Box>
            <Flex flexDirection="column" alignItems="flex-start" p={2}>
              <Box
                mb={3}
                display="flex"
                alignItems="center"
                gap={3}
                justifyContent="space-between"
              >
                <DurationInput
                  value={value.seconds}
                  onChange={(s) => {
                    onTimeFilterChange({ ...value, seconds: s })
                  }}
                  size="sm"
                />
                <Text fontSize="sm" color="gray.600">
                  <Trans>After the start of the conversation</Trans>
                </Text>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                gap={3}
                justifyContent="space-between"
              >
                <DurationInput
                  value={value.secondsEnd}
                  onChange={(s) => {
                    onTimeFilterChange({ ...value, secondsEnd: s })
                  }}
                  size="sm"
                />
                <Text fontSize="sm" color="gray.600">
                  <Trans>Before the end of the conversation</Trans>
                </Text>
              </Box>
            </Flex>
          </Box>
        </Collapse>
      </>
    </FormControl>
  )
}
