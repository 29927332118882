import { Box, Icon } from '@chakra-ui/react'
import React, { MutableRefObject } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { MdClose } from 'react-icons/md'
import ReactSelect, {
  ClearIndicatorProps,
  GroupBase,
  IndicatorsContainerProps,
  Props as ReactSelectProps,
  SelectInstance,
  components,
  mergeStyles,
} from 'react-select'

import { customStyles, customTheme } from './customTheme'

export type SelectOptionBase = {
  value: string
  label: string
}

export type SelectOption = SelectOptionBase & {
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  [key: string]: any
}

const DropdownIndicator: React.FC = () => {
  return <Icon as={FiChevronDown} boxSize="20px" color="black" />
}

function ClearIndicator<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: ClearIndicatorProps<Option, IsMulti, Group>): React.ReactElement {
  return (
    <components.ClearIndicator {...props}>
      <Icon as={MdClose} boxSize="18px" />
    </components.ClearIndicator>
  )
}

function IndicatorsContainer<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: IndicatorsContainerProps<Option, IsMulti, Group>): React.ReactElement {
  return (
    <Box px={2}>
      <components.IndicatorsContainer
        {...(props as IndicatorsContainerProps<Option, IsMulti>)}
      />
    </Box>
  )
}

const IndicatorSeparator = null

export const componentOverrides = {
  ClearIndicator,
  DropdownIndicator,
  IndicatorsContainer,
  IndicatorSeparator,
}

export type SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = ReactSelectProps<Option, IsMulti, Group>

export const Select = React.forwardRef(
  <
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: ReactSelectProps<Option, IsMulti, Group> & {
      mRef?:
        | ((instance: SelectInstance<Option, IsMulti, Group> | null) => void)
        | MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
        | null
    },
    _ref: React.Ref<SelectInstance<Option, IsMulti, Group>>,
  ) => {
    const { styles, components: componentsProps = {}, ...selectProps } = props

    return (
      <ReactSelect
        ref={props.mRef}
        components={{
          ...componentOverrides,
          ...componentsProps,
        }}
        styles={mergeStyles(styles ?? {}, customStyles())}
        theme={customTheme}
        closeMenuOnSelect={!selectProps.isMulti}
        {...selectProps}
      />
    )
  },
) as <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: ReactSelectProps<Option, IsMulti, Group> & {
    mRef?:
      | ((instance: SelectInstance<Option, IsMulti, Group> | null) => void)
      | MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
      | null
  },
) => React.ReactElement
