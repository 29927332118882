import { ChakraProvider, ChakraProviderProps } from '@chakra-ui/react'
import React from 'react'

import theme from './theme'

export const ThemeProvider: React.FC<ChakraProviderProps> = ({
  children,
  ...props
}) => {
  return (
    <ChakraProvider resetCSS theme={theme} {...props}>
      {children}
    </ChakraProvider>
  )
}
