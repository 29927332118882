import { Box, Progress } from '@chakra-ui/react'
import React, { useContext, useRef } from 'react'
import { CellProps, ColumnWithLooseAccessor, HeaderProps } from 'react-table'
import { useMeasure } from 'react-use'

import { DataGridRefContext } from '../DataGrid'
import { MultiValueCell } from '../components/Cell'
import ReferenceLine from '../components/ReferenceLine'
import ReferenceRange from '../components/ReferenceRange'
import { ColumnDefinition, DataType, ProgressColumn } from '../types'
import { getDataValueRange } from '../utils'

export default function progressColumn<T extends DataType>(
  c: ColumnDefinition<T>,
  // biome-ignore lint/suspicious/noExplicitAny: legacy
): ColumnWithLooseAccessor<any> {
  return {
    id: c.id ?? 'progress',
    accessor: (d) => d.segments,
    width: c.width ?? 'auto',
    minWidth: 50,
    Cell: function ProgressCell({ row, data }: CellProps<T, string>) {
      const { range, getSegmentValue, progressBarHeight } =
        c as ProgressColumn<T>
      const segments = row.values.progress as T['segments']
      const [minValue, maxValue] = getDataValueRange(
        data as T[],
        getSegmentValue,
        range,
      )
      return (
        <MultiValueCell values={segments}>
          {(s) => (
            <Progress
              value={c.getSegmentValue?.(s) ?? 0}
              min={minValue}
              max={maxValue}
              height={progressBarHeight}
              colorScheme={s.color}
              borderRadius="sm"
              width="full"
            />
          )}
        </MultiValueCell>
      )
    },
    Header: function ColumnHeader({
      data,
    }: HeaderProps<T>): React.ReactElement | null {
      const { range, referenceRange, referenceLines, getSegmentValue } =
        c as ProgressColumn<T>
      const [minValue, maxValue] = getDataValueRange(
        data as T[],
        getSegmentValue,
        range,
      )
      const elementRef = useRef<HTMLDivElement | null>(null)
      const gridElementRef = useContext(DataGridRefContext)
      const [measureRef, { width }] = useMeasure<HTMLDivElement>()
      const offsetLeft = elementRef.current?.offsetLeft
      const gridHeight = gridElementRef?.current?.clientHeight ?? 0
      if (
        (referenceRange === undefined && referenceLines === undefined) ||
        referenceLines?.length === 0
      ) {
        return null
      }
      return (
        <Box
          ref={(elm) => {
            elementRef.current = elm
            measureRef(elm as HTMLDivElement)
          }}
        >
          {data.length > 0 && offsetLeft !== undefined && gridHeight > 0 && (
            <Box
              position="absolute"
              top={0}
              left={`${offsetLeft}px`}
              width={`${width}px`}
              height={`${gridHeight}px`}
              zIndex={1}
            >
              {referenceRange && (
                <ReferenceRange
                  minValue={minValue}
                  maxValue={maxValue}
                  formatValue={c.formatValue}
                  {...referenceRange}
                />
              )}
              {(referenceLines ?? []).map((x) => (
                <ReferenceLine
                  key={x.color}
                  {...x}
                  minValue={minValue}
                  maxValue={maxValue}
                />
              ))}
            </Box>
          )}
        </Box>
      )
    },
  }
}
