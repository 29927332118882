import { Role as RoleEnum, useCurrentUser } from '@capturi/core'
import { Role, User, useUsers } from '@capturi/stores'
import {
  FixedItemsSelect,
  FixedItemsSelectOption,
  OptionProps,
  components,
} from '@capturi/ui-select'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Trans, select, t } from '@lingui/macro'
import React, { useMemo, useState } from 'react'

import { LibraryFolderPermissions } from '../../types'

type Props = {
  value: LibraryFolderPermissions | null
  onChange: (value: LibraryFolderPermissions | null) => void
  id?: string
  fixCurrentUser?: boolean
  fixCurrentUserRole?: boolean
}

type AccessType = 'all' | 'private' | 'users' | 'roles'

const createRoleOption = (
  role: Role,
  isHighlighted = false,
  isFixed = false,
): FixedItemsSelectOption<Role> => ({
  value: role,
  label: localizeRole(role),
  data: role,
  isHighlighted: isHighlighted,
  isFixed: isFixed,
})

const createUserOption = (
  user: User,
  isHighlighted = false,
  isFixed = false,
): FixedItemsSelectOption<User> => ({
  value: user.uid,
  label: user.name,
  data: user,
  isHighlighted: isHighlighted,
  isFixed: isFixed,
})

const localizeRole = (role: Role): string =>
  select(role, {
    administrator: 'Administrator',
    teamlead: 'Team lead',
    user: 'User',
    other: 'unknown',
  })

const UserOption: React.ComponentType<
  OptionProps<FixedItemsSelectOption<User>, true>
> = (props) => {
  const { label, data } = props.data as FixedItemsSelectOption<User>
  const { title, email } = data ?? {}
  const subtext = [title, email].filter(Boolean).join(', ')
  return (
    <components.Option {...props}>
      <Box noOfLines={1} wordBreak="break-all">
        {label}
      </Box>
      <Text fontSize="xs" color="textMuted" noOfLines={1} wordBreak="break-all">
        {subtext}
      </Text>
    </components.Option>
  )
}

const allRoleOptions: Role[] = ['administrator', 'teamlead', 'user']

const FolderPermissions: React.FC<Props> = ({
  value,
  onChange,
  fixCurrentUser,
  fixCurrentUserRole,
  id,
}) => {
  const currentUser = useCurrentUser()
  const { users, getUserByUid } = useUsers()

  const userValues = value?.users
  const roleValues = value?.roles

  const [accessType, _setAccessType] = useState<AccessType>(() => {
    if (userValues?.length) {
      if (userValues.length === 1 && userValues[0] === currentUser.id) {
        return 'private'
      }
      return 'users'
    }
    if (roleValues?.length) return 'roles'
    return 'all'
  })

  const [userSelectionState, setUserSelectionState] = useState(() => {
    const initialValues = userValues ?? []
    if (fixCurrentUser && !initialValues.includes(currentUser.id)) {
      initialValues.unshift(currentUser.id)
    }
    return initialValues
  })

  const [roleSelectionState, setRoleSelectionState] = useState(() => {
    const initialValues = roleValues ?? []
    if (fixCurrentUserRole && !initialValues.includes(currentUser.role)) {
      initialValues.unshift(currentUser.role)
    }
    return initialValues
  })

  const selectedUsers = useMemo(() => {
    let users = userValues ?? []
    if (fixCurrentUser && !users.includes(currentUser.id)) {
      users = [currentUser.id, ...users]
    }
    return users.map((uid) => {
      const user = getUserByUid(uid)
      return createUserOption(
        user,
        user.uid === currentUser.id,
        fixCurrentUser && user.uid === currentUser.id,
      )
    })
  }, [userValues, fixCurrentUser, currentUser.id, getUserByUid])

  const selectedRoles = useMemo(() => {
    let roles = (roleValues ?? []).map((role) => role.toLowerCase()) as Role[]
    if (fixCurrentUserRole && !roles.includes(currentUser.role)) {
      roles = [currentUser.role, ...roles]
    }
    return roles.map((role) =>
      createRoleOption(
        role,
        role === currentUser.role,
        fixCurrentUserRole && role === currentUser.role,
      ),
    )
  }, [roleValues, fixCurrentUserRole, currentUser.role])

  const roleOptions = allRoleOptions.map((role) =>
    createRoleOption(
      role,
      role === currentUser.role,
      fixCurrentUserRole && role === currentUser.role,
    ),
  )

  const userOptions = useMemo(
    () =>
      users.map((user) =>
        createUserOption(
          user,
          user.uid === currentUser.id,
          fixCurrentUser && user.uid === currentUser.id,
        ),
      ),
    [users, currentUser, fixCurrentUser],
  )

  const setSelectedUsers = (userIds: string[]): void => {
    setUserSelectionState(userIds)
    onChange({ users: userIds })
  }

  const setSelectedRoles = (roles: Role[]): void => {
    setRoleSelectionState(roles as RoleEnum[])
    onChange({ roles: roles as RoleEnum[] })
  }

  const setAccessType = (type: AccessType): void => {
    switch (type) {
      case 'all': {
        onChange(null)
        break
      }
      case 'private': {
        setSelectedUsers([currentUser.id])
        break
      }
      case 'users': {
        const selectedUsers = [...userSelectionState]
        // Add list is empty then add current user when switching to this access type
        if (selectedUsers.length === 0) {
          selectedUsers.push(currentUser.id)
        }
        setSelectedUsers(selectedUsers)
        break
      }
      case 'roles': {
        const selectedRoles = [...roleSelectionState]
        // Add list is empty then add current user role when switching to this access type
        if (selectedRoles.length === 0) {
          selectedRoles.push(currentUser.role)
        }
        setSelectedRoles(selectedRoles)
        break
      }
    }
    _setAccessType(type)
  }

  const userWillLooseAccessByRole =
    accessType === 'roles' && !(roleValues ?? []).includes(currentUser.role)
  const userWillLooseAccessByUserId =
    accessType === 'users' && !(userValues ?? []).includes(currentUser.id)
  return (
    <>
      <RadioGroup id={id} value={accessType} onChange={setAccessType}>
        <Stack>
          <Box>
            <Radio value="all" id="access-type-all">
              <Trans>All</Trans>
            </Radio>
          </Box>
          <Box>
            <Radio value="private" id="access-type-private">
              <Trans>Private</Trans>
            </Radio>
          </Box>
          <Box>
            <Radio value="users" id="access-type-users">
              <Trans>Selected users</Trans>
            </Radio>
            {accessType === 'users' && (
              <Box p={1} pt={2}>
                <FixedItemsSelect
                  options={userOptions}
                  values={selectedUsers}
                  onChange={(values) =>
                    setSelectedUsers((values ?? []).map((x) => x.value))
                  }
                  components={{ Option: UserOption }}
                  placeholder={t`Select users ...`}
                />
              </Box>
            )}
          </Box>
          <Box>
            <Radio value="roles" id="access-type-roles">
              <Trans>Selected roles</Trans>
            </Radio>
            {accessType === 'roles' && (
              <Box p={1} pt={2}>
                <FixedItemsSelect
                  options={roleOptions}
                  values={selectedRoles}
                  onChange={(values) =>
                    setSelectedRoles(values.map((x) => x.value) as Role[])
                  }
                  placeholder={t`Select roles ...`}
                />
              </Box>
            )}
          </Box>
        </Stack>
      </RadioGroup>
      {userWillLooseAccessByUserId && (
        <Alert status="info" mt={2}>
          <AlertIcon />
          <AlertDescription>
            <Trans>
              You will no longer be able to access the playlist because you
              removed yourself from the list of users with access
            </Trans>
          </AlertDescription>
        </Alert>
      )}
      {userWillLooseAccessByRole && (
        <Alert status="info" mt={2}>
          <AlertIcon />
          <AlertDescription>
            <Trans>
              You will no longer be able to access the playlist because you
              removed <strong>{localizeRole(currentUser.role)}</strong> from the
              list of roles with access
            </Trans>
          </AlertDescription>
        </Alert>
      )}
    </>
  )
}

export default FolderPermissions
