import { Button, ButtonProps } from '@capturi/ui-components'
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  MenuItem,
  MenuItemProps,
} from '@chakra-ui/react'
import { createPath } from 'history'
import React from 'react'
import {
  LinkProps as RRLinkProps,
  useHref,
  useLocation,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom'

const basePath = '/'

function isModifiedEvent(event: React.MouseEvent): boolean {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

export function isLocalURL(url?: string): boolean {
  if (url === undefined) return true
  if (url.startsWith('/')) return true
  try {
    // absolute urls can be local if they are on the same origin
    const locationOrigin = window.location.origin
    const resolved = new URL(url, locationOrigin)
    return resolved.origin === locationOrigin && hasBasePath(resolved.pathname)
  } catch {
    return false
  }
}

function hasBasePath(path: string): boolean {
  return path === basePath || path.startsWith(`${basePath}/`)
}

export type LinkProps = ChakraLinkProps &
  Pick<RRLinkProps, 'replace' | 'state' | 'to'>

/**
 * Enhances chakra Link component with react-router navigation (unless link is external)
 */
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  function LinkWithRef(
    {
      isExternal: isExternalProp,
      onClick,
      replace: replaceProp = false,
      state,
      target,
      to,
      ...restProps
    },
    ref,
  ): JSX.Element {
    // use `isExternal` prop if set otherwise auto-detect
    const isExternal = isExternalProp ?? !isLocalURL(to.toString())

    const navigate = useNavigate()
    const location = useLocation()
    const path = useResolvedPath(to)

    let href = useHref(to)
    if (isExternal) {
      href = to.toString()
    }

    function handleClick(event: React.MouseEvent<HTMLAnchorElement>): void {
      onClick?.(event)
      if (
        !(isExternal || event.defaultPrevented) && // onClick prevented default
        event.button === 0 && // Ignore everything but left clicks
        (!target || target === '_self') && // Let browser handle "target=_blank" etc.
        !isModifiedEvent(event) // Ignore clicks with modifier keys
      ) {
        event.preventDefault()

        // If the URL hasn't changed, a regular <a> will do a replace instead of
        // a push, so do the same here.
        const replace =
          !!replaceProp || createPath(location) === createPath(path)

        navigate(to, { replace, state })
      }
    }

    return (
      <ChakraLink
        ref={ref}
        href={href}
        onClick={handleClick}
        target={isExternal ? '_blank' : target}
        isExternal={isExternal}
        rel={isExternal ? 'noopener noreferrer' : undefined}
        {...restProps}
      />
    )
  },
)

export const MenuLink = React.forwardRef<
  HTMLButtonElement,
  MenuItemProps & Pick<LinkProps, 'to'>
>(function MenuLinkWithRef(props, ref) {
  return <MenuItem ref={ref} as={Link} variant="unstyled" {...props} />
})

export const ButtonLink = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & Pick<LinkProps, 'to' | 'target'>
>(function ButtonLinkWithRef(props, ref) {
  return (
    <Button
      ref={ref}
      as={Link}
      textDecoration="none"
      css={{
        '&:hover': {
          textDecoration: 'none',
        },
      }}
      {...props}
    />
  )
})

export default Link
