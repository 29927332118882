import { useToken } from '@chakra-ui/react'
import React from 'react'
import {
  ActionMeta,
  GroupBase,
  OnChangeValue,
  StylesConfig,
} from 'react-select'

import { Select, SelectOptionBase, SelectProps as _SelectProps } from './Select'

export type FixedItemsSelectOption<T = unknown> = SelectOptionBase & {
  data?: T
  isHighlighted?: boolean
  isFixed?: boolean
}

export type FixedItemsSelectProps<
  Option extends FixedItemsSelectOption,
  Group extends GroupBase<Option>,
> = Omit<_SelectProps<Option, true, Group>, 'onChange'> & {
  options: Option[]
  values: Option[]
  onChange: (values: readonly Option[]) => void
}

export function FixedItemsSelect<
  Option extends FixedItemsSelectOption,
  Group extends GroupBase<Option>,
>({
  options,
  values,
  components,
  onChange,
}: FixedItemsSelectProps<Option, Group>): React.ReactElement {
  const multiValueHighlightColor = useToken('colors', 'primary.200') as string

  function handleChange(
    newValue: OnChangeValue<Option, true>,
    actionMeta: ActionMeta<Option>,
  ): void {
    let val = newValue
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return
        }
        break
      case 'clear':
        val = val.filter((v) => v.isFixed)
        break
    }

    onChange(val)
  }

  const styles: StylesConfig<Option, true, Group> = {
    multiValue: (base, { data }) => {
      const { isHighlighted } = data as FixedItemsSelectOption
      return isHighlighted
        ? {
            ...base,
            backgroundColor: multiValueHighlightColor,
          }
        : base
    },
    multiValueLabel: (base, { data }) => {
      const { isFixed } = data as FixedItemsSelectOption
      return isFixed
        ? {
            ...base,
            paddingLeft: '6px',
            paddingRight: '6px',
          }
        : base
    },
    multiValueRemove: (base, { data }) => {
      const { isFixed } = data as FixedItemsSelectOption
      return isFixed
        ? {
            ...base,
            display: 'none',
          }
        : base
    },
  }
  return (
    <Select
      isClearable={values?.some((v) => !v.isFixed)}
      isMulti={true}
      onChange={handleChange}
      options={options}
      styles={styles}
      value={values}
      components={components}
    />
  )
}
