import { TimeResolution } from '@capturi/core'
import { i18n } from '@lingui/core'
import { differenceInDays, isEqual, startOfYear } from 'date-fns'

export const xAxis =
  (resolution: TimeResolution, from: Date, to: Date) =>
  (date: Date, index: number): string => {
    if (!(date instanceof Date)) {
      return ''
    }
    if (resolution === 'Hours') {
      const daysCount = differenceInDays(to, from)
      if (daysCount < 2) {
        return i18n.date(date, { hour: '2-digit' })
      }
      if (index % 24 !== 0) return ' '
      return i18n.date(date, {
        day: 'numeric',
        month: 'short',
      })
    }
    if (resolution === 'Months') {
      const isFirstMonthOfYear = isEqual(date, startOfYear(date))
      return i18n.date(date, {
        month: 'short',
        year: isFirstMonthOfYear ? 'numeric' : undefined,
      })
    }
    return i18n.date(date, { day: 'numeric', month: 'short' })
  }

export const yAxis = {
  percent: (value: number): string => {
    if (!Number.isFinite(value)) return ''
    return i18n.number(value, {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    })
  },
  value: (value: number): string => {
    if (!Number.isFinite(value)) return ''
    return i18n.number(value, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    })
  },
}
