import { i18n } from '@lingui/core'
import React from 'react'

type Props = {
  value: number
  formatValue?: (value: number) => string
  bg: string
  color: string
  viewBox?: {
    x: number
    y: number
    width: number
    height: number
  }
}

const defaultFormat = (value: number): string => {
  const isWholeNumber = (value * 100) % 1 === 0
  return i18n.number(value, {
    style: 'percent',
    minimumFractionDigits: isWholeNumber ? 0 : 1,
    maximumFractionDigits: isWholeNumber ? 0 : 1,
  })
}

const ReferenceLineLabel: React.FC<Props> = ({
  viewBox,
  value,
  formatValue = defaultFormat,
  bg,
  color,
}) => {
  if (viewBox == null) return null
  const { width, x, y } = viewBox
  const labelHeight = 16
  const labelPadding = 4
  const boxSize = 8
  return (
    <g transform={`translate(${width + x}, ${y - labelHeight / 2})`}>
      <rect width="50" height={labelHeight} rx={labelHeight / 2} fill={bg} />
      <rect
        width={boxSize}
        height={boxSize}
        rx={boxSize / 3}
        x={labelPadding}
        y={labelHeight / 2 - boxSize / 2}
        fill={color}
        stroke="white"
        strokeWidth={1}
      />
      <text
        fill="white"
        textAnchor="start"
        fontSize="11px"
        dx={labelPadding + boxSize + 2}
        dy={labelHeight / 2}
        dominantBaseline="central"
      >
        {formatValue(value)}
      </text>
    </g>
  )
}

export default ReferenceLineLabel
