import { ShowModal, useModal } from '@capturi/use-modal'

import {
  DrawerContainer as ConversationDetailsDrawer,
  DrawerContainerProps,
} from './ConversationDetailsDrawer'

export { ConversationDetailsDrawer }

export function useConversationDetailsDrawer(): ShowModal<DrawerContainerProps> {
  const [open] = useModal(ConversationDetailsDrawer)
  return open
}
