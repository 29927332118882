import { RepeatCall } from '@capturi/api-filters'
import {
  getLocalizedRepeatCallLabel,
  getLocalizedRepeatCallName,
} from '@capturi/ui-components'
import React from 'react'

import { FilterValueLabel } from '../../components/FilterValueLabel'

export const RepeatCallsFilterValueLabel: React.FC<{ value?: RepeatCall }> = ({
  value,
}) => {
  return (
    <FilterValueLabel
      name={getLocalizedRepeatCallName(value?.type)}
      value={getLocalizedRepeatCallLabel(value?.value)}
    />
  )
}
