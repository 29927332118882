import analytics from '@capturi/analytics'
import { Button, Emoji, formatSeconds } from '@capturi/ui-components'
import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC, memo } from 'react'

import { WidgetSummary } from '../hooks/useLongConversationExamples'

type Props = {
  summary: WidgetSummary
  currentIndex: number
  onSummaryClick: (summaryUid: string) => void
}

export const LongConversationsExampleListItem: FC<Props> = memo(
  ({ summary, currentIndex, onSummaryClick }) => (
    <Flex
      w="100%"
      boxShadow="0px 16px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)"
      borderRadius={4}
      bg="accents.lightBackground.default"
      _hover={{
        bg: 'gray.100',
        transform: 'scale(1.02)',
        button: { textDecoration: 'underline' },
      }}
      transition="all 0.2s ease-in-out"
      cursor="pointer"
      onClick={() => {
        onSummaryClick(summary.uid)
        analytics.event('See_more_summary_conversation_navigate', {
          summaryUid: summary.uid,
        })
      }}
    >
      <Flex
        background="accents.darkBackground.default"
        w="8px"
        borderTopLeftRadius={4}
        borderBottomLeftRadius={4}
      />
      <HStack justify="space-between" w="100%" p={4} align="self-start">
        <VStack align="start" gap={0} h="100%">
          <HStack
            mb={2}
            gap={0}
            fontSize="lg"
            fontWeight="medium"
            align="baseline"
          >
            <Emoji as="span" fontSize="24px" mr={1} symbol={summary.emoji} />
            <Text>
              <Trans>#Example {currentIndex}</Trans>
            </Text>
          </HStack>
          <Text color="gray.600" mb={4}>
            {formatSeconds(summary.duration)} <Trans>Minutes</Trans>
          </Text>
          <Text
            color="gray.600"
            fontSize="md"
            whiteSpace="pre-wrap"
            noOfLines={4}
          >
            {summary.rootCause}
          </Text>
          <Button mt={2} primary variant="link">
            <Trans>See more</Trans>
          </Button>
        </VStack>
      </HStack>
    </Flex>
  ),
)
