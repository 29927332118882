import { useOrganization } from '@capturi/stores'
import { select } from '@lingui/macro'
import React from 'react'

import { FilterValueLabel } from '../../components/FilterValueLabel'

export const CustomerValueLabel: React.FC<{ value?: string[] }> = ({
  value,
}) => {
  const { organizationType } = useOrganization()
  return (
    <FilterValueLabel
      name={select(organizationType, {
        public: 'Citizen',
        other: 'Customer',
      })}
      value={value}
    />
  )
}
