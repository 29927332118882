import { SelectOption } from '@capturi/ui-select'
import { t } from '@lingui/macro'
import React from 'react'

const useGetPresetOptions = (): SelectOption[] => {
  const presetOptions = React.useMemo(() => {
    const presetOptionsArr: SelectOption[] = [
      { label: t`Private`, value: 'Private' },
      { label: t`Everyone can see`, value: 'EveryoneCanSee' },
      { label: t`Admins`, value: 'Admins' },
      { label: t`Admin & Team leads`, value: 'AdminTeamLeads' },
    ]
    return presetOptionsArr.map<SelectOption>((x: SelectOption) => ({
      value: x.value,
      label: x.label,
    }))
  }, [])
  return presetOptions
}
export default useGetPresetOptions
