import { Button } from '@capturi/ui-components'
import { Flex, Input } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useState } from 'react'

import {
  FilterCriteriaComponentBaseProps,
  FocusableElement,
} from '../../components/PhoneFilter/components/PhoneSegmentBuilder'

type ExternalIdentityInputProps = FilterCriteriaComponentBaseProps<string> & {
  initialFocusRef?: React.RefObject<FocusableElement>
}

export function ExternalIdentityInput(
  props: ExternalIdentityInputProps,
): React.ReactElement {
  const [value, setValue] = useState<string>(props.value || '')

  const handleSearch = (): void => {
    props.setValue?.(value.trim())
    props.onClose?.()
  }

  const handlePaste = (value: string): void => {
    props.setValue?.(value.trim())
  }

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  return (
    <Flex m="2">
      <Input
        ref={props.initialFocusRef as React.LegacyRef<HTMLInputElement>}
        onChange={(e) => setValue(e.currentTarget.value)}
        value={value}
        placeholder={t`External id`}
        onKeyDown={handleEnter}
        onPaste={(e) => handlePaste(e.clipboardData.getData('Text'))}
        borderTopRightRadius="0"
        borderBottomRightRadius="0"
        size="sm"
      />
      <Button
        borderTopLeftRadius="0"
        borderBottomLeftRadius="0"
        onClick={handleSearch}
      >
        <Trans>Search</Trans>
      </Button>
    </Flex>
  )
}
