import { Text, Tooltip } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { CellProps, ColumnWithLooseAccessor } from 'react-table'

import { MultiValueCell } from '../components/Cell'
import { ColumnDefinition, DataBasisColumn, DataType } from '../types'

const numberFormat: Intl.NumberFormatOptions = {
  style: 'decimal',
  maximumFractionDigits: 0,
}

function createTooltipText(value: number, total?: number): string {
  const formattedValue = i18n.number(value, numberFormat)
  if (total === undefined) {
    return t`${formattedValue} conversations`
  }
  const formattedTotal = i18n.number(total, numberFormat)
  return t`${formattedValue} out of ${formattedTotal} conversations`
}

function createOutputText(value: number, total?: number): string {
  const formattedValue = i18n.number(value, numberFormat)
  if (total === undefined) {
    return formattedValue
  }
  const formattedTotal = i18n.number(total, numberFormat)
  return `${formattedValue}/${formattedTotal}`
}

export default function dataBasisColumn<T extends DataType>(
  c: ColumnDefinition<T>,
  // biome-ignore lint/suspicious/noExplicitAny: legacy
): ColumnWithLooseAccessor<any> {
  return {
    id: c.id ?? 'dataBasis',
    accessor: (d: T) => d.segments,
    width: c.width ?? 64,
    Cell: function DataBasisCell({ row, column }: CellProps<T, string>) {
      const segments = row.values.dataBasis as T['segments']
      return (
        <MultiValueCell values={segments} alignRight={column.alignRight}>
          {(s) => {
            const segmentValue = (c as DataBasisColumn<T>).getSegmentValue(s)
            const value =
              typeof segmentValue === 'number'
                ? segmentValue
                : segmentValue.value
            const total =
              typeof segmentValue === 'number' ? undefined : segmentValue.total

            const textProps = {
              fontSize: 'sm',
              color: 'gray.600',
            }

            const tooltipText = createTooltipText(value, total)
            const outputText = createOutputText(value, total)
            return (
              <Tooltip label={tooltipText} key={s.label}>
                <Text {...textProps}>{outputText}</Text>
              </Tooltip>
            )
          }}
        </MultiValueCell>
      )
    },
    alignRight: true,
  }
}
