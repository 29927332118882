import { PostHogConfig, posthog } from 'posthog-js'

import { Tracker } from '../types'

export type PostHogInitConfig = {
  token?: string
  config: Partial<PostHogConfig>
}

export default function init({
  token,
  config,
}: PostHogInitConfig): Tracker | undefined {
  if (!token) return
  posthog.init(token, config)

  const tracker: Tracker = {
    event: (action, params) => posthog.capture?.(action, params),
    identify: (id, user, team, teamLeadsTeams) => {
      if (user) {
        const {
          id: uid,
          ownerUid,
          email,
          role,
          organizationId,
          title,
          isAdmin,
          isTeamLead,
          domain,
          teamUid,
          isDeleted,
          name,
          teamLeadTeamUids,
          inviteStatus,
        } = user
        posthog.identify?.(ownerUid, {
          id,
          uid,
          email,
          role,
          organizationId,
          title,
          isAdmin,
          isTeamLead,
          domain,
          teamUid,
          isDeleted,
          name,
          teamLeadTeamUids,
          inviteStatus,
          ...user.permissions,
          team,
          teamLeadsTeams,
        })
        if (organizationId) posthog.group('organizationId', organizationId)
        if (domain) posthog.group('domain', domain)
      }
    },
    modalview: (modalName) =>
      posthog.capture?.('screen_view', { screen_name: modalName }),
    pageview: (url) => !url?.includes('q=') && posthog.capture?.('$pageview'),
    reset: () => {
      try {
        posthog.reset?.()
      } catch (_e) {
        // Reset can fail if posthog isn't initialized yet
      }
    },
    record: (enable) => {
      if (enable) {
        posthog.startSessionRecording()
      } else {
        posthog.stopSessionRecording()
      }
    },
  }
  return tracker
}
