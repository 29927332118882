import React from 'react'
import { Column } from 'react-table'

import { DataGridColumn } from './DataGrid'
import columns from './columns'
import {
  BaseColumnDefinition,
  COLUMN_TYPES,
  ColumnDefinition,
  DataType,
} from './types'

function isColumnDef<T extends DataType>(
  c: ColumnDefinition<T> | Column<T>,
): c is BaseColumnDefinition<T> {
  return (
    (c as BaseColumnDefinition<T>).type !== undefined &&
    COLUMN_TYPES.includes((c as BaseColumnDefinition<T>).type)
  )
}

function useColumns<T extends DataType>(
  colDefs: DataGridColumn<T>[],
): Column<T>[] {
  return React.useMemo(() => {
    return colDefs.map<Column<T>>((colDef) => {
      if (isColumnDef(colDef)) {
        return columns[colDef.type](colDef)
      }
      return colDef as Column<T>
    })
  }, [colDefs])
}

export default useColumns
