import { Icon, useToast } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { useMemo } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { useLocalStorage } from 'react-use'

const STORAGE_KEY = 'PersonalQuestions'

export const useQuestionsLocalStorage = () => {
  const [questions = [], setQuestions] = useLocalStorage<string[]>(
    STORAGE_KEY,
    [],
  )
  const toast = useToast()

  return useMemo(() => {
    const addQuestion = (question: string) => {
      setQuestions([...questions, question])
    }

    const deleteQuestion = (question: string) => {
      const index = questions.indexOf(question)
      questions.splice(index, 1)
      setQuestions(questions)
      toast({
        title: t`Personal question removed`,
        status: 'success',
        icon: <Icon as={MdCheckCircle} boxSize={6} />,
      })
    }

    return {
      questions,
      addQuestion,
      deleteQuestion,
    }
  }, [questions, setQuestions, toast])
}
