import {
  CheckboxOption,
  GroupProps,
  MenuProps,
  MultiSelectFooter,
  OptionProps,
  components,
} from '@capturi/ui-select'
import { Box, Divider } from '@chakra-ui/react'
import React from 'react'

import { SearchResultRow } from './SearchResultRow'
import { SearchSelectOption } from './domain'

export const Option: React.ComponentType<
  OptionProps<SearchSelectOption, boolean>
> = (props) => {
  const { searchResult } = props.data as SearchSelectOption
  return (
    <components.Option {...props}>
      <CheckboxOption isSelected={props.isSelected}>
        <SearchResultRow searchResult={searchResult} />
      </CheckboxOption>
    </components.Option>
  )
}

type SearchInputMenuProps<Options, IsMulti extends boolean> = {
  onSubmit: () => void
  onReset: () => void
  formatSelectedCount: (count: number) => React.ReactElement
} & MenuProps<Options, IsMulti>

export const SearchInputMenu: React.ComponentType<
  SearchInputMenuProps<SearchSelectOption, boolean>
> = ({ onSubmit, onReset, formatSelectedCount, ...props }) => {
  return (
    <components.Menu {...props}>
      <Box overflow="hidden">
        {props.children}
        <MultiSelectFooter
          onReset={onReset}
          onSubmit={onSubmit}
          selectedCount={props.getValue().length}
          formatSelectedCount={formatSelectedCount}
        />
      </Box>
    </components.Menu>
  )
}

export const SearchInputGroup: React.ComponentType<
  GroupProps<SearchSelectOption, boolean>
> = ({ children, ...restProps }) => {
  return (
    <components.Group {...restProps}>
      {children}
      <Divider minH="4px" w="100%" />
    </components.Group>
  )
}
