import {
  PhraseSettings,
  PreviewTrackerHitsResponseModel,
  TimeFilterKind,
  trackersAPI,
} from '@capturi/api-trackers'
import request, { RequestOptions, getErrorObject } from '@capturi/request'
import { useLatest, useMountedState, usePrevious } from 'react-use'
import { phraseHitsCache, phraseHitsCacheMonitor } from './cache'
import { createPhraseStateHash, useCacheUpdater } from './cache-helpers'

import { Speaker } from '@capturi/core'
import { SearchParamsObject } from '@capturi/filters'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'
import uniqBy from 'lodash/uniqBy'
import React from 'react'
import { PhraseState } from '../../..'

export interface UseTrackerHitsPreviewResult {
  previewData: PreviewTrackerHitsResponseModel | null
  updatePreviewData: (additionalPhrases?: PhraseState[]) => void
  isFetchingPreviewData: boolean
}

function fetchData<T>(
  requestOptions: RequestOptions,
): [Promise<T>, () => void] {
  const controller = new AbortController()
  const { signal } = controller
  const promise = request<T>({
    ...requestOptions,
    signal,
  })
  return [promise, controller.abort.bind(controller)]
}

export function useTrackerHitsPreview(
  fields: PhraseState[],
  speakerId: Speaker,
  timeFilterKind: TimeFilterKind,
  timeFilterSeconds: number | undefined,
  timeFilterSecondsEnd: number | null,
  numberOfPhrases: number,
  filters: () => SearchParamsObject,
): UseTrackerHitsPreviewResult {
  const isMounted = useMountedState()
  const phraseHitsCache = useTrackerHitsCacheContext()
  const phraseHitsCacheMonitor = usePhraseHitsCacheMonitorContext()
  const cacheUpdater = useCacheUpdater(phraseHitsCacheMonitor)

  const [previewData, setPreviewData] =
    React.useState<PreviewTrackerHitsResponseModel | null>(null)
  const [isFetching, setIsFetching] = React.useState(false)
  const abortRequestRef = React.useRef<() => void>(noop)

  const phraseFieldsRef = useLatest(fields)
  const previousPhraseFields = usePrevious(phraseFieldsRef.current)

  const updatePreviewData = React.useCallback(async () => {
    const sanitizedPhrases = uniqBy(
      phraseFieldsRef.current
        // trim values
        .map((x) => ({ ...x, value: x.value.trim() }))
        // remove empty values
        .filter((x) => x.value.length > 0),
      (x) => x.value,
    )
    if (sanitizedPhrases.length === 0) {
      // Return if we do not have any phrases
      return
    }

    const hashes = sanitizedPhrases.reduce<{ [phrase: string]: string }>(
      (memo, p) => {
        memo[p.value] = createPhraseStateHash(
          p,
          speakerId,
          timeFilterKind,
          timeFilterSeconds,
          timeFilterSecondsEnd,
        )
        return memo
      },
      {},
    )

    const newOrChangedPhrases = sanitizedPhrases.filter((p) => {
      const cacheEntry = phraseHitsCache.get(p.value)
      const hash = hashes[p.value]
      // new = no cache entry
      // changed = hash changed
      return !cacheEntry || hash !== cacheEntry.hash
    })

    newOrChangedPhrases.forEach((p) => {
      const hash = hashes[p.value]
      cacheUpdater.markAsLoading(p.value, hash)
    })

    try {
      // Abort ongoing request
      abortRequestRef.current()
      setIsFetching(true)
      const [promise, abort] = fetchData<PreviewTrackerHitsResponseModel>(
        trackersAPI.previewTrackerHits({
          count: numberOfPhrases,
          phrases: sanitizedPhrases.map((x) => x.value),
          speakerId,
          filters: filters(),
          timeFilter: {
            kind: timeFilterKind,
            seconds: timeFilterSeconds,
            secondsEnd: timeFilterSecondsEnd,
          },
          phrasesSettings: sanitizedPhrases.reduce<
            Record<string, PhraseSettings>
          >((memo, phrase) => {
            memo[phrase.value] = {
              near: phrase.settings.near ?? null,
              notNear: phrase.settings.notNear ?? null,
              precision: phrase.settings.precision ?? null,
            }
            return memo
          }, {}),
        }),
      )
      abortRequestRef.current = abort
      const resp = await promise
      if (isMounted()) {
        cacheUpdater.setCacheResults(hashes, resp)
        setPreviewData(resp)
        setIsFetching(false)
      }
    } catch (e) {
      const error = await getErrorObject(e)
      if (error.name === 'AbortError') {
        // nevermind, on purpose
        return
      }
      if (isMounted()) {
        setIsFetching(false)
      }
    }
  }, [
    phraseFieldsRef,
    speakerId,
    timeFilterKind,
    timeFilterSeconds,
    timeFilterSecondsEnd,
    phraseHitsCache,
    cacheUpdater,
    numberOfPhrases,
    filters,
    isMounted,
  ])

  // biome-ignore lint/correctness/useExhaustiveDependencies:  Trigger calculation when either speaker or time filter changes
  React.useEffect(() => {
    // Trigger calculation when either speaker or time filter changes
    updatePreviewData()
  }, [updatePreviewData, speakerId, timeFilterKind, timeFilterSeconds])

  React.useEffect(() => {
    const omitBlankPhrases = (list: PhraseState[]): PhraseState[] => {
      return list.filter((x) => !isEmpty(x.value.trim()))
    }
    // Trigger calculation when any phrases (or their configurations) change
    if (
      !isEqual(
        omitBlankPhrases(fields),
        omitBlankPhrases(previousPhraseFields ?? []),
      )
    ) {
      updatePreviewData()
    }
  }, [updatePreviewData, fields, previousPhraseFields])

  return {
    previewData,
    // Update explicitly, e.g. in a phrase field input onBlur callback
    updatePreviewData,
    isFetchingPreviewData: isFetching,
  }
}

const TrackerHitsCacheContext = React.createContext(phraseHitsCache)
export const useTrackerHitsCacheContext = (): typeof phraseHitsCache =>
  React.useContext(TrackerHitsCacheContext)

const PhraseHitsCacheMonitorContext = React.createContext(
  phraseHitsCacheMonitor,
)
export const usePhraseHitsCacheMonitorContext =
  (): typeof phraseHitsCacheMonitor =>
    React.useContext(PhraseHitsCacheMonitorContext)
