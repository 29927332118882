import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

import ListContext from './ListContext'

export type ListProps = {
  disablePadding?: boolean
  dense?: boolean
} & BoxProps

export const List = React.forwardRef<HTMLDivElement, ListProps>(function List(
  { disablePadding = false, dense = false, ...props },
  ref,
) {
  const context = React.useMemo(
    () => ({ dense, disableGutters: false }),
    [dense],
  )
  return (
    <ListContext.Provider value={context}>
      <Box
        ref={ref}
        as="ul"
        listStyleType="none"
        listStyleImage="initial"
        listStylePosition="initial"
        position="relative"
        py={disablePadding ? 0 : 2}
        {...props}
      >
        {props.children}
      </Box>
    </ListContext.Provider>
  )
})
