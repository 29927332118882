import { FilterPeriodSelectContainer } from '@capturi/filters'
import { usePageTitle } from '@capturi/react-utils'
import { Caption, PageHeading } from '@capturi/ui-components'
import { Box, Flex } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

const Workspace = React.lazy(() => import('./components/Workspace'))

const HitRatePage: React.FC = () => {
  usePageTitle(t`Hit rate`)
  return (
    <>
      <Flex>
        <Box mr={4}>
          <PageHeading w="fit-content" data-stonly="hitrate-header">
            <Trans>Hit rate</Trans>
          </PageHeading>
          <Caption color="textMuted">
            <Trans>
              The percentage of conversations that match the selected filter in
              relation to the selected base data
            </Trans>
          </Caption>
        </Box>
        <Box ml="auto">
          <FilterPeriodSelectContainer />
        </Box>
      </Flex>
      <Flex flex={1} mt={{ base: 4, lg: 5 }}>
        <Workspace />
      </Flex>
    </>
  )
}

export default HitRatePage
