import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

type Folder = {
  uid: string
  title: string | null
}

type FolderMenuProps = {
  folders: Folder[] | undefined
  isVisible?: boolean
  moveToFolder: (newFolderUid: string | null) => void
  currentFolderUid: string | null | undefined
  MenuButtonComponent?: React.ElementType
}

export const FolderMenu: React.FC<FolderMenuProps> = React.memo(
  ({
    folders,
    isVisible = true,
    moveToFolder,
    currentFolderUid,
    MenuButtonComponent,
  }) => {
    return (
      <Menu isLazy placement={'bottom-start'}>
        {({ isOpen }) => (
          <>
            <MenuButton as={MenuButtonComponent} isVisible={isVisible} />
            {isOpen && (
              <Portal>
                <MenuList maxH="40vh" overflowY="auto">
                  <MenuItem
                    key="root-folder"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      moveToFolder?.(null)
                    }}
                  >
                    <Flex align="center">
                      <Text
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        width="184px"
                        fontSize="sm"
                        ml={2}
                        fontWeight={
                          currentFolderUid == null ? 'bold' : 'normal'
                        }
                      >
                        <Trans>[No folder]</Trans>
                      </Text>
                    </Flex>
                  </MenuItem>
                  {folders && folders.length > 0 ? (
                    folders.map((folder) => {
                      return (
                        <MenuItem
                          key={folder.uid}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            moveToFolder?.(folder.uid)
                          }}
                        >
                          <Flex align="center">
                            <Text
                              textOverflow="ellipsis"
                              overflow="hidden"
                              whiteSpace="nowrap"
                              width="184px"
                              fontSize="sm"
                              ml={2}
                              fontWeight={
                                currentFolderUid === folder.uid
                                  ? 'bold'
                                  : 'normal'
                              }
                            >
                              {folder.title}
                            </Text>
                          </Flex>
                        </MenuItem>
                      )
                    })
                  ) : (
                    <Flex justifyContent="center">
                      <Text fontSize="xs" color="gray.600">
                        <Trans>No folders</Trans>
                      </Text>
                    </Flex>
                  )}
                </MenuList>
              </Portal>
            )}
          </>
        )}
      </Menu>
    )
  },
)
