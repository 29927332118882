import { Comment, CreateCommentPayload } from '@capturi/api-comments'
import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

type CommentRequestPayload = {
  conversationUid: string
  payload: CreateCommentPayload
}

export const useComments = (
  conversationUid: string,
): UseQueryResult<Comment[], ResponseError> =>
  useQuery({
    queryKey: ['comments', conversationUid],
    queryFn: async () => {
      const result = await request.get<{ comments: Comment[] }>(
        `conversations/${conversationUid}/comments?api-version=3.3`,
      )
      return result.comments
    },
  })

export const addNewComment = (): UseMutationResult<
  Comment,
  ResponseError,
  CommentRequestPayload,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ conversationUid, payload }) => {
      return request.post<Comment>(
        `conversations/${conversationUid}/comment?api-version=3.3`,
        {
          json: payload,
        },
      )
    },
    onSuccess: (newComment) => {
      queryClient.setQueryData<Comment[]>(
        ['comments', newComment.conversationUid],
        (oldData) => {
          if (!oldData) {
            return [newComment]
          }
          return [newComment, ...oldData]
        },
      )
    },
  })
}
