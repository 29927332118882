import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

export type EmojiProps = BoxProps & {
  label?: string
  symbol: string
}

export function Emoji(props: EmojiProps): React.ReactElement {
  const { label, symbol, ...restProps } = props
  return (
    <Box
      as="span"
      aria-hidden={label ? undefined : true}
      aria-label={label ? label : undefined}
      role="img"
      {...restProps}
    >
      {symbol}
    </Box>
  )
}
