import { DependentItem } from '@capturi/api-shared'
import request, { ResponseError } from '@capturi/request'
import {
  UseQueryResult,
  UseSuspenseQueryResult,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query'

import { InaccessibleScore, Score, ScoreSummary } from '../models'
import { baseUrl, cacheKey } from './constants'

export type ScoresResponse = {
  scores: Score[]
}

type InaccessibleScoreResponse = {
  scores: (Score | InaccessibleScore)[]
}

type ScoreDeps = {
  dependents: DependentItem[]
}

const selectorOneAccessible =
  (uid: string | undefined): ((data: Score) => Score | undefined) =>
  (data) => {
    if (uid) return data
  }

export const useScore = (
  uid: string | undefined,
  refetchInterval?: number,
): UseQueryResult<Score | undefined, ResponseError> =>
  useQuery({
    queryKey: [cacheKey, uid],
    queryFn: () => request.get<Score>(`scores/${uid}/?api-version=3.3`),
    select: selectorOneAccessible(uid),
    enabled: !!uid,
    refetchInterval: refetchInterval ?? false,
  })

const selectorOnlyAccessible = (data: ScoresResponse): Score[] => {
  return data.scores.filter((d) => d.accessLevel !== 'None')
}
export const useScores = (
  refetchInterval?: number,
): UseSuspenseQueryResult<Score[], ResponseError> =>
  useSuspenseQuery({
    queryKey: [cacheKey],
    queryFn: () =>
      request.get<ScoresResponse>(`${baseUrl}&includeInaccessible=true`),
    select: selectorOnlyAccessible,
    refetchInterval: refetchInterval,
  })

const selectorAll = (
  data: InaccessibleScoreResponse,
): (Score | InaccessibleScore)[] => {
  return data.scores
}
export const useAllScores = (): UseSuspenseQueryResult<
  (Score | InaccessibleScore)[],
  ResponseError
> =>
  useSuspenseQuery({
    queryKey: [cacheKey],
    queryFn: () =>
      request.get<InaccessibleScoreResponse>(
        `${baseUrl}&includeInaccessible=true`,
      ),
    select: selectorAll,
  })

export const useScoreSummaries = (period: {
  from: Date
  to: Date
}): UseQueryResult<ScoreSummary[], ResponseError> =>
  useQuery({
    queryKey: ['scoreSummaries', period],
    queryFn: async () => {
      const result = await request.post<{ scores: ScoreSummary[] }>(
        'insights/scores?&api-version=3.3',
        {
          json: {
            fromInclusive: period.from,
            toInclusive: period.to,
          },
        },
      )
      return result.scores
    },
  })

const selectorDeps = (data: ScoreDeps): DependentItem[] => {
  return data.dependents
}

export const useScoreDeps = (
  uid: string | undefined,
): UseQueryResult<DependentItem[] | undefined, ResponseError> =>
  useQuery({
    queryKey: [uid],
    queryFn: () =>
      request.get<ScoreDeps>(`scores/${uid}/dependents/?api-version=3.3`),
    select: selectorDeps,
    enabled: !!uid,
  })
