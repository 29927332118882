import {
  InaccessibleFilter,
  PhoneFilterValues,
  SavedPhoneFilter,
  useUpdateSavedFilter,
} from '@capturi/api-filters'
import { useToast } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { t } from '@lingui/macro'

import { CreateUpdateSegmentDialog } from '../components/SavedFilterDialogs'
import { toSavedFilterValues } from '../mappers'

export type SaveFilterStateOptions = {
  savedFilter?: SavedPhoneFilter
  onFilterSaved?: (isNew: boolean, savedFilter: SavedPhoneFilter) => void
}

function useSaveFilterState(options?: SaveFilterStateOptions): {
  save: (state: Partial<PhoneFilterValues>) => void
  saveNew: (
    state: PhoneFilterValues | undefined,
    onSaved?: (savedFilter: SavedPhoneFilter | InaccessibleFilter) => void,
  ) => void
} {
  const toast = useToast()
  const { mutate: updateFilter } = useUpdateSavedFilter()
  const [openCreateUpdateSegmentDialog] = useModal(CreateUpdateSegmentDialog)

  const onSaveFilter = (state: Partial<PhoneFilterValues>): null => {
    if (options?.savedFilter == null) return null
    // map state to request model
    const values = toSavedFilterValues(state)
    const newSavedFilter = { ...options.savedFilter, values: values }

    updateFilter(
      { uid: newSavedFilter.uid, filter: newSavedFilter },
      {
        onSuccess: () => {
          toast({
            title: t`Segment saved`,
            status: 'success',
          })
          options?.onFilterSaved?.(false, newSavedFilter)
        },
        onError: (error) => {
          toast({
            title: t`An error occurred`,
            description: error.message,
            status: 'error',
          })
        },
      },
    )
    return null
  }

  const onSaveNewFilter = (state: PhoneFilterValues | undefined): void => {
    openCreateUpdateSegmentDialog({
      state,
      onFilterSaved: (isNew, savedFilter) =>
        options?.onFilterSaved?.(isNew, savedFilter),
    })
  }

  return {
    save: onSaveFilter,
    saveNew: onSaveNewFilter,
  }
}

export default useSaveFilterState
