import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import { useNotificationsContext } from '../../contexts/useNotifications'
import CounterBadge from '../CounterBadge'
import Notifications from '../Notifications'
import NotificationsButton from './NotificationsButton'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop: () => void = () => {}

const modifiers = [
  {
    name: 'preventOverflow',
    options: {
      altAxis: true,
      padding: 32,
    },
  },
]

const NotificationCenter: React.FC = () => {
  const { unreadCount, markAllAsRead } = useNotificationsContext()

  return (
    <Popover modifiers={modifiers}>
      {({ onClose = noop }) => (
        <>
          <PopoverTrigger>
            <NotificationsButton aria-label={t`Show notifications`}>
              {unreadCount > 0 && <CounterBadge count={unreadCount} />}
            </NotificationsButton>
          </PopoverTrigger>
          <Portal>
            <PopoverContent zIndex={4} maxW="19.5em">
              <PopoverArrow />
              <PopoverHeader display="flex" justifyContent="space-between">
                <Text>
                  <Trans>Notifications</Trans>
                </Text>
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => {
                    markAllAsRead()
                    onClose()
                  }}
                  isDisabled={unreadCount === 0}
                >
                  <Trans>Mark all as read</Trans>
                </Button>
              </PopoverHeader>
              <PopoverBody p={0}>
                <Notifications onClose={onClose} />
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  )
}

export default NotificationCenter
