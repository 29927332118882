import {
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps,
  HTMLChakraProps,
  SystemProps,
  chakra,
} from '@chakra-ui/react'
import React from 'react'

export type FormLabelProps = {
  leftIcon?: React.ReactElement
  rightIcon?: React.ReactElement
  iconSpacing?: SystemProps['marginRight']
} & ChakraFormLabelProps

export const FormLabel: React.FC<FormLabelProps> = ({
  leftIcon,
  rightIcon,
  iconSpacing = 2,
  children,
  ...restProps
}) => {
  return (
    <ChakraFormLabel display="flex" alignItems="center" {...restProps}>
      {leftIcon && (
        <FormLabelIcon mr={iconSpacing} className="field-label__left-icon">
          {leftIcon}
        </FormLabelIcon>
      )}
      <chakra.span className="field-label__text">{children}</chakra.span>
      {rightIcon && (
        <FormLabelIcon ml={iconSpacing} className="field-label__right-icon">
          {rightIcon}
        </FormLabelIcon>
      )}
    </ChakraFormLabel>
  )
}

const FormLabelIcon: React.FC<HTMLChakraProps<'span'>> = (props) => {
  const { children, ...restProps } = props

  const _children = React.isValidElement(children)
    ? React.cloneElement(children as React.ReactElement, {
        'aria-hidden': true,
        focusable: false,
      })
    : children

  return <chakra.span {...restProps}>{_children}</chakra.span>
}
