import { TimeResolution } from '@capturi/core'
import { RequestOptions } from '@capturi/request'

import { baseQuery, controller } from '../constants'

export const interactionInsightsAPI = {
  getInteractionSummaries: (): RequestOptions => ({
    url: `${controller}/summaries/interaction`,
    query: baseQuery,
    method: 'post',
  }),
  getSpeakerDistributionStats: (): RequestOptions => ({
    url: `${controller}/speaker-distribution/organization`,
    query: baseQuery,
    method: 'post',
  }),
  getSpeakerDistributionStatsByTeam: (): RequestOptions => ({
    url: `${controller}/speaker-distribution/organization/by-team`,
    query: baseQuery,
    method: 'post',
  }),
  getInteractionStatisticsTimeSeries: (
    resolution: TimeResolution,
    offset: number,
  ): RequestOptions => ({
    url: `${controller}/interaction/time-series`,
    query: {
      ...baseQuery,
      resolution,
      offset,
    },
    method: 'post',
  }),
  getSpeakingRateStats: (): RequestOptions => ({
    url: `${controller}/speak_rate/organization`,
    query: baseQuery,
    method: 'post',
  }),
  getSpeakingRateStatsByTeam: (): RequestOptions => ({
    url: `${controller}/speak_rate/organization/by-team`,
    query: baseQuery,
    method: 'post',
  }),
  getLongestMonologueStats: (): RequestOptions => ({
    url: `${controller}/longest_monologue/organization`,
    query: baseQuery,
    method: 'post',
  }),
  getLongestMonologueStatsByTeam: (): RequestOptions => ({
    url: `${controller}/longest_monologue/organization/by-team`,
    query: baseQuery,
    method: 'post',
  }),
}
