import { Box, BoxProps, useTheme } from '@chakra-ui/react'
import { getColor } from '@chakra-ui/theme-tools'
import React from 'react'

type HexValueProps = {
  hex: string
  color?: never
  hue?: never
}

type ColorHueProps = {
  hex?: never
  color: string
  hue?: string | number
}

type Props = (HexValueProps | ColorHueProps) & BoxProps

export const ColorLabel: React.FC<Props> = ({
  color,
  hue = 500,
  hex,
  ...props
}) => {
  const theme = useTheme()

  return (
    <Box
      role="presentation"
      display="inline-block"
      flexShrink={0}
      boxSize={2}
      bg={hex ? hex : getColor(theme, `${color}.${hue}`)}
      borderRadius="sm"
      aria-hidden
      {...props}
    />
  )
}
