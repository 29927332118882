export default {
  baseStyle: {
    fontWeight: 'normal',
    lineHeight: 1.2,
    letterSpacing: '0.02em',
  },
  defaultProps: {
    size: 'lg',
  },
  sizes: {
    '4xl': {
      fontSize: ['4xl', null, '5xl'],
      lineHeight: 1,
    },
    '3xl': {
      fontSize: ['3xl', null, '4xl'],
      lineHeight: 1,
    },
    '2xl': {
      fontSize: ['2xl', null, '3xl'],
      lineHeight: [1.2, null, 1],
    },
    xl: { fontSize: 'xl', lineHeight: 1.2 },
    lg: { fontSize: 'lg', lineHeight: 1.2 },
    md: { fontSize: 'md', lineHeight: 1.2 },
    sm: { fontSize: 'sm', lineHeight: 1.2 },
    xs: { fontSize: 'xs', lineHeight: 1.2 },
  },
}
