import analytics from '@capturi/analytics'
import { AdversusStatus } from '@capturi/api-conversations'
import { PreviewTrackerHitsResponseModel } from '@capturi/api-trackers'
import { AudioProvider, formatTime, useAudioContext } from '@capturi/audio'
import { Speaker, useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { useUsers } from '@capturi/stores'
import { Button, List } from '@capturi/ui-components'
import { ShowModal, useModal } from '@capturi/use-modal'
import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { useConversationDetailsDrawer } from 'components/ConversationDetailsDrawer'
import { PlayListItem } from 'components/PlayList'
import omit from 'lodash/omit'
import React from 'react'
import { MdLaunch } from 'react-icons/md'
import translateAdversusStatus from 'utils/translateAdversusStatus'

import format from '../../../utils/numberFormat'

const PAGE_SIZE = 30
const dateFormat: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
}

type ConversationsDrawerProps = {
  data: PreviewTrackerHitsResponseModel
  drawerTitle?: string
}

export type DrawerContainerProps = Omit<DrawerProps, 'children'> &
  ConversationsDrawerProps

export const ConversationsDrawerContainer: React.FC<DrawerContainerProps> = ({
  isOpen,
  onClose,
  data,
  drawerTitle,
}) => {
  return (
    <AudioProvider>
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottom="1px"
            borderBottomColor="gray.200"
            lineHeight={1.4}
            display="flex"
            alignItems="center"
            pr={10}
          >
            <Box flex="1">
              <Text fontSize="sm" color="textMuted">
                <Trans>
                  {format(data.conversations)} hits out of the{' '}
                  {format(data.conversationsTotal)} most recent conversations
                </Trans>
              </Text>
              <Text fontSize="1rem">
                {drawerTitle ?? <Trans> Conversations</Trans>}
              </Text>
            </Box>
          </DrawerHeader>
          <DrawerBody overflow="hidden" p={0}>
            <DrawerBodyContent data={data} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </AudioProvider>
  )
}

type ConversationEntryType = Omit<
  PreviewTrackerHitsResponseModel['phrases'][number]['conversations'][number],
  'hits'
> & {
  phrases: {
    id: string
    phrase: string
    speakerId: Speaker
    speakerUserUid: string | null
    when: number
  }[]
}

function generateConversationsList(
  phrases: PreviewTrackerHitsResponseModel['phrases'],
): ConversationEntryType[] {
  const conversationsMap = phrases.reduce<{
    [uid: string]: ConversationEntryType
  }>((memo, x) => {
    const { phrase, conversations } = x
    conversations.forEach((c) => {
      if (memo[c.uid] === undefined) {
        const conversation = omit(c, ['hits'])
        memo[c.uid] = {
          ...conversation,
          phrases: [],
        }
      }
      memo[c.uid].phrases = [
        ...memo[c.uid].phrases,
        ...c.hits.map((hit) => ({
          id: `${phrase}:${hit.when}`,
          phrase,
          ...hit,
        })),
      ].sort((a, b) => a.when - b.when)
    })
    return memo
  }, {})
  return Object.values(conversationsMap).sort(
    (a, b) => b.dateTime.getTime() - a.dateTime.getTime(),
  )
}

const DrawerBodyContent: React.FC<ConversationsDrawerProps> = ({ data }) => {
  const [pageNumber, setPageNumber] = React.useState(1)
  const { showStatus } = useFeatureFlags()
  const { getTime, pause, playbackContext } = useAudioContext()
  const user = useCurrentUser()
  const { getUserByUid } = useUsers()

  const maxPage = data.conversations / PAGE_SIZE
  const hasNextPage = maxPage > pageNumber

  const conversations = React.useMemo(() => {
    return generateConversationsList(data.phrases).slice(
      0,
      PAGE_SIZE * pageNumber,
    )
  }, [data.phrases, pageNumber])

  const openConversationDetailsDrawer = useConversationDetailsDrawer()
  const onGoToConversation = (uid: string): void => {
    analytics.event('analytics_Conversations_GoToConversation')

    React.startTransition(() => {
      let initialAudioTimestamp: number | undefined
      if (playbackContext?.conversationUid === uid) {
        initialAudioTimestamp = getTime()
        pause()
      }
      openConversationDetailsDrawer({
        uid,
        initialAudioTimestamp,
      })
    })
  }

  return (
    <Box h="full" overflow="auto">
      <List>
        {conversations.map((c) => {
          return (
            <Box key={c.uid}>
              <Flex pl={4} pr={2} py={2} bg="gray.50">
                <Box flex="1" pr={1}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text fontSize="md" fontWeight="medium">
                      {c.customer}
                    </Text>
                    <IconButton
                      aria-label={t`Go to conversation`}
                      title={t`Go to conversation`}
                      variant="ghost"
                      size="xs"
                      icon={<MdLaunch />}
                      isRound
                      onClick={() => onGoToConversation(c.uid)}
                    />
                  </Flex>
                  <Flex fontSize="sm" color="gray.600" align="center" pr={1}>
                    <Text as="span" flexShrink={0}>
                      {i18n.date(c.dateTime, dateFormat)}
                    </Text>
                    <Divider orientation="vertical" mx={2} minH={3} />
                    <Text>{getUserByUid(c.userUid).name}</Text>
                    {showStatus && (
                      <Text
                        noOfLines={1}
                        wordBreak="break-all"
                        flex="1 0 auto"
                        textAlign="right"
                        pl={2}
                      >
                        {translateAdversusStatus(c.status as AdversusStatus)}
                      </Text>
                    )}
                  </Flex>
                </Box>
              </Flex>
              <List disablePadding>
                {c.phrases.map((hit) => (
                  <PlayListItem
                    hasAudio={c.hasAudio}
                    key={hit.id}
                    isDisabled={!user.permissions.playback || c.deleted}
                    timestamp={hit.when}
                    rollbackSeconds={3}
                    onPlay={() =>
                      analytics.event('analytics_EditTracker_PlayAudio', {
                        date: c.dateTime.toUTCString(),
                        uid: hit.id,
                      })
                    }
                    context={{
                      conversationUid: c.uid,
                      hitId: hit.id,
                    }}
                    showAudioPlayer
                  >
                    <Flex align="center" justify="space-between" flex={1}>
                      <Text flex={1}>
                        <em>{hit.phrase}</em>
                      </Text>
                      <Text flex={0} ml={4} color="textMuted" fontSize="sm">
                        {formatTime(hit.when)}
                      </Text>
                    </Flex>
                  </PlayListItem>
                ))}
              </List>
            </Box>
          )
        })}
        <Flex justifyContent="center" py={2} px={4}>
          <Button
            onClick={() => {
              if (hasNextPage) {
                setPageNumber((x) => x + 1)
              }
            }}
            isDisabled={!hasNextPage}
            size="sm"
          >
            {!hasNextPage ? (
              <Trans>No more conversations</Trans>
            ) : (
              <Trans>Load more</Trans>
            )}
          </Button>
        </Flex>
      </List>
    </Box>
  )
}

export function useConversationsDrawer(): ShowModal<DrawerContainerProps> {
  const [open] = useModal(ConversationsDrawerContainer)
  return open
}
