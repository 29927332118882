import { useAPI } from '@capturi/api-utils'
import { Speaker } from '@capturi/core'
import { useDebounce } from '@capturi/react-utils'
import { useMemo } from 'react'

import { conversationsAPI } from '../api'

export type WordSearchResult = {
  words: string[]
  timestamp: number
  speakerId: number
}

export type WordSearch = {
  results: WordSearchResult[]
  isSearching: boolean
  hasNoResults: boolean
}

export type WordSearchResponseModel = {
  searchResults: {
    phrase: string
    otherPersonOffsets: number[]
    salesPersonOffsets: number[]
  }[]
}

export function useWordSearch(
  conversationUid: string,
  query: string,
  debounceDelay = 300,
  minCharacters = 1,
): WordSearch {
  const debouncedQuery = useDebounce(query, debounceDelay)

  const { data, isValidating } = useAPI<WordSearchResponseModel>(
    () => {
      if (debouncedQuery.length >= minCharacters) {
        return conversationsAPI.wordSearch(conversationUid, debouncedQuery)
      }
      return null
    },
    () => ({
      query: debouncedQuery.toLowerCase(),
    }),
    {
      suspense: false,
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 10,
      focusThrottleInterval: 1000 * 60 * 10,
    },
  )

  const results = useMemo(() => {
    if (data == null || debouncedQuery.length === 0) {
      return []
    }

    return data.searchResults
      .reduce<WordSearchResult[]>((total, x) => {
        const salesPersonOffsets =
          x.salesPersonOffsets?.map((offset) => ({
            words: [x.phrase],
            timestamp: offset,
            speakerId: Speaker.Employee,
          })) || []
        const otherPersonOffsets =
          x.otherPersonOffsets?.map((offset) => ({
            words: [x.phrase],
            timestamp: offset,
            speakerId: Speaker.Customer,
          })) || []

        // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
        return [...total, ...salesPersonOffsets, ...otherPersonOffsets]
      }, [])
      .sort((a, b) => a.timestamp - b.timestamp)
  }, [data, debouncedQuery])

  const hasNoResults = useMemo(() => {
    return (
      !isValidating &&
      debouncedQuery.length >= minCharacters &&
      results.length === 0
    )
  }, [isValidating, debouncedQuery, results, minCharacters])

  return {
    results,
    isSearching: isValidating,
    hasNoResults,
  }
}
