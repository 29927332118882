import { AdversusStatus } from '@capturi/api-conversations'
import { Tag, TagLabel, TagProps } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'

import translateAdversusStatus from 'utils/translateAdversusStatus'

type colorScheme = 'danger' | 'warning' | 'success' | 'gray'

const getcolorScheme = (status?: AdversusStatus): colorScheme => {
  if (status === 'success') return 'success'
  if (status === 'privateRedial' || status === 'automaticRedial')
    return 'warning'
  if (status === 'unqualified' || status === 'notInterested') return 'danger'
  return 'gray'
}

const StatusLabel: React.FC<{ status?: AdversusStatus } & TagProps> = ({
  status,
  ...props
}) => {
  if (!status || status === 'unknown') return null

  return (
    <Tag
      backgroundColor={getcolorScheme(status)}
      variant="solid"
      size="sm"
      {...props}
    >
      <TagLabel title={t`Status`}>{translateAdversusStatus(status)}</TagLabel>
    </Tag>
  )
}

export default StatusLabel
