import { generateImageUrl } from '@capturi/request'
import { User } from '@capturi/stores'
import { Avatar, AvatarGroup, HStack, Text, VStack } from '@chakra-ui/react'
import React from 'react'

// User avatars + title + description

export const UserListView: React.FC<{
  title: string
  description: React.ReactNode
  users: User[] | undefined
}> = ({ title, description, users }) => {
  return (
    <VStack align="start" spacing={0}>
      <HStack spacing={1}>
        {users && users.length > 0 && (
          <AvatarGroup spacing="-0.3rem" max={4} variant="sharing-search">
            {users?.map((user) => (
              <Avatar
                key={user.uid}
                name={user.name}
                src={generateImageUrl(user.profileImage?.key, { size: 18 })}
                aria-label={user.name}
                variant="sharing-search"
              />
            ))}
          </AvatarGroup>
        )}
        <Text m="0" fontSize="sm" fontWeight="medium">
          {title}
        </Text>
      </HStack>
      <Text color="gray.600" fontSize="sm">
        {description}
      </Text>
    </VStack>
  )
}
