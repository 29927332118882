import { isSameDay, isValid, parseISO } from 'date-fns'

import { NamedPeriod, PeriodDefinitions } from './constants'
import {
  FixedPeriodDefinition,
  PeriodDefinition,
  parseFixedDateTimePeriod,
  parseFixedPeriod,
  parseURLPeriod,
} from './period'

export function parseInitialPeriod(
  period: PeriodDefinition | NamedPeriod | string | undefined,
  fallbackPeriod: PeriodDefinition,
): PeriodDefinition {
  if (period === undefined || period === null) return fallbackPeriod

  if (typeof period === 'string') {
    // 1. Parse as Date
    const parsedDate = parseISO(period)

    if (isValid(parsedDate)) {
      return new FixedPeriodDefinition(parsedDate, parsedDate)
    }
    // 2. Parse as NamedPeriod
    if (Object.values(NamedPeriod).includes(period as NamedPeriod)) {
      return PeriodDefinitions[period as NamedPeriod]
    }
    // 3. Parse as FixedPeriod
    const parsedPeriod = parseURLPeriod(period)
    if (parsedPeriod) {
      if (isSameDay(parsedPeriod[0], parsedPeriod[1])) {
        // If the period is a single day, use FixedDateTimePeriodDefinition
        return parseFixedDateTimePeriod(period) ?? fallbackPeriod
      }
      return parseFixedPeriod(period) ?? fallbackPeriod
    }

    return fallbackPeriod
  }
  return period
}
