import request, { ResponseError } from '@capturi/request'
import { useQuery } from '@tanstack/react-query'

type UseKeyTopicsDigestProps = {
  widgetType: 'topChanges' | 'topTimeConsumers' | 'repeatCalls'
  keyTopicsSessionUid: string | undefined
  keyTopicClusterId: string | undefined
}

export type KeyTopicsDigestTopDriversResponseModel = { digest: string }

export const useSharedDigest = ({
  keyTopicsSessionUid,
  keyTopicClusterId,
  widgetType,
}: UseKeyTopicsDigestProps) =>
  useQuery<KeyTopicsDigestTopDriversResponseModel, ResponseError>({
    queryKey: [
      'keyTopics',
      'session',
      keyTopicsSessionUid,
      'digest',
      widgetType,
      'cluster',
      keyTopicClusterId,
    ],
    queryFn: async () =>
      await request.get<KeyTopicsDigestTopDriversResponseModel>(
        `keytopics/${keyTopicsSessionUid}/${keyTopicClusterId}/digest/${widgetType}?api-version=3.3`,
        {
          retryOnNoContent: true,
        },
      ),
    enabled:
      keyTopicClusterId !== undefined && keyTopicsSessionUid !== undefined,
    staleTime: 1000 * 60 * 60 * 24, // 1 day
  })
