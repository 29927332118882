import { GrantedLevel } from '@capturi/core'
import { Flex, HStack, IconButton, Spacer } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { MdClose } from 'react-icons/md'

import GrantedLevelSelect from '../../GrantedLevelSelect'
import { UserView } from '../../UserView'
import { UserEntry } from '../domain'

export const AclUserRow: React.FC<{
  userEntry: UserEntry
  onChangeGrantedLevel: (grantedLevel: GrantedLevel) => void
  onRemove: () => void
}> = ({ userEntry, onChangeGrantedLevel, onRemove }) => {
  if (userEntry.userDetails === undefined) return null

  return (
    <Flex px={2} py={1} align="center" w="100%" gap={1}>
      <HStack columnGap={1} overflow="hidden">
        <UserView user={userEntry.userDetails} />
      </HStack>
      <Spacer />
      <GrantedLevelSelect
        value={userEntry.level}
        onChange={onChangeGrantedLevel}
      />
      <IconButton
        icon={<MdClose />}
        aria-label={t`Remove`}
        variant="ghost"
        onClick={onRemove}
      />
    </Flex>
  )
}
