import analytics from '@capturi/analytics'

import { ConversationPageView } from '../../pages/Conversations/routes'

export enum Event {
  TabClicked = 'conversationDetails_tabClicked_contents',
  GoToPreviousConversation = 'conversationDetails_history_goToConversation',
  HitMarkTooltipOpened = 'conversationDetails_hitmark_tooltipOpened',
}

export const log = (event: Event): void => {
  analytics.event(event)
}

export const logTabClicked = (name: ConversationPageView): void => {
  analytics.event(Event.TabClicked, { name })
}
