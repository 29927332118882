import type {
  KeySubTopicCluster,
  KeyTopicsReadyResponse,
  KeyTopicsResponse,
} from '../../KeyTopics/hooks/types'
import { KeyTopicsSessionSelector } from '../types'

const getThreeTopSubTopicClusters = (
  keySubTopicClusters: KeySubTopicCluster[],
) => {
  return keySubTopicClusters
    .filter((subTopicCluster) => !subTopicCluster.other)
    .toSorted((a, b) => b.amount - a.amount)
    .slice(0, 3)
}

const mapKeySubTopicClustersToGraphData = (
  keySubTopicClusters: KeySubTopicCluster[],
) =>
  keySubTopicClusters.map((subTopicCluster) => ({
    label: subTopicCluster.keySubTopics[0]?.name ?? subTopicCluster.label,
    percentage: subTopicCluster.percentage,
  }))

type TopConversationDriversWidgetSelectorTopics = {
  label: string
  percentage: number
  id: string
  graphData: {
    label: string
    percentage: number
  }[]
  keyTopics: KeyTopicsReadyResponse['keyTopicClusters'][number]['keyTopics']
  digestSubTopicClusters: KeyTopicsReadyResponse['keyTopicClusters'][number]['keySubTopicClusters']
}

export type TopConversationDriversWidgetReadySelector = {
  status: 'ready'
  benchmark: KeyTopicsReadyResponse['benchmark']
  keyTopicClusters: TopConversationDriversWidgetSelectorTopics[]
}

export const selector = (
  data: KeyTopicsResponse,
): KeyTopicsSessionSelector<TopConversationDriversWidgetReadySelector> => {
  if (data === null) return { status: 'gone' } // handle 204 No Content response
  if (data.status === 'Failed') return { status: 'failed' }
  if (data.status === 'Processing' || data.status === 'Pending')
    return { status: 'loading' }

  return {
    status: 'ready',
    benchmark: data.benchmark,
    keyTopicClusters: data.keyTopicClusters
      .filter(({ label }) => label !== 'OTHER')
      .toSorted((a, b) => b.percentage - a.percentage)
      .slice(0, 6)
      .map(
        ({ icon, label, percentage, id, keyTopics, keySubTopicClusters }) => {
          const name = keyTopics.length > 0 ? keyTopics[0].name : label
          const clusterLabel = icon ? `${icon}\u00A0${name}` : name // \u00A0 is non-breaking space
          const topThreeSubTopicClusters =
            getThreeTopSubTopicClusters(keySubTopicClusters)
          return {
            label: clusterLabel,
            percentage,
            id,
            graphData: mapKeySubTopicClustersToGraphData(
              topThreeSubTopicClusters,
            ),
            digestSubTopicClusters: topThreeSubTopicClusters,
            keyTopics,
          }
        },
      ),
  }
}
