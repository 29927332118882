import { useCurrentUser } from '@capturi/core'
import { useUsers } from '@capturi/stores'
import {
  CheckboxOption,
  OnChangeValue,
  OptionProps,
  SelectOption,
  components,
} from '@capturi/ui-select'
import { Text } from '@chakra-ui/react'
import React, { useMemo } from 'react'

import {
  FilterCriteriaComponentBaseProps,
  FilterCriteriaSelect,
} from '../../components/PhoneFilter/components/PhoneSegmentBuilder'
import getMissingOptions from '../getMissingOptions'

export type UserSelectConfig = {
  isMulti?: boolean
}

export type UserSelectProps = FilterCriteriaComponentBaseProps<
  string[],
  UserSelectConfig
> & { showFooter?: boolean }

type UserOption = SelectOption & {
  title?: string
  email?: string
}

function formatUser({ name, title }: { name: string; title: string }): string {
  const titleOutput = title ? ` (${title})` : ''
  return name + titleOutput
}

const Option: React.ComponentType<OptionProps<UserOption, boolean>> = (
  props,
) => {
  const { label, title, email } = props.data as UserOption
  const subtext = [title, email].filter(Boolean).join(', ')

  return (
    <components.Option {...props}>
      <CheckboxOption isSelected={props.isSelected}>
        <>
          <Text>{label}</Text>
          <Text fontSize="sm" color="textMuted">
            {subtext}
          </Text>
        </>
      </CheckboxOption>
    </components.Option>
  )
}

export function UserSelect(props: UserSelectProps): React.ReactElement {
  const { users: allUsers } = useUsers()
  const { isAdmin } = useCurrentUser()
  const users = useMemo(
    () => (isAdmin ? allUsers : allUsers.filter((u) => u.isSubordinate)),
    [allUsers, isAdmin],
  )
  const isMultiSelect = props.options?.isMulti ?? true

  const { options, value } = React.useMemo(() => {
    const currentStateValue = props.value ?? []

    const options = users.map((x) => ({
      value: x.uid,
      label: formatUser(x),
      title: x.title,
      email: x.email,
    }))
    const missingOptions = getMissingOptions(
      currentStateValue,
      options,
      'Deleted User',
    )
    // If current value is not part of the options then find them, and add them later
    const mergedOptions = missingOptions.length
      ? [...missingOptions, ...options]
      : options
    const value = currentStateValue.map((x) => {
      const option = mergedOptions.find((o) => o.value === x)
      return (
        option ?? {
          value: x,
          label: x,
        }
      )
    })

    return {
      options: mergedOptions,
      value,
    }
  }, [users, props.value])

  const handleChange = (option: OnChangeValue<SelectOption, boolean>): void => {
    if (option == null) {
      props.resetValue?.()
      return
    }
    const options = (Array.isArray(option) ? option : [option]) as NonNullable<
      OnChangeValue<SelectOption, true>
    >
    props.setValue?.(options.map((x) => x.value))
    if (!isMultiSelect) {
      props.onClose?.()
    }
  }

  return (
    <FilterCriteriaSelect
      options={options}
      value={value}
      onChange={handleChange}
      onClose={props.onClose}
      autoFocus={true}
      components={{ Option }}
      isMulti={isMultiSelect}
      showFooter={props.showFooter}
    />
  )
}
