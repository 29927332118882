import {
  Button,
  Icon,
  IconButton,
  Tooltip,
  ButtonProps as _ButtonProps,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { MdAdd } from 'react-icons/md'

type ButtonProps = Omit<_ButtonProps, 'children'>

export type AddFilterCriteriaButtonProps = {
  showText: boolean
  component: React.ElementType
} & ButtonProps

export const AddFilterCriteriaButton: React.FC<AddFilterCriteriaButtonProps> =
  React.forwardRef(function AddFilterCriteriaButton(
    { showText, component: Component = Button, ...props },
    ref,
  ) {
    const icon = <Icon as={MdAdd} boxSize={5} />

    let buttonProps = {}
    if (showText) {
      buttonProps = {
        as: Button,
        leftIcon: icon,
        iconSpacing: 1,
      }
    } else {
      buttonProps = {
        as: IconButton,
        icon: icon,
      }
    }

    const addFilterMessage = t`Add filter`

    const output = (
      <Component
        ref={ref}
        size="sm"
        borderBottom="2px solid"
        borderBottomColor="gray.300"
        borderRadius="md"
        aria-label={t`Add a filter criteria`}
        data-intercom-target="add filter"
        {...buttonProps}
        {...props}
      >
        {showText && addFilterMessage}
      </Component>
    )

    if (showText) {
      return output
    }
    return (
      <Tooltip hasArrow label={addFilterMessage} aria-label={addFilterMessage}>
        {output}
      </Tooltip>
    )
  })
