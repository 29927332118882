import { SpeakerTrackerUserHitsResponseModel } from '@capturi/api-insights'
import {
  PhoneSegmentState,
  Segment,
  SegmentState,
  TextSegmentState,
} from '@capturi/filters'
import { useUsers } from '@capturi/stores'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

import { UserSegments } from '../../shared/types'

export type Datum = {
  targets: number
  hits: number
  hitRate: number
  stateIndex: number
}

export type UserData = UserSegments<Datum>

export type TextTrackerUserHitsResponseModel = {
  users: {
    cases: number
    casesWithHit: number
    casesWithHitPercent: number
    userUid: string
  }[]
}

export type UserItemTargetHitsModel = {
  userHits: {
    targets: number
    targetsWithHit: number
    targetsWithHitPercent: number
    userUid: string
  }[]
}

function convertSpeakerTrackerUserHitToUserItemTargetHit(
  speakerTrackerUserHit: SpeakerTrackerUserHitsResponseModel | null,
): UserItemTargetHitsModel | null {
  return speakerTrackerUserHit
    ? {
        userHits: speakerTrackerUserHit.speakerTrackerUserHits.map((user) => ({
          targets: user.conversations,
          targetsWithHit: user.conversationsWithHit,
          targetsWithHitPercent: user.conversationsWithHitPercent,
          userUid: user.userUid,
        })),
      }
    : null
}

function convertTextTrackerUserHitToUserItemTargetHit(
  textTrackerUserHit: TextTrackerUserHitsResponseModel | null,
): UserItemTargetHitsModel | null {
  return textTrackerUserHit
    ? {
        userHits: textTrackerUserHit.users.map((user) => ({
          targets: user.cases,
          targetsWithHit: user.casesWithHit,
          targetsWithHitPercent: user.casesWithHitPercent,
          userUid: user.userUid,
        })),
      }
    : null
}

export function convertHitsToUserItemSegments(
  phoneSegmentStates: PhoneSegmentState[],
  phoneSegments: Segment<SpeakerTrackerUserHitsResponseModel>[],
  textSegmentStates: TextSegmentState[],
  textSegments: Segment<TextTrackerUserHitsResponseModel>[],
  getIndexForState: (state: SegmentState) => number,
): Segment<UserItemTargetHitsModel>[] {
  const segments = [
    ...phoneSegments.map<Segment<UserItemTargetHitsModel> & { index: number }>(
      (x, index) => ({
        ...x,
        index: getIndexForState(phoneSegmentStates[index]),
        data: convertSpeakerTrackerUserHitToUserItemTargetHit(x.data),
      }),
    ),
    ...textSegments.map<Segment<UserItemTargetHitsModel> & { index: number }>(
      (x, index) => ({
        ...x,
        index: getIndexForState(textSegmentStates[index]),
        data: convertTextTrackerUserHitToUserItemTargetHit(x.data),
      }),
    ),
  ]
  // ensure segments are sorted by state.index, important for Datum
  const sortedSegments = segments.sort((a, b) => a.index - b.index)
  return sortedSegments.map(({ index: _, ...segment }) => segment)
}

export function useUserHits(
  segments: Segment<UserItemTargetHitsModel>[],
): UserData[] {
  const { getUserByUid } = useUsers()

  return useMemo(() => {
    const allUserUids = new Set(
      segments.flatMap((s) => (s.data?.userHits ?? []).map((x) => x.userUid)),
    )

    const hitMaps = segments.map<{ [key: string]: Datum }>((s, index) => {
      return (s.data?.userHits ?? []).reduce<{
        [key: string]: Datum
      }>((acc, x) => {
        acc[x.userUid] = {
          targets: x.targets,
          hits: x.targetsWithHit,
          hitRate: x.targetsWithHitPercent / 100,
          stateIndex: index,
        }
        return acc
      }, {})
    })

    const segmentData = [...allUserUids].reduce<{ [key: string]: UserData }>(
      (acc, userUid) => {
        acc[userUid] = {
          segments: segments.reduce<Segment<Datum>[]>((acc, s, i) => {
            const map = hitMaps[i][userUid]
            if (map != null) {
              acc.push({
                color: s.color,
                label: s.label,
                data: map,
              })
            }
            return acc
          }, []),
          user: getUserByUid(userUid),
        }
        return acc
      },
      {},
    )

    const data = orderBy(
      Object.values(segmentData),
      [(x) => x.segments[0]?.data?.hitRate, (x) => x.user.name],
      ['desc', 'asc'],
    )

    return data
  }, [segments, getUserByUid])
}
