import { Icon, IconButton, IconButtonProps } from '@chakra-ui/react'
import React from 'react'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'

import { InputProps } from './Input'

const iconSizes: { [key in NonNullable<InputProps['size']>]: number } = {
  sm: 3,
  md: 4,
  lg: 5,
}

const BaseButton: React.FC<IconButtonProps> = (props) => {
  return (
    <IconButton
      h="50%"
      w="100%"
      minW="auto"
      bg="white"
      borderRadius={0}
      borderLeft="1px solid"
      borderColor="inherit"
      _focus={{
        boxShadow: 'none',
      }}
      tabIndex={-1}
      {...props}
    />
  )
}

export const IncrementButton: React.FC<IconButtonProps> = (props) => {
  const iconSize = iconSizes[(props.size as InputProps['size']) ?? 'md']
  return (
    <BaseButton
      icon={<Icon as={AiFillCaretUp} boxSize={iconSize} />}
      {...props}
    />
  )
}

export const DecrementButton: React.FC<IconButtonProps> = (props) => {
  const iconSize = iconSizes[(props.size as InputProps['size']) ?? 'md']
  return (
    <BaseButton
      icon={<Icon as={AiFillCaretDown} boxSize={iconSize} />}
      {...props}
    />
  )
}
