import { useDeleteFolder } from '@capturi/api-trackers'
import { ModalCloseButton, useToast } from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import { Event, logEvent } from '../events'

export type ConfirmDeleteTrackerFolderProps = {
  folderUid: string
  folderName: string
} & BaseModalComponentProps<void>

export const ConfirmDeleteTrackerFolder: React.FC<
  ConfirmDeleteTrackerFolderProps
> = ({ folderUid, folderName, onClose }) => {
  const toast = useToast()
  const cancelRef = React.useRef<HTMLButtonElement>(null)
  const { isPending, error, mutate } = useDeleteFolder()
  return (
    <AlertDialog
      isOpen
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      closeOnEsc={!isPending}
      closeOnOverlayClick={!isPending}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>
          <Trans>Delete {folderName}</Trans>
        </AlertDialogHeader>
        <ModalCloseButton isDisabled={isPending} />
        <AlertDialogBody>
          <div>
            <Text fontWeight="bold">
              <Trans>Are you sure?</Trans>
            </Text>
          </div>
          <Text>
            <Trans>
              The trackers from the folder will be moved out, ensuring that the
              trackers inside the folder remain unaffected and won’t be deleted.
            </Trans>
          </Text>
        </AlertDialogBody>
        <AlertDialogFooter>
          <div>
            <Stack isInline spacing={4} justify="flex-end">
              <Button ref={cancelRef} onClick={onClose} isDisabled={isPending}>
                <Trans>Cancel</Trans>
              </Button>
              <Button
                isLoading={isPending}
                colorScheme="red"
                onClick={() => {
                  mutate(folderUid, {
                    onSuccess: () => {
                      toast({
                        status: 'success',
                        title: t`Successfully deleted ${folderName}`,
                      })
                      onClose()
                      logEvent(Event.TrackerFolder_Deleted)
                    },
                    onError: (error) => {
                      toast({
                        status: 'error',
                        title: t`Could not delete ${folderName}`,
                        description: error.message,
                      })
                    },
                  })
                }}
              >
                <Trans>Delete</Trans>
              </Button>
            </Stack>
            {error && <Text color="red">{error.message}</Text>}
          </div>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
