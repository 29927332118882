import type {
  KeyTopicsReadyResponse,
  KeyTopicsResponse,
} from 'pages/KeyTopics/hooks/types'
import { KeyTopicsTopTimeConsumersSessionSelector } from '../types'

type TopTimeConsumersWidgetSelectorTopics = {
  label: string
  id: string
  amount: number
  keyTopics: KeyTopicsReadyResponse['keyTopicClusters'][number]['keyTopics']
}

export type TopTimeConsumersWidgetReadySelector = {
  status: 'ready'
  benchmark: KeyTopicsReadyResponse['benchmark']
  keyTopicClusters: TopTimeConsumersWidgetSelectorTopics[]
}

export const selector = (
  data: KeyTopicsResponse | { status: 'Empty' },
): KeyTopicsTopTimeConsumersSessionSelector<TopTimeConsumersWidgetReadySelector> => {
  if (data === null) {
    // handle 204 No Content response
    return {
      status: 'gone',
    }
  }
  if (data.status === 'Empty') return { status: 'empty' }
  if (data.status === 'Failed') return { status: 'failed' }
  if (data.status === 'Processing' || data.status === 'Pending') {
    return { status: 'loading' }
  }

  return {
    status: 'ready',
    benchmark: data.benchmark,
    keyTopicClusters: data.keyTopicClusters
      .toSorted((a, b) => {
        return b.amount - a.amount
      })
      .slice(0, 5)
      .map(({ amount, id, icon, label, keyTopics }) => {
        const name = keyTopics.length > 0 ? keyTopics[0].name : label
        const clusterLabel = icon ? `${icon}\u00A0${name}` : name // \u00A0 is non-breaking space
        return {
          id,
          label: clusterLabel,
          amount,
          keyTopics,
        }
      }),
  }
}
