import { ShowModal, useModal } from '@capturi/use-modal'

import {
  EmailTrackerExamplesDrawer,
  EmailTrackerExamplesDrawerProps,
} from './EmailTrackerExamplesDrawer'
import {
  PhoneTrackerExamplesDrawer,
  PhoneTrackerExamplesDrawerProps,
} from './PhoneTrackerExamplesDrawer'

export { PhoneTrackerExamplesDrawer }

export function usePhoneTrackerExamplesDrawer(): ShowModal<PhoneTrackerExamplesDrawerProps> {
  const [open] = useModal(PhoneTrackerExamplesDrawer)

  return open
}

export function useEmailTrackerExamplesDrawer(): ShowModal<EmailTrackerExamplesDrawerProps> {
  const [open] = useModal(EmailTrackerExamplesDrawer)

  return open
}
