import { addMinutes } from 'date-fns'

import { Period } from '../period'

export function adjustDateForDSTOverlap(
  date: Date,
  initialOffset: number,
): Date {
  const offset = date.getTimezoneOffset()
  const diff = initialOffset - offset
  if (diff !== 0) {
    return addMinutes(date, diff)
  }
  return date
}

export function adjustPeriodForDSTOverlap(period: Period): {
  fromInclusive: Date
  toInclusive: Date
} {
  const fromTZOffset = period.from.getTimezoneOffset()
  return {
    fromInclusive: period.from,
    toInclusive: adjustDateForDSTOverlap(period.to, fromTZOffset),
  }
}
