import analytics from '@capturi/analytics'
import { Color, Shape, ShapeMark } from '@capturi/sharing'
import { Box, HStack, Text, useTheme } from '@chakra-ui/react'
import { getColor } from '@chakra-ui/theme-tools'
import { t } from '@lingui/macro'
import React from 'react'

const TextTrackerItem: React.FC<{
  trackerHit: number
  uid: string
  shape: Shape
  color: Color
  tracker: string | undefined
  selectedTrackerUid: string | null
  onSelectTracker: (trackerUid: string) => void
}> = ({
  trackerHit,
  uid,
  tracker,
  color,
  shape,
  selectedTrackerUid,
  onSelectTracker,
}) => {
  const theme = useTheme()
  const handleTrackerClick = React.useCallback(() => {
    analytics.event('analytics_Conversation_selectTracker')
    onSelectTracker(uid ?? '')
  }, [onSelectTracker, uid])
  const handleGetColorValue = React.useCallback(
    (color: string) => {
      return getColor(theme, color)
    },
    [theme],
  )
  return (
    <HStack
      align="baseline"
      spacing={2}
      textTransform="capitalize"
      cursor="pointer"
      fontWeight="normal"
      onClick={handleTrackerClick}
      color={uid === selectedTrackerUid ? 'primary.500' : ''}
    >
      <Box transform="translateY(4px)">
        <ShapeMark
          color={color}
          shape={shape}
          outline={false}
          getColorValue={handleGetColorValue}
        />
      </Box>

      <Text wordBreak="break-word">{`${
        tracker ?? t`Tracker not found`
      } (${trackerHit})`}</Text>
    </HStack>
  )
}

export default TextTrackerItem
