import { Textarea, TextareaProps } from '@chakra-ui/react'
import React, { useRef } from 'react'

const DynamicTextArea: React.FC<Omit<TextareaProps, 'height' | 'h'>> = ({
  value,
  onChange,
  ...props
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  if (textAreaRef.current) {
    textAreaRef.current.style.height = '0px'
    const scrollHeight = textAreaRef.current.scrollHeight
    textAreaRef.current.style.height = `${scrollHeight}px`
  }

  const handleHeightChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    onChange?.(e)
  }
  return (
    <Textarea
      ref={textAreaRef}
      value={value}
      onChange={handleHeightChange}
      {...props}
    />
  )
}

export default DynamicTextArea
