import analytics from '@capturi/analytics'
import { Spinner } from '@capturi/ui-components'
import { Box, Flex, IconButton, Input, InputGroup } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { MdClose } from 'react-icons/md'
import { renderTags } from './RenderTags'

type Props = {
  isFocused: boolean
  words: string[]
  newWord: string
  isLoading: boolean
  onFocus: () => void
  onBlur: () => void
  onAdd: () => void
  setWords: (words: string[]) => void
  setNewWord: (word: string) => void
}

export const RapidSearchInput: React.FC<Props> = ({
  isFocused,
  words,
  newWord,
  isLoading,
  onFocus,
  onBlur,
  onAdd,
  setWords,
  setNewWord,
}) => {
  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Backspace' && !newWord) {
      const newState = words.slice(0, words.length - 1)
      setWords(newState)
    } else if (newWord && ['Enter', ',', 'Tab', '.'].includes(e.key)) {
      e.preventDefault()
      onAdd()
    }
  }

  const handleReset = (): void => {
    if (newWord) {
      setNewWord('')
    }
    setWords([])
  }

  const handleRemove = (index: number): void => {
    const newState = [...words]
    newState.splice(index, 1)
    setWords(newState)
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>): void => {
    event.preventDefault()
    const text = event.clipboardData.getData('Text')

    const clipboardWords = text.split(/,|\s/) // split by ',' and whitespace here
    const s = new Set(words)
    const filteredWords = clipboardWords.filter(
      (word, index) =>
        word && !s.has(word) && clipboardWords.indexOf(word) === index,
    )
    const newState = [...words, ...filteredWords]
    setWords(newState)
    analytics.event('rapidSearch_paste', { words: newState })
  }

  return (
    <InputGroup
      width="100%"
      marginLeft="0"
      flexDir="row"
      fontFamily="body"
      justifyContent="space-between"
    >
      <Flex
        wrap="wrap"
        minH="40px"
        w="100%"
        alignItems={'center'}
        borderRadius="4px"
        border={words.length ? '1px solid transparent' : 'none'}
        borderRight="none"
      >
        {words.map((word, index) => renderTags(word, index, handleRemove))}
        <Input
          flex={1}
          w={words.length ? 'auto' : '100%'}
          px={2}
          height="auto"
          border={'none'}
          _focusVisible={{ borderRight: 'none' }}
          borderColor={isFocused ? 'none' : 'gray.200'}
          _hover={{
            borderColor: isFocused ? 'primary.500' : 'inherit',
          }}
          borderRight={isFocused ? 'none' : 'inherit'}
          borderRightRadius="none"
          outline={0}
          placeholder={
            !words.length ? t`Search by words in transcriptions` : ''
          }
          onBlur={() => {
            onBlur()
          }}
          onFocus={onFocus}
          onKeyDown={handleKeypress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNewWord(e.target.value.toLocaleLowerCase())
          }
          value={newWord}
          onPaste={handlePaste}
        />
      </Flex>
      <Box w="40px">
        {isLoading && (
          <Spinner delay={0} display="inline-block" mt={2} mr={2} />
        )}
        {!isLoading && words.length > 0 && (
          <IconButton
            size="md"
            variant="ghost"
            _hover={{ background: 'none' }}
            icon={<MdClose />}
            aria-label={t`Reset words`}
            onClick={handleReset}
          />
        )}
      </Box>
    </InputGroup>
  )
}
