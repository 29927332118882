import { Speaker } from '@capturi/core'
import qs from 'query-string'

import { basePath } from '../routes'

const getRoutes = (
  includeBasePath = true,
): {
  main: (props?: { speaker?: Speaker; tag?: string }) => string
  tracker: (props: {
    trackerUid: string
    speaker?: Speaker
    tag?: string
  }) => string
} => {
  const prefix = includeBasePath ? basePath : ''
  return {
    main: ({ speaker, tag } = {}) => {
      return qs.stringifyUrl({
        url: `${prefix}/trackers`,
        query: {
          speaker,
          tag,
        },
      })
    },
    tracker: ({
      trackerUid,
      speaker,
      tag,
    }: {
      trackerUid: string
      speaker?: Speaker
      tag?: string
    }) => {
      return qs.stringifyUrl({
        url: `${prefix}/trackers/${trackerUid}`,
        query: {
          speaker,
          tag,
        },
      })
    },
  }
}

export default getRoutes
