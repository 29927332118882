import request, { ResponseError } from '@capturi/request'
import {
  InfiniteData,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { Case, CaseListResponseModel } from '../models'

export const useDeleteCase = (): UseMutationResult<
  Case,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (uid: string) =>
      request.delete<Case>(`cases/${uid}?api-version=3.3`),
    onSuccess: (_, caseUid) => {
      queryClient.setQueriesData<InfiniteData<CaseListResponseModel>>(
        { queryKey: ['cases', 'list'] },
        (oldData) => {
          if (!oldData) {
            return oldData
          }
          return {
            ...oldData,
            pages: oldData.pages.map((c) => ({
              continuationToken: c.continuationToken,
              cases: c.cases.filter((c) => c.uid !== caseUid),
            })),
          }
        },
      )
      queryClient.removeQueries({
        queryKey: ['cases', caseUid],
      })
    },
  })
}
