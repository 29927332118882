import queryString from 'query-string'
import useSWR, { SWRConfiguration, SWRResponse } from 'swr'

import { API, APIFactory, RequestTuple } from './types'

// biome-ignore lint/suspicious/noExplicitAny: legacy
type SearchParams = { [key: string]: any } | null | undefined

type GetEndpoint<E extends API> = (endpoint: E) => RequestTuple | null
type GetSearchParams = () => SearchParams

export interface UseAPI<E extends API> {
  // Overload 1
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  // biome-ignore lint/correctness/noUnusedVariables: Legacy
  <Data = any, _Error = any>(
    getEndpoint: GetEndpoint<E>,
    config?: SWRConfiguration<Data, Error>,
  ): SWRResponse<Data, Error>
  // Overload 2
  // biome-ignore lint/suspicious/noRedeclare: legacy
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  <Data = any, Error = any>(
    getEndpoint: GetEndpoint<E>,
    getSearchParams?: GetSearchParams,
    config?: SWRConfiguration<Data, Error>,
  ): SWRResponse<Data, Error>
}

export default function createUseAPI<E extends API>(
  apiFactory: APIFactory<E>,
  apiVersion: string,
): UseAPI<E> {
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  const useAPIHook: UseAPI<E> = <Data = any, Error = any>(
    // biome-ignore lint/suspicious/noExplicitAny: legacy
    ...args: any
  ): SWRResponse<Data, Error> => {
    // Overload 1
    const getEndpoint: GetEndpoint<E> = args[0]
    let config: SWRConfiguration<Data, Error> | undefined = args[1]
    let getSearchParams: GetSearchParams | undefined

    if (typeof args[1] === 'function') {
      // Overload 2
      getSearchParams = args[1]
      config = args[2]
    }

    const api = apiFactory(apiVersion)
    const endpoint = getEndpoint(api as E)

    let key: string | null = null
    if (endpoint) {
      const [url] = endpoint

      let searchParams: SearchParams = {}
      if (typeof getSearchParams === 'function') {
        const result = getSearchParams()
        if (result) {
          searchParams = result
        }
      }
      searchParams['api-version'] = apiVersion

      key = queryString.stringifyUrl({
        url,
        query: searchParams,
      })
    }

    return useSWR<Data, Error>(key, config)
  }
  return useAPIHook
}
