import GT_Eesti_Display_Regular_Woff from '@capturi/assets/fonts/GT-Eesti/GT-Eesti-Display-Regular.woff'
import GT_Eesti_Display_Regular_Woff2 from '@capturi/assets/fonts/GT-Eesti/GT-Eesti-Display-Regular.woff2'
import Rubik_Medium_Woff from '@capturi/assets/fonts/Rubik/rubik-v18-latin-ext_latin-500.woff'
import Rubik_Medium_Woff2 from '@capturi/assets/fonts/Rubik/rubik-v18-latin-ext_latin-500.woff2'
import Rubik_MediumItalic_Woff from '@capturi/assets/fonts/Rubik/rubik-v18-latin-ext_latin-500italic.woff'
import Rubik_MediumItalic_Woff2 from '@capturi/assets/fonts/Rubik/rubik-v18-latin-ext_latin-500italic.woff2'
import Rubik_Italic_Woff from '@capturi/assets/fonts/Rubik/rubik-v18-latin-ext_latin-italic.woff'
import Rubik_Italic_Woff2 from '@capturi/assets/fonts/Rubik/rubik-v18-latin-ext_latin-italic.woff2'
import Rubik_Regular_Woff from '@capturi/assets/fonts/Rubik/rubik-v18-latin-ext_latin-regular.woff'
import Rubik_Regular_Woff2 from '@capturi/assets/fonts/Rubik/rubik-v18-latin-ext_latin-regular.woff2'
import { css } from '@emotion/react'

/**
 * https://google-webfonts-helper.herokuapp.com/fonts/rubik?subsets=latin,latin-ext
 */

const globalStyles = css`
  @font-face {
    font-family: 'GT-Eesti-Display-Regular';
    src:
      url('${GT_Eesti_Display_Regular_Woff2}') format('woff2'),
      url('${GT_Eesti_Display_Regular_Woff}') format('woff');
    font-display: swap;
  }
  /* rubik-regular - latin-ext_latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src:
      url('${Rubik_Regular_Woff2}') format('woff2'),
      url('${Rubik_Regular_Woff}') format('woff');
  }
  /* rubik-500 - latin-ext_latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src:
      url('${Rubik_Medium_Woff2}') format('woff2'),
      url('${Rubik_Medium_Woff}') format('woff');
  }
  /* rubik-italic - latin-ext_latin */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    src:
      url('${Rubik_Italic_Woff2}') format('woff2'),
      url('${Rubik_Italic_Woff}') format('woff');
  }
  /* rubik-500italic - latin-ext_latin */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    src:
      url('${Rubik_MediumItalic_Woff2}') format('woff2'),
      url('${Rubik_MediumItalic_Woff}') format('woff');
  }
`

export default globalStyles
