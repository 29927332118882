import analytics from '@capturi/analytics'
import {
  BaseTracker,
  TrackerFolder,
  useDeleteTracker,
  useTrackerDeps,
  useTrackerFolders,
  useTrackers,
} from '@capturi/api-trackers'
import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { Speaker, useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { usePageTitle } from '@capturi/react-utils'
import { getErrorMessage } from '@capturi/request'
import { UserPermissionsList } from '@capturi/sharing/src/components/UserPermissionsList'
import { useOrganization } from '@capturi/stores'
import {
  ContentPlaceholder,
  List,
  ListItem,
  ListItemMenu,
  MenuItem,
  MenuList,
  PageHeading,
  SortDirectionArrow,
  useToast,
} from '@capturi/ui-components'
import { useConfirm } from '@capturi/use-confirm'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  ButtonGroup,
  Flex,
  HStack,
  Icon,
  IconButton,
  MenuDivider,
  Portal,
  Stack,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, select, t } from '@lingui/macro'
import { DependentsInfoDialog } from 'components/DependentsInfoDialog'
import { ButtonLink } from 'components/Link'
import capitalize from 'lodash/capitalize'
import NotFoundPage from 'pages/NotFoundPage'
import useTrendsRoutes from 'pages/TrendsV2/routes'
import { getRoutes } from 'pages/analytics'
import { FilterTagSelectPopover } from 'pages/analytics/trackers'
import { useTagsContext } from 'pages/analytics/trackers/hooks/useTagsContext'
import { switchPermissionPreset } from 'pages/analytics/trackers/utils'
import qs from 'query-string'
import React from 'react'
import {
  MdDelete,
  MdEdit,
  MdFilterList,
  MdInfo,
  MdLaunch,
} from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import {
  CellProps,
  Column,
  Row,
  useFlexLayout,
  useSortBy,
  useTable,
} from 'react-table'

import { CreateTrackerButton } from '../components/TrackerButtons'
import routes from '../routes'

const TagsToolTip: React.FC = () => {
  return (
    <Stack spacing={2}>
      <Box>
        <Text as="strong">
          <Trans>Tags</Trans>
        </Text>
        <Text>
          <Trans>
            Tags allow you to group trackers and create a better overview
          </Trans>
        </Text>
      </Box>
    </Stack>
  )
}

const TrackersPage: React.FC = () => {
  usePageTitle(t`Trackers`)
  const navigate = useNavigate()
  const trendsRoutes = useTrendsRoutes()
  const user = useCurrentUser()
  const { data: trackers = [] } = useTrackers()

  const { mutate: deleteTracker } = useDeleteTracker()
  const { data: folders } = useTrackerFolders()
  const confirm = useConfirm()
  const toast = useToast()
  const { useEmailChannelAsDefault } = useFeatureFlags()
  const [openTrackerDependentsDialog] = useModal(DependentsInfoDialog)
  const [selectedTags, setSelectedTags] = useTagsContext()
  const { fetchTrackerDependants } = useTrackerDeps()
  const { organizationType } = useOrganization()

  const handleSetSelectedTags = React.useCallback(
    (tags: string[]) => {
      setSelectedTags(tags)
      analytics.event('trackers_filter-by-tag')
    },
    [setSelectedTags],
  )

  const displayTrackers = React.useMemo(() => {
    if (selectedTags && selectedTags.length > 0) {
      if (trackers)
        return trackers.filter((tracker) =>
          selectedTags.some((selectedTag) =>
            tracker.tags.includes(selectedTag),
          ),
        )
    }
    return trackers
  }, [selectedTags, trackers])

  const onEditTracker = (uid: string): void => {
    navigate(routes.editTracker(uid))
    analytics.event('editTracker_open')
  }

  const onNavigateToAnalytics = (tracker: BaseTracker): void => {
    navigate(
      getRoutes().trackers.tracker({
        speaker: tracker.speech?.speakerId,
        trackerUid: tracker.uid,
      }),
    )
  }

  const foldersByUid = React.useMemo(() => {
    return folders?.reduce<Record<string, TrackerFolder>>((acc, folder) => {
      acc[folder.uid] = folder
      return acc
    }, {})
  }, [folders])

  const columns = React.useMemo<Array<Column<BaseTracker>>>(() => {
    const cols: Array<Column<BaseTracker>> = [
      {
        Header: t`Name`,
        accessor: 'name',
        width: 128,
      },
      {
        id: 'speakerId',
        Header: t`Speaker`,
        accessor: (tracker): string => {
          if (!tracker.speech) return ''
          const value: Record<Speaker, string> = {
            [Speaker.All]: t`All`,
            [Speaker.Employee]: t`Employee`,
            [Speaker.Customer]: select(organizationType, {
              public: 'Citizen',
              other: 'Customer',
            }),
          }
          return value[tracker.speech.speakerId]
        },
        width: 80,
        Cell: function WordsCell({ value }: CellProps<BaseTracker, string>) {
          return (
            <Box noOfLines={1} wordBreak="break-all" title={value}>
              {value}
            </Box>
          )
        },
      },
      {
        Header: t`Words/sentences`,
        accessor: (tracker) => {
          return useEmailChannelAsDefault
            ? tracker.text?.phrases
            : tracker.speech?.phrases
        },
        width: 280,
        Cell: function WordsCell({
          value,
        }: CellProps<BaseTracker, string[] | null | undefined>) {
          return <Box>{value?.join(', ')}</Box>
        },
      },
      {
        id: 'folderUid',
        Header: t`Folder`,
        accessor: (tracker): string => {
          if (tracker.folderUid == null) return ''
          const folder = foldersByUid?.[tracker?.folderUid]
          return folder?.title ?? tracker.folderUid
        },
        width: 80,
        Cell: function FolderCell({ value }: CellProps<BaseTracker, string>) {
          return (
            <Flex justify="flex-start" flexWrap="wrap" wordBreak="break-word">
              <Text>{value}</Text>
            </Flex>
          )
        },
      },
      {
        id: 'tags',
        Header: function WordsHeader() {
          return (
            <Tooltip label={<TagsToolTip />}>
              <Box display="inline-block">
                <Trans>Tags</Trans>
                <Icon as={MdInfo} ml={2} color="gray.400" />
              </Box>
            </Tooltip>
          )
        },
        accessor: 'tags',
        width: 80,
        Cell: function WordsCell({ value }) {
          return (
            <Box flexWrap="wrap" key="tags-header-cell">
              {value.map((value, i) => (
                <Tag key={`${i}-${value}`} ml="1" mb="1" wordBreak="break-word">
                  {capitalize(value)}
                </Tag>
              ))}
            </Box>
          )
        },
      },
      {
        id: 'acl',
        Header: t`Sharing`,
        width: 100,
        accessor: 'acl',
        Cell: function EditAclCell({ row }) {
          if (row.original.permissionPreset !== 'Custom')
            return (
              <Tag>{switchPermissionPreset(row.original.permissionPreset)}</Tag>
            )

          return (
            <Box textAlign="center">
              <UserPermissionsList acl={row.original.acl} isAccordion={false} />
            </Box>
          )
        },
      },
    ]
    return cols
  }, [foldersByUid, organizationType, useEmailChannelAsDefault])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: displayTrackers as readonly BaseTracker[],
        sortTypes: React.useMemo(
          () => ({
            alphanumeric: (
              row1: Row<BaseTracker>,
              row2: Row<BaseTracker>,
              columnId: string,
            ) => {
              const v1 = String(row1.values[columnId])
              const v2 = String(row2.values[columnId])
              return v1.localeCompare(v2, user.language, {
                numeric: true,
                sensitivity: 'base',
              })
            },
          }),
          [user.language],
        ),
        autoResetSortBy: false,
        disableSortRemove: true,
        initialState: {
          sortBy: [
            {
              id: 'name',
              desc: false,
            },
          ],
        },
      },
      useFlexLayout,
      useSortBy,
    )

  if (!user.permissions.editTracker) return <NotFoundPage />

  const handleDeleteTracker = async (uid: string): Promise<void> => {
    try {
      await deleteTracker(uid)
      toast({ title: t`Tracker deleted`, status: 'success' })
      analytics.event('tracker_deleted')
      navigate(
        qs.stringifyUrl({
          url: getRoutes(false).trackers.main(),
        }),
        { replace: true },
      )
    } catch (error) {
      const errorMsg = getErrorMessage(error, {
        409: t`The tracker cannot be deleted while calculation of statistics is ongoing`,
      })
      toast({
        title: t`Ouch, we could not delete the tracker`,
        description: errorMsg,
        status: 'error',
      })
      throw error
    }
  }

  // Delete tracker
  const onDeleteTracker = async (uid: string, name: string): Promise<void> => {
    const { dependents } = await fetchTrackerDependants(uid)
    const hasDependents = dependents?.length

    if (hasDependents) {
      openTrackerDependentsDialog({
        heading: t`The tracker cannot be deleted`,
        description: t`Remove references to this tracker from the following segments, dashboards and scores before it can be deleted.`,
        dependents,
      })
      return
    }
    try {
      await confirm({
        title: t`Are you sure?`,
        description: t`Are you sure, you want to delete the tracker "${name}"?`,
        cancelText: t`Cancel`,
        confirmText: t`Delete`,
      })
      handleDeleteTracker(uid)
    } catch {
      // confirm dialog cancelled
      return
    }
  }

  function renderPlaceholder(): React.ReactElement {
    return (
      <ContentPlaceholder.Container mt="10vh">
        <ContentPlaceholder.Image as={Icon_EmptyState} />
        <ContentPlaceholder.Heading>
          <Trans>It&apos;s a bit empty here!</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Text>
            <Trans>Trackers are the core of Capturi.</Trans>
          </Text>
          <Text mt={4}>
            <Trans>
              Hurry up create your first tracker. You can create trackers either
              manually or on the basis of Capturi&apos;s intelligently made
              trends based upon your conversation data.
            </Trans>
          </Text>
        </ContentPlaceholder.Body>
        <ContentPlaceholder.Footer>
          <ButtonGroup>
            <ButtonLink to={trendsRoutes.liveTrends()}>
              <Trans>Go to trends</Trans>
            </ButtonLink>
            <CreateTrackerButton>
              <Trans>Add tracker</Trans>
            </CreateTrackerButton>
          </ButtonGroup>
        </ContentPlaceholder.Footer>
      </ContentPlaceholder.Container>
    )
  }

  return (
    <>
      <Box>
        <PageHeading mb={10}>
          <Trans>Trackers</Trans>
        </PageHeading>
      </Box>
      {trackers?.length === 0 ? (
        renderPlaceholder()
      ) : (
        <Box>
          <HStack
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            mb="2"
          >
            <Box minW="14em">
              <FilterTagSelectPopover
                selectedTags={selectedTags}
                setSelectedTags={handleSetSelectedTags}
              >
                <IconButton
                  borderRadius={4}
                  icon={<Icon as={MdFilterList} />}
                  size="sm"
                  aria-label="tag icon"
                />
              </FilterTagSelectPopover>
            </Box>

            <CreateTrackerButton>
              <Trans>Add tracker</Trans>
            </CreateTrackerButton>
          </HStack>
          <Box minW="300px" width="100%" overflowX="auto">
            <Box {...getTableProps()}>
              <Box
                color="textMuted"
                fontWeight="medium"
                borderBottom="2px"
                borderBottomColor="gray.300"
              >
                {headerGroups.map((headerGroup, index) => (
                  <Box
                    pl={4}
                    pr={12}
                    py={2}
                    userSelect="none"
                    {...headerGroup.getHeaderGroupProps()}
                    key={index}
                  >
                    {headerGroup.headers.map((column, columnIndex) => {
                      return (
                        <Flex
                          align="center"
                          pl={3}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                          )}
                          key={columnIndex}
                        >
                          <Box key={column.id}>{column.render('Header')}</Box>
                          {column.isSorted && (
                            <SortDirectionArrow
                              isSortedDesc={column.isSortedDesc}
                              mx={1}
                              key={`SortDirectionArrow-${columnIndex}`}
                            />
                          )}
                        </Flex>
                      )
                    })}
                  </Box>
                ))}
              </Box>
              <List disablePadding {...getTableBodyProps()}>
                {rows.map((row, index) => {
                  prepareRow(row)
                  return (
                    <Tooltip
                      hasArrow
                      placement="top"
                      isDisabled={row.original.accessLevel === 'Edit'}
                      key={index}
                    >
                      <ListItem
                        position="relative"
                        button={true}
                        hasToolTip={row.original.accessLevel !== 'Edit'}
                        isDisabled={row.original.accessLevel !== 'Edit'}
                        onClick={() => onEditTracker(row.original.uid)}
                        hasDivider
                        defaultHideSecondaryAction
                        alignItems="flex-start"
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell, index) => (
                          <Box px={3} {...cell.getCellProps()} key={index}>
                            {cell.render('Cell')}
                          </Box>
                        ))}
                        <ListItemMenu
                          isDisabled={row.original.accessLevel !== 'Edit'}
                        >
                          <Portal>
                            <MenuList>
                              <MenuItem
                                onClick={() =>
                                  onNavigateToAnalytics(row.original)
                                }
                              >
                                <MdLaunch />
                                <Text ml="2">
                                  <Trans>Go to tracker analysis</Trans>
                                </Text>
                              </MenuItem>
                              <MenuDivider />
                              <MenuItem
                                onClick={() => onEditTracker(row.original.uid)}
                              >
                                <MdEdit />
                                <Text ml="2">
                                  <Trans>Edit</Trans>
                                </Text>
                              </MenuItem>
                              <Tooltip
                                label={t`It is not possible to delete the tracker as recent changes are still being processed`}
                                isDisabled={
                                  !(
                                    row.original.isProcessing ||
                                    row.original.isTextProcessing
                                  )
                                }
                              >
                                <MenuItem
                                  isDisabled={
                                    row.original.isProcessing ||
                                    row.original.isTextProcessing
                                  }
                                  onClick={() =>
                                    onDeleteTracker(
                                      row.original.uid,
                                      row.original.name,
                                    )
                                  }
                                >
                                  <MdDelete />
                                  <Text ml="2">
                                    <Trans>Delete</Trans>
                                  </Text>
                                </MenuItem>
                              </Tooltip>
                            </MenuList>
                          </Portal>
                        </ListItemMenu>
                      </ListItem>
                    </Tooltip>
                  )
                })}
              </List>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default TrackersPage
