import request, { RequestOptions, ResponseError } from '@capturi/request'
import {
  RefetchOptions,
  UseQueryOptions,
  UseQueryResult,
  useQueries,
} from '@tanstack/react-query'
import noop from 'lodash/noop'
import pick from 'lodash/pick'
import queryString from 'query-string'
import React from 'react'

import { useSegmentFilterKeys } from '../definitions'
import { PeriodDefinition, useFilterPeriodContext } from '../period'
import { configurations } from '../segmentConfigurations'
import {
  PhoneSegmentState,
  useSegmentStatesContext,
} from '../state/segment-state/useSegmentStates'
import { Segment } from '../types'
import {
  SearchParamsObject,
  toFilterSearchParams,
} from '../utils/toFilterSearchParams'

interface SegmentsResponse<Data, Error> {
  isLoading: boolean
  revalidate: (options?: RefetchOptions) => void
  isEnabled: boolean
  segments: Segment<Data>[]
  error?: Error | null
}
interface SegmentsWithSourceResponse<Data, Error>
  extends SegmentsResponse<Data, Error> {
  source: string
}
/* Suspense does not work together with useQueries - https://github.com/TanStack/query/issues/1523*/
type UseQueriesOptions = Omit<UseQueryOptions, 'suspense'>

type MultiSegmentsResponse<Data, Error> = SegmentsWithSourceResponse<
  Data,
  Error
>[]

type Source = string
type GetEndpointWithSource = (source: Source) => RequestOptions
type GetEndpoint = () => RequestOptions
type GetFilterQueryModel = (
  state: PhoneSegmentState,
  source: Source,
  periodDefinition: PeriodDefinition,
  mapperFn: typeof toFilterSearchParams,
) => SearchParamsObject
export function useFetchMultiSegments<Data = unknown, Error = ResponseError>(
  sources: Source[],
  getEndpoint: GetEndpointWithSource,
  queryOptions?: Partial<UseQueriesOptions>,
): MultiSegmentsResponse<Data, Error>
export function useFetchMultiSegments<Data = unknown, Error = ResponseError>(
  sources: Source[],
  getEndpoint: GetEndpointWithSource,
  getFilterQueryModel?: GetFilterQueryModel,
  queryOptions?: Partial<UseQueriesOptions>,
): MultiSegmentsResponse<Data, Error>
export function useFetchMultiSegments<Data = unknown, Error = ResponseError>(
  sources: Source[],
  getEndpoint: GetEndpointWithSource,
  param3?: GetFilterQueryModel | Partial<UseQueriesOptions>,
  param4?: Partial<UseQueriesOptions>,
): MultiSegmentsResponse<Data, Error> {
  let getFilterQueryModel: GetFilterQueryModel | undefined
  let queryOptions: Partial<UseQueriesOptions> | undefined
  if (typeof param3 === 'function') {
    getFilterQueryModel = param3
    queryOptions = param4
  } else {
    queryOptions = param3 ?? param4
  }

  const { periodDef } = useFilterPeriodContext()
  const { states } = useSegmentStatesContext()
  const { activeKeys } = useSegmentFilterKeys()

  /**
   * Generate a list of query objects for each source.
   * Each source may have several urls for each segment configuration but the result list will be flattened
   * */
  const sourceQueries = (() => {
    return sources.flatMap((source) => {
      const requestOptions = getEndpoint(source)
      return states.map((state, i) => {
        const { color, label } = configurations[i]
        if (state.channel === 'email') {
          return {
            source,
            requestOptions: requestOptions,
            filterQueryModel: {},
            segmentColor: color,
            segmentLabel: label,
            enabled: false,
          }
        }

        const activeStateValues = pick(state.values, activeKeys)
        let filterQueryModel = toFilterSearchParams(
          activeStateValues,
          periodDef,
        )

        if (typeof getFilterQueryModel === 'function') {
          filterQueryModel = getFilterQueryModel?.(
            state,
            source,
            periodDef,
            toFilterSearchParams,
          )
        }

        return {
          source,
          requestOptions,
          filterQueryModel,
          segmentColor: color,
          segmentLabel: label,
          enabled: true,
        }
      })
    }, [])
  })()

  // make queries
  const results = useQueries({
    queries: sourceQueries.map(
      ({ requestOptions, filterQueryModel, enabled }) => {
        const isPostReq = requestOptions.method === 'post'

        return {
          refetchOnWindowFocus: false,
          staleTime: 10 * 1000, // dedupingInterval
          ...queryOptions,
          ...(isPostReq
            ? createPostConfig(requestOptions, filterQueryModel)
            : createGetConfig(requestOptions, filterQueryModel)),
          enabled,
        }
      },
    ),
  }) as UseQueryResult<Data, Error>[]

  // Associate `source` with response data and enhance with segment meta data
  return React.useMemo(() => {
    const obj = results.reduce<{
      [source: string]: SegmentsWithSourceResponse<Data, Error>
    }>((memo, result, i) => {
      if (sourceQueries[i] === undefined) {
        return memo
      }
      const { source, segmentColor, segmentLabel } = sourceQueries[i]
      if (memo[source] === undefined) {
        memo[source] = {
          source,
          segments: [],
          isLoading: false,
          isEnabled: false,
          revalidate: noop,
          error: undefined,
        }
      }

      memo[source].segments.push({
        color: segmentColor,
        label: segmentLabel,
        data: result.isSuccess ? result.data : null,
      })
      memo[source].isLoading = result.isFetching
      memo[source].revalidate = result.refetch
      memo[source].error = result.error

      return memo
    }, {})
    return Object.values(obj)
  }, [results, sourceQueries])
}

function createGetConfig(
  requestOptions: RequestOptions,
  filterQueryModel: SearchParamsObject = {},
): UseQueryOptions {
  const url = queryString.stringifyUrl({
    url: requestOptions.url,
    query: {
      ...(requestOptions.query ?? {}),
      ...filterQueryModel,
    },
  })
  return {
    queryKey: [url],
  }
}

function createPostConfig(
  requestOptions: RequestOptions,
  filterQueryModel: SearchParamsObject = {},
): UseQueryOptions {
  const url = queryString.stringifyUrl({
    url: requestOptions.url,
    query: requestOptions.query ?? {},
  })
  return {
    /**
     * The url itself is not unique enough to be used as query key.
     * Generate a new one as a concatenation of the url and the serialized model
     * */
    queryKey: [
      `${url}:${JSON.stringify(filterQueryModel)}`,
      requestOptions,
      url,
      filterQueryModel,
    ],
    /**
     * switch queryFn with POST requests
     * Filter model needs to be added to the request body
     */
    queryFn: () => {
      return request({
        ...requestOptions,
        url: url,
        json: filterQueryModel,
      })
    },
  }
}

export function useFetchSegments<Data = unknown, Error = ResponseError>(
  getEndpoint: GetEndpoint,
  queryOptions?: Partial<UseQueriesOptions>,
): SegmentsResponse<Data, Error>
export function useFetchSegments<Data = unknown, Error = ResponseError>(
  getEndpoint: GetEndpoint,
  getFilterQueryModel?: GetFilterQueryModel,
  queryOptions?: Partial<UseQueriesOptions>,
): SegmentsResponse<Data, Error>
export function useFetchSegments<Data = unknown, Error = ResponseError>(
  getEndpoint: GetEndpoint,
  param2?: GetFilterQueryModel | Partial<UseQueriesOptions>,
  param3?: Partial<UseQueriesOptions>,
): SegmentsResponse<Data, Error> {
  let getFilterQueryModel: GetFilterQueryModel | undefined
  let queryOptions: Partial<UseQueriesOptions> | undefined
  if (typeof param2 === 'function') {
    getFilterQueryModel = param2
    queryOptions = param3
  } else {
    queryOptions = param2
  }

  const url = getEndpoint().url
  const sources = React.useMemo(() => [url], [url])
  // Call `useFetchMultiSegments` and use `url` as only source. Result is the first entry
  const [result] = useFetchMultiSegments<Data, Error>(
    sources,
    getEndpoint,
    getFilterQueryModel,
    queryOptions,
  )
  return result
}

export function useFetchMultiPhoneSegments<
  Data = unknown,
  Error = ResponseError,
>(
  sources: Source[],
  getEndpoint: GetEndpointWithSource,
  queryOptions?: Partial<UseQueriesOptions>,
): MultiSegmentsResponse<Data, Error>
export function useFetchMultiPhoneSegments<
  Data = unknown,
  Error = ResponseError,
>(
  sources: Source[],
  getEndpoint: GetEndpointWithSource,
  getFilterQueryModel?: GetFilterQueryModel,
  queryOptions?: Partial<UseQueriesOptions>,
): MultiSegmentsResponse<Data, Error>
export function useFetchMultiPhoneSegments<
  Data = unknown,
  Error = ResponseError,
>(
  sources: Source[],
  getEndpoint: GetEndpointWithSource,
  param3?: GetFilterQueryModel | Partial<UseQueriesOptions>,
  param4?: Partial<UseQueriesOptions>,
): MultiSegmentsResponse<Data, Error> {
  let getFilterQueryModel: GetFilterQueryModel | undefined
  let queryOptions: Partial<UseQueriesOptions> | undefined
  if (typeof param3 === 'function') {
    getFilterQueryModel = param3
    queryOptions = param4
  } else {
    queryOptions = param3 ?? param4
  }

  const { periodDef } = useFilterPeriodContext()
  const { phoneSegmentStates, getIndexForState } = useSegmentStatesContext()
  const { activeKeys } = useSegmentFilterKeys()

  /**
   * Generate a list of query objects for each source.
   * Each source may have several urls for each segment configuration but the result list will be flattened
   * */
  const sourceQueries = (() => {
    return sources.flatMap((source) => {
      const requestOptions = getEndpoint(source)
      return phoneSegmentStates.map((state) => {
        const { color, label } = configurations[getIndexForState(state)]

        const activeStateValues = pick(state.values, activeKeys)
        let filterQueryModel = toFilterSearchParams(
          activeStateValues,
          periodDef,
        )
        if (typeof getFilterQueryModel === 'function') {
          filterQueryModel = getFilterQueryModel?.(
            state,
            source,
            periodDef,
            toFilterSearchParams,
          )
        }

        return {
          source,
          requestOptions,
          filterQueryModel,
          segmentColor: color,
          segmentLabel: label,
          enabled: true,
        }
      })
    }, [])
  })()

  // make queries
  const results = useQueries({
    queries: sourceQueries.map(
      ({ requestOptions, filterQueryModel, enabled }) => {
        const isPostReq = requestOptions.method === 'post'
        return {
          refetchOnWindowFocus: false,
          staleTime: 10 * 1000, // dedupingInterval
          ...queryOptions,
          ...(isPostReq
            ? createPostConfig(requestOptions, filterQueryModel)
            : createGetConfig(requestOptions, filterQueryModel)),
          enabled,
        }
      },
    ),
  }) as UseQueryResult<Data, Error>[]

  // Associate `source` with response data and enhance with segment meta data
  return React.useMemo(() => {
    const obj = results.reduce<{
      [source: string]: SegmentsWithSourceResponse<Data, Error>
    }>((memo, result, i) => {
      if (sourceQueries[i] === undefined) {
        return memo
      }
      const { source, segmentColor, segmentLabel, enabled } = sourceQueries[i]
      if (memo[source] === undefined) {
        memo[source] = {
          source,
          segments: [],
          isEnabled: false,
          isLoading: false,
          revalidate: noop,
          error: undefined,
        }
      }

      memo[source].segments.push({
        color: segmentColor,
        label: segmentLabel,
        data: result.isSuccess ? result.data : null,
      })
      memo[source].isLoading = result.isLoading
      memo[source].revalidate = result.refetch
      memo[source].error = result.error
      memo[source].isEnabled = enabled

      return memo
    }, {})
    return Object.values(obj)
  }, [results, sourceQueries])
}

export function useFetchPhoneSegments<Data = unknown, Error = ResponseError>(
  getEndpoint: GetEndpoint,
  queryOptions?: Partial<UseQueriesOptions>,
): SegmentsResponse<Data, Error>
export function useFetchPhoneSegments<Data = unknown, Error = ResponseError>(
  getEndpoint: GetEndpoint,
  getFilterQueryModel?: GetFilterQueryModel,
  queryOptions?: Partial<UseQueriesOptions>,
): SegmentsResponse<Data, Error>
export function useFetchPhoneSegments<Data = unknown, Error = ResponseError>(
  getEndpoint: GetEndpoint,
  param2?: GetFilterQueryModel | Partial<UseQueriesOptions>,
  param3?: Partial<UseQueriesOptions>,
): SegmentsResponse<Data, Error> {
  let getFilterQueryModel: GetFilterQueryModel | undefined
  let queryOptions: Partial<UseQueriesOptions> | undefined
  if (typeof param2 === 'function') {
    getFilterQueryModel = param2
    queryOptions = param3
  } else {
    queryOptions = param2
  }

  const url = getEndpoint().url
  const sources = React.useMemo(() => [url], [url])
  // Call `useFetchMultiSegments` and use `url` as only source. Result is the first entry
  const [result] = useFetchMultiPhoneSegments<Data, Error>(
    sources,
    getEndpoint,
    getFilterQueryModel,
    queryOptions,
  )

  if (result === undefined) {
    return {
      segments: [],
      isEnabled: false,
      isLoading: false,
      revalidate: noop,
      error: undefined,
    }
  }

  return result
}
