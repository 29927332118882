import { Button, useMultiStyleConfig, useTab } from '@chakra-ui/react'
import React from 'react'

type Props = {
  icon: React.ReactElement
  children: React.ReactNode
  onClick?: () => void
  isDisabled?: boolean
}

export const IconTab = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const tabProps = useTab({ ...props, ref })
    const styles = useMultiStyleConfig('Tabs', tabProps)

    return (
      <Button
        isDisabled={props.isDisabled}
        __css={styles.tab}
        {...tabProps}
        leftIcon={props.icon}
        display="flex"
      >
        {tabProps.children}
      </Button>
    )
  },
)
