import {
  PreviewTrackerHitsResponseModel,
  TimeFilterKind,
} from '@capturi/api-trackers'
import { Speaker } from '@capturi/core'
import * as React from 'react'

import { PhraseState } from '../../../types'
import { CacheItem } from './cache'
import { CacheMonitor } from './cache-monitor'

type UseCacheUpdaterReturn = {
  setCacheResults: (
    hashes: { [phrase: string]: string },
    resp: PreviewTrackerHitsResponseModel,
  ) => void
  markAsLoading: (phrase: string, hash: string) => void
}

export function useCacheUpdater(
  monitor: CacheMonitor<CacheItem>,
): UseCacheUpdaterReturn {
  return React.useMemo(() => {
    function setCacheResults(
      hashes: { [phrase: string]: string },
      resp: PreviewTrackerHitsResponseModel,
    ): void {
      Object.keys(hashes).forEach((phrase) => {
        const respEntry = resp.phrases.find((x) => x.phrase === phrase)
        monitor.setItem(phrase, {
          hash: hashes[phrase],
          phrase: phrase,
          conversations: respEntry?.conversations ?? [],
          conversationsTotal: resp.conversationsTotal,
          isLoading: false,
        })
      })
    }

    function markAsLoading(phrase: string, hash: string): void {
      monitor.setItem(phrase, {
        isLoading: true,
        hash,
        phrase,
      })
    }
    return {
      markAsLoading,
      setCacheResults,
    }
  }, [monitor])
}

export const createPhraseStateHash = (
  phrase: PhraseState,
  speakerId: Speaker,
  timeFilterKind: TimeFilterKind,
  timeFilterSeconds: number | undefined,
  timeFilterSecondsEnd: number | null,
): string => {
  return JSON.stringify({
    value: phrase.value,
    settings: phrase.settings,
    speakerId,
    timeFilterKind,
    timeFilterSeconds,
    timeFilterSecondsEnd,
  })
}
