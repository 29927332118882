import { FallbackProps } from '@capturi/react-utils'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'

const HttpErrorBoundaryFallback: React.FC<FallbackProps> = ({ error }) => {
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  const status = (error as any)?.status

  const message = {
    title: t`An error occurred`,
    description: t`An error occurred while preparing the page for you`,
  }
  if (status === 403) {
    message.title = t`Access Denied`
    message.description = t`You do not have permission to access this page.`
  }
  if (status === 404) {
    message.title = t`Not Found`
    message.description = t`The page you are requesting could not be found`
  }

  return (
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
      height="200px"
      bg="gray.200"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        {message.title}
      </AlertTitle>
      <AlertDescription maxWidth="sm">{message.description}</AlertDescription>
    </Alert>
  )
}

export default HttpErrorBoundaryFallback
