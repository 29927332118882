import { useModal } from '@capturi/use-modal'
import React, { useCallback } from 'react'

import { ConfirmationDialog } from './ConfirmationDialog'

export type BaseConfirmDialogProps = {
  onClose: () => void
  onConfirm: () => void
}

export type ShowConfirmModal<P extends BaseConfirmDialogProps> = (
  props?: Omit<P, keyof BaseConfirmDialogProps>,
) => Promise<void>

export const useConfirm = <T extends BaseConfirmDialogProps>(
  component: React.FunctionComponent<T> = ConfirmationDialog as React.FunctionComponent<T>,
): ShowConfirmModal<T> => {
  const [showModal, hideModal] = useModal(component)
  return useCallback(
    (confirmProps) => {
      return new Promise<void>((resolve, reject) => {
        const props: BaseConfirmDialogProps = {
          ...(confirmProps ?? {}),
          onConfirm: () => {
            resolve()
            hideModal()
          },
          onClose: () => {
            reject()
          },
        }
        // FIXME: can't figure out the correct typing here
        // biome-ignore lint/suspicious/noExplicitAny: legacy
        showModal(props as any)
      })
    },
    [showModal, hideModal],
  )
}
