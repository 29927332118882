export const basePath = '/dashboards'

const routes = {
  root: basePath,
  list: (): string => basePath,
  view: (uid: string): string => `${basePath}/${uid}`,
  editor: (uid: string): string => `${basePath}/${uid}/edit`,
}

export default routes
