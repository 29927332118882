import analytics from '@capturi/analytics'
import { ScoreState } from '@capturi/api-filters'
import { useAllScores } from '@capturi/api-scoring'
import { Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import { FilterValueLabel } from '../../components/FilterValueLabel'

export const ScoreValueLabel: React.FC<{
  value: ScoreState[]
}> = ({ value: values }) => {
  const uids: string[] = []
  values.map((val) => {
    uids.push(val.uid)
  })

  const hasValues = !isEmpty(values)
  const { data: scores, isLoading: loading } = useAllScores()
  const isLoading = hasValues && loading

  const scoreValues = uids.map((uid) => {
    const score = (scores ?? []).find((x) => x.uid === uid)
    const accessLevel = score?.accessLevel
    if (accessLevel === 'None') analytics.event('filter_scores_noView')

    return (
      <Text
        as="span"
        color={accessLevel === 'None' ? 'warning' : 'black'}
        key={score?.uid}
      >
        {score?.name ?? '?'}
      </Text>
    )
  })

  return (
    <FilterValueLabel
      name={t`Score`}
      value={scoreValues}
      isLoading={isLoading}
    />
  )
}

export const formatRange = (
  min: number | undefined | null,
  max: number | undefined | null,
): string => {
  if (min != null && min === max) {
    return String(min)
  }
  if (min != null && max != null) {
    return t`Between ${min} and ${max}`
  }
  if (min != null) {
    return t`Minimum ${min}`
  }
  if (max != null) {
    return t`Maximum ${max}`
  }
  return ''
}
