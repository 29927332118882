import {
  DirectionPosition,
  DirectionValue,
  DurationComparator,
  DurationType,
  MultiValueFilter,
  SenderPosition,
  SenderType,
  TextFilterValues,
} from '@capturi/api-filters'
import { useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { DEFAULT_TEXT_STATE } from '../../state/segment-state'
import { FilterEvent, logEvent } from './events'

export type DirectionFilterItem = {
  uid: string
  filterType: 'directionFilters'
  position: DirectionPosition
  values: DirectionValue[] | null
  inverted: boolean
}

export type DurationFilterItem = {
  uid: string
  filterType: 'durationFilters'
  value: number | null
  type: DurationType
  comparator: DurationComparator
}

export type MultiValueFilterItem = {
  uid: string
  filterType:
    | 'emailsFilters'
    | 'externalUidFilters'
    | 'inboxFilters'
    | 'statusFilters'
    | 'tagFilters'
    | 'teamFilters'
    | 'userFilters'
  values: string[] | null
  inverted: boolean
}

export type SenderFilterItem = {
  uid: string
  filterType: 'senderFilters'
  type: SenderType
  position: SenderPosition
  values: string[] | null
  inverted: boolean
}

export type TrackerFilterItem = {
  uid: string
  filterType: 'trackers'
  inverted: boolean
  uids: string[] | null
  phrase: string | null
  segmentLabel?: string
}

export type CustomFieldFilterItem = {
  uid: string
  filterType: 'customFieldFilters'
  inverted: boolean
  field: string
  values: string[] | null
}

export type NonCustomFilterType =
  | DirectionFilterItem['filterType']
  | DurationFilterItem['filterType']
  | MultiValueFilterItem['filterType']
  | SenderFilterItem['filterType']
  | TrackerFilterItem['filterType']

export type FilterType =
  | NonCustomFilterType
  | CustomFieldFilterItem['filterType']

export type TextFilterItem =
  | DirectionFilterItem
  | DurationFilterItem
  | MultiValueFilterItem
  | SenderFilterItem
  | TrackerFilterItem
  | CustomFieldFilterItem

function getInitialValues(type: NonCustomFilterType): TextFilterItem {
  switch (type) {
    case 'directionFilters':
      return {
        uid: uuidv4(),
        filterType: type,
        inverted: false,
        position: DirectionPosition.First,
        values: [],
      }
    case 'durationFilters':
      return {
        uid: uuidv4(),
        filterType: type,
        value: 0,
        type: DurationType.All,
        comparator: DurationComparator.Gt,
      }
    case 'emailsFilters':
    case 'externalUidFilters':
    case 'inboxFilters':
    case 'statusFilters':
    case 'tagFilters':
    case 'teamFilters':
    case 'userFilters':
      return {
        uid: uuidv4(),
        filterType: type,
        inverted: false,
        values: [],
      }
    case 'senderFilters':
      return {
        uid: uuidv4(),
        filterType: type,
        inverted: false,
        type: SenderType.Email,
        position: SenderPosition.First,
        values: [],
      }
    case 'trackers':
      return {
        uid: uuidv4(),
        filterType: type,
        inverted: false,
        phrase: null,
        uids: [],
      }
  }
}

function getInitialValuesForCustomField(field: string): CustomFieldFilterItem {
  return {
    uid: uuidv4(),
    filterType: 'customFieldFilters',
    inverted: false,
    field,
    values: [],
  }
}

export function mapTextFilterItemsToValues(
  items: TextFilterItem[],
): TextFilterValues {
  return items.reduce<TextFilterValues>(
    (acc, item) => {
      switch (item.filterType) {
        case 'directionFilters':
          if ((item.values?.length ?? 0) > 0) {
            acc.directionFilters = [
              ...(acc.directionFilters || []),
              {
                inverted: item.inverted,
                position: item.position,
                values: item.values,
              },
            ]
          }
          break
        case 'durationFilters':
          if (item.value != null) {
            acc.durationFilters = [
              ...(acc.durationFilters || []),
              {
                type: item.type,
                comparator: item.comparator,
                value: item.value,
              },
            ]
          }
          break
        case 'emailsFilters':
        case 'externalUidFilters':
        case 'inboxFilters':
        case 'statusFilters':
        case 'tagFilters':
        case 'teamFilters':
        case 'userFilters':
          if ((item.values?.length ?? 0) > 0) {
            acc[item.filterType] = [
              ...(acc[item.filterType] || []),
              { inverted: item.inverted, values: item.values },
            ]
          }
          break
        case 'senderFilters':
          if ((item.values?.length ?? 0) > 0) {
            acc.senderFilters = [
              ...(acc.senderFilters || []),
              {
                type: item.type,
                position: item.position,
                values: item.values,
                inverted: item.inverted,
              },
            ]
          }
          break
        case 'trackers':
          if (item.uids && (item.uids.length ?? 0) > 0) {
            acc.trackers = [
              ...(acc.trackers || []),
              {
                uids: item.uids,
                phrase: item.phrase,
                inverted: item.inverted,
              },
            ]
          }
          break
        case 'customFieldFilters':
          if ((item.values?.length ?? 0) > 0) {
            acc.customFieldFilters = [
              ...(acc.customFieldFilters || []),
              {
                field: item.field,
                inverted: item.inverted,
                values: item.values,
              },
            ]
          }
          break
      }
      return acc
    },
    { ...DEFAULT_TEXT_STATE },
  )
}

function mapMultiValueFiltersToItems(
  values: MultiValueFilter[],
  type: MultiValueFilterItem['filterType'],
): MultiValueFilterItem[] {
  return values.map<MultiValueFilterItem>((value) => ({
    ...value,
    uid: uuidv4(),
    filterType: type,
  }))
}

function mapTextFilterValuesToItems(
  values: TextFilterValues,
): TextFilterItem[] {
  return [
    ...(values.directionFilters || []).map<DirectionFilterItem>((value) => ({
      ...value,
      uid: uuidv4(),
      filterType: 'directionFilters',
    })),
    ...(values.durationFilters || []).map<DurationFilterItem>((value) => ({
      ...value,
      uid: uuidv4(),
      filterType: 'durationFilters',
    })),
    ...mapMultiValueFiltersToItems(values.emailsFilters, 'emailsFilters'),
    ...mapMultiValueFiltersToItems(
      values.externalUidFilters,
      'externalUidFilters',
    ),
    ...mapMultiValueFiltersToItems(values.inboxFilters, 'inboxFilters'),
    ...(values.senderFilters || []).map<SenderFilterItem>((value) => ({
      ...value,
      uid: uuidv4(),
      filterType: 'senderFilters',
    })),
    ...mapMultiValueFiltersToItems(values.statusFilters, 'statusFilters'),
    ...mapMultiValueFiltersToItems(values.tagFilters, 'tagFilters'),
    ...mapMultiValueFiltersToItems(values.teamFilters, 'teamFilters'),
    ...(values.trackers || []).map<TextFilterItem>((value) => ({
      ...value,
      uid: uuidv4(),
      filterType: 'trackers',
    })),
    ...mapMultiValueFiltersToItems(values.userFilters, 'userFilters'),
    ...(values.customFieldFilters || []).map<CustomFieldFilterItem>(
      (value) => ({
        ...value,
        uid: uuidv4(),
        filterType: 'customFieldFilters',
      }),
    ),
  ]
}

export const useTextFilter = (
  initialValue: TextFilterValues,
  segmentLabel: string,
): {
  items: TextFilterItem[]
  addItem: (type: NonCustomFilterType) => string
  addCustomFieldItem: (field: string) => string
  removeItem: (uid: string) => void
  changeItem: (newItem: TextFilterItem) => void
  filterValues: TextFilterValues
  reinitWithValues: (values: TextFilterValues) => void
} => {
  const [items, setItems] = useState<TextFilterItem[]>(
    mapTextFilterValuesToItems(initialValue),
  )

  return useMemo(() => {
    const addItem = (itemType: NonCustomFilterType): string => {
      const textFilterItem = getInitialValues(itemType)
      setItems((prevItems) => [...prevItems, textFilterItem])
      return textFilterItem.uid
    }

    const addCustomFieldItem = (field: string): string => {
      const textFilterItem = getInitialValuesForCustomField(field)
      setItems((prevItems) => [...prevItems, textFilterItem])
      return textFilterItem.uid
    }

    const removeItem = (uid: string): void => {
      setItems((prevItems) => prevItems.filter((item) => item.uid !== uid))
    }

    const changeItem = (newItem: TextFilterItem): void => {
      setItems((prevItems) =>
        prevItems.map((oldItem) =>
          oldItem.uid === newItem.uid ? newItem : oldItem,
        ),
      )

      logEvent(FilterEvent.SetFilterCriteriaValue, {
        filter: newItem.filterType,
        segmentLabel: segmentLabel,
        isNot: 'inverted' in newItem ? newItem.inverted : false,
        channel: 'E-mail',
      })
    }

    const reinitWithValues = (values: TextFilterValues): void => {
      setItems(mapTextFilterValuesToItems(values))
    }

    return {
      items,
      addItem,
      addCustomFieldItem,
      removeItem,
      changeItem,
      filterValues: mapTextFilterItemsToValues(items),
      reinitWithValues,
    }
  }, [items, segmentLabel])
}
