import {
  PhoneFilterValues,
  Range,
  SavedPhoneFilter,
  useUpdateSavedFilter,
} from '@capturi/api-filters'
import { useCurrentUser } from '@capturi/core'
import {
  CsvConfiguratorModal,
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { IoMdPie } from 'react-icons/io'
import {
  MdClose,
  MdCloudDownload,
  MdMoreVert,
  MdNotificationsActive,
  MdNotificationsOff,
  MdShuffle,
} from 'react-icons/md'

import { FilterChannelButton } from '../../components/FilterChannelButton'
import { SegmentContainerOptions } from '../../containers/SegmentsContainer'
import {
  FilterEvent,
  logEvent,
} from '../../containers/SegmentsContainer/events'
import useSaveFilterState from '../../data/useSaveFilterState'
import useValidateAsSavedSegment from '../../hooks/useValidateAsSavedSegment'
import { toFilterValues } from '../../mappers'
import * as msgs from '../../messageDescriptors'
import { configurations } from '../../segmentConfigurations'
import { DEFAULT_STATE } from '../../state/segment-state/useSegmentStates'
import { Channel, PhoneSegmentBuilderState, SentimentState } from '../../types'
import {
  getFilterCriteriaCount,
  hasFilterValues,
  hasUnsavedChanges,
} from '../../utils/filterState'
import {
  FilterDefinitions,
  PhoneSegmentBuilder,
} from './components/PhoneSegmentBuilder'
import { SavedPhoneFilterButton } from './components/SavedPhoneFilterButton'

type PhoneFilterProps = {
  /**
   * The state to be used in controlled mode
   */
  state?: PhoneSegmentBuilderState
  /**
   * Possible filters and their definitions
   */
  filterDefinitions: FilterDefinitions
  /**
   * The callback fired when the state changes
   */
  onStateChange?: (state: PhoneSegmentBuilderState) => void

  disabledChannels?: Channel[]
  onChannelChange?: (channel: Channel) => void
  /**
   * The callback fired when the current state is persisted as a saved filter
   */
  onStateSaved?: (savedFilter: SavedPhoneFilter, isNew: boolean) => void
  /**
   * The callback fired when the saved filter state changes
   */
  onSavedFilterChange?: (savedFilter: SavedPhoneFilter | undefined) => void
  onStateReset?: (state: PhoneSegmentBuilderState) => void
  /**
   * Color of this segment build
   */
  color?: string
  /**
   * Label of this segment build
   */
  label?: string
} & SegmentContainerOptions

export const PhoneFilter: React.FC<
  React.PropsWithChildren<PhoneFilterProps>
> = ({
  state = { channel: 'phone', values: {} },
  filterDefinitions,
  onStateChange,
  disabledChannels = ['email'],
  onChannelChange,
  onStateSaved,
  onSavedFilterChange,
  onStateReset,
  onToggleRandomizer,
  isRandomizerEnabled,
  color = configurations[0].color,
  label = configurations[0].label,
  showExportButton,
  showNotificationButton: showNotificationButtonProp,
  showResetButton,
  showSaveButton,
  showRandomizerButton,
  initiallyOpened,
  resetSavedFilterOnValuesChange = false,
  isReadOnly,
  allowReselectionWhenReadOnly = false,
  placeholderText,
  children,
}) => {
  const ref = React.useRef<React.ElementRef<typeof PhoneSegmentBuilder>>(null)
  const { channel, values, savedFilter } = state

  const currentUser = useCurrentUser()
  const { mutate: updateFilter, isPending: isUpdatingFilter } =
    useUpdateSavedFilter()
  const toast = useToast()

  /**
   * Reinitialise filter criteria UI components in SegmentBuilder
   * TODO: look into a solution where this is done automatically based on state changes
   * but while keeping the order of criterias added.
   */
  const reinitialiseSegmentBuilderCriterias = (
    values: PhoneFilterValues,
  ): void => {
    ref.current?.initFilterValues(values)
  }

  const handleSavedFilterChange = (savedFilter: SavedPhoneFilter): void => {
    const values = toFilterValues(savedFilter.values ?? {})
    onStateChange?.({
      values,
      savedFilter,
      channel,
    })
    reinitialiseSegmentBuilderCriterias(values)
    onSavedFilterChange?.(savedFilter)
    logEvent(FilterEvent.LoadFilterContext, {
      segmentLabel: label,
    })
  }

  const handleResetSavedFilterClicked = (): void => {
    const state = {
      values: DEFAULT_STATE,
      savedFilter: undefined,
      channel,
    }
    onStateChange?.(state)
    reinitialiseSegmentBuilderCriterias(state.values)
    if (savedFilter !== undefined) {
      onSavedFilterChange?.(undefined)
    }
    onStateReset?.(state)
    logEvent(FilterEvent.ClearFilterContext, {
      segmentLabel: label,
    })
  }

  const handleResetStateClicked = (): void => {
    const state = {
      values: DEFAULT_STATE,
      savedFilter: undefined,
      channel,
    }
    onStateChange?.(state)
    reinitialiseSegmentBuilderCriterias(state.values)
    onStateReset?.(state)
    if (savedFilter !== undefined) {
      onSavedFilterChange?.(undefined)
    }
  }

  const toggleSubscription = async (
    uid: string,
    subscribe: boolean,
  ): Promise<void> => {
    updateFilter(
      {
        uid,
        filter: { subscribe },
      },
      {
        onSuccess: (updatedSavedFilter) => {
          onStateChange?.({
            channel,
            values: state.values,
            savedFilter: updatedSavedFilter,
          })
          logEvent(
            updatedSavedFilter.isSubscribed
              ? FilterEvent.Subscribe
              : FilterEvent.UnSubscribe,
            {
              segmentLabel: label,
            },
          )
          toast({
            title: updatedSavedFilter.isSubscribed
              ? t`Notifications are enabled for this segment.`
              : t`Notifications are disabled for this segment.`,
            status: 'success',
          })
        },
        onError: () => {
          toast({
            title: t`Could not update subscription status`,
            status: 'error',
          })
        },
      },
    )
  }

  const { saveNew, save } = useSaveFilterState({
    savedFilter: savedFilter as SavedPhoneFilter,
    onFilterSaved: (isNew, savedFilter) => {
      const values = toFilterValues(savedFilter?.values ?? {})
      onStateChange?.({
        channel,
        values,
        savedFilter,
      })
      onStateSaved?.(savedFilter, isNew)
      if (isNew) {
        logEvent(FilterEvent.CreateFilter, {
          subscribe: savedFilter?.isSubscribed,
          filterCriteriaCount: getFilterCriteriaCount(values),
          segmentLabel: label,
        })
      } else {
        logEvent(FilterEvent.SaveFilter, {
          filterCriteriaCount: getFilterCriteriaCount(values),
          segmentLabel: label,
        })
      }
    },
  })

  // Does any of the filter state keys have any values
  const hasValues = React.useMemo(() => hasFilterValues(values), [values])

  const notificationToggletooltipMsg = savedFilter
    ? savedFilter?.isSubscribed
      ? i18n._(msgs.notifications.enabled())
      : i18n._(msgs.notifications.disabled())
    : i18n._(msgs.notifications.savedFilterNotSelected())

  const { isSaveDisabled, saveDisabledReason } = useSaveButtonState(state)
  const { notValidSegmentReason } = useValidateAsSavedSegment(state)
  const showNotificationButton =
    showNotificationButtonProp && (hasValues || savedFilter != null)

  const {
    isOpen: isContextMenuOpen,
    onOpen: onOpenContextMenu,
    onClose: onCloseContextMenu,
  } = useDisclosure()

  const {
    isOpen: isSaveSegmentMenuOpen,
    onOpen: onOpenSaveSegmentMenu,
    onClose: onCloseSaveSegmentMenu,
  } = useDisclosure()

  const closeAllMenus = React.useCallback(() => {
    onCloseSaveSegmentMenu()
    onCloseContextMenu()
  }, [onCloseSaveSegmentMenu, onCloseContextMenu])

  const handleChannelChange = React.useCallback(
    (channel: Channel): void => {
      onChannelChange?.(channel)
    },
    [onChannelChange],
  )

  return (
    <Box>
      <Flex
        align="center"
        wrap="wrap"
        gap={2}
        my={2}
        w="100%"
        data-stonly="filter"
      >
        <FilterChannelButton
          channel={channel}
          color={color}
          disabledChannels={disabledChannels}
          onChannelChange={handleChannelChange}
        />
        {savedFilter && (
          <SavedPhoneFilterButton
            segmentTooltipLabel={
              notValidSegmentReason !== undefined
                ? notValidSegmentReason
                : t`Select a segment`
            }
            notValidSegmentReason={notValidSegmentReason}
            segmentColor={color}
            savedFilter={savedFilter as SavedPhoneFilter}
            onChangeSavedFilter={handleSavedFilterChange}
            onResetSavedFilter={handleResetSavedFilterClicked}
            initiallyOpened={initiallyOpened}
            isReadOnly={isReadOnly}
            allowReselectionWhenReadOnly={allowReselectionWhenReadOnly}
            placeholderText={placeholderText}
          />
        )}
        <PhoneSegmentBuilder
          ref={ref}
          isReadOnly={isReadOnly}
          filterDefinitions={filterDefinitions}
          savedFilter={savedFilter as SavedPhoneFilter}
          onChangeSavedFilter={handleSavedFilterChange}
          state={state.values}
          onStateChange={(values) =>
            onStateChange?.({
              channel,
              values,
              savedFilter: resetSavedFilterOnValuesChange
                ? undefined
                : savedFilter,
            })
          }
          onAddFilterCriteria={(filterName) =>
            logEvent(FilterEvent.AddFilterCriteria, {
              filterName,
              segmentLabel: label,
              channel: 'Phone',
            })
          }
          onFilterCriteriaValueReset={(filterName) =>
            logEvent(FilterEvent.ResetFilterCriteriaValue, {
              filterName,
              segmentLabel: label,
              channel: 'Phone',
            })
          }
          onRemoveFilterCriteria={(filterName) =>
            logEvent(FilterEvent.RemoveFilterCriteria, {
              filterName,
              segmentLabel: label,
              channel: 'Phone',
            })
          }
          onFilterCriteriaValueChange={(
            filterName,
            _filterDefinition,
            newValue,
          ) => {
            const value = (() => {
              if (newValue == null) return undefined
              switch (filterName as keyof PhoneFilterValues) {
                case 'duration': {
                  const v = newValue as Range
                  return [v.min, v.max].join(':')
                }
                case 'sentiment': {
                  const v = newValue as SentimentState
                  return `${v.score}:${v.speaker}`
                }
                case 'status': {
                  return String(newValue)
                }
                default:
                  return undefined
              }
            })()
            logEvent(FilterEvent.SetFilterCriteriaValue, {
              filter: filterName,
              segmentLabel: label,
              channel: 'Phone',
              ...(value ? { value } : {}),
            })
          }}
        />

        <Stack direction="row" spacing="0.5" align="center">
          {showResetButton && (
            <IconButton
              aria-label={t`Reset`}
              icon={<MdClose />}
              onClick={handleResetStateClicked}
              variant="ghost"
              size="xs"
            />
          )}
          {((showSaveButton && currentUser.permissions.editSegment) ||
            showExportButton ||
            showNotificationButton) && (
            <Box>
              {/* Box wrapper ensures we do not apply margins to Menu component (unsupported) */}
              <Menu
                autoSelect={false}
                isOpen={isContextMenuOpen}
                onClose={closeAllMenus}
              >
                <MenuButton
                  as={IconButton}
                  icon={<MdMoreVert />}
                  variant="ghost"
                  size="xs"
                  onClick={onOpenContextMenu}
                  data-stonly="filter three dot"
                />
                <MenuList minW="auto">
                  {showSaveButton && currentUser.permissions.editSegment && (
                    <Tooltip
                      hasArrow
                      label={saveDisabledReason}
                      aria-label={saveDisabledReason}
                      placement="left"
                    >
                      {savedFilter ? (
                        <>
                          <MenuItem
                            icon={<IoMdPie />}
                            isDisabled={isSaveDisabled}
                            onClick={onOpenSaveSegmentMenu}
                            closeOnSelect={false}
                          >
                            <Trans>Save segment</Trans>
                          </MenuItem>

                          <Menu
                            placement="auto-start"
                            isOpen={isSaveSegmentMenuOpen}
                            onClose={closeAllMenus}
                          >
                            <MenuList>
                              <Tooltip
                                label={t`You don't have edit rights for this segment`}
                                isDisabled={savedFilter.accessLevel === 'Edit'}
                                hasArrow
                                placement="top"
                              >
                                <MenuItem
                                  closeOnSelect={true}
                                  onClick={() => {
                                    save(state.values)
                                    closeAllMenus()
                                  }}
                                  isDisabled={
                                    savedFilter.accessLevel !== 'Edit'
                                  }
                                >
                                  <span>
                                    <Trans>
                                      Save to <b>{savedFilter.name}</b>
                                    </Trans>
                                  </span>
                                </MenuItem>
                              </Tooltip>
                              <MenuItem
                                closeOnSelect={true}
                                onClick={() => {
                                  closeAllMenus()
                                  saveNew(state.values as PhoneFilterValues)
                                }}
                              >
                                <Trans>Save as new segment</Trans>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </>
                      ) : (
                        <MenuItem
                          icon={<IoMdPie />}
                          isDisabled={isSaveDisabled}
                          onClick={() => {
                            closeAllMenus()
                            saveNew(state.values as PhoneFilterValues)
                          }}
                        >
                          <Trans>Save segment</Trans>
                        </MenuItem>
                      )}
                    </Tooltip>
                  )}
                  {showExportButton && <ExportButton />}
                  {showNotificationButton && (
                    <Tooltip
                      hasArrow
                      label={notificationToggletooltipMsg}
                      aria-label={notificationToggletooltipMsg}
                      openDelay={300}
                      placement="left"
                    >
                      <MenuItem
                        icon={
                          savedFilter?.isSubscribed === true ? (
                            <MdNotificationsActive />
                          ) : (
                            <MdNotificationsOff />
                          )
                        }
                        isDisabled={isUpdatingFilter || savedFilter == null}
                        onClick={() => {
                          if (savedFilter != null) {
                            toggleSubscription(
                              savedFilter.uid,
                              !savedFilter.isSubscribed,
                            )
                          } else {
                            saveNew(values as PhoneFilterValues)
                          }
                        }}
                      >
                        <Trans>Notification</Trans>
                      </MenuItem>
                    </Tooltip>
                  )}
                  {showRandomizerButton && (
                    <MenuItem
                      icon={<MdShuffle />}
                      onClick={() => onToggleRandomizer?.('on')}
                    >
                      <Trans>Enable quality assurance</Trans>
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </Box>
          )}
          {children}
        </Stack>
      </Flex>
      {/* TODO: what color is this? */}
      {isRandomizerEnabled && (
        <Tag size="md" variant="solid" bg="#C1D2DC" color="InfoText">
          <MdShuffle />
          <TagLabel ml="2">
            <Trans>Quality assurance</Trans>
          </TagLabel>
          <TagCloseButton onClick={() => onToggleRandomizer?.('off')} />
        </Tag>
      )}
    </Box>
  )
}

const ExportButton: React.FC = () => {
  const [openCsvConfigurator] = useModal(CsvConfiguratorModal)
  const segmentState = useFirstPhoneSegmentState()
  const { period } = useFilterPeriodContext()

  return (
    <Tooltip
      hasArrow
      label={t`Export conversations in CSV format`}
      aria-label={t`Export conversations in CSV format`}
      openDelay={300}
      placement="left"
    >
      <MenuItem
        icon={<MdCloudDownload />}
        onClick={() =>
          openCsvConfigurator({
            period: period,
            filterValues: segmentState.values,
          })
        }
      >
        <Trans>Export</Trans>
      </MenuItem>
    </Tooltip>
  )
}

function useSaveButtonState(state: PhoneSegmentBuilderState): {
  isSaveDisabled: boolean
  saveDisabledReason: string | undefined
} {
  const { values, savedFilter } = state
  const validAsSavedSegmentResult = useValidateAsSavedSegment(state)

  // Does any of the filter state keys have any values
  const hasValues = React.useMemo(() => hasFilterValues(values), [values])

  // Does any of the filter state keys have any values
  const hasUnsavedValues = hasUnsavedChanges(state)

  const isInvalidAsSavedSegment =
    typeof validAsSavedSegmentResult === 'boolean'
      ? validAsSavedSegmentResult
      : validAsSavedSegmentResult?.isValid === false

  const saveDisabledReason =
    typeof validAsSavedSegmentResult !== 'boolean'
      ? validAsSavedSegmentResult?.notValidReason
      : undefined

  const isSaveDisabled =
    // 1. Invalid as saved segment
    isInvalidAsSavedSegment ||
    // 2. Does not have any values to save
    !hasValues ||
    // 3. No unsaved changes
    (savedFilter != null && !hasUnsavedValues)

  return {
    isSaveDisabled,
    saveDisabledReason,
  }
}
