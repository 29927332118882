import { useTeams } from '@capturi/core'
import { InvertibleMultiSelectFooter } from '@capturi/ui-select'
import { Plural, t } from '@lingui/macro'
import React, { useCallback } from 'react'

import { FilterValueLabel } from '../../../components/FilterValueLabel'
import useTeamsFilterOptions from '../../../data/useTeamsFilterOptions'
import { TeamSelect } from '../../../filters'
import { defaultSWRConfig } from '../../../filters/constants'
import { MultiValueFilterItem } from '../useTextFilter'
import { TextFilterPopoverComponentProps } from './types'

export const TeamsValueLabel: React.FC<{
  inverted: boolean
  uids?: string[] | null
}> = ({ inverted, uids }) => {
  const { getTeamByUid } = useTeams()
  const names = (uids ?? []).map((uid) => getTeamByUid(uid)?.name ?? uid)
  return <FilterValueLabel name={t`Team`} inverted={inverted} value={names} />
}

export const TeamSelectFilterComponent: React.FC<
  TextFilterPopoverComponentProps<MultiValueFilterItem>
> = ({ item, onChangeValue, onClose }) => {
  const { teams } = useTeamsFilterOptions(defaultSWRConfig)

  const handleSetValue = useCallback(
    (newValue: string[] | undefined) => {
      onChangeValue({
        ...item,
        values: newValue ?? null,
      })
    },
    [item, onChangeValue],
  )

  return (
    <>
      <TeamSelect
        value={item.values}
        onClose={onClose}
        setValue={handleSetValue}
        options={{ isMulti: true }}
        showFooter={false}
      />
      <InvertibleMultiSelectFooter
        entityName={t`Team(s)`}
        isNot={item.inverted}
        onChangeIsNot={(isNot) => {
          onChangeValue({
            ...item,
            inverted: isNot,
          })
        }}
        onReset={() =>
          onChangeValue({
            ...item,
            values: [],
          })
        }
        onSubmit={onClose}
        onSelectAllOptions={() =>
          onChangeValue({
            ...item,
            values: teams.map((team) => team.uid),
          })
        }
        selectAllText={t`Select all`}
        selectedCount={item.values?.length || 0}
        formatSelectedCount={(count: string | number) => (
          <Plural value={count} one={'1 selected'} other={'# selected'} />
        )}
      />
    </>
  )
}
