import analytics from '@capturi/analytics'
import {
  sanitizeFilter,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import { Flex, HStack, Heading, Link } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import qs from 'query-string'
import { FC } from 'react'

import conversationsRoutes from '../../Conversations/routes'
import { useAverageDuration } from '../hooks/useAverageDuration'
import { LongConversationsExampleList } from './components/LongConversationsExampleList'
import { useLongConversationExamples } from './hooks/useLongConversationExamples'

export const LongConversationsExampleWidget: FC = () => {
  const { data, isLoading } = useLongConversationExamples()
  const { data: averageDuration } = useAverageDuration()
  const hasAverageDuration = !!averageDuration && averageDuration > 0
  const { periodDef } = useFilterPeriodContext()
  const { states } = useSegmentStatesContext()

  if (!data?.length && !isLoading) {
    return null
  }
  const sanitizedFilter = sanitizeFilter(states[0])
  const filterSearchParams = averageDuration
    ? { ...sanitizedFilter?.values, duration: { min: averageDuration * 2 } }
    : sanitizedFilter?.values

  const url = qs.stringifyUrl({
    url: conversationsRoutes.list(),
    query: {
      filter: JSON.stringify(filterSearchParams),
      period: periodDef.name,
      channel: 'phone',
    },
  })

  return (
    <Flex flexDir="column">
      <HStack justify="space-between">
        <Heading
          fontSize="2xl"
          textShadow="textExtended"
          mb={3}
          data-stonly="sentiment_conversations"
          width="fit-content"
        >
          <Trans>Long conversation examples</Trans>
        </Heading>
        {hasAverageDuration && (
          <Link
            href={hasAverageDuration ? url : undefined}
            onClick={() =>
              analytics.event('long_conversations_example_explore_more')
            }
            fontSize="xs"
            variant="primary"
            fontWeight="medium"
          >
            <Trans>Explore more</Trans>
          </Link>
        )}
      </HStack>
      <LongConversationsExampleList />
    </Flex>
  )
}
