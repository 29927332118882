import {
  FormControl,
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
} from '@chakra-ui/react'
import { Plural, Trans } from '@lingui/macro'
import React from 'react'

type Props = {
  onChange: (value: number, timeUnit: 'days' | 'hours') => void
  value: { value: number; timeUnit: 'days' | 'hours' }
  title: string
}

const RepeatCallsCriteria: React.FC<Props> = ({ onChange, value, title }) => {
  return (
    <FormControl>
      <FormLabel fontSize="xs" mb={0} textTransform="uppercase">
        <Trans>Criteria</Trans>
      </FormLabel>
      <HStack>
        <Text>{title}</Text>
        <NumberInput
          min={1}
          max={100}
          size="xs"
          onChange={(_, valueAsNumber) => {
            onChange(valueAsNumber, value.timeUnit)
          }}
          value={value.value}
        >
          <NumberInputField h="24px" w="48px" p={2} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Select
          size="xs"
          width="80px"
          value={value.timeUnit}
          onChange={(e) => {
            const timeUnit = e.target.value as 'days' | 'hours'
            onChange(value.value, timeUnit)
          }}
        >
          <option value="days">
            <Plural value={value.value} one="day" other="days" />
          </option>
          <option value="hours">
            <Plural value={value.value} one="hour" other="hours" />
          </option>
        </Select>
      </HStack>
    </FormControl>
  )
}

export default RepeatCallsCriteria
