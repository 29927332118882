import { Tracker } from '@capturi/api-conversations'
import { BaseTracker, isTrackerPrivate } from '@capturi/api-trackers'
import { ShapeMark } from '@capturi/sharing'
import { Box, Flex, Icon, Text, Tooltip, useTheme } from '@chakra-ui/react'
import { getColor } from '@chakra-ui/theme-tools'
import { t } from '@lingui/macro'
import React, { useRef } from 'react'
import { MdVisibilityOff } from 'react-icons/md'

import TrackerMetadata from '../TrackerMetadata'

type Props = {
  trackerHit: Tracker
  tracker: BaseTracker | undefined
  hitMap: Map<string, TrackerMetadata>
}

const OverviewTrackerContent: React.FC<Props> = ({
  trackerHit,
  tracker,
  hitMap,
}) => {
  const theme = useTheme()
  const handleGetColorValue = React.useCallback(
    (color: string) => {
      return getColor(theme, color)
    },
    [theme],
  )
  const textRef = useRef<HTMLParagraphElement>(null)
  const hideTooltip =
    textRef.current?.offsetWidth !== undefined &&
    textRef.current.offsetWidth < 220

  return (
    <Flex gap="1" align="center">
      <Box transform="translateY(1px)">
        <ShapeMark
          color={hitMap.get(trackerHit.uid)?.color}
          shape={hitMap.get(trackerHit.uid)?.shape}
          outline={false}
          getColorValue={handleGetColorValue}
        />
      </Box>

      <Tooltip
        label={`${tracker?.name} (${hitMap.get(trackerHit.uid)?.count ?? 0})`}
        hasArrow
        placement="top"
        isDisabled={hideTooltip}
      >
        <Flex gap="1">
          <Text
            fontWeight="normal"
            wordBreak="break-word"
            textOverflow="ellipsis"
            overflow="hidden"
            maxW="100px"
            ref={textRef}
          >{`${tracker?.name ?? t`Tracker not found`} `}</Text>

          <Text fontWeight="normal" overflow="hidden">{`(${
            hitMap.get(trackerHit.uid)?.count ?? 0
          })`}</Text>
        </Flex>
      </Tooltip>
      {tracker && isTrackerPrivate(tracker) && (
        <Box ml="1" transform="translateY(2px)">
          <Icon as={MdVisibilityOff} />
        </Box>
      )}
    </Flex>
  )
}

export default OverviewTrackerContent
