import analytics from '@capturi/analytics'
import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { memo } from 'react'
import { ScoreData } from './ScoreList'

type Props = { score: ScoreData; onScoreClick: (scoreUid: string) => void }

const ScoreListItem: React.FC<Props> = memo(({ score, onScoreClick }) => {
  return (
    <Flex
      py={4}
      w="100%"
      align="center"
      justify="center"
      boxShadow="0px 16px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04)"
      borderRadius={4}
      bg="accents.lightBackground.default"
      _hover={{ bg: 'gray.100', transform: 'scale(1.02)' }}
      transition="all 0.2s ease-in-out"
      cursor="pointer"
      onClick={() => {
        onScoreClick(score.uid)
        analytics.event('score_widget_navigate_to_score', {
          scoreUid: score.uid,
        })
      }}
    >
      <HStack justify="space-between" w="100%" px={3} align="end">
        <Box
          opacity={1}
          borderRadius="999px"
          w="1"
          alignSelf="stretch"
          background="accents.darkBackground.default"
        />
        <VStack align="start" gap={0}>
          <HStack>
            <Box fontSize="20px" fontWeight="medium" lineHeight="1.2">
              {score.averageScore}/
              <Box as="span" fontSize="xs" color="gray.600">
                {score.maxPossibleScore}
              </Box>
            </Box>
          </HStack>
          <Box>{score.name}</Box>
        </VStack>
        <VStack alignItems="end" flex={1} justify="end">
          <Text color="gray.600">
            {score.conversationsTotal} <Trans>conversations</Trans>
          </Text>
        </VStack>
      </HStack>
    </Flex>
  )
})

export default ScoreListItem
