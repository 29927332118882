import { SpeakerTrackerTeamHitsResponseModel } from '@capturi/api-insights'
import { useTeams } from '@capturi/core'
import {
  PhoneSegmentState,
  Segment,
  SegmentState,
  TextSegmentState,
} from '@capturi/filters'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

import { TeamSegments } from '../../shared/types'

export type Datum = {
  targets: number
  hits: number
  hitRate: number
  stateIndex: number
}

export type TeamData = TeamSegments<Datum>

export type TextTrackerTeamHitsResponseModel = {
  teams: {
    cases: number
    casesWithHit: number
    casesWithHitPercent: number
    teamUid: string
  }[]
}

export type TeamItemTargetHitsModel = {
  teamHits: {
    targets: number
    targetsWithHit: number
    targetsWithHitPercent: number
    teamUid: string
  }[]
}

function convertSpeakerTrackerTeamHitToTeamItemTargetHit(
  speakerTrackerUserHit: SpeakerTrackerTeamHitsResponseModel | null,
): TeamItemTargetHitsModel | null {
  return speakerTrackerUserHit
    ? {
        teamHits: speakerTrackerUserHit.teams.map((team) => ({
          targets: team.conversations,
          targetsWithHit: team.conversationsWithHit,
          targetsWithHitPercent: team.conversationsWithHitPercent,
          teamUid: team.teamUid,
        })),
      }
    : null
}

function convertTextTrackerTeamHitToTeamItemTargetHit(
  textTrackerTeamHit: TextTrackerTeamHitsResponseModel | null,
): TeamItemTargetHitsModel | null {
  return textTrackerTeamHit
    ? {
        teamHits: textTrackerTeamHit.teams.map((team) => ({
          targets: team.cases,
          targetsWithHit: team.casesWithHit,
          targetsWithHitPercent: team.casesWithHitPercent,
          teamUid: team.teamUid,
        })),
      }
    : null
}

export function convertHitsToTeamItemSegments(
  phoneSegmentStates: PhoneSegmentState[],
  phoneSegments: Segment<SpeakerTrackerTeamHitsResponseModel>[],
  textSegmentStates: TextSegmentState[],
  textSegments: Segment<TextTrackerTeamHitsResponseModel>[],
  getIndexForState: (state: SegmentState) => number,
): Segment<TeamItemTargetHitsModel>[] {
  const segments = [
    ...phoneSegments.map<Segment<TeamItemTargetHitsModel> & { index: number }>(
      (x, index) => ({
        ...x,
        index: getIndexForState(phoneSegmentStates[index]),
        data: convertSpeakerTrackerTeamHitToTeamItemTargetHit(x.data),
      }),
    ),
    ...textSegments.map<Segment<TeamItemTargetHitsModel> & { index: number }>(
      (x, index) => ({
        ...x,
        index: getIndexForState(textSegmentStates[index]),
        data: convertTextTrackerTeamHitToTeamItemTargetHit(x.data),
      }),
    ),
  ]
  // ensure segments are sorted by state.index, important for Datum
  const sortedSegments = segments.sort((a, b) => a.index - b.index)
  return sortedSegments.map(({ index: _, ...segment }) => segment)
}

export function useTeamHits(
  segments: Segment<TeamItemTargetHitsModel>[],
): TeamData[] {
  const { getTeamByUid } = useTeams()

  return useMemo(() => {
    const allTeamUids = new Set(
      segments.flatMap((s) => (s.data?.teamHits ?? []).map((x) => x.teamUid)),
    )

    const hitMaps = segments.map<{ [key: string]: Datum }>((s, index) => {
      return (s.data?.teamHits ?? []).reduce<{
        [key: string]: Datum
      }>((acc, x) => {
        acc[x.teamUid] = {
          targets: x.targets,
          hits: x.targetsWithHit,
          hitRate: x.targetsWithHitPercent / 100,
          stateIndex: index,
        }
        return acc
      }, {})
    })

    const segmentData = [...allTeamUids].reduce<{ [key: string]: TeamData }>(
      (acc, teamUid) => {
        acc[teamUid] = {
          segments: segments.reduce<Segment<Datum>[]>((acc, s, i) => {
            const map = hitMaps[i][teamUid]
            if (map != null) {
              acc.push({
                color: s.color,
                label: s.label,
                data: map,
              })
            }
            return acc
          }, []),
          team: getTeamByUid(teamUid) ?? {
            uid: teamUid,
            name: 'N/A',
            members: [],
          },
        }
        return acc
      },
      {},
    )

    const data = orderBy(
      Object.values(segmentData),
      [(x) => x.segments[0]?.data?.hitRate, (x) => x.team.name],
      ['desc', 'asc'],
    )

    return data
  }, [segments, getTeamByUid])
}
