import {
  OrganizationMetadataSettings,
  useOrganizationSettings,
} from '@capturi/api-organization'
import { useCurrentUser } from '@capturi/core'
import {
  getLocalizedCustomPropLabel,
  getLocalizedCustomPropValue,
} from '@capturi/ui-components'
import React from 'react'

import { FilterValueLabel } from '../../components/FilterValueLabel'

export type CustomPropFilterValueLabelProps = {
  filterKey: keyof OrganizationMetadataSettings
  value?: unknown
}

export function CustomPropFilterValueLabel({
  filterKey,
  value,
}: CustomPropFilterValueLabelProps): React.ReactElement | null {
  const currentUser = useCurrentUser()
  const { data } = useOrganizationSettings()

  const settings = data?.metadata[filterKey]
  const locale = currentUser.language
  const name =
    settings != null
      ? getLocalizedCustomPropLabel(settings.label, locale) ?? '?'
      : '?'

  const strValue = getLocalizedCustomPropValue(
    filterKey,
    locale,
    data?.metadata,
    value,
  )

  return <FilterValueLabel name={name} value={strValue} />
}
