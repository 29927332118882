export default {
  baseStyle: {
    content: {
      borderColor: 'gray.400',
      boxShadow: 'lg',
    },
  },
  variants: {
    responsive: {
      content: {
        width: 'unset',
      },
    },
  },
}
