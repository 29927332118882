import { useCurrentUser } from '@capturi/core'
import {
  FilterPeriodSelectContainer,
  MultiSegmentContainer,
  useFilterDefinitions,
} from '@capturi/filters'
import { usePageTitle } from '@capturi/react-utils'
import { PageHeading } from '@capturi/ui-components'
import { Box, Flex } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

const DataTabs = React.lazy(() => import('./DataTabs'))

const ActivityPage: React.FC = () => {
  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  usePageTitle(t`Activity`)
  return (
    <Box>
      <Flex align="start" justify="space-between">
        <PageHeading>
          <Trans>Activity</Trans>
        </PageHeading>
        <FilterPeriodSelectContainer />
      </Flex>
      <Box my={8}>
        <MultiSegmentContainer filterDefinitions={filterDefinitions} />
      </Box>
      <Box my={6}>
        <DataTabs />
      </Box>
    </Box>
  )
}

export default ActivityPage
