import { HStack, Icon, Text } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { FC, memo } from 'react'
import { MdInfoOutline } from 'react-icons/md'
import { pctFormat } from '../../analytics/shared/utils'

export const UnqualifiedAmount: FC<{
  amount: number
  totalAmount: number
}> = memo(({ amount, totalAmount }) => {
  const unqualifiedAmount = totalAmount - amount
  const unqualifiedPercentage =
    totalAmount > 0
      ? ` (${i18n.number(unqualifiedAmount / totalAmount, pctFormat)})`
      : ''

  if (unqualifiedAmount <= 0) {
    return null
  }

  return (
    <HStack>
      <Icon as={MdInfoOutline} boxSize={4} />
      <Text color="gray.600" fontSize="xs" fontStyle="italic">
        <Trans>
          {unqualifiedAmount}
          {unqualifiedPercentage} conversations does not qualify to be
          classified
        </Trans>
      </Text>
    </HStack>
  )
})
