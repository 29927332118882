import { Text } from '@chakra-ui/react'
import identity from 'lodash/identity'
import { CellProps, ColumnWithLooseAccessor } from 'react-table'

import { Cell, MultiValueCell } from '../components/Cell'
import { ColumnDefinition, DataType } from '../types'

export default function valueColumn<T extends DataType>(
  c: ColumnDefinition<T>,
  // biome-ignore lint/suspicious/noExplicitAny: legacy
): ColumnWithLooseAccessor<any> {
  const isMultiValue = c.getSegmentValue !== undefined
  const accessor = isMultiValue ? (d: T) => d.segments : c.getValue
  const formatValue = c.formatValue ?? identity
  const id = c.id ?? 'value'
  return {
    id,
    accessor,
    width: c.width ?? 75,
    Cell: function ValueCell({ row, column }: CellProps<T, string>) {
      if (isMultiValue) {
        const segments = row.values[id] as T['segments']
        return (
          <MultiValueCell values={segments} alignRight={column.alignRight}>
            {(s) => (
              <Text key={s.label}>{formatValue(c.getSegmentValue?.(s))}</Text>
            )}
          </MultiValueCell>
        )
      }
      return <Cell>{row.values[id]}</Cell>
    },
    alignRight: c.alignRight,
  }
}
