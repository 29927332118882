import request, { ResponseError } from '@capturi/request'
import {
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryResult,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query'

import { InaccessibleFilter, SavedPhoneFilter } from '../../models'
import { url, cacheKey } from './constants'

export type SavedFilterResponse = {
  savedFilterGroups: SavedPhoneFilter[]
}

type SavedInaccessibleFilterResponse = {
  savedFilterGroups: (SavedPhoneFilter | InaccessibleFilter)[]
}

const selectorOnlyAccessible = (
  data: SavedFilterResponse,
): SavedPhoneFilter[] => {
  return data.savedFilterGroups.filter((d) => !!d.permissionPreset)
}

export const useSavedFilter = ({
  uid,
  options,
}: {
  uid: string | null | undefined
  options?: Partial<
    UseQueryOptions<SavedPhoneFilter | undefined, ResponseError>
  >
}): UseQueryResult<SavedPhoneFilter | undefined, ResponseError> => {
  return useQuery({
    queryKey: [uid],
    queryFn: () =>
      request.get<SavedPhoneFilter>(`filters/${uid}?api-version=3.3`),
    enabled: !!uid,
    ...options,
  })
}

const selectorAll = (
  data: SavedInaccessibleFilterResponse,
): (SavedPhoneFilter | InaccessibleFilter)[] => {
  return data.savedFilterGroups
}

export const useSavedFilters = (): UseSuspenseQueryResult<
  SavedPhoneFilter[],
  ResponseError
> =>
  useSuspenseQuery({
    queryKey: [cacheKey],
    queryFn: () =>
      request.get<SavedFilterResponse>(`${url}&includeInaccessible=true`),
    select: selectorOnlyAccessible,
  })

export const useAllSavedFilters = (): UseSuspenseQueryResult<
  (SavedPhoneFilter | InaccessibleFilter)[],
  ResponseError
> =>
  useSuspenseQuery({
    queryKey: [cacheKey],
    queryFn: () =>
      request.get<SavedInaccessibleFilterResponse>(
        `${url}&includeInaccessible=true`,
      ),
    select: selectorAll,
  })
