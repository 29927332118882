import { TagsResponseModel, trackersAPI } from '@capturi/api-trackers'
import { useAPI } from '@capturi/api-utils'
import { FormLabel } from '@capturi/ui-components'
import {
  CreatableSelect,
  OnChangeValue,
  SelectOption,
} from '@capturi/ui-select'
import {
  Flex,
  FormControl,
  Icon,
  Text,
  Tooltip,
  chakra,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { Controller } from 'react-hook-form'
import { MdInfoOutline, MdLabel } from 'react-icons/md'

function convertToSelectOption(val: string): SelectOption {
  return {
    value: val.toLowerCase(),
    label: capitalize(val),
  }
}
export const TagsField: React.FC = () => {
  const { data } = useAPI<TagsResponseModel>(() => trackersAPI.getTags(), {
    suspense: false,
  })

  const options = React.useMemo(() => {
    return (data?.tags ?? []).map(convertToSelectOption)
  }, [data])

  const NoTagOptionsMessage = (): React.ReactElement => {
    return (
      <Flex direction="column" p={4}>
        <Text>
          <Trans>No tags have been created yet.</Trans>
        </Text>
        <Text>
          <Trans>Create a new tag by writing the name of it.</Trans>
        </Text>
      </Flex>
    )
  }

  return (
    <FormControl>
      <FormLabel
        htmlFor="tracker_tags"
        leftIcon={<MdLabel />}
        data-stonly="tracker_tags"
      >
        <chakra.span>
          <Trans>Tags</Trans>
          <Tooltip
            hasArrow
            label={t`Use tags to group trackers, thus making it easier to find a specific tracker when having many.`}
          >
            <chakra.span ml={1}>
              <Icon as={MdInfoOutline} />
            </chakra.span>
          </Tooltip>
        </chakra.span>
      </FormLabel>
      <Controller
        name="tags"
        render={({ field }) => {
          return (
            <CreatableSelect
              {...field}
              id="tracker_tags"
              isMulti
              components={{ NoOptionsMessage: NoTagOptionsMessage }}
              placeholder={t`Add or create a tag`}
              hideSelectedOptions={false}
              options={options}
              value={field.value.map(convertToSelectOption)}
              onChange={(option: OnChangeValue<SelectOption, true>) => {
                field.onChange((option ?? []).map((x) => x.value))
              }}
            />
          )
        }}
      />
    </FormControl>
  )
}
