import { createPatchPayload } from '@capturi/api-shared'
import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { CreateEditSegmentPayload, SavedPhoneFilter } from '../../models'
import { url, cacheKey } from './constants'
import { SavedFilterResponse } from './useSavedFilters'

export type UpdateSavedFilterPayload = {
  uid: string
  filter: Partial<CreateEditSegmentPayload>
}

export const useCreateSavedFilter = (): UseMutationResult<
  SavedPhoneFilter,
  ResponseError,
  CreateEditSegmentPayload
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (model: CreateEditSegmentPayload) =>
      request.post<SavedPhoneFilter>(url, { json: model }),
    onSuccess: (newFilter) => {
      queryClient.setQueryData<SavedFilterResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { savedFilterGroups: [newFilter] }
        }
        return { savedFilterGroups: [newFilter, ...oldData.savedFilterGroups] }
      })
    },
  })
}

export const useUpdateSavedFilter = (): UseMutationResult<
  SavedPhoneFilter,
  ResponseError,
  UpdateSavedFilterPayload
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey],
    mutationFn: ({ uid, filter }: UpdateSavedFilterPayload) => {
      const originalData = queryClient
        .getQueryData<SavedFilterResponse>([cacheKey])
        ?.savedFilterGroups.find((f) => f.uid === uid)
      const originalPayload = originalData
        ? {
            ...originalData,
            subscribe: originalData.isSubscribed,
          }
        : undefined

      const changes = createPatchPayload(originalPayload, filter)
      return request.patch<SavedPhoneFilter>(`filters/${uid}?api-version=3.3`, {
        json: changes,
      })
    },
    onSuccess: (newFilter) => {
      queryClient.setQueryData<SavedFilterResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { savedFilterGroups: [newFilter] }
        }
        return {
          savedFilterGroups: oldData.savedFilterGroups.map((f) =>
            f.uid === newFilter.uid ? newFilter : f,
          ),
        }
      })
    },
  })
}

export const useDeleteFilter = (): UseMutationResult<
  SavedPhoneFilter,
  ResponseError,
  string
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey],
    mutationFn: (filterUid: string) =>
      request.delete<SavedPhoneFilter>(`filters/${filterUid}?api-version=3.3`),
    onSuccess: (_, filterUid) => {
      queryClient.setQueryData<SavedFilterResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { savedFilterGroups: [] }
        }
        return {
          savedFilterGroups: oldData?.savedFilterGroups.filter(
            (f) => f.uid !== filterUid,
          ),
        }
      })
    },
  })
}
