import {
  toFilterSearchParams,
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import request from '@capturi/request'
import { useQuery } from '@tanstack/react-query'

export const useAverageDuration = () => {
  const { periodDef } = useFilterPeriodContext()
  const segmentState = useFirstPhoneSegmentState()
  return useQuery<number>({
    queryKey: ['conversationAverageDuration', segmentState.values, periodDef],
    queryFn: async () => {
      const response = await request.post<{ averageDuration: number }>(
        'conversations/average-duration',
        {
          json: {
            ...toFilterSearchParams({ ...segmentState.values }, periodDef),
          },
          query: {
            'api-version': '3.3',
          },
        },
      )
      return response.averageDuration
    },
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
  })
}
