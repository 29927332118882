import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react'
import { ReactElement } from 'react'

export function renderTags(
  phrase: string,
  index: number,
  removePhrase: (index: number) => void,
): ReactElement {
  return (
    <Tag
      m="1"
      h="22px"
      justifyContent="space-between"
      key={index}
      borderRadius="50px"
      border="1px solid var(--chakra-colors-primary-500)"
      maxW="300px"
    >
      <TagLabel>{phrase}</TagLabel>
      <TagCloseButton onClick={() => removePhrase(index)} />
    </Tag>
  )
}
