import { Box, Flex, Skeleton, SkeletonProps, Stack } from '@chakra-ui/react'
import React from 'react'

const UserBreakdownSkeleton: React.FC<SkeletonProps> = ({
  isLoaded,
  children,
  ...restProps
}) => {
  if (isLoaded) {
    return <Box {...restProps}>{children}</Box>
  }
  const widths = [90, 80, 50, 60, 40]
  return (
    <Flex w="100%" {...restProps}>
      <Stack spacing={0} mt={5} flex={1}>
        {widths.map((width, i) => (
          <Box
            key={i}
            py={2}
            px={2}
            borderBottom="1px"
            borderBottomColor="gray.100"
          >
            <Flex h={6}>
              <Flex w="200px" align="center">
                <Skeleton boxSize={6} borderRadius="100%" />
                <Skeleton h={3} flex={1} ml={2} />
              </Flex>
              <Flex ml={4} align="center">
                <Skeleton w={8} h={3} />
              </Flex>
              <Flex flex={1} ml={4} align="center">
                <Skeleton w={`${width}%`} h={3} />
              </Flex>
              <Skeleton boxSize={6} borderRadius="100%" ml={4} />
            </Flex>
          </Box>
        ))}
      </Stack>
    </Flex>
  )
}

export default UserBreakdownSkeleton
