import { Store } from '@tanstack/react-store'
import { useStore } from '@tanstack/react-store'
import { useCallback, useEffect, useRef } from 'react'
import { useLatest, useMountedState } from 'react-use'

export const counterStore = new Store<number>(0)

/**
 * This hook can orchestrate calling an update function (`callback`) in all consumers of this hook.
 * We use to trigger revalidation of data related to changes to a tracker.
 * @param callback callback function to be called on updates
 * @returns a function which will trigger calling of the `callback` argument everywhere this hook is used.
 */
export function useRevalidateData(callback?: () => void): () => void {
  const count = useStore(counterStore)
  const countAtMountRef = useRef(count)
  const cbRef = useLatest(callback)
  const isMounted = useMountedState()

  useEffect(() => {
    if (count === countAtMountRef.current) {
      // no calls to trigger revalidation since the consumer component mounted.
      return
    }
    if (typeof cbRef.current === 'function') {
      if (isMounted()) {
        cbRef.current()
      }
    }
  }, [count, cbRef, isMounted])

  return useCallback(() => {
    counterStore.setState((count) => count + 1)
  }, [])
}
