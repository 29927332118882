import { TrackerFolder } from '@capturi/api-trackers'
import { Icon, chakra } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { MdClear } from 'react-icons/md'

const renderIcons = (
  selectedFolder: TrackerFolder | undefined,
  handleClear: (e: React.MouseEvent) => void,
): React.ReactElement => {
  if (selectedFolder) {
    return (
      <chakra.span display="flex" alignItems="center">
        <Icon
          aria-label={t`Clear`}
          as={MdClear}
          className="clear"
          p="0"
          onClick={handleClear}
          transition={'color 0.2s'}
        />
        <Icon as={FiChevronDown} className="open-indicator" />
      </chakra.span>
    )
  }
  return <Icon as={FiChevronDown} className="open-indicator" />
}

export default renderIcons
