import {
  InaccessibleSavedTextFilter,
  SavedTextFilter,
} from '@capturi/api-filters'
import {
  OnChangeValue,
  OptionProps,
  PopoutSelect,
  ReactSelectProps,
  SelectOption,
  components,
} from '@capturi/ui-select'
import { Box, Flex } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'

type FilterOption = SelectOption & {
  filter?: SavedTextFilter | InaccessibleSavedTextFilter
}

const Option: React.ComponentType<OptionProps<FilterOption, boolean>> = (
  props,
) => {
  const { label } = props.data as FilterOption
  return (
    <components.Option {...props}>
      <Flex align="center">
        <Box noOfLines={1} wordBreak="break-all">
          {label}
        </Box>
      </Flex>
    </components.Option>
  )
}

type SavedTextFilterPopoutSelectProps = {
  value?: SavedTextFilter | InaccessibleSavedTextFilter
  onChange: (filter: SavedTextFilter | InaccessibleSavedTextFilter) => void
  options: SavedTextFilter[]
} & Omit<
  ReactSelectProps<FilterOption, boolean>,
  'value' | 'onChange' | 'options'
>

function SavedTextFilterPopoutSelect({
  value: valueProp,
  options: optionProp,
  onChange,
  ...reactSelectProps
}: SavedTextFilterPopoutSelectProps): React.ReactElement {
  const { options, value } = React.useMemo(() => {
    const options = optionProp.map((x) => ({
      value: x.uid,
      label: x.name,
      filter: x,
    }))

    let value = null
    if (valueProp) {
      value = {
        value: valueProp.uid,
        label: valueProp.name,
        filter: valueProp,
      }
    }
    return {
      options,
      value,
    }
  }, [optionProp, valueProp])

  const handleChange = (option: OnChangeValue<FilterOption, boolean>): void => {
    if (option == null) {
      return
    }
    const filter = (option as FilterOption).filter
    if (!filter) {
      return
    }
    onChange(filter)
  }

  return (
    <PopoutSelect
      placeholder={t`Search for a segment ...`}
      options={options}
      value={value}
      onChange={handleChange}
      components={{ Option }}
      autoFocus={true}
      noOptionsMessage={() => t`No options`}
      {...reactSelectProps}
    />
  )
}

export default SavedTextFilterPopoutSelect
