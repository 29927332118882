import { useTheme } from '@capturi/ui-theme'
import { Icon, IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { IoMdClose } from 'react-icons/io'

export type RemoveFilterButtonProps = {
  label: string
  hasTooltip?: boolean
  tooltipLabel?: string
} & Omit<IconButtonProps, 'aria-label'>

export const RemoveFilterButton: React.FC<RemoveFilterButtonProps> = ({
  label,
  tooltipLabel: tooltipLabelProp,
  hasTooltip = true,
  onClick,
  ...props
}) => {
  const tooltipLabel = tooltipLabelProp ?? label
  const theme = useTheme()

  const button = (
    <IconButton
      as="div"
      aria-label={label}
      display="flex"
      tabIndex={-1}
      boxSize="1.25rem"
      minW="1.25rem"
      icon={
        <Icon boxSize="0.875rem !important" strokeWidth="1px" as={IoMdClose} />
      }
      onClick={(e) => {
        e.stopPropagation()
        onClick?.(e)
      }}
      bg="inherit"
      _hover={{
        boxShadow: 'inset 0px 0px 5px 20px rgba(0,0,0,.08)',
      }}
      _focus={{
        boxShadow: theme.shadows.outline,
      }}
      _active={{
        boxShadow: 'inset 0px 0px 5px 20px rgba(0,0,0,.12)',
      }}
      transition="none"
      {...props}
    />
  )

  if (hasTooltip) {
    return (
      <Tooltip
        hasArrow
        label={tooltipLabel}
        aria-label={tooltipLabel}
        placement="top"
      >
        {button}
      </Tooltip>
    )
  }
  return button
}
