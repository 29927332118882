import analytics from '@capturi/analytics'
import { Badge, Box, Heading } from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import { useMatch, useResolvedPath } from 'react-router-dom'

import { Trans } from '@lingui/macro'
import Link from '../../../Link'

export type SidebarLinkProps = {
  name: ReactElement | string
  icon?: React.ComponentType
  to: string
  show?: boolean
  hasNewBadge?: boolean
  hasBetaBadge?: boolean
  onClick: () => void
  children?: React.ReactNode
}

const SidebarLink: React.FC<SidebarLinkProps> = ({
  name,
  icon,
  to,
  show = true,
  children,
  hasBetaBadge,
  onClick,
  ...restProps
}) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: false })
  const isActive = match != null
  if (!show) return null

  return (
    <Link
      to={to}
      variant="unstyled"
      role="menuitem"
      h={8}
      lineHeight={8}
      display="flex"
      alignItems="center"
      pos="relative"
      px={2}
      mr={4}
      ml={2}
      borderRadius={4}
      transition="all 0.3s ease-out"
      outline="none !important"
      bg={isActive ? 'gray.300' : 'inherit'}
      opacity={isActive ? '1' : '0.8'}
      _hover={{
        bg: isActive ? undefined : 'blackAlpha.100',
      }}
      _focus={{
        bg: isActive ? undefined : 'blackAlpha.100',
      }}
      letterSpacing="0.03em"
      {...restProps}
      onClick={() => {
        onClick()
        analytics.event('main-menu__nav-item-clicked', {
          href: to,
        })
      }}
      color="gray.800"
    >
      <Box as={icon} display="inline-block" mr={2} boxSize={4} />
      <Heading as="span" mt="1" size="md" fontWeight="normal">
        {name}
      </Heading>
      {hasBetaBadge && (
        <Badge colorScheme="primary" lineHeight="1.2" ml={1}>
          <Trans>Beta</Trans>
        </Badge>
      )}
      {children}
      <Box
        position="absolute"
        right="19px"
        top="calc(50%-3px)"
        transform="translateX(50%)"
        w="6px"
        h="6px"
        opacity={isActive ? '1' : '0'}
        background="workspace.sidebar.darkbg"
        borderRadius="999px"
        transition="opacity 0.5s ease-out;"
      />
    </Link>
  )
}

export default SidebarLink
