import {
  SpeakerTrackerUserHitsResponseModel,
  insightsAPI,
} from '@capturi/api-insights'
import { BaseTracker } from '@capturi/api-trackers'
import {
  toFilterSearchParams,
  useFetchPhoneSegments,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import { PlaySnippetsButton } from '@capturi/ui-components'
import { Box, MenuItem, MenuList, Tooltip } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useConversationsDrawer } from 'components/ConversationsDrawer'
import { DataGrid, DataGridColumn } from 'components/DataGrid'
import React from 'react'

import { useAtom } from 'jotai'
import NoDataText from '../../shared/components/NoDataText'
import UserBreakdownSkeleton from '../../shared/components/UserBreakdownSkeleton'
import { POLL_INTERVAL } from '../constants'
import {
  TextTrackerUserHitsResponseModel,
  UserData,
  convertHitsToUserItemSegments,
  useUserHits,
} from '../data/useUserHits'
import {
  useEmailTrackerExamplesDrawer,
  usePhoneTrackerExamplesDrawer,
} from '../drawer'
import { Event, logEvent } from '../events'
import { useRevalidateData } from '../hooks/useRevalidateData'
import { useTextSegments } from '../hooks/useTextSegments'
import { plottableUsersAtom } from '../state'
import { formatHitRate, isProcessingInCurrentPeriod } from '../utils'

const hasHits = (d: UserData): boolean =>
  d.segments.some((x) => x.data?.hits && x.data.hits > 0)

export type UserHitsProps = {
  tracker: BaseTracker
}

const UserHits = React.memo<UserHitsProps>(function UserHitsMemo({ tracker }) {
  const { states, phoneSegmentStates, textSegmentStates, getIndexForState } =
    useSegmentStatesContext()
  const openAudioSnippetsDrawer = usePhoneTrackerExamplesDrawer()
  const openEmailConversationDrawer = useEmailTrackerExamplesDrawer()
  const openConversationsDrawer = useConversationsDrawer()
  const { period, periodDef } = useFilterPeriodContext()
  const processingInCurrentPeriod = isProcessingInCurrentPeriod({
    isProcessing: tracker.isProcessing,
    processingProgress: tracker.processingProgress,
    isTextProcessing: tracker.isTextProcessing,
    textProcessingProgress: tracker.textProcessingProgress,
    periodFrom: period.from,
  })

  const [selectedUsers, setSelectedUsers] = useAtom(
    plottableUsersAtom(tracker.uid),
  )

  const {
    segments: phoneSegments,
    revalidate,
    isLoading,
  } = useFetchPhoneSegments<SpeakerTrackerUserHitsResponseModel>(
    () => insightsAPI.getSpeakerTrackerUserHits(tracker.uid),
    {
      refetchInterval: processingInCurrentPeriod ? POLL_INTERVAL : false,
    },
  )

  const { segments: textSegments, refetch: refetchTextSegments } =
    useTextSegments<TextTrackerUserHitsResponseModel>({
      url: `insights/trackers/text/${tracker.uid}/users`,
      refetchInterval: processingInCurrentPeriod ? POLL_INTERVAL : false,
    })

  const res = convertHitsToUserItemSegments(
    phoneSegmentStates,
    phoneSegments,
    textSegmentStates,
    textSegments,
    getIndexForState,
  )

  const rowData = useUserHits(res)
  const segmentsCount = res.length

  const triggerRevalidation = useRevalidateData(() => {
    revalidate()
    refetchTextSegments()
  })

  const handleGoToPhoneTrackerUserConversations = React.useCallback(
    (valueIndex: number, userUid: string): void => {
      const state = states[valueIndex]
      if (state.channel !== 'phone') {
        return
      }

      logEvent(Event.TrackerUser_ViewPhoneTrackerExamples)

      openAudioSnippetsDrawer({
        tracker,
        filterState: {
          ...state.values,
          userUids: [userUid],
          trackers: [
            ...state.values.trackers,
            {
              uids: [tracker.uid],
            },
          ],
        },
        periodDef,
        excludeDeletedConversations: false,
        onClose: () => {
          triggerRevalidation()
        },
      })
    },
    [states, openAudioSnippetsDrawer, tracker, periodDef, triggerRevalidation],
  )

  const handleGoToEmailUserConversations = React.useCallback(
    (valueIndex: number, userUid: string): void => {
      const state = states[valueIndex]
      if (state.channel !== 'email') {
        return
      }

      logEvent(Event.TrackerUser_ViewEmailTrackerExamples)
      openEmailConversationDrawer({
        tracker,
        filterState: {
          ...state.values,
          userFilters: [{ values: [userUid], inverted: false }],
          trackers: [
            ...state.values.trackers,
            {
              uids: [tracker.uid],
              inverted: false,
              phrase: null,
            },
          ],
        },
        periodDef,
        onClose: () => {
          triggerRevalidation()
        },
      })
    },
    [
      states,
      openEmailConversationDrawer,
      periodDef,
      tracker,
      triggerRevalidation,
    ],
  )

  const handleViewNotTrackerConversations = React.useCallback(
    (valueIndex: number, userUid: string): void => {
      const state = states[valueIndex]
      if (state.channel !== 'phone') {
        return
      }

      logEvent(Event.TrackerUser_ViewNotTrackerConversations)

      openConversationsDrawer({
        url: 'conversations/list?api-version=3.3&excludeDeleted=false',
        getFilterRequestModel: () =>
          toFilterSearchParams(
            {
              ...state.values,
              userUids: [userUid],
              trackers: [
                ...state.values.trackers,
                {
                  uids: [tracker.uid],
                  inverted: true,
                },
              ],
            },
            periodDef,
          ),
      })
    },
    [states, openConversationsDrawer, tracker, periodDef],
  )

  const handleViewTrackerConversations = React.useCallback(
    (valueIndex: number, userUid: string): void => {
      const state = states[valueIndex]
      if (state.channel !== 'phone') {
        return
      }

      logEvent(Event.TrackerUser_ViewPhoneTrackerConversations)
      openConversationsDrawer({
        url: 'conversations/list?api-version=3.3&excludeDeleted=false',
        getFilterRequestModel: () =>
          toFilterSearchParams(
            {
              ...state.values,
              userUids: [userUid],
              trackers: [
                ...state.values.trackers,
                {
                  uids: [tracker.uid],
                  inverted: false,
                },
              ],
            },
            periodDef,
          ),
      })
    },
    [states, openConversationsDrawer, tracker.uid, periodDef],
  )

  const handleSelectRows = React.useCallback(
    (rows: UserData[]) => {
      const users = rows.map((x) => x.user.uid)
      setSelectedUsers(users)
      logEvent(Event.TrackerUser_PlotTimeSeries, {
        userPlotCount: users.length,
        segmentCount: segmentsCount,
      })
    },
    [setSelectedUsers, segmentsCount],
  )

  const columns = React.useMemo(() => {
    const columns: Array<DataGridColumn<UserData>> = [
      {
        type: 'user',
        getValue: (d) => d.user.uid,
        width: 200,
      },
      {
        type: 'value',
        id: 'hit-rate,',
        getSegmentValue: (s) => s.data?.hitRate,
        formatValue: formatHitRate,
        alignRight: true,
        width: 50,
      },
      {
        type: 'progress',
        getSegmentValue: (s) => s.data?.hitRate,
        formatValue: formatHitRate,
        range: [0, 1],
      },
      {
        type: 'dataBasis',
        getSegmentValue: (s) => {
          const { hits = 0, targets: count = 0 } = s.data ?? {}
          return {
            value: hits,
            total: count,
          }
        },
      },
      {
        type: 'button',

        render: (row, rowSegmentIndex) => {
          const data = row.segments[rowSegmentIndex].data
          if (!data) return <Box />
          const stateIndex = data.stateIndex
          const isEmailChannel = states[stateIndex].channel === 'email'
          return (
            <PlaySnippetsButton
              label={t`View conversations`}
              onClick={() =>
                isEmailChannel
                  ? handleGoToEmailUserConversations(stateIndex, row.user.uid)
                  : handleGoToPhoneTrackerUserConversations(
                      stateIndex,
                      row.user.uid,
                    )
              }
            />
          )
        },
      },
      {
        type: 'menu',

        render: (row, rowSegmentIndex) => {
          const data = row.segments[rowSegmentIndex].data
          if (!data) return <Box />
          const stateIndex = data.stateIndex
          const isEmailChannel = states[stateIndex].channel === 'email'
          return (
            <Tooltip isDisabled={!isEmailChannel} label={t`Not available`}>
              <MenuList>
                <MenuItem
                  isDisabled={isEmailChannel}
                  onClick={() =>
                    handleViewTrackerConversations(stateIndex, row.user.uid)
                  }
                >
                  <Trans>View conversations with the tracker</Trans>
                </MenuItem>
                <MenuItem
                  isDisabled={isEmailChannel}
                  onClick={() =>
                    handleViewNotTrackerConversations(stateIndex, row.user.uid)
                  }
                >
                  <Trans>View conversations without the tracker</Trans>
                </MenuItem>
              </MenuList>
            </Tooltip>
          )
        },
      },
    ]
    return columns
  }, [
    states,
    handleGoToPhoneTrackerUserConversations,
    handleGoToEmailUserConversations,
    handleViewTrackerConversations,
    handleViewNotTrackerConversations,
  ])

  const initialSelectedRowsRef = React.useRef(() => {
    return rowData.reduce<Record<string, boolean>>((memo, row, i) => {
      if (selectedUsers.indexOf(row.user.uid) !== -1) {
        memo[i] = true
      }
      return memo
    }, {})
  })

  return (
    <UserBreakdownSkeleton isLoaded={!isLoading}>
      <DataGrid
        data={rowData}
        columns={columns}
        selectableRows
        initialSelectedRows={initialSelectedRowsRef.current}
        onSelectedRowsChange={handleSelectRows}
        isRowSelectable={hasHits}
      >
        <NoDataText show={!isLoading && rowData.length === 0} />
      </DataGrid>
    </UserBreakdownSkeleton>
  )
})

export default UserHits
