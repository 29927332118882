import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useRef, useState } from 'react'

import { EditSnippetResultType, LibraryItem, NewFolder } from '../types'
import {
  PlaylistSelector,
  PlaylistSelectorContent,
  PlaylistSelectorResult,
} from './PlaylistSelector'

export interface UpdateSnippetDialogOptions {
  snippet: LibraryItem
}

type UpdateSnippetDialogProps = UpdateSnippetDialogOptions &
  BaseModalComponentProps<EditSnippetResultType>

export const UpdateSnippetDialog: React.FC<UpdateSnippetDialogProps> = ({
  isOpen = false,
  onClose,
  onSubmit,
  snippet,
}) => {
  // Refs
  const formRef = useRef<HTMLFormElement>(null)
  const titleRef = useRef<HTMLInputElement>(null)

  // State
  const [title, setTitle] = useState(snippet?.title ?? '')
  const [description, setDescription] = useState<string>(
    snippet?.description ?? '',
  )
  const [playlistState, setPlaylistState] =
    useState<PlaylistSelectorResult | null>({
      uid: snippet.folderUid,
      isNew: false,
    })

  const handleSubmitChanges = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (formRef.current?.checkValidity() === false) {
      return
    }
    if (playlistState == null) {
      return
    }

    const folder: NewFolder | undefined = playlistState.isNew
      ? {
          name: playlistState.name,
          description: playlistState.description,
          permissions: playlistState.permissions ?? null,
        }
      : undefined

    const result: LibraryItem = {
      ...(snippet ?? {}),
      title,
      description,
      folderUid: playlistState.uid,
    }

    onSubmit?.({ snippet: result, folder, originalSnippet: snippet })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={titleRef}>
      <ModalOverlay>
        <ModalContent>
          <form ref={formRef} onSubmit={handleSubmitChanges}>
            <ModalHeader>
              <Trans>Edit audio snippet</Trans>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={4}>
                <FormControl isRequired>
                  <FormLabel htmlFor="snippetTitle">
                    <Trans>Title</Trans>
                  </FormLabel>
                  <Input
                    id="snippetTitle"
                    ref={titleRef}
                    placeholder={t`Title`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setTitle(e.target.value)
                    }
                    value={title}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="description">
                    <Trans>Description</Trans>
                  </FormLabel>
                  <Textarea
                    id="description"
                    placeholder={t`Description ...`}
                    onChange={(
                      e: React.ChangeEvent<HTMLTextAreaElement>,
                    ): void => setDescription(e.target.value)}
                    value={description}
                    height="20"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="playlist">
                    <Trans>Playlist</Trans>
                  </FormLabel>
                  <PlaylistSelector
                    id="playlist"
                    isRequired
                    defaultValue={snippet?.folderUid}
                    onChange={setPlaylistState}
                  >
                    <PlaylistSelectorContent />
                  </PlaylistSelector>
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Stack isInline spacing={4} justify="flex-end">
                <Button secondary type="button" onClick={onClose}>
                  <Trans>Cancel</Trans>
                </Button>
                <Button primary type="submit">
                  <Trans>Save</Trans>
                </Button>
              </Stack>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
