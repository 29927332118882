import { Button, DateRangePicker } from '@capturi/ui-components'
import {
  Box,
  ButtonProps,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { isSameDay } from 'date-fns'
import React from 'react'
import { MdDateRange } from 'react-icons/md'

import {
  FixedDateTimePeriodDefinition,
  FixedPeriodDefinition,
  PeriodDefinition,
  PeriodDefinitions,
  formatPeriod,
  useFilterPeriodContext,
} from '../../../period'
import { FilterPeriodSelectContext } from '../FilterPeriodSelectContainer'

const datePeriodOptions = {
  now: {
    period: [PeriodDefinitions.Today, PeriodDefinitions.Yesterday],
  },
  last: {
    period: [
      PeriodDefinitions.Last7Days,
      PeriodDefinitions.Last30Days,
      PeriodDefinitions.Last3Months,
    ],
  },
  current: {
    period: [
      PeriodDefinitions.CurrentWeek,
      PeriodDefinitions.CurrentMonth,
      PeriodDefinitions.CurrentQuarter,
    ],
  },
  previous: {
    period: [
      PeriodDefinitions.LastWeek,
      PeriodDefinitions.LastMonth,
      PeriodDefinitions.LastQuarter,
    ],
  },
}

const DateRangeDialogContent: React.FC<{
  value?: PeriodDefinition
  onChange: (def: PeriodDefinition) => void
  onApply: (def: PeriodDefinition) => void
}> = ({ value, onChange, onApply }) => {
  const range = React.useMemo(() => {
    if (value === undefined) return undefined
    const { from, to } = value.create(new Date())
    return { from, to }
  }, [value])

  const difinitionsLabels = {
    [PeriodDefinitions.Today.name]: t`Today`,
    [PeriodDefinitions.Yesterday.name]: t`Yesterday`,

    [PeriodDefinitions.Last7Days.name]: t`7 d`,
    [PeriodDefinitions.Last30Days.name]: t`30 d`,
    [PeriodDefinitions.Last3Months.name]: t`3 mo`,
    [PeriodDefinitions.Last12Months.name]: t`12 mo`,

    // Current
    [PeriodDefinitions.CurrentWeek.name]: t`Week`,
    [PeriodDefinitions.CurrentMonth.name]: t`Month`,
    [PeriodDefinitions.CurrentQuarter.name]: t`Quarter`,
    [PeriodDefinitions.CurrentYear.name]: t`Year`,

    // Previous
    [PeriodDefinitions.LastWeek.name]: t`Week`,
    [PeriodDefinitions.LastMonth.name]: t`Month`,
    [PeriodDefinitions.LastQuarter.name]: t`Quarter`,
  }

  const onSelectPeriod = (def: PeriodDefinition): void => {
    onApply(def)
  }

  const onSelectDateRange = (range: { from: Date; to: Date }): void => {
    // Create period definition from date range
    const def = isSameDay(range.from, range.to)
      ? new FixedDateTimePeriodDefinition(range.from, range.to)
      : new FixedPeriodDefinition(range.from, range.to)
    onChange(def)
  }

  const periodsLabels = Object.entries(datePeriodOptions)
  const periodTitles = ['now', t`last`, t`current`, t`previous`]

  return (
    <Stack direction="row" spacing={0}>
      <Box flex="0 0 auto" borderRight="1px" borderRightColor="gray.200" p="4">
        {periodsLabels.map((period, i) => {
          const definitions = period[1]
          const title = periodTitles[i]
          return (
            <React.Fragment key={title}>
              {title !== 'now' && (
                <Heading
                  fontFamily="body"
                  fontSize="md"
                  fontWeight="medium"
                  textTransform="capitalize"
                  mb="2"
                  mt="4"
                >
                  {title}
                </Heading>
              )}
              <Box display="flex" gap="2">
                {definitions.period.map((o) => (
                  <Button
                    secondary
                    key={o.name}
                    size="xs"
                    fontWeight="normal"
                    onClick={() => onSelectPeriod(o)}
                    borderRadius="20"
                    color={o.name === value?.name ? 'white' : 'black'}
                    bgColor={
                      o.name === value?.name ? 'brand.primary' : 'gray-200'
                    }
                    _hover={{
                      bgColor:
                        o.name === value?.name ? 'primary.600' : 'gray.300',
                    }}
                    textAlign="center"
                  >
                    <Text>{difinitionsLabels[o.name]}</Text>
                  </Button>
                ))}
              </Box>
            </React.Fragment>
          )
        })}
      </Box>
      <DateRangePicker
        value={range}
        showInputs
        onSelectDateRange={onSelectDateRange}
        fixedWeeks
        disabled={[{ after: new Date() }]}
        placeholderFrom={t`From`}
        placeholderTo={t`To`}
      />
    </Stack>
  )
}

type DateRangeButtonProps = Omit<ButtonProps, 'children'>

const DateRangeButton: React.FC<DateRangeButtonProps> = React.forwardRef<
  HTMLButtonElement,
  DateRangeButtonProps
>(function DateRangeButton(props, ref) {
  const { periodDef } = useFilterPeriodContext()
  return (
    <Button
      ref={ref}
      size="sm"
      borderRadius="md"
      leftIcon={<MdDateRange />}
      {...props}
    >
      {periodDef ? (
        formatPeriod(periodDef)
      ) : (
        <Trans>Select date period ...</Trans>
      )}
    </Button>
  )
})

export const DateRangeDialog: React.FC<{ usePortal?: boolean }> = ({
  usePortal,
}) => {
  const { value, setValue, applyValue } = React.useContext(
    FilterPeriodSelectContext,
  )

  const renderPopoverContent = (onClose: () => void): React.ReactElement => {
    return (
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody p={0}>
          <DateRangeDialogContent
            value={value}
            onChange={setValue}
            onApply={(value) => applyValue(value, onClose)}
          />
        </PopoverBody>
        <PopoverFooter>
          <Stack isInline spacing={2} justify="flex-end">
            <Button onClick={onClose} size="sm">
              <Trans>Cancel</Trans>
            </Button>
            <Button
              primary
              size="sm"
              onClick={() => applyValue(value, onClose)}
            >
              <Trans>Apply</Trans>
            </Button>
          </Stack>
        </PopoverFooter>
      </PopoverContent>
    )
  }

  return (
    <Popover placement="auto-end" variant="responsive">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <DateRangeButton />
          </PopoverTrigger>
          {usePortal ? (
            <Portal>{renderPopoverContent(onClose)}</Portal>
          ) : (
            renderPopoverContent(onClose)
          )}
        </>
      )}
    </Popover>
  )
}
