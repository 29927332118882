import initConsole from './console'
import initIntercom from './intercom'
import initPosthog from './posthog'
import initStonly from './stonly'
import { InitConfig, Tracker } from './types'
import { getShouldPrintEvents } from './utils'

export { default as useIntercom } from './intercom/useIntercom'
export * from './types'
const isProd = process.env.NODE_ENV !== 'development'

let trackers: Tracker[] = []

export function init(config: InitConfig): void {
  trackers = []

  // Init Intercom
  if (config.intercom && isProd) {
    const intercomTracker = initIntercom({
      ...config.intercom,
      ...(config.utmParams ?? {}),
    })
    if (intercomTracker) trackers.push(intercomTracker)
  }
  // Init PostHog
  if (config.posthog && isProd) {
    const posthogTracker = initPosthog(config.posthog)
    if (posthogTracker) trackers.push(posthogTracker)
  }
  // Init Console logger
  const shouldPrintEvents = getShouldPrintEvents()
  if (shouldPrintEvents || config.debug === true) {
    const consoleLogger = initConsole()
    trackers.push(consoleLogger)
  }
  // Init Stonly
  if (config.stonly && isProd) {
    const stonlyTracker = initStonly(config.stonly)
    if (stonlyTracker) trackers.push(stonlyTracker)
  }
}

function apply<Key extends keyof Tracker>(key: Key): Tracker[Key] {
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  return (...args: any[]) => {
    trackers.forEach((tracker) => {
      // biome-ignore lint/suspicious/noExplicitAny: legacy
      const fn = tracker[key] as (...args: any[]) => void
      fn(...args)
    })
  }
}

const tracker: Tracker = {
  event: apply('event'),
  identify: apply('identify'),
  modalview: apply('modalview'),
  pageview: apply('pageview'),
  reset: apply('reset'),
  record: apply('record'),
}

export default tracker
