import { t } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import { FilterValueLabel } from '../../components/FilterValueLabel'
import useTeamsFilterOptions from '../../data/useTeamsFilterOptions'

export const TeamValueLabel: React.FC<{ uids?: string[] | null }> = ({
  uids,
}) => {
  const hasValues = !isEmpty(uids)
  const { teams, isLoading } = useTeamsFilterOptions({ suspense: false })

  const teamNames = (uids ?? []).map(
    (uid) => teams.find((x) => x.uid === uid)?.name ?? '?',
  )
  return (
    <FilterValueLabel
      name={t`Team`}
      value={teamNames}
      isLoading={hasValues && isLoading}
    />
  )
}
