import { SentimentScore, SentimentSpeaker } from '@capturi/api-sentiment'
import { I18n, i18n as globalI18n } from '@lingui/core'
import { t } from '@lingui/macro'

export function scoreToString(
  score?: SentimentScore,
  i18n: I18n = globalI18n,
): string | undefined {
  switch (score) {
    case 'Low':
      return t(i18n)`Low`
    case 'Medium':
      return t(i18n)`Medium`
    case 'High':
      return t(i18n)`High`
    default:
      return undefined
  }
}

export function speakerToString(
  orgType: string,
  speaker?: SentimentSpeaker,
  i18n: I18n = globalI18n,
): string | undefined {
  switch (speaker) {
    case 'User':
      return t(i18n)`Employee`
    case 'Other':
      return orgType === 'public' ? t(i18n)`Citizen` : t(i18n)`Customer`
    case 'Combined':
      return t(i18n)`All`
    default:
      return undefined
  }
}
