import { MasterPreset } from '@capturi/api-shared'
import { BaseTracker, TrackerFolder } from '@capturi/api-trackers'
import { Acl, PermissionPreset } from '@capturi/core'
import { Segment } from '@capturi/filters'
import { useOrganization } from '@capturi/stores'

import { useStore } from '@tanstack/react-store'
import { useCallback, useMemo } from 'react'
import { TrackersListSortBy, trackersListSortByStore } from './state'
import { TrackerStats } from './useSegmentsOverview'

export type ListItemsTracker = {
  accessLevel: BaseTracker['accessLevel']
  permissionPreset: BaseTracker['permissionPreset']
  folderUid: string | null | undefined
  tags: string[]
  speech?: { phrases: string[]; speakerId: number | null | undefined }
  text?: { phrases: string[] }
  speakerId: number | null | undefined
  trackerUid: string
  trackerName: string
  isSegmentsLoading: boolean
  isMasterTarget: boolean
  segments: {
    color: string
    label: string
    data: number
  }[]
}

export type ListItems = {
  uid: string
  title: string
  accessLevel: TrackerFolder['accessLevel']
  permissionPreset: PermissionPreset
  acl: Acl | null
  permissionPresetUserUid: string | null
  searchMatchType: 'folder' | 'tracker' | null
  trackers: ListItemsTracker[]
  isMasterTarget: boolean
  masterSettings: {
    preset: MasterPreset
    targets: {
      folderUid: string
      userUid: string
    }
  } | null
}

function compareTrackers(
  a: ListItemsTracker,
  b: ListItemsTracker,
  sortBy: TrackersListSortBy,
  compareString: (a: string, b: string) => number,
): number {
  if (sortBy === 'hitrate') {
    return b.segments[0].data - a.segments[0].data
  }
  return compareString(a.trackerName, b.trackerName)
}

export const useMapListItems = (
  trackers: BaseTracker[] | undefined,
  folders: TrackerFolder[],
  isSegmentLoading: boolean,
  segments: Segment[],
  getSegments: (trackerUid: string) => Segment<TrackerStats>[],
): {
  folders: ListItems[]
  root: ListItems['trackers']
  trackerUidsByFolderUids: Map<string | null, Set<string>>
} => {
  const { conversationLanguage } = useOrganization()
  const compareString = useMemo(
    () => Intl.Collator(conversationLanguage).compare,
    [conversationLanguage],
  )

  const getValues = useCallback(
    (
      trackerUid: string,
    ): {
      color: string
      label: string
      data: number
    }[] => {
      const data = getSegments(trackerUid) || []
      return segments.map((s, index) => ({
        color: s.color,
        label: s.label,
        data: data[index]?.data?.hitPercentage ?? 0,
      }))
    },
    [getSegments, segments],
  )

  const sortBy = useStore(trackersListSortByStore)

  return useMemo(() => {
    const trackersByFolderUid = trackers?.reduce<
      Map<string | null, ListItems['trackers']>
    >((memo, tracker) => {
      const key = tracker.folderUid || null
      const a: ListItemsTracker[] = memo.get(key) || []

      a.push({
        accessLevel: tracker.accessLevel,
        permissionPreset: tracker.permissionPreset,
        tags: tracker.tags,
        trackerUid: tracker.uid,
        trackerName: tracker.name,
        isSegmentsLoading: isSegmentLoading,
        segments: getValues(tracker.uid),
        folderUid: tracker.folderUid,
        isMasterTarget: tracker.isMasterTarget,
        speech: tracker.speech?.phrases
          ? {
              phrases: tracker.speech.phrases,
              speakerId: tracker.speech.speakerId,
            }
          : undefined,
        text: tracker.text?.phrases
          ? { phrases: tracker.text.phrases }
          : undefined,
        speakerId: tracker.speech?.speakerId,
      })
      memo.set(key, a)

      return memo
    }, new Map())

    const trackerUidsByFolderUids = trackers?.reduce<
      Map<string | null, Set<string>>
    >((memo, tracker) => {
      const key = tracker.folderUid || null
      const a: Set<string> = memo.get(key) || new Set()
      a.add(tracker.uid)
      memo.set(key, a)
      return memo
    }, new Map())

    const items = folders
      .map<ListItems>((f) => ({
        title: f.title,
        uid: f.uid,
        acl: f.acl,
        searchMatchType: null,
        accessLevel: f.accessLevel,
        permissionPreset: f.permissionPreset,
        permissionPresetUserUid: f.permissionPresetUserUid,
        trackers: (trackersByFolderUid?.get(f.uid) || [])?.sort((a, b) =>
          compareTrackers(a, b, sortBy, compareString),
        ),
        masterSettings: f.masterSettings,
        isMasterTarget: f.isMasterTarget,
      }))
      .sort((a, b) => compareString(a.title, b.title))

    const root = (trackersByFolderUid?.get(null) || []).sort((a, b) =>
      compareTrackers(a, b, sortBy, compareString),
    )

    return {
      folders: items,
      root,
      trackerUidsByFolderUids: trackerUidsByFolderUids ?? new Map(),
    }
  }, [
    isSegmentLoading,
    sortBy,
    compareString,
    getValues, // this one triggers re-renders
    trackers,
    folders,
  ])
}
