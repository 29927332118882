import {
  Box,
  BoxProps,
  Heading as ChakraHeading,
  Flex,
  FlexProps,
  HeadingProps,
} from '@chakra-ui/react'
import React from 'react'

type Size = 'md' | 'lg'
const SizeContext = React.createContext<Size>('lg')

const headerFontSizeMapping: Record<Size, HeadingProps['size']> = {
  lg: '4xl',
  md: '2xl',
}

export const Image = (props: BoxProps): React.ReactElement => (
  <Box mb={4} {...props} />
)
export const Heading = (props: HeadingProps): React.ReactElement => {
  const size = React.useContext(SizeContext)

  return (
    <ChakraHeading
      fontSize={headerFontSizeMapping[size]}
      color="text"
      textAlign="center"
      display="flex"
      alignItems="center"
      justifyContent="center"
      mb={2}
      {...props}
    />
  )
}
export const Body = (props: FlexProps): React.ReactElement => {
  const size = React.useContext(SizeContext)
  return (
    <Flex
      fontSize={size}
      color="textMuted"
      textAlign="center"
      justify="center"
      direction="column"
      align="center"
      {...props}
    />
  )
}
export const Footer = (props: FlexProps): React.ReactElement => (
  <Flex align="center" mt={8} {...props} />
)

export type ContainerProps = {
  size?: Size
} & Omit<FlexProps, 'size'>

export const Container: React.FC<ContainerProps> = ({
  size = 'lg',
  ...restProps
}) => {
  return (
    <Flex w="100%" justify="center">
      <SizeContext.Provider value={size}>
        <Flex
          direction="column"
          justify="center"
          align="center"
          width="100%"
          maxWidth="28rem"
          {...restProps}
        />
      </SizeContext.Provider>
    </Flex>
  )
}
