import { Button } from '@capturi/ui-components'
import {
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverProps,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

import {
  PhraseNearnessForm,
  PhraseNearnessFormProps,
} from './PhraseNearnessForm'
import { PhraseNearnessFormContents } from './PhraseNearnessFormContents'

type PhraseNearnessPopoverProps = Omit<PopoverProps, 'children'> & {
  heading?: React.ReactNode
  children?: React.ReactNode
} & Pick<PhraseNearnessFormProps, 'defaultValues' | 'onSubmit'>

export const PhraseNearnessPopover: React.FC<PhraseNearnessPopoverProps> = ({
  children,
  heading,
  onSubmit,
  defaultValues,
  ...restProps
}) => {
  return (
    <Popover isLazy placement="auto" {...restProps}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>{children}</PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PhraseNearnessForm
                defaultValues={defaultValues}
                onSubmit={(data, e) => {
                  onSubmit?.(data, e)
                  onClose()
                }}
              >
                <PopoverArrow />
                {heading && <PopoverHeader>{heading}</PopoverHeader>}
                <PopoverBody>
                  <PhraseNearnessFormContents />
                </PopoverBody>
                <PopoverFooter>
                  <HStack spacing={4} justify="flex-end">
                    <Button secondary type="button" onClick={onClose}>
                      <Trans>Cancel</Trans>
                    </Button>
                    <Button primary type="submit">
                      <Trans>Save</Trans>
                    </Button>
                  </HStack>
                </PopoverFooter>
              </PhraseNearnessForm>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  )
}
