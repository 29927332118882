import { useFeatureFlags } from '@capturi/feature-flags'
import { useHover } from '@capturi/react-utils'
import { ShowModal } from '@capturi/use-modal'
import {
  Box,
  Circle,
  Collapse,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useCallback, useRef, useState } from 'react'
import { MdChevronRight, MdDelete, MdEdit, MdMoreHoriz } from 'react-icons/md'

import { Event, logEvent } from '../events'
import { ListItems } from '../useMapListItems'
import { CreateUpdateTrackerFolderDialogProps } from './TrackerFolderForm'
import TrackerListItem from './TrackerListItem'

type TrackerListFolder = {
  item: ListItems
  onMoveTrackerToFolder: (trackerUid: string, folderUid: string | null) => void
  onEditFolder: ShowModal<CreateUpdateTrackerFolderDialogProps>
  onSelectTracker: (trackerUid: string) => void
  onDeleteFolder: (folderUid: string, folderName: string) => void
  currentSelectedTrackerUid?: string
  searchMatchType: ListItems['searchMatchType']
}

export const TrackerListFolder: React.FC<TrackerListFolder> = React.memo(
  ({
    item,
    onMoveTrackerToFolder,
    onSelectTracker,
    onEditFolder,
    onDeleteFolder,
    searchMatchType,
    currentSelectedTrackerUid,
  }) => {
    const containsSelectedTracker = currentSelectedTrackerUid !== undefined
    const prevContainsSelectedTracker = useRef(containsSelectedTracker)
    const [isExpanded, setIsExpanded] = useState(containsSelectedTracker)
    const toggleIsExpanded = useCallback(() => {
      setIsExpanded(!isExpanded)
    }, [isExpanded])

    if (prevContainsSelectedTracker.current !== containsSelectedTracker) {
      prevContainsSelectedTracker.current = containsSelectedTracker
      if (!isExpanded) setIsExpanded(containsSelectedTracker)
    }
    const { enableMasterTracker } = useFeatureFlags()

    const tooltipLabel = t`You don't have access to edit this folder`

    const containerRef = useRef<HTMLDivElement>(null)
    const isHovering = useHover(containerRef)
    const isDisabled = searchMatchType === 'tracker'
    return (
      <Box ref={containerRef}>
        <Flex
          color={isExpanded ? 'gray.800' : 'gray.600'}
          justifyContent="space-between"
          minH={10}
          w="100%"
          onClick={toggleIsExpanded}
          pointerEvents={isDisabled ? 'none' : 'auto'}
          cursor={isDisabled ? undefined : 'pointer'}
        >
          <Flex alignItems="center" gap={1}>
            <Icon
              as={MdChevronRight}
              transform={
                isExpanded || isDisabled ? 'rotate(90deg)' : 'rotate(0deg)'
              }
              transition="all 200ms ease-out"
            />
            <Text fontSize="sm" wordBreak="break-word">
              {item.title} ({item.trackers.length})
            </Text>
          </Flex>
          <HStack spacing="0">
            <Menu>
              {({ isOpen }) => (
                <>
                  <Tooltip
                    label={
                      item.isMasterTarget
                        ? t`This folder can only be edited in the 'Master tracker organisation'`
                        : tooltipLabel
                    }
                    hasArrow
                    placement="top"
                    isDisabled={
                      item.accessLevel === 'Edit' && !item.isMasterTarget
                    }
                  >
                    <MenuButton
                      transition="visibility 200ms linear,opacity 200ms linear"
                      visibility={isHovering ? 'visible' : 'hidden'}
                      opacity={isHovering ? 100 : 0}
                      variant="ghost"
                      as={IconButton}
                      icon={<MdMoreHoriz />}
                      onClick={(e) => e.stopPropagation()}
                      isDisabled={
                        item.accessLevel === 'View' ||
                        isDisabled ||
                        item.isMasterTarget
                      }
                    />
                  </Tooltip>
                  {isOpen && (
                    <Portal>
                      <MenuList>
                        <MenuItem
                          icon={<MdEdit />}
                          onClick={(e) => {
                            e.stopPropagation()
                            onEditFolder({
                              folder: item,
                              enableMasterTracker: enableMasterTracker,
                            })
                            logEvent(Event.TrackerFolder_EditOpened)
                          }}
                        >
                          <Trans>Edit</Trans>
                        </MenuItem>
                        <MenuItem
                          icon={<MdDelete />}
                          onClick={(e) => {
                            e.stopPropagation()
                            onDeleteFolder(item.uid, item.title)
                          }}
                        >
                          <Trans>Delete</Trans>
                        </MenuItem>
                      </MenuList>
                    </Portal>
                  )}
                </>
              )}
            </Menu>
            {containsSelectedTracker && (
              <Box>
                <Circle mx="8px" background="primary.500" size="8px" />
              </Box>
            )}
          </HStack>
        </Flex>
        <Box
          filter=" drop-shadow(0px -4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));"
          pb={isExpanded ? 4 : 0}
        >
          <Collapse in={isDisabled || isExpanded}>
            {item.trackers.length > 0 ? (
              item.trackers.map((tracker) => (
                <TrackerListItem
                  tracker={tracker}
                  onTrackerSelected={onSelectTracker}
                  isSelected={tracker.trackerUid === currentSelectedTrackerUid}
                  showProgress
                  key={tracker.trackerUid}
                  onMoveToFolder={onMoveTrackerToFolder}
                />
              ))
            ) : (
              <Flex justifyContent="center">
                <Text fontSize="xs" color="gray.600">
                  <Trans>Folder is empty...</Trans>
                </Text>
              </Flex>
            )}
          </Collapse>
        </Box>
      </Box>
    )
  },
)
