import { isSameDay as isSameDayFns } from 'date-fns'

type DateWithTime = {
  date?: Date
  hours?: number
  minutes?: number
}

export function isSameDay(a?: Date, b?: Date): boolean {
  if (a && !b) {
    return true
  }
  if (b && !a) {
    return true
  }
  if (a && b) {
    return isSameDayFns(a, b)
  }
  return false
}

export function dateWithTime(options: DateWithTime): Date {
  const date = options.date ?? new Date()
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    options.hours,
    options.minutes,
    0,
  )
}
