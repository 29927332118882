import { AdversusStatus } from '@capturi/api-conversations'
import { OnChangeValue, SelectOptionBase } from '@capturi/ui-select'
import React, { useMemo } from 'react'

import {
  FilterCriteriaComponentBaseProps,
  FilterCriteriaSelect,
} from '../../components/PhoneFilter/components/PhoneSegmentBuilder'
import translateAdversusStatus from './adversusStatus'

const values: AdversusStatus[] = [
  'success',
  'notInterested',
  'privateRedial',
  'automaticRedial',
  'unqualified',
  'invalid',
  'unknown',
]

type StatusSelectProps = FilterCriteriaComponentBaseProps<string>

export function StatusSelect(props: StatusSelectProps): React.ReactElement {
  const status = props.value

  const value = useMemo(
    () =>
      status
        ? {
            label: translateAdversusStatus(status as AdversusStatus),
            value: status,
          }
        : undefined,
    [status],
  )

  const options = React.useMemo(() => {
    const options = values.map((status) => ({
      label: translateAdversusStatus(status),
      value: status,
    }))
    // If current value is not part of the fixed options then add it
    if (value && !options.find((x) => x.value === value.value)) {
      return [value, ...options]
    }
    return options
  }, [value])

  const handleChange = (
    option: OnChangeValue<SelectOptionBase, boolean>,
  ): void => {
    if (option == null) {
      return
    }
    const value = (option as SelectOptionBase).value
    props.setValue?.(value)
    props.onClose?.()
  }

  return (
    <FilterCriteriaSelect
      options={options}
      value={value}
      onChange={handleChange}
      autoFocus={true}
    />
  )
}
