import { AdversusStatus } from '@capturi/api-conversations'
import { PhoneFilterValues } from '@capturi/api-filters'
import {
  ScoreCustomNumberPropParameter,
  ScoreCustomPropParameter,
  ScoreParameter,
} from '@capturi/api-scoring'
import {
  CustomPropFilterValueLabel,
  CustomerValueLabel,
  DurationValueLabel,
  ExternalIdentityValueLabel,
  FilterDefinition,
  FilterDefinitions,
  LabelsValueLabel,
  SentimentValueLabel,
  StatusValueLabel,
  SubjectValueLabel,
  TeamValueLabel,
  TrackersValueLabel,
  UsersValueLabel,
  getFilterDefinitions,
} from '@capturi/filters'
import { t } from '@lingui/macro'
import React from 'react'
import { BsQuestion } from 'react-icons/bs'

const defaultDefinitions = getFilterDefinitions()

const getFilterDefinition = (
  type: keyof PhoneFilterValues,
  definitions: FilterDefinitions,
): FilterDefinition => {
  return (
    definitions.get(type) ?? {
      name: t`Unknown`,
      icon: BsQuestion,
      renderText: () => <span>{t`Unknown`}</span>,
      Component: () => <div />,
    }
  )
}

const filterInfoForCustomNumberProp = (
  index: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10,
  param: ScoreCustomNumberPropParameter<typeof index>,
  definitions: FilterDefinitions,
): {
  icon: React.ComponentType
  label: React.ReactNode
} => {
  const { min, max } = param
  return {
    icon: getFilterDefinition(`customNumberProp${index}`, definitions).icon,
    label: (
      <CustomPropFilterValueLabel
        filterKey={`customNumberProp${index}`}
        value={{ min, max }}
      />
    ),
  }
}

const filterInfoForCustomProp = (
  index: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10,
  param: ScoreCustomPropParameter<typeof index>,
  definitions: FilterDefinitions,
): {
  icon: React.ComponentType
  label: React.ReactNode
} => {
  return {
    icon: getFilterDefinition(`customProp${index}`, definitions).icon,
    label: (
      <CustomPropFilterValueLabel
        filterKey={`customProp${index}`}
        value={param.value}
      />
    ),
  }
}

/**
 * Get filter icon and label based on score parameter type
 * @param param a score parameter
 * @returns filter icon and label
 */
export const getFilterInfo = (
  param: ScoreParameter,
  definitions: FilterDefinitions = defaultDefinitions,
): {
  icon: React.ComponentType
  label: React.ReactNode
} => {
  switch (param.type) {
    case 'customer': {
      return {
        icon: getFilterDefinition('customers', definitions).icon,
        label: <CustomerValueLabel value={param.customers} />,
      }
    }
    case 'custom-number-prop-1':
      return filterInfoForCustomNumberProp(1, param, definitions)
    case 'custom-number-prop-2':
      return filterInfoForCustomNumberProp(2, param, definitions)
    case 'custom-number-prop-3':
      return filterInfoForCustomNumberProp(3, param, definitions)
    case 'custom-number-prop-4':
      return filterInfoForCustomNumberProp(4, param, definitions)
    case 'custom-number-prop-5':
      return filterInfoForCustomNumberProp(5, param, definitions)
    case 'custom-number-prop-6':
      return filterInfoForCustomNumberProp(6, param, definitions)
    case 'custom-number-prop-7':
      return filterInfoForCustomNumberProp(7, param, definitions)
    case 'custom-number-prop-8':
      return filterInfoForCustomNumberProp(8, param, definitions)
    case 'custom-number-prop-9':
      return filterInfoForCustomNumberProp(9, param, definitions)
    case 'custom-number-prop-10':
      return filterInfoForCustomNumberProp(10, param, definitions)
    case 'custom-prop-1':
      return filterInfoForCustomProp(1, param, definitions)
    case 'custom-prop-2':
      return filterInfoForCustomProp(2, param, definitions)
    case 'custom-prop-3':
      return filterInfoForCustomProp(3, param, definitions)
    case 'custom-prop-4':
      return filterInfoForCustomProp(4, param, definitions)
    case 'custom-prop-5':
      return filterInfoForCustomProp(5, param, definitions)
    case 'custom-prop-6':
      return filterInfoForCustomProp(6, param, definitions)
    case 'custom-prop-7':
      return filterInfoForCustomProp(7, param, definitions)
    case 'custom-prop-8':
      return filterInfoForCustomProp(8, param, definitions)
    case 'custom-prop-9':
      return filterInfoForCustomProp(9, param, definitions)
    case 'custom-prop-10':
      return filterInfoForCustomProp(10, param, definitions)
    case 'duration': {
      const { min, max } = param
      return {
        icon: getFilterDefinition('duration', definitions).icon,
        label: <DurationValueLabel value={{ min, max }} />,
      }
    }
    case 'external-identity': {
      return {
        icon: getFilterDefinition('externalIdentity', definitions).icon,
        label: <ExternalIdentityValueLabel value={param.externalIdentity} />,
      }
    }
    case 'label': {
      return {
        icon: getFilterDefinition('labels', definitions).icon,
        label: <LabelsValueLabel value={param.labels} />,
      }
    }
    case 'sentiment': {
      const { score, speaker } = param
      return {
        icon: getFilterDefinition('sentiment', definitions).icon,
        label: <SentimentValueLabel value={{ score, speaker }} />,
      }
    }
    case 'status': {
      return {
        icon: getFilterDefinition('status', definitions).icon,
        label: <StatusValueLabel value={param.status as AdversusStatus} />,
      }
    }
    case 'subject': {
      return {
        icon: getFilterDefinition('subjects', definitions).icon,
        label: <SubjectValueLabel value={param.subjects} />,
      }
    }
    case 'not-tracker': {
      const value = {
        uids: param.trackerUids,
      }
      return {
        icon: getFilterDefinition('notTrackers', definitions).icon,
        label: <TrackersValueLabel state={value} exclusion />,
      }
    }
    case 'tracker': {
      const value = {
        uids: param.trackerUids,
      }
      return {
        icon: getFilterDefinition('trackers', definitions).icon,
        label: <TrackersValueLabel state={value} />,
      }
    }
    case 'team': {
      return {
        icon: getFilterDefinition('teamUids', definitions).icon,
        label: <TeamValueLabel uids={param.teamUids} />,
      }
    }
    case 'user': {
      return {
        icon: getFilterDefinition('userUids', definitions).icon,
        label: <UsersValueLabel uids={param.userUids} />,
      }
    }
    default:
      return {
        icon: BsQuestion,
        label: <span />,
      }
  }
}
