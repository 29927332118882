import { ShowModal, useModal } from '@capturi/use-modal'

import {
  CaseDetailsDrawer,
  CaseDrawerContainerProps,
} from './CaseDetailsDrawer'

export { CaseDetailsDrawer }

export function useCaseDetailsDrawer(): ShowModal<CaseDrawerContainerProps> {
  const [open] = useModal(CaseDetailsDrawer)
  return open
}
