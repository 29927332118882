import { TimeResolution } from '@capturi/core'
import { RequestOptions } from '@capturi/request'

import { baseQuery, controller } from '../constants'

export const activityInsightsAPI = {
  getActivitySummaries: (): RequestOptions => ({
    url: `${controller}/summaries/activity`,
    query: baseQuery,
    method: 'post',
  }),
  getDurationStatistics: (): RequestOptions => ({
    url: `${controller}/duration/organization`,
    query: baseQuery,
    method: 'post',
  }),
  getDurationStatisticsTimeSeries: (
    resolution: TimeResolution,
    offset: number,
  ): RequestOptions => ({
    url: `${controller}/duration/organization/time-series`,
    query: {
      ...baseQuery,
      resolution,
      offset,
    },
    method: 'post',
  }),
}
