import { t } from '@lingui/macro'
import { TopicsNode } from './types'

type UseTableData = {
  rows: TopicsNode[] | undefined
  otherRow: TopicsNode | undefined
}

export const mapTopicsNodeToTableData = (
  data: TopicsNode | undefined,
): UseTableData => {
  if (!data?.children) {
    return { rows: undefined, otherRow: undefined }
  }

  const otherRowIndex = data.children.findIndex((item) => item.other)
  let otherRow: TopicsNode | undefined
  let rows = data.children
  if (otherRowIndex !== -1) {
    const otherRowChild = data.children[otherRowIndex]
    otherRow = {
      ...otherRowChild,
      label: t`Other`,
      percentage: otherRowChild.percentage / 100,
      percentageOfTotal: otherRowChild.percentageOfTotal
        ? otherRowChild.percentageOfTotal / 100
        : undefined,
    }
    rows = [...rows.slice(0, otherRowIndex), ...rows.slice(otherRowIndex + 1)]
  }

  const sortedChildren = [...rows].sort((a, b) => b.amount - a.amount)
  return {
    rows: sortedChildren.map((item) => {
      return {
        ...item,
        percentage: item.percentage / 100,
        percentageOfTotal: item.percentageOfTotal
          ? item.percentageOfTotal / 100
          : undefined,
      }
    }),
    otherRow,
  }
}
