const secondsInDay = 86400
const secondsInHour = 3600

export const secondsToTimeValue = (
  seconds: number,
): { value: number; timeUnit: 'days' | 'hours' } => {
  if (seconds >= secondsInDay) {
    return {
      value: Math.ceil(seconds / secondsInDay),
      timeUnit: 'days',
    }
  }

  return {
    value: Math.ceil(seconds / secondsInHour),
    timeUnit: 'hours',
  }
}

export const timeValueToSeconds = (
  value: number,
  timeUnit: 'days' | 'hours',
): number => {
  return value * (timeUnit === 'days' ? secondsInDay : secondsInHour)
}
