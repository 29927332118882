import React, { useCallback, useMemo } from 'react'
import useAudio, { HTMLMediaState } from './useAudio'

import analytics from '@capturi/analytics'
import { useToast } from '@chakra-ui/react'
import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'
import { useCounter } from 'react-use'

declare global {
  interface Window {
    env?: {
      apiUrl: string
    }
  }
}

type AudioContextType = {
  audioState: HTMLMediaState
  playbackContext: PlaybackContext | null
  setPlaybackContext: (context: PlaybackContext | null) => void
  seek: (time: number) => void
  play: (timestamp?: number, context?: PlaybackContext) => void
  pause: () => void
  load: (source: string, startTime?: number, autoPlay?: boolean) => void
  playbackRate: HTMLMediaElement['playbackRate']
  setPlaybackRate: (rate: number | ((currentRate: number) => number)) => void
  getTime: () => number
  audioRef: React.RefObject<HTMLAudioElement>
  sourceRef: React.RefObject<HTMLSourceElement>
  isAudioLoading: boolean
}

export const AudioContext = React.createContext<AudioContextType>({
  audioState: {
    buffered: [],
    time: 0,
    duration: 0,
    paused: true,
    muted: false,
    volume: 1,
  },
  playbackContext: null,
  setPlaybackContext: noop,
  seek: noop,
  play: noop,
  pause: noop,
  load: noop,
  playbackRate: 1,
  setPlaybackRate: noop,
  getTime: () => 0,
  isAudioLoading: false,
  audioRef: { current: null },
  sourceRef: { current: null },
})

export type PlaybackContext = {
  timestamp?: number
  conversationUid?: string
  snippetUid?: string
  phrase?: string
  hitId?: string
  [key: string]: string | number | undefined
}

const generateFullPath = (source: string, type: string): string => {
  if (process.env.NODE_ENV === 'development')
    return 'https://cdn.freesound.org/previews/717/717965_1648170-lq.mp3'

  const baseUrl = 'https://api.capturi.ai'
  let sourceEndpoint: string
  switch (type) {
    case 'audio/ogg':
      sourceEndpoint = '/opus'
      break

    case 'audio/x-caf':
      sourceEndpoint = '/caf'
      break

    case 'audio/wav':
      sourceEndpoint = '/wav'
      break

    default:
      sourceEndpoint = ''
      break
  }

  return `${baseUrl}${source}${sourceEndpoint}?api-version=3.3`
}

const AudioProvider: React.FC<{
  multiSource?: boolean
  enableTimeUpdates?: boolean
  enableProgressUpdates?: boolean
  children?: React.ReactNode
}> = ({
  multiSource = true,
  enableTimeUpdates = false,
  enableProgressUpdates = false,
  children,
}) => {
  const sourceRef = React.useRef<HTMLSourceElement | null>(null)

  const audioElement = (
    // biome-ignore lint/a11y/useMediaCaption: <explanation>
    <audio>
      {multiSource ? (
        <>
          <source type="audio/ogg" />
          <source type="audio/x-caf" />
          <source ref={sourceRef} type="audio/wav" />
        </>
      ) : (
        <source ref={sourceRef} />
      )}
    </audio>
  )

  const [, { inc: forceUpdate }] = useCounter()
  const toast = useToast()
  const [audio, audioState, controls, audioRef] = useAudio(audioElement, {
    disableProgressUpdates: !enableProgressUpdates,
    disableTimeUpdates: !enableTimeUpdates,
  })

  const [isAudioLoading, setIsAudioLoading] = React.useState(false)
  const [playbackContext, setPlaybackContext] =
    React.useState<PlaybackContext | null>(null)

  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy
  React.useEffect(() => {
    if (audioRef.current) {
      for (const s of audioRef.current.children) {
        if ((s as HTMLSourceElement).src === audioRef.current?.currentSrc)
          sourceRef.current = s as HTMLSourceElement
      }
    }
  }, [audioRef, audioRef.current?.currentSrc])

  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy
  React.useEffect(() => {
    let observerRef: HTMLSourceElement | null = null
    const listenerFunction = (): void => {
      analytics.event('audio_failed_loading')
      setIsAudioLoading(false)
    }

    if (
      sourceRef.current &&
      sourceRef.current.src !== '' &&
      playbackContext !== null
    ) {
      observerRef = sourceRef.current
      observerRef.addEventListener('error', listenerFunction)
    }

    return () => {
      if (observerRef)
        observerRef.removeEventListener('error', listenerFunction)
    }
  }, [playbackContext, sourceRef.current?.src, toast])

  const seek = (time: number): void => {
    controls.seek(time)
  }

  const play = (timestamp?: number, context?: PlaybackContext): void => {
    if (timestamp !== undefined) {
      seek(timestamp)
    }
    if (context !== undefined) {
      setPlaybackContext(context)
    }

    /*
    Depending on what page the context comes from we have different ways of ensuring that the same playback is not re-loaded by the UI.
    Library page - Context is NOT undefined when context change
    Drawers (Analytic pages) - ConversationUid is NOT undefined when context change   
    Conversation Page - Does it differently (uses toggle()), and does not use this.
    */
    if (
      context !== undefined &&
      context.conversationUid !== playbackContext?.conversationUid
    )
      setIsAudioLoading(true)

    controls.play()
  }

  const pause = (): void => {
    controls.pause()
  }

  const load = (source: string, startTime = 0, autoPlay = false): void => {
    if (sourceRef.current?.src.includes(source)) return
    if (audioRef.current && sourceRef.current) {
      audioRef.current.onloadedmetadata = () => {
        if (audioRef.current == null) return
        const duration = audioRef.current.duration
        if (Number.isFinite(duration) && startTime >= duration) {
          audioRef.current.currentTime = 0
        } else {
          audioRef.current.currentTime = startTime
        }
        forceUpdate()
      }
      // AudioRef resets on load (clicking play), passing on the preivues playbackrate to the new playback. To keep same playbackRate for drawer.
      const pastPlaybackRate = audioRef.current.playbackRate
      if (audioRef)
        for (const s of audioRef.current.children) {
          const sr = s as HTMLSourceElement
          sr.src = generateFullPath(source, sr.type)
        }

      setIsAudioLoading(true) // When toggle() is used, load() runs before the play() callback.
      const startLoadTime = new Date().getTime()
      let isNewContext = true

      audioRef.current.load()

      audioRef.current.addEventListener('canplay', () => {
        setIsAudioLoading(false)
        const deltaTime = new Date().getTime() - startLoadTime
        if (isNewContext) {
          analytics.event('analytics_time_spent_loading_audio', {
            loadingTime: deltaTime,
            duration: audioRef.current?.duration,
            format: sourceRef.current?.type,
          })
          isNewContext = false
        }
      })

      audioRef.current.playbackRate = pastPlaybackRate
      if (autoPlay) controls.play()
    }
  }

  const setPlaybackRate = (
    rate: number | ((currentRate: number) => number),
  ): void => {
    if (audioRef.current == null) {
      return
    }
    audioRef.current.playbackRate =
      typeof rate === 'function' ? rate(audioRef.current.playbackRate) : rate
    forceUpdate()
  }

  const getTime = (): number => {
    return audioRef.current?.currentTime ?? 0
  }

  const context: AudioContextType = {
    audioState,
    playbackContext,
    setPlaybackContext,
    seek,
    play,
    pause,
    load,
    setPlaybackRate,
    playbackRate: audioRef.current?.playbackRate ?? 1,
    getTime,
    audioRef,
    isAudioLoading,
    sourceRef,
  }

  return (
    <>
      {audio}
      <AudioContext.Provider value={context}>{children}</AudioContext.Provider>
    </>
  )
}

export type AudioEventHandler = (args: {
  timestamp: number
  context?: PlaybackContext | null
}) => void

export type AudioOptions = {
  /**
   * A playback context is used to determine whether an instance of
   * useAudioPlayback is currently `active`, fx. when starting playback from an audio
   * bookmark (or snippet) only that bookmark among several other bookmarks should be determined
   * as `active`. The context is a set of keys and primitive value pairs which implicitly includes the `timestamp`
   * as well.
   */
  context?: PlaybackContext
  // Disable playback
  isDisabled?: boolean
  /**
   * On playback pause callback
   * @timestamp timestamp where pause was triggered
   * @context the current playback context
   */
  onPause?: AudioEventHandler
  /**
   * On playback start callback
   * @timestamp timestamp where start was triggered
   * @context the current playback context
   */
  onPlay?: AudioEventHandler
  /**
   * On seek callback
   * @timestamp new timestamp
   * @context the current playback context
   */
  onSeek?: AudioEventHandler
  /**
   * On playback stop callback
   * @timestamp timestamp where stop was triggered
   * @context the current playback context
   */
  onStop?: AudioEventHandler
  // Reset to `timestamp` on resume
  resetTimestampOnResume?: boolean
  // Start playing `x` seconds before `timestamp`
  rollbackSeconds?: number
  // Where playback begins
  timestamp?: number
}

export type UseAudioPlayback = Omit<AudioContextType, 'play'> & {
  // All key/value pairs of the current playback context match this context
  isContextMatch: boolean
  // If the audio source is playing and there is a context match
  isPlaying: boolean
  // Audio source URL of the audio context
  audioSource: string | undefined
  // Pause playback
  pause: () => void
  // Start playback
  play: (
    timestamp?: number,
    resetTimestampOnResume?: boolean,
    shortcutContext?: PlaybackContext,
  ) => Promise<void>
  // Seek to timestamp
  seek: (timestamp: number) => void
  // Stop playback and reset to `options.timestamp`
  stop: () => void
  /**
   * Toggle play/pause.
   * @returns boolean `true` indicates `play`
   * */
  toggle: (resetTimestampOnResume?: boolean) => boolean
}

const useAudioContext = (
  sourceUrl?: string,
  options?: AudioOptions,
): UseAudioPlayback => {
  const ctx = React.useContext(AudioContext)
  const onPause = options?.onPause
  const onPlay = options?.onPlay

  const handleOnPause = useCallback(
    (args: { timestamp: number; context?: PlaybackContext | null }) => {
      onPause?.(args)
    },
    [onPause],
  )

  const handleOnPlay = useCallback(
    (args: { timestamp: number; context?: PlaybackContext | null }) => {
      onPlay?.(args)
    },
    [onPlay],
  )

  const isAudioPaused = ctx.audioState.paused

  const playbackContext = useMemo(
    () => ({
      ...(options?.context ?? {}),
      timestamp: options?.timestamp,
    }),
    [options?.context, options?.timestamp],
  )

  const isContextMatch = useCallback(
    (context: PlaybackContext) => {
      return isEqual(ctx.playbackContext, context)
    },
    [ctx.playbackContext],
  )

  const isPlaying = useCallback(
    (context: PlaybackContext) => {
      if (isAudioPaused) return false
      if (context.timestamp === undefined) return true
      return isContextMatch(context)
    },
    [isAudioPaused, isContextMatch],
  )

  const pause = useCallback(() => {
    ctx.pause()
    handleOnPause?.({
      timestamp: ctx.getTime(),
      context: playbackContext,
    })
  }, [ctx, handleOnPause, playbackContext])

  const play = useCallback(
    async (
      timestamp?: number,
      resetTimestampOnResumeOverride?: boolean,
      shortcutContext?: PlaybackContext,
    ): Promise<void> => {
      if (options?.isDisabled === true) {
        return
      }

      const playbackTimestamp = (ts?: number): number | undefined => {
        // Subtract `rollbackSeconds` if provided
        return ts === undefined
          ? undefined
          : ts - (options?.rollbackSeconds ?? 0)
      }

      const playbackTime = timestamp ?? options?.timestamp

      ctx.load(sourceUrl ?? '', playbackTimestamp(playbackTime))

      const resetTimestampOnResume =
        resetTimestampOnResumeOverride ?? options?.resetTimestampOnResume

      if (
        isContextMatch(playbackContext) ||
        (shortcutContext !== undefined && isContextMatch(shortcutContext))
      ) {
        // Play from timestamp or resume if no timestamp is given.
        // If `resetTimestampOnResume === true` then set initial timestamp.
        ctx.play(
          resetTimestampOnResume ? playbackTimestamp(playbackTime) : timestamp,
        )
      } else {
        // This is a context change - set timestamp and context
        ctx.play(playbackTimestamp(playbackTime), playbackContext)
      }
      handleOnPlay({
        timestamp: ctx.getTime(),
        context: playbackContext,
      })
    },
    [
      options?.isDisabled,
      options?.timestamp,
      options?.resetTimestampOnResume,
      options?.rollbackSeconds,
      ctx,
      sourceUrl,
      isContextMatch,
      playbackContext,
      handleOnPlay,
    ],
  )

  const seek = useCallback(
    (time: number) => {
      ctx.seek(time)

      ctx.load(sourceUrl ?? '', time)

      options?.onSeek?.({
        timestamp: time,
        context: playbackContext,
      })
    },
    [ctx, options, playbackContext, sourceUrl],
  )

  const stop = useCallback(() => {
    ctx.pause()
    ctx.seek(options?.timestamp ?? 0)
    options?.onStop?.({
      timestamp: ctx.getTime(),
      context: playbackContext,
    })
  }, [ctx, options, playbackContext])

  const toggle = useCallback(
    (resetTimestampOnResume?: boolean): boolean => {
      if (isAudioPaused) {
        play(undefined, resetTimestampOnResume)
        return true
      }

      if (
        options?.timestamp !== undefined &&
        !isContextMatch(playbackContext)
      ) {
        // This is a context change
        play(undefined, resetTimestampOnResume)
        return true
      }
      pause()
      return false
    },
    [
      isAudioPaused,
      isContextMatch,
      options?.timestamp,
      pause,
      play,
      playbackContext,
    ],
  )

  return {
    ...ctx,
    audioSource: sourceUrl,
    isContextMatch: isContextMatch(playbackContext),
    isPlaying: isPlaying(playbackContext),
    pause,
    play,
    seek,
    stop,
    toggle,
  }
}

export { AudioProvider, useAudioContext }
