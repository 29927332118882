import { Team, comparators } from '@capturi/core'
import { useMemo } from 'react'
import useSWR, { SWRConfiguration, SWRResponse } from 'swr'

type TeamsResponseModel = {
  teams: Team[]
}

function useTeamsFilterOptions(swrConfig: SWRConfiguration = {}): {
  teams: Team[]
  isLoading: boolean
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  mutate: SWRResponse<TeamsResponseModel, any>['mutate']
} {
  const { data, error, mutate } = useSWR<TeamsResponseModel>(
    'authentication/filter/teams',
    {
      suspense: true,
      revalidateOnFocus: false,
      dedupingInterval: 10000,
      ...swrConfig,
    },
  )

  return useMemo(() => {
    return {
      teams: (data?.teams ?? []).sort(comparators.compareTeams),
      isLoading: !(data || error),
      mutate,
    }
  }, [data, error, mutate])
}

export default useTeamsFilterOptions
