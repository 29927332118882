import { Summary, useSummaries } from '@capturi/api-summaries'
import {
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import { ResponseError } from '@capturi/request'
import { useMemo } from 'react'

//Predefined list of positive/negative emojis
const highlyPositives = ['🥰', '😀', '🤩', '🥳', '👍', '☀️', '🏆']
const highlyNegatives = ['🤯', '😳', '😢', '😶‍🌫️', '😪', '🫥', '🌧️']

type SummariesWithSentimentData = {
  isLoading: boolean
  error: ResponseError | null
  data: WidgetSummary[]
}

export type WidgetSummary = Summary & { emoji: string }

export function useSentimentSummaries(): SummariesWithSentimentData {
  const segmentState = useFirstPhoneSegmentState()
  const { period } = useFilterPeriodContext()

  const {
    data: summaries,
    isLoading: areSummariesLoading,
    error,
  } = useSummaries(segmentState.values, period, 40, true)

  const mappedSentimentSummaries = useMemo(() => {
    if (!summaries?.pages.length) {
      return []
    }
    const data = summaries.pages[0]
      .reduce<WidgetSummary[]>((acc, summary) => {
        if (
          summary.rootCause === null ||
          summary.sentiment.wordScore === null ||
          summary.sentiment.wordScore.other === null ||
          summary.sentiment.wordScore.other === 'Medium'
        ) {
          return acc
        }

        acc.push({
          ...summary,
          emoji:
            summary.sentiment.wordScore?.other === 'High'
              ? highlyPositives[
                  Math.floor(Math.random() * highlyPositives.length)
                ]
              : highlyNegatives[
                  Math.floor(Math.random() * highlyNegatives.length)
                ],
        })
        return acc
      }, [])
      .sort((a, b) => a.dateTime.getDate() - b.dateTime.getDate())
    return data
  }, [summaries?.pages[0]])

  return {
    isLoading: areSummariesLoading,
    error: error,
    data: mappedSentimentSummaries,
  }
}
