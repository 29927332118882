import { OnChangeValue, SelectOption } from '@capturi/ui-select'
import React from 'react'

import {
  FilterCriteriaComponentBaseProps,
  FilterCriteriaSelect,
} from '../../components/PhoneFilter/components/PhoneSegmentBuilder'
import useTeamsFilterOptions from '../../data/useTeamsFilterOptions'
import { defaultSWRConfig } from '../constants'
import getMissingOptions from '../getMissingOptions'

export type TeamSelectConfig = {
  isMulti?: boolean
}

export type TeamSelectProps = FilterCriteriaComponentBaseProps<
  string[],
  TeamSelectConfig
> & { showFooter?: boolean }

export function TeamSelect(props: TeamSelectProps): React.ReactElement {
  const { teams } = useTeamsFilterOptions(defaultSWRConfig)
  const isMultiSelect = props.options?.isMulti ?? true

  const { options, value } = React.useMemo(() => {
    const teamUids = props.value ?? []

    const options = teams.map((x) => ({
      value: x.uid,
      label: x.name,
    }))

    const value = teamUids.map((x) => {
      const option = options.find((o) => o.value === x)
      return (
        option ?? {
          value: x,
          label: x,
        }
      )
    })
    const missingOptions = getMissingOptions(teamUids, options)

    return {
      options: missingOptions.length
        ? [...missingOptions, ...options]
        : options,
      value,
    }
  }, [teams, props.value])

  const handleChange = (option: OnChangeValue<SelectOption, boolean>): void => {
    if (option == null) {
      props.resetValue?.()
      return
    }
    const options = (Array.isArray(option) ? option : [option]) as NonNullable<
      OnChangeValue<SelectOption, true>
    >
    props.setValue?.(options.map((x) => x.value))
    if (!isMultiSelect) {
      props.onClose?.()
    }
  }

  return (
    <FilterCriteriaSelect
      options={options}
      value={value}
      onChange={handleChange}
      autoFocus={true}
      onClose={props.onClose}
      isMulti={isMultiSelect}
      showFooter={props.showFooter}
    />
  )
}
