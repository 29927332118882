import { Grid, Skeleton, SkeletonProps } from '@chakra-ui/react'
import { css } from '@emotion/react'
import React from 'react'

const lineHeight = '16px'

const ListSkeleton: React.FC<
  {
    rowCount?: number
    colCount?: number
    fadeInContent?: boolean
  } & SkeletonProps
> = ({
  rowCount = 5,
  colCount = 4,
  fadeInContent = true,
  isLoaded,
  children,
  ...restProps
}) => {
  if (isLoaded) {
    const cssStyle = fadeInContent
      ? ''
      : css`
          animation: none;
        `
    return (
      <Skeleton isLoaded {...restProps} css={cssStyle}>
        {children}
      </Skeleton>
    )
  }
  const templateColumns = ['1fr']
    .concat(Array(Math.max(0, colCount - 1)).fill('70px'))
    .join(' ')
  return (
    <Grid
      templateColumns={templateColumns}
      templateRows={`repeat(${rowCount}, ${lineHeight})`}
      columnGap={2}
      rowGap={4}
      alignItems="center"
      p={4}
      {...restProps}
    >
      {[...Array(rowCount).keys()].map((i) => (
        <React.Fragment key={i}>
          {[...Array(colCount).keys()].map((j) => (
            <Skeleton key={j} h={lineHeight} />
          ))}
        </React.Fragment>
      ))}
    </Grid>
  )
}

export default ListSkeleton
