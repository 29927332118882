import { ErrorBoundary } from '@capturi/react-utils'
import TabsSkeleton from 'pages/analytics/trackers/components/TabsSkeleton'
import { TagsProvider } from 'pages/analytics/trackers/hooks/useTagsContext'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import CreateTrackerPage from './pages/CreateTrackerPage'
import EditTrackerPage from './pages/EditTrackerPage'
import TrackersPage from './pages/TrackersPage'

export { default as routes } from './routes'

const TrackerPageRouter: React.FC = () => {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<TabsSkeleton />}>
        <TagsProvider>
          <Routes>
            <Route path="/" element={<TrackersPage />} />
            <Route path="create" element={<CreateTrackerPage />} />
            <Route path="edit/:uid" element={<EditTrackerPage />} />
          </Routes>
        </TagsProvider>
      </React.Suspense>
    </ErrorBoundary>
  )
}

export default TrackerPageRouter
