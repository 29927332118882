import clamp from 'lodash/clamp'

type BaseTab = {
  label: string
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  value: any
}

type UseSpeakerTabsState<T extends BaseTab> = {
  index: number
  getSpeaker: (index: number) => T
  speakers: T[]
}

function useSpeakerTabsState<T extends BaseTab>(
  speakers: T[] | (() => T[]),
  speaker: T['value'],
): UseSpeakerTabsState<T> {
  const options = typeof speakers === 'function' ? speakers() : speakers
  const index = clamp(
    options.findIndex((o) => o.value === speaker),
    0,
    options.length - 1,
  )
  return {
    index,
    getSpeaker: (index) => options[index],
    speakers: options,
  }
}

export default useSpeakerTabsState
