import { Stack, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

import TextTrackerItem from './CaseTrackerItem'
import { TrackerShape } from './types'

type TrackersViewProps = {
  selectedTrackerUid: string | null
  trackerList: TrackerShape[]
  onSelectTracker: (trackerUid: string) => void
}

const CaseTrackersView: React.FC<TrackersViewProps> = ({
  trackerList,
  selectedTrackerUid,
  onSelectTracker,
}) => {
  return (
    <VStack gap="0" align="flex-start">
      <Text fontSize="sm" fontWeight="medium">
        <Trans>Trackers</Trans>
      </Text>
      {trackerList.length > 0 ? (
        <Stack spacing={3} pt={1}>
          {trackerList.map((item) => {
            return (
              <TextTrackerItem
                key={item.uid}
                uid={item.uid}
                color={item.color}
                shape={item.shape}
                trackerHit={item.count}
                tracker={item.name}
                selectedTrackerUid={selectedTrackerUid}
                onSelectTracker={onSelectTracker}
              />
            )
          })}
        </Stack>
      ) : (
        <Text mt="2" fontSize="lg" color="gray.600">
          <Trans>No trackers on the conversation</Trans>
        </Text>
      )}
    </VStack>
  )
}
//
export default CaseTrackersView
