import { AdversusStatus } from '@capturi/api-conversations'
import { t } from '@lingui/macro'

function translateAdversusStatus(status: AdversusStatus | undefined): string {
  switch (status) {
    case 'success':
      return t`AdversusStatus_success`
    case 'notInterested':
      return t`AdversusStatus_notInterested`
    case 'privateRedial':
      return t`AdversusStatus_privateRedial`
    case 'automaticRedial':
      return t`AdversusStatus_automaticRedial`
    case 'unqualified':
      return t`AdversusStatus_unqualified`
    case 'invalid':
      return t`AdversusStatus_invalid`
    case 'unknown':
      return t`AdversusStatus_unknown`
    default: {
      return status as string
    }
  }
}

export default translateAdversusStatus
