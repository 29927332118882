import { t } from '@lingui/macro'
import React from 'react'
import { IoMdPricetag } from 'react-icons/io'

import { MultiValueFilterItem } from '../useTextFilter'
import { BasePopoverSelectFilter } from './BasePopoverSelectFilter'
import { TextFilterComponentProps } from './types'

export const LabelsFilter: React.FC<
  TextFilterComponentProps<MultiValueFilterItem> & {
    isOpen: boolean
    onOpen: (itemUid: string) => void
  }
> = (props) => {
  return (
    <BasePopoverSelectFilter
      {...props}
      filter={{ name: 'Tags' }}
      singularEntityName={t`Label`}
      pluralEntityName={t`Label(s)`}
      iconComponent={IoMdPricetag}
      onChangeValue={(item) => {
        props.onChangeValue({
          ...item,
          filterType: 'tagFilters',
        })
      }}
    />
  )
}
