import { mode } from '@chakra-ui/theme-tools'
// biome-ignore lint/suspicious/noExplicitAny: legacy
type Dict = Record<string, any>

function baseStyleTrack(props: Dict): Dict {
  return {
    bg: mode('gray.300', 'whiteAlpha.300')(props),
  }
}

export default {
  baseStyle: (props: Dict): Dict => ({
    track: baseStyleTrack(props),
  }),
}
