import {} from '../containers/SegmentsContainer'

import { PhoneFilterValues, TextFilterValues } from '@capturi/api-filters'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

import { toFilterValues, toTextFilterValues } from '../mappers'
import { PhoneSegmentBuilderState, TextSegmentBuilderState } from '../types'

export const getFilterCriteriaCount = (
  state: Partial<PhoneFilterValues>,
): number => {
  return Object.values(state).filter((x) => !isEmpty(x)).length
}

export const hasFilterValues = (
  state: Partial<PhoneFilterValues | TextFilterValues> | undefined,
): boolean => {
  if (!state) return false
  return Object.values(state).some((x) => {
    if (typeof x === 'boolean') return true
    return x && !isEmpty(x)
  })
}

export const hasUnsavedChanges = (state: PhoneSegmentBuilderState): boolean => {
  if (state.channel !== 'phone') {
    return false
  }

  if (state.savedFilter?.values == null) return false
  return !isEqual(state.values, toFilterValues(state.savedFilter.values))
}

export const textSegmentHasUnsavedValuesChanges = (
  state: TextSegmentBuilderState,
): boolean => {
  if (
    state.channel !== 'email' ||
    state.savedTextFilter == null ||
    state.savedTextFilter.accessLevel === 'None' || // Inaccessible filter
    state.savedTextFilter.values == null
  ) {
    return false
  }

  return !isEqual(
    state.values,
    toTextFilterValues(state.savedTextFilter.values),
  )
}
