import { Acl, PermissionPreset } from '@capturi/core'
import { Button } from '@capturi/ui-components'
import { Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import {
  MdEdit,
  MdLockOutline,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md'
import { UserPermissionsList } from '../components/UserPermissionsList'

export const PermissionPresetState: React.FC<{
  permissionPreset: PermissionPreset
  localizedEntityName: string
  acl: Acl | null
  onOpen: () => void
}> = ({ permissionPreset, acl, localizedEntityName, onOpen }) => {
  switch (permissionPreset) {
    case 'Inherit':
      return (
        <Button
          mb={2}
          leftIcon={<MdVisibility />}
          onClick={onOpen}
          variant="gold-outline"
        >
          <Trans>Same as folder</Trans>
        </Button>
      )
    case 'EveryoneCanView':
      return (
        <>
          <Button
            mb={2}
            leftIcon={<MdVisibility />}
            onClick={onOpen}
            variant="gold-outline"
          >
            <Trans>Everyone can view</Trans>
          </Button>
          <Text color="gray.600" fontSize="xs">
            <Trans>View: Everyone</Trans>
          </Text>
          <Text color="gray.600" fontSize="xs">
            <Trans>
              Edit: Users with permission to create {localizedEntityName}
            </Trans>
          </Text>
        </>
      )
    case 'Private':
      return (
        <>
          <Button
            mb={2}
            leftIcon={<MdVisibilityOff />}
            onClick={onOpen}
            variant="gold-outline"
          >
            <Trans>Private</Trans>
          </Button>
          <Text color="gray.600" fontSize="xs">
            <Trans>View: You</Trans>
          </Text>
          <Text color="gray.600" fontSize="xs">
            <Trans>Edit: You</Trans>
          </Text>
        </>
      )
    case 'Locked':
      return (
        <>
          <Button
            mb={2}
            leftIcon={<MdLockOutline />}
            onClick={onOpen}
            variant="gold-outline"
          >
            <Trans>Only you can edit</Trans>
          </Button>
          <Text color="gray.600" fontSize="xs">
            <Trans>View: Everyone</Trans>
          </Text>
          <Text color="gray.600" fontSize="xs">
            <Trans>Edit: You</Trans>
          </Text>
        </>
      )
    case 'Custom':
      return (
        <>
          <Button
            mb={2}
            leftIcon={<MdEdit />}
            onClick={onOpen}
            variant="gold-outline"
          >
            <Trans>Custom sharing rules</Trans>
          </Button>
          <Text color="gray.600" fontSize="xs">
            <Trans>Customized rules for who can view and edit</Trans>
          </Text>
          <UserPermissionsList acl={acl} isAccordion={false} />
        </>
      )
    case 'EveryoneCanEdit':
      return (
        <Button
          leftIcon={<MdVisibility />}
          onClick={onOpen}
          variant="gold-outline"
        >
          <Trans>Everyone can edit</Trans>
        </Button>
      )
  }
}
