import { chakra } from '@chakra-ui/react'

export const Highlight = chakra('span', {
  baseStyle: {
    fontWeight: 'medium',
  },
})

export const Description = chakra('p', {
  baseStyle: {
    color: 'gray.600',
  },
})

export const Caption = chakra('p', {
  baseStyle: {
    fontSize: 'sm',
  },
})

export const Underlined = chakra('span', {
  baseStyle: {
    textDecoration: 'underline',
  },
})
