import { Segment } from '@capturi/filters'

export type DataType = {
  segments: Segment[]
}

export const COLUMN_TYPES = [
  'user',
  'team',
  'value',
  'progress',
  'dataBasis',
  'button',
  'menu',
] as const
export type ColumnType = (typeof COLUMN_TYPES)[number]

export interface BaseColumnDefinition<T extends DataType> {
  type: ColumnType
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  getValue?: (row: T) => any
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  getSegmentValue?: (segment: T['segments'][number]) => any
  formatValue?: (value: number | undefined) => string
  alignRight?: boolean
  id?: string
  width?: string | number
}

export type ProgressColumn<T extends DataType> = BaseColumnDefinition<T> & {
  type: 'progress'
  range?: [number, number]
  progressBarHeight?: number
  referenceRange?: {
    min?: number
    max?: number
    label?: string
    formatterConfig?: RangeFormatterConfig
  }
  referenceLines?: {
    value: number | undefined
    label: string
    color: string
  }[]
}
export interface DataBasisColumn<T extends DataType>
  extends BaseColumnDefinition<T> {
  type: 'dataBasis'
  getSegmentValue: (
    segment: T['segments'][number],
  ) => number | { value: number; total: number }
}

export interface ButtonColumn<T extends DataType>
  extends BaseColumnDefinition<T> {
  type: 'button'
  render: (row: T, segmentIndex: number) => React.ReactElement
}

export interface MenuColumn<T extends DataType>
  extends BaseColumnDefinition<T> {
  type: 'menu'
  render: (row: T, segmentIndex: number) => React.ReactElement
}

export type ColumnDefinition<T extends DataType> =
  | BaseColumnDefinition<T>
  | ProgressColumn<T>
  | ButtonColumn<T>
  | DataBasisColumn<T>
  | MenuColumn<T>

export type RenderValueType = (value?: number) => string | React.ReactElement

export type RangeFormatterConfig = {
  // Less Than
  lt?: (value: number, renderValue: RenderValueType) => string
  // Greater Than
  gt?: (value: number, renderValue: RenderValueType) => string
  // Between min and max
  btw?: (min: number, max: number, renderValue: RenderValueType) => string
}
