import { Text } from '@chakra-ui/react'
import React from 'react'

import {
  ValidationError,
  getValidationErrorText,
} from '../hooks/usePreviewAccess'

export const PreviewAccessWarningText: React.FC<{
  warningText: string | undefined
}> = ({ warningText }) => {
  if (warningText === undefined) return null
  return (
    <Text color="warning" fontSize="xs" fontStyle="italic">
      {warningText}
    </Text>
  )
}

export const ValidationErrorText: React.FC<{
  validationError: ValidationError | string | undefined
}> = ({ validationError }) => {
  if (validationError === undefined) return null
  return (
    <Text color="danger" fontSize="xs" fontStyle="italic">
      {getValidationErrorText(validationError)}
    </Text>
  )
}
