import { ScoreParameter } from '@capturi/api-scoring'
import { useCurrentUser } from '@capturi/core'
import { useFilterDefinitions } from '@capturi/filters'
import { getFilterInfo } from '@capturi/scoring'
import orderBy from 'lodash/orderBy'
import React from 'react'
import { ScoreWithConversationData } from '../Hooks/useScoresWithConversationData'

export type ScoreParameterResult = {
  parameter: ScoreParameter
  score: number
  color: string
  filter: {
    icon: React.ComponentType
    label: React.ReactNode
  }
}

type ScoreBreakdown = {
  hits: ScoreParameterResult[]
  misses: ScoreParameterResult[]
}

const createColorIterator = (
  colors: string[],
): {
  next(): string
} => {
  let i = -1
  return {
    next: () => {
      i++
      return colors[i % colors.length]
    },
  }
}

export function useBreakdown(
  score: ScoreWithConversationData,
): ScoreBreakdown | undefined {
  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  return React.useMemo(() => {
    if (score === undefined) return undefined
    const colorIterator = createColorIterator([
      'segmentPrimary',
      'segmentSecondary',
      'segmentTertiary',
      'segmentQuaternary',
      'segmentQuinary',
    ])

    const { hits, misses } = score.parameters.reduce<{
      hits: ScoreParameterResult[]
      misses: ScoreParameterResult[]
    }>(
      (memo, param) => {
        const paramResult = score.conversationData.parameters.find(
          (x) => x.uid === param.uid,
        )

        const result = {
          parameter: param,
          color: 'gray.200',
          score: paramResult?.score ?? 0,
          filter: getFilterInfo(param, filterDefinitions),
        }
        if (paramResult && paramResult.score > 0) {
          memo.hits.push(result)
        } else {
          memo.misses.push(result)
        }
        return memo
      },
      {
        hits: [],
        misses: [],
      },
    )

    const sortParams = (
      params: ScoreParameterResult[],
    ): ScoreParameterResult[] =>
      orderBy(params, ['parameter.weight', 'definition.name'], ['desc', 'asc'])

    return {
      hits: sortParams(hits).map((x) => ({
        ...x,
        color: colorIterator.next(),
      })),
      misses: sortParams(misses),
    }
  }, [score, filterDefinitions])
}
