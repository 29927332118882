import React from 'react'

import ShadowFilter from './ShadowFilter'

const DiamondWithOutline: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#shadow)">
        <path
          d="M8.29289 1.70711C8.68342 1.31658 9.31658 1.31658 9.70711 1.70711L15.2929 7.29289C15.6834 7.68342 15.6834 8.31658 15.2929 8.70711L9.70711 14.2929C9.31658 14.6834 8.68342 14.6834 8.29289 14.2929L2.70711 8.70711C2.31658 8.31658 2.31658 7.68342 2.70711 7.29289L8.29289 1.70711Z"
          fill={color}
        />
        <path
          d="M8.29289 1.70711C8.68342 1.31658 9.31658 1.31658 9.70711 1.70711L15.2929 7.29289C15.6834 7.68342 15.6834 8.31658 15.2929 8.70711L9.70711 14.2929C9.31658 14.6834 8.68342 14.6834 8.29289 14.2929L2.70711 8.70711C2.31658 8.31658 2.31658 7.68342 2.70711 7.29289L8.29289 1.70711Z"
          stroke="white"
        />
      </g>
      <ShadowFilter />
    </svg>
  )
}
const DiamondNoOutline: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#shadow)">
        <path
          d="M5.29289 0.707106C5.68342 0.316582 6.31658 0.316583 6.70711 0.707107L10.2929 4.29289C10.6834 4.68342 10.6834 5.31658 10.2929 5.70711L6.70711 9.29289C6.31658 9.68342 5.68342 9.68342 5.29289 9.29289L1.70711 5.70711C1.31658 5.31658 1.31658 4.68342 1.70711 4.29289L5.29289 0.707106Z"
          fill={color}
        />
      </g>
      <ShadowFilter />
    </svg>
  )
}

const Diamond: React.FC<{ color: string; outline: boolean }> = ({
  color,
  outline,
}) => {
  return outline ? (
    <DiamondWithOutline color={color} />
  ) : (
    <DiamondNoOutline color={color} />
  )
}

export default React.memo(Diamond)
