import { Button, getLocalizedCustomPropLabel } from '@capturi/ui-components'
import { Flex, Input } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

import { CustomPropFilterProps } from './types'

function transformToArrayFormat(value: string): string[] {
  return [value]
}

type CustomPropTextProps = CustomPropFilterProps<'Text'> & {
  initialFocusRef?: React.RefObject<HTMLInputElement>
}

export function CustomPropText({
  settings,
  locale,
  ...props
}: CustomPropTextProps): React.ReactElement {
  const [value, setValue] = React.useState<string[]>(() => {
    if (Array.isArray(props.value) && typeof props.value[0] === 'string') {
      return transformToArrayFormat(props.value[0])
    }
    return transformToArrayFormat('')
  })

  const handleSubmit = (): void => {
    props.setValue?.(transformToArrayFormat(value[0].trim()))
    props.onClose?.()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <Flex m={2}>
      <Input
        ref={props.initialFocusRef}
        onChange={(e) =>
          setValue(transformToArrayFormat(e.currentTarget.value))
        }
        value={value}
        placeholder={getLocalizedCustomPropLabel(settings.label, locale) ?? '?'}
        onKeyDown={handleKeyDown}
        borderTopRightRadius={0}
        borderBottomRightRadius={0}
        size="sm"
      />
      <Button
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
        onClick={handleSubmit}
      >
        <Trans>Search</Trans>
      </Button>
    </Flex>
  )
}
