import React from 'react'
import { useMatch } from 'react-router-dom'

const MostRecentDashboardAndFolderContext = React.createContext<{
  mostRecentDashboardUid: string | undefined
  mostRecentFolderUid: string | undefined
}>({ mostRecentDashboardUid: undefined, mostRecentFolderUid: undefined })

export const useMostRecentDashboardAndFolder = (): React.ContextType<
  typeof MostRecentDashboardAndFolderContext
> => React.useContext(MostRecentDashboardAndFolderContext)

export const MostRecentDashboardAndFolderProvider: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const mostRecentDashboardUid = React.useRef<string | undefined>(undefined)
  const dashboardMatch = useMatch('/dashboards/:uid/*')
  if (dashboardMatch != null) {
    mostRecentDashboardUid.current = dashboardMatch.params.uid
  }

  const mostRecentFolderUid = React.useRef<string | undefined>(undefined)
  const folderMatch = useMatch('/dashboards/folders/:uid/*')
  if (folderMatch != null) {
    mostRecentFolderUid.current = folderMatch.params.uid
  }

  return (
    <MostRecentDashboardAndFolderContext.Provider
      value={{
        mostRecentDashboardUid: mostRecentDashboardUid.current,
        mostRecentFolderUid: mostRecentFolderUid.current,
      }}
    >
      {children}
    </MostRecentDashboardAndFolderContext.Provider>
  )
}
