import { Spinner } from '@capturi/ui-components'
import noop from 'lodash/noop'
import React, { createContext, useContext, useEffect, useState } from 'react'

import { LibraryFolderPermissions } from '../../types'
import PlaylistCreate from './PlaylistCreate'
import PlaylistSelect from './PlaylistSelect'

export type PlaylistSelectorContextType = {
  id?: string
  defaultValue?: string
  isRequired?: boolean
  isCreateMode: boolean
  setIsCreateMode: (val: boolean) => void
  state: PlaylistSelectorResult
  setCreateState: (state: Omit<PlaylistCreateResult, 'isNew'>) => void
  setSelectState: (result: Omit<PlaylistSelectResult, 'isNew'>) => void
}

export const PlaylistSelectorContext =
  createContext<PlaylistSelectorContextType>({
    isCreateMode: false,
    setIsCreateMode: noop,
    state: { uid: undefined, isNew: false },
    setCreateState: noop,
    setSelectState: noop,
  })

export type PlaylistSelectorProps = {
  id?: string
  defaultValue?: string
  isRequired?: boolean
  onChange: (result: PlaylistSelectorResult) => void
  children:
    | React.ReactNode
    | ((props: {
        isCreateMode: boolean
        setIsCreateMode: (val: boolean) => void
      }) => React.ReactElement)
}

export type PlaylistSelectResult = {
  uid: string | undefined
  name?: never
  description?: never
  permissions?: never
  isNew: false
}

export type PlaylistCreateResult = {
  uid?: never
  name: string
  description?: string
  permissions?: LibraryFolderPermissions | null
  isNew: true
}

export type PlaylistSelectorResult = PlaylistSelectResult | PlaylistCreateResult

export const PlaylistSelector = ({
  children,
  id,
  isRequired,
  defaultValue,
  onChange,
}: PlaylistSelectorProps): React.ReactElement => {
  const [isCreateMode, _setIsCreateMode] = useState(false)
  const [state, setState] = useState<PlaylistSelectorResult>({
    uid: defaultValue,
    isNew: false,
  })

  useEffect(() => {
    onChange(state)
  }, [state, onChange])

  const setCreateState = (state: Omit<PlaylistCreateResult, 'isNew'>): void => {
    setState({
      ...state,
      uid: undefined,
      isNew: true,
    })
  }

  const setSelectState = (state: Partial<PlaylistSelectResult> = {}): void => {
    setState({
      ...state,
      isNew: false,
    })
  }

  const setIsCreateMode = (isCreateMode: boolean): void => {
    _setIsCreateMode(isCreateMode)
    if (isCreateMode) {
      setCreateState({
        name: '',
        permissions: null,
      })
    } else {
      setSelectState()
    }
  }

  const ctx: PlaylistSelectorContextType = {
    id,
    isRequired,
    defaultValue,
    isCreateMode,
    setIsCreateMode,
    state,
    setCreateState,
    setSelectState,
  }
  return (
    <PlaylistSelectorContext.Provider value={ctx}>
      {typeof children === 'function'
        ? children({ isCreateMode, setIsCreateMode })
        : children}
    </PlaylistSelectorContext.Provider>
  )
}

export const PlaylistSelectorContent: React.FC = () => {
  const { isCreateMode } = useContext(PlaylistSelectorContext)
  return (
    <React.Suspense fallback={<Spinner display="block" />}>
      {isCreateMode ? <PlaylistCreate /> : <PlaylistSelect />}
    </React.Suspense>
  )
}
