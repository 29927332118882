import type {
  KeySubTopicCluster,
  KeyTopicCluster,
  KeyTopicsReadyResponse,
  KeyTopicsResponse,
} from 'pages/KeyTopics/hooks/types'
import { KeyTopicsSessionSelector } from '../types'

export type TopChangesWidgetSelectorTopics = {
  label: string
  amount: number
  expected: number
  amountVsExpectedDiff: number
  significant: boolean
  id: string
  graphData: {
    label: string
    percentage: number
  }[]
  keyTopics: KeyTopicCluster['keyTopics']
}

export type TopChangesWidgetReadySelector = {
  status: 'ready'
  benchmark: KeyTopicsReadyResponse['benchmark']
  keyTopicClusters: TopChangesWidgetSelectorTopics[]
}

const mapKeySubTopicClustersToGraphData = (
  keySubTopicClusters: KeySubTopicCluster[],
) =>
  keySubTopicClusters
    .filter((subTopicCluster) => !subTopicCluster.other)
    .toSorted((a, b) => b.amount - a.amount)
    .slice(0, 3)
    .map((subTopicCluster) => ({
      label: subTopicCluster.keySubTopics[0]?.name ?? subTopicCluster.label,
      percentage: subTopicCluster.percentage,
    }))

export const selector = (
  data: KeyTopicsResponse,
): KeyTopicsSessionSelector<TopChangesWidgetReadySelector> => {
  if (data === null) return { status: 'gone' } // handle 204 No Content response
  if (data.status === 'Failed') return { status: 'failed' }
  if (data.status === 'Processing' || data.status === 'Pending')
    return { status: 'loading' }

  return {
    status: 'ready',
    benchmark: data.benchmark,
    keyTopicClusters: data.keyTopicClusters
      .reduce<(KeyTopicCluster & { amountVsExpectedDiff: number })[]>(
        (acc, keyTopicCluster) => {
          const amountVsExpectedDiff =
            keyTopicCluster.amount - keyTopicCluster.expected
          if (keyTopicCluster.label !== 'OTHER' && amountVsExpectedDiff > 0) {
            acc.push({
              ...keyTopicCluster,
              amountVsExpectedDiff,
            })
          }
          return acc
        },
        [],
      )
      .toSorted((a, b) => {
        if (a.significant && !b.significant) {
          return -1
        }
        if (!a.significant && b.significant) {
          return 1
        }
        return b.amountVsExpectedDiff - a.amountVsExpectedDiff
      })
      .slice(0, 6)
      .map(
        ({
          amount,
          expected,
          amountVsExpectedDiff,
          id,
          icon,
          label,
          keyTopics,
          keySubTopicClusters,
          significant,
        }) => {
          const name = keyTopics.length > 0 ? keyTopics[0].name : label
          const clusterLabel = icon ? `${icon}\u00A0${name}` : name // \u00A0 is non-breaking space
          return {
            label: clusterLabel,
            amount,
            expected,
            amountVsExpectedDiff,
            significant,
            id,
            graphData: mapKeySubTopicClustersToGraphData(keySubTopicClusters),
            keyTopics,
          }
        },
      ),
  }
}
