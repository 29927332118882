export default {
  baseStyle: {
    control: {
      borderColor: 'gray.400',
    },
  },
  defaultProps: {
    colorScheme: 'primary',
  },
}
