import { activityInsightsAPI } from './api/activity'
import { coachingInsightsAPI } from './api/coaching'
import { conversationInsightsAPI } from './api/conversation'
import { hitRateInsightsAPI } from './api/hit-rate'
import { interactionInsightsAPI } from './api/interaction'
import { scoresInsightsAPI } from './api/scores'
import { trackerHitsInsightsAPI } from './api/tracker-hits'

export const insightsAPI = {
  ...activityInsightsAPI,
  ...coachingInsightsAPI,
  ...conversationInsightsAPI,
  ...hitRateInsightsAPI,
  ...interactionInsightsAPI,
  ...scoresInsightsAPI,
  ...trackerHitsInsightsAPI,
}
