import { PermissionPreset } from '@capturi/core'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

/**
 * Tracker hit rates are rounded percentages in order to limit the precision.
 * However, if the value is below 1% we should a single decimal precision.
 * @param value number between [0...1]
 * @returns formated percentage
 */
export const formatHitRate = (value: number | null | undefined): string => {
  if (value == null) return ''
  return i18n.number(value, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: value < 0.01 ? 1 : 0,
  })
}

export const roundHitRate = (value: number): number => {
  if (value < 0.01) return value
  return Math.round(value * 100) / 100
}

export const isProcessingInCurrentPeriod = (options: {
  isProcessing: boolean
  processingProgress: Date | null
  isTextProcessing: boolean
  textProcessingProgress: Date | null
  periodFrom: Date
}): boolean => {
  const processingInCurrentPeriod =
    options.isProcessing &&
    (options.processingProgress === null || // Processing not started yet, but will start soon
      (options.processingProgress != null &&
        options.processingProgress > options.periodFrom))

  const textProcessingInCurrentPeriod =
    options.isTextProcessing &&
    (options.textProcessingProgress === null || // Processing not started yet, but will start soon
      (options.textProcessingProgress != null &&
        options.textProcessingProgress > options.periodFrom))

  return processingInCurrentPeriod || textProcessingInCurrentPeriod
}

export const switchPermissionPreset = (param: PermissionPreset): string => {
  switch (param) {
    case 'EveryoneCanView':
      return t`Everyone can view`
    case 'EveryoneCanEdit':
      return t`Everyone can edit`
    case 'Private':
      return t`Private`
    case 'Custom':
      return t`Custom`
    case 'Locked':
      return t`Only you can edit`
    case 'Inherit':
      return t`Same as folder`
  }
}
