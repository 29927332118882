import { Range } from '@capturi/api-filters'
import { formatSecondsToLongHMS } from '@capturi/ui-components'
import { t } from '@lingui/macro'
import isNumber from 'lodash/isNumber'

export function areEqual(a: Range, b: Range): boolean {
  const minEqual = a.min === b.min || (a.min == null && b.min == null)
  const maxEqual = a.max === b.max || (a.max == null && b.max == null)
  return minEqual && maxEqual
}

export function formatDuration(duration: Range): string {
  const { min, max } = duration
  if (isNumber(min) && isNumber(max)) {
    return `${formatSecondsToLongHMS(min)} - ${formatSecondsToLongHMS(max)}`
  }
  if (isNumber(max)) {
    return t`Maximum ${formatSecondsToLongHMS(max)}`
  }
  if (isNumber(min)) {
    return t`Minimum ${formatSecondsToLongHMS(min)}`
  }
  return ''
}
