import { generateImageUrl } from '@capturi/request'
import { User } from '@capturi/stores'
import { Avatar, Flex, Text, VStack } from '@chakra-ui/react'
import React from 'react'

// User avatar + name + email

export const UserView: React.FC<{
  user: User
}> = ({ user }) => {
  return (
    <>
      <Avatar
        name={user.name}
        src={generateImageUrl(user.profileImage?.key, { size: 32 })}
        aria-label={user.name}
        size="sm"
      />
      <Flex alignItems="center" w="100%" overflow="hidden">
        <VStack align="start" spacing={0} overflow="hidden">
          <Text m="0" fontSize="sm" fontWeight="medium">
            {user.name}
          </Text>
          <Text
            m="0"
            fontSize="xs"
            color="gray.600"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            w="100%"
          >
            {user.email}
          </Text>
        </VStack>
      </Flex>
    </>
  )
}
