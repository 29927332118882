import { Box, FlexProps, Icon, Text, Tooltip } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { MdVisibilityOff } from 'react-icons/md'

// Lightweight Tag, not using Chakra's Tag component for purpose of performance
const PrivateTrackerTag: React.FC<FlexProps> = (props) => {
  return (
    <Tooltip label={t`This tracker is private and only visible to you`}>
      <Box
        as="span"
        fontSize="xs"
        fontWeight="medium"
        minH="5"
        minW="5"
        bg="cremeYellow"
        borderRadius="md"
        color="gray.800"
        alignItems="center"
        display="inline-flex"
        verticalAlign="top"
        maxWidth="100%"
        px="2"
        {...props}
      >
        <Icon as={MdVisibilityOff} mr={1} verticalAlign="top" />
        <Text lineHeight={1} noOfLines={1}>
          <Trans>Private</Trans>
        </Text>
      </Box>
    </Tooltip>
  )
}

export default React.memo(PrivateTrackerTag)
