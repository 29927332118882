import type { TrackerFolder } from '@capturi/api-trackers'
import { Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import type { FC } from 'react'

type Props = {
  folderData: TrackerFolder | undefined
}

export const MasterFolderContent: FC<Props> = ({ folderData }) => {
  if (folderData && !folderData.masterSettings) {
    return (
      <Text color="danger">
        <Trans>
          Chosen folder is not a master folder. Either make the folder a master
          folder or choose another folder.
        </Trans>
      </Text>
    )
  }
  if (folderData?.masterSettings) {
    return (
      <Text color="gray.600" noOfLines={1}>
        {folderData?.title}
      </Text>
    )
  }
  return (
    <Text color="gray.600">
      <Trans>No folder</Trans>
    </Text>
  )
}
