import { Highlight } from '@capturi/ui-components'
import { InvertibleMultiSelectFooter, OnChangeValue } from '@capturi/ui-select'
import {
  Box,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { Plural, Trans, t } from '@lingui/macro'
import React, { useCallback, useMemo } from 'react'

import {
  FilterCriteriaButton,
  FilterCriteriaSelect,
} from '../../PhoneFilter/components/PhoneSegmentBuilder'
import { Filter, useFilterValues } from './hooks/useFilterValues'

type FilterOption = {
  label: string
  value: string
}

type MultiValueItem = {
  uid: string
  values: string[] | null
  inverted: boolean
}

interface BasePopoverSelectFilterProps {
  item: MultiValueItem
  onChangeValue: (item: MultiValueItem) => void
  onClose: () => void
  onRemove: (itemUid: string) => void

  isOpen: boolean
  onOpen: (itemUid: string) => void
  filter: Filter
  singularEntityName: string
  pluralEntityName: string
  iconComponent: React.ElementType
}

export const BasePopoverSelectFilter: React.FC<
  BasePopoverSelectFilterProps
> = ({
  isOpen,
  onClose,
  item,
  onChangeValue,
  onOpen,
  onRemove,
  filter,
  singularEntityName,
  pluralEntityName,
  iconComponent,
}) => {
  const { data, isLoading } = useFilterValues(filter)
  const options = useMemo(
    () =>
      data?.values.map<FilterOption>((value) => ({
        label: value,
        value: value,
      })) ?? [],
    [data?.values],
  )

  const values = useMemo(
    () =>
      item.values
        ? item.values.map((value) => ({
            label: value,
            value: value,
          }))
        : undefined,
    [item.values],
  )

  const handleChange = useCallback(
    (option: OnChangeValue<FilterOption, true>): void => {
      onChangeValue({
        uid: item.uid,
        inverted: item.inverted,
        values: option.map((x) => x.value),
      })
    },
    [item.inverted, item.uid, onChangeValue],
  )

  return (
    <Popover
      placement="bottom-start"
      isOpen={isOpen}
      onClose={onClose}
      isLazy
      lazyBehavior="unmount"
    >
      {({ isOpen, onClose }) => (
        <>
          <Box display="inline-block">
            <PopoverTrigger>
              <FilterCriteriaButton
                size="sm"
                maxWidth={'20rem'}
                onReset={() => onRemove(item.uid)}
                onClick={() => onOpen(item.uid)}
                isActive={isOpen}
                hasValue={!!values?.length}
                leftIcon={<Icon boxSize="20px !important" as={iconComponent} />}
              >
                <Highlight mr="1">{singularEntityName}</Highlight>
                {item.inverted ? (
                  <Trans>
                    is <Highlight>NOT</Highlight>
                  </Trans>
                ) : (
                  <Trans>is</Trans>
                )}
                {` ${values?.map((v) => v.label).join(', ')}`}
              </FilterCriteriaButton>
            </PopoverTrigger>
          </Box>

          <PopoverContent w={400}>
            <FilterCriteriaSelect
              onClose={onClose}
              options={options}
              value={values}
              onChange={handleChange}
              autoFocus={true}
              isMulti={true}
              isLoading={isLoading}
              showFooter={false}
            />
            <InvertibleMultiSelectFooter
              entityName={pluralEntityName}
              isNot={item.inverted}
              onChangeIsNot={(isNot) => {
                onChangeValue({
                  ...item,
                  inverted: isNot,
                })
              }}
              onReset={() => handleChange([])}
              onSubmit={onClose}
              onSelectAllOptions={() => handleChange(options)}
              selectAllText={t`Select all`}
              selectedCount={values?.length || 0}
              formatSelectedCount={(count: string | number) => (
                <Plural value={count} one={'1 selected'} other={'# selected'} />
              )}
            />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
