import { PreviewTrackerHitsPhrase } from '@capturi/api-trackers'

import { CacheMonitor } from './cache-monitor'

type CacheItemWithData = {
  hash: string
  phrase: string
  conversations: PreviewTrackerHitsPhrase['conversations']
  conversationsTotal: number
  isLoading: false
}

type CacheItemLoadingPlaceholder = {
  hash: string
  phrase: string
  conversations?: never
  conversationsTotal?: never
  isLoading: true
}

export type CacheItem = CacheItemWithData | CacheItemLoadingPlaceholder

export const phraseHitsCache = new Map<string, CacheItem>()
export const phraseHitsCacheMonitor = new CacheMonitor(phraseHitsCache)
