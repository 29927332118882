import { Card, CardBody, CardHeader, StyleProps } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

export const GraphBenchmarkCard: React.FC<
  PropsWithChildren & { title: string; bodyBottomPadding?: number } & StyleProps
> = ({ title, children, bodyBottomPadding = 4, ...restProps }) => {
  return (
    <Card
      maxH="124px"
      shadow="none"
      border="1px solid"
      borderColor="gray.200"
      {...restProps}
    >
      <CardHeader
        pl={4}
        pr={4}
        pb={0}
        pt={3}
        fontSize="sm"
        fontWeight="medium"
        color="gray.600"
      >
        {title}
      </CardHeader>
      <CardBody
        pl={4}
        pr={4}
        pb={bodyBottomPadding}
        pt={2}
        h="100%"
        overflowY="auto"
      >
        {children}
      </CardBody>
    </Card>
  )
}
