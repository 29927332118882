import { useAllScores } from '@capturi/api-scoring'
import { useAllTrackers, useTrackerByUid } from '@capturi/api-trackers'
import { Spinner } from '@capturi/ui-components'
import {
  Box,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import {
  ReadOnlyContext,
  SegmentationFieldContext,
} from './PhoneSegmentBuilder'
import { FilterCriteriaButton } from './components/FilterCriteriaButton'
import { FilterDefinitions, FocusableElement } from './types'
import { FilterCriteria } from './useFilterCriterias'

type FilterCriteriaComponent = {
  filter: FilterCriteria
  element: React.ReactElement
  isOpen: boolean
}

type FilterCriteriaWrapperProps<T> = {
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  value: any
  wrapper:
    | (({
        value,
        children,
      }: {
        value: T
        children?: React.ReactNode
      }) => React.ReactElement)
    | undefined
  children?: React.ReactNode
}

type ScoreFilterType = {
  uid: string
  min?: number | null
  max?: number | null
}

function FilterCriteriaWrapper<T>({
  value,
  wrapper,
  children,
}: FilterCriteriaWrapperProps<T>): React.ReactElement {
  return wrapper ? wrapper({ value, children }) : <>{children}</>
}

export function useFilterComponents<TValues>(
  state: Partial<TValues>,
  filters: FilterCriteria[],
  focusedFilter: FilterCriteria | undefined,
  setFilterFocus: (filter?: FilterCriteria) => void,
  onRemoveFilter: (filter: FilterCriteria) => void,
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  onFilterValueChange: (filter: FilterCriteria, newValue: any) => void,
  onFilterValueReset: (filter: FilterCriteria) => void,
  filterDefinitions: FilterDefinitions,
): FilterCriteriaComponent[] {
  const focusedFilterId = focusedFilter?.id
  const isReadOnly = React.useContext(ReadOnlyContext)
  const maxWidthSegmentField = React.useContext(SegmentationFieldContext)
  const { data: trackers } = useAllTrackers()
  const trackersByUid = useTrackerByUid(trackers)

  const { data: scores } = useAllScores()

  return React.useMemo(() => {
    return filters.reduce<FilterCriteriaComponent[]>((memo, filter) => {
      const filterDefinition = filterDefinitions.get(filter.stateKey)
      if (
        filterDefinition === undefined ||
        filterDefinition.isAvailable?.() === false
      ) {
        // Filter is not available due to user role constraints or feature flags
        return memo
      }

      const filterValue = (() => {
        const stateValue = state[filter.stateKey as keyof TValues]
        if (filterDefinition.allowMultiple && Array.isArray(stateValue)) {
          return stateValue[filter.index]
        }
        return stateValue
      })()

      const hasInaccessibleTracker = (filterValue?.uids ?? []).some(
        (uid: string) => {
          const tracker = trackersByUid?.[uid]
          return tracker && tracker.accessLevel === 'None'
        },
      )

      const hasInaccessibleScore =
        Array.isArray(filterValue) &&
        (filterValue ?? []).some((item: ScoreFilterType) => {
          const score = scores?.find((x) => x.uid === item.uid)
          return score && score.accessLevel === 'None'
        })

      if (filterDefinition.inactive) {
        const filterNotSupportedOnPageMessage = t`This filter criteria is not supported on this page and has been disabled.`
        memo.push({
          isOpen: false,
          filter: filter,
          element: (
            <Tooltip
              label={filterNotSupportedOnPageMessage}
              aria-label={filterNotSupportedOnPageMessage}
            >
              <FilterCriteriaButton
                size="sm"
                hasValue={!isEmpty(filterValue)}
                leftIcon={<Icon boxSize={5} as={filterDefinition.icon} />}
                onReset={() => onRemoveFilter(filter)}
                isInApplicable
                maxWidth={maxWidthSegmentField ?? '20rem'}
              >
                {filterDefinition.renderText(filterValue)}
              </FilterCriteriaButton>
            </Tooltip>
          ),
        })
        return memo
      }

      const initialFocusRef = React.createRef<FocusableElement>()
      const borderProps =
        hasInaccessibleTracker || hasInaccessibleScore
          ? {
              border: '2px solid',
              borderColor: 'warningLight',
            }
          : {}

      const isOpen = !isReadOnly && focusedFilterId === filter.id
      memo.push({
        isOpen: isOpen,
        filter: filter,
        element: (
          <Popover
            placement="bottom-start"
            isOpen={isOpen}
            onClose={() => setFilterFocus(undefined)}
            initialFocusRef={initialFocusRef}
            isLazy
          >
            {({ isOpen, onClose }) => (
              <>
                <FilterCriteriaWrapper
                  value={filterValue}
                  wrapper={filterDefinition.filterCriteriaButtonWrapper}
                >
                  <Box display="inline-block">
                    <PopoverTrigger>
                      <FilterCriteriaButton
                        size="sm"
                        hasValue={
                          filterDefinition.hasValue?.(filterValue) ??
                          !isEmpty(filterValue)
                        }
                        leftIcon={
                          <Icon
                            boxSize="20px !important"
                            as={filterDefinition.icon}
                          />
                        }
                        onReset={() => onRemoveFilter(filter)}
                        onClick={() => {
                          if (isReadOnly) return
                          setFilterFocus(filter)
                        }}
                        isActive={isOpen}
                        maxWidth={maxWidthSegmentField ?? '20rem'}
                        {...borderProps}
                      >
                        {filterDefinition.renderText(filterValue)}
                      </FilterCriteriaButton>
                    </PopoverTrigger>
                  </Box>
                </FilterCriteriaWrapper>
                <PopoverContent w={filterDefinition.popoverWidth ?? 400}>
                  <React.Suspense
                    fallback={<Spinner display="block" m="1rem auto" />}
                  >
                    <filterDefinition.Component
                      onClose={onClose}
                      initialFocusRef={initialFocusRef}
                      value={filterValue}
                      // biome-ignore lint/suspicious/noExplicitAny: legacy
                      setValue={(newValue: any) =>
                        onFilterValueChange(filter, newValue)
                      }
                      bg="tomato"
                      resetValue={() => {
                        onFilterValueReset(filter)
                      }}
                      options={filterDefinition.componentOptions}
                    />
                  </React.Suspense>
                </PopoverContent>
              </>
            )}
          </Popover>
        ),
      })
      return memo
    }, [])
  }, [
    filters,
    filterDefinitions,
    isReadOnly,
    focusedFilterId,
    state,
    trackersByUid,
    scores,
    maxWidthSegmentField,
    onRemoveFilter,
    setFilterFocus,
    onFilterValueChange,
    onFilterValueReset,
  ])
}
