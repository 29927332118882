import analytics, { EventParams } from '@capturi/analytics'

export enum LibraryAnalyticsEvent {
  // Snippet CRUD
  CreateSnippet = 'library_createSnippet',
  UpdateSnippet = 'library_updateSnippet',
  DeleteSnippet = 'library_deleteSnippet',
  DeleteAllSnippets = 'library_delete_all_snippets',
  // Create dialog
  CreateSnippetDialog_PlayAudio = 'library_createSnippet_pressedPlay',
  CreateSnippetDialog_StopAudio = 'library_createSnippet_pressedStop',
  // Snippet library page
  PlaySnippetFromLibrary = 'library_playSnippetFromLibrary',
  ChangeFocusPlaylistLibrary = 'library_playlist_focus_changed',
  // Snippet menu
  CopySnippetLink = 'library_snippet_copyLink',
  GoToSnippetConversation = 'library_snippet_gotoConversation',
  DownloadSnippet = 'library_snippet_download',
  // Playlist CRUD
  CreatePlaylist = 'library_createPlaylist',
  CreatePlaylistFromSnippetDialog = 'library_CreatePlaylistFromSnippetDialog',
  UpdatePlaylist = 'library_updatePlaylist',
  DeletePlaylist = 'library_deletePlaylist',
}

export function logEvent(
  event: LibraryAnalyticsEvent,
  data?: EventParams,
): void {
  analytics.event(event, data)
}
