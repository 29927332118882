import analytics from '@capturi/analytics'
import {
  getNextPageParam,
  selectConversations,
} from '@capturi/api-conversations'
import {
  AudioProvider,
  useAudioContext,
  useAudioShortcut,
} from '@capturi/audio'
import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import request from '@capturi/request'
import { useUsers } from '@capturi/stores'
import {
  List,
  ListItemSecondaryAction,
  ListSkeleton,
} from '@capturi/ui-components'
import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { useConversationDetailsDrawer } from 'components/ConversationDetailsDrawer'
import OptionalAudioPlayerActions from 'components/OptionalAudioPlayerActions'
import { PlayListItem } from 'components/PlayList'
import { ConversationPageView } from 'pages/Conversations/routes'
import React from 'react'
import { MdLaunch } from 'react-icons/md'
import translateAdversusStatus from 'utils/translateAdversusStatus'

import { useInfiniteQuery } from '@tanstack/react-query'
import IsReviewedCheckmark from '../../pages/Conversations/ConversationsPage/isReviewedCheckmark.svg'

type DrawerOptions = {
  url: string
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  getFilterRequestModel: () => { [key: string]: any }
  initialTab?: ConversationPageView
}

export type DrawerContainerProps = DrawerOptions & Omit<DrawerProps, 'children'>

const dateFormat: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
}

export const DrawerContainer: React.FC<DrawerContainerProps> = ({
  url,
  getFilterRequestModel,
  initialTab,
  isOpen,
  onClose,
}) => {
  return (
    <AudioProvider>
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottom="1px"
            borderBottomColor="gray.200"
            lineHeight={1.4}
            display="flex"
            alignItems="center"
            pr={10}
          >
            <Trans>Conversations</Trans>
          </DrawerHeader>
          <DrawerBody overflow="hidden" p={0}>
            <DrawerBodyContent
              url={url}
              getFilterRequestModel={getFilterRequestModel}
              initialTab={initialTab}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </AudioProvider>
  )
}

const DrawerBodyContent: React.FC<DrawerOptions> = ({
  url,
  getFilterRequestModel,
  initialTab,
}) => {
  const { showStatus } = useFeatureFlags()
  const { getTime, playbackContext, pause } = useAudioContext()
  const { permissions } = useCurrentUser()
  const { getUserByUid } = useUsers()
  useAudioShortcut()

  const openConversationDetailsDrawer = useConversationDetailsDrawer()

  const onGoToConversation = (uid: string): void => {
    analytics.event('analytics_Conversations_GoToConversation')
    React.startTransition(() => {
      let initialAudioTimestamp: number | undefined
      if (playbackContext?.conversationUid === uid) {
        initialAudioTimestamp = getTime()
        pause()
      }
      openConversationDetailsDrawer({
        uid,
        initialAudioTimestamp,
        initialTab,
      })
    })
  }
  const filter = getFilterRequestModel()

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['conversations', url, filter],
      queryFn: async ({ pageParam }) =>
        await request.post(url, {
          json: pageParam
            ? { continuationToken: pageParam }
            : {
                ...filter,
                limit: 50,
                sortDirection: 1,
              },
        }),
      getNextPageParam: getNextPageParam,
      initialPageParam: null,
      select: selectConversations,
    })

  const handleLoadMore = (): void => {
    fetchNextPage()
  }

  return (
    <Box h="full" overflow="auto">
      <List>
        <ListSkeleton isLoaded={!isLoading} rowCount={5}>
          {(data?.pages ?? []).map((conversations, i) => (
            <React.Fragment key={i}>
              {conversations.map((c) => {
                const {
                  uid,
                  dateTime,
                  userUid,
                  customer,
                  duration,
                  subject,
                  status,
                  deleted,
                  qaIsReviewed,
                  hasAudio,
                } = c
                return (
                  <PlayListItem
                    key={uid}
                    context={{ conversationUid: uid }}
                    timestamp={0}
                    hasAudio={hasAudio}
                    showAudioPlayer
                    onPlay={() =>
                      analytics.event('analytics_Conversations_PlayAudio', {
                        uid,
                        date: dateTime.toUTCString(),
                      })
                    }
                    additionalActions={(props) => (
                      <OptionalAudioPlayerActions
                        {...props}
                        duration={duration}
                        conversationUid={uid}
                      />
                    )}
                    isDisabled={!permissions.playback || deleted || !hasAudio}
                  >
                    <Box w="full" minW={0}>
                      <Flex align="center">
                        <Text fontSize="md" fontWeight="medium">
                          {customer}
                        </Text>
                        <Divider orientation="vertical" mx={2} minH={3} />
                        <HStack gap={0}>
                          <Text
                            noOfLines={1}
                            wordBreak="break-all"
                            title={subject}
                            flex={1}
                          >
                            {subject}
                          </Text>
                          {permissions.qualityAssurance && qaIsReviewed && (
                            <Icon boxSize="5" as={IsReviewedCheckmark} />
                          )}
                        </HStack>
                        {deleted && (
                          <Badge colorScheme="red">
                            <Trans>Deleted</Trans>
                          </Badge>
                        )}
                      </Flex>
                      <Flex fontSize="sm" color="gray.600" align="center">
                        <Text as="span" flexShrink={0}>
                          {i18n.date(dateTime, dateFormat)}
                        </Text>
                        <Divider orientation="vertical" mx={2} minH={3} />
                        <Text>{getUserByUid(userUid).name}</Text>
                        {showStatus && (
                          <Text
                            noOfLines={1}
                            wordBreak="break-all"
                            flex="1 0 auto"
                            textAlign="right"
                            pl={2}
                          >
                            {translateAdversusStatus(status)}
                          </Text>
                        )}
                      </Flex>
                    </Box>
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label={t`Go to conversation`}
                        title={t`Go to conversation`}
                        variant="ghost"
                        size="sm"
                        icon={<MdLaunch />}
                        onClick={() => onGoToConversation(uid)}
                      />
                    </ListItemSecondaryAction>
                  </PlayListItem>
                )
              })}
            </React.Fragment>
          ))}
        </ListSkeleton>
      </List>
      {!isLoading && (
        <Flex my={4} justify="center" w="100%">
          <Button
            onClick={handleLoadMore}
            isDisabled={!hasNextPage || isFetchingNextPage}
            isLoading={isFetchingNextPage}
          >
            {!hasNextPage ? (
              <Trans>No more conversations</Trans>
            ) : (
              <Trans>Load more</Trans>
            )}
          </Button>
        </Flex>
      )}
    </Box>
  )
}
