import { Range } from '@capturi/api-filters'
import { t } from '@lingui/macro'
import React from 'react'

import { FilterValueLabel } from '../../components/FilterValueLabel'
import { formatDuration } from './utils'

export const DurationValueLabel: React.FC<{ value?: Range }> = ({ value }) => {
  const strValue = value ? formatDuration(value) : null
  return <FilterValueLabel name={t`Duration`} value={strValue} />
}
