import React from 'react'
import { Route, Routes } from 'react-router-dom'

const MetabaseReportList = React.lazy(() => import('./MetabaseReportList'))
const MetabaseReportIframe = React.lazy(() => import('./MetabaseReportIframe'))

export { default as routes } from './routes'
export const MetabaseReports: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<MetabaseReportList />} />
      <Route path="/:reportUid" element={<MetabaseReportIframe />} />
    </Routes>
  )
}
