import { Box, Icon, Menu, MenuButton, Portal } from '@chakra-ui/react'
import { MdMoreVert } from 'react-icons/md'
import { CellProps, ColumnWithLooseAccessor } from 'react-table'

import { MultiValueCell } from '../components/Cell'
import { ColumnDefinition, DataType, MenuColumn } from '../types'

export default function menuColumn<T extends DataType>(
  c: ColumnDefinition<T>,
  // biome-ignore lint/suspicious/noExplicitAny: legacy
): ColumnWithLooseAccessor<any> {
  const { render, id = 'menu', width = 32 } = c as MenuColumn<T>
  return {
    id,
    accessor: (d: T) => d.segments,
    width,
    Cell: function MenuCell({ row, column }: CellProps<T, string>) {
      const segments = row.values.menu as T['segments']
      return (
        <MultiValueCell values={segments} alignRight={column.alignRight}>
          {(s, i) => (
            <Menu key={s.label}>
              <MenuButton
                color="gray.600"
                display="block"
                onClick={(e) => e.stopPropagation()}
              >
                <Icon as={MdMoreVert} boxSize={5} />
              </MenuButton>
              <Portal>
                {/* Prevent click event from menu item click to propagate to the row causing the row to be selected */}
                <Box onClick={(e) => e.stopPropagation()}>
                  {render(row.original, i)}
                </Box>
              </Portal>
            </Menu>
          )}
        </MultiValueCell>
      )
    },
    alignRight: true,
  }
}
