import { Button, Spinner } from '@capturi/ui-components'
import {
  Box,
  Divider,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { CSSProperties, ChangeEvent, FC, memo, useState } from 'react'
import { MdClose } from 'react-icons/md'

import { FilterCriteriaComponentBaseProps } from '../../components/PhoneFilter/components/PhoneSegmentBuilder'
import { useKeyTopicsFilterValues } from './useKeyTopicsFilterValues'

// For performance reasons these have been extracted to inline declarations
const itemStyle: CSSProperties = {
  display: 'inline-flex',
  alignSelf: 'center',
  padding: '0.25rem 0.75rem',
  borderRadius: '9999px',
  borderWidth: '1px',
  borderStyle: 'solid',
  cursor: 'pointer',
  lineHeight: '1.2',
  fontSize: 'var(--chakra-fontSizes-xs)',
  fontWeight: 'normal',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  height: '1.25rem',
  boxShadow: 'var(--chakra-shadows-sm)',
}

const selectedItemStyle: CSSProperties = {
  ...itemStyle,
  backgroundColor: 'var(--chakra-colors-gray-200)',
  borderColor: 'var(--chakra-colors-primary-500)',
}

const unselectedItemStyle: CSSProperties = {
  ...itemStyle,
  backgroundColor: 'var(--chakra-colors-gray-50)',
  borderColor: 'var(--chakra-colors-gray-200)',
}

const KeyTopicsWrapItem: FC<{
  topic: string
  isSelected: (keyTopic: string) => boolean
  toggleSelection: (keyTopic: string) => void
}> = memo(({ topic, isSelected, toggleSelection }) => {
  return (
    // Using <li> directly for performance reasons
    <li
      style={isSelected(topic) ? selectedItemStyle : unselectedItemStyle}
      onClick={() => toggleSelection(topic)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          toggleSelection(topic)
        }
      }}
    >
      {topic}
    </li>
  )
})

const wrapStyle: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.5rem',
  padding: '0.25rem 0',
  marginBottom: '0.25rem',
  width: '100%',
  listStyleType: 'none',
}

const KeyTopicsWrap: FC<{
  topics: string[]
  isSelected: (keyTopic: string) => boolean
  toggleSelection: (keyTopic: string) => void
}> = memo(({ topics, isSelected, toggleSelection }) => {
  return (
    <ul style={wrapStyle}>
      {topics.map((item: string) => (
        // Using <ul> directly for performance reasons
        <KeyTopicsWrapItem
          key={item}
          topic={item}
          isSelected={isSelected}
          toggleSelection={toggleSelection}
        />
      ))}
    </ul>
  )
})

export type KeyTopicSelectProps = FilterCriteriaComponentBaseProps<string[]>

export const KeyTopicSelect: FC<KeyTopicSelectProps> = memo((props) => {
  const [searchQuery, setSearchQuery] = useState('')
  const {
    isLoading,
    isSelected,
    filteredKeyTopics,
    selectedKeyTopics,
    selectedSize,
    toggleSelection,
    resetSelection,
    selectAll,
  } = useKeyTopicsFilterValues(props.value ?? null, searchQuery)

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleOk = () => {
    props.setValue?.(selectedKeyTopics)
    props.onClose?.()
  }

  return (
    <VStack gap={1}>
      <Box w="100%">
        <Box p={2} w="100%">
          <InputGroup size="sm">
            <Input
              autoFocus={true}
              placeholder={t`Search key topics...`}
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {searchQuery && searchQuery.length > 0 && (
              <InputRightElement cursor="pointer">
                <Icon
                  fontSize="14px"
                  as={MdClose}
                  onClick={() => setSearchQuery('')}
                />
              </InputRightElement>
            )}
          </InputGroup>
        </Box>
      </Box>
      <Box maxH={96} overflowY="auto" px={2} w="100%">
        {searchQuery === '' ? (
          <>
            <Text
              align="center"
              fontSize="sm"
              minH={5}
              mb={filteredKeyTopics.length > 0 ? 1 : 2}
            >
              <Trans>👆Start typing to find key topics...</Trans>
            </Text>
            {filteredKeyTopics.length > 0 && (
              <VStack align="start" gap={0}>
                <Divider borderColor="gray.300" mb={1} />
                <Text fontSize="xs" fontWeight="medium">
                  <Trans>Selected</Trans>
                </Text>
                <KeyTopicsWrap
                  topics={filteredKeyTopics}
                  isSelected={isSelected}
                  toggleSelection={toggleSelection}
                />
              </VStack>
            )}
          </>
        ) : isLoading ? (
          <Spinner delay={100} />
        ) : filteredKeyTopics.length > 0 ? (
          <VStack gap={2}>
            <Divider borderColor="gray.300" />
            <KeyTopicsWrap
              topics={filteredKeyTopics}
              isSelected={isSelected}
              toggleSelection={toggleSelection}
            />
          </VStack>
        ) : (
          <Text align="center" fontSize="sm" minH={5} mb={2}>
            <Trans>No results found</Trans>
          </Text>
        )}
      </Box>
      <Box boxShadow="0 -4px 8px -6px rgba(0,0,0,0.2)" p={2} w="100%">
        <HStack justifyContent="space-between" spacing={4}>
          <HStack spacing={2}>
            <Button
              isDisabled={
                selectAll === undefined || filteredKeyTopics.length === 0
              }
              onClick={selectAll}
              size="xs"
              variant="ghost"
            >
              <Trans>Select all</Trans>
            </Button>
            <Button
              size="xs"
              fontWeight="normal"
              variant="ghost"
              onClick={() => setSearchQuery('')}
              py={0}
            >
              <Trans>{selectedSize} selected</Trans>
            </Button>
          </HStack>
          <HStack spacing={1}>
            <Button onClick={resetSelection} size="xs" variant="ghost">
              <Trans>Reset</Trans>
            </Button>
            <Button onClick={handleOk} size="xs" primary variant="ghost">
              <Trans>OK</Trans>
            </Button>
          </HStack>
        </HStack>
      </Box>
    </VStack>
  )
})
