import { ErrorBoundary } from '@capturi/react-utils'
import Loader from 'pages/analytics/shared/components/Loader'
import { TimeResolutionProvider } from 'pages/analytics/shared/contexts/ResolutionContext'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import HitRatePage from './hitrate'

const Analysis: React.FC = () => {
  return (
    <TimeResolutionProvider>
      <ErrorBoundary>
        <React.Suspense fallback={Loader}>
          <Routes>
            <Route path={'/:uid/*'} element={<HitRatePage />} />
          </Routes>
        </React.Suspense>
      </ErrorBoundary>
    </TimeResolutionProvider>
  )
}

export default Analysis
