import React from 'react'

import ShadowFilter from './ShadowFilter'

const TriangleNoOutline: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
    >
      <g filter="url(#shadow)">
        <path
          d="M6.86603 8.5C6.48113 9.16667 5.51888 9.16667 5.13397 8.5L1.66987 2.5C1.28497 1.83333 1.7661 1 2.5359 1L9.4641 0.999999C10.2339 0.999999 10.715 1.83333 10.3301 2.5L6.86603 8.5Z"
          fill={color}
        />
      </g>
      <ShadowFilter />
    </svg>
  )
}

const TriangleWithOutline: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#shadow)">
        <path
          d="M8.86603 11.5C8.48113 12.1667 7.51888 12.1667 7.13397 11.5L1.93782 2.5C1.55292 1.83333 2.03405 0.999999 2.80385 0.999999L13.1962 0.999998C13.966 0.999998 14.4471 1.83333 14.0622 2.5L8.86603 11.5Z"
          fill={color}
        />
        <path
          d="M8.86603 11.5C8.48113 12.1667 7.51888 12.1667 7.13397 11.5L1.93782 2.5C1.55292 1.83333 2.03405 0.999999 2.80385 0.999999L13.1962 0.999998C13.966 0.999998 14.4471 1.83333 14.0622 2.5L8.86603 11.5Z"
          stroke="white"
        />
      </g>
      <ShadowFilter />
    </svg>
  )
}

const Triangle: React.FC<{ color: string; outline: boolean }> = ({
  color,
  outline,
}) => {
  return outline ? (
    <TriangleWithOutline color={color} />
  ) : (
    <TriangleNoOutline color={color} />
  )
}

export default React.memo(Triangle)
