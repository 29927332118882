import { MessageFilters } from '@capturi/api-trackers'
import { Box, Center, Divider, Flex } from '@chakra-ui/react'
import React from 'react'

import { CaseActiveInField } from './CaseActiveInField'
import { CaseMessageTypeField } from './CaseMessageTypeField'

type Props = {
  messageFilters: MessageFilters | null
  onMessageFiltersChange: (messageTypes: MessageFilters | null) => void
}

const CaseMessageFiltersFields: React.FC<Props> = ({
  messageFilters,
  onMessageFiltersChange,
}) => {
  return (
    <Flex w="100%" mt={2} mb={4}>
      <Box>
        <CaseMessageTypeField
          messageFilters={messageFilters}
          onMessageFiltersChange={onMessageFiltersChange}
        />
      </Box>
      <Center mx={8}>
        <Divider h="100%" orientation="vertical" />
      </Center>
      <Box>
        <CaseActiveInField
          messageFilters={messageFilters}
          onMessageFiltersChange={onMessageFiltersChange}
        />
      </Box>
    </Flex>
  )
}

export default CaseMessageFiltersFields
