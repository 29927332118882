import React from 'react'

import ShadowFilter from './ShadowFilter'

const HexagonWithOutline: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#shadow)">
        <path
          d="M7.5 1.28868C7.8094 1.11004 8.1906 1.11004 8.5 1.28868L13.5622 4.21132C13.8716 4.38996 14.0622 4.72008 14.0622 5.07735V10.9227C14.0622 11.2799 13.8716 11.61 13.5622 11.7887L8.5 14.7113C8.1906 14.89 7.8094 14.89 7.5 14.7113L2.43782 11.7887C2.12842 11.61 1.93782 11.2799 1.93782 10.9226V5.07735C1.93782 4.72008 2.12842 4.38996 2.43782 4.21132L7.5 1.28868Z"
          fill={color}
        />
        <path
          d="M7.5 1.28868C7.8094 1.11004 8.1906 1.11004 8.5 1.28868L13.5622 4.21132C13.8716 4.38996 14.0622 4.72008 14.0622 5.07735V10.9227C14.0622 11.2799 13.8716 11.61 13.5622 11.7887L8.5 14.7113C8.1906 14.89 7.8094 14.89 7.5 14.7113L2.43782 11.7887C2.12842 11.61 1.93782 11.2799 1.93782 10.9226V5.07735C1.93782 4.72008 2.12842 4.38996 2.43782 4.21132L7.5 1.28868Z"
          stroke="white"
        />
      </g>
      <ShadowFilter />
    </svg>
  )
}
const HexagonNoOutline: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#shadow)">
        <path
          d="M5.5 0.288675C5.8094 0.110042 6.1906 0.110042 6.5 0.288675L9.83013 2.21132C10.1395 2.38996 10.3301 2.72008 10.3301 3.07735V6.92265C10.3301 7.27992 10.1395 7.61004 9.83013 7.78868L6.5 9.71132C6.1906 9.88996 5.8094 9.88996 5.5 9.71132L2.16987 7.78868C1.86047 7.61004 1.66987 7.27992 1.66987 6.92265V3.07735C1.66987 2.72008 1.86047 2.38996 2.16987 2.21132L5.5 0.288675Z"
          fill={color}
        />
      </g>
      <ShadowFilter />
    </svg>
  )
}

const Hexagon: React.FC<{ color: string; outline: boolean }> = ({
  color,
  outline,
}) => {
  return outline ? (
    <HexagonWithOutline color={color} />
  ) : (
    <HexagonNoOutline color={color} />
  )
}

export default React.memo(Hexagon)
