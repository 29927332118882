import analytics from '@capturi/analytics'
import {
  InaccessibleSavedTextFilter,
  SavedTextFilter,
  useSavedTextFilters,
} from '@capturi/api-filters'
import { ErrorBoundary } from '@capturi/react-utils'
import { Button, ButtonProps, Spinner } from '@capturi/ui-components'
import {
  Box,
  BoxProps,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { IoMdPie } from 'react-icons/io'

import { RemoveFilterButton } from '../../PhoneFilter/components/PhoneSegmentBuilder/components/RemoveFilterButton'
import SavedTextFilterPopoutSelect from './SavedTextFilterPopoutSelect'

export type SavedTextFilterButtonProps = {
  savedTextFilter: SavedTextFilter | InaccessibleSavedTextFilter | undefined
  onChangeSavedFilter: (
    savedFilter: SavedTextFilter | InaccessibleSavedTextFilter,
  ) => void
  onResetSavedFilter?: () => void
  initiallyOpened?: boolean
  isReadOnly?: boolean
  allowReselectionWhenReadOnly?: boolean
  placeholderText?: string
  segmentTooltipLabel?: string
  notValidSegmentReason?: string
  buttonProps?: Omit<ButtonProps, 'children' | 'onReset'>
} & Omit<BoxProps, 'children'>

export const SavedTextFilterButton = React.forwardRef<
  HTMLDivElement,
  SavedTextFilterButtonProps
>(function SavedTextFilterButton(
  {
    savedTextFilter,
    onChangeSavedFilter,
    onResetSavedFilter,
    initiallyOpened = false,
    isReadOnly,
    allowReselectionWhenReadOnly = false,
    placeholderText = null,
    segmentTooltipLabel,
    notValidSegmentReason,
    buttonProps,
    ...props
  },
  ref,
) {
  const [isOpen, setIsOpen] = React.useState(initiallyOpened)
  const open = (): void => {
    if (isReadOnly && !allowReselectionWhenReadOnly) return
    setIsOpen(!isOpen)
  }
  const close = (): void => setIsOpen(false)

  if (isReadOnly && !allowReselectionWhenReadOnly && !savedTextFilter) {
    return null
  }

  const borderProps = notValidSegmentReason
    ? { border: '2px solid', borderColor: 'warning' }
    : {}

  return (
    <Flex {...props} gap={1} ref={ref}>
      <Popover isOpen={isOpen} onClose={close} placement="bottom-start" isLazy>
        <Tooltip
          hasArrow
          aria-label={segmentTooltipLabel}
          label={segmentTooltipLabel}
          isDisabled={isReadOnly && !allowReselectionWhenReadOnly}
          placement="top"
        >
          {/* additional element as tooltip ref is not passed on */}
          <Box display="inline-block">
            <PopoverTrigger>
              <OpenSavedTextFilterButton
                text={savedTextFilter ? savedTextFilter.name : placeholderText}
                onReset={onResetSavedFilter}
                showReset={!!savedTextFilter && !isReadOnly}
                onClick={open}
                isReadOnly={isReadOnly && !allowReselectionWhenReadOnly}
                {...borderProps}
                {...buttonProps}
              />
            </PopoverTrigger>
          </Box>
        </Tooltip>
        <PopoverContent>
          <ErrorBoundary>
            <React.Suspense fallback={<Box h={8} />}>
              <SavedTextFilterPopoverContent
                value={savedTextFilter}
                onChange={(filter) => {
                  if (!isReadOnly || allowReselectionWhenReadOnly) {
                    onChangeSavedFilter(filter)
                    analytics.event('segment_trackers_noView')
                  }
                  close()
                }}
              />
            </React.Suspense>
          </ErrorBoundary>
        </PopoverContent>
      </Popover>
    </Flex>
  )
})

type OpenSavedTextFilterButtonProps = {
  text: string | null
  showReset: boolean
  onReset?: () => void
  isReadOnly?: boolean
} & Omit<ButtonProps, 'children'>
const OpenSavedTextFilterButton: React.FC<OpenSavedTextFilterButtonProps> =
  React.forwardRef<HTMLButtonElement, OpenSavedTextFilterButtonProps>(
    function OpenSavedTextFilterButton(
      { text, showReset, onReset, isReadOnly, ...props },
      ref,
    ) {
      const styleProps: ButtonProps = isReadOnly
        ? {
            _hover: {},
            _active: {},
            _focus: {},
            cursor: 'auto',
            variant: 'solid',
          }
        : {}
      return (
        <Button
          ref={ref}
          size="sm"
          background="gray.200"
          borderBottom="2px solid"
          borderBottomColor="gray.300"
          borderRadius="md"
          maxW={56}
          justifyContent="flex-start"
          pl={2}
          pr={2}
          {...styleProps}
          {...props}
        >
          <Box
            flex="0 0 auto"
            aria-hidden
            color="currentColor"
            as={IoMdPie}
            boxSize="16px !important"
          />
          {text && (
            <Text
              as="span"
              fontWeight="medium"
              noOfLines={1}
              wordBreak="break-all"
              ml={2}
            >
              {text}
            </Text>
          )}
          {showReset && (
            <RemoveFilterButton
              label={t`Deselect saved segment`}
              onClick={onReset}
              ml={1}
            />
          )}
        </Button>
      )
    },
  )

type SavedTextFilterPopoverContentProps = {
  value: SavedTextFilter | InaccessibleSavedTextFilter | undefined
  onChange: (savedFilter: SavedTextFilter | InaccessibleSavedTextFilter) => void
}

export const SavedTextFilterPopoverContent: React.FC<
  SavedTextFilterPopoverContentProps
> = ({ value, onChange }) => {
  const { data: savedFilters, isLoading } = useSavedTextFilters()
  return (
    <SavedTextFilterPopoutSelect
      options={savedFilters || []}
      value={value}
      onChange={onChange}
      isLoading={isLoading}
      loadingMessage={() => (
        <VStack>
          <Text>
            <Trans>Loading segments ...</Trans>
          </Text>
          <Spinner delay={0} />
        </VStack>
      )}
    />
  )
}
