import { TextProps } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

import ChartMessage from './ChartMessage'

type Props = {
  show: boolean
} & TextProps

const NoDataText: React.FC<Props> = ({ show, ...props }) => {
  if (!show) {
    return null
  }
  return (
    <ChartMessage {...props}>
      <Trans>No data available</Trans>
    </ChartMessage>
  )
}

export default NoDataText
