import { Store } from '@tanstack/react-store'
import type React from 'react'

export const modalStore = new Store<
  {
    // biome-ignore lint/suspicious/noExplicitAny: to make the store typings work :'(
    Component: React.FC<any>
    // biome-ignore lint/suspicious/noExplicitAny: to make the store typings work :'(
    props: any
    uid: string
  }[]
>([])
