import { PermissionPreset } from '@capturi/core'
import {
  Flex,
  FormControl,
  FormLabel,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import { ShareAndEditOptionComponent } from './ShareAndEditOptionsComponent'
import { Option, getSharingOptions } from './getSharingOptions'

export const ShareAndEditOptions: React.FC<{
  title?: string
  permissionPreset: PermissionPreset
  folderPermissionPreset?: PermissionPreset
  onChange: (permissionPreset: PermissionPreset) => void
  localizedEntityName: string
}> = ({
  title,
  permissionPreset,
  folderPermissionPreset,
  onChange,
  localizedEntityName,
}) => {
  const sharingOptions = getSharingOptions(localizedEntityName)

  const sharedTitle = t`Share with other users`
  const notSharedTitle = t`Only you`

  const optionsByCategory = sharingOptions.reduce<Record<string, Option[]>>(
    (categorizedOptions, option) => {
      const { category } = option
      categorizedOptions[category] = categorizedOptions[category] ?? []
      categorizedOptions[category].push(option)
      return categorizedOptions
    },
    {},
  )

  const fallbackPermissionPreset =
    permissionPreset === 'Inherit' ? 'Private' : permissionPreset
  const [lastSelectedPermissionPreset, setLastSelectedPermissionPreset] =
    React.useState<PermissionPreset>(fallbackPermissionPreset)
  const handlePermissionPresetChange = React.useCallback(
    (permissionPreset: PermissionPreset) => {
      if (permissionPreset !== 'Inherit') {
        setLastSelectedPermissionPreset(permissionPreset)
      }
      onChange(permissionPreset)
    },
    [onChange],
  )

  const handleSameAsGroupChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target
      onChange(checked ? 'Inherit' : lastSelectedPermissionPreset)
    },
    [lastSelectedPermissionPreset, onChange],
  )

  return (
    <FormControl>
      {title && title.length > 0 && (
        <FormLabel htmlFor="permissionPreset">{title}</FormLabel>
      )}

      <Flex gap={2} flexDirection="column">
        {folderPermissionPreset && (
          <Flex display="flex" alignItems="center">
            <Switch
              size="sm"
              color="primary"
              isChecked={permissionPreset === 'Inherit'}
              onChange={handleSameAsGroupChange}
              mr={2}
            />
            <VStack spacing={0} alignItems="start">
              <Text fontSize="sm" fontWeight="medium">
                <Trans>Same as folder</Trans>
              </Text>
              <Text fontSize="xs" color="gray.600">
                <Trans>Follow folder permission settings</Trans>
              </Text>
            </VStack>
          </Flex>
        )}

        <ShareAndEditOptionComponent
          optionsByCategory={optionsByCategory.notShared}
          onChange={handlePermissionPresetChange}
          permissionPreset={
            permissionPreset === 'Inherit'
              ? folderPermissionPreset
              : permissionPreset
          }
          flexBasis="80px"
          categoryTitle={notSharedTitle}
          isDisabled={permissionPreset === 'Inherit'}
        />
        <ShareAndEditOptionComponent
          optionsByCategory={optionsByCategory.shared}
          onChange={handlePermissionPresetChange}
          permissionPreset={
            permissionPreset === 'Inherit'
              ? folderPermissionPreset
              : permissionPreset
          }
          flexBasis="250px"
          categoryTitle={sharedTitle}
          isDisabled={permissionPreset === 'Inherit'}
        />
      </Flex>
    </FormControl>
  )
}
