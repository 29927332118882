import { routes as conversationRoutes } from 'pages/Conversations'
import {
  ConversationPageView,
  conversationPageViews,
} from 'pages/Conversations/routes'
import React from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'

const RedirectToConversation: React.FC = () => {
  const { uid, conversationPageView } = useParams()
  const location = useLocation()
  const realTabName = Object.values(conversationPageViews).includes(
    conversationPageView as ConversationPageView,
  )
    ? (conversationPageView as ConversationPageView)
    : undefined

  return (
    <Navigate
      to={
        uid
          ? conversationRoutes.conversation(uid, realTabName, location.search)
          : `${conversationRoutes.root}${
              location.search ? location.search : ''
            }`
      }
      replace
    />
  )
}

export default RedirectToConversation
