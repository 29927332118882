import { PhraseSettings } from '@capturi/api-trackers'

const hasNearnessSettings = (settings?: PhraseSettings): boolean => {
  if (!settings) return false
  const { near, notNear } = settings
  const hasNearness =
    (near !== null && near !== undefined) ||
    (notNear !== null && notNear !== undefined)
  return hasNearness
}

export default hasNearnessSettings
