import {
  ConversationResponseModel,
  conversationsAPI,
} from '@capturi/api-conversations'
import request, { downloadBlob } from '@capturi/request'
import { useToast } from '@capturi/ui-components'
import { t } from '@lingui/macro'

const useDownloadConversation = (
  conversationUid: string,
): (() => Promise<void>) => {
  const toast = useToast()

  const downloadConversation = async (): Promise<void> => {
    try {
      toast({
        title: t`Fetching audio`,
        status: 'info',
      })
      // Fetch conversation info
      const conversation = await request<ConversationResponseModel>(
        conversationsAPI.getConversation(conversationUid),
      )
      // Fetch audio info
      const downloadUrl = `playback/audio/${conversationUid}?api-version=3.3`

      const { subject, dateTime, customer } = conversation

      //Make the date look pretty-ish for the filename
      const formattedDate = dateTime?.toLocaleString().replace(/\/|:/g, '-')
      const name = `${formattedDate} ${customer} ${subject}.wav`
      await downloadBlob(downloadUrl, name)
      toast({
        title: t`Audio downloaded`,
        status: 'success',
      })
    } catch (error) {
      let errMsg = undefined

      if (error instanceof Error) {
        errMsg = error.message
      }

      toast({
        title: t`Could not download audio`,
        status: 'error',
        description: errMsg,
      })
    }
  }

  return downloadConversation
}

export default useDownloadConversation
