import { useFirstPhoneSegmentState } from '@capturi/filters'
import { useFilterPeriodContext } from '@capturi/filters'
import { useIntersectionTracking } from '@capturi/ui-components'
import { RefObject, useEffect } from 'react'

export const useHomePageIntersectionTracking = ({
  onIntersect,
}: { onIntersect: () => void }): RefObject<HTMLDivElement> => {
  const { values: segmentValues } = useFirstPhoneSegmentState()
  const { periodDef } = useFilterPeriodContext()

  const { ref, reset } = useIntersectionTracking({
    onIntersect,
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to reset tracking on changes of period / filters
  useEffect(() => {
    reset()
  }, [reset, segmentValues, periodDef])

  return ref
}
