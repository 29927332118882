import { formatTime } from '@capturi/audio'
import {
  Box,
  Flex,
  Icon,
  Progress,
  StyleProps,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useBenchmarkScores } from 'pages/KeyTopics/hooks/useBenchmarkScores'
import React from 'react'
import { MdAlignVerticalCenter } from 'react-icons/md'
import { Benchmark } from '../../../../pages/KeyTopics/hooks/types'
import { SentimentBenchmark } from '../SentimentBenchmark'
import { GraphBenchmarkCard } from './GraphBenchmarkCard'

const GraphBenchmarks: React.FC<{
  benchmark: Benchmark
  referenceBenchmark: Benchmark
  showReferenceBenchmark: boolean
  totalConversationsInTopic?: number
  benchmarkCardStyle?: StyleProps
}> = ({ benchmark, referenceBenchmark, showReferenceBenchmark }) => {
  const formattedTime = formatTime(benchmark.avgDuration)
  const { combinedScores, sumOfScores } = useBenchmarkScores(
    benchmark.scores,
    referenceBenchmark.scores,
  )
  return (
    <>
      <GraphBenchmarkCard title={t`Duration`}>
        <Box textAlign="center">
          <Text
            id="shrink-with-width"
            align="center"
            lineHeight="1.2"
            fontSize={formattedTime.length > 5 ? '4xl' : '5xl'}
            fontWeight="medium"
          >
            {formattedTime}
          </Text>
          {showReferenceBenchmark && (
            <Flex color="gray.600" align="center" justify="center">
              <Icon as={MdAlignVerticalCenter} />
              <Text align="center" fontSize="lg" ml={1}>
                {formatTime(referenceBenchmark?.avgDuration)}
              </Text>
            </Flex>
          )}
        </Box>
      </GraphBenchmarkCard>
      <GraphBenchmarkCard bodyBottomPadding={3} title={t`Customer sentiment`}>
        <SentimentBenchmark
          sentiment={benchmark.sentiment}
          referenceSentiment={referenceBenchmark.sentiment}
          showReferenceBenchmark={showReferenceBenchmark}
        />
      </GraphBenchmarkCard>
      <GraphBenchmarkCard title={t`Score`}>
        <VStack spacing={2} align="stretch">
          {combinedScores.length > 0 ? (
            combinedScores.map((item) => (
              <Box key={item.score.uid}>
                <Flex justifyContent="space-between">
                  <Text fontSize="sm" noOfLines={1}>
                    {item.score.name}
                  </Text>
                  <Flex
                    justifyContent="space-between"
                    flex={showReferenceBenchmark ? '0 0 65px' : 'initial'}
                  >
                    {showReferenceBenchmark && (
                      <Flex align="center">
                        <Icon as={MdAlignVerticalCenter} color="gray.600" />
                        <Text fontSize="sm" color="gray.600" ml={1}>
                          {item.rootScore.avgScore.toFixed(1)}
                        </Text>
                      </Flex>
                    )}
                    <Text fontSize="sm" fontWeight="medium">
                      {item.score.avgScore.toFixed(1)}
                    </Text>
                  </Flex>
                </Flex>
                <Progress
                  h="3px"
                  hasStripe
                  value={
                    sumOfScores !== 0 ? item.score.avgScore / sumOfScores : 0
                  }
                />
              </Box>
            ))
          ) : (
            <Text fontSize="sm" fontWeight="medium">
              <Trans>No results</Trans>
            </Text>
          )}
        </VStack>
      </GraphBenchmarkCard>
    </>
  )
}

export default React.memo(GraphBenchmarks)
