import {
  HStack,
  Icon,
  UseCheckboxProps,
  chakra,
  useCheckbox,
  useId,
} from '@chakra-ui/react'
import React from 'react'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'

const StyledControl = chakra('div', {
  baseStyle: {
    w: '100%',
    h: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'start',
    userSelect: 'none',
    flexShrink: 0,
    borderWidth: '2px',
    borderColor: 'gray.300',
    borderRadius: 'md',
    py: 3,
    px: 4,
  },
})

const StyledContainer = chakra('label', {
  baseStyle: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'top',
    position: 'relative',
    _disabled: {
      cursor: 'not-allowed',
    },
  },
})

export type ButtonCheckboxProps = UseCheckboxProps

export const ButtonCheckbox: React.FC<
  ButtonCheckboxProps & { children?: React.ReactNode }
> = ({ children, ...props }) => {
  const { getInputProps, getCheckboxProps, htmlProps, state } =
    useCheckbox(props)
  const inputProps = getInputProps()
  const id = useId()
  const bg = state.isChecked ? 'gray.100' : 'white'
  const checkboxColor = state.isChecked ? 'brand.primary' : 'gray.300'

  return (
    <StyledContainer
      aria-disabled={state.isDisabled}
      opacity={state.isDisabled ? 0.4 : undefined}
      {...htmlProps}
    >
      <input {...inputProps} aria-labelledby={id} />
      <StyledControl {...getCheckboxProps()} bg={bg} id={id}>
        <HStack spacing={4} w="full">
          <Icon
            as={state.isChecked ? MdCheckBox : MdCheckBoxOutlineBlank}
            boxSize={5}
            color={checkboxColor}
          />
          {children}
        </HStack>
      </StyledControl>
    </StyledContainer>
  )
}
