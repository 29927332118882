export type Shape = 'Diamond' | 'Hexagon' | 'Circle' | 'Pentagon' | 'Triangle'

export const Shapes: Shape[] = [
  'Diamond',
  'Hexagon',
  'Circle',
  'Pentagon',
  'Triangle',
]

export const DefaultShape = 'Circle'
