import { BrowserHistory, To, createBrowserHistory } from 'history'
import React, { useLayoutEffect } from 'react'
import { BrowserRouterProps, Router } from 'react-router-dom'

import { useStore } from '@tanstack/react-store'
import { orgIdStore } from 'features/auth/state'
import qs from 'query-string'

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type HistoryFn = (to: To, state?: any) => void

function CustomBrowserRouter({
  basename,
  children,
  window,
}: BrowserRouterProps): React.ReactElement {
  const orgId = useStore(orgIdStore)
  const historyRef = React.useRef<BrowserHistory>()
  if (historyRef.current == null) {
    historyRef.current = createBrowserHistory({ window })
  }

  const [state, setState] = React.useState({
    action: historyRef.current.action,
    location: historyRef.current.location,
  })

  const history: BrowserHistory = React.useMemo(() => {
    function enhanceHistoryFn(wrappedFn: HistoryFn): HistoryFn {
      return function enhancedHistoryFn(to, state) {
        let modifiedTo = to
        if (typeof to === 'string') {
          const { url, query } = qs.parseUrl(to)
          if (query.org !== orgId) {
            modifiedTo = qs.stringifyUrl({
              url,
              query: {
                ...query,
                org: orgId,
              },
            })
          }
        } else {
          const query = qs.parse(to.search ?? '')
          if (query.org !== orgId) {
            modifiedTo.search = qs.stringify({
              ...query,
              org: orgId,
            })
          }
        }
        wrappedFn(modifiedTo, state)
      }
    }

    const instance = historyRef.current as BrowserHistory
    return {
      ...instance,
      push: enhanceHistoryFn(instance.push),
      replace: enhanceHistoryFn(instance.replace),
    }
  }, [orgId])

  useLayoutEffect(() => history.listen(setState), [history])

  return (
    <Router
      basename={basename}
      // biome-ignore lint/correctness/noChildrenProp: legacy
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  )
}

export default CustomBrowserRouter
