import {
  BaseTracker,
  PhraseSettings,
  TimeFilterKind,
  isTrackerPrivate,
} from '@capturi/api-trackers'
import { formatTime } from '@capturi/audio'
import { useFilterPeriodContext } from '@capturi/filters'
import { UserPermissionsList } from '@capturi/sharing'
import { useOrganization, useUsers } from '@capturi/stores'
import { SectionHeading } from '@capturi/ui-components'
import {
  Box,
  Collapse,
  Flex,
  Grid,
  Popover,
  PopoverTrigger,
  Tag,
  Text,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { Trans, select, t } from '@lingui/macro'
import NearnessPopoverContent from 'components/ConversationDetails/components/NearnessPopoverContent'
import PrivateTrackerTag from 'components/PrivateTrackerTag'
import ProcessingTag from 'components/ProcessingTag'
import capitalize from 'lodash/capitalize'
import { EditTrackerButton } from 'pages/TrackersPage/components/TrackerButtons'
import React, { useState } from 'react'
import {
  MdHistory,
  MdInfoOutline,
  MdLabel,
  MdPerson,
  MdPowerSettingsNew,
  MdShare,
  MdTimer,
  MdTrackChanges,
  MdUnfoldLess,
  MdUnfoldMore,
} from 'react-icons/md'

import { useFeatureFlags } from '@capturi/feature-flags'
import hasNearnessSettings from '../../../../utils/hasNearnessSettings'
import { speakers } from '../messageDescriptors'
import { isProcessingInCurrentPeriod, switchPermissionPreset } from '../utils'

type TrackerAccordionType = {
  tracker: BaseTracker
}

const clampLine = css`
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const TrackerAccordion: React.FC<TrackerAccordionType> = ({ tracker }) => {
  const { getUserByUid } = useUsers()
  const canEditTracker = tracker.accessLevel === 'Edit'
  const { organizationType } = useOrganization()
  const { useEmailChannelAsDefault } = useFeatureFlags()
  const [isOpen, setIsOpen] = useState(false)

  const { period } = useFilterPeriodContext()
  const processingInCurrentPeriod = isProcessingInCurrentPeriod({
    isProcessing: tracker.isProcessing,
    processingProgress: tracker.processingProgress,
    isTextProcessing: tracker.isTextProcessing,
    textProcessingProgress: tracker.textProcessingProgress,
    periodFrom: period.from,
  })

  const getTimeFilterString = ({
    seconds,
    secondsEnd,
    kind,
  }: {
    seconds: number | undefined
    secondsEnd: number | null
    kind: TimeFilterKind
  }): string | React.ReactElement => {
    const formattedTimestamp = formatTime(seconds)
    const formattedTimestampEnd = formatTime(secondsEnd || undefined)
    switch (kind) {
      case 'FROMSTART':
        return t`First ${formattedTimestamp}`
      case 'FROMENDING':
        return t`Last ${formattedTimestamp}`
      case 'RANGE':
        return (
          <>
            <Text>{t`${formattedTimestamp} seconds after start`}</Text>
            <Text>{t`${formattedTimestampEnd} seconds before end`}</Text>
          </>
        )
      default:
        return t`Entire conversation`
    }
  }
  const getSpeaker = (speaker: string): string => {
    let speakerValue = ''
    switch (speaker) {
      case 'All':
        speakerValue = t`All`
        break
      case 'Employee':
        speakerValue = t`Employee`
        break
      case 'Customer':
        speakerValue = select(organizationType, {
          public: 'Citizen',
          other: 'Customer',
        })
    }
    return speakerValue
  }

  const processingTooltip = t`Statistics for this tracker are currently being calculated`
  return (
    <Box mb="2">
      <Flex>
        <Flex
          flexGrow="1"
          py="1"
          pr="2"
          pl="0"
          gap="2"
          alignItems="center"
          cursor={useEmailChannelAsDefault ? undefined : 'pointer'}
          onClick={
            useEmailChannelAsDefault ? undefined : () => setIsOpen((t) => !t)
          }
        >
          <Flex mr="auto">
            <Box textAlign="left">
              <SectionHeading
                m="0"
                pr="2"
                display="flex"
                alignItems="center"
                fontSize="2xl"
              >
                {capitalize(tracker.name)}
                {isTrackerPrivate(tracker) && <PrivateTrackerTag ml={2} />}
              </SectionHeading>
            </Box>
            {processingInCurrentPeriod && (
              <ProcessingTag
                label={t`Calculating`}
                tooltipLabel={processingTooltip}
                mx={2}
              />
            )}
          </Flex>
          {!useEmailChannelAsDefault && (
            <Flex py="1" alignItems="center">
              {isOpen ? (
                <MdUnfoldLess fontSize="14" cursor="pointer" />
              ) : (
                <MdUnfoldMore fontSize="14" cursor="pointer" />
              )}

              <Text
                ml="2"
                size="lg"
                fontSize="sm"
                fontWeight="medium"
                cursor="pointer"
              >
                <Trans>Show details</Trans>
              </Text>
            </Flex>
          )}
        </Flex>
        <EditTrackerButton
          canEditTracker={canEditTracker}
          isMasterTarget={tracker.isMasterTarget}
          uid={tracker.uid}
        >
          {tracker.isMasterTarget ? <Trans>Master Tracker</Trans> : null}
        </EditTrackerButton>
      </Flex>
      <Collapse in={isOpen}>
        <Box
          mt="2"
          backgroundColor="#fff"
          py="1"
          px="4"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="4px"
        >
          <Grid templateColumns="1fr 1px .7fr" columnGap={2} fontSize="sm">
            <Box py="3" fontWeight="medium" px="1">
              <Box mb="4">
                <Text>
                  <Trans>Words/sentences</Trans>
                </Text>
                {tracker.speech?.phrases?.map((word) => {
                  const nearnessObject = tracker.speech?.phrasesSettings?.[word]

                  return hasNearnessSettings(nearnessObject) ? (
                    <Popover trigger="hover" key={word}>
                      <PopoverTrigger>
                        <Tag
                          fontWeight="normal"
                          cursor="pointer"
                          borderRadius="xl"
                          mr="2"
                          mt="2"
                        >
                          <Box mr="2">
                            <MdTrackChanges />
                          </Box>
                          {word}
                        </Tag>
                      </PopoverTrigger>
                      <NearnessPopoverContent
                        phrasesSetting={nearnessObject as PhraseSettings}
                      />
                    </Popover>
                  ) : (
                    <Tag
                      fontWeight="normal"
                      key={word}
                      borderRadius="xl"
                      mr="2"
                      mt="2"
                    >
                      {word}
                    </Tag>
                  )
                })}
              </Box>
              {tracker.tags.length !== 0 && (
                <Box mb="6">
                  <Flex alignItems="center">
                    <MdLabel fontSize="14" />

                    <Text as="span" px="4px">
                      <Trans>Tags</Trans>
                    </Text>
                    <MdInfoOutline />
                  </Flex>
                  {tracker.tags.map((tag) => (
                    <Tag
                      fontWeight="normal"
                      key={tag}
                      borderRadius="4px"
                      mr="2"
                      mt="2"
                      backgroundColor="gray.300"
                    >
                      {tag}
                    </Tag>
                  ))}
                </Box>
              )}
            </Box>
            <Box height="100%" bg="gray.200" />
            <Box py="3" pl="3">
              <Flex justify="space-between" align="center" mb="4">
                <Box display="flex" alignItems="center">
                  <MdShare size="14" />
                  <Text fontWeight="medium" ml="8px">
                    <Trans>Sharing</Trans>
                  </Text>
                </Box>

                {tracker.acl && tracker.acl.length > 0 ? (
                  <UserPermissionsList acl={tracker.acl} isAccordion={true}>
                    <Box
                      px="2"
                      bg="gray.300"
                      fontWeight="medium"
                      borderRadius="4"
                    >
                      {switchPermissionPreset(tracker.permissionPreset)}
                    </Box>
                  </UserPermissionsList>
                ) : (
                  <Box
                    px="2"
                    bg="gray.300"
                    fontWeight="medium"
                    borderRadius="4"
                  >
                    {switchPermissionPreset(tracker.permissionPreset)}
                  </Box>
                )}
              </Flex>
              {tracker.speech && (
                <>
                  <Flex justify="space-between" align="center" mb="4">
                    <Box display="flex" alignItems="center">
                      <MdPerson size="14" />
                      <Text fontWeight="medium" ml="8px">
                        <Trans>Mentioned by</Trans>
                      </Text>
                    </Box>
                    <Box
                      px="2"
                      bg="gray.300"
                      fontWeight="medium"
                      borderRadius="4"
                    >
                      {getSpeaker(speakers[tracker.speech.speakerId])}
                    </Box>
                  </Flex>
                  <Flex justify="space-between" align="center" mb="4">
                    <Box display="flex" alignItems="center">
                      <MdTimer size="14" />
                      <Text fontWeight="medium" ml="8px">
                        <Trans>Active</Trans>
                      </Text>
                    </Box>
                    <Box
                      px="2"
                      bg="gray.300"
                      fontWeight="medium"
                      borderRadius="4"
                    >
                      {getTimeFilterString(tracker.speech?.timeFilter)}
                    </Box>
                  </Flex>
                </>
              )}

              <Flex justify="space-between" align="center" gap="2" mb="4">
                <Box display="flex" alignItems="center">
                  <MdPowerSettingsNew size="14" />
                  <Text fontWeight="medium" ml="8px">
                    <Trans>Created</Trans>
                  </Text>
                </Box>
                <Box
                  px="2"
                  bg="gray.300"
                  fontWeight="medium"
                  borderRadius="4"
                  css={clampLine}
                >
                  <Trans>
                    {i18n.date(tracker.created, { dateStyle: 'long' })} by{' '}
                    {getUserByUid(tracker.createdByUserUid).name}
                  </Trans>
                </Box>
              </Flex>
              <Flex justify="space-between" align="center" gap="2" mb="4">
                <Box display="flex" alignItems="center">
                  <MdHistory size="14" />
                  <Text fontWeight="medium" ml="8px">
                    <Trans>Updated</Trans>
                  </Text>
                </Box>
                <Box
                  px="2"
                  bg="gray.300"
                  fontWeight="medium"
                  borderRadius="4"
                  css={clampLine}
                >
                  <Trans>
                    {i18n.date(tracker.updated, { dateStyle: 'long' })} by{' '}
                    {getUserByUid(tracker.updatedByUserUid).name}
                  </Trans>
                </Box>
              </Flex>
            </Box>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  )
}

export default TrackerAccordion
