import { Box, IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { GiSpeaker } from 'react-icons/gi'
import { MdPause, MdPlayArrow, MdStop, MdVolumeOff } from 'react-icons/md'
import { useHover } from 'react-use'
import Spinner from '../Spinner'

const fontSizes = {
  lg: '1.6rem',
  md: '1.4rem',
  sm: '1.2rem',
  xs: '1rem',
}

export type PlayButtonProps = {
  isPlaying?: boolean
  isLoading?: boolean
  useStopIcon?: boolean
  useSpeakerIcon?: boolean
  hasAudio?: boolean
  isPlaybackAllowed?: boolean
} & Omit<IconButtonProps, 'aria-label' | 'disabled'>

export const PlayButton = React.forwardRef<HTMLButtonElement, PlayButtonProps>(
  function PlayButton(
    {
      isPlaying = false,
      isLoading = false,
      size = 'sm',
      colorScheme = 'primary',
      variant = 'lifted-icon',
      useStopIcon = false,
      useSpeakerIcon = true,
      hasAudio = true,
      isPlaybackAllowed = true,
      ...restProps
    },
    ref,
  ) {
    const fontSize = fontSizes[size as keyof typeof fontSizes] ?? 'xs'

    // biome-ignore lint/suspicious/noRedeclare: not sure the rule is right here?
    const [PlayButton] = useHover((isHovering) => {
      let icon = MdPlayArrow
      if (!hasAudio) icon = MdVolumeOff
      if (isPlaying) {
        icon = useStopIcon ? MdStop : MdPause
        if (useSpeakerIcon && !isHovering) {
          icon = GiSpeaker
        }
      }
      return (
        <Tooltip
          label={
            !hasAudio
              ? t`Audio on this conversation has been removed`
              : t`You do not have permission to play audio`
          }
          isDisabled={hasAudio && isPlaybackAllowed}
        >
          <IconButton
            ref={ref}
            aria-label="Play"
            isRound
            variant={variant}
            colorScheme={colorScheme}
            size={size}
            fontSize={fontSize}
            isDisabled={!(hasAudio && isPlaybackAllowed)}
            {...restProps}
          >
            {isLoading ? (
              <>
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  w="100%"
                  h="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box as={MdPlayArrow} aria-hidden color="whiteAlpha.500" />
                </Box>
                <Spinner
                  size={typeof size === 'string' ? size : 'sm'}
                  thickness="2px"
                  color="whiteAlpha.800"
                  emptyColor="transparent"
                />
              </>
            ) : (
              <Box as={icon} aria-hidden color="currentColor" />
            )}
          </IconButton>
        </Tooltip>
      )
    })
    return PlayButton
  },
)
