import request from '@capturi/request'
import { useToast } from '@capturi/ui-components'
import { useConfirm } from '@capturi/use-confirm'
import { useModal } from '@capturi/use-modal'
import { t } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useMemo } from 'react'

import { LibraryAnalyticsEvent, logEvent } from './analytics'
import { api as apiFactory } from './api'
import CreateFolderDialog from './components/CreateFolderDialog'
import UpdateFolderDialog from './components/UpdateFolderDialog'
import { LibraryFolder, NewFolder, SnippetLibraryResponseModel } from './types'
import * as folderUtils from './utils/folders'

const api = apiFactory()

interface Intents {
  createFolder: () => void
  deleteFolder: (folder: LibraryFolder) => void
  editFolder: (folder: LibraryFolder) => void
}

function useFolderCRUD({
  onFolderCreated,
  onFolderDeleted,
  onFolderUpdated,
}: {
  onFolderCreated?: (folder: LibraryFolder) => void
  onFolderDeleted?: (folder: LibraryFolder) => void
  onFolderUpdated?: (folder: LibraryFolder) => void
}): Intents {
  const toast = useToast()

  const [showCreateFolderModal] = useModal(CreateFolderDialog)
  const [showUpdateFolderModal] = useModal(UpdateFolderDialog)
  const confirm = useConfirm()

  const handleCreateFolder = useCallback(
    async (folder: NewFolder): Promise<void> => {
      const { permissions } = folder
      const name = folder.name.trim()
      const description = folder.description?.trim()

      logEvent(LibraryAnalyticsEvent.CreatePlaylist, {
        isPublic: folderUtils.isPublic(permissions),
        roles: permissions?.roles,
        userCount: permissions?.users?.length ?? 0,
        hasDescription: !isEmpty(description),
      })
      try {
        const newFolder: LibraryFolder = await request.post(
          ...api.createFolder({
            name,
            description,
            permissions,
          }),
        )
        onFolderCreated?.(newFolder)
        toast({
          title: t`Playlist created`,
          description: t`The playlist ‘${newFolder.name}‘ has been created`,
          status: 'success',
        })
      } catch (_error) {
        toast({
          title: t`An error occurred`,
          description: t`The playlist ‘${name}‘ was not created`,
          status: 'error',
        })
      }
    },
    [onFolderCreated, toast],
  )

  const handleUpdateFolder = useCallback(
    async (folder: LibraryFolder): Promise<void> => {
      const { permissions } = folder
      const name = folder.name.trim()
      const description = folder.description?.trim()

      logEvent(LibraryAnalyticsEvent.UpdatePlaylist, {
        isPublic: folderUtils.isPublic(permissions),
        roles: permissions?.roles,
        userCount: permissions?.users?.length ?? 0,
        hasDescription: !isEmpty(description),
      })
      try {
        const updatedFolder = await request.patch<LibraryFolder>(
          ...api.updateFolder(folder.uid, name, description, permissions),
        )
        onFolderUpdated?.(updatedFolder)
        toast({
          title: t`Playlist updated`,
          description: t`The playlist ‘${name}‘ has been updated`,
          status: 'success',
        })
      } catch (_error) {
        toast({
          title: t`An error occurred`,
          description: t`The playlist ‘${name}‘ was not updated`,
          status: 'error',
        })
      }
    },
    [onFolderUpdated, toast],
  )

  const handleDeleteFolder = useCallback(
    async (folder: LibraryFolder): Promise<void> => {
      try {
        await request.delete(...api.deleteFolder(folder.uid))
        onFolderDeleted?.(folder)
        toast({
          title: t`Playlist deleted`,
          description: t`The playlist ‘${folder.name}‘ has been deleted`,
          status: 'success',
        })
      } catch (error) {
        toast({
          title: t`An error occurred`,
          description: t`The playlist ‘${name}‘ was not deleted`,
          status: 'error',
        })
        throw error
      }
    },
    [onFolderDeleted, toast],
  )

  return useMemo(() => {
    const createFolder = (): void => {
      showCreateFolderModal({
        onSubmit: handleCreateFolder,
      })
    }

    const editFolder = (folder: LibraryFolder): void => {
      showUpdateFolderModal({
        folder,
        onSubmit: handleUpdateFolder,
      })
    }

    const deleteFolder = async (folder: LibraryFolder): Promise<void> => {
      try {
        const folderSnippets: SnippetLibraryResponseModel | undefined =
          await request.get(...api.getSnippets(folder.uid))
        if (folderSnippets && folderSnippets.audioSnippets.length > 0) {
          toast({
            title: t`Playlist can not be deleted`,
            description: t`The playlist ‘${folder.name}‘ is not empty. Delete the contents of the playlist before deleting the playlist.`,
            status: 'info',
          })
          return
        }
        await confirm({
          title: t`Delete playlist`,
          description: t`Are you sure want to delete the playlist "${folder.name}"?`,
          cancelText: t`Cancel`,
          confirmText: t`Delete`,
        })
        logEvent(LibraryAnalyticsEvent.DeletePlaylist)
        handleDeleteFolder(folder)
      } catch {
        //
      }
    }

    return {
      createFolder,
      editFolder,
      deleteFolder,
    }
  }, [
    showCreateFolderModal,
    handleCreateFolder,
    showUpdateFolderModal,
    handleUpdateFolder,
    confirm,
    toast,
    handleDeleteFolder,
  ])
}

export default useFolderCRUD
