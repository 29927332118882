import { Button } from '@capturi/ui-components'
import { MenuProps, SelectOption, components } from '@capturi/ui-select'
import { Box } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { MdCreateNewFolder } from 'react-icons/md'

type CreateFolderMenuProps<Options, IsMulti extends boolean> = {
  onCreateFolder: () => void
} & MenuProps<Options, IsMulti>

export const CreateFolderMenu: React.ComponentType<
  CreateFolderMenuProps<SelectOption, boolean>
> = ({ onCreateFolder, ...props }) => {
  return (
    <components.Menu {...props}>
      <Box>
        {props.children}
        <Box pl={4} pr={6} py={3} backgroundColor="gray.50">
          <Button
            onClick={onCreateFolder}
            leftIcon={<MdCreateNewFolder />}
            variant="ghost"
            w="100%"
          >
            <Trans>Create folder</Trans>
          </Button>
        </Box>
      </Box>
    </components.Menu>
  )
}
