// biome-ignore lint/suspicious/noExplicitAny: legacy
type Dict = Record<string, any>

import type { SystemStyleFunction } from '@chakra-ui/theme-tools'

function variantHitMark(): Dict {
  return {
    zIndex: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    boxShadow:
      '0px 3px 1px rgba(33, 109, 151, 0.01), 0px 2px 1px rgba(33, 109, 151, 0.05), 0px 1px 1px rgba(33, 109, 151, 0.09), 0px 0px 0px rgba(33, 109, 151, 0.1), 0px 0px 0px rgba(33, 109, 151, 0.1);',
    borderRadius: 12,
    borderColor: 'white',
    borderWidth: 1,
    maxWidth: '240px',
    pointerEvents: 'auto',
    cursor: 'pointer',
  }
}

const variantHitMarkInFront: SystemStyleFunction = (props) => {
  return {
    ...props.theme.components.Tooltip.variants.hitmark(),
    zIndex: 1,
  }
}

export default {
  variants: {
    hitmark: variantHitMark,
    hitmarkInFront: variantHitMarkInFront,
  },
}
