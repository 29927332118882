import styled from '@capturi/ui-theme'
import {
  Box,
  Grid as ChakraGrid,
  Flex,
  FlexProps,
  Skeleton,
  SkeletonCircle,
  Stack,
} from '@chakra-ui/react'
import React from 'react'

import {
  hitsLineHeightPx,
  talkLineHeightPx,
  userRowHeightPx,
  userRowSpacingPx,
} from './constants'

const GridAreas = {
  Users: 'audio-player__Users',
  UserSeekers: 'audio-player__user-seeker',
  UserPercentage: 'audio-player__user-percentage',
  Play: 'audio-player__play',
  Seeker: 'audio-player__seeker',
  Timestamp: 'audio-player__timestamp',
}

const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: '${GridAreas.Users} ${GridAreas.UserSeekers} ${GridAreas.UserPercentage} ';
  column-gap: 1rem;
  align-items: flex-start;
`

const UserRow: React.FC<FlexProps> = (props) => (
  <Flex align="center" h={userRowHeightPx} {...props} />
)

const UserSkeleton: React.FC = () => {
  return (
    <Stack isInline align="center">
      <Skeleton display={['none', null, null, 'block']} h={5} w={16} />
      <SkeletonCircle h={6} w={6} />
    </Stack>
  )
}

const AudioSkeleton: React.FC = () => {
  return (
    <>
      <Flex direction="column" width="100%">
        <Box
          px={5}
          pt={5}
          pb={3}
          bg="accents.lightBackground.default"
          borderWidth={1}
          borderRadius={'10px 10px 0px 0px'}
          borderColor="border.light"
          overflow="hidden"
        >
          <Grid>
            <Stack
              spacing={userRowSpacingPx}
              align="flex-end"
              justify="center"
              gridArea={GridAreas.Users}
            >
              <UserRow alignItems="flex-end">
                <UserSkeleton />
              </UserRow>
              <UserRow alignItems="flex-start">
                <UserSkeleton />
              </UserRow>
            </Stack>
            <Box gridArea={GridAreas.UserSeekers} position="relative" pb={1}>
              <Stack spacing={0}>
                <UserRow>
                  <ChakraGrid gridRowGap={0} w="100%">
                    <Box h={hitsLineHeightPx} />
                    <Skeleton h={talkLineHeightPx} w="100%" />
                  </ChakraGrid>
                </UserRow>
                <Box h={userRowSpacingPx} w="100%" position="relative" />
                <UserRow>
                  <ChakraGrid gridRowGap={1} w="100%">
                    <Skeleton h={talkLineHeightPx} w="100%" />
                    <Box h={hitsLineHeightPx} />
                  </ChakraGrid>
                </UserRow>
              </Stack>
            </Box>
            <Stack
              gridArea={GridAreas.UserPercentage}
              spacing={userRowSpacingPx}
              align="flex-end"
              justify="center"
            >
              <UserRow alignItems="flex-end">
                <Skeleton h={talkLineHeightPx} w={6} />
              </UserRow>

              <UserRow alignItems="flex-start">
                <Skeleton h={talkLineHeightPx} w={6} />
              </UserRow>
            </Stack>
          </Grid>
        </Box>
      </Flex>
      <Flex
        justify="space-between"
        align="center"
        px="5"
        py="0.875rem"
        borderWidth={1}
        borderRadius={'0px 0px 10px 10px'}
        borderColor="border.light"
      >
        <Box display="inline-block">
          <SkeletonCircle h={8} w={8} />
        </Box>

        <Box>
          <Flex align="center">
            <Skeleton h={5} w="6rem" />
            <Skeleton h={5} w="6rem" ml={4} />
            <Skeleton h={8} w={36} ml={4} borderRadius={4} />
          </Flex>
        </Box>
      </Flex>
    </>
  )
}

export default AudioSkeleton
