import analytics from '@capturi/analytics'
import { Summary } from '@capturi/api-summaries'
import { formatTime } from '@capturi/audio'
import { useTeams } from '@capturi/core'
import { useUsers } from '@capturi/stores'
import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Tag,
  Text,
  VStack,
  useTheme,
  useToast,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { FC, useEffect, useRef, useState } from 'react'
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
  MdOutlineThumbDown,
  MdOutlineThumbUp,
} from 'react-icons/md'

import { useConversationDetailsDrawer } from '../../../components/ConversationDetailsDrawer'

type Props = {
  summaryData: Summary
}

const dateFormat: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

const MAX_SUMMARY_HEIGHT = 110

export const SummaryItem: FC<Props> = ({ summaryData }) => {
  const { getTeamByUid } = useTeams()
  const { getUserByUid } = useUsers()

  const user = getUserByUid(summaryData.userUid)
  const team = getTeamByUid(summaryData?.teamUid)

  const [show, setShow] = useState(false)
  const handleToggleShow = (): void => setShow((s) => !s)
  const ref = useRef<HTMLParagraphElement>(null)
  const [height, setHeight] = useState(MAX_SUMMARY_HEIGHT)
  const forceShow = MAX_SUMMARY_HEIGHT > (ref.current?.offsetHeight || 0)

  const openConversationDetailsDrawer = useConversationDetailsDrawer()
  const toast = useToast()
  const theme = useTheme()
  const [reviewed, setReviewed] = useState(false)

  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy
  useEffect(() => {
    setHeight(ref.current?.offsetHeight ?? MAX_SUMMARY_HEIGHT)
  }, [ref.current?.offsetHeight])

  return (
    <Box flexDirection="column" mb={4}>
      <Box
        borderColor="transparent"
        pos="relative"
        bg="white"
        borderRadius="3px"
        css={css`&:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -1px;
            border-radius: 4px;
            opacity: 0.5;
            background: ${theme.colors.aiGradient};
          }`}
      >
        <Box borderRadius={4} position="relative" overflow="hidden" w="100%">
          <Box
            px={4}
            py={2}
            bg="gray.50"
            borderBottom="1px solid"
            borderColor="gray.200"
            cursor="pointer"
            onClick={() => {
              openConversationDetailsDrawer({
                uid: summaryData.uid,
                showOverlay: true,
              })
            }}
            _hover={{ bg: 'gray.100' }}
          >
            <HStack>
              <Flex w="100%" justifyContent="space-between">
                <VStack alignItems="start" gap={0}>
                  <Text fontSize="md" fontWeight="medium">
                    {summaryData.subject}
                  </Text>
                  <HStack gap={0}>
                    {summaryData.keyTopic && (
                      <Text fontSize="sm">{summaryData.keyTopic}</Text>
                    )}
                    {summaryData.keyTopic && summaryData.keySubTopic && (
                      <Icon as={MdKeyboardArrowRight} />
                    )}
                    {summaryData.keySubTopic && (
                      <Text fontSize="sm">{summaryData.keySubTopic}</Text>
                    )}
                  </HStack>
                </VStack>
                <VStack gap={1} alignItems="end">
                  <Flex gap={2}>
                    {team?.name && <Tag>{team?.name}</Tag>}{' '}
                    <Text fontWeight="medium" fontSize="sm">
                      {user.name}
                    </Text>
                  </Flex>
                  <HStack gap={1}>
                    <Text fontSize="sm" color="gray.600">
                      {formatTime(summaryData.duration)} <Trans>Minutes</Trans>
                    </Text>
                    <Text fontSize="sm" color="gray.600">
                      |
                    </Text>
                    <Text fontSize="xs" color="gray.600">
                      {i18n.date(summaryData.dateTime, dateFormat)}
                    </Text>
                  </HStack>
                </VStack>
              </Flex>
            </HStack>
          </Box>
          <HStack
            py={2}
            w="100%"
            justifyContent="space-between"
            alignItems="start"
            cursor={forceShow ? undefined : 'pointer'}
            onClick={handleToggleShow}
            _hover={{
              background: forceShow ? undefined : 'gray.200',
            }}
          >
            <Box gap={2} textAlign="start">
              <Box
                transition="height 200ms"
                height={forceShow || show ? height : MAX_SUMMARY_HEIGHT}
              >
                <Box overflow="hidden" pl={2}>
                  <Box ref={ref} ml={2}>
                    {summaryData.rootCause != null && (
                      <>
                        <Text
                          color="gray.600"
                          fontWeight="medium"
                          fontSize="sm"
                          mt={0.5}
                        >
                          <Trans>Call reason description</Trans>
                        </Text>
                        <Text whiteSpace="pre-wrap" pb={3}>
                          {summaryData.rootCause}
                        </Text>
                      </>
                    )}
                    <Text color="gray.600" fontWeight="medium" fontSize="sm">
                      <Trans>Additional details</Trans>
                    </Text>
                    <Text whiteSpace="pre-wrap" pb={4}>
                      {summaryData.summary}
                    </Text>
                  </Box>
                </Box>
                {!forceShow && (
                  <Box
                    transition="height 300ms"
                    id="gradientBox"
                    pos={show ? 'static' : 'absolute'}
                    bottom="0px"
                    w="100%"
                    h={show ? '12px' : '50px'}
                    background={
                      show
                        ? 'none'
                        : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 100%)'
                    }
                  >
                    <Icon
                      transform={show ? 'scale(1, 1)' : 'scale(1, -1)'}
                      pos="absolute"
                      left="50%"
                      ml="-20px"
                      bottom="4px"
                      as={MdKeyboardArrowUp}
                      fontSize="20px"
                    />
                  </Box>
                )}
              </Box>
            </Box>
            {!reviewed && (
              <HStack gap="1" pr={2}>
                <IconButton
                  aria-label="Thumbs up"
                  icon={<MdOutlineThumbUp />}
                  size="xs"
                  variant="ghost"
                  color="gray.500"
                  onClick={(e) => {
                    e.stopPropagation()
                    analytics.event('summary_ThumbsUp', {
                      summary: summaryData,
                      summaryUid: summaryData.uid,
                    })
                    setReviewed(true)
                    toast({
                      title: t`Thank you for the feedback`,
                      description: t`You are helping us to improve future summaries`,
                      status: 'success',
                      duration: 4000,
                      isClosable: true,
                    })
                  }}
                />
                <IconButton
                  aria-label="Thumbs down"
                  fontSize="8px"
                  icon={<MdOutlineThumbDown />}
                  size="xs"
                  variant="ghost"
                  color="gray.500"
                  onClick={(e) => {
                    e.stopPropagation()
                    analytics.event('summary_ThumbsDown', {
                      summary: summaryData,
                      conversationUid: summaryData.uid,
                    })
                    setReviewed(true)
                    toast({
                      title: t`Thank you for the feedback`,
                      description: t`You are helping us to improve future summaries`,
                      status: 'success',
                      duration: 4000,
                      isClosable: true,
                    })
                  }}
                />
              </HStack>
            )}
          </HStack>
        </Box>
      </Box>
    </Box>
  )
}
