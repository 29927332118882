import { atom } from 'jotai'
import { selectAtom } from 'jotai/utils'

export const textPhraseFieldsAtom = atom<string[]>([])

export const selectDuplicateTextPhrasesAtom = selectAtom(
  textPhraseFieldsAtom,
  (phraseFields) => {
    const seenPhrases = new Set<string>()
    const duplicates = new Set<string>()
    phraseFields.forEach((phrase) => {
      if (seenPhrases.has(phrase)) {
        duplicates.add(phrase)
      }
      seenPhrases.add(phrase)
    })
    return duplicates
  },
)
