import { PreviewAccessRequestModel } from '@capturi/core'
import { RequestOptions } from '@capturi/request'
import queryString from 'query-string'

import {
  CreateTrackerPayload,
  PreviewTrackerHitsRequestModel,
  UpdateTrackerPayload,
} from './models'

const DEFAULT_API_VERSION = '3.3'

const controller = 'trackers'
const baseQuery = {
  'api-version': DEFAULT_API_VERSION,
}

export const trackersAPI = {
  getTrackers: (includeInaccessible = false): RequestOptions => ({
    url: `${controller}`,
    query: { ...baseQuery, includeInaccessible },
  }),
  getTracker: (uid: string): RequestOptions => ({
    url: `${controller}/${uid}`,
    query: baseQuery,
  }),
  createTracker: (tracker: CreateTrackerPayload): RequestOptions => ({
    url: `${controller}`,
    method: 'post',
    json: tracker,
    query: baseQuery,
  }),
  updateTracker: (
    uid: string,
    tracker: UpdateTrackerPayload,
  ): RequestOptions => ({
    url: `${controller}/${uid}`,
    method: 'patch',
    json: tracker,
    query: baseQuery,
  }),
  deleteTracker: (uid: string): RequestOptions => ({
    url: `${controller}/${uid}`,
    method: 'delete',
    query: baseQuery,
  }),
  getSimilarWords: (
    words: string[],
    numExamples = 20,
    language = 'da-DK',
  ): RequestOptions => ({
    url: queryString.stringifyUrl(
      {
        url: 'synonyms/similar_words',
        query: {
          language,
          numExamples: numExamples.toString(),
          query: words,
          'api-version': DEFAULT_API_VERSION,
        },
      },
      { arrayFormat: 'separator', arrayFormatSeparator: ' ' },
    ),
    query: baseQuery,
  }),
  getTags: (): RequestOptions => ({
    url: `${controller}/tags`,
    query: baseQuery,
  }),
  getIsWordInDictionary: (
    word: string,
    languageTag = 'da-DK',
    useStemming = true,
  ): RequestOptions => ({
    url: 'transcriptions/is-in-dictionary',
    query: {
      ...baseQuery,
      useStemming: useStemming.toString(),
      languageTag,
      word,
    },
  }),
  previewTrackerHits: (
    requestModel: PreviewTrackerHitsRequestModel,
  ): RequestOptions => ({
    url: `${controller}/preview`,
    method: 'post',
    json: requestModel,
    query: baseQuery,
  }),
  getPreviewAccess: (model: PreviewAccessRequestModel): RequestOptions => ({
    url: `${controller}/preview-access`,
    query: baseQuery,
    method: 'post',
    json: model,
  }),
}

export const trackerFoldersAPI = {
  getPreviewAccess: (model: PreviewAccessRequestModel): RequestOptions => ({
    url: `${controller}/folders/preview-access`,
    query: baseQuery,
    method: 'post',
    json: model,
  }),
}
