import {
  AudioOptions,
  AudioProvider,
  formatTime,
  useAudioContext,
} from '@capturi/audio'
import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'
import { usePrevious, useSlider } from 'react-use'

import { useCurrentUser } from '@capturi/core'
import { PlayButton } from '@capturi/ui-components'
import styled from '@capturi/ui-theme'

const BAR_HEIGHT = 17

const Bar = styled.div`
  position: absolute;
  height: 3px;
  top: 7px;
  width: 100%;
  background-color: ${(p): string => p.theme.colors.gray[200]};
  border-radius: 6px;
`
const CutBar = styled.div`
  position: absolute;
  top: 5px;
  height: ${7}px;
  background-color: ${(p): string => p.theme.colors.primary[200]};
  border-radius: ${(p): string => p.theme.radii.md};
  transition: 220ms linear;
`

const Seeker = styled.div`
  position: absolute;
  background-color: ${(p): string => p.theme.colors.gray[600]};
  height: ${BAR_HEIGHT}px;
  width: 2px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  align-items: center;
`

const TimeText = styled(Box)`
  margin: 0;
  grid-column-start: 2;
`

export type PlayerProps = AudioOptions & {
  audioDuration: number
  conversationUid?: string
  start?: number
  end?: number
}

function Player({
  audioDuration,
  conversationUid,
  start = 0,
  end,
  ...audioOptionsProps
}: PlayerProps): React.ReactElement {
  const { audioState, playbackContext, play, pause, seek, isPlaying, getTime } =
    useAudioContext(
      conversationUid ? `/playback/audio/${conversationUid}` : undefined,
      audioOptionsProps,
    )
  const currentUser = useCurrentUser()

  const ref = useRef(null)
  const { isSliding, value: sliderValue } = useSlider(ref)
  const wasSliding = usePrevious(isSliding)

  const hasConstrainedInterval = start !== undefined && end !== undefined

  // `props.audioDuration` will be used until audio is loaded. Audio is always lazy loaded
  const totalDuration = playbackContext?.timestamp || audioDuration
  const scale = totalDuration / 100 || 1

  let startPoint = 0
  let endPoint = totalDuration

  if (start !== undefined) {
    startPoint = Math.min(Math.max(start, 0), totalDuration)
  }

  if (end !== undefined) {
    endPoint = Math.min(Math.max(startPoint, end), totalDuration)
  }

  const duration = endPoint - startPoint

  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy>
  useEffect(() => {
    if (audioState.time > endPoint) {
      pause()
      seek(startPoint)
    }
  }, [audioState, totalDuration, endPoint, startPoint, seek, pause])

  const seekerValue = React.useMemo(() => {
    if (isSliding) {
      return sliderValue
    }

    return audioState.time / totalDuration || 0
  }, [isSliding, sliderValue, audioState.time, totalDuration])

  useEffect(() => {
    if (wasSliding && !isSliding) {
      const timestamp = sliderValue * totalDuration
      seek(timestamp)
    }
  }, [wasSliding, isSliding, sliderValue, seek, totalDuration])

  const onTogglePlay = (): void => {
    if (isPlaying) {
      pause()
    } else {
      const currentTime = getTime()
      const isCursorOutsideInterval =
        currentTime < startPoint || currentTime > endPoint
      play(isCursorOutsideInterval ? startPoint : undefined)
    }
  }

  return (
    <Grid>
      <PlayButton
        onClick={onTogglePlay}
        isPlaying={isPlaying}
        useSpeakerIcon={false}
        isDisabled={!currentUser.permissions.playback}
        mr="2"
      />
      <Box ref={ref} w="100%" position="relative" height={`${BAR_HEIGHT}px`}>
        <Bar />
        {hasConstrainedInterval && (
          <CutBar
            style={{
              marginLeft: `${startPoint / scale}%`,
              width: `${duration / scale}%`,
            }}
          />
        )}
        <Seeker style={{ left: `${seekerValue * 100}%` }} />
      </Box>
      <TimeText fontSize="sm">
        <Flex justify="space-between">
          <Text>
            {`${formatTime(audioState.time ?? startPoint)}
              ${hasConstrainedInterval ? ` / ${formatTime(endPoint)}` : ''}`}
          </Text>
          <Text color="textMuted">{formatTime(totalDuration)}</Text>
        </Flex>
      </TimeText>
    </Grid>
  )
}

export default function SimpleAudioPlayer(
  props: PlayerProps,
): React.ReactElement {
  return (
    <AudioProvider enableTimeUpdates>
      <Player {...props} />
    </AudioProvider>
  )
}
