import { TimeResolution } from '@capturi/core'
import { useFilterPeriodContext } from '@capturi/filters'
import { Box, Checkbox, Tooltip } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { Store, useStore } from '@tanstack/react-store'
import React from 'react'
import { useTimeResolutionContext } from '../contexts/ResolutionContext'

const onlyWeekdaysStore = new Store<boolean>(true)

const acceptedResolutions: TimeResolution[] = ['Hours', 'Days']

function useOnlyWeekdaysToggle(): [boolean, React.ReactElement] {
  const onlyWeekdays = useStore(onlyWeekdaysStore)

  const { resolution } = useTimeResolutionContext()
  const { period } = useFilterPeriodContext()
  const isDisabled =
    !acceptedResolutions.includes(resolution) || period.days() === 1

  const ToggleComponent = (
    <Tooltip
      label={t`Hides the graphical display of Saturdays and Sundays in line graphs. Activation does not influence data, but only changes the graphical display so that Saturdays and Sundays do not appear in line graphs.`}
    >
      <Box display="inline">
        <Checkbox
          colorScheme="primary"
          isChecked={onlyWeekdays}
          onChange={(e) => onlyWeekdaysStore.setState(() => e.target.checked)}
          isDisabled={isDisabled}
          isIndeterminate={isDisabled}
        >
          <Trans>Hide weekends</Trans>
        </Checkbox>
      </Box>
    </Tooltip>
  )

  return [isDisabled ? false : onlyWeekdays, ToggleComponent]
}

export default useOnlyWeekdaysToggle
