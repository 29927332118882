import analytics from '@capturi/analytics'
import { AudioControls, AudioOptions, useAudioContext } from '@capturi/audio'
import { NoAudioTooltip } from '@capturi/sharing'
import { ListItem, PlayButton } from '@capturi/ui-components'
import { Box, BoxProps, Collapse, StyleProps } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { msg, t } from '@lingui/macro'
import React, { useEffect, useState } from 'react'
import { MdPause, MdPlayArrow, MdReplay } from 'react-icons/md'
import { useUnmount } from 'react-use'

const msgs = {
  rewind: msg`Rewind 10 seconds`,
  forward: msg`Forward 10 seconds`,
  toggleSpeed: msg`Toggle playback speed`,
}

enum Event {
  Play = 'playlist_item_Play',
  Pause = 'playlist_item_Pause',
  JumpBackward = 'playlist_item_JumpBackward',
  JumpForward = 'playlist_item_JumpForward',
  TogglePlaybackRate = 'playlist_item_TogglePlaybackRate',
  ManualSeekStop = 'playlist_item_ManualSeekStop',
}

export type PlayListItemProps = AudioOptions &
  StyleProps & {
    hasAudio?: boolean
    isSelected?: boolean
    hidePlayButton?: boolean
    showAudioPlayer?: boolean
    isLibrarySnippet?: boolean
    playOnClick?: boolean
    additionalActions?: (options: {
      duration: number
      getTime: () => number
      isPlaying: boolean
      toggle: () => void
    }) => React.ReactElement
    children?: React.ReactNode
  }

const selectedItemStyleProps: BoxProps = {
  bg: 'inherit',
  _focus: {
    bg: 'inherit',
    outline: 'none',
  },
  _hover: {
    bg: 'inherit',
  },
}

const PlayListItem: React.FC<PlayListItemProps> = ({
  isSelected = false,
  hidePlayButton = false,
  showAudioPlayer: showAudioPlayerProp = false,
  children,
  isLibrarySnippet = false,
  additionalActions,
  hasAudio = true,
  context,
  isDisabled,
  onPause,
  onPlay,
  onSeek,
  onStop,
  resetTimestampOnResume,
  rollbackSeconds,
  timestamp,
  playOnClick = true,
  ...restStyleProps
}) => {
  const audioOptions: AudioOptions = {
    context,
    isDisabled,
    onPause,
    onPlay,
    onSeek,
    onStop,
    resetTimestampOnResume,
    rollbackSeconds,
    timestamp: timestamp ?? 0,
  }

  const uid = isLibrarySnippet ? context?.snippetUid : context?.conversationUid

  const audioSource = isLibrarySnippet
    ? `/playback/library/${uid}`
    : `/playback/audio/${uid}`

  const {
    toggle,
    isPlaying,
    isContextMatch,
    getTime,
    audioState,
    stop,
    isAudioLoading,
  } = useAudioContext(audioSource, audioOptions)

  const [hasBeenPlayed, setHasBeenPlayed] = useState<boolean>(false)

  const showAudioControls =
    !isDisabled && showAudioPlayerProp && (isSelected || isContextMatch)

  useUnmount(() => {
    if (isPlaying) {
      stop()
    }
  })

  const handleIcon = (): JSX.Element => {
    let icon = <MdPlayArrow />
    if (hasBeenPlayed && !isPlaying) icon = <MdReplay />
    if (isPlaying) icon = <MdPause />
    return icon
  }

  useEffect(() => {
    // When disabled state changes to `true` and audio is playing then stop playback.
    if (isPlaying && isDisabled) {
      stop()
    }
  }, [isDisabled, isPlaying, stop])
  return (
    <NoAudioTooltip isDisabled={!isDisabled && hasAudio}>
      <Box as="li">
        <ListItem
          {...restStyleProps}
          alignItems="start"
          ContainerComponent="div"
          button={!isDisabled}
          isDisabled={isDisabled || !hasAudio}
          pb="2"
          onClick={() => {
            if (playOnClick && window.getSelection()?.isCollapsed) {
              toggle(true)
            }
          }}
          defaultHideSecondaryAction={!isPlaying}
          isSelected={isSelected || isPlaying}
          aria-label={t`Play audio`}
          {...(isPlaying ? selectedItemStyleProps : {})}
        >
          {!hidePlayButton && (
            <PlayButton
              isPlaying={isPlaying}
              mr={4}
              ml={0}
              icon={handleIcon()}
              onClick={() => {
                if (!playOnClick && window.getSelection()?.isCollapsed) {
                  toggle(true)
                }
                setHasBeenPlayed(true)
              }}
              isLoading={(isSelected || isContextMatch) && isAudioLoading}
              transform={`scale(${isPlaying ? 1.2 : 1})`}
            />
          )}
          {children}
        </ListItem>
        <Collapse in={showAudioControls} animateOpacity={false} unmountOnExit>
          <Box
            px={8}
            py={1}
            bg="gray.100"
            borderBottom="1px solid"
            borderBottomRadius="lg"
            borderColor="gray.200"
          >
            <AudioControls.Provider
              audioSource={audioSource}
              options={audioOptions}
            >
              <AudioControls.ButtonGroup spacing={1}>
                <AudioControls.PlayPauseButton
                  onPlay={() => analytics.event(Event.Play)}
                  onPause={() => analytics.event(Event.Pause)}
                />
                <AudioControls.JumpBackwardButton
                  title={i18n._(msgs.rewind)}
                  aria-label={i18n._(msgs.rewind)}
                  onClick={() => analytics.event(Event.JumpBackward)}
                />
                <AudioControls.JumpForwardButton
                  title={i18n._(msgs.forward)}
                  aria-label={i18n._(msgs.forward)}
                  onClick={() => analytics.event(Event.JumpForward)}
                />
                <AudioControls.PlaybackRateButton
                  title={i18n._(msgs.toggleSpeed)}
                  aria-label={i18n._(msgs.toggleSpeed)}
                  onClick={() => analytics.event(Event.TogglePlaybackRate)}
                />
              </AudioControls.ButtonGroup>
              <AudioControls.Time mx={4} />
              <AudioControls.Track
                onSeekStop={() => analytics.event(Event.ManualSeekStop)}
              />
              {typeof additionalActions === 'function' && (
                <Box mx="1">
                  {additionalActions({
                    duration: audioState.duration,
                    isPlaying,
                    getTime,
                    toggle,
                  })}
                </Box>
              )}
            </AudioControls.Provider>
          </Box>
        </Collapse>
      </Box>
    </NoAudioTooltip>
  )
}

export default PlayListItem
