import { CellProps, ColumnWithLooseAccessor } from 'react-table'

import AvatarLabel from '../components/AvatarLabel'
import { Cell } from '../components/Cell'
import { ColumnDefinition, DataType } from '../types'

export default function userColumn<T extends DataType>(
  c: ColumnDefinition<T>,
  // biome-ignore lint/suspicious/noExplicitAny: legacy
): ColumnWithLooseAccessor<any> {
  return {
    id: c.id ?? 'userId',
    accessor: c.getValue,
    width: c.width ?? 200,
    Cell: function UserCell({ row }: CellProps<T, string>) {
      return (
        <Cell>
          <AvatarLabel uid={row.values.userId} />
        </Cell>
      )
    },
  }
}
