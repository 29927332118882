import { theme as capturiTheme } from '@capturi/ui-theme'
import { GroupBase, StylesConfig, Theme } from 'react-select'

export function customStyles<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(): StylesConfig<Option, IsMulti, Group> {
  return {
    container: (base, state) => {
      return {
        ...base,
        fontSize: capturiTheme.fontSizes.sm,
        opacity: state.isDisabled ? 0.4 : 1,
        cursor: 'not-allowed',
      }
    },
    control: (base) => ({
      ...base,
      backgroundColor: capturiTheme.colors.white,
      cursor: 'pointer',
    }),
    singleValue: (base, state) => ({
      ...base,
      color: state.theme.colors.neutral80,
    }),
    multiValueLabel: (base) => ({
      ...base,
      padding: '3px 2px',
    }),
    multiValue: (base) => {
      return {
        ...base,
        backgroundColor: capturiTheme.colors.gray[300],
        borderRadius: '10px',
        overflow: 'hidden',
      }
    },
    multiValueRemove: (base) => ({
      ...base,
      cursor: 'pointer',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: `0 ${capturiTheme.space[2]}`,
      cursor: 'text',
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: '6px',
    }),
    menuPortal: (base) => ({
      ...base,
      minWidth: '10em',
      zIndex: 9999,
    }),
    placeholder: (base) => ({
      ...base,
      whiteSpace: 'nowrap',
      color: capturiTheme.colors.gray[500],
    }),
    groupHeading: (base) => ({
      ...base,
      padding: '0 .5rem',
    }),
    option: (base, state) => {
      const defualtOptions = {
        color: base.color,
        cursor: 'pointer',
        fontWeight: base.fontWeight,
        backgroundColor: 'initial',
        padding: '.5rem',
        ':hover': {
          backgroundColor: capturiTheme.colors.gray[50],
        },
      }

      if (state.isDisabled) {
        return {
          ...defualtOptions,
          color: capturiTheme.colors.gray[500],
          cursor: 'not-allowed',
        }
      }

      if (state.isSelected) {
        return {
          ...defualtOptions,
          backgroundColor: capturiTheme.colors.gray[200],
          color: 'inherit',
          fontWeight: 500,
          ':hover': { backgroundColor: capturiTheme.colors.gray[300] },
        }
      }

      return defualtOptions
    },
  }
}

export const customTheme = (theme: Theme): Theme => ({
  ...theme,
  borderRadius: 6,
  colors: {
    ...theme.colors,
    primary: capturiTheme.colors.primary[400],
    primary75: capturiTheme.colors.primary[200],
    primary50: capturiTheme.colors.gray[200],
    primary25: capturiTheme.colors.gray[100],
    neutral30: capturiTheme.colors.gray[300],
    neutral20: capturiTheme.colors.gray[200],
  },
  spacing: {
    ...theme.spacing,
    controlHeight: 32,
  },
})
