import { useMemo } from 'react'
import useSWR, { KeyedMutator, SWRConfiguration } from 'swr'

import { Team } from '../domain'

interface UseTeamsResponse {
  teams: Team[]
  mutate: KeyedMutator<Team[]>
  isLoading: boolean
  getTeamByUid: (uid: string) => Team | undefined
}

export function useTeams(swrConfig: SWRConfiguration = {}): UseTeamsResponse {
  const { data, error, mutate } = useSWR<Team[]>(
    'authentication/organization/teams',
    {
      suspense: false,
      revalidateOnFocus: false,
      dedupingInterval: 10000,
      ...swrConfig,
    },
  )
  return useMemo(() => {
    const teams = data ?? []
    const teamMap = new Map(teams.map((t) => [t.uid, t]))
    return {
      teams,
      mutate,
      isLoading: !(data || error),
      getTeamByUid: (uid: string) => teamMap.get(uid),
    }
  }, [data, error, mutate])
}
