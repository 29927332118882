import { Flex, FlexProps, Stack } from '@chakra-ui/react'
import React from 'react'

export const Cell: React.FC<FlexProps> = (props) => (
  <Flex align="center" width="full" alignItems="center" minH={6} {...props} />
)

export function MultiValueCell<T>({
  values,
  children,
  alignRight,
  ...restProps
}: {
  values: T[]
  children: (value: T, valueIndex: number) => React.ReactElement
  alignRight?: boolean
} & Omit<FlexProps, 'children'>): React.ReactElement {
  const justifyContent = alignRight ? 'flex-end' : 'flex-start'
  return (
    <Stack spacing="2" h="full">
      {values.map((v, i) => (
        <Cell
          key={i}
          height={`${100 / values.length}%`}
          justifyContent={justifyContent}
          {...restProps}
        >
          {children(v, i)}
        </Cell>
      ))}
    </Stack>
  )
}
