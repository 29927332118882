import { useState } from 'react'
import { useEffectOnce } from 'react-use'

export const useScrollable = (
  ref: React.RefObject<HTMLElement>,
  bottomPadding = 0,
): number => {
  const [height, setHeight] = useState<number>(0)
  useEffectOnce(() => {
    // Handler to call on window resize
    const handleResize = (): void => {
      const newHeight =
        window.innerHeight -
        (ref.current?.offsetTop || 0) -
        ((ref.current?.offsetParent as HTMLElement)?.offsetTop || 0) -
        bottomPadding
      if (newHeight !== height) {
        setHeight(newHeight)
      }
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  })

  return height
}
