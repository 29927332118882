import analytics from '@capturi/analytics'
import { useCurrentUser } from '@capturi/core'
import {
  FilterPeriodSelectContainer,
  SingleSegmentContainer,
  useFilterDefinitions,
} from '@capturi/filters'
import { usePageTitle } from '@capturi/react-utils'
import { PageHeading } from '@capturi/ui-components'
import { Button } from '@capturi/ui-components'
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { I18nProvider } from '@lingui/react'
import { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import { MdArrowDownward, MdDownload, MdOutgoingMail } from 'react-icons/md'

import {
  UseTopTimeConsumersKeyTopicsResponse,
  useKeyTopics,
  useLongConversationKeyTopics,
} from '../../hooks/useKeyTopics'
import ConversationsSentimentWidget from './ConversationsSentimentWidget/ConversationsSentimentWidget'
import SessionCreationFailed from './FailedStates/SessionCreationFailed'
import { LongConversationsExampleWidget } from './LongConversationsExampleWidget/LongConversationsExampleWidget'
import { RepeatCallsWidget } from './RepeatCallsWidget'
import { ScoreWidget } from './ScoreWidget/ScoreWidget'
import { useHomePageIntersectionTracking } from './Shared/useHomePageIntersectionTracking'
import { TopChangesWidget } from './TopChangesWidget'
import { TopConversationDriversWidget } from './TopConversationDriversWidget'
import { TopTimeConsumersWidget } from './TopTimeConsumersWidget'
import { useDownloadReportMutation } from './hooks/useDownloadReportMutation'

const selectSessionUidProps = {
  select: (data: UseTopTimeConsumersKeyTopicsResponse) => {
    if (data.status === 'Empty') {
      return { status: 'empty' }
    }
    return {
      uid: data.uid,
    }
  },
}

// Helper function to check if an element is in viewport
const isElementInViewport = (el: HTMLElement | null) => {
  if (!el) return false
  const rect = el.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

const HomePage: FC = () => {
  usePageTitle(t`Home`)

  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)

  const {
    data,
    error: sharedKeyTopicsSessionError,
    refetch: startNewSharedSession,
  } = useKeyTopics(selectSessionUidProps)
  const {
    data: lcData,
    error: longConversationsKeyTopicsSessionError,
    refetch: startNewLongConversationsSession,
  } = useLongConversationKeyTopics(selectSessionUidProps)
  const user = useCurrentUser({ suspense: false })

  const trackScrollToBottom = useCallback(() => {
    analytics.event('homepage_scrolled_to_bottom')
  }, [])
  const ref = useHomePageIntersectionTracking({
    onIntersect: trackScrollToBottom,
  })

  const { mutate: createReport } = useDownloadReportMutation()

  const handleDownloadReport = useCallback(() => {
    analytics.event('homepage_download_report')
    createReport()
  }, [createReport])

  const toast = useToast()
  const topConversationDriversRef = useRef<HTMLDivElement>(null)
  const topTimeConsumersRef = useRef<HTMLDivElement>(null)
  const repeatCallsRef = useRef<HTMLDivElement>(null)
  const toastIdRef = useRef<string | number | undefined>()

  const handleScrollToInsights = useCallback(() => {
    const isTopConversationDriversInView = isElementInViewport(
      topConversationDriversRef.current,
    )
    const isTopTimeConsumersInView = isElementInViewport(
      topTimeConsumersRef.current,
    )

    if (isTopConversationDriversInView) {
      topTimeConsumersRef.current?.scrollIntoView({ behavior: 'smooth' })
    } else if (isTopTimeConsumersInView) {
      repeatCallsRef.current?.scrollIntoView({ behavior: 'smooth' })
    } else {
      topConversationDriversRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    analytics.event('homepage_scrolled_to_insights')
  }, [])

  const observer = useMemo(() => {
    return new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (toastIdRef.current) {
            toast.close(toastIdRef.current)
            toastIdRef.current = undefined
          }
        } else {
          if (!toastIdRef.current) {
            toastIdRef.current = toast({
              position: 'bottom',
              duration: null,
              render: () => (
                <I18nProvider i18n={i18n}>
                  <Button
                    leftIcon={<MdArrowDownward />}
                    left="50%"
                    transform="translateX(-50%)"
                    zIndex="1000"
                    primary
                    size="md"
                    transition="bottom 0.3s ease"
                    onClick={handleScrollToInsights}
                  >
                    <Trans>Scroll for more insights</Trans>
                  </Button>
                </I18nProvider>
              ),
              isClosable: false,
            })
          }
        }
      },
      { threshold: 0.8 },
    )
  }, [toast, handleScrollToInsights])

  useEffect(() => {
    // Add observation for repeatCallsRef
    if (repeatCallsRef.current) {
      observer.observe(repeatCallsRef.current)
    }

    return () => {
      if (toastIdRef.current) {
        toast.close(toastIdRef.current)
      }
      observer.disconnect()
    }
  }, [observer, toast])

  return (
    <Flex flexDir="column">
      <Box mb={7} flex="0 1 auto" overflowY="auto">
        <Flex align="center" justify="space-between" mb={8}>
          <Box>
            <PageHeading>
              <Trans>Greetings, {user.name.split(' ')[0]}</Trans>
            </PageHeading>
          </Box>
          <HStack>
            <FilterPeriodSelectContainer />
          </HStack>
        </Flex>
        <Flex flexDir="row" justifyContent="space-between">
          <SingleSegmentContainer filterDefinitions={filterDefinitions} />
          <HStack>
            <Tooltip
              label={t`Coming: Get this sent to your inbox recurringly`}
              hasArrow
              placement="top"
            >
              <IconButton
                aria-label={t`Get this sent to your inbox recurringly`}
                size="sm"
                fontSize="8px"
                icon={<MdOutgoingMail />}
                variant="solid"
                color="gray.800"
                data-stonly="Subscribe to frontpage"
                onClick={() => analytics.event('homepage_email_report')}
              />
            </Tooltip>
            <Tooltip label={t`Download report`} hasArrow placement="top">
              <Button
                id="download_report_button"
                aria-label={t`Download report`}
                borderRadius="999"
                borderColor="primary.500"
                color="primary.500"
                leftIcon={<MdDownload />}
                onClick={handleDownloadReport}
                size="sm"
                variant="outline"
                _hover={{ bg: 'primary.50' }}
                _active={{ bg: 'primary.100' }}
              >
                <Trans>Download and share</Trans>
              </Button>
            </Tooltip>
          </HStack>
        </Flex>
      </Box>
      <VStack alignItems="stretch" flex="1 1 auto" mb="30px" gap={16}>
        {sharedKeyTopicsSessionError ? (
          <SessionCreationFailed
            error={sharedKeyTopicsSessionError}
            startNewSession={startNewSharedSession}
          />
        ) : (
          <TopChangesWidget
            keyTopicsSessionUid={data?.uid}
            startNewSession={startNewSharedSession}
          />
        )}
        <ConversationsSentimentWidget />
        {sharedKeyTopicsSessionError ? (
          <SessionCreationFailed
            error={sharedKeyTopicsSessionError}
            startNewSession={startNewSharedSession}
          />
        ) : (
          <Box ref={topConversationDriversRef} scrollMarginTop="10vh">
            <TopConversationDriversWidget
              keyTopicsSessionUid={data?.uid}
              startNewSession={startNewSharedSession}
            />
          </Box>
        )}
        <ScoreWidget />
        {longConversationsKeyTopicsSessionError ? (
          <SessionCreationFailed
            error={longConversationsKeyTopicsSessionError}
            startNewSession={startNewLongConversationsSession}
          />
        ) : (
          <Box ref={topTimeConsumersRef} scrollMarginTop="10vh">
            <TopTimeConsumersWidget
              keyTopicsSessionUid={lcData?.uid}
              startNewSession={startNewLongConversationsSession}
            />
          </Box>
        )}
        <LongConversationsExampleWidget />
        {sharedKeyTopicsSessionError ? (
          <SessionCreationFailed
            error={sharedKeyTopicsSessionError}
            startNewSession={startNewSharedSession}
          />
        ) : (
          <Box ref={repeatCallsRef} scrollMarginTop="10vh">
            <RepeatCallsWidget
              keyTopicsSessionUid={data?.uid}
              startNewSession={startNewSharedSession}
            />
          </Box>
        )}
      </VStack>
      <div ref={ref} style={{ height: '1px' }} />
    </Flex>
  )
}

export default HomePage
