import { t } from '@lingui/macro'
import React from 'react'
import { FilterValueLabel } from '../../components/FilterValueLabel'

export const SpeakersValueLabel: React.FC<{ value: number[] }> = ({
  value,
}) => {
  const getLabelValue = (value: number[]): string | undefined => {
    if (value && value[0] === 2) {
      return t`Agent and customer`
    }
    return t`Only agent`
  }
  return <FilterValueLabel name={t`Speakers`} value={getLabelValue(value)} />
}
