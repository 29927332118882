import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { t } from '@lingui/macro'

import { useMemo } from 'react'

export type CsvConfigDataProps = {
  category: string
  show?: boolean
  data: {
    label: string
    value: string
    hidden?: boolean
  }[]
}[]

export function useCsvConfigData() {
  const { aiFeatures } = useFeatureFlags()
  const {
    permissions: { hideUserInfo },
  } = useCurrentUser()

  const CsvConfigData: CsvConfigDataProps = useMemo(() => {
    return [
      {
        category: 'Metadata',
        data: [
          {
            label: t`Conversation uid`,
            value: 'Uid',
          },
          {
            label: t`Date`,
            value: 'Date',
          },
          {
            label: t`Subject`,
            value: 'Subject',
          },
          {
            label: t`Status`,
            value: 'Status',
          },
          {
            label: t`Duration`,
            value: 'Duration',
          },
          {
            label: t`Customer`,
            value: 'Customer',
          },
          {
            label: t`Employee uid`,
            value: 'UserUid',
            hidden: hideUserInfo,
          },
          {
            label: t`Employee name`,
            value: 'UserName',
            hidden: hideUserInfo,
          },
          {
            label: t`Employee email`,
            value: 'UserEmail',
            hidden: hideUserInfo,
          },
          {
            label: t`Label`,
            value: 'Label',
          },
          {
            label: t`External identity`,
            value: 'ExternalIdentity',
          },
        ],
      },
      {
        category: 'Tracker data',
        data: [
          {
            label: t`Tracker name`,
            value: 'TrackerName',
          },
          {
            label: t`Tracker employee hit`,
            value: 'TrackerUserHit',
          },
          {
            label: t`Tracker employee hit count`,
            value: 'TrackerUserHitCount',
          },
          {
            label: t`Tracker customer hit`,
            value: 'TrackerCustomerHit',
          },
          {
            label: t`Tracker customer hit count`,
            value: 'TrackerCustomerHitCount',
          },
          {
            label: t`Tracker words`,
            value: 'TrackerWord',
          },
          {
            label: t`Tracker words employee hit`,
            value: 'TrackerWordUserHits',
          },
          {
            label: t`Tracker words customer hit`,
            value: 'TrackerWordCustomerHits',
          },
          {
            label: t`Tracker tags`,
            value: 'TrackerTags',
          },
        ],
      },
      {
        category: 'Capturi AI',
        show: aiFeatures,
        data: [
          {
            label: t`Summary`,
            value: 'Summary',
          },
          {
            label: t`Key topics`,
            value: 'KeyTopic',
          },
          {
            label: t`Key sub topics`,
            value: 'KeySubTopic',
          },
          {
            label: t`Root cause`,
            value: 'RootCause',
          },
        ],
      },
      {
        category: 'Sentiment data',

        data: [
          {
            label: t`Sentiment by employee`,
            value: 'SentimentWordScoreUser',
          },
          {
            label: t`Sentiment by customer`,
            value: 'SentimentWordScoreOther',
          },
          {
            label: t`Sentiment score combined`,
            value: 'SentimentWordScoreCombined',
          },
        ],
      },
      {
        category: 'Interaction data',

        data: [
          {
            label: t`Talk ratio employee`,
            value: 'SpeakPercentUser',
          },
          {
            label: t`Talk ratio customer`,
            value: 'SpeakPercentCustomer',
          },
          {
            label: t`Speech rate employee`,
            value: 'SpeakRateUser',
          },
          {
            label: t`Speech rate customer`,
            value: 'SpeakRateCustomer',
          },
          {
            label: t`Longest monologue employee`,
            value: 'LongestMonologueUser',
          },
          {
            label: t`Longest monologue customer`,
            value: 'LongestMonologueCustomer',
          },
        ],
      },
      {
        category: 'Custom props',

        data: [
          {
            label: t`Custom prop 1`,
            value: 'customProp1',
          },
          {
            label: t`Custom prop 2`,
            value: 'customProp2',
          },
          {
            label: t`Custom prop 3`,
            value: 'customProp3',
          },
          {
            label: t`Custom prop 4`,
            value: 'customProp4',
          },
          {
            label: t`Custom prop 5`,
            value: 'customProp5',
          },
          {
            label: t`Custom prop 6`,
            value: 'customProp6',
          },
          {
            label: t`Custom prop 7`,
            value: 'customProp7',
          },
          {
            label: t`Custom prop 8`,
            value: 'customProp8',
          },
          {
            label: t`Custom prop 9`,
            value: 'customProp9',
          },
          {
            label: t`Custom prop 10`,
            value: 'customProp10',
          },
        ],
      },
      {
        category: 'Custom number props',

        data: [
          {
            label: t`Custom number prop 1`,
            value: 'customNumberProp1',
          },
          {
            label: t`Custom number prop 2`,
            value: 'customNumberProp2',
          },
          {
            label: t`Custom number prop 3`,
            value: 'customNumberProp3',
          },
          {
            label: t`Custom number prop 4`,
            value: 'customNumberProp4',
          },
          {
            label: t`Custom number prop 5`,
            value: 'customNumberProp5',
          },
          {
            label: t`Custom number prop 6`,
            value: 'customNumberProp6',
          },
          {
            label: t`Custom number prop 7`,
            value: 'customNumberProp7',
          },
          {
            label: t`Custom number prop 8`,
            value: 'customNumberProp8',
          },
          {
            label: t`Custom number prop 9`,
            value: 'customNumberProp9',
          },
          {
            label: t`Custom number prop 10`,
            value: 'customNumberProp10',
          },
        ],
      },
    ]
  }, [aiFeatures, hideUserInfo])
  return {
    CsvConfigData,
  }
}
