import { useCurrentUser } from '@capturi/core'
import {
  FilterPeriodSelectContainer,
  SingleSegmentContainer,
  useFilterDefinitions,
} from '@capturi/filters'
import { PageHeading } from '@capturi/ui-components'
import { Box, Flex, HStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

import { SummaryOverview } from './Components/SummaryOverview'

const SummaryPage: React.FC = () => {
  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  return (
    <Flex
      className="conversations-container"
      flexDir="column"
      h="100%"
      overflowY="auto"
      w="100%"
    >
      <Box mb={4}>
        <Flex align="center" justify="space-between" mb={6}>
          <PageHeading>
            <Trans>Summaries</Trans>
          </PageHeading>
          <HStack>
            <FilterPeriodSelectContainer />
          </HStack>
        </Flex>
        <SingleSegmentContainer
          disabledChannels={['email']}
          filterDefinitions={filterDefinitions}
        />
      </Box>
      <Flex flexDir="column" gap={3} w="100%" overflowY="hidden" h="100%">
        <SummaryOverview />
      </Flex>
    </Flex>
  )
}

export default SummaryPage
