import { Heading, TextProps } from '@chakra-ui/react'
import React from 'react'

const ChartMessage: React.FC<TextProps> = ({ children, ...props }) => {
  return (
    <Heading
      color="blackAlpha.400"
      textAlign="center"
      fontWeight="medium"
      {...props}
    >
      {children}
    </Heading>
  )
}

export default ChartMessage
