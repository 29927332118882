import { RequestOptions } from '@capturi/request'
import { CreateCommentPayload } from './models'

const DEFAULT_API_VERSION = '3.3'

const controller = 'conversations'
const baseQuery = {
  'api-version': DEFAULT_API_VERSION,
}

export const commentsAPI = {
  getRecipients: (conversationUid: string): RequestOptions => ({
    url: `${controller}/${conversationUid}/comment_recipients`,
    query: baseQuery,
  }),
  getComments: (conversationUid: string): RequestOptions => ({
    url: `${controller}/${conversationUid}/comments`,
    query: baseQuery,
  }),
  getAllComments: (userUid: string): RequestOptions => ({
    url: `comments/for-user/${userUid}`,
    query: baseQuery,
  }),
  addComment: (
    conversationUid: string,
    comment: CreateCommentPayload,
  ): RequestOptions => ({
    url: `${controller}/${conversationUid}/comment`,
    method: 'post',
    json: comment,
    query: baseQuery,
  }),
}
