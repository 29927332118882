import React from 'react'

export const trendsRootPath = '/insights/trends'

type UseTrendsRoutes = {
  liveTrends: (
    sourceId?: string,
    topicIndex?: number,
    trends?: boolean,
    conversations?: boolean,
  ) => string

  segmentTrends: (
    sourceId?: string,
    topicIndex?: number,
    trends?: boolean,
    conversations?: boolean,
  ) => string
}

function useTrendsRoutes(): UseTrendsRoutes {
  return React.useMemo(
    () => ({
      liveTrends: (
        sourceId?: string,
        topicIndex?: number,
        trends?: boolean,
        conversations?: boolean,
      ): string => {
        let path = `${trendsRootPath}/live`
        if (sourceId !== undefined) {
          path += `/${sourceId}`
        }
        if (topicIndex !== undefined) {
          path += `/topics/${topicIndex}`
        }
        if (trends) {
          path += '/trends'
        }
        if (conversations) {
          path += '/conversations'
        }
        return path
      },
      segmentTrends: (
        sourceId?: string,
        topicIndex?: number,
        trends?: boolean,
        conversations?: boolean,
      ): string => {
        let path = `${trendsRootPath}/segment`
        if (sourceId !== undefined) {
          path += `/${sourceId}`
        }
        if (topicIndex !== undefined) {
          path += `/topics/${topicIndex}`
        }
        if (trends) {
          path += '/trends'
        }
        if (conversations) {
          path += '/conversations'
        }
        return path
      },
    }),
    [],
  )
}

export default useTrendsRoutes
