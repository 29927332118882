import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import { useTheme } from '@capturi/ui-theme'

import React from 'react'

import { motion } from 'framer-motion'
import { MdArrowDropDown, MdArrowDropUp, MdCampaign } from 'react-icons/md'
import { useMeasure } from 'react-use'

type Props = {
  expectedAmount: number
  actualAmount: number
  amountVsExpectedDiff: number
  significant: boolean
}

const topPadding = 18
const bottomPadding = 21
const centralElementShadow = {
  filter:
    'drop-shadow(0px 15px 4px rgba(0, 0, 0, 0.00)) drop-shadow(0px 10px 4px rgba(0, 0, 0, 0.01)) drop-shadow(0px 5px 3px rgba(0, 0, 0, 0.05)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.09)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.10))',
}

const createBezierPath = (
  width: number,
  height: number,
  expectedBarTop: number,
  actualBarTop: number,
) => {
  const startX = width * 0.33 - 3
  const endX = width * 0.67 + 3
  return `M ${startX},${expectedBarTop} C ${(startX + endX) / 2},${expectedBarTop} ${
    (startX + endX) / 2
  },${actualBarTop} ${endX},${actualBarTop} V ${height - bottomPadding} H ${startX} Z`
}

export const ExpectedVsActualBarChart: React.FC<Props> = ({
  expectedAmount,
  actualAmount,
  amountVsExpectedDiff,
  significant,
}) => {
  const theme = useTheme()

  const [ref, { width, height }] = useMeasure<HTMLDivElement>()

  // Determine the maximum value to normalize the bar heights
  const maxValue = Math.max(expectedAmount, actualAmount)

  // Calculate the available height for the bars (excluding top and bottom padding)
  const availableHeightPx = Math.max(height - topPadding - bottomPadding, 0)
  const expectedHeightPercentage = maxValue > 0 ? expectedAmount / maxValue : 0
  const actualHeightPercentage = maxValue > 0 ? actualAmount / maxValue : 0

  // Calculate the top positions of the bars for rendering
  const expectedBarTopPx =
    topPadding + availableHeightPx * (1 - expectedHeightPercentage)
  const actualBarTopPx =
    topPadding + availableHeightPx * (1 - actualHeightPercentage)
  const expectedBarHeightPx = availableHeightPx * expectedHeightPercentage
  const actualBarHeightPx = availableHeightPx * actualHeightPercentage

  const bezierPath = createBezierPath(
    width,
    height,
    expectedBarTopPx,
    actualBarTopPx,
  )

  if (expectedAmount === 0 && actualAmount === 0) {
    return null
  }

  return (
    <Box ref={ref} position="relative" w="100%" h="100%">
      <svg width="100%" height="100%">
        <motion.g layout>
          {/* Line between bars */}
          <motion.path
            d={bezierPath}
            fill={theme.colors.segments.primary[500]}
            stroke="none"
            opacity={0.25}
          />
          {/* Bars for expected and actual values */}
          <motion.rect
            id="expected-bar"
            initial={{
              y: expectedBarTopPx,
              height: expectedBarHeightPx,
            }}
            animate={{
              y: expectedBarTopPx,
              height: expectedBarHeightPx,
            }}
            transition={{
              duration: 0.1,
              ease: 'easeOut',
            }}
            x="0"
            width="33%"
            fill={theme.colors.segments.primary[500]}
            rx="3"
            ry="3"
          />
          <motion.rect
            id="actual-bar"
            initial={{
              y: actualBarTopPx,
              height: actualBarHeightPx,
            }}
            animate={{
              y: actualBarTopPx,
              height: actualBarHeightPx,
            }}
            transition={{
              duration: 0.1,
              ease: 'easeOut',
            }}
            x="67%"
            width="33%"
            fill={theme.colors.segments.primary[500]}
            rx="3"
            ry="3"
          />
        </motion.g>
      </svg>

      {/* Annotations */}
      <motion.div
        initial={{ top: expectedBarTopPx - topPadding }}
        animate={{ top: expectedBarTopPx - topPadding }}
        transition={{ duration: 0.1, ease: 'easeOut' }}
        style={{
          position: 'absolute',
          left: '0%',
          right: '67%',
          fontSize: '14px',
          fontWeight: '500',
          textAlign: 'center',
        }}
      >
        {expectedAmount}
      </motion.div>
      <Text
        position="absolute"
        left="0%"
        right="67%"
        bottom="0"
        textColor="gray.600"
        fontSize="xs"
        textAlign="center"
      >
        <Trans>Expected</Trans>
      </Text>
      <motion.div
        initial={{ top: actualBarTopPx - topPadding }}
        animate={{ top: actualBarTopPx - topPadding }}
        transition={{ duration: 0.1, ease: 'easeOut' }}
        style={{
          position: 'absolute',
          left: '67%',
          right: '0%',
          fontSize: '14px',
          fontWeight: '500',
          textAlign: 'center',
        }}
      >
        {actualAmount}
      </motion.div>
      <Text
        position="absolute"
        left="67%"
        right="0%"
        bottom="0"
        textColor="gray.600"
        fontSize="xs"
        textAlign="center"
      >
        <Trans>Actual</Trans>
      </Text>

      {/* Central element: change annotation */}
      <Flex
        alignItems="center"
        justifyContent="center"
        position="absolute"
        left="33%"
        right="33%"
        top="50%"
        transform="translateY(-50%)"
        fontSize="md"
        fontWeight="medium"
        color="gray.800"
      >
        {amountVsExpectedDiff > 0 ? (
          <Icon as={MdArrowDropUp} sx={centralElementShadow} />
        ) : null}
        {amountVsExpectedDiff < 0 ? (
          <Icon as={MdArrowDropDown} sx={centralElementShadow} />
        ) : null}
        <Text sx={centralElementShadow}>{Math.abs(amountVsExpectedDiff)}</Text>
        {significant && (
          <Icon as={MdCampaign} ml={1} sx={centralElementShadow} />
        )}
      </Flex>
    </Box>
  )
}
