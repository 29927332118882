import { mode } from '@chakra-ui/theme-tools'
// biome-ignore lint/suspicious/noExplicitAny: legacy
type Dict = Record<string, any>

function variantEnclosedColored(props: Dict): Dict {
  const { colorScheme: c } = props
  return {
    tab: {
      _selected: {
        color: mode(`${c}.500`, `${c}.300`)(props),
      },
    },
  }
}

/*
  `enclosed-colored-vertical` is basically the built-in `enclosed-colored` but
  with a few adjustments for the vertical layout.
*/
function variantEnclosedColoredVertical(props: Dict): Dict {
  const { colorScheme: c, theme } = props
  const baseProps = theme.components.Tabs.variants['enclosed-colored'](props)
  return {
    ...baseProps,
    tab: {
      ...baseProps.tab,
      flexDirection: 'column',
      alignItems: 'flex-start',
      textAlign: 'left',
      mb: undefined,
      _notLast: {
        borderBottom: 0,
      },
      _selected: {
        ...baseProps.tab._selected,
        color: mode(`${c}.500`, `${c}.300`)(props),
        boxShadow: 'inset 1px 0 0 0 currentcolor',
        borderLeftColor: 'currentcolor',
        borderTopColor: 'inherit',
      },
    },
  }
}

function variantBordered(props: Dict): Dict {
  const { theme } = props
  const baseProps = theme.components.Tabs.variants['enclosed-colored'](props)
  return {
    ...baseProps,
    tabpanels: {
      border: '1px solid',
      borderTop: '0px',
      borderColor: 'inherit',
    },
  }
}

export default {
  variants: {
    'enclosed-colored': variantEnclosedColored,
    'enclosed-colored-vertical': variantEnclosedColoredVertical,
    'enclosed-colored-bordered': variantBordered,
  },
  defaultProps: {
    variant: 'enclosed-colored',
    colorScheme: 'primary',
  },
}
