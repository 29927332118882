import request from '@capturi/request'
import { useQueries } from '@tanstack/react-query'
import { DEFAULT_FLAGS, Flags } from './flags'

export type OrganizationSettings = {
  ai: {
    enabled: boolean
  }
}
//Flags controlled by the scim config
type ScimFlags = {
  disableUserConfig: boolean
  hideInviteOptions: boolean
}
type AllFlags = Flags & { aiFeatures: boolean }
type FeatureFlagsResponse = AllFlags &
  ScimFlags & {
    isInitialLoading: boolean
  }

export const useFeatureFlags = (): FeatureFlagsResponse => {
  const queriesResults = useQueries({
    queries: [
      {
        queryKey: ['organizationFeatures'],
        queryFn: () =>
          request.get<Flags & ScimFlags>(
            'authentication/organization/features',
          ),
        retryOnMount: false,
        retry: false,
        staleTime: 1000 * 60 * 60, // 1 hour
      },
      {
        queryKey: ['organizationSettings'],
        queryFn: () =>
          request.get<OrganizationSettings>(
            'organization/settings?api-version=3.3',
          ),
        retry: false,
        retryOnMount: false,
        staleTime: 1000 * 60 * 60, // 1 hour
      },
    ],
  })

  const { data: featureFlagsData, isLoading: isLoadingFeatureFlagsData } =
    queriesResults[0]
  const {
    data: organizationSettingsData,
    isLoading: isLoadingOrganizationSettingsData,
  } = queriesResults[1]

  const isLoading =
    isLoadingFeatureFlagsData || isLoadingOrganizationSettingsData

  const aiFeatures = !!organizationSettingsData?.ai.enabled

  return {
    disableUserConfig: false, //Flag controlled by the scim config
    hideInviteOptions: false, //Flag controlled by the scim config
    ...DEFAULT_FLAGS,
    ...featureFlagsData,
    aiFeatures,
    isInitialLoading: isLoading,
  }
}
