import { Acl } from '@capturi/core'
import { HStack } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { useAcl } from '../hooks/useAcl'
import { localizePluralGrantedRole } from '../utils'

import { Accumulator, TagsTooltipPlural } from './TagsTooltipPlural'
import { UserPermissionsSection } from './UserPermissionsSection'

const maxEntriesCount = 5

export const UserPermissionsList: React.FC<{
  acl: Acl | null | undefined
  isAccordion: boolean
  children?: React.ReactNode
}> = ({ acl, isAccordion, children }) => {
  const { getUserByUid, getTeamByUid } = useAcl()

  const initial: Accumulator = { Edit: [], View: [], Block: [] }
  const accessLevelToEntryName = acl?.reduce((acc, entry) => {
    const existing = acc[entry.level]
    if (entry.type === 'user') {
      const userName = getUserByUid(entry.userUid)?.name
      if (userName) existing.push(userName)
    } else if (entry.type === 'team') {
      const teamName = getTeamByUid(entry.teamUid)?.name
      if (teamName) existing.push(teamName)
    } else if (entry.type === 'role') {
      existing.push(localizePluralGrantedRole(entry.role))
    }
    return acc
  }, initial)

  if (!accessLevelToEntryName) return null

  if (isAccordion) {
    return (
      <HStack gap={1} alignItems="center" flexWrap="wrap" maxW="360px">
        <TagsTooltipPlural entries={accessLevelToEntryName}>
          {children}
        </TagsTooltipPlural>
      </HStack>
    )
  }

  return (
    <>
      <UserPermissionsSection
        entries={accessLevelToEntryName.Edit}
        title={t`Edit`}
        collapsedEntries={accessLevelToEntryName.Edit.splice(maxEntriesCount)}
      />
      <UserPermissionsSection
        entries={accessLevelToEntryName.View}
        title={t`See`}
        collapsedEntries={accessLevelToEntryName.View.splice(maxEntriesCount)}
      />
      <UserPermissionsSection
        entries={accessLevelToEntryName.Block}
        title={t`Can't see`}
        collapsedEntries={accessLevelToEntryName.Block.splice(maxEntriesCount)}
      />
    </>
  )
}
