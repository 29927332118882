import { Trans, t } from '@lingui/macro'
import React from 'react'
export const duplicatePhraseTooltipMsg = (): string =>
  t`This phrase occurs more than once. Remove duplicates before you can save.`
export const duplicatePhraseErrorMsg = (): string =>
  t`The tracker has a phrase which occurs multiple times. Remove all duplicates before you can save your changes.`
export const duplicateEmailPhraseErrorMsg = (): string =>
  t`The tracker has an e-mail phrase which occurs multiple times. Remove all duplicates before you can save your changes.`
export const orphanedQuoteTooltipMsg = (): string =>
  t`This phrase has an orphaned quote. Remove it before you can save.`
export const orphanedQuoteErrorMsg = (): string =>
  t`The tracker has a phrase with an orphaned quote. Remove all orphaned quotes before you can save your changes.`
export const hasUnknownWordsTooltipMsg = (): string =>
  t`You have added a new word to our dictionary. We will update our speech recognition engine as soon as possible so the tracker will be able to recognize the word in your conversations.`

export const phraseIsTooShortTooltipMsg = (): React.ReactNode => (
  <Trans>
    You have added a short word.
    <br />
    Capturi can have difficulty recognizing certain very short words correctly.
    <br />
    It is recommended instead to make whole sentences or clarify the context
    under &quot;Advanced view&quot;.
    <br />
    Click on &quot;Templates and tips for trackers&quot; for further assistance.
  </Trans>
)
