import { RefObject, useEffect, useState } from 'react'

/**
 * Based on useHoverDirty from react-use but using mouseenter/mouseleave instead of
 * mouseover/mouseout due over/out event bubbling
 *
 * Problem with useHoverDirty:
 * If hover sensoring is set up on a parent element then once hovering over a child element
 * the parent will no longer be regarded as being hovered over. This is due to mouseover/mouseout
 * works, see: https://javascript.info/mousemove-mouseover-mouseout-mouseenter-mouseleave#mouseout-when-leaving-for-a-child
 *
 * TODO: consider a different name for this hook to differentiate between enter/leave and over/out hovering
 * or make this hook configurable to work with either.
 *
 * https://github.com/streamich/react-use/blob/master/src/useHoverDirty.ts
 */

export const useHover = (ref: RefObject<Element>, enabled = true): boolean => {
  const [value, setValue] = useState(false)

  useEffect(() => {
    const onMouseEnter = (): void => setValue(true)
    const onMouseLeave = (): void => setValue(false)

    if (enabled && ref && ref.current) {
      ref.current.addEventListener('mouseenter', onMouseEnter)
      ref.current.addEventListener('mouseleave', onMouseLeave)
    }

    // fixes react-hooks/exhaustive-deps warning about stale ref elements
    const { current } = ref

    return () => {
      if (enabled && current) {
        current.removeEventListener('mouseenter', onMouseEnter)
        current.removeEventListener('mouseleave', onMouseLeave)
      }
    }
  }, [enabled, ref])

  return value
}
