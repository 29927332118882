import { MessageFilters, MessageTypes } from '@capturi/api-trackers'
import { FormLabel } from '@capturi/ui-components'
import {
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  Icon,
  Stack,
  Text,
  chakra,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useEffect, useState } from 'react'
import {
  MdCallMissedOutgoing,
  MdDownload,
  MdMail,
  MdUpload,
} from 'react-icons/md'

export const CaseMessageTypeField: React.FC<{
  messageFilters: MessageFilters | null
  onMessageFiltersChange: (messageFilters: MessageFilters | null) => void
}> = ({ messageFilters, onMessageFiltersChange }) => {
  const [state, setState] = useState<Set<MessageTypes>>(
    new Set(messageFilters?.messageTypes),
  )

  // When pasting tracker from clipboard, we need to re-set the state
  useEffect(() => {
    setState(new Set(messageFilters?.messageTypes))
  }, [messageFilters?.messageTypes])

  const handleUpdateMessageTypes = (
    value: MessageTypes,
    isChecked: boolean,
  ): void => {
    if (isChecked) {
      state.add(value)
    } else {
      state.delete(value)
    }
    onMessageFiltersChange(
      messageFilters?.messageTypes
        ? { ...messageFilters, messageTypes: [...state] }
        : null,
    )
  }

  return (
    <FormControl>
      <FormLabel htmlFor="tracker_text_MessageTypes" leftIcon={<MdMail />}>
        <Trans>Mentioned in</Trans>
      </FormLabel>
      <CheckboxGroup>
        <Stack mt={4} spacing={[1, 5]} direction={['column', 'row']}>
          <Checkbox
            isChecked={state.has('Inbound')}
            onChange={(e) => {
              handleUpdateMessageTypes('Inbound', e.target.checked)
            }}
          >
            <Flex alignItems="center">
              <Icon mr={1} boxSize={4} as={MdDownload} />
              <Text>
                <Trans>
                  Inbound <chakra.span color="gray.600">(customer)</chakra.span>
                </Trans>
              </Text>
            </Flex>
          </Checkbox>
          <Checkbox
            isChecked={state.has('Outbound')}
            onChange={(e) => {
              handleUpdateMessageTypes('Outbound', e.target.checked)
            }}
          >
            <Flex alignItems="center">
              <Icon mr={1} boxSize={4} as={MdUpload} />
              <Text>
                <Trans>Outbound </Trans>
                <chakra.span color="gray.600">
                  <Trans> (employee)</Trans>
                </chakra.span>
              </Text>
            </Flex>
          </Checkbox>
          <Checkbox
            isChecked={state.has('AutoReply')}
            onChange={(e) => {
              handleUpdateMessageTypes('AutoReply', e.target.checked)
            }}
          >
            <Flex alignItems="center">
              <Icon mr={1} boxSize={4} as={MdCallMissedOutgoing} />
              <Trans>Auto replies</Trans>
            </Flex>
          </Checkbox>
        </Stack>
      </CheckboxGroup>
    </FormControl>
  )
}
