import { useCurrentUser } from '@capturi/core'
import { generateImageUrl } from '@capturi/request'
import { Avatar, Button, ButtonProps } from '@chakra-ui/react'
import { css } from '@emotion/react'
import React from 'react'

const NotificationsButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function NotificationsButton({ children, ...props }, ref) {
    const user = useCurrentUser({ suspense: false })
    return (
      <Button
        ref={ref}
        height="48px"
        p="0"
        borderRadius="full"
        size="xs"
        fontSize="12px"
        bg={'gray.300'}
        color={'gray.800'}
        boxShadow="2xl"
        _hover={{
          bg: 'gray.400',
        }}
        _active={{
          bg: 'gray.500',
        }}
        css={css`
          background-clip: padding-box;
          svg {
            display: block;
          }
          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -2px;
            border-radius: inherit;
            background: linear-gradient(
              180deg,
              #ef3a42 0%,
              #ffb220 49%,
              #ff91b3 100%
            );
          }
        `}
        {...props}
      >
        <Avatar
          bg="workspace.sidebar.lightbg"
          color="gray.800"
          size="md"
          src={generateImageUrl(user.profileImage?.key, { size: 64 })}
          name={user.name}
        >
          {children}
        </Avatar>
        {children}
      </Button>
    )
  },
)

export default NotificationsButton
