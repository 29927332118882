import { mode } from '@chakra-ui/theme-tools'
// biome-ignore lint/suspicious/noExplicitAny: legacy
type Dict = Record<string, any>

const baseStyleList = (props: Dict): Dict => {
  return {
    boxShadow: mode('lg', 'dark-lg')(props),
    borderColor: 'gray.400',
  }
}

export default {
  parts: ['groupTitle'],
  baseStyle: (props: Dict): Dict => ({
    groupTitle: {
      color: 'textMuted',
    },
    list: baseStyleList(props),
  }),
}
