import { SentimentSpeaker } from '@capturi/api-sentiment'
import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import {
  FilterPeriodSelectContainer,
  MultiSegmentContainer,
  SegmentFilterKeysProvider,
  useFilterDefinitions,
} from '@capturi/filters'
import { usePageTitle } from '@capturi/react-utils'
import { useOrganization } from '@capturi/stores'
import {
  PageHeading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@capturi/ui-components'
import { Box, Flex } from '@chakra-ui/react'
import { Trans, select, t } from '@lingui/macro'
import React from 'react'

import useSpeakerTabsState from '../shared/hooks/useSpeakerTabsState'
import SpeakerContext from './contexts/SpeakerContext'
import { Event, logEvent } from './events'
import { SpeakerProvider, useSpeakerContext } from './hooks/useSpeakerContext'

const DataTabs = React.lazy(() => import('./components/DataTabs'))

type SpeakerTab = {
  label: string
  value: Exclude<SentimentSpeaker, 'Combined'>
}

const SentimentPage: React.FC = () => {
  const [speaker, setSpeaker] = useSpeakerContext()
  const { organizationType } = useOrganization()
  usePageTitle(t`Sentiment`)
  const {
    index,
    getSpeaker,
    speakers: speakerTabs,
  } = useSpeakerTabsState((): SpeakerTab[] => {
    return [
      {
        label: select(organizationType, {
          public: 'Citizen',
          other: 'Customer',
        }),
        value: 'Other',
      },
      {
        label: t`Employee`,
        value: 'User',
      },
    ]
  }, speaker)

  const onTabClicked = (speaker: SentimentSpeaker): void => {
    logEvent(Event.SpeakerTabClicked, speaker)
  }

  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)

  return (
    <SegmentFilterKeysProvider>
      <Box>
        <Flex align="start" justify="space-between">
          <PageHeading data-stonly="sentiment_page--title">
            <Trans>Sentiment</Trans>
          </PageHeading>
          <FilterPeriodSelectContainer />
        </Flex>
        <Box my={8} mb={4}>
          <MultiSegmentContainer filterDefinitions={filterDefinitions} />
        </Box>
        <Tabs
          index={index}
          onChange={(index) => setSpeaker(getSpeaker(index).value)}
          isLazy
        >
          <TabList>
            {speakerTabs.map((x) => (
              <Tab key={x.value} onClick={() => onTabClicked(x.value)}>
                {x.label}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {speakerTabs.map((x) => (
              <TabPanel key={x.value} px={0}>
                <SpeakerContext.Provider value={x.value}>
                  <DataTabs />
                </SpeakerContext.Provider>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </SegmentFilterKeysProvider>
  )
}

export default function SentimentPageWrapper(): React.ReactElement {
  const { employeeAsDefaultSpeakerTab } = useFeatureFlags()

  const getInitialSpeakerValue = (
    speakerFromUrl?: SentimentSpeaker,
  ): Exclude<SentimentSpeaker, 'Combined'> => {
    if (speakerFromUrl && speakerFromUrl !== 'Combined') {
      return speakerFromUrl
    }
    return employeeAsDefaultSpeakerTab ? 'User' : 'Other'
  }

  return (
    <SpeakerProvider initialValue={getInitialSpeakerValue}>
      <SentimentPage />
    </SpeakerProvider>
  )
}
