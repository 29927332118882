import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  Skeleton,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import React from 'react'
import { MdMoreVert } from 'react-icons/md'

const HeaderSkeleton: React.FC = () => {
  return (
    <Flex justifyContent="space-between" width="100%">
      <Box flex={1}>
        <Box>
          <Skeleton borderRadius="md" h={5} w="13%" />
          <Skeleton borderRadius="md" h={6} w="45%" />
        </Box>
        <Wrap spacing={1} mt={2} maxW="70%">
          <WrapItem>
            <Skeleton borderRadius="md" h={5} w={14} />
          </WrapItem>
          <WrapItem>
            <Skeleton borderRadius="md" h={5} w={20} />
          </WrapItem>
          <WrapItem>
            <Skeleton borderRadius="md" h={5} w={16} />
          </WrapItem>
          <WrapItem>
            <Skeleton borderRadius="md" h={5} w={20} />
          </WrapItem>
        </Wrap>
      </Box>
      <Box flex="0 0 auto">
        <Flex direction="row" alignItems="center" gap={2}>
          <Flex direction="column" align="end" gap={2.5}>
            <Skeleton borderRadius="md" h={2} w={20} mr={2} />
            <Flex align="center" justify="flex-end">
              <Skeleton borderRadius="md" h={5} w={44} mr={2} />
            </Flex>
          </Flex>

          <Menu placement="bottom-end">
            <MenuButton
              color="gray.600"
              disabled={true}
              icon={<MdMoreVert />}
              as={IconButton}
              variant="ghost"
            />
          </Menu>
        </Flex>
      </Box>
    </Flex>
  )
}

export default HeaderSkeleton
