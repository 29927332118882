import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerProps,
  Flex,
} from '@chakra-ui/react'
import React from 'react'

import CaseDetails from '../../components/CaseDetails'

type DrawerOptions = {
  caseUid: string
  trackerUid?: string
  messageUid?: string
}

export type CaseDrawerContainerProps = DrawerOptions &
  Omit<DrawerProps, 'children'>

const HeaderWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      alignItems="start"
      borderBottom="1px solid"
      borderColor="gray.200"
      justifyContent="space-between"
      gap={4}
      p={2}
      pb={8}
      flex="0 1 auto"
    >
      {children}
      <Divider orientation="vertical" h={10} borderLeftWidth="2px" />
      <DrawerCloseButton position="static" mt={1} />
    </Flex>
  )
}

export const CaseDetailsDrawer: React.FC<CaseDrawerContainerProps> = ({
  caseUid,
  trackerUid,
  messageUid,
  isOpen,
  onClose,
}) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="xl">
      <DrawerContent
        // Fixes bug when scrolling with mouse wheel on second level drawer did not work
        onWheel={(e) => {
          e.stopPropagation()
        }}
        onTouchMove={(e) => {
          e.stopPropagation()
        }}
      >
        <DrawerBody overflow="hidden" p={2} pb={0}>
          <CaseDetails
            caseUid={caseUid}
            onCaseDeleted={onClose}
            initialTrackerUid={trackerUid}
            messageUidToScrollTo={messageUid}
            HeaderWrapper={HeaderWrapper}
            separateHeader={true}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
