import React, { MutableRefObject } from 'react'
import { GroupBase, SelectInstance, mergeStyles } from 'react-select'
import Creatable, { CreatableProps } from 'react-select/creatable'

import { componentOverrides } from './Select'
import { customStyles, customTheme } from './customTheme'

export type CreatableSelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> = CreatableProps<Option, IsMulti, Group>

export const CreatableSelect = React.forwardRef(
  <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
    props: CreatableSelectProps<Option, IsMulti, Group> & {
      mRef?:
        | ((instance: SelectInstance<Option, IsMulti, Group> | null) => void)
        | MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
        | null
    },
    _ref: React.Ref<SelectInstance<Option, IsMulti, Group>>,
  ) => {
    const { styles, components: componentsProps = {}, ...restProps } = props

    return (
      <Creatable
        ref={props.mRef}
        components={{
          ...componentOverrides,
          ...componentsProps,
        }}
        styles={mergeStyles(styles ?? {}, customStyles())}
        theme={customTheme}
        closeMenuOnSelect={!props.isMulti}
        {...restProps}
      />
    )
  },
) as <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: CreatableSelectProps<Option, IsMulti, Group> & {
    mRef?:
      | ((instance: SelectInstance<Option, IsMulti, Group> | null) => void)
      | MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
      | null
  },
) => React.ReactElement
