import { Box, BoxProps, UseRadioProps, useRadio } from '@chakra-ui/react'
import React from 'react'

export type RadioButtonProps = {
  value?: string | number
  radioProps?: UseRadioProps
  colorScheme?: string
} & BoxProps

export const RadioButton = React.forwardRef<HTMLDivElement, RadioButtonProps>(
  function CustomRadio(
    { children, radioProps, colorScheme = 'primary', ...restProps },
    ref,
  ) {
    const { getInputProps, getCheckboxProps } = useRadio(radioProps)
    const inputProps = getInputProps()
    const checkboxProps = getCheckboxProps()
    // biome-ignore lint/suspicious/noExplicitAny: legacy
    const isChecked = (inputProps as any).checked
    return (
      <Box
        as="label"
        ref={ref}
        className={[
          'cap-radio-button',
          isChecked && 'cap-radio-button--checked',
        ]
          .filter(Boolean)
          .join(' ')}
        {...restProps}
      >
        <input {...inputProps} />
        <Box
          {...checkboxProps}
          className="cap-radio-button__control"
          display="inline-flex"
          alignItems="center"
          fontSize="sm"
          fontWeight="medium"
          cursor="pointer"
          borderRadius="md"
          h={6}
          minW={6}
          lineHeight={1.2}
          bg="white"
          border="1px"
          borderColor="gray.300"
          userSelect="none"
          _checked={{
            bg: `${colorScheme}.500`,
            borderColor: `${colorScheme}.500`,
            color: 'white',
          }}
          position="relative"
          _focus={{
            boxShadow: 'outline',
            zIndex: 1,
          }}
          px={2}
          whiteSpace="nowrap"
        >
          {children}
        </Box>
      </Box>
    )
  },
)
