export type Color =
  | 'segments.primary.500'
  | 'segments.secondary.500'
  | 'segments.tertiary.500'
  | 'segments.quaternary.500'
  | 'segments.quinary.500'

export const Colors: Color[] = [
  'segments.primary.500',
  'segments.secondary.500',
  'segments.tertiary.500',
  'segments.quaternary.500',
  'segments.quinary.500',
]

export const DefaultColor = 'segments.primary.500'
