import { ShowModal, useModal } from '@capturi/use-modal'

import {
  DrawerContainer as ConversationsDrawer,
  DrawerContainerProps,
} from './ConversationsDrawer'

export { ConversationsDrawer }

export function useConversationsDrawer(): ShowModal<DrawerContainerProps> {
  const [open] = useModal(ConversationsDrawer)
  return open
}
