import {
  TrackerFolder,
  isTrackerPrivate,
  useTrackerFolders,
} from '@capturi/api-trackers'
import { ColorLabel } from '@capturi/charts'
import { Speaker } from '@capturi/core'
import { useHover } from '@capturi/react-utils'
import { useOrganization } from '@capturi/stores'
import { FolderMenu } from '@capturi/ui-components'
import {
  Box,
  Flex,
  Icon,
  Skeleton,
  Text,
  Tooltip,
  useToken,
} from '@chakra-ui/react'
import { select, t } from '@lingui/macro'
import React, { useCallback, useRef } from 'react'
import { MdFlare, MdMoveToInbox, MdVisibilityOff } from 'react-icons/md'

import { ListItemsTracker } from '../useMapListItems'
import { formatHitRate } from '../utils'
import { CustomProgress, ListItem, Title } from './List'

type Props = {
  tracker: ListItemsTracker
  onTrackerSelected: (uid: string) => void
  isSelected: boolean
  showProgress?: boolean
  speakerTab?: number
  progressMin?: number
  progressMax?: number
  onMoveToFolder: (trackerUid: string, newFolderUid: string | null) => void
}

const getSpeaker = (
  orgType: string,
  speaker?: Speaker | null,
): string | undefined => {
  if (speaker == null) return undefined

  const speakerId = {
    '-1': t`All`,
    '1': t`Employee`,
    '0': select(orgType, {
      public: 'Citizen',
      other: 'Customer',
    }),
  }
  return speakerId[speaker]
}

const TrackerListItemContents = React.memo<{
  tracker: ListItemsTracker
  trackerName: string
  canEdit: boolean
  folders: TrackerFolder[] | undefined
  isHovering: boolean
  handleMoveToFolder: (newFolderUid: string | null) => void
  currentFolderUid: string | null | undefined
  values: { color: string; label: string; data: number }[]
  speaker: string | undefined
  isLoading: boolean
  showProgress: boolean
  isSelected: boolean
  progressMin: number
  progressMax: number
}>(
  ({
    tracker,
    trackerName,
    canEdit,
    folders,
    isHovering,
    handleMoveToFolder,
    currentFolderUid,
    values,
    speaker,
    isLoading,
    showProgress,
    isSelected,
    progressMin,
    progressMax,
  }) => {
    return (
      <>
        <Flex>
          <Flex flexGrow="1">
            <Box position="relative">
              <Flex
                gap={1}
                mr={
                  isTrackerPrivate(tracker) || tracker.isMasterTarget
                    ? '8px'
                    : '0px'
                }
              >
                {isTrackerPrivate(tracker) && (
                  <Tooltip
                    label={t`Tracker is private`}
                    hasArrow
                    placement="top"
                  >
                    <Box as="span">
                      <Icon boxSize={3} as={MdVisibilityOff} />
                    </Box>
                  </Tooltip>
                )}
                {tracker.isMasterTarget && (
                  <Tooltip
                    label={t`Tracker is a master tracker`}
                    hasArrow
                    placement="top"
                  >
                    <Box as="span">
                      <Icon boxSize={3} as={MdFlare} />
                    </Box>
                  </Tooltip>
                )}
              </Flex>
            </Box>
            <Title label={trackerName} />
          </Flex>
          {canEdit && (
            <FolderMenu
              folders={folders}
              isVisible={isHovering}
              moveToFolder={handleMoveToFolder}
              currentFolderUid={currentFolderUid}
              MenuButtonComponent={MoveToFolderMenuButton}
            />
          )}
        </Flex>
        <div>
          {values.length === 1 ? (
            <>
              <Flex align="center" justify="space-between">
                <Text fontSize="xs" color="textMuted">
                  {speaker}
                </Text>
                <Box
                  fontWeight="500"
                  fontSize="md"
                  color="gray.800"
                  minW={10}
                  textAlign="right"
                >
                  {isLoading ? (
                    <Skeleton borderRadius="md" h={4} w="100%" />
                  ) : (
                    <Box fontWeight="500" fontSize="md" as="span">
                      {formatHitRate(values[0].data)}
                    </Box>
                  )}
                </Box>
              </Flex>
              {showProgress && values[0]?.data != null && (
                <CustomProgress
                  isLoading={isLoading}
                  isSelected={isSelected}
                  min={progressMin}
                  max={progressMax}
                  value={values[0].data}
                />
              )}
            </>
          ) : (
            <Flex justify="space-between">
              <Box
                display="inline-flex"
                flexDirection="column"
                fontSize="0.875rem"
                lineHeight={1.2}
                fontWeight="500"
                color="gray.800"
              >
                {values.map((v) => (
                  <Flex
                    display="inline-flex"
                    justify="space-between"
                    align="center"
                    wrap="nowrap"
                    key={v.label}
                  >
                    <ColorLabel color={v.color} m={1} mr={2} />
                    <Skeleton isLoaded={!isLoading} fadeDuration={1}>
                      <Box fontWeight="500" fontSize="md" color="gray.800">
                        {formatHitRate(v.data)}
                      </Box>
                    </Skeleton>
                  </Flex>
                ))}
              </Box>

              <Text
                alignSelf="end"
                placeSelf="end"
                fontSize="xs"
                color="textMuted"
              >
                {speaker}
              </Text>
            </Flex>
          )}
        </div>
      </>
    )
  },
)

const TrackerListItem: React.FC<Props> = ({
  tracker,
  showProgress = false,
  progressMin = 0,
  progressMax = 1,
  isSelected,
  onTrackerSelected,
  onMoveToFolder,
}) => {
  const {
    trackerUid,
    trackerName,
    folderUid: currentFolderUid,
    isSegmentsLoading: isLoading,
  } = tracker
  const speakerValue = tracker.speech?.speakerId
  const values = tracker.segments ?? []
  const canEdit = tracker.accessLevel === 'Edit'
  const containerRef = useRef<HTMLDivElement>(null)
  const [primary] = useToken('colors', ['primary.500'])
  const { organizationType } = useOrganization()
  const isHovering = useHover(containerRef)
  const speaker = getSpeaker(organizationType, speakerValue)
  const { data: folders } = useTrackerFolders('Edit')
  const handleOnClick = useCallback(
    () => onTrackerSelected(trackerUid),
    [onTrackerSelected, trackerUid],
  )
  const handleMoveToFolder = useCallback(
    (newFolderUid: string | null) => {
      onMoveToFolder(trackerUid, newFolderUid)
    },
    [onMoveToFolder, trackerUid],
  )
  return (
    <ListItem
      isSelected={isSelected}
      onClick={handleOnClick}
      ref={containerRef}
      color={primary}
    >
      <TrackerListItemContents
        tracker={tracker}
        trackerName={trackerName}
        canEdit={canEdit}
        folders={folders}
        isHovering={isHovering}
        handleMoveToFolder={handleMoveToFolder}
        currentFolderUid={currentFolderUid}
        values={values}
        speaker={speaker}
        isLoading={isLoading}
        showProgress={showProgress}
        isSelected={isSelected}
        progressMin={progressMin}
        progressMax={progressMax}
      />
    </ListItem>
  )
}

export default React.memo(TrackerListItem)

const MoveToFolderMenuButton = React.forwardRef<
  HTMLDivElement,
  {
    onClick: () => void
    visibility: 'visible' | 'hidden'
    isVisible: boolean
  }
>(({ onClick, isVisible }, ref) => {
  const handleOnClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      onClick()
    },
    [onClick],
  )
  return (
    <Tooltip
      hasArrow
      label={t`Move tracker to folder`}
      aria-label={t`Move tracker to folder`}
      placement="top"
    >
      <Box
        ref={ref}
        transition="visibility 200ms linear,opacity 200ms linear"
        visibility={isVisible ? 'visible' : 'hidden'}
        opacity={isVisible ? 1 : 0}
      >
        <Icon
          as={MdMoveToInbox}
          aria-label={t`Move to a folder`}
          onClick={handleOnClick}
        />
      </Box>
    </Tooltip>
  )
})
