import { RepeatCall } from '@capturi/api-filters'
import { I18n, i18n as globalI18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { secondsToTimeValue } from './convertSeconds'

export const getLocalizedRepeatCallName = (
  type: RepeatCall['type'] | undefined,
  i18n: I18n = globalI18n,
): string => {
  if (type === undefined) {
    return t(i18n)`Repeat calls`
  }

  switch (type) {
    case 'Causing':
      return t(i18n)`Trigger calls`
    case 'Repeat':
      return t(i18n)`Repeat calls`
    case 'None':
      return t(i18n)`First call resolution`
  }
}

export const getLocalizedRepeatCallLabel = (
  seconds: number | undefined,
  i18n: I18n = globalI18n,
): string | undefined => {
  if (seconds === undefined) {
    return undefined
  }

  const { value, timeUnit } = secondsToTimeValue(seconds)
  switch (timeUnit) {
    case 'days':
      return value > 1
        ? t(i18n)`within ${value} days`
        : t(i18n)`within ${value} day`
    case 'hours':
      return value > 1
        ? t(i18n)`within ${value} hours`
        : t(i18n)`within ${value} hour`
  }
}
