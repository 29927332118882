import { TimeFilterKind, Tracker } from '@capturi/api-trackers'
import { Speaker } from '@capturi/core'
import merge from 'lodash/merge'

import { PhraseState, TrackerFormModel } from './types'

export const createPhraseField = (
  phraseState?: Partial<PhraseState>,
): PhraseState => {
  return {
    value: '',
    settings: {
      near: undefined,
      notNear: undefined,
      precision: undefined,
    },
    ...phraseState,
  }
}

function defaultTrackerFormModel(currentUserUid: string): TrackerFormModel {
  return {
    name: '',
    tags: [],
    description: '',
    speech: {
      phrases: [],
      speakerId: Speaker.All,
      timeFilter: {
        seconds: 30,
        secondsEnd: null,
        kind: TimeFilterKind.ENTIRECONVERSATION,
      },
    },
    text: {
      phrases: [],
      messageFilters: null,
      globalSettings: { near: [], notNear: [] },
    },
    permissionPreset: 'Private',
    acl: null,
    permissionPresetUserUid: currentUserUid,
    folderUid: null,
    masterSettings: null,
  }
}

export function createFormModel(
  currentUserUid: string,
  tracker?: Partial<Tracker>,
): TrackerFormModel {
  if (!tracker) {
    return defaultTrackerFormModel(currentUserUid)
  }

  const values: Partial<TrackerFormModel> = {
    uid: tracker.uid,
    name: tracker.name,
    tags: tracker.tags,
    description: tracker.description,
    permissionPreset: tracker.permissionPreset,
    acl: tracker.acl,
    permissionPresetUserUid: tracker.permissionPresetUserUid,
    folderUid: tracker.folderUid,
    masterSettings: {
      preset: tracker.masterSettings?.preset ?? 'Private',
      targets: Object.keys(tracker.masterSettings?.targets ?? {}),
    },
    speech: tracker.speech
      ? {
          phrases: (tracker.speech.phrases ?? []).map((x) => {
            const settings = tracker.speech
              ? tracker.speech.phrasesSettings?.[x]
              : undefined
            return {
              value: x,
              settings: {
                near: settings?.near,
                notNear: settings?.notNear,
                precision: settings?.precision,
              },
            }
          }),
          speakerId: tracker.speech.speakerId,
          timeFilter: tracker.speech.timeFilter,
        }
      : undefined,
    text: tracker.text,
  }
  const result = merge({}, defaultTrackerFormModel(currentUserUid), values)
  return result
}
