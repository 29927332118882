import {
  Box,
  useDisclosure,
  useMediaQuery,
  useOutsideClick,
  useToken,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import noop from 'lodash/noop'
import * as React from 'react'
import { useEffect, useRef } from 'react'

import SidebarContent from './SidebarContent'

const CollapsibleSidebar: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)

  const [xl] = useToken('breakpoints', ['1.5xl'])

  const [isWindowMaxWidth] = useMediaQuery(`(min-width: ${xl})`)
  const showAsOverlay = !isWindowMaxWidth

  const { isOpen, onClose, onOpen, onToggle } = useDisclosure({
    defaultIsOpen: !showAsOverlay,
  })
  useOutsideClick({
    ref: ref,
    handler: showAsOverlay ? onClose : noop,
  })

  useEffect(() => {
    if (showAsOverlay) {
      onClose()
    } else {
      onOpen()
    }
  }, [showAsOverlay, onClose, onOpen])

  return (
    <Box
      ref={ref}
      position={showAsOverlay ? 'fixed' : isOpen ? 'relative' : 'fixed'}
      height="100vh"
      bg="workspace.sidebar.lightbg"
      color="workspace.sidebar.text"
      zIndex={1}
      transform={{
        base: `translateX(${isOpen ? '0%' : '-95%'})`,
      }}
      boxShadow={{
        base: isOpen ? 'dark-lg' : 'none',
        '1.5xl': 'none',
      }}
      transition="box-shadow 200ms ease-out, transform 200ms ease-out"
      css={css`
        border-image-source: linear-gradient(
          180deg,
          rgba(239, 58, 66, 0.5) 0%,
          rgba(255, 178, 32, 0.5) 49%,
          rgba(255, 145, 179, 0.5) 100%
        );
        border-right: 2px solid;
        border-image-slice: 1;
      `}
    >
      <SidebarContent
        onClose={onClose}
        showAsOverlay={showAsOverlay}
        toggleIsSidebarOpen={onToggle}
        isSidebarOpened={isOpen}
      />
    </Box>
  )
}

export default CollapsibleSidebar
