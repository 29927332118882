import { CacheItem, phraseHitsCacheMonitor } from './cache'

import React from 'react'

export function usePhraseHitsPreview(phrase: string): CacheItem | undefined {
  const [previewData, setPreviewData] = React.useState<CacheItem>()

  const sanitizedPhrase = phrase.trim()

  React.useEffect(() => {
    if (sanitizedPhrase.length === 0) {
      setPreviewData(undefined)
    } else {
      phraseHitsCacheMonitor.attach(sanitizedPhrase, setPreviewData)
    }
    // Ensure we stop listening once the component is no longer in use
    return () => {
      phraseHitsCacheMonitor.detach(sanitizedPhrase, setPreviewData)
    }
  }, [sanitizedPhrase])

  return previewData
}
