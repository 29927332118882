import { Team, User } from '../domain'

export const compareUsers = (a: User, b: User): number => {
  const byName = a.name.trim().localeCompare(b.name.trim())
  if (byName !== 0) return byName
  return (a.role ?? '').localeCompare(b.role ?? '')
}

export const compareTeams = (a: Team, b: Team): number => {
  return a.name.localeCompare(b.name)
}
