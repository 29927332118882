import { useContext, useEffect, useState } from 'react'
import { useMountedState } from 'react-use'

import { AudioContext } from './AudioProvider'

const useAudioTime = (): [number | undefined, number] => {
  const { audioRef, audioState } = useContext(AudioContext)
  const [time, setTime] = useState<number | undefined>()
  const isMounted = useMountedState()

  useEffect(() => {
    const element = audioRef.current

    const handleTimeUpdate = (): void => {
      if (isMounted()) {
        setTime(element?.currentTime)
      }
    }

    element?.addEventListener('timeupdate', handleTimeUpdate)
    return () => {
      element?.removeEventListener('timeupdate', handleTimeUpdate)
    }
  }, [audioRef, isMounted])

  return [time, audioState.duration]
}

export default useAudioTime
