import {
  DashboardDependent,
  DependentItem,
  ScoreDependent,
  SegmentDependent,
} from '@capturi/api-shared'
import { Button, Emoji, Highlight } from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Box,
  BoxProps,
  Flex,
  Link,
  LinkProps,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { Plural, Trans } from '@lingui/macro'
import conversationRoutes from 'pages/Conversations/routes'
import dashboardRoutes from 'pages/Dashboards/routes'
import { routes as scoringRoutes } from 'pages/ScoringPage/routes'
import qs from 'query-string'
import React from 'react'

const EditLink: React.FC<LinkProps> = (props) => (
  <Link
    isExternal
    color="primary.500"
    fontWeight="medium"
    fontSize="sm"
    whiteSpace="nowrap"
    {...props}
  />
)

export const DependentsInfoDialog: React.FC<
  BaseModalComponentProps & {
    heading: string
    description: string
    dependents: DependentItem[]
  }
> = ({ heading, description, dependents, isOpen = false, onClose }) => {
  const segments = dependents.filter(
    (x): x is SegmentDependent => x.type === 'savedFilterGroup',
  )
  const dashboards = dependents.filter(
    (x): x is DashboardDependent => x.type === 'dashboard',
  )
  const scores = dependents.filter(
    (x): x is ScoreDependent => x.type === 'score',
  )
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader>
              <Emoji symbol="⚠️" mr={1} />
              {heading}
            </ModalHeader>
          </Flex>
          <ModalCloseButton />
          <ModalBody>
            <Highlight>{description}</Highlight>
            <Stack spacing={8} mt={4} color="textMuted">
              <SegmentsList segments={segments} />
              <DashboardsList dashboards={dashboards} />
              <ScoresList scores={scores} />
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button primary onClick={onClose}>
              <Trans>OK</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

const SegmentsList: React.FC<
  {
    segments: SegmentDependent[]
  } & BoxProps
> = ({ segments, ...props }) => {
  if (segments.length === 0) return null

  const generateUrl = (uid: string): string => {
    return qs.stringifyUrl({
      url: conversationRoutes.list(),
      query: {
        savedFilterUid: uid,
      },
    })
  }

  return (
    <Box {...props}>
      <Text>
        <Plural
          value={segments.length}
          one={'1 segment'}
          other={'# segments'}
        />
        :
      </Text>
      <UnorderedList spacing={2} ml={6} mt={2}>
        {segments.map((x) => (
          <ListItem key={x.uid}>
            <Flex wrap="wrap" align="center">
              <Text as="span" mr={2}>
                {x.name}
              </Text>
              <EditLink href={generateUrl(x.uid)} ml={2}>
                <Trans>Edit segment</Trans>
              </EditLink>
            </Flex>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  )
}

const DashboardsList: React.FC<
  {
    dashboards: DashboardDependent[]
  } & BoxProps
> = ({ dashboards, ...props }) => {
  if (dashboards.length === 0) return null

  const totalWidgets = dashboards.reduce((sum, d) => sum + d.widgets.length, 0)

  const widgetCount = (count: number): React.ReactElement => (
    <Plural value={count} one={'1 widget'} other={'# widgets'} />
  )

  return (
    <Box {...props}>
      <Text>
        <Plural
          value={dashboards.length}
          one={'1 dashboard'}
          other={'# dashboards'}
        />{' '}
        ({widgetCount(totalWidgets)}):
      </Text>
      <UnorderedList spacing={2} ml={6} mt={2}>
        {dashboards.map((x) => (
          <ListItem key={x.uid}>
            <Flex wrap="wrap" align="center">
              <Text as="span" mr={2}>
                {x.title} ({widgetCount(x.widgets.length)})
              </Text>
              <EditLink href={dashboardRoutes.editor(x.uid)}>
                <Trans>Edit dashboard</Trans>
              </EditLink>
            </Flex>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  )
}

const ScoresList: React.FC<
  {
    scores: ScoreDependent[]
  } & BoxProps
> = ({ scores, ...props }) => {
  if (scores.length === 0) return null
  return (
    <Box {...props}>
      <Text>
        <Plural value={scores.length} one={'1 score'} other={'# scores'} />:
      </Text>
      <UnorderedList spacing={2} ml={6} mt={2}>
        {scores.map((x) => (
          <ListItem key={x.uid}>
            <Flex wrap="wrap" align="center">
              <Text as="span" mr={2}>
                {x.name}
              </Text>
              <EditLink href={scoringRoutes.editScore(x.uid)} ml={2}>
                <Trans>Edit score</Trans>
              </EditLink>
            </Flex>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  )
}
