import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

type Props = {
  count: number
} & BoxProps

const CounterBadge: React.FC<Props> = ({ count = 0, ...boxProps }) => {
  const output = count < 10 ? `${count}` : '9+'
  const fontSize = `${count < 10 ? 0.75 : 0.625}rem`
  return (
    <Box
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
      transform="translate(20%, -20%)"
      top="0"
      right="0"
      rounded="full"
      bg="#49B7A1"
      color="white"
      borderColor="white"
      border="2px solid"
      boxSize="1.5rem"
      fontSize={fontSize}
      boxShadow="base"
      {...boxProps}
    >
      {output}
    </Box>
  )
}

export default CounterBadge
