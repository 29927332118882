import { theme } from '@capturi/ui-theme'

export default function createStyle(className: string): string {
  return `
    .${className}.rdp {
      font-size: inherit;
      margin-top: 0;
    }
    .${className} .rdp-table {
      margin-top: ${theme.space[2]};
    }
    .${className} .rdp-head_cell {
      font-weight: 400;
      font-size: 0.875em;
      color: ${theme.colors.gray[500]};
      text-transform: none;
    }
    .${className} .rdp-cell {
      width: 2.5em;
      height: 2.5em;
    }
    .${className} .rdp-day {
      width: 2.5em;
      height: 2.5em;
      border-radius: 0;
    }
    .${className} .rdp-day:focus {
      outline: 0;
    }
    .${className} .rdp-day_range_middle:not(.rdp-day_outside):not(.rdp-day_range_start):not(.rdp-day_range_end) {
      background-color: ${theme.colors.primary[50]};
      color: ${theme.colors.primary[400]};
    }
    .${className} .rdp-day_selected:not(.rdp-day_disabled):not(.rdp-day_outside) {
      background-color: ${theme.colors.primary[400]};
      color: ${theme.colors.white};
    }
    .${className} .rdp-day_selected:not(.rdp-day_disabled):not(.rdp-day_outside):hover {
      background-color:${theme.colors.primary[500]};
      color: ${theme.colors.white};
    }
    .${className}.rdp .rdp-day:not(.rdp-day_disabled):not(.rdp-day_selected):not(.rdp-day_outside):hover {
      background-color: ${theme.colors.primary[50]};
    }
    .${className} .rdp-day_today:not(.rdp-day_selected){
      color: ${theme.colors.primary[500]};
    }
  `
}
