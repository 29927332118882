import { Benchmark, Topic, TopicsNode } from './types'

export type GraphTopicsNode = {
  id: string // unique id for graph, debugging
  topicId: string // id for API
  subTopicId?: string
  label: string
  graphLabel: string

  amount: number
  benchmark?: Benchmark
  color?: string
  children?: GraphTopicsNode[]
  topics?: Topic[]
  tooltipItems?: string[]
}

type GraphData = {
  data: GraphTopicsNode | undefined
  graphNodeById: { [key: string]: GraphTopicsNode }
  totalAmount: number
}

export const mapTopicsNodeToGraphData = (
  currentTopicsData: TopicsNode | undefined,
): GraphData => {
  const graphTopicsData: GraphTopicsNode | undefined = currentTopicsData
    ? {
        ...currentTopicsData,
        graphLabel: currentTopicsData.label,
        amount: 0,
        children: currentTopicsData.children?.map((node) => ({
          ...node,
          benchmark: undefined,
          graphLabel:
            node.label.length > 15
              ? `${node.label.slice(0, 15)}...`
              : node.label,
          label: node.label,
          children: undefined,
        })),
      }
    : undefined

  const graphNodeById = Object.fromEntries(
    graphTopicsData?.children?.map((node) => [node.id, node]) ?? [],
  )

  if (
    graphTopicsData === undefined ||
    graphTopicsData?.children === undefined
  ) {
    return {
      data: undefined,
      graphNodeById,
      totalAmount: 0,
    }
  }

  return {
    data: graphTopicsData,
    graphNodeById,
    totalAmount: currentTopicsData?.amount ?? 0,
  }
}
