import { useCurrentUser } from '@capturi/core'
import { SingleSegmentContainer, useFilterDefinitions } from '@capturi/filters'
import { Highlight } from '@capturi/ui-components'
import { Box, Flex, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import getPercentage from 'pages/TrackersPage/utils/getPercentage'
import React from 'react'

import format from '../../../utils/numberFormat'
import { PreviewTrackerHitsPlayButton } from './PreviewTrackerHitsPlayButton'

export type PreviewTrackerHitsViewProps = {
  hits: number
  total: number
  isFetching: boolean
  shortTextForm?: boolean
  onViewConversations: () => void
}
export const NUMBER_OF_PHRASES = 1000

export const PreviewTrackerHitsFilter: React.FC<
  PreviewTrackerHitsViewProps
> = ({ hits, total, isFetching, onViewConversations }) => {
  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  return (
    <Box
      backgroundColor="gray.50"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="4px"
      px={4}
    >
      <Flex pb={2} alignItems="top" justifyContent="space-between">
        <Text
          position="absolute"
          right="15px"
          mb={4}
          mt={3}
          ml="2"
          color="gray.400"
        >
          <Trans>Preview</Trans>
        </Text>
        <SingleSegmentContainer
          filterDefinitions={filterDefinitions}
          showSaveButton={false}
          showNotificationButton={false}
          showExportButton={false}
          showResetButton={false}
        />
      </Flex>
      <Flex align="center" justify="flex-end" minH={6} py={2} flex-shrink="0">
        <Text fontSize="sm" textAlign="right" color="textMuted">
          <Text color="gray.800" as="span" fontWeight="bold">
            {getPercentage(hits, total)}
          </Text>{' '}
          <Trans>
            (Found in <Highlight color="text">{format(hits)}</Highlight> out of
            the {format(total)} most recent conversations)
          </Trans>
        </Text>
        <PreviewTrackerHitsPlayButton
          hits={hits}
          isFetching={isFetching}
          onViewConversations={onViewConversations}
        />
      </Flex>
    </Box>
  )
}
