import { AuthService } from '@capturi/auth'
import { Button } from '@capturi/ui-components'
import {
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { DEFAULT_LANGUAGE, useLanguage } from 'i18n'
import React, { KeyboardEvent, useState } from 'react'

const isValidEmail = (email: string): boolean =>
  /\S+@[a-zA-Z\d]*\.\S+/.test(email)

const isKnownEmailDomain = (email: string): boolean => {
  return ['duka', 'capturi'].some((d) => email.includes(d))
}

const providerMap = new Map([
  ['duka', 'okta/duka'],
  ['capturi', 'okta/duka'],
])

const getProvider = (email: string): string | null => {
  if (email.includes('duka')) return 'duka'
  if (email.includes('capturi')) return 'capturi'
  return null
}

export const EmailLogin: React.FC<{
  setKnownOrg: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setKnownOrg }) => {
  const { language = DEFAULT_LANGUAGE } = useLanguage()
  const [email, setEmail] = useState<string>('')
  const goToProvider = (): void => {
    const provider = getProvider(email)
    if (!provider) return
    const url = providerMap.get(provider)
    if (!url) return
    window.location.href = AuthService.generateAuthURL(url, language)
  }

  const validEmail = isValidEmail(email)
  const knownEmailDomain = isKnownEmailDomain(email)
  const handleEnter = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && validEmail && knownEmailDomain) goToProvider()
  }

  const handleWrongOrg = (): void => {
    if (validEmail && !knownEmailDomain) {
      setKnownOrg(false)
    } else {
      setKnownOrg(true)
    }
  }
  return (
    <>
      <Flex alignItems="center" my="8">
        <Divider />
        <Text mx="4" whiteSpace="nowrap" color="gray.400" fontWeight="medium">
          <Trans>or use your e-mail</Trans>
        </Text>
        <Divider />
      </Flex>

      <FormControl isInvalid={validEmail && !knownEmailDomain}>
        <FormLabel fontWeight="light" htmlFor="name">
          <Trans>E-mail address</Trans>
        </FormLabel>
        <Input
          onChange={(e) => setEmail(e.currentTarget.value)}
          value={email}
          placeholder="john@company.com"
          autoComplete="email"
          type="email"
          id="email"
          onKeyPress={handleEnter}
          onKeyUp={handleWrongOrg}
        />
        <FormErrorMessage color="danger" fontStyle="italic">
          <Trans>
            We could not recognize your e-mail address. Try another or login
            with Microsoft or Google instead.
          </Trans>
        </FormErrorMessage>
      </FormControl>
      <Button
        mt="4"
        width="100%"
        isDisabled={!(validEmail && knownEmailDomain)}
        onClick={goToProvider}
        size="md"
        colorScheme="petrolBlue"
      >
        <Trans>Log in</Trans>
      </Button>
    </>
  )
}
