import trackerRoutes from './trackers/routes'

export const basePath = '/insights'

type Routes = {
  main: string
  activity: string
  interaction: string
  sentiment: string
  trackers: ReturnType<typeof trackerRoutes>
}

export const getRoutes = (includeBasePath = true): Routes => {
  const prefix = includeBasePath ? basePath : ''
  return {
    main: includeBasePath ? basePath : '/',
    activity: `${prefix}/activity`,
    interaction: `${prefix}/interaction`,
    sentiment: `${prefix}/sentiment`,
    trackers: trackerRoutes(includeBasePath),
  }
}
