import { useCurrentUser } from '@capturi/core'
import {
  FilterPeriodSelectContainer,
  MultiSegmentContainer,
  useFilterDefinitions,
} from '@capturi/filters'
import { ErrorBoundary, usePageTitle } from '@capturi/react-utils'
import { PageHeading } from '@capturi/ui-components'
import { Box, Flex } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import Loader from '../shared/components/Loader'

const DataTabs = React.lazy(() => import('./DataTabs'))

const InteractionPage: React.FC = () => {
  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  usePageTitle(t`Interaction`)
  return (
    <Box>
      <Flex align="start" justify="space-between">
        <PageHeading>
          <Trans>Interaction</Trans>
        </PageHeading>
        <FilterPeriodSelectContainer />
      </Flex>
      <Box my={8}>
        <MultiSegmentContainer filterDefinitions={filterDefinitions} />
      </Box>
      <Box my={6}>
        <ErrorBoundary>
          <React.Suspense fallback={Loader}>
            <DataTabs />
          </React.Suspense>
        </ErrorBoundary>
      </Box>
    </Box>
  )
}

export default InteractionPage
