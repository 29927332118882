import { theme } from '@capturi/ui-theme'
import { getColor } from '@chakra-ui/theme-tools'
import React from 'react'

export type Theme = {
  gridColor: string
  gridTextColor: string
  referenceLineColor: string
  getPrimaryColor: (hue: string) => string
  getSecondaryColor: (hue: string) => string
}

export const defaultTheme: Theme = {
  gridColor: getColor(theme, 'blackAlpha.300'),
  gridTextColor: getColor(theme, 'blackAlpha.600'),
  referenceLineColor: getColor(theme, 'blackAlpha.700'),
  getPrimaryColor: (hue: string) => getColor(theme, `${hue}.500`),
  getSecondaryColor: (hue: string) => getColor(theme, `${hue}.300`),
}

export const ChartThemeContext = React.createContext<Theme>(defaultTheme)
export const useChartTheme = (): Theme => React.useContext(ChartThemeContext)
