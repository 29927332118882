import { formatTime } from '@capturi/audio'
import {
  Box,
  CardHeader,
  Flex,
  HStack,
  Icon,
  Progress,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import React from 'react'
import { MdAlignVerticalCenter } from 'react-icons/md'
import { Benchmark } from '../../../../pages/KeyTopics/hooks/types'
import { useBenchmarkScores } from '../../../../pages/KeyTopics/hooks/useBenchmarkScores'
import { pctFormat } from '../../../../pages/analytics/shared/utils'
import { SentimentBenchmark } from '../SentimentBenchmark'
import { DrawerBenchmarkCard } from './DrawerBenchmarkCard'

export const DrawerBenchmarksSkeleton: React.FC = React.memo(() => {
  return (
    <>
      <Skeleton
        borderRadius="md"
        minH="118px"
        shadow="none"
        border="1px solid"
        borderColor="gray.200"
        w="40%"
      />
      <Flex gap={2} flex="1">
        <Skeleton
          flex="1 1 50%"
          borderRadius="md"
          minH="118px"
          shadow="none"
          border="1px solid"
          borderColor="gray.200"
        />
        <Skeleton
          flex="1 1 50%"
          borderRadius="md"
          minH="118px"
          shadow="none"
          border="1px solid"
          borderColor="gray.200"
        />
      </Flex>
    </>
  )
})

const DrawerBenchmarks: React.FC<{
  benchmark: Benchmark
  referenceBenchmark: Benchmark
  showReferenceBenchmark: boolean
  totalConversationsPct: number
  conversationsPct: number
  conversationCount: number
}> = ({
  benchmark,
  referenceBenchmark,
  showReferenceBenchmark,
  conversationCount,
  totalConversationsPct,
  conversationsPct,
}) => {
  const formattedTime = formatTime(benchmark.avgDuration)
  const { combinedScores, sumOfScores } = useBenchmarkScores(
    benchmark.scores,
    referenceBenchmark.scores,
  )
  return (
    <>
      <DrawerBenchmarkCard width="40%">
        <HStack justifyContent="space-around">
          <Box textAlign="center">
            <CardHeader
              pl={4}
              pr={4}
              pb={0}
              pt={1}
              fontSize="sm"
              fontWeight="medium"
              color="gray.600"
            >
              <Trans>Conversations</Trans>
            </CardHeader>
            <Flex alignItems="center" justifyContent="center">
              <Text
                id="shrink-with-width"
                lineHeight="1.2"
                fontSize={formattedTime.length > 5 ? '4xl' : '5xl'}
                fontWeight="medium"
              >
                {conversationCount}
              </Text>
              <Text color="gray.600" fontSize="3xl" lineHeight="1.2" ml={1}>
                {conversationsPct
                  ? `(${i18n.number(conversationsPct / 100, pctFormat)})`
                  : ''}
              </Text>
            </Flex>
            {showReferenceBenchmark && (
              <Flex color="gray.600" align="center" justify="center">
                {totalConversationsPct > 0 && (
                  <Text align="center" fontSize="lg" ml={1}>
                    {i18n.number(totalConversationsPct / 100, pctFormat)}{' '}
                    <Trans>of total</Trans>
                  </Text>
                )}
              </Flex>
            )}
          </Box>
          <Box textAlign="center">
            <CardHeader
              pl={4}
              pr={4}
              pb={0}
              pt={1}
              fontSize="sm"
              fontWeight="medium"
              color="gray.600"
            >
              <Trans>Duration</Trans>
            </CardHeader>
            <Text
              id="shrink-with-width"
              align="center"
              lineHeight="1.2"
              fontSize={formattedTime.length > 5 ? '4xl' : '5xl'}
              fontWeight="medium"
            >
              {formattedTime}
            </Text>
            {showReferenceBenchmark && (
              <Flex color="gray.600" align="center" justify="center">
                <Icon as={MdAlignVerticalCenter} />
                <Text align="center" fontSize="lg" ml={1}>
                  {formatTime(referenceBenchmark?.avgDuration)}{' '}
                </Text>
              </Flex>
            )}
          </Box>
        </HStack>
      </DrawerBenchmarkCard>
      <Flex gap={2} flex="1">
        <DrawerBenchmarkCard bodyBottomPadding={3} flex="1 1 50%">
          <CardHeader
            pl={0}
            pr={4}
            pb={2}
            pt={0}
            fontSize="sm"
            fontWeight="medium"
            color="gray.600"
          >
            <Trans>Customer sentiment</Trans>
          </CardHeader>
          <SentimentBenchmark
            sentiment={benchmark.sentiment}
            referenceSentiment={referenceBenchmark.sentiment}
            showReferenceBenchmark={showReferenceBenchmark}
          />
        </DrawerBenchmarkCard>
        <DrawerBenchmarkCard flex="1 1 50%">
          <CardHeader
            pl={0}
            pr={4}
            pb={2}
            pt={0}
            fontSize="sm"
            fontWeight="medium"
            color="gray.600"
          >
            <Trans>Score</Trans>
          </CardHeader>
          <VStack spacing={2} align="stretch">
            {combinedScores.length > 0 ? (
              combinedScores.map((item) => (
                <Box key={item.score.uid}>
                  <Flex justifyContent="space-between">
                    <Text fontSize="sm" noOfLines={1}>
                      {item.score.name}
                    </Text>
                    <Flex
                      justifyContent="space-between"
                      flex={showReferenceBenchmark ? '0 0 65px' : 'initial'}
                    >
                      {showReferenceBenchmark && (
                        <Flex align="center">
                          <Icon as={MdAlignVerticalCenter} color="gray.600" />
                          <Text fontSize="sm" color="gray.600" ml={1}>
                            {item.rootScore.avgScore.toFixed(1)}
                          </Text>
                        </Flex>
                      )}
                      <Text fontSize="sm" fontWeight="medium">
                        {item.score.avgScore.toFixed(1)}
                      </Text>
                    </Flex>
                  </Flex>
                  <Progress
                    h="3px"
                    hasStripe
                    value={
                      sumOfScores !== 0 ? item.score.avgScore / sumOfScores : 0
                    }
                  />
                </Box>
              ))
            ) : (
              <Text fontSize="sm" fontWeight="medium">
                <Trans>No results</Trans>
              </Text>
            )}
          </VStack>
        </DrawerBenchmarkCard>
      </Flex>
    </>
  )
}

export default React.memo(DrawerBenchmarks)
