import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { ContentPlaceholder } from '@capturi/ui-components'
import React from 'react'

const EmptyWidget: React.FC<{ heading: string; body: string }> = ({
  heading,
  body,
}) => {
  return (
    <ContentPlaceholder.Container mb={12} mt={4} size="md">
      <ContentPlaceholder.Image as={Icon_EmptyState} mb={0} />
      <ContentPlaceholder.Heading fontSize="4xl">
        {heading}
      </ContentPlaceholder.Heading>
      <ContentPlaceholder.Body fontSize="lg" w="full" maxW="28rem">
        {body}
      </ContentPlaceholder.Body>
    </ContentPlaceholder.Container>
  )
}

export default EmptyWidget
