import { PlacementWithLogical } from '@chakra-ui/react'
import { ReactNode, createContext, useContext } from 'react'

interface FilterMenuPlacementContextType {
  placement: PlacementWithLogical | undefined
}

const FilterMenuPlacementContext = createContext<
  FilterMenuPlacementContextType | undefined
>(undefined)

interface FilterMenuPlacementProviderProps {
  children: ReactNode
  value: PlacementWithLogical
}

export const FilterMenuPlacementProvider = ({
  children,
  value,
}: FilterMenuPlacementProviderProps) => {
  return (
    <FilterMenuPlacementContext.Provider value={{ placement: value }}>
      {children}
    </FilterMenuPlacementContext.Provider>
  )
}

export const useFilterMenuPlacementContext = ():
  | PlacementWithLogical
  | undefined => {
  const context = useContext(FilterMenuPlacementContext)
  if (context === undefined) {
    return 'bottom-start' // fallback to bottom-start if not wrapped into provider
  }
  return context.placement
}
