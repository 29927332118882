import { AuthService } from '@capturi/auth'
import { Button, ButtonProps } from '@capturi/ui-components'
import { Box, Flex, Grid, Stack, StackProps } from '@chakra-ui/react'
import { css, keyframes } from '@emotion/react'
import { Trans } from '@lingui/macro'
import { DEFAULT_LANGUAGE, useLanguage } from 'i18n'
import React from 'react'

import GoogleLogoIcon from './images/Google_logo.svg'
import MicrosoftLogoIcon from './images/Microsoft_icon.svg'

type ProviderButtonProps = ButtonProps & {
  href: string
  icon: React.ReactElement
}

export const ProviderButton: React.FC<ProviderButtonProps> = ({
  children,
  icon,
  ...props
}) => {
  return (
    <Button
      as="a"
      size="md"
      textDecoration="none"
      px={0}
      variant="outline"
      background="white"
      zIndex="1"
      {...props}
    >
      <Grid
        templateColumns="40px 1fr 40px"
        w="100%"
        h="100%"
        background="white"
        borderRadius="4px"
      >
        <Flex align="center" justify="center">
          {icon}
        </Flex>
        <Flex align="center" justify="center">
          {children}
        </Flex>
        <Box />
      </Grid>
    </Button>
  )
}

export const ProviderButtons: React.FC<StackProps & { knownOrg: boolean }> = (
  props,
) => {
  const { language = DEFAULT_LANGUAGE } = useLanguage()

  const steam = keyframes`
  0% { transform: scale(0.6,0.6); }
  4% { transform: scale(1.05,1.05); }
  8% { transform: scale(0.98,0.98); }
  12% { transform: scale(1.03,1.03); }
  16% { transform:  scale(1.01,1.01); }
  20% { transform:  scale(1,1); }
  `

  return (
    <Stack spacing={4} {...props}>
      <ProviderButton
        css={
          !props.knownOrg &&
          css`
            &:before {
              animation: ${steam} 4500ms ease-out 6;
              content: '';
              position: absolute;
              left: -2px;
              top: -2px;
              background: #2f96d0;
              background-size: 400%;
              width: calc(100% + 4px);
              height: calc(80% + 4px);
              z-index: -1;
              filter: blur(20px);
              opacity: 0.8;
            }
          `
        }
        href={AuthService.generateAuthURL('azure', language)}
        icon={<MicrosoftLogoIcon />}
      >
        <Trans>Continue with Microsoft</Trans>
      </ProviderButton>
      <ProviderButton
        href={AuthService.generateAuthURL('google', language)}
        icon={<GoogleLogoIcon />}
      >
        <Trans>Continue with Google</Trans>
      </ProviderButton>
    </Stack>
  )
}
