import { PhraseSettings } from '@capturi/api-trackers'
import { useOrganization } from '@capturi/stores'
import {
  Box,
  Flex,
  Heading,
  PopoverArrow,
  PopoverContent,
  Tag,
  Text,
} from '@chakra-ui/react'
import { Trans, select, t } from '@lingui/macro'
import React, { useMemo } from 'react'

const NearnessSection: React.FC<{
  speaker: string
  isNear?: boolean
  activity: string
  phrases: string[]
}> = ({ speaker, activity, phrases, isNear }) => {
  const sortedPhrases = useMemo(() => [...phrases].sort(), [phrases])
  return (
    <Box my="4">
      <Text fontWeight="normal">
        {isNear ? (
          <Trans>
            Words / sentences{' '}
            <Text as="span" fontWeight="medium">
              must
            </Text>{' '}
            be said in the vicinity of at least one of these
          </Trans>
        ) : (
          <Trans>
            Words / sentences{' '}
            <Text as="span" fontWeight="medium">
              must not
            </Text>{' '}
            be said in the vicinity of one of these
          </Trans>
        )}
      </Text>
      <Flex align="center" gap="8" my="4">
        <Flex align="center" gap="2">
          <Text fontWeight="medium">
            <Trans>Mentioned by</Trans>
          </Text>
          <Tag>{speaker}</Tag>
        </Flex>
        <Flex align="center" gap="2">
          <Text fontWeight="medium">
            <Trans>Distance</Trans>{' '}
          </Text>
          <Box>
            <Tag>{activity}</Tag>
          </Box>
        </Flex>
      </Flex>

      <Text fontWeight="medium">
        <Trans>Words/sentences</Trans>
      </Text>
      {sortedPhrases.map((w) => (
        <Tag fontWeight="normal" key={w} borderRadius="xl" mr="2" mt="2">
          {w.toLowerCase()}
        </Tag>
      ))}
    </Box>
  )
}

const NearnessPopoverContent: React.FC<{
  phrasesSetting: PhraseSettings
}> = ({ phrasesSetting }) => {
  const { organizationType } = useOrganization()
  const speakerMap: { [key: string]: string } = {
    '-1': t`All`,
    '1': t`Employee`,
    '0': select(organizationType, {
      public: 'Citizen',
      other: 'Customer',
    }),
  }

  const nearSpeaker = phrasesSetting.near
    ? speakerMap[phrasesSetting.near.speakerId]
    : ''
  const notNearSpeaker = phrasesSetting.notNear
    ? speakerMap[phrasesSetting.notNear.speakerId]
    : ''

  const nearActivty =
    phrasesSetting.near?.secondsAfter === -1
      ? t`Entire conversation`
      : t`${phrasesSetting.near?.secondsAfter}s (before and after)`

  const notNearActivty =
    phrasesSetting.notNear?.secondsAfter === -1
      ? t`Entire conversation`
      : t`${phrasesSetting.notNear?.secondsAfter}s (before and after)`

  const nearHasPhrases =
    phrasesSetting.near && phrasesSetting.near.phrases.length !== 0
  const notNearHasPhrases =
    phrasesSetting.notNear && phrasesSetting.notNear.phrases.length !== 0

  return (
    <PopoverContent color="black" w={400} fontSize="sm" p="4">
      <PopoverArrow />
      <Heading>
        <Trans>Advanced &quot;nearness&quot; setup</Trans>
      </Heading>

      {nearHasPhrases && (
        <NearnessSection
          isNear
          activity={nearActivty}
          speaker={nearSpeaker}
          phrases={phrasesSetting.near?.phrases ?? []}
        />
      )}

      {notNearHasPhrases && nearHasPhrases && (
        <Box w="full" height="0.5" bg="gray.200" />
      )}

      {notNearHasPhrases && (
        <NearnessSection
          activity={notNearActivty}
          speaker={notNearSpeaker}
          phrases={phrasesSetting.notNear?.phrases ?? []}
        />
      )}
    </PopoverContent>
  )
}

export default NearnessPopoverContent
