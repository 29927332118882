import analytics from '@capturi/analytics'
import { Button, ContentPlaceholder } from '@capturi/ui-components'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { FallbackProps } from 'react-error-boundary'

import EmptyStateIcon from './EmptyStateIcon'

const styles = {
  errorBoundary: {
    width: '100%',
    height: 'auto',
    padding: '4rem 1rem',
    margin: '0',
    maxWidth: 'initial',
    backgroundColor: '#a92f2fc4',
    color: '#fafafa',
  } as React.CSSProperties,
}

type ExtendFallbackProps = {
  error: unknown
  resetErrorBoundary?: FallbackProps['resetErrorBoundary']
}

const DefaultFallbackComponent: React.FC<ExtendFallbackProps> = ({
  error: maybeError,
  resetErrorBoundary,
}) => {
  const error =
    maybeError instanceof Error ? maybeError : new Error('Unknown error')

  if (process.env.NODE_ENV !== 'production')
    return (
      <Container title={error?.toString()} style={styles.errorBoundary}>
        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Something went wrong
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>{error?.toString()}</AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Container>
    )

  if (error.name === 'Timeout') {
    analytics.event('analytics_timeout_response')

    return (
      <ContentPlaceholder.Container mt="10vh">
        <ContentPlaceholder.Heading>
          <Trans>The period you picked is too big</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Text>
            <Trans>Select a more narrow period and try again</Trans>
          </Text>
          {resetErrorBoundary !== undefined && (
            <ContentPlaceholder.Footer>
              <Button primary onClick={resetErrorBoundary}>
                <Trans>Reload page</Trans>
              </Button>
            </ContentPlaceholder.Footer>
          )}
        </ContentPlaceholder.Body>
      </ContentPlaceholder.Container>
    )
  }

  return (
    <ContentPlaceholder.Container mt={20}>
      <EmptyStateIcon />
      <ContentPlaceholder.Heading>
        <Trans>Whoops! There has been an error</Trans>
      </ContentPlaceholder.Heading>
      <ContentPlaceholder.Body>
        <Trans>Try reloading the page</Trans>
      </ContentPlaceholder.Body>
      {resetErrorBoundary !== undefined && (
        <ContentPlaceholder.Footer direction="column" width="100%">
          <Button primary onClick={resetErrorBoundary}>
            <Trans>Reload page</Trans>
          </Button>
          <Accordion allowToggle width={'100%'} mt="8">
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Details
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>{error?.toString()}</AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ContentPlaceholder.Footer>
      )}
    </ContentPlaceholder.Container>
  )
}

export default DefaultFallbackComponent
