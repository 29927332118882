import 'react-day-picker/dist/style.css'

import { Box, Flex, HStack, Input, Stack } from '@chakra-ui/react'
import { Global, css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { endOfDay, isBefore, isDate, startOfDay } from 'date-fns'
import React, { useEffect, useState } from 'react'
import {
  DateRange,
  DayModifiers,
  DayPickerProps,
  addToRange,
} from 'react-day-picker'
import { MdAccessTime } from 'react-icons/md'
import { usePrevious } from 'react-use'

import LocalizedDayPicker from './LocalizedDayPicker'
import NavBar from './NavBar'
import createStyle from './style'
import { dateWithTime, isSameDay } from './utils'

const CLS_NAME = 'capturi-dp'
const cssStyle = createStyle(CLS_NAME)

const DateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

const formatTimeString = (date: Date | undefined): string => {
  if (!date) return ''
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  return `${hours}:${minutes}`
}

export type DateRangePickerProps = DayPickerProps & {
  // Controlled value
  value?: DateRange
  // Default value when not controlled
  defaultValue?: DateRange
  onSelectDateRange?: (range: { from: Date; to: Date }) => void
  onSelectDate?: (date: Date) => void
  placeholderFrom?: string
  placeholderTo?: string
  showInputs?: boolean
}

enum InputType {
  FROM = 0,
  TO = 1,
  NONE = 2,
}

export function defaultFormat(d: Date): string {
  if (isDate(d)) {
    const year = d.getFullYear()
    const month = `${d.getMonth() + 1}`
    const day = `${d.getDate()}`
    return `${year}-${month}-${day}`
  }
  return ''
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  value: controlledValue,
  defaultValue,
  onSelectDateRange,
  onSelectDate,
  placeholderFrom = '',
  placeholderTo = '',
  showInputs = false,
  disabled,
}) => {
  const { current: isControlled } = React.useRef(controlledValue != null)

  const fromInputRef = React.useRef<HTMLInputElement>(null)
  const toInputRef = React.useRef<HTMLInputElement>(null)

  const fromTimeInputRef = React.useRef<HTMLInputElement>(null)
  const toTimeInputRef = React.useRef<HTMLInputElement>(null)

  const [activeInput, setActiveInput] = useState<InputType>(() => {
    if (showInputs) {
      if (isControlled) {
        return InputType.NONE
      }
      if (!defaultValue) {
        return InputType.FROM
      }
    }
    return InputType.NONE
  })

  const [value, setValue] = useState<Partial<DateRange>>(
    defaultValue ?? {
      from: undefined,
      to: undefined,
    },
  )

  const _value = isControlled && controlledValue ? controlledValue : value
  const previousValue = usePrevious(_value)
  const [month, setMonth] = useState<Date>(_value.from ?? new Date())

  const [fromTimeValue, setFromTimeValue] = useState<string>('00:00')
  const [toTimeValue, setToTimeValue] = useState<string>('23:59')

  const [showTimeInputs, setShowTimeInputs] = useState<boolean>(true)

  const handleTimeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const fromInputRefValue = fromTimeInputRef?.current?.value
    const toInputRefValue = toTimeInputRef?.current?.value
    if (fromInputRefValue && e.target.id === 'from-time-picker') {
      setFromTimeValue(fromInputRefValue)
    }
    if (toInputRefValue && e.target.id === 'to-time-picker') {
      setToTimeValue(toInputRefValue)
    }

    const [fromHours, fromMinutes] =
      fromInputRefValue?.split(':').map((val) => Number.parseInt(val, 10)) ?? []

    const fromDate = dateWithTime({
      date: _value.from,
      hours: fromHours,
      minutes: fromMinutes,
    })
    const [toHours, toMinutes] =
      toInputRefValue?.split(':').map((val) => Number.parseInt(val, 10)) ?? []

    let toDate = dateWithTime({
      date: _value.to,
      hours: toHours,
      minutes: toMinutes,
    })

    // special case where we convert 00:00-23:59 to a full day 00:00:00-23:59:59
    if (
      isSameDay(_value.from, _value.to) &&
      fromHours === 0 &&
      fromMinutes === 0 &&
      toHours === 23 &&
      toMinutes === 59
    ) {
      toDate = endOfDay(toDate)
    }

    onSelectDateRange?.({ from: fromDate, to: toDate })
  }
  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy
  useEffect(() => {
    if (isControlled) {
      const fromDidChange = !isSameDay(_value.from, previousValue?.from)
      const toDidChange = !isSameDay(_value.to, previousValue?.to)
      if (fromDidChange) {
        pageToMonth(_value.from)
      } else if (toDidChange) {
        pageToMonth(_value.to)
      }
    }
    const shouldShowTimeInputs = isSameDay(_value.from, _value.to)
    if (shouldShowTimeInputs) {
      setShowTimeInputs(true)
      setFromTimeValue(formatTimeString(_value.from))
      setToTimeValue(formatTimeString(_value.to))
    } else {
      setShowTimeInputs(false)
      setFromTimeValue('00:00')
      setToTimeValue('23:59')
    }
  }, [isControlled, _value, previousValue, toTimeValue, fromTimeValue])

  useEffect(() => {
    if (isControlled || !defaultValue) {
      // Not controlled and no value - focus `from` input
      setTimeout(() => fromInputRef.current?.focus())
    } else if (defaultValue?.from && isDate(defaultValue.from)) {
      setMonth(defaultValue.from)
    }
  }, [defaultValue])

  const handleDayClick = (date: Date, modifiers: DayModifiers): void => {
    if (modifiers.disabled) {
      return
    }
    let range: DateRange
    if (activeInput === InputType.NONE) {
      // We don't have an explicit focus on the `from` or `to` inputs. Manipulate existing range
      range = addToRange(date, _value as DateRange) ?? {
        from: undefined,
        to: undefined,
      }

      // When user clicks on from/to day
      // react-day-picker either deselects range (click on from)
      // or sets range to { from: to, to: undefined } (click on to)
      // but we want to set it to a single day selection instead
      range.from = range.from ?? date
      range.to = range.to ?? date
    } else {
      let { from, to } = _value
      if (activeInput === InputType.FROM) {
        from = date
      }
      if (activeInput === InputType.TO) {
        to = date
      }
      if (from && to && isBefore(to, from)) {
        ;[from, to] = [to, from] // swap `from` and `to`
      }
      range = { from, to } as DateRange
    }

    const [fromHours, fromMinutes] = fromTimeValue
      .split(':')
      .map((val) => Number.parseInt(val, 10))

    const [toHours, toMinutes] = toTimeValue
      .split(':')
      .map((val) => Number.parseInt(val, 10))

    const simpleFromDate = !isSameDay(range.from, range.to)
      ? dateWithTime({
          date: startOfDay(range.from ?? new Date()),
          hours: 0,
          minutes: 0,
        })
      : dateWithTime({
          date: range.from,
          hours: fromHours,
          minutes: fromMinutes,
        })

    let simpleToDate = !isSameDay(range.from, range.to)
      ? dateWithTime({
          date: startOfDay(range?.to ?? new Date()),
          hours: 23,
          minutes: 59,
        })
      : dateWithTime({
          date: range.to,
          hours: toHours,
          minutes: toMinutes,
        })

    // special case where we convert 00:00-23:59 to a full day 00:00:00-23:59:59
    if (
      isSameDay(range.from, range.to) &&
      fromHours === 0 &&
      fromMinutes === 0 &&
      toHours === 23 &&
      toMinutes === 59
    ) {
      simpleToDate = endOfDay(simpleToDate)
    }

    range = { from: simpleFromDate, to: simpleToDate }
    setValue(range)
    if (range?.from && range?.to) {
      onSelectDateRange?.({ from: range.from, to: range.to })
    }
    onSelectDate?.(date)

    if (activeInput === InputType.FROM) {
      // Focus `to` input
      toInputRef.current?.focus()
    } else {
      // Remove explicit input focus
      setActiveInput(InputType.NONE)
    }
  }

  // Page to the selected month
  const pageToMonth = (date?: Date): void => {
    if (date) {
      setMonth(date)
    }
  }

  const handleFocusFromInput = (): void => {
    pageToMonth(_value.from)
    setActiveInput(InputType.FROM)
  }

  const handleFocusToInput = (): void => {
    pageToMonth(_value.to)
    setActiveInput(InputType.TO)
  }

  const selectedDays = ((): DateRange => {
    if (_value.from && _value.to) {
      return { from: _value.from, to: _value.to }
    }
    return { from: _value.from }
  })()

  const fromInputValue = _value.from
    ? i18n.date(_value.from, DateFormatOptions)
    : undefined
  const toInputValue = _value.to
    ? i18n.date(_value.to, DateFormatOptions)
    : undefined

  return (
    <Flex alignItems="center" flexDir="column" overflow="auto">
      {showInputs && (
        <Stack
          as="header"
          isInline
          spacing={[2, 4]}
          alignItems="center"
          p={[2, 4]}
          maxW="273px"
        >
          <Input
            ref={fromInputRef}
            size="sm"
            value={fromInputValue}
            onFocus={handleFocusFromInput}
            placeholder={placeholderFrom}
            isReadOnly
            minW={0}
            flex={1}
            cursor="pointer"
          />
          <Box flex={0}>-</Box>
          <Input
            ref={toInputRef}
            size="sm"
            value={toInputValue}
            onFocus={handleFocusToInput}
            placeholder={placeholderTo}
            isReadOnly
            minW={0}
            flex={1}
            cursor="pointer"
          />
        </Stack>
      )}
      <LocalizedDayPicker
        className={CLS_NAME}
        onDayClick={handleDayClick}
        selected={selectedDays}
        components={{ Caption: (p) => <NavBar {...p} /> }}
        weekStartsOn={1}
        month={month}
        onMonthChange={setMonth}
        mode="range"
        disabled={disabled}
        footer={
          showTimeInputs && (
            <HStack gap="1" mt="2" justifyContent="right">
              <MdAccessTime />
              <Input
                ref={fromTimeInputRef}
                type="time"
                id="from-time-picker"
                value={fromTimeValue}
                onChange={handleTimeChange}
                borderRadius="base"
                size="sm"
                width="auto"
                css={css`
                  &::-webkit-calendar-picker-indicator {
                    display: none;
                  }
                `}
              />
              <Box as="span">-</Box>
              <Input
                ref={toTimeInputRef}
                type="time"
                id="to-time-picker"
                value={toTimeValue}
                onChange={handleTimeChange}
                borderRadius="base"
                size="sm"
                width="auto"
                css={css`
                  &::-webkit-calendar-picker-indicator {
                    display: none;
                  }
                `}
              />
            </HStack>
          )
        }
      />
      <Global
        styles={css`
          ${cssStyle}
        `}
      />
    </Flex>
  )
}

export { DateRangePicker }
