import { TagsResponseModel, trackersAPI } from '@capturi/api-trackers'
import { useAPI } from '@capturi/api-utils'
import { GroupBase, PopoutSelect, SelectOptionBase } from '@capturi/ui-select'
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import capitalize from 'lodash/capitalize'
import React, { useMemo } from 'react'

type FilterTagsSelectPopoverProps = {
  selectedTags: string[] | undefined
  setSelectedTags: (tags: string[]) => void
  children?: React.ReactNode
}

const FilterTagsSelectPopover: React.FC<FilterTagsSelectPopoverProps> = ({
  selectedTags,
  setSelectedTags,
  children,
}: FilterTagsSelectPopoverProps) => {
  const { data: tagOptions } = useAPI<TagsResponseModel>(trackersAPI.getTags())

  const selectedTagOptions = useMemo(
    () =>
      selectedTags?.map((tag) => ({
        label: capitalize(tag),
        value: tag,
      })) ?? [],
    [selectedTags],
  )

  const selectedTagsGroup: GroupBase<SelectOptionBase> = useMemo(
    () => ({
      label: t`Selected`,
      options: selectedTagOptions,
    }),
    [selectedTagOptions],
  )

  const allOptionsGroup: GroupBase<SelectOptionBase> = useMemo(
    () => ({
      label: t`Options`,
      options: (tagOptions?.tags || [])
        .sort((a, b) => a.localeCompare(b))
        .map((tag) => ({
          label: tag,
          value: tag,
        })),
    }),
    [tagOptions],
  )

  const options = [selectedTagsGroup, allOptionsGroup]

  return (
    <Popover placement="bottom-start" isLazy>
      {({ onClose }) => (
        <>
          <Box display="inline-block" w="100%">
            <PopoverTrigger>{children}</PopoverTrigger>
          </Box>
          <Portal>
            <PopoverContent>
              <PopoutSelect
                placeholder={t`Choose tag`}
                onChange={(option): void => {
                  const selectedTags = option?.map((o) => o.value)
                  setSelectedTags(selectedTags)
                }}
                onSubmit={onClose}
                resetText={t`Reset`}
                selectAllText={t`Select all`}
                value={selectedTagOptions}
                options={options}
                isMulti
                closeMenuOnSelect={false}
                noOptionsMessage={() => t`No options`}
              />
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  )
}

export default FilterTagsSelectPopover
