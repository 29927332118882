import { Flex, Icon, Input, Tooltip, chakra } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React, { useEffect, useRef } from 'react'
import { MdErrorOutline, MdInfoOutline } from 'react-icons/md'
import { CellProps } from 'react-table'

import {
  duplicatePhraseTooltipMsg,
  hasUnknownWordsTooltipMsg,
  orphanedQuoteTooltipMsg,
  phraseIsTooShortTooltipMsg,
} from '../../../../../messages'
import { PhraseState } from '../../../../../types'
import { MAX_WORDS_PER_PHRASE } from '../../../Phrases'
import { useDictionaryWordContext } from '../../../useDictionaryWordStatus'
import { usePhraseValidation } from '../../../usePhraseValidation'

const sanitizeAndTruncatePhrase = (phrase: string): string => {
  return (
    phrase
      .split(' ')
      // Remove empty strings due to multi-whitespace
      .filter(Boolean)
      // Limit to max words per phrase
      .slice(0, MAX_WORDS_PER_PHRASE)
      .join(' ')
  )
}

export function PhraseCell(
  props: CellProps<PhraseState, string>,
): React.ReactElement {
  const {
    row,
    rows,
    addPhraseFields,
    removePhraseField,
    updatePhraseField,
    phrasesListDOMRef,
  } = props
  const { validateWords } = useDictionaryWordContext()
  const inputRef = useRef<HTMLInputElement>(null)
  /**
   * Manage input field state locally and update table data on input blur.
   * This makes everything more performant and makes sure rows are not
   * sorted on every key press due to constant changes to the row data.
   */
  const [value, setValue] = React.useState(props.value)

  const validatePhrase = usePhraseValidation()
  const {
    hasUnknownWords,
    isDuplicatePhrase,
    hasOrphanedQuote,
    isShortPhrase,
  } = validatePhrase(value)

  useEffect(() => {
    // Sync to local state once actual state changes
    setValue(props.value)
  }, [props.value])

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <Flex align="center">
      <Input
        width="22em"
        ref={inputRef}
        key={row.id}
        className="tracker-phrase-field__input"
        placeholder={t`Add word/phrase`}
        color={hasUnknownWords ? 'primary.500' : undefined}
        value={value}
        onChange={(e) => {
          const value = e.currentTarget.value
          setValue(value)
          const phraseWords = value.trimStart().split(' ')
          if (phraseWords.length > MAX_WORDS_PER_PHRASE) {
            addPhraseFields()
          }
        }}
        onPaste={(e) => {
          const text = e.clipboardData.getData('Text')
          // split into words
          const words = text.replace(/\s\s+/g, ' ').split(' ').filter(Boolean)
          const phrases: string[] = []
          // Create 1 or more phrases
          while (words.length) {
            phrases.push(words.splice(0, MAX_WORDS_PER_PHRASE).join(' '))
          }
          if (phrases.length) {
            addPhraseFields(...phrases)
          }
        }}
        onBlur={() => {
          const sanitizedValue = sanitizeAndTruncatePhrase(value)
          const sortedRowIndex = rows.findIndex((x) => x.id === row.id)
          if (
            sanitizedValue === '' &&
            rows.length > 1 &&
            sortedRowIndex === rows.length - 1
          ) {
            /* 
              This is the very last item in the list.
              If it does not have a value once the input is blurred we remove it.
            */
            removePhraseField(row.index)
          } else {
            updatePhraseField(row.index, {
              value: sanitizedValue,
            })
            validateWords(sanitizedValue)
          }
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key !== 'Enter') {
            return
          }
          // Focus next phrase input field or create a new one if this is the last in the list.
          const fields = phrasesListDOMRef.current?.querySelectorAll(
            '.tracker-phrase-field__input',
          )
          if (fields) {
            const sortedRowIndex = rows.findIndex((x) => x.id === row.id)
            const nextField = fields[sortedRowIndex + 1] as
              | HTMLInputElement
              | undefined
            if (nextField) {
              nextField.focus()
              //Only add new field if there is some content
            } else if (value) {
              addPhraseFields()
            }
          }
        }}
      />
      {hasUnknownWords && (
        <Tooltip label={hasUnknownWordsTooltipMsg()} hasArrow openDelay={300}>
          <chakra.span ml={2}>
            <Icon as={MdInfoOutline} color="primary.500" fontSize="md" />
          </chakra.span>
        </Tooltip>
      )}
      {isShortPhrase && (
        <Tooltip label={phraseIsTooShortTooltipMsg()} hasArrow openDelay={300}>
          <chakra.span ml={2}>
            <Icon as={MdInfoOutline} color="warning" fontSize="md" />
          </chakra.span>
        </Tooltip>
      )}
      {hasOrphanedQuote && (
        <Tooltip label={orphanedQuoteTooltipMsg()} hasArrow openDelay={300}>
          <chakra.span ml={2}>
            <Icon as={MdErrorOutline} color="danger" fontSize="md" />
          </chakra.span>
        </Tooltip>
      )}
      {isDuplicatePhrase && (
        <Tooltip label={duplicatePhraseTooltipMsg()} hasArrow openDelay={300}>
          <chakra.span ml={2}>
            <Icon as={MdErrorOutline} color="danger" fontSize="md" />
          </chakra.span>
        </Tooltip>
      )}
    </Flex>
  )
}
