import { Button } from '@capturi/ui-components'
import { FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

export const Form: React.FC<{
  onSubmit: () => void
  children?: React.ReactNode
}> = ({ onSubmit, children }) => {
  const formRef = React.useRef<HTMLFormElement>(null)

  const handleSubmitChanges = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (formRef.current?.checkValidity() === false) {
      return
    }
    onSubmit()
  }

  return (
    <form ref={formRef} onSubmit={handleSubmitChanges}>
      {children}
    </form>
  )
}

export const FormControls: React.FC<{
  initialFocusRef: React.RefObject<HTMLInputElement>
  name: string
  onNameChange: React.ChangeEventHandler<HTMLInputElement>
}> = ({ initialFocusRef, name, onNameChange }) => {
  return (
    <Stack spacing={4}>
      <FormControl isRequired>
        <FormLabel htmlFor="filterName">
          <Trans>Name</Trans>
        </FormLabel>
        <Input
          id="filterName"
          ref={initialFocusRef}
          placeholder={t`Name`}
          onChange={onNameChange}
          value={name}
        />
      </FormControl>
    </Stack>
  )
}

export const ModalFooterActions: React.FC<{
  onClose: () => void
}> = ({ onClose }) => {
  return (
    <Stack isInline spacing={4} justify="flex-end">
      <Button secondary type="button" onClick={onClose}>
        <Trans>Cancel</Trans>
      </Button>
      <Button primary type="submit">
        <Trans>Save</Trans>
      </Button>
    </Stack>
  )
}

export const TitleInput: React.FC<{
  inputRef?: React.RefObject<HTMLInputElement>
  value: string | undefined
  onChange: (value: string) => void
}> = ({ value, onChange, inputRef }) => {
  return (
    <FormControl isRequired>
      <FormLabel htmlFor="filter-title">
        <Trans>Name</Trans>
      </FormLabel>
      <Input
        id="filter-title"
        ref={inputRef}
        placeholder={t`Name`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        value={value}
      />
    </FormControl>
  )
}
