import { createPatchPayload } from '@capturi/api-shared'
import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { CreateEditSavedTextFilterPayload, SavedTextFilter } from '../../models'
import { url, cacheKey } from '../text/constants'
import { SavedTextFilterResponse } from './useSavedTextFilters'

export type UpdateSavedTextFilterPayload = {
  uid: string
  filter: Partial<CreateEditSavedTextFilterPayload>
}

export const useCreateSavedTextFilter = (): UseMutationResult<
  SavedTextFilter,
  ResponseError,
  CreateEditSavedTextFilterPayload
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (model: CreateEditSavedTextFilterPayload) =>
      request.post<SavedTextFilter>(url, { json: model }),
    onSuccess: (newFilter) => {
      queryClient.setQueryData<SavedTextFilterResponse>(
        [cacheKey],
        (oldData) => {
          if (!oldData) {
            return { filters: [newFilter] }
          }
          return {
            filters: [newFilter, ...oldData.filters],
          }
        },
      )
    },
  })
}

export const useUpdateSavedTextFilter = (): UseMutationResult<
  SavedTextFilter,
  ResponseError,
  UpdateSavedTextFilterPayload
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey],
    mutationFn: ({ uid, filter }: UpdateSavedTextFilterPayload) => {
      const originalData = queryClient
        .getQueryData<SavedTextFilterResponse>([cacheKey])
        ?.filters.find((f) => f.uid === uid)

      const changes = createPatchPayload(originalData, filter)
      return request.patch<SavedTextFilter>(
        `filters/text/${uid}?api-version=3.3`,
        {
          json: changes,
        },
      )
    },
    onSuccess: (newFilter) => {
      queryClient.setQueryData<SavedTextFilterResponse>(
        [cacheKey],
        (oldData) => {
          if (!oldData) {
            return { filters: [newFilter] }
          }

          return {
            filters: oldData.filters.map((f) =>
              f.uid === newFilter.uid ? newFilter : f,
            ),
          }
        },
      )
    },
  })
}

export const useDeleteTextFilter = (): UseMutationResult<
  SavedTextFilter,
  ResponseError,
  string
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey],
    mutationFn: (filterUid: string) =>
      request.delete<SavedTextFilter>(
        `filters/text/${filterUid}?api-version=3.3`,
      ),
    onSuccess: (_, filterUid) => {
      queryClient.setQueryData<SavedTextFilterResponse>(
        [cacheKey],
        (oldData) => {
          if (!oldData) {
            return { filters: [] }
          }
          return {
            filters: oldData?.filters.filter((f) => f.uid !== filterUid),
          }
        },
      )
    },
  })
}
