import isEmpty from 'lodash/isEmpty'
import { useMemo } from 'react'

import { FilterDefinitions } from './types'
import { FilterCriteria } from './useFilterCriterias'

const hasFilterType = (stateKey: string, filters: FilterCriteria[]): boolean =>
  filters.find((x) => x.stateKey === stateKey) !== undefined

export function useAvailableFilters<TValues>(
  currentFilters: FilterCriteria[],
  state: Partial<TValues>,
  filterDefinitions: FilterDefinitions,
): string[][] {
  return useMemo(() => {
    const availableKeys = Array.from(filterDefinitions.keys()).reduce<{
      [key: string]: string[]
    }>(
      (memo, key) => {
        const filterDefinition = filterDefinitions.get(key)
        if (
          filterDefinition === undefined ||
          filterDefinition.isAvailable?.() === false
        ) {
          // Filter is not available due to fx. user role constraints or feature flags
          return memo
        }
        if (filterDefinition.inactive) {
          // Filter is not active
          return memo
        }
        if (
          hasFilterType(key, currentFilters) &&
          !filterDefinition.allowMultiple
        ) {
          // Already added (but has no value yet)
          return memo
        }
        const stateValue = state[key as keyof TValues]
        if (isEmpty(stateValue) || filterDefinition.allowMultiple) {
          // Filter is available
          const { sortGroup = 'default' } = filterDefinition
          if (memo[sortGroup] === undefined) {
            memo[sortGroup] = []
          }
          memo[sortGroup].push(key)
        }
        return memo
      },
      {
        default: [],
      },
    )
    return Object.values(availableKeys).map((group) => {
      return group.sort((aKey, bKey) => {
        const filterA = filterDefinitions.get(aKey)
        const filterB = filterDefinitions.get(bKey)
        return (filterA?.name ?? '').localeCompare(filterB?.name ?? '')
      })
    })
  }, [state, currentFilters, filterDefinitions])
}
