import { Box, BoxProps, Text, Tooltip } from '@chakra-ui/react'
import identity from 'lodash/identity'
import React from 'react'

import { RangeFormatterConfig, RenderValueType } from '../types'
import {
  createRangeFormatter,
  defaultRangeFormatterConfig,
  valueToPercent,
} from '../utils'

type Props = {
  // Range lower bound
  min?: number
  // Range upper bound
  max?: number
  // Text label
  label?: string
  // Color of range indicators
  color?: string
  // Width of range indicators
  width?: number
  formatValue?: RenderValueType
  formatterConfig?: RangeFormatterConfig
  minValue?: number
  maxValue?: number
}

const ReferenceRange: React.FC<Props> = ({
  min,
  max,
  minValue = 0,
  maxValue = 100,
  formatValue = identity,
  formatterConfig = defaultRangeFormatterConfig,
  label,
  color = 'green.400',
  width = 2,
}) => {
  const minPct = min == null ? 0 : valueToPercent(min, minValue, maxValue)
  const maxPct = max == null ? 100 : valueToPercent(max, minValue, maxValue)
  const widthPct = maxPct - minPct

  const topOffset = -30

  const boundsProps: BoxProps = {
    position: 'absolute',
    top: topOffset,
    bottom: 0,
    borderLeftWidth: `${width}px`,
    borderLeftStyle: 'dashed',
    borderLeftColor: color,
  }

  const formatRange = createRangeFormatter(formatterConfig)
  const formattedRange = formatRange(min, max, formatValue)
  return (
    <>
      <Box
        position="absolute"
        top={topOffset}
        left={`${minPct}%`}
        w={`${widthPct}%`}
        bg="transparent"
        borderTopWidth={`${width + 1}px`}
        borderTopStyle={'solid'}
        borderTopColor={color}
      >
        <Tooltip
          hasArrow
          label={formattedRange}
          aria-label={formattedRange}
          placement="top"
          bg={color}
        >
          <Text
            textAlign="center"
            whiteSpace="nowrap"
            fontSize="sm"
            color={color}
            textTransform="uppercase"
            transform="translateY(-100%)"
            pb="1px"
          >
            {label}
          </Text>
        </Tooltip>
      </Box>
      {min != null && <Box {...boundsProps} left={`${minPct}%`} />}
      {max != null && <Box {...boundsProps} left={`${minPct + widthPct}%`} />}
    </>
  )
}

export default ReferenceRange
