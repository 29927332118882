import { generateImageUrl } from '@capturi/request'
import { Spinner as _Spinner } from '@capturi/ui-components'
import { Avatar, AvatarProps } from '@chakra-ui/react'
import React from 'react'

type Props = {
  name: string
  isLoading?: boolean
  error?: Error
  profileImage: null | { key: string }
} & AvatarProps

export const UserAvatar: React.FC<Props> = (props) => {
  const { name, error, isLoading, profileImage, ...restProps } = props

  return (
    <Avatar
      name={error ? undefined : name}
      src={
        error || !profileImage?.key
          ? undefined
          : generateImageUrl(profileImage.key, {
              size: props.size === 'sm' ? 32 : 64,
            })
      }
      title={name}
      aria-label={name}
      {...restProps}
    >
      {isLoading ? <Spinner /> : props.children}
    </Avatar>
  )
}

const Spinner: React.FC = () => (
  <_Spinner pos="absolute" emptyColor="whiteAlpha.600" color="blackAlpha.500" />
)
