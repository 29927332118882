import { alertAnatomy as parts } from '@chakra-ui/anatomy'
import { theme } from '@chakra-ui/theme'
import type {
  PartsStyleFunction,
  StyleFunctionProps,
} from '@chakra-ui/theme-tools'

const originalVariants = theme.components.Alert.variants

type Status = 'info' | 'warning' | 'success' | 'error'

function withRemappedStatusColor(
  variantFn?: PartsStyleFunction<typeof parts>,
): PartsStyleFunction<typeof parts> | undefined {
  if (variantFn == null) return undefined

  // Default color scheme for alerts
  // Need this to see if the user passed in a colorScheme
  // as the colorScheme will be set by the base Alert config.
  const DEFAULT_COLOR_SCHEMES: { [status in Status]: string } = {
    info: 'blue',
    warning: 'orange',
    success: 'green',
    error: 'red',
  }

  // Custom colorScheme for alerts
  const STATUSES: { [status in Status]: Partial<StyleFunctionProps> } = {
    info: { colorScheme: 'primary' },
    warning: { colorScheme: 'orange' },
    success: { colorScheme: 'green' },
    error: { colorScheme: 'red' },
  }

  return (props) => {
    const status: Status = props.status ?? 'info'
    if (props.colorScheme === DEFAULT_COLOR_SCHEMES[status]) {
      return variantFn({
        ...props,
        ...STATUSES[status],
      })
    }
    return variantFn(props)
  }
}
const neutralVariant: PartsStyleFunction<typeof parts> = () => {
  return {
    container: {
      bg: 'signal.info',
      position: 'relative', // Add this line
      '& button[aria-label="Close"]': {
        // Target the close button
        position: 'absolute',
        top: '50%',
        right: '8px', // Adjust horizontal position as needed
        transform: 'translateY(-50%)',
        color: 'white',
        '& > svg': {
          color: 'white',
        },
      },
    },
    icon: {
      color: 'red',
    },
    title: {
      fontWeight: 'bold',
      color: 'white',
    },
    description: {
      color: 'white',
    },
    closeButton: {
      color: 'white',
      '& > svg': {
        color: 'white',
      },
      _hover: {
        color: 'red.500',
        '& > svg': {
          color: 'red.500',
        },
      },
    },
  }
}

export default {
  variants: {
    subtle: withRemappedStatusColor(originalVariants?.subtle),
    'left-accent': withRemappedStatusColor(originalVariants?.['left-accent']),
    'top-accent': withRemappedStatusColor(originalVariants?.['top-accent']),
    solid: withRemappedStatusColor(originalVariants?.solid),
    neutral: neutralVariant,
  },
}
