import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  StyleProps,
  Text,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import React from 'react'
import { MdAlignVerticalCenter, MdSentimentSatisfied } from 'react-icons/md'
import { Benchmark } from '../../../pages/KeyTopics/hooks/types'
import { pctFormat } from '../../../pages/analytics/shared/utils'

export const SentimentBenchmark: React.FC<
  {
    sentiment: Benchmark['sentiment']
    referenceSentiment: Benchmark['sentiment']
    showReferenceBenchmark: boolean
  } & StyleProps
> = ({ sentiment, referenceSentiment, showReferenceBenchmark }) => {
  return (
    <Box>
      <Grid
        h={1}
        templateColumns={`${sentiment.high}fr ${sentiment.medium}fr ${sentiment.low}fr`}
        gap={0}
      >
        <GridItem bg="segments.secondary.500" />
        <GridItem bg="segments.tertiary.500" />
        <GridItem bg="danger" />
      </Grid>

      <Flex fontSize="sm" direction="column" gap={1} mt={1}>
        <Flex justifyContent="space-between">
          <Flex alignItems="center" gap={1}>
            <Icon color="segments.secondary.500" as={MdSentimentSatisfied} />
            <Text>
              <Trans>Positive</Trans>
            </Text>
          </Flex>
          <Flex
            flex={showReferenceBenchmark ? '0 0 50%' : 'initial'}
            justifyContent="space-between"
          >
            {showReferenceBenchmark && (
              <Flex align="center" color="gray.600">
                <Icon as={MdAlignVerticalCenter} />
                <Text fontSize="sm" alignItems="center" ml={1}>
                  {referenceSentiment.total
                    ? i18n.number(
                        referenceSentiment.high / referenceSentiment.total,
                        pctFormat,
                      )
                    : '0%'}
                </Text>
              </Flex>
            )}
            <Text fontSize="sm" fontWeight="medium">
              {sentiment.total
                ? i18n.number(sentiment.high / sentiment.total, pctFormat)
                : '0%'}
            </Text>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between">
          <Flex alignItems="center" gap={1}>
            <Icon color="segments.tertiary.500" as={MdSentimentSatisfied} />
            <Text>
              <Trans>Neutral</Trans>
            </Text>
          </Flex>
          <Flex
            flex={showReferenceBenchmark ? '0 0 50%' : 'initial'}
            justifyContent="space-between"
          >
            {showReferenceBenchmark && (
              <Flex align="center" color="gray.600">
                <Icon as={MdAlignVerticalCenter} />
                <Text fontSize="sm" alignItems="center" ml={1}>
                  {referenceSentiment.total
                    ? i18n.number(
                        referenceSentiment.medium / referenceSentiment.total,
                        pctFormat,
                      )
                    : '0%'}
                </Text>
              </Flex>
            )}
            <Text fontWeight="medium">
              {sentiment.total
                ? i18n.number(sentiment.medium / sentiment.total, pctFormat)
                : '0%'}
            </Text>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between">
          <Flex alignItems="center" gap={1}>
            <Icon color="danger" as={MdSentimentSatisfied} />
            <Text>
              <Trans>Negative</Trans>
            </Text>
          </Flex>
          <Flex
            flex={showReferenceBenchmark ? '0 0 50%' : 'initial'}
            justifyContent="space-between"
          >
            {showReferenceBenchmark && (
              <Flex align="center" color="gray.600">
                <Icon as={MdAlignVerticalCenter} />
                <Text fontSize="sm" alignItems="center" ml={1}>
                  {referenceSentiment.total
                    ? i18n.number(
                        referenceSentiment.low / referenceSentiment.total,
                        pctFormat,
                      )
                    : '0%'}
                </Text>
              </Flex>
            )}
            <Text fontSize="sm" fontWeight="medium">
              {sentiment.total
                ? i18n.number(sentiment.low / sentiment.total, pctFormat)
                : '0%'}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}
