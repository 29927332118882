import React from 'react'
import { CellProps } from 'react-table'

import { PhraseState } from '../../../../types'
import { useConversationsDrawer } from '../../ConversationsDrawer'
import { PreviewTrackerHits } from '../../PreviewTrackerHits'
import { logEvent } from '../../logEvent'
import { usePhraseHitsPreview } from '../../preview-data/usePhraseHitsPreview'

export function PhraseHitsCell({
  value,
}: CellProps<PhraseState, string>): React.ReactElement {
  const preview = usePhraseHitsPreview(value)
  const openConversationsDrawer = useConversationsDrawer()
  return (
    <PreviewTrackerHits
      hits={preview?.conversations?.length || 0}
      total={preview?.conversationsTotal || 0}
      isFetching={!!preview?.isLoading}
      shortTextForm
      onViewConversations={() => {
        if (!preview) return
        logEvent('openPreviewSingleTrackerHit', true)
        openConversationsDrawer({
          data: {
            conversations: preview.conversations?.length ?? 0,
            conversationsTotal: preview.conversationsTotal ?? 0,
            phrases: [
              {
                phrase: preview.phrase,
                conversations: preview.conversations ?? [],
              },
            ],
          },
          drawerTitle: value,
        })
      }}
    />
  )
}
