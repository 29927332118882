import { Box, Button, ButtonProps as _ButtonProps } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'

import { ReadOnlyContext } from '../../PhoneSegmentBuilder'
import { RemoveFilterButton } from '../RemoveFilterButton'

type ButtonProps = Omit<_ButtonProps, 'children'>

export type FilterCriteriaButtonProps = {
  /**
   * If the criteria has a value set
   */
  hasValue: boolean
  /**
   * Is this value of selection for this criteria currently active
   */
  isActive?: boolean
  /**
   * When user clicks the reset button
   */
  onReset: () => void
  /**
   * The filter criteria is not able to be applied in the given context
   * Not to be confused with `ButtonProps.isDisabled`
   */
  isInApplicable?: boolean
} & ButtonProps

export const FilterCriteriaButton = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<FilterCriteriaButtonProps>
>(function FilterCriteriaButton(
  { hasValue, isActive, onReset, isInApplicable, children, ...props },
  ref,
) {
  const isReadOnly = React.useContext(ReadOnlyContext)
  /**
   * Prevent flickering between `error` and `active` states on mount:
   * At mount the filter criteria does not yet have a value but should not be regarded as in an `error` state
   * until the user is given a chance to select a value but does not.
   * `usePrevious` does not work here because we need the wasActive value to be frozen once set
   */
  const wasActiveRef = React.useRef(isActive)
  React.useEffect(() => {
    if (isActive) {
      wasActiveRef.current = true
    }
  }, [isActive])

  const hasError = !hasValue && wasActiveRef.current

  let styleProps: ButtonProps = {
    borderBottom: '2px solid',
    borderBottomColor: 'gray.300',
    bg: 'gray.100',
    pl: '2',
    fontWeight: 'normal',
    _hover: {
      bg: 'gray.300',
    },
    _active: {
      bg: 'gray.300',
    },
  }
  if (isActive) {
    // When filter is currently active
    styleProps = {
      pl: '2',
      fontWeight: 'normal',
      color: 'primary.500',
      bg: 'primary.100',
      _hover: {
        bg: 'primary.200',
      },
      _active: {
        bg: 'primary.200',
      },
      colorScheme: 'primary',
      variant: 'lifted',
    }
  } else if (hasError) {
    // When filter criteria does not have a value and was active
    styleProps = {
      pl: '2',
      fontWeight: 'normal',
      color: 'red.500',
      bg: 'red.100',
      _hover: {
        bg: 'red.200',
      },
      _active: {
        bg: 'red.200',
      },
    }
  }

  if (isInApplicable) {
    /**
     * Filter criteria is not applicable in the current context
     * Render in semi disabled state.
     * Filter criteria can still be removed.
     */
    styleProps = {
      ...styleProps,
      opacity: 0.4,
      boxShadow: 'none',
      cursor: 'auto',
    }
  }

  if (isReadOnly) {
    styleProps._hover = {}
    styleProps._active = {}
    styleProps._focus = {}
    styleProps.cursor = 'auto'
  }

  return (
    <Button
      ref={ref}
      borderRadius="md"
      transition="none"
      {...styleProps}
      {...props}
    >
      <Box maxWidth="inherit" noOfLines={1} wordBreak="break-all">
        {children}
      </Box>
      {!isReadOnly && (
        <RemoveFilterButton
          label={t`Remove this filter`}
          onClick={onReset}
          ml={2}
        />
      )}
    </Button>
  )
})
