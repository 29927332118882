import type {
  KeyTopicCluster,
  KeyTopicsReadyResponse,
  KeyTopicsResponse,
} from '../../KeyTopics/hooks/types'
import { KeyTopicsSessionSelector } from '../types'

export type RepeatCallsWidgetSelectorTopics = {
  amount: number
  id: string
  label: string
  repeatCalls?: {
    amount: number
    avgDuration: number
    sumDuration: number
  }
  repeatCallsAmount: number
  repeatCallsShare: number
  repeatCallsAmountIfReducedToAverage: number
  keyTopics: KeyTopicCluster['keyTopics']
}

export type RepeatCallsWidgetReadySelector = {
  status: 'ready'
  benchmark: KeyTopicsReadyResponse['benchmark']
  displayedKeyTopicClusters: RepeatCallsWidgetSelectorTopics[]
  topKeyTopicClusters: RepeatCallsWidgetSelectorTopics[]
  bottomKeyTopicClusters: RepeatCallsWidgetSelectorTopics[]
  repeatCallsShare: number
  repeatCallSettings: KeyTopicsReadyResponse['repeatCallSettings']
}

export const repeatCallAmountIfReducedToAverage = (
  totalRepeatCallsShare: number,
  topicRepeatCallsShare: number,
  topicRepeatCallsAmount: number,
  topicTotalAmount: number,
) => {
  const expectedRepeatCallsAmount = totalRepeatCallsShare * topicTotalAmount
  const sign = topicRepeatCallsShare < totalRepeatCallsShare ? -1 : 1
  return sign * Math.abs(expectedRepeatCallsAmount - topicRepeatCallsAmount)
}

export const selector = (
  data: KeyTopicsResponse,
): KeyTopicsSessionSelector<RepeatCallsWidgetReadySelector> => {
  if (data === null) return { status: 'gone' } // handle 204 No Content response
  if (data.status === 'Failed') return { status: 'failed' }
  if (data.status === 'Processing' || data.status === 'Pending')
    return { status: 'loading' }

  const totalRepeatCallsShare =
    (data.benchmark.repeatCalls?.amount ?? 0) / data.benchmark.amount

  const keyTopicClusters = data.keyTopicClusters
    .reduce<KeyTopicCluster[]>((acc, keyTopicCluster) => {
      if (keyTopicCluster.label !== 'OTHER') {
        acc.push(keyTopicCluster)
      }
      return acc
    }, [])
    .map(({ amount, id, icon, label, keyTopics, benchmark }) => {
      const topicClusterRepeatCallsShare =
        benchmark.amount > 0
          ? (benchmark.repeatCalls?.amount ?? 0) / benchmark.amount
          : 0
      const name = keyTopics.length > 0 ? keyTopics[0].name : label
      const clusterLabel = icon ? `${icon}\u00A0${name}` : name // \u00A0 is non-breaking space
      return {
        label: clusterLabel,
        amount,
        id,
        repeatCalls: benchmark.repeatCalls,
        repeatCallsAmount: benchmark.repeatCalls?.amount ?? 0,
        repeatCallsAmountIfReducedToAverage: repeatCallAmountIfReducedToAverage(
          totalRepeatCallsShare,
          topicClusterRepeatCallsShare,
          benchmark.repeatCalls?.amount ?? 0,
          benchmark.amount,
        ),
        repeatCallsShare: topicClusterRepeatCallsShare,
        keyTopics,
      }
    })
    .toSorted((a, b) => b.repeatCallsShare - a.repeatCallsShare)

  const higherThanAverage = keyTopicClusters.filter(
    (cluster) => cluster.repeatCallsShare > totalRepeatCallsShare,
  )
  const topKeyTopicClusters = higherThanAverage.slice(0, 5)
  const remainingClusters = keyTopicClusters.slice(5)
  const remaninglowerThanAverage = remainingClusters.filter(
    (cluster) => cluster.repeatCallsShare < totalRepeatCallsShare,
  )
  const bottomKeyTopicClusters = remaninglowerThanAverage.slice(-2)

  const displayedKeyTopicClusters = [
    ...topKeyTopicClusters,
    ...bottomKeyTopicClusters,
  ]

  return {
    status: 'ready',
    benchmark: data.benchmark,
    repeatCallsShare: totalRepeatCallsShare,
    displayedKeyTopicClusters,
    topKeyTopicClusters,
    bottomKeyTopicClusters,
    repeatCallSettings: data.repeatCallSettings,
  }
}
