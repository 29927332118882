import { DurationComparator, DurationType } from '@capturi/api-filters'
import {
  HStack,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  VStack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import debounce from 'lodash/debounce'
import React, { useEffect, useMemo, useState } from 'react'

import { FilterValueLabel } from '../../../components/FilterValueLabel'
import { DurationFilterItem } from '../useTextFilter'
import { TextFilterPopoverComponentProps } from './types'

const localizeDurationComparator = (comparator: DurationComparator): string => {
  switch (comparator) {
    case DurationComparator.Gt:
      return t`Minimum`
    case DurationComparator.Eq:
      return t`Equal`
    case DurationComparator.Lt:
      return t`Maximum`
  }
}

const localizeDurationType = (type: DurationType): string => {
  switch (type) {
    case DurationType.AutoReply:
      return t`auto replies`
    case DurationType.Inbound:
      return t`inbound messages`
    case DurationType.Outbound:
      return t`outbound messages`
    case DurationType.All:
      return t`all messages`
  }
}

export const DurationValueLabel: React.FC<{
  item: DurationFilterItem
}> = ({ item }) => {
  const { comparator, type, value } = item
  const label = `${localizeDurationComparator(
    comparator,
  )} ${value} ${localizeDurationType(type)}`
  return <FilterValueLabel name={t`Number of messages`} value={label} />
}

export const DurationFilterComponent: React.FC<
  TextFilterPopoverComponentProps<DurationFilterItem>
> = ({ item, onChangeValue }) => {
  const [value, setValue] = useState<number | undefined>(
    item.value ?? undefined,
  )

  const debouncedOnChangeValue = useMemo(
    () => debounce(onChangeValue, 500),
    [onChangeValue],
  )
  useEffect(() => {
    return () => {
      // ensure we call the debounced function when unmounting
      debouncedOnChangeValue.flush()
    }
  }, [debouncedOnChangeValue])

  const handleDurationChange = (value: number | undefined): void => {
    setValue(value)
    debouncedOnChangeValue({
      ...item,
      value: value ?? null,
    })
  }

  return (
    <VStack alignContent="flex-start" p={4} alignItems="stretch">
      <Heading size="md" fontWeight="md">
        <Trans>Number of messages</Trans>
      </Heading>
      <HStack>
        <Select
          size="sm"
          value={item.comparator}
          onChange={(e) => {
            onChangeValue({
              ...item,
              comparator: e.target.value as DurationComparator,
            })
          }}
          w={52}
        >
          {Object.values(DurationComparator).map((comparator) => (
            <option key={comparator} value={comparator}>
              {localizeDurationComparator(comparator)}
            </option>
          ))}
        </Select>

        <NumberInput
          inputMode="numeric"
          size="sm"
          min={0}
          onChange={(valueAsString, valueAsNumber) => {
            if (valueAsString === '') {
              handleDurationChange(undefined)
              return
            }
            if (!Number.isInteger(valueAsNumber)) {
              return
            }

            handleDurationChange(valueAsNumber)
          }}
          value={value}
          w={36}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>

        <Select
          size="sm"
          value={item.type}
          onChange={(e) => {
            onChangeValue({
              ...item,
              type: e.target.value as DurationType,
            })
          }}
        >
          {Object.values(DurationType).map((type) => (
            <option key={type} value={type}>
              {localizeDurationType(type)}
            </option>
          ))}
        </Select>
      </HStack>
    </VStack>
  )
}
