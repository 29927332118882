import { SenderPosition, SenderType } from '@capturi/api-filters'
import { Button } from '@capturi/ui-components'
import { IsNotMenu } from '@capturi/ui-select'
import {
  Box,
  Fade,
  Flex,
  Icon,
  Select,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { ReactElement, useRef, useState } from 'react'
import { MdWarning } from 'react-icons/md'

import { SenderFilterItem } from '../useTextFilter'
import { DEFAULT_SPLIT_CHARACTERS } from './BaseMultiTextFilter'
import { TextFilterPopoverComponentProps } from './types'

const VALUES_TO_SHOW = 20

const EMAIL_SPLIT_CHARACTERS = new Set(DEFAULT_SPLIT_CHARACTERS)
EMAIL_SPLIT_CHARACTERS.delete('.')

const localizeSenderType = (comparator: SenderType): string => {
  switch (comparator) {
    case SenderType.Domain:
      return t`E-mail domain`
    case SenderType.Email:
      return t`Specific e-mail`
  }
}

export const InitialSenderFilterComponent: React.FC<
  TextFilterPopoverComponentProps<SenderFilterItem>
> = (props) => {
  return (
    <>
      <SenderMultiTextFilter
        {...props}
        entityName={t`Sender`}
        placeholder={
          props.item.type === SenderType.Domain
            ? t`Enter e-mail domain...`
            : t`Enter e-mail address...`
        }
        hint={t`You can also paste in a list of e-mail addresses`}
        splitCharactersSet={EMAIL_SPLIT_CHARACTERS}
      />
    </>
  )
}

export type SenderMultiTextFilterComponentProps =
  TextFilterPopoverComponentProps<SenderFilterItem> & {
    entityName: string
    placeholder?: string
    hint?: string
    splitCharactersSet?: Set<string>
  }

export function SenderMultiTextFilter({
  splitCharactersSet = DEFAULT_SPLIT_CHARACTERS,
  onClose,
  item,
  onChangeValue,
  entityName,
  placeholder,
  hint,
}: SenderMultiTextFilterComponentProps): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>(null)
  const [state, setState] = useState<string[]>(item.values || [])
  const [inverted, setInverted] = useState<boolean>(item.inverted)
  const [attemptedDuplicate, setAttemptedDuplicate] = useState<boolean>(false)
  const [newPhrase, setNewPhrase] = useState<string>('')

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>): void => {
    event.preventDefault()
    const text = event.clipboardData.getData('Text')

    const values = text.split(/,|\s/) // we split a , and any whitespace

    const s = new Set(state)
    const filteredNumbers = values.filter(
      (value, _index) =>
        value && // checks that it's non-empty sting
        !s.has(value),
    )

    const newState = [...state, ...filteredNumbers]
    setState(newState)
    setNewPhrase('')
    inputRef?.current?.focus()
  }

  const handleAdd = (): void => {
    if (state.includes(newPhrase)) {
      setAttemptedDuplicate(true)
      setNewPhrase('')
      return
    }
    if (newPhrase) {
      setState([...state, newPhrase])
      setAttemptedDuplicate(false)
      setNewPhrase('')
    }
  }

  const handleOk = (): void => {
    handleAdd()
    onChangeValue({
      uid: item.uid,
      values: state,
      inverted: inverted,
      filterType: 'senderFilters',
      type: item.type,
      position: item.position,
    })

    onClose?.()
  }

  const handleReset = (): void => {
    if (newPhrase) {
      setNewPhrase('')
    }
    setState([])
    onChangeValue({
      uid: item.uid,
      values: [],
      inverted: inverted,
      filterType: 'senderFilters',
      type: SenderType.Email,
      position: SenderPosition.First,
    })

    setAttemptedDuplicate(false)
  }

  const removePhrase = (index: number): void => {
    const newState = [...state]
    newState.splice(index, 1)
    setState(newState)
    inputRef?.current?.focus()
  }

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (newPhrase && splitCharactersSet.has(e.key)) {
      e.preventDefault()
      handleAdd()
    }
  }

  const renderPhrase = (phrase: string, index: number): ReactElement => {
    return (
      <Tag m="1" justifyContent="space-between" key={index} borderRadius="999">
        <TagLabel>{phrase}</TagLabel>
        <TagCloseButton onClick={() => removePhrase(index)} />
      </Tag>
    )
  }

  return (
    <Box>
      <Box p="2" pb="0">
        <Flex
          onClick={() => inputRef?.current?.focus()}
          border="solid 1px"
          borderColor="border.light"
          borderRadius="md"
          height="100%"
          transition="200ms padding ease-out"
          padding={1}
          justify="space-between"
          flexDirection="column"
          _focusWithin={{
            borderColor: 'primary.500',
            boxShadow: '0 0 0 1px var(--chakra-colors-primary-500)',
          }}
        >
          <Flex wrap="wrap" alignItems={'center'}>
            {state
              .slice(Math.max(state.length - VALUES_TO_SHOW, 0)) // this gives the last 20 items in the list
              .map(renderPhrase)}
            <Box
              as="input"
              autoFocus={true}
              fontSize="sm"
              px={2}
              my="6px"
              outline={0}
              placeholder={placeholder}
              ref={inputRef}
              onBlur={handleAdd}
              onKeyDown={handleKeypress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewPhrase(e.target.value)
              }
              value={newPhrase}
              onPaste={handlePaste}
            />
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          color="primary.300"
          mt="1"
        >
          <Text fontStyle="italic" color="info" fontSize="sm">
            {hint}
          </Text>
          <Fade in={attemptedDuplicate}>
            <Text
              as="span"
              color="info"
              fontSize="xs"
              display="flex"
              alignItems="center"
            >
              <Trans>Duplicates are not allowed</Trans>
              <Icon ml={1} as={MdWarning} boxSize="3 !important" />
            </Text>
          </Fade>
        </Flex>
      </Box>
      <Flex direction="column-reverse" mt={3}>
        <Flex
          justifyContent="space-between"
          px={2}
          pb={1}
          pt={2}
          alignItems="center"
          boxShadow="0 -4px 8px -6px rgba(0,0,0,0.2)"
        >
          {state.length > VALUES_TO_SHOW ? (
            <Text fontSize="sm">{t`Showing ${VALUES_TO_SHOW} / ${state.length}`}</Text>
          ) : (
            <Text fontSize="sm">
              {state.length} <Trans>selected</Trans>
            </Text>
          )}
          <Flex>
            <Button onClick={handleReset} variant="ghost">
              <Trans>Reset</Trans>
            </Button>
            <Button onClick={handleOk} ml="2" primary variant="ghost">
              <Trans>OK</Trans>
            </Button>
          </Flex>
        </Flex>
        <Flex justify="space-between" align="center" zIndex={1} p={2}>
          <Select
            size="sm"
            value={item.type}
            onChange={(e) => {
              onChangeValue({
                ...item,
                type: e.target.value as SenderType,
              })
            }}
            w={32}
          >
            {Object.values(SenderType).map((comparator) => (
              <option key={comparator} value={comparator}>
                {localizeSenderType(comparator)}
              </option>
            ))}
          </Select>
          <IsNotMenu
            entityName={entityName}
            isNot={inverted}
            onChangeIsNot={setInverted}
          />
        </Flex>
      </Flex>
    </Box>
  )
}
