import { useAllScores } from '@capturi/api-scoring'
import {
  BaseTracker,
  isTrackerPrivate,
  useAllTrackers,
  useTrackerByUid,
} from '@capturi/api-trackers'
import { t } from '@lingui/macro'
import React from 'react'

import { PhoneSegmentBuilderState } from '../types'

type ValidationResult = {
  isValid: boolean
  notValidReason: string | undefined
  notValidSegmentReason?: string | undefined
}

export default function useValidateAsSavedSegment(
  state: PhoneSegmentBuilderState,
): ValidationResult {
  const { data: trackers } = useAllTrackers()
  const { data: scores } = useAllScores()
  const trackersByUid = useTrackerByUid(trackers)

  return React.useMemo(() => {
    //handle inaccessible segments in UI
    if (state.savedFilter?.accessLevel === 'None')
      return {
        isValid: false,
        notValidReason: t`You don’t have view rights to the selected segment. Remove or change segment in order to save.`,
        notValidSegmentReason: t`You don’t have view rights to this segment. You can’t save the widget, but can view results.`,
      }
    // Saved segments may not contain inaccessible trackers nor may it contain an inaccessible score

    let hasInaccessibleScore: string

    //Scores is an array right now, but it is only possible to select a single score for a segment (hence the first index selector below).
    //This could be redone in the future to suit data structure.
    if (state.values.scores?.[0])
      hasInaccessibleScore = state.values.scores?.[0].uid
    const inaccessibleScore = scores?.find(
      (s) => s.uid === hasInaccessibleScore && s.accessLevel === 'None',
    )
    const { hasInaccessibleTrackers } = [
      ...(state.values.trackers?.flatMap((t) => t.uids) ?? []),
      ...(state.values.notTrackers?.flatMap((t) => t.uids) ?? []),
    ].reduce(
      (memo, trackerUid) => {
        const tracker = trackersByUid?.[trackerUid]
        if (tracker === undefined) return memo

        // Inaccessible tracker
        if (tracker.accessLevel === 'None') {
          memo.hasInaccessibleTrackers = true
          return memo
        }

        const accessibleTracker = tracker as BaseTracker
        if (isTrackerPrivate(accessibleTracker)) {
          memo.hasPrivateTrackers = true
        }

        return memo
      },
      {
        hasPrivateTrackers: false,
        hasInaccessibleTrackers: false,
      },
    )

    if (hasInaccessibleTrackers || inaccessibleScore) {
      return {
        isValid: false,
        notValidReason: t`You don’t have view rights to one or more filters. Remove or change this filter in order to save the segment`,
        notValidSegmentReason: t`You don’t have view rights to one or more filters in the segment.`,
      }
    }

    return {
      isValid: true,
      notValidReason: undefined,
    }
  }, [
    state.savedFilter?.accessLevel,
    state.values.scores,
    state.values.trackers,
    state.values.notTrackers,
    scores,
    trackersByUid,
  ])
}
