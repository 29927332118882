import {
  UserPermissions,
  applyPermissionsRules,
  useCurrentUser,
} from '@capturi/core'
import {
  defaultUserPermissions,
  useUserActions,
} from 'pages/Organization/hooks/useUserActions'
import React, { useEffect, useState } from 'react'

import analytics from '@capturi/analytics'
import { useToast } from '@chakra-ui/react'
import { t } from '@lingui/macro'

type UseUserPermissions = {
  togglePermission: (key: keyof UserPermissions, value: boolean) => void
  inProgress: boolean
  permissions: UserPermissions
}

export default function useUserPermissions(): UseUserPermissions {
  const user = useCurrentUser()
  const { patchUser } = useUserActions()
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [permissions, setPermissions] = useState<UserPermissions>({
    ...defaultUserPermissions,
    ...user.permissions,
  })
  const toast = useToast()

  useEffect(() => {
    setPermissions({ ...defaultUserPermissions, ...user.permissions })
  }, [user.permissions])

  const handleChange = React.useCallback(
    async (
      key: keyof UserPermissions,
      value: boolean,
      userPermissions: UserPermissions,
    ): Promise<void> => {
      try {
        setInProgress(true)
        analytics.event('profileMenu_permissionChanged', {
          permission: key,
          value: value,
        })

        await patchUser(user.id, {
          permissions: userPermissions,
        })
        window.location.href = window.location.origin
        toast({
          title: t`Your role has been updated`,
          status: 'success',
        })
      } catch (error) {
        let errMsg: null | string = null
        if (error instanceof Error) {
          errMsg = error.message
        }
        toast({
          title: t`Could not update the user's permission`,
          description: errMsg,
          status: 'error',
        })
      } finally {
        setInProgress(false)
      }
    },
    [patchUser, toast, user.id],
  )

  const togglePermission = React.useCallback(
    (key: keyof UserPermissions, value: boolean) => {
      const userPermissions = applyPermissionsRules(permissions, key, value)
      setPermissions(userPermissions)
      handleChange(key, value, userPermissions)
    },
    [handleChange, permissions],
  )

  return {
    togglePermission,
    inProgress,
    permissions,
  }
}
