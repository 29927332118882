import { Box, Text } from '@chakra-ui/react'
import React from 'react'

import { formatArray } from '../utils'

export const TagsTooltipLabel: React.FC<{
  entries: string[]
  title: string
}> = ({ entries, title }) => {
  return (
    <Box noOfLines={6}>
      {entries.length > 0 && (
        <Box>
          <Text>{title}:</Text>
          {formatArray(entries).map((name, index) => (
            <Text mr={1} as="span" whiteSpace="pre" key={index}>
              {name}
            </Text>
          ))}
        </Box>
      )}
    </Box>
  )
}
