import { ScoreState } from '@capturi/api-filters'
import { useAllScores } from '@capturi/api-scoring'
import { Tooltip } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

export const ScoreTooltipWrapper: React.FC<{
  state: ScoreState[]
  children?: React.ReactNode
}> = ({ state, children }) => {
  const { data: scores, isLoading: loading } = useAllScores()

  const uids = state.map((val) => val.uid)

  const hasValues = !isEmpty(uids)
  const isLoading = hasValues && loading
  if (isLoading) return <>{children}</>

  const scoresArr = Array.from(scores ?? [])
  const hasPrivateScores = (uids ?? []).some((uid) => {
    const score = scoresArr.find((x) => x.uid === uid)
    return score && score.accessLevel === 'None'
  })

  if (!hasPrivateScores) return <>{children}</>

  const tooltipText = t`You don’t have view rights to one or more scores.`
  return (
    <Tooltip
      label={tooltipText}
      aria-label={tooltipText}
      hasArrow={true}
      placement="top"
    >
      {children}
    </Tooltip>
  )
}
