import React from 'react'
// biome-ignore lint/suspicious/noExplicitAny: legacy
export interface NamedCuiComponent<Props = any, Element = any>
  extends React.ForwardRefExoticComponent<
    Props & React.RefAttributes<Element>
  > {
  cuiName?: string
}

export interface NamedCuiElement {
  type?: NamedCuiComponent
  key: string | number | null
}

export function isCuiElement(
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  element: any,
  cuiNames: string[],
): element is NamedCuiElement {
  const cuiName = (element as NamedCuiElement).type?.cuiName
  if (!cuiName) return false
  return React.isValidElement(element) && cuiNames.indexOf(cuiName) !== -1
}
