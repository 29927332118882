import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

import { NamedCuiComponent } from '../cui'
import ListContext from './ListContext'

export type ListItemSecondaryActionProps = {
  showSecondaryAction?: boolean
} & Omit<BoxProps, 'icon'>

export const ListItemSecondaryAction = React.forwardRef<
  HTMLDivElement,
  ListItemSecondaryActionProps
>(function ListItemSecondaryAction({ showSecondaryAction: _, ...props }, ref) {
  const { disableGutters, dense } = React.useContext(ListContext)
  return (
    <Box
      ref={ref}
      position="absolute"
      top="50%"
      transform="translateY(-50%)"
      right={disableGutters ? 0 : dense ? 2 : 4}
      {...props}
    />
  )
}) as NamedCuiComponent

ListItemSecondaryAction.cuiName = 'ListItemSecondaryAction'
