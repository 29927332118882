import { t } from '@lingui/macro'
import React from 'react'

import { FilterValueLabel } from '../../components/FilterValueLabel'

export const KeyTopicValueLabel: React.FC<{ value?: string[] }> = ({
  value,
}) => {
  return <FilterValueLabel name={t`Key topic`} value={value} />
}
