import theme, { CapturiTheme } from './theme'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

export * from './ThemeProvider'

declare module '@emotion/react' {
  export interface Theme extends CapturiTheme {}
}

if (process.env.NODE_ENV === 'development' && window) {
  // Exposing theme on global window in development
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  ;(window as any).__theme__ = theme
}

// TODO: investigate: maybe chakra provides are way to use `useTheme` with our custom theme
function useCapturiTheme(): CapturiTheme {
  return useTheme() as CapturiTheme
}

export { theme, useCapturiTheme as useTheme }
export type { CapturiTheme }
export default styled
