import { FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useContext, useRef } from 'react'
import FolderPermissions from '../FolderPermissions'
import {
  PlaylistCreateResult,
  PlaylistSelectorContext,
} from './PlaylistSelector'

const PlaylistCreate: React.FC = () => {
  const {
    state,
    setCreateState: setState,
    id,
    isRequired,
  } = useContext(PlaylistSelectorContext)
  const inputRef = useRef<HTMLInputElement>(null)
  return (
    <Stack spacing={2}>
      <Input
        ref={inputRef}
        id={id}
        isRequired={isRequired}
        value={state.name}
        onChange={(event) =>
          setState({
            ...(state as PlaylistCreateResult),
            name: event.target.value,
          })
        }
        autoFocus
        placeholder={t`Create playlist ...`}
        size="sm"
        borderRadius="md"
      />
      <FormControl pl={1}>
        <FormLabel>
          <Trans>Access to the playlist</Trans>
        </FormLabel>
        <FolderPermissions
          value={state.permissions || null}
          onChange={(value) =>
            setState({
              ...(state as PlaylistCreateResult),
              permissions: value,
            })
          }
          fixCurrentUser
          fixCurrentUserRole
        />
      </FormControl>
    </Stack>
  )
}

export default PlaylistCreate
