import isEqual from 'react-fast-compare'

const createPatchPayload = <T extends object>(
  original: T | undefined,
  updated: Partial<T>,
): Partial<T> => {
  if (!original) return updated

  return Object.entries(updated).reduce<Partial<T>>((memo, [key, value]) => {
    if (!isEqual(original[key as keyof T], value))
      memo[key as keyof T] = value as T[keyof T]
    return memo
  }, {})
}

export default createPatchPayload
