import { TimeResolution } from '@capturi/core'
import { RequestOptions } from '@capturi/request'

import { baseQuery, controller } from '../constants'

const basePath = `${controller}/share`

export const hitRateInsightsAPI = {
  getShareStatisticsByUser: (): RequestOptions => ({
    url: `${basePath}`,
    query: baseQuery,
    method: 'post',
  }),
  getShareStatisticsByTeam: (): RequestOptions => ({
    url: `${basePath}/teams`,
    query: baseQuery,
    method: 'post',
  }),
  getShareStatisticsTimeSeries: (
    resolution: TimeResolution,
    offset: number,
  ): RequestOptions => ({
    url: `${basePath}/time-series`,
    query: {
      ...baseQuery,
      resolution,
      offset,
    },
    method: 'post',
  }),
  getUserShareStatisticsConversations: (userUid: string): RequestOptions => ({
    url: `${basePath}/${userUid}/conversations`,
    query: baseQuery,
    method: 'post',
  }),
  getTeamShareStatisticsConversations: (teamUid: string): RequestOptions => ({
    url: `${basePath}/team/${teamUid}/conversations`,
    query: baseQuery,
    method: 'post',
  }),
}
