import { ConversationScoreResult } from '@capturi/api-conversations'
import { Score } from '@capturi/api-scoring'
import { useMemo } from 'react'

type ConversationData = {
  uid: string
  score: number
  parameters: { uid: string; score: number }[]
}

export type ScoreWithConversationData = Score & {
  conversationData: ConversationData
}

export const useScoresWithConversationData = (
  scores: Score[] | undefined,
  conversationScores: ConversationScoreResult[],
): ScoreWithConversationData[] | undefined => {
  return useMemo(() => {
    const scoresWithConversationData = scores?.reduce<
      ScoreWithConversationData[]
    >((memo, score): ScoreWithConversationData[] => {
      Object.values(conversationScores).forEach((s) => {
        if (s.uid === score.uid) {
          memo.push({
            ...score,
            conversationData: {
              score: s.score,
              parameters: s.parameters,
              uid: score.uid,
            },
          })
        }
      })
      return memo
    }, [])
    return scoresWithConversationData?.sort(
      (a, b) => b.conversationData.score - a.conversationData.score,
    )
  }, [scores, conversationScores])
}
