import {
  CommentRecipients,
  CommentRecipientsResponseModel,
  commentsAPI,
} from '@capturi/api-comments'
import { useAPI } from '@capturi/api-utils'
import {
  ActionMeta,
  OnChangeValue,
  Select,
  SelectOption,
} from '@capturi/ui-select'
import { t } from '@lingui/macro'
import React, { useMemo } from 'react'

type Props = {
  conversationUid: string
  initialRecipientUserUids?: string[]
  autoFocus?: boolean
  onChange: (receptions: string[]) => void
}

const formatOption = (user: CommentRecipients): SelectOption => ({
  value: user.userUid,
  label: `${user.name} (${user.conversationRole})`,
})

const RecipientsSelector: React.FC<Props> = ({
  conversationUid,
  onChange,
  initialRecipientUserUids = [],
  autoFocus,
}) => {
  const { data: recipients } = useAPI<CommentRecipientsResponseModel>(
    commentsAPI.getRecipients(conversationUid),
    {
      revalidateOnFocus: false,
    },
  )

  const options = useMemo(
    () =>
      (recipients?.commentRecipients || [])
        .map(formatOption)
        .sort((a, b) => a.label.localeCompare(b.label)),
    [recipients],
  )

  const initialValue = useMemo(
    () =>
      initialRecipientUserUids.reduce<SelectOption[]>((memo, userUid) => {
        const user = recipients?.commentRecipients?.find(
          (value) => value.userUid === userUid,
        )
        if (user) memo.push(formatOption(user))
        return memo
      }, []),
    [recipients, initialRecipientUserUids],
  )

  const handleChange = (
    values: OnChangeValue<SelectOption, boolean>,
    action: ActionMeta<SelectOption>,
  ): void => {
    if (
      ['select-option', 'remove-value'].includes(action.action) &&
      Array.isArray(values)
    ) {
      onChange(values.map((x) => x.value))
    }
  }

  return (
    <div>
      <Select
        defaultValue={initialValue}
        isLoading={!recipients}
        isMulti
        options={options}
        placeholder={t`Select recipients ...`}
        onChange={handleChange}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        hideSelectedOptions={false}
        backspaceRemovesValue
        isClearable={false}
        autoFocus={autoFocus}
        minMenuHeight={100}
        maxMenuHeight={212}
      />
    </div>
  )
}

export default RecipientsSelector
