import {
  ChipsInput,
  Description,
  FormLabel,
  RadioButton,
  RadioButtonGroup,
} from '@capturi/ui-components'
import {
  Box,
  Collapse,
  FormControl,
  FormErrorMessage,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { Controller, Path, useController, useFormState } from 'react-hook-form'
import { MdTimer } from 'react-icons/md'

import { SpeakerField } from '../../../SpeakerField'
import { PhraseNearnessFormModel } from './PhraseNearnessForm'

export const PhraseNearnessFormContents: React.FC = () => {
  return (
    <Stack spacing={4}>
      <PhrasesField<PhraseNearnessFormModel> name="phrases" />
      <SpeakerField name="speakerId" />
      <DistanceField />
    </Stack>
  )
}

export type FormField<
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  TFormModel extends Record<string, any>,
  TName extends Path<TFormModel> = Path<TFormModel>,
> = {
  name: TName
  isRequired?: boolean
}
// biome-ignore lint/suspicious/noExplicitAny: legacy
export function PhrasesField<TFormModel extends Record<string, any>>({
  name,
  isRequired,
}: FormField<TFormModel>): React.ReactElement {
  const { errors } = useFormState()
  const isInvalid = errors[name] != null
  const toast = useToast()
  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel>
        <Trans>Words/phrases</Trans>
      </FormLabel>
      <Controller
        name={name}
        render={({ field }) => {
          return (
            <Box minH="5em">
              <ChipsInput
                autofocus={true}
                inputPlaceholderText={t`Add word/phrase`}
                value={field.value}
                onChange={field.onChange}
                onClipboardCopy={() => {
                  toast({
                    title: t`All phrases copied to clipboard`,
                  })
                }}
                maxSentenceLength={4}
              />
            </Box>
          )
        }}
        rules={{
          required: isRequired ? t`Add at least one word/phrase` : false,
        }}
      />
      <ErrorMessage
        name={name}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  )
}

const DEFAULT_DISTANCE = 5
const ENTIRE_CONVERSATION_VALUE = -1
const STEP_SIZE = 1
const MIN_CUSTOM_DISTANCE = 1

export function DistanceField(): React.ReactElement {
  const secondsBeforeController = useController<
    PhraseNearnessFormModel,
    'secondsBefore'
  >({
    name: 'secondsBefore',
    rules: {
      validate: {
        negativeOneOrLarger: (v) => {
          return (
            (v != null && v >= ENTIRE_CONVERSATION_VALUE) ||
            t`Please select a valid distance`
          )
        },
      },
    },
  })
  const secondsAfterController = useController<PhraseNearnessFormModel>({
    name: 'secondsAfter',
  })

  // Use the `secondsBefore` field as the primary field and copy all value changes to the `secondsAfter` field as well.
  const field = secondsBeforeController.field
  const isInvalid =
    Object.keys(secondsBeforeController.formState.errors).length > 0

  function handleChange(value: number | null): void {
    secondsBeforeController.field.onChange(value)
    secondsAfterController.field.onChange(value)
  }

  // Derive nearness type from value
  const nearnessType =
    field.value === ENTIRE_CONVERSATION_VALUE ? 'entire-conversation' : 'custom'

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel
        htmlFor="phrase-nearness-settings__nearness-type"
        leftIcon={<MdTimer />}
      >
        <Trans>Distance in seconds</Trans>
      </FormLabel>
      <RadioButtonGroup
        id="phrase-nearness-settings__nearness-type"
        value={nearnessType}
        onChange={(nextValue: typeof nearnessType) => {
          if (nextValue === 'entire-conversation') {
            handleChange(ENTIRE_CONVERSATION_VALUE)
          } else {
            handleChange(DEFAULT_DISTANCE)
          }
        }}
      >
        <RadioButton value={'custom'}>
          <Trans>Custom</Trans>
        </RadioButton>
        <RadioButton value={'entire-conversation'}>
          <Trans>Entire conversation</Trans>
        </RadioButton>
      </RadioButtonGroup>
      <Collapse in={nearnessType === 'custom'}>
        <HStack spacing={4} mt={2}>
          <Box w="5.2em">
            <NumberInput
              {...field}
              value={field.value ?? ''}
              onChange={(_valueString, valueNumber) => {
                const newValue = Number.isSafeInteger(valueNumber)
                  ? valueNumber
                  : null
                handleChange(newValue)
              }}
              min={MIN_CUSTOM_DISTANCE}
              step={STEP_SIZE}
              allowMouseWheel
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Description fontSize="sm">
            <Trans>Seconds (before and after)</Trans>
          </Description>
        </HStack>
      </Collapse>
      <ErrorMessage
        name={field.name}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  )
}
