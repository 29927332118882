import { Role } from '../domain/user'

export type UserPermissions = {
  organization: boolean
  editEverything: boolean
  editDashboard: boolean
  editTracker: boolean
  editSegment: boolean
  editScore: boolean
  editLibrary: boolean
  playback: boolean
  download: boolean
  editMasterTracker: boolean
  viewReports: boolean
  text: boolean
  qualityAssurance: boolean
  hideUserInfo: boolean
}

export type PermissionRow = {
  key: keyof UserPermissions
  name: string
  tooltip: string
  disabled?: boolean
  hidden?: boolean
}

export const applyPermissionsRules = (
  currentPermissions: UserPermissions,
  key: keyof UserPermissions,
  value: boolean,
): UserPermissions => {
  let newPermissions = {
    ...currentPermissions,
    [key]: value,
  }

  // When editEverything is enabled, we force-enable all the CRUD permissions
  if (key === 'editEverything' && value) {
    newPermissions = {
      ...newPermissions,
      editDashboard: true,
      editTracker: true,
      editSegment: true,
      editScore: true,
      editLibrary: true,
    }
  }

  // Download can only be enabled if playback is enabled
  if (key === 'playback' && !value) {
    newPermissions = {
      ...newPermissions,
      download: false,
    }
  }

  return newPermissions
}

export type PermissionPreset =
  | 'Inherit'
  | 'EveryoneCanView'
  | 'EveryoneCanEdit'
  | 'Private'
  | 'Locked'
  | 'Custom'

export type GrantedLevel = 'View' | 'Edit' | 'Block'
export type GrantedRole = 'User' | 'TeamLead' | 'Administrator' | 'API'

export type EveryonePermissionEntry = { level: GrantedLevel; type: 'everyone' }
export type RolePermissionEntry = {
  level: GrantedLevel
  role: GrantedRole
  type: 'role'
}
export type TeamPermissionEntry = {
  level: GrantedLevel
  teamUid: string
  type: 'team'
}
export type UserPermissionEntry = {
  level: GrantedLevel
  userUid: string
  type: 'user'
}

export type AclEntry =
  | EveryonePermissionEntry
  | RolePermissionEntry
  | TeamPermissionEntry
  | UserPermissionEntry

export type Acl = AclEntry[]

export type PreviewAccessRequestModel = {
  uid?: string
  folderUid?: string | null
  permissionPreset: PermissionPreset
  permissionPresetUserUid: string | null
  acl: Acl | null
}

export type PreviewAccessResponse = 'NoAccess' | 'Editable' | 'Viewable'

export const roleToGrantedRole = (role: Role): GrantedRole | undefined => {
  switch (role) {
    case 'user':
      return 'User'
    case 'teamlead':
      return 'TeamLead'
    case 'administrator':
      return 'Administrator'
  }
}

export const grantedRoleToRole = (role: GrantedRole): Role | undefined => {
  switch (role) {
    case 'User':
      return Role.user
    case 'TeamLead':
      return Role.teamlead
    case 'Administrator':
      return Role.administrator
  }
}
