import { useToken } from '@chakra-ui/react'
import React from 'react'
import { useCounter, useInterval } from 'react-use'
import { Area, ResponsiveContainer } from 'recharts'

import {
  CartesianGrid,
  TimeSeriesChart,
  XAxis,
  YAxis,
} from '../../../time-series/TimeSeriesChart'

const values = [10, 7, 7, 6, 4, 8, 5, 4, 6, 9]
const dataset = values.map((v, i) => ({ date: new Date(i), value: v }))

const preAnimationDelay = 100
const animationDuration = 800
const postAnimationDelay = 100

const totalAnimationDuration =
  preAnimationDelay + animationDuration + postAnimationDelay

export type LineChartSkeletonProps = {
  isLoaded?: boolean
  margin?: { top?: number; right?: number; bottom?: number; left?: number }
  children?: React.ReactNode
}
export const LineChartSkeleton: React.FC<LineChartSkeletonProps> = ({
  isLoaded,
  margin,
  children,
}) => {
  const [gray400] = useToken('colors', ['gray.400'])

  /**
   * Recharts line graphs have a nice animation when rendering a line initially.
   * We repeat this initial animation by forcing a remount of the skeleton chart
   * by changing it `key` prop in intervals.
   * It's a bit of a hack but recharts won't repeat the animation otherwise.
   */
  const [forceUpdateKey, { inc }] = useCounter(0)
  useInterval(() => inc(1), isLoaded ? null : totalAnimationDuration)

  if (isLoaded) {
    return <>{children}</>
  }

  return (
    <ResponsiveContainer width="100%" height="100%" minWidth={100}>
      <TimeSeriesChart data={dataset} key={forceUpdateKey} margin={margin}>
        <CartesianGrid />
        <XAxis tickFormatter={() => ''} />
        <YAxis tickFormatter={() => ''} />
        <Area
          type="natural"
          dataKey="value"
          stroke={gray400}
          strokeWidth={2}
          dot={false}
          fill="url(#gradient)"
          isAnimationActive
          animationEasing="ease-in-out"
          animationBegin={preAnimationDelay}
          animationDuration={animationDuration}
        />
      </TimeSeriesChart>
    </ResponsiveContainer>
  )
}
