import analytics, { EventParams } from '@capturi/analytics'

export const logEvent = (
  action: string,
  isAdvancedView: boolean,
  params: EventParams = {},
): void =>
  analytics.event(`trackerEditor_${action}`, {
    view: isAdvancedView ? 'advanced' : 'simple',
    ...params,
  })
