import { createPatchPayload } from '@capturi/api-shared'
import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import isEqual from 'react-fast-compare'

import {
  BaseTracker,
  PhraseSettings,
  Tracker,
  TrackerFormModel,
  UpdateTrackerPayload,
} from '../models'
import { baseUrl, cacheKey } from './constants'
import { TrackerResponse } from './useTrackers'

type UpdateTrackerFormModel = {
  uid: string
  tracker: Partial<TrackerFormModel>
}

export type MoveTrackerToFolderFormModel = {
  uid: string
  fields: UpdateTrackerPayload
}

export const useCreateTracker = (): UseMutationResult<
  Tracker,
  ResponseError,
  TrackerFormModel,
  ResponseError
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (model: TrackerFormModel) => {
      let speech: Tracker['speech'] | undefined = undefined
      if (model.speech) {
        const phrasesSettings =
          model.speech.phrases?.reduce<Record<string, PhraseSettings>>(
            (memo, word) => {
              memo[word.value] = word.settings
              return memo
            },
            {},
          ) ?? null

        const phrases = model.speech.phrases?.map((p) => p.value) ?? null
        speech = {
          phrases,
          phrasesSettings,
          speakerId: model.speech.speakerId,
          timeFilter: model.speech.timeFilter,
        }
      }
      const payload = { ...model, speech }
      return request.post<Tracker>(baseUrl, {
        json: payload,
      })
    },
    onSuccess: (newTracker) => {
      queryClient.setQueryData<TrackerResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { trackers: [newTracker] }
        }
        return { trackers: [newTracker, ...oldData.trackers] }
      })
    },
  })
}

export const useUpdateTracker = (): UseMutationResult<
  Tracker,
  ResponseError,
  UpdateTrackerFormModel,
  ResponseError
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey],
    mutationFn: ({ uid, tracker }: UpdateTrackerFormModel) => {
      const oldData = queryClient.getQueryData<TrackerResponse>([cacheKey])
      let speech: BaseTracker['speech'] | undefined | null
      if (tracker.speech == null) {
        speech = tracker.speech // use undefined or null
      } else if (tracker.speech.phrases == null) {
        speech = null
      } else {
        const trackerFormModelPhrases = tracker.speech.phrases.filter(
          (p) => p.value != null && p.value.trim().length > 0,
        )
        if (
          trackerFormModelPhrases == null ||
          trackerFormModelPhrases.length === 0
        ) {
          speech = null
        } else {
          const phrasesSettings =
            trackerFormModelPhrases?.reduce<Record<string, PhraseSettings>>(
              (memo, word) => {
                memo[word.value] = word.settings
                return memo
              },
              {},
            ) ?? null

          const phrases = trackerFormModelPhrases?.map((p) => p.value) ?? null

          speech = {
            phrases,
            phrasesSettings,
            speakerId: tracker.speech.speakerId,
            timeFilter: tracker.speech.timeFilter,
          }
        }
      }

      const oldTracker = oldData?.trackers.find(
        (tracker) => tracker.uid === uid,
      )

      const oldTargets = Object.keys(oldTracker?.masterSettings?.targets ?? {})

      const masterSettings = isEqual(
        { preset: oldTracker?.masterSettings?.preset, targets: oldTargets },
        {
          preset: tracker.masterSettings?.preset,
          targets: tracker.masterSettings?.targets,
        },
      )
        ? undefined
        : tracker.masterSettings

      const newTracker: Partial<BaseTracker> = {
        ...tracker,
        speech,
        masterSettings,
      }

      const payload = createPatchPayload(oldTracker, newTracker)
      return request.patch<Tracker>(`trackers/${uid}?api-version=3.3`, {
        json: { ...payload },
      })
    },
    onSuccess: (newTracker) => {
      queryClient.setQueryData<TrackerResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { trackers: [newTracker] }
        }
        return {
          trackers: oldData.trackers.map((f) =>
            f.uid === newTracker.uid ? newTracker : f,
          ),
        }
      })
      queryClient.setQueryData<Tracker>([cacheKey, newTracker.uid], () => {
        return newTracker
      })
    },
  })
}

export const useDeleteTracker = (): UseMutationResult<
  Tracker,
  ResponseError,
  string
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey],
    mutationFn: (trackerUid: string) =>
      request.delete<Tracker>(`trackers/${trackerUid}?api-version=3.3`),
    onSuccess: (_, trackerUid) => {
      queryClient.setQueryData<TrackerResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { trackers: [] }
        }
        return {
          trackers: oldData?.trackers.filter((f) => f.uid !== trackerUid),
        }
      })
    },
  })
}

export const useMoveToFolder = (): UseMutationResult<
  Tracker,
  ResponseError,
  MoveTrackerToFolderFormModel,
  ResponseError
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey],
    mutationFn: ({ uid, fields }: MoveTrackerToFolderFormModel) => {
      return request.patch<Tracker>(`trackers/${uid}?api-version=3.3`, {
        json: fields,
      })
    },
    onSuccess: (newTracker) => {
      queryClient.setQueryData<TrackerResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { trackers: [newTracker] }
        }
        return {
          trackers: oldData.trackers.map((f) =>
            f.uid === newTracker.uid ? newTracker : f,
          ),
        }
      })
    },
  })
}
