import { ColumnWithLooseAccessor } from 'react-table'

import { ColumnDefinition, ColumnType } from '../types'
import button from './button'
import dataBasis from './dataBasis'
import menu from './menu'
import progress from './progress'
import team from './team'
import user from './user'
import value from './value'

// biome-ignore lint/suspicious/noExplicitAny: legacy
type CreateColumnFn = (c: ColumnDefinition<any>) => ColumnWithLooseAccessor<any>

const columns: Record<ColumnType, CreateColumnFn> = {
  button,
  dataBasis,
  progress,
  user,
  value,
  menu,
  team,
}

export default columns
