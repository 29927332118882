export const basePath = '/analysis'

type Routes = {
  hitrate: string
}

export const getRoutes = (includeBasePath = true): Routes => {
  const prefix = includeBasePath ? basePath : ''
  return {
    hitrate: `${prefix}/hitrate`,
  }
}
