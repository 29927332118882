import { Box, BoxProps } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { forwardRef } from 'react'

const GridAreas = {
  Header: 'activity-layout__header',
  MediaView: 'activity-layout__media-view',
  DetailView: 'activity-layout__detail_view',
}

// Main
const MainGrid = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  return (
    <Box
      {...props}
      ref={ref}
      display="grid"
      gridTemplateAreas={`
        '${GridAreas.Header}'
        '${GridAreas.MediaView}'
        '${GridAreas.DetailView} '
      `}
      gridTemplateRows="min-content auto"
      marginLeft="0.5rem"
      paddingRight="0.75rem"
      overflowY="auto"
      overflowX="hidden"
      position="relative"
    />
  )
})

const Main = forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  <MainGrid ref={ref} {...props} />
))

const Header = styled.div`
  grid-area: ${GridAreas.Header};
`

const MediaView = styled.div`
  grid-area: ${GridAreas.MediaView};
`

const DetailView = styled.div`
  grid-area: ${GridAreas.DetailView};
`

export default {
  Main,
  Header: Header,
  Media: MediaView,
  Detail: DetailView,
}
