import { Box } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useCallback } from 'react'
import { OnChangeValue } from 'react-select'

import { Select } from './Select'

type Option = {
  value: string
  label: string
}

export const IsNotMenu: React.FC<{
  entityName: string
  isNot: boolean
  onChangeIsNot: (isNot: boolean) => void
}> = ({ entityName, isNot, onChangeIsNot }) => {
  const handleOnChange = useCallback(
    (newValue: OnChangeValue<Option, false>) => {
      onChangeIsNot(newValue?.value === 'isNot')
    },
    [onChangeIsNot],
  )

  return (
    <Box fontSize="md" fontWeight="medium" mx={2}>
      {`${entityName} `}
      <Box width="auto" display="inline-flex">
        <Select
          styles={{
            group: (base) => ({
              ...base,
              paddingTop: 4,
              paddingBottom: 0,
            }),
          }}
          options={[
            { value: 'is', label: t`is` },
            { value: 'isNot', label: t`is not` },
          ]}
          value={
            isNot
              ? { value: 'isNot', label: t`is not` }
              : { value: 'is', label: t`is` }
          }
          onChange={handleOnChange}
        />
      </Box>{' '}
      <Trans>included</Trans>
    </Box>
  )
}
