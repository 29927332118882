import { Color, Shape } from '@capturi/sharing'
import { useMemo } from 'react'
import { Hit } from '../Audio/types'
import {
  Result,
  TrackerTableContentsResult,
} from '../Trackers/useTrackerTableContents'

type TrackerHitUtils = {
  convertResultToHit: (
    hit: Result,
    isSelected: boolean,
    shape?: Shape,
    color?: Color,
    isDimmed?: boolean,
  ) => Hit
  shapeForHit: (
    hit: Result,
    contents: TrackerTableContentsResult,
  ) => Shape | undefined
  colorForHit: (
    hit: Result,
    contents: TrackerTableContentsResult,
  ) => Color | undefined
}

export const useTrackerHitUtils = (): TrackerHitUtils => {
  return useMemo(() => {
    const convertResultToHit = (
      hit: Result,
      isSelected: boolean,
      shape?: Shape,
      color?: Color,
      isDimmed?: boolean,
    ): Hit => {
      const hits: Hit = {
        id: `${hit.trackerUid}:${hit.words.join('-')}:${hit.timestamp}:${
          hit.speakerId
        }`,
        title: isSelected ? hit.words.join(', ') : hit.trackerName ?? '',
        timestamp: hit.timestamp,
        speakerId: hit.speakerId,
        color: color,
        shape: shape,
        isDimmed: isDimmed,
        tooltipIsOpen: isSelected,
      }
      return hits
    }

    const shapeForHit = (
      hit: Result,
      contents: TrackerTableContentsResult,
    ): Shape | undefined => {
      return hit.trackerUid != null
        ? contents.trackersShapeColors?.[hit.trackerUid]?.shape
        : undefined
    }

    const colorForHit = (
      hit: Result,
      contents: TrackerTableContentsResult,
    ): Color | undefined => {
      return hit.trackerUid != null
        ? contents.trackersShapeColors?.[hit.trackerUid]?.color
        : undefined
    }

    return { convertResultToHit, shapeForHit, colorForHit }
  }, [])
}
