import { Message } from '@capturi/api-cases/src/models'
import { useTrackers } from '@capturi/api-trackers'
import { useScrollable } from '@capturi/react-utils'
import { Colors, Shapes } from '@capturi/sharing'
import { Button } from '@capturi/ui-components'
import { Box, Card, Flex, HStack, Icon, Text, Tooltip } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, {
  startTransition,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { MdComment, MdDownload, MdLibraryAdd, MdUpload } from 'react-icons/md'

import CaseTrackersView from './CaseTrackersView'
import MessageBubble from './MessageBubble'
import { TrackerShape } from './types'

type Props = {
  messages: Message[]
  messageTypes: {
    Inbound: number
    Outbound: number
    AutoReply: number
  }
  scrollToMessageRef: React.RefObject<HTMLDivElement>
  initialTrackerUid?: string
  messageUidToScrollTo?: string | undefined
}

const Messages: React.FC<Props> = ({
  messages,
  messageTypes,
  scrollToMessageRef,
  initialTrackerUid,
  messageUidToScrollTo,
}) => {
  const [selectedTrackerUid, setSelectedTrackerUid] = useState<string | null>(
    initialTrackerUid ?? null,
  )

  const { data: trackers } = useTrackers()

  const trackerMap = useMemo(() => {
    const trackerHits = messages.reduce<Map<string, number>>(
      (memo, message) => {
        message.trackerHits?.forEach((m) => {
          Object.values(m.phrases).map((t) => {
            const initialCount = memo.get(m.trackerUid) || 0
            memo.set(m.trackerUid, t.length + initialCount)
          })
        })
        return memo
      },
      new Map(),
    )

    const trackerMap = new Map(trackers?.map((t) => [t.uid, t.name]))

    return Array.from(trackerHits).reduce<Map<string, TrackerShape>>(
      (memo, [key, value], index) => {
        const trackerName = trackerMap.get(key)
        if (trackerName) {
          memo.set(key, {
            uid: key,
            name: trackerName,
            count: value,
            color: Colors[index % Colors.length],
            shape: Shapes[Math.floor(index / Colors.length) % Shapes.length],
          })
        }
        return memo
      },
      new Map(),
    )
  }, [messages, trackers])

  const trackersList = useMemo(
    () => Array.from(trackerMap, ([_, value]) => value),
    [trackerMap],
  )

  const handleSelectTracker = useCallback(
    (trackerUid: string): void => {
      startTransition(() => {
        if (selectedTrackerUid === trackerUid) {
          setSelectedTrackerUid(null)
        } else {
          setSelectedTrackerUid(trackerUid)
        }
      })
    },
    [selectedTrackerUid],
  )

  const trackerRef = useRef<HTMLDivElement>(null)
  const trackerListHeight = useScrollable(trackerRef, 8)

  return (
    <HStack alignItems="flex-start" gap="6">
      <Card
        boxShadow="none"
        border="1px solid"
        borderColor="gray.200"
        flexGrow="1"
      >
        <Box
          py="14px"
          px={8}
          alignItems="center"
          _focus={{ outline: undefined }}
          _hover={{ bg: 'unset' }}
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          <HStack w="100%" gap="2" justify="flex-end">
            <Tooltip
              aria-label={t`Not available on text`}
              label={t`Not available on text`}
              hasArrow
              placement="top"
            >
              <Button
                size="sm"
                color="gray.400"
                pl={4}
                leftIcon={<MdComment />}
                variant="ghost"
              >
                <Trans>Add comment</Trans>
              </Button>
            </Tooltip>
            <Tooltip
              aria-label={t`Not available on text`}
              label={t`Not available on text`}
              hasArrow
              placement="top"
            >
              <Button
                size="sm"
                color="gray.400"
                pl={4}
                leftIcon={<MdLibraryAdd />}
                variant="ghost"
              >
                <Trans>Add to library</Trans>
              </Button>
            </Tooltip>
            <HStack gap={2}>
              <Tooltip label={t`Received`} hasArrow placement="top">
                <HStack gap="0" align="center">
                  <Icon boxSize="16px" as={MdDownload} />
                  <Text color="gray.600">{messageTypes.Inbound}</Text>
                </HStack>
              </Tooltip>
              <Tooltip label={t`Sent`} hasArrow placement="top">
                <HStack gap="0" align="center">
                  <Icon boxSize="16px" as={MdUpload} />
                  <Text color="gray.600">
                    {messageTypes.Outbound + messageTypes.AutoReply}
                  </Text>
                </HStack>
              </Tooltip>
            </HStack>
          </HStack>
        </Box>
        <Flex
          px={3}
          py={2}
          flexDirection="column"
          bg="accents.lightBackground.default"
        >
          {messages
            .sort((a, b) => a.created.getTime() - b.created.getTime())
            .map((m) => (
              <MessageBubble
                key={m.uid}
                trackersMap={trackerMap}
                selectedTrackerUid={selectedTrackerUid}
                message={m}
                scrollToMessageRef={
                  messageUidToScrollTo === m.uid ? scrollToMessageRef : null
                }
              />
            ))}
        </Flex>
      </Card>

      <Box
        w="230px"
        flexShrink={0}
        position="sticky"
        top="0"
        ref={trackerRef}
        h={trackerListHeight}
        overflowY="auto"
      >
        <CaseTrackersView
          trackerList={trackersList}
          selectedTrackerUid={selectedTrackerUid}
          onSelectTracker={handleSelectTracker}
        />
      </Box>
    </HStack>
  )
}

export default Messages
