import { useState } from 'react'
import { DEFAULT_LANGUAGE, isSupportedLanguage } from './languages'

export default function usePreferredLanguage(
  initialLanguage?: string,
): [string, (language: string) => void] {
  return useState(() => {
    if (initialLanguage && isSupportedLanguage(initialLanguage)) {
      return initialLanguage
    }

    // Inspect browser preferred languages
    const matches = window.navigator.languages
      .map((bcp47Code) => bcp47Code.split('-')[0])
      .filter(isSupportedLanguage)

    if (matches.length > 0) {
      // the first element is the browser language, but we prefer
      // danish if present in the array
      const bestMatch = matches.includes('da') ? 'da-DK' : matches[0]
      return bestMatch
    }

    return DEFAULT_LANGUAGE
  })
}
