import { Box, Flex } from '@chakra-ui/react'
import React from 'react'

import { valueToPercent } from '../utils'

type Props = {
  value?: number
  minValue?: number
  maxValue?: number
  label?: string
  color?: string
}

const ReferenceLine: React.FC<Props> = ({
  value,
  minValue = 0,
  maxValue = 100,
  label,
  color,
}) => {
  if (value === undefined) return null
  const baseColor = 'gray.700'
  const pct = valueToPercent(value, minValue, maxValue)
  return (
    <Box
      position="absolute"
      top={0}
      bottom={0}
      left={`${pct}%`}
      w="1px"
      bg="transparent"
      borderLeftWidth="1px"
      borderLeftColor={baseColor}
      boxShadow="1px 0 2px 0px rgba(0,0,0,0.3)"
      _hover={{
        zIndex: 1,
      }}
    >
      {label != null && (
        <Box
          position="absolute"
          top={0}
          left={0}
          fontSize="sm"
          bg={baseColor}
          color="white"
          whiteSpace="nowrap"
          transform="translateX(-50%)"
          lineHeight={1}
          py={1}
          px={2}
          borderRadius="full"
          boxShadow="2px 2px 2px 0 rgba(0,0,0,0.2)"
        >
          <Flex align="center">
            {color && (
              <Box
                boxSize={2}
                bg={`${color}.500`}
                borderRadius="sm"
                border="1px solid white"
                mr={1}
                aria-hidden
              />
            )}
            <Box>{label}</Box>
          </Flex>
        </Box>
      )}
    </Box>
  )
}

export default ReferenceLine
