import {
  BaseTracker,
  isTrackerPrivate,
  useAllTrackers,
  useTrackerByUid,
} from '@capturi/api-trackers'
import { t } from '@lingui/macro'
import React, { useMemo } from 'react'

import { TextSegmentBuilderState } from '../types'
import {
  hasFilterValues,
  textSegmentHasUnsavedValuesChanges,
} from '../utils/filterState'

type ValidationResult = {
  isValid: boolean
  notValidReason: string | undefined
  notValidSegmentReason?: string | undefined
}

export function useValidateAsSavedTextSegment(
  state?: TextSegmentBuilderState,
): ValidationResult {
  const { data: trackers } = useAllTrackers()
  const trackersByUid = useTrackerByUid(trackers)

  return React.useMemo(() => {
    if (state === undefined) {
      return {
        isValid: false,
        notValidReason: undefined,
      }
    }

    if (state.savedTextFilter?.accessLevel === 'None')
      return {
        isValid: false,
        notValidReason: t`You don’t have view rights to the selected segment. Remove or change segment in order to save.`,
        notValidSegmentReason: t`You don’t have view rights to this segment. You can’t save the widget, but can view results.`,
      }

    const { hasInaccessibleTrackers } = [
      ...(state.values.trackers?.flatMap((t) => t.uids ?? []) ?? []),
    ].reduce(
      (memo, trackerUid) => {
        const tracker = trackersByUid?.[trackerUid]
        if (tracker === undefined) return memo

        // Inaccessible tracker
        if (tracker.accessLevel === 'None') {
          memo.hasInaccessibleTrackers = true
          return memo
        }

        const accessibleTracker = tracker as BaseTracker
        if (isTrackerPrivate(accessibleTracker)) {
          memo.hasPrivateTrackers = true
        }

        return memo
      },
      {
        hasPrivateTrackers: false,
        hasInaccessibleTrackers: false,
      },
    )

    if (hasInaccessibleTrackers) {
      return {
        isValid: false,
        notValidReason: t`You don’t have view rights to one or more filters. Remove or change this filter in order to save the segment`,
        notValidSegmentReason: t`You don’t have view rights to one or more filters in the segment.`,
      }
    }

    return {
      isValid: true,
      notValidReason: undefined,
    }
  }, [state, trackersByUid])
}

export function useSaveButtonState(state?: TextSegmentBuilderState): {
  isSaveDisabled: boolean
  saveDisabledReason: string | undefined
} {
  const validAsSavedSegmentResult = useValidateAsSavedTextSegment(state)
  const hasValues = useMemo(
    () => hasFilterValues(state?.values),
    [state?.values],
  )

  return useMemo(() => {
    if (state === undefined) {
      return {
        isSaveDisabled: true,
        saveDisabledReason: undefined,
      }
    }

    // Does any of the filter state keys have any values
    const hasUnsavedValues = textSegmentHasUnsavedValuesChanges(state)

    const isInvalidAsSavedSegment =
      typeof validAsSavedSegmentResult === 'boolean'
        ? validAsSavedSegmentResult
        : validAsSavedSegmentResult?.isValid === false

    const saveDisabledReason =
      typeof validAsSavedSegmentResult !== 'boolean'
        ? validAsSavedSegmentResult?.notValidReason
        : undefined

    const isSaveDisabled =
      // 1. Invalid as saved segment
      isInvalidAsSavedSegment ||
      // 2. Does not have any values to save
      !hasValues ||
      // 3. No unsaved changes
      (state.savedTextFilter != null && !hasUnsavedValues)
    return {
      isSaveDisabled,
      saveDisabledReason,
    }
  }, [hasValues, state, validAsSavedSegmentResult])
}
