import { Badge, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'

import { formatArray } from '../utils'

export const TagsTooltip: React.FC<{
  entries: string[]
  isAccordion: boolean
}> = ({ entries }) => {
  return (
    <Tooltip
      placement="top"
      hasArrow
      label={formatArray(entries).map((name, index) => (
        <Text mr={1} as="span" whiteSpace="pre" key={index}>
          {name}
        </Text>
      ))}
    >
      <Badge
        className="item"
        px={2}
        borderRadius="4px"
        marginInlineStart="0rem !important"
        backgroundColor="gray.300"
      >
        ...
      </Badge>
    </Tooltip>
  )
}
