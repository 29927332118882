import { i18n } from '@lingui/core'
import sumBy from 'lodash/sumBy'

export function avgBy<T>(
  list: Array<T>,
  iteratee: (value: T) => number,
): number {
  if (list.length === 0) {
    return 0
  }
  const sum = sumBy(list, iteratee)
  return sum / list.length
}

export const numberFormat: Intl.NumberFormatOptions = {
  style: 'decimal',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
}

export const pctFormat: Intl.NumberFormatOptions = {
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
}

export const formatValue = (
  value?: number,
  formatOptions: Intl.NumberFormatOptions = numberFormat,
): string => {
  if (value === undefined) return ''
  return i18n.number(value, formatOptions)
}
