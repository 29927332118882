import { useCurrentUser } from '@capturi/core'
import { User, useUsers } from '@capturi/stores'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React from 'react'

type TrackerDescriptionItemProps = {
  date: Date
  userUid: string
  label: string
}

const TrackerDescriptionItem: React.FC<TrackerDescriptionItemProps> = ({
  userUid,
  date,
  label,
}) => {
  const { i18n } = useLingui()
  const currentUser = useCurrentUser()
  const { getUserByUid } = useUsers()

  const getValidDate = (date: Date | undefined): Date | undefined => {
    if (date === undefined) return undefined
    const millis = date.getTime()
    if (millis > 0) return date
    return undefined
  }

  const getValidUser = (uid: string | undefined): User | undefined => {
    if (uid === undefined) return undefined
    return getUserByUid(uid)
  }

  /**
   * the dates `updated` and `created` were introduced to the tracker model at a later point meaning that all tracker existing trackers before that point do not have a valid date object which we have to deal with
   */
  const updatedOn = getValidDate(date)
  /**
   * `updatedByUserUid` and `created` were introduced to the tracker model at a later point meaning that all tracker existing trackers before that point do not have a valid user uid value (00000000-0000-0000-0000-000000000000)
   */
  const user = getValidUser(userUid)
  const isCurrentUser = currentUser.id === userUid

  const youMessage = t`you`
  const updatedByMessage = user
    ? t`${label} by ${isCurrentUser ? youMessage : user.name}`
    : undefined
  const formattedTimestamp = updatedOn
    ? i18n.date(updatedOn, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })
    : undefined
  return (
    <span>
      {[updatedByMessage, formattedTimestamp].filter(Boolean).join(', ')}
    </span>
  )
}

export default TrackerDescriptionItem
