import { GrantedLevel } from '@capturi/core'
import { Flex, IconButton, Spacer } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { MdClose } from 'react-icons/md'

import GrantedLevelSelect from '../../GrantedLevelSelect'
import { RoleView } from '../../RoleView'
import { RoleEntry } from '../domain'

export const AclRoleRow: React.FC<{
  roleEntry: RoleEntry
  onChangeGrantedLevel: (grantedLevel: GrantedLevel) => void
  onRemove: () => void
}> = ({ roleEntry, onChangeGrantedLevel, onRemove }) => {
  return (
    <Flex px={2} py={1} align="center" w="100%" gap={1}>
      <RoleView grantedRole={roleEntry.role} />

      <Spacer />
      <GrantedLevelSelect
        value={roleEntry.level}
        onChange={onChangeGrantedLevel}
      />
      <IconButton
        icon={<MdClose />}
        aria-label={t`Remove`}
        variant="ghost"
        onClick={onRemove}
      />
    </Flex>
  )
}
