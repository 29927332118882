import { PermissionPreset } from '@capturi/core'
import {
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Radio,
  Stack,
  Text,
  VStack,
  useRadioGroup,
} from '@chakra-ui/react'
import React from 'react'

import { Option } from './getSharingOptions'

export const ShareAndEditOptionComponent: React.FC<{
  permissionPreset: PermissionPreset | undefined
  onChange: (permissionPreset: PermissionPreset) => void
  categoryTitle: string
  flexBasis: string
  optionsByCategory: Option[]
  isDisabled?: boolean
}> = ({
  permissionPreset,
  onChange,
  categoryTitle,
  flexBasis,
  optionsByCategory,
  isDisabled = false,
}) => {
  const { getRadioProps, getRootProps } = useRadioGroup({
    name: 'permissionPreset',
    value: permissionPreset,
    onChange: onChange,
  })

  return (
    <VStack align="start" spacing={2}>
      <VStack {...getRootProps()} spacing="2" w="100%">
        <Flex w="100%" alignItems="center">
          <Text
            as="span"
            color="gray.400"
            fontSize="sm"
            fontWeight="medium"
            flexBasis={flexBasis}
            float="left"
          >
            {categoryTitle}
          </Text>
          <Divider borderColor="gray.300" display="inline-block" />
        </Flex>
        {optionsByCategory.map((option) => {
          const radioProps = getRadioProps({ value: option.value })
          return (
            <Radio
              w="100%"
              {...radioProps}
              key={option.value}
              isDisabled={isDisabled}
            >
              <Stack>
                <Grid
                  templateColumns="auto 1fr"
                  gap={3}
                  ml={2}
                  alignItems="center"
                >
                  <GridItem>
                    <Flex
                      bg={
                        radioProps.isChecked
                          ? 'primaryGradient'
                          : 'accents.lightBackground.default'
                      }
                      border="gray.100 1px solid"
                      shadow="base"
                      borderRadius={4}
                      boxSize={9}
                      alignItems="center"
                      justifyContent="center"
                      color={radioProps.isChecked ? 'white' : 'gray.800'}
                    >
                      {<Icon as={option.icon} boxSize={6} />}
                    </Flex>
                  </GridItem>
                  <GridItem>
                    <VStack spacing={0} alignItems="start">
                      <Text fontSize="sm" fontWeight="medium">
                        {option.label}
                      </Text>
                      <Text fontSize="xs" color="gray.600">
                        {option.description}
                      </Text>
                    </VStack>
                  </GridItem>
                </Grid>
              </Stack>
            </Radio>
          )
        })}
      </VStack>
    </VStack>
  )
}
