import { Box, Flex } from '@chakra-ui/react'
import React, { ComponentType, useEffect, useRef } from 'react'

import { useCase } from '@capturi/api-cases'
import { usePageTitle } from '@capturi/react-utils'
import { t } from '@lingui/macro'
import Header from './Header'
import HeaderSkeleton from './Header/HeaderSkeleton'
import Messages from './content/Messages'
import MessagesSkeleton from './content/MessagesSkeleton'

const CaseDetails: React.FC<{
  caseUid: string
  initialTrackerUid?: string
  messageUidToScrollTo?: string
  HeaderWrapper?: ComponentType<{ children: React.ReactNode }>
  separateHeader?: boolean
  onCaseDeleted: () => void
}> = ({
  caseUid,
  initialTrackerUid,
  messageUidToScrollTo,
  HeaderWrapper = ({ children }) => <>{children}</>,
  separateHeader = false,
  onCaseDeleted,
}) => {
  const { data: singleCase, isLoading } = useCase(caseUid)
  usePageTitle(t`Conversation: ${singleCase?.subject}`)

  const scrollToMessageRef = useRef<HTMLDivElement | null>(null)
  const scrolledToInitialMessage = useRef<boolean>(false)
  useEffect(() => {
    if (
      messageUidToScrollTo &&
      !scrolledToInitialMessage.current &&
      !isLoading &&
      singleCase &&
      singleCase.messages.length > 0
    ) {
      if (singleCase.messages[0].uid === messageUidToScrollTo) {
        // we don't need to scroll if it's first message
        scrolledToInitialMessage.current = true
      } else {
        if (scrollToMessageRef.current) {
          scrollToMessageRef.current.scrollIntoView({
            behavior: 'instant',
            block: 'start',
          })
          scrolledToInitialMessage.current = true
        }
      }
    }
  }, [isLoading, singleCase, messageUidToScrollTo])

  if (isLoading || !singleCase) {
    return (
      <Box w="100%" h="100%">
        <HeaderWrapper>
          <HeaderSkeleton />
        </HeaderWrapper>
        <MessagesSkeleton />
      </Box>
    )
  }

  return (
    <Flex direction="column" h="100%" alignItems="stretch" w="100%">
      {separateHeader && (
        <HeaderWrapper>
          <Header
            singleCase={singleCase}
            userUids={singleCase.users}
            onCaseDeleted={onCaseDeleted}
          />
        </HeaderWrapper>
      )}
      <Flex
        bg="white"
        p={4}
        pr={0}
        pb={0}
        outline="1px solid"
        outlineColor="gray.200"
        borderRadius="4px"
        overflowY="auto"
        direction="column"
        gap={4}
        outlineOffset="-1px"
      >
        {!separateHeader && (
          <HeaderWrapper>
            <Header
              singleCase={singleCase}
              userUids={singleCase.users}
              onCaseDeleted={onCaseDeleted}
            />
          </HeaderWrapper>
        )}
        <Messages
          key={singleCase.uid}
          messages={singleCase.messages}
          messageTypes={singleCase.messageTypes}
          initialTrackerUid={initialTrackerUid}
          messageUidToScrollTo={messageUidToScrollTo}
          scrollToMessageRef={scrollToMessageRef}
        />
      </Flex>
    </Flex>
  )
}

export default CaseDetails
