import { AccessLevel, DependentItem, MasterPreset } from '@capturi/api-shared'
import { Acl, PermissionPreset, Speaker } from '@capturi/core'
export enum TimeFilterKind {
  ENTIRECONVERSATION = 'ENTIRECONVERSATION',
  FROMSTART = 'FROMSTART',
  FROMENDING = 'FROMENDING',
  RANGE = 'RANGE',
}

type TrackerSpeech = {
  phrases: string[] | null
  speakerId: Speaker
  phrasesSettings: Record<string, PhraseSettings> | null
  timeFilter: {
    kind: TimeFilterKind
    seconds: number | undefined
    secondsEnd: number | null
  }
}

type TrackerText = {
  phrases: string[] | null
  messageFilters: MessageFilters | null
  globalSettings: TextGlobalSettings
}

export type TextGlobalSettings = {
  near: string[]
  notNear: string[]
}

/**
 * A basic Tracker model
 */
export type BaseTracker = {
  uid: string
  name: string
  description: string
  accessLevel: AccessLevel
  isProcessing: boolean
  processingProgress: Date | null
  isTextProcessing: boolean
  textProcessingProgress: Date | null
  tags: string[]
  updated: Date
  updatedByUserUid: string
  created: Date
  createdByUserUid: string
  permissionPreset: PermissionPreset
  acl: Acl | null
  folderUid?: string | null
  permissionPresetUserUid: string | null
  isMasterTarget: boolean
  masterSettings?: {
    preset: MasterPreset
    targets:
      | string[]
      | {
          trackerUid: string
          userUid: string
        }
  } | null
  speech: TrackerSpeech | null
  text: TrackerText | null
}

/**
 * An extended Tracker model which is the response model of
 * /trackers/{uid}
 */
export type Tracker = BaseTracker

export type PhrasePrecision = 'HigherAccuracy' | 'MoreHits'

/*
TODO: fix type of phraseSettings
 PhraseSettings near and notNear 
 - can be undefined when creating and updating trackers 
 - is null or `PhraseNearnessSettings` when it comes from /tracker api
*/
export type PhraseSettings = {
  near?: PhraseNearnessSettings | null
  notNear?: PhraseNearnessSettings | null
  precision?: PhrasePrecision | null
}

export type PhraseNearnessSettings = {
  phrases: string[]
  speakerId: number
  secondsBefore: number
  secondsAfter: number
}

export type InacessibleTracker = Pick<
  BaseTracker,
  'uid' | 'name' | 'accessLevel'
>

export type TrackerListItem<IncludeInaccessible extends boolean = false> =
  IncludeInaccessible extends true ? Tracker | InacessibleTracker : Tracker

export type CreateTrackerPayload = {
  name: string
  description: string
  tags: string[]
  permissionPreset: PermissionPreset
  acl: Acl | null
  folderUid: string | null
  permissionPresetUserUid: string | null
  masterSettings?: {
    preset: MasterPreset
    targets: string[]
  } | null
  speech?: TrackerSpeech | null
  text?: TrackerText | null
}

export type MessageTypes = 'Inbound' | 'Outbound' | 'AutoReply'
export enum MessagePosition {
  Any = 'Any',
  First = 'First',
  Last = 'Last',
}
export type MessageFields = 'Subject' | 'Text'

export type MessageFilters = {
  messageTypes: MessageTypes[] | null
  messagePosition: MessagePosition
  messageFields: MessageFields[] | null
}

export type TrackerFormModel = {
  uid?: string
  name: string
  tags: string[]
  description: string
  speech?: {
    phrases: PhraseState[] | undefined
    speakerId: Speaker
    timeFilter: {
      kind: TimeFilterKind
      seconds: number | undefined
      secondsEnd: number | null
    }
  } | null
  text?: {
    phrases: string[] | null
    messageFilters: MessageFilters | null
    globalSettings: {
      near: string[]
      notNear: string[]
    }
  } | null
  permissionPreset: PermissionPreset
  acl: Acl | null
  folderUid: string | null
  permissionPresetUserUid: string | null
  masterSettings?: {
    preset: MasterPreset
    targets: string[]
  } | null
}

export type PhraseState = {
  value: string
  settings: PhraseSettings
  precision?: PhrasePrecision
}

export type UpdateTrackerPayload = Partial<CreateTrackerPayload>

export type TrackerResponseModel<IncludeInaccessible extends boolean> = {
  trackers: TrackerListItem<IncludeInaccessible>[]
}

export type TagsResponseModel = {
  tags: string[]
}

export type PreviewTrackerHitsRequestModel = {
  phrases: string[]
  count: number
  speakerId: Speaker
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  filters: Record<string, any>
  timeFilter: {
    kind: TimeFilterKind
    seconds: number | undefined
    secondsEnd: number | null
  }
  phrasesSettings: Record<string, PhraseSettings> | null
}

export type PreviewTrackerHitsPhrase = {
  phrase: string
  conversations: PreviewTrackerHitsConversation[]
}

type PreviewTrackerHitsConversation = {
  uid: string
  userUid: string
  dateTime: Date
  customer: string
  duration: number
  subject: string
  labels: string[]
  status: string // AdversusStatus ...
  deleted: boolean
  hasAudio: boolean
  hits: PreviewTrackerHitsHits[]
}

type PreviewTrackerHitsHits = {
  when: number
  speakerUserUid: string
  speakerId: Speaker
}

export type PreviewTrackerHitsResponseModel = {
  // Total number of conversations included in preview
  conversationsTotal: number
  // Number of conversations with at least one hit for any phrase
  conversations: number
  // All phrases searched for
  phrases: PreviewTrackerHitsPhrase[]
}

export type GetTrackerDependentsResponseModel = {
  dependents: DependentItem[]
}

// Folders

export type TrackerFolder = {
  uid: string
  title: string
  permissionPreset: PermissionPreset
  acl: Acl | null
  permissionPresetUserUid: string | null
  accessLevel: AccessLevel
  isMasterTarget: boolean
  masterSettings: {
    preset: MasterPreset
    targets: {
      folderUid: string
      userUid: string
    }
  } | null
}

export type InacessibleTrackerFolder = Pick<TrackerFolder, 'uid' | 'title'>

export type CreateTrackerFolderPayload = {
  title: string
  permissionPreset: PermissionPreset
  acl: Acl | null
  permissionPresetUserUid: string | null
  masterSettings?: {
    preset: MasterPreset | undefined
  } | null
}

export type UpdateTrackerFolderPayload = Partial<CreateTrackerFolderPayload>
