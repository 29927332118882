import { UserPermissions } from '../utils/permissions'
import { Team } from './team'

export enum Role {
  user = 'user',
  administrator = 'administrator',
  teamlead = 'teamlead',
}

export enum InviteStatus {
  accepted = 'accepted',
  sent = 'sent',
  unknown = 'unknown',
}

export type User = {
  id: string
  name: string
  organizationId?: string
  profileImage?: { key: string }
  email?: string
  title?: string
  role?: Role
  teamUid?: Team['uid']
  teamLeadTeamUids?: Team['uid'][]
  inviteStatus: InviteStatus
  isDeleted?: boolean
}

export type UserDetails = {
  organizationId: string
  id: string
  email: string
  name: string
  profileImage: {
    key: string
    type: string
  } | null
  title: string
  role: Role
  inviteStatus: 'unknown' | 'accepted' | 'sent'
  team: {
    uid: string
    name: string
  } | null
  teamLeadTeams:
    | {
        uid: string
        name: string
      }[]
    | null
  mostRecentConversation: Date | null
  permissions: Partial<UserPermissions>
}
