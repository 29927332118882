import { PhrasePrecision } from '@capturi/api-trackers'
import { Button } from '@capturi/ui-components'
import { PopoutSelect, SelectOption } from '@capturi/ui-select'
import { Icon, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { MdArrowDropDown } from 'react-icons/md'
import { CellProps } from 'react-table'

import { PhraseState } from '../../../../types'
import { logEvent } from '../../logEvent'

type PrecisionOption = SelectOption & {
  label: string
  value: 'Default' | PhrasePrecision
}

const precisionOptionToPhrasePrecision = (
  precisionOption: PrecisionOption,
): PhrasePrecision | undefined => {
  switch (precisionOption.value) {
    case 'HigherAccuracy':
      return 'HigherAccuracy'
    case 'MoreHits':
      return 'MoreHits'
    default:
      return undefined
  }
}

export function PrecisionCell(
  props: CellProps<PhraseState, PhrasePrecision>,
): React.ReactElement {
  const { value: settings, row, updatePhraseField } = props

  const DefaultPrecisionOption: PrecisionOption = {
    name: t`Default`,
    label: t`Default`,
    value: 'Default',
  }
  const PrecisionOptions: PrecisionOption[] = [
    {
      name: t`High`,
      label: t`High (only allows hits with HIGH probability)`,
      value: 'HigherAccuracy',
    },
    DefaultPrecisionOption,
    {
      name: t`Low`,
      label: t`Low (also allows hits with LESS probability)`,
      value: 'MoreHits',
    },
  ]

  const selectedPrecisionOption = PrecisionOptions.find((option) => {
    return settings === option.value
  })
  return (
    <Popover placement="bottom-start">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              minW="100px"
              justifyContent="space-between"
              variant="outline"
              px="8px"
              py="5px"
              rightIcon={<Icon as={MdArrowDropDown} boxSize="20px" />}
            >
              {selectedPrecisionOption?.name ?? DefaultPrecisionOption.name}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoutSelect
              value={selectedPrecisionOption}
              onChange={(newValue) => {
                if (newValue == null) {
                  return
                }

                updatePhraseField(row.index, {
                  settings: {
                    precision: precisionOptionToPhrasePrecision(newValue),
                  },
                })
                logEvent(`_probability:${newValue.value}`, true)
                onClose()
              }}
              options={PrecisionOptions}
              isSearchable={false}
            />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
