import { useFirstPhoneSegmentState } from '@capturi/filters'
import {
  Box,
  Center,
  Divider,
  Flex,
  FormControl,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useEffectOnce } from 'react-use'

import { TimeFilter, TrackerFormModel } from '../../types'
import { usePhraseFields } from '../../usePhraseFields'
import { SpeakerField } from '../SpeakerField'
import { TimeFilterField } from '../TimeFilterField'
import { useConversationsDrawer } from './ConversationsDrawer'
import PhrasesLabel from './PhrasesLabel'
import { PreviewTrackerHitsFilter } from './PreviewTrackerFilter'
import PhrasesAdvancedView from './advanced/PhrasesAdvancedView'
import { logEvent } from './logEvent'
import { useTrackerHitsPreview } from './preview-data/useTrackerHitsPreview'
import SpeechPhrasesSimpleView from './simple/SpeechPhrasesSimpleView'
import { useDictionaryWordContext } from './useDictionaryWordStatus'
import { SimilarWordsContext, useSimilarWords } from './useSimilarWords'

export const MAX_WORDS_PER_PHRASE = 4
export const NUMBER_OF_PHRASES = 1000

export type ToggleSort = (filed: 'value' | 'conversationHits') => void
export type SortOptions = {
  field: 'value' | 'conversationHits'
  isAscending: boolean
}

type Props = {
  value: TimeFilter
  onTimeFilterChange: (o: TimeFilter) => void
}

export const Phrases = React.memo(function PhrasesWithMemo({
  value,
  onTimeFilterChange,
}: Props) {
  const { phrases: state } = usePhraseFields()
  const { getValues, watch } = useFormContext<TrackerFormModel>()
  const hasPhrases = state.length > 0
  const speakerId = watch('speech.speakerId')
  const [useAdvancedView, setUseAdvancedView] = useState<boolean>(false)
  const { getFilterRequestModel } = useFirstPhoneSegmentState()

  const similarWordsResult = useSimilarWords(state)

  const { previewData, updatePreviewData, isFetchingPreviewData } =
    useTrackerHitsPreview(
      state,
      speakerId,
      value.kind,
      value.seconds,
      value.secondsEnd,
      NUMBER_OF_PHRASES,
      getFilterRequestModel,
    )

  const { validateWords } = useDictionaryWordContext()

  useEffectOnce(() => {
    // Check all words initially to see if they exist in our dictionary.
    validateWords(state.map((x) => x.value))
  })

  const openConversationsDrawer = useConversationsDrawer()
  return (
    <>
      <Box
        p={4}
        background="white"
        pos="relative"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            // Prevent form submission
            e.preventDefault()
          }
        }}
      >
        <FormControl>
          <Flex flexDirection="column" gap="8">
            <HStack
              w={{ md: '50%', sm: '100%' }}
              alignItems="top"
              alignContent="stretch"
            >
              <SpeakerField isRequired={hasPhrases} />
              <Box alignSelf="stretch">
                <Divider
                  orientation="vertical"
                  borderColor="gray.200"
                  opacity="1"
                  mr={2}
                />
              </Box>

              <TimeFilterField
                onTimeFilterChange={onTimeFilterChange}
                value={value}
              />
            </HStack>
            <Center>
              <Divider w="100%" borderColor="gray.200" opacity="1" />
            </Center>
            <HStack alignItems="flex-start">
              <PhrasesLabel
                MaxWordsPerPhrase={MAX_WORDS_PER_PHRASE}
                dataStonly={'tracker_phrases'}
              />
              <Box ml="auto" minH={9}>
                <PreviewTrackerHitsFilter
                  hits={previewData?.conversations || 0}
                  total={previewData?.conversationsTotal || 0}
                  isFetching={isFetchingPreviewData}
                  onViewConversations={() => {
                    const trackerName = getValues('name')
                    if (previewData) {
                      logEvent('openPreviewAllTrackerHits', useAdvancedView)
                      openConversationsDrawer({
                        data: previewData,
                        drawerTitle: trackerName,
                      })
                    }
                  }}
                />
              </Box>
            </HStack>
          </Flex>
          <SimilarWordsContext.Provider value={similarWordsResult}>
            <Tabs isLazy onChange={() => setUseAdvancedView(!useAdvancedView)}>
              <TabList>
                <Tab
                  onClick={() =>
                    logEvent('toggleAdvancedView', useAdvancedView)
                  }
                >
                  <Trans>Simple</Trans>
                </Tab>
                <Tab
                  onClick={() =>
                    logEvent('toggleAdvancedView', useAdvancedView)
                  }
                >
                  <Box data-stonly="view-switch" mr={4}>
                    <Trans>Advanced</Trans>
                  </Box>
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel p={'16px 16px'}>
                  <SpeechPhrasesSimpleView />
                </TabPanel>
                <TabPanel p={'16px 0'}>
                  <PhrasesAdvancedView updatePreviewData={updatePreviewData} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </SimilarWordsContext.Provider>
        </FormControl>
      </Box>
    </>
  )
})
