import analytics from '@capturi/analytics'
import { SentimentScore, SentimentSpeaker } from '@capturi/api-sentiment'

export enum Event {
  SpeakerTabClicked = 'analytics_Sentiment_SpeakerTabClicked',
  ScoreTabClicked = 'analytics_Sentiment_ScoreTabClicked',
  ViewSentimentScoreConversations = 'analytics_Sentiment_ViewSentimentScoreConversations',
  User_PlotTimeSeries = 'analytics_Sentiment_User_PlotTimeSeries',
  User_ViewSentimentScoreConversations = 'analytics_Sentiment_User_ViewSentimentScoreConversations',
  Team_PlotTimeSeries = 'analytics_Sentiment_Team_PlotTimeSeries',
  Team_ViewSentimentScoreConversations = 'analytics_Sentiment_Team_ViewSentimentScoreConversations',
}

export function logEvent(
  event: Event,
  speaker: SentimentSpeaker,
  sentimentScore?: SentimentScore,
  data: Record<string, string | number> = {},
): void {
  analytics.event(event, {
    speaker,
    sentimentScore,
    ...data,
  })
}
