import { useUsers } from '@capturi/stores'
import { useMemo } from 'react'

import {
  AudioSnippetNotification,
  AudioSnippetNotificationResponseModel,
  CommentNotification,
  CommentNotificationResponseModel,
  Notification,
  NotificationResponseModel,
} from '../types'

export const useNotificationsData = (
  notifications?: NotificationResponseModel[],
): Notification[] => {
  const { getUserByUid } = useUsers()

  return useMemo(() => {
    if (!notifications) return []

    const mapToNotification = (n: NotificationResponseModel): Notification => {
      return {
        ...n,
        user: getUserByUid(n.userUid),
      }
    }

    const mapToComment = (
      n: CommentNotificationResponseModel,
    ): CommentNotification => {
      return {
        ...(mapToNotification(n) as CommentNotification),
        commentCreatedBy: getUserByUid(n.commentCreatedByUid),
      }
    }

    const mapToAudioSnippet = (
      n: AudioSnippetNotificationResponseModel,
    ): AudioSnippetNotification => {
      return {
        ...(mapToNotification(n) as AudioSnippetNotification),
        audioSnippetCreatedBy: getUserByUid(n.audioSnippetCreatedByUserUid),
      }
    }

    return notifications.map<Notification>((n) => {
      switch (n.type) {
        case 'comment': {
          return mapToComment(n as CommentNotificationResponseModel)
        }
        case 'audio-snippet': {
          return mapToAudioSnippet(n as AudioSnippetNotificationResponseModel)
        }
        default: {
          return mapToNotification(n)
        }
      }
    })
  }, [notifications, getUserByUid])
}
