import analytics from '@capturi/analytics'
import { MasterPreset } from '@capturi/api-shared'
import {
  MessageFilters,
  MessagePosition,
  TextGlobalSettings,
  TimeFilterKind,
  trackersAPI,
  useTracker,
  useTrackerFolder,
} from '@capturi/api-trackers'
import { AuthService } from '@capturi/auth'
import { PreviewAccessRequestModel, useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import {
  FilterPeriodProvider,
  FixedPeriodDefinition,
  SegmentStatesProvider,
} from '@capturi/filters'
import { usePreviewAccess } from '@capturi/sharing'
import { useOrganization } from '@capturi/stores'
import { Button, useToast } from '@capturi/ui-components'
import { OnChangeValue, SelectOption } from '@capturi/ui-select'
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  CloseButton,
  Collapse,
  Flex,
  Grid,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  ToastId,
  Tooltip,
  chakra,
} from '@chakra-ui/react'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, t } from '@lingui/macro'
import isArray from 'lodash/isArray'
import isEqual from 'lodash/isEqual'
import {
  type FC,
  PropsWithChildren,
  type ReactElement,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  FormProvider,
  FormState,
  useForm,
  useFormContext,
} from 'react-hook-form'
import { MdEmail, MdPhone, MdWarning } from 'react-icons/md'
import { useCopyToClipboard, useEffectOnce } from 'react-use'
import useSWR from 'swr'

import { Provider, useAtom } from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'
import { IconTab } from '../components/IconTab'
import { MasterColumn } from '../components/MasterColumn'
import getMasterPreset from '../utils/getMasterPreset'
import useGetPresetOptions from '../utils/presetOptions'
import { DescriptionField } from './components/DescriptionField'
import { FolderField } from './components/FolderField'
import { MasterFolderContent } from './components/MasterFolderContent'
import { NameField } from './components/NameField'
import SharingAndEditRights from './components/SharingAndEditRights'
import { TagsField } from './components/TagsField'
import { Phrases } from './components/phrases'
import PhrasesSimpleCaseView from './components/phrases/simple/TextPhrasesSimpleView'
import { DictionaryWordProvider } from './components/phrases/useDictionaryWordStatus'
import { useOrphanedQuotes } from './components/phrases/useOphranedQuotes'
import { createFormModel } from './createFormModel'
import {
  duplicateEmailPhraseErrorMsg,
  duplicatePhraseErrorMsg,
  orphanedQuoteErrorMsg,
} from './messages'
import { speechPhraseFieldsAtom } from './state/speechPhrases'
import { textPhraseFieldsAtom } from './state/textPhrases'
import { PhraseState, TimeFilter, TrackerFormModel } from './types'
import {
  useDuplicateSpeechPhrases,
  useDuplicateTextPhrases,
  usePhraseFields,
} from './usePhraseFields'
import { useTrackerDraft } from './useTrackerDraft'
import { validationSchema } from './validationSchema'

const showDevtools = false

const DEFAULT_TIME_FILTER: TimeFilter = {
  kind: TimeFilterKind.ENTIRECONVERSATION,
  seconds: undefined,
  secondsEnd: null,
}

type TrackerEditorProviderProps = {
  initialValues: (
    createFormModelFn: typeof createFormModel,
  ) => Partial<TrackerFormModel>
  children?: ReactNode
}

const JotaiAtomsHydrator: FC<
  {
    speechPhrases: PhraseState[]
    textPhrases: string[]
  } & PropsWithChildren
> = ({ speechPhrases, textPhrases, children }) => {
  useHydrateAtoms([
    [speechPhraseFieldsAtom, speechPhrases],
    [textPhraseFieldsAtom, textPhrases],
  ])
  return <>{children}</>
}

export const TrackerEditorProvider: FC<TrackerEditorProviderProps> = ({
  initialValues,
  children,
}) => {
  const initialFormValuesRef = useRef(initialValues(createFormModel))
  const formMethods = useForm<TrackerFormModel>({
    defaultValues: initialFormValuesRef.current,
    resolver: yupResolver(validationSchema),
  })

  return (
    <Provider>
      <JotaiAtomsHydrator
        speechPhrases={initialFormValuesRef.current.speech?.phrases || []}
        textPhrases={initialFormValuesRef.current.text?.phrases || []}
      >
        <FormProvider {...formMethods}>
          {children}
          {showDevtools && process.env.NODE_ENV === 'development' && (
            <DevTool control={formMethods.control} placement="top-right" />
          )}
        </FormProvider>
      </JotaiAtomsHydrator>
    </Provider>
  )
}

type TrackerEditorProps = {
  onSubmit: (formData: TrackerFormModel) => void
  onPhrasesListChange: (
    hasSpeechPhrases: boolean,
    hasTextPhrases: boolean,
  ) => void
  onMessageFiltersChange: (state: MessageFilters | null) => void
  onFolderChange: (isMasterFolder: boolean) => void
  onMasterTrackerToggle: (isMasterTracker: boolean) => void
  renderHeaderActions?: (
    state: FormState<TrackerFormModel>,
    hasDuplicatePhrases: boolean,
    hasPhraseWithOrphanedQuote: boolean,
    isPreviewAccessLoading: boolean,
    hasPreviewAccessValidationError: boolean,
    importTracker?: () => Promise<void>,
    exportTracker?: () => void,
    removeSavedTracker?: (loadDefaultTracker?: boolean) => void,
  ) => ReactElement
  renderFooterActions?: (
    state: FormState<TrackerFormModel>,
    hasDuplicatePhrases: boolean,
    hasPhraseWithOrphanedQuote: boolean,
    isPreviewAccessLoading: boolean,
    hasPreviewAccessValidationError: boolean,
    removeSavedTracker?: (loadDefaultTracker?: boolean) => void,
  ) => ReactElement
}

export const TrackerEditor: FC<TrackerEditorProps> = ({
  onSubmit,
  onPhrasesListChange,
  onMessageFiltersChange,
  onFolderChange,
  onMasterTrackerToggle,
  renderHeaderActions,
  renderFooterActions,
}) => {
  const { phrases: speechPhrases, setPhraseFields } = usePhraseFields()
  const duplicateSpeechPhrases = useDuplicateSpeechPhrases()
  const duplicateTextPhrases = useDuplicateTextPhrases()
  const { hasPhraseWithOrphanedQuote } = useOrphanedQuotes()
  const [, copy] = useCopyToClipboard()
  const toast = useToast()
  const { handleSubmit, formState, getValues, setValue, control, watch } =
    useFormContext<TrackerFormModel>()

  const loadedDraftToastIdRef = useRef<ToastId>()
  const { conversationLanguage, uid: orgUid } = useOrganization()

  const { enableMasterTracker, enableText, useEmailChannelAsDefault } =
    useFeatureFlags()
  const currentUser = useCurrentUser()

  const presetOptions = useGetPresetOptions()

  const formValues = watch()
  const { data: folderData } = useTrackerFolder(
    formValues.folderUid ?? undefined,
  )
  const { data: tracker } = useTracker(formValues.uid)

  let initialTabIndex = 0
  if (
    enableText &&
    (useEmailChannelAsDefault || (tracker != null && tracker.speech == null))
  ) {
    initialTabIndex = 1
  }
  const [tabsIndex, setTabsIndex] = useState(initialTabIndex)

  const orgTargets = Object.keys(tracker?.masterSettings?.targets ?? {})
  const { data: userOrganizations = [] } = useSWR<
    { id: string; name: string }[]
  >(AuthService.listOrganizationsURL, { suspense: false })

  const [textPhrases, setTextPhrases] = useAtom(textPhraseFieldsAtom)
  const [nearness, setNearness] = useState<TextGlobalSettings>(
    tracker?.text?.globalSettings ?? { near: [], notNear: [] },
  )
  const [messageFilters, setMessageFilters] = useState<MessageFilters | null>(
    tracker?.text?.messageFilters ?? {
      messageTypes: ['Inbound', 'Outbound'],
      messagePosition: MessagePosition.Any,
      messageFields: ['Text'],
    },
  )
  const [timeFilter, setTimeFilter] = useState<TimeFilter>(
    tracker?.speech?.timeFilter ?? DEFAULT_TIME_FILTER,
  )
  const { hasDraft, savedTrackerDraft, unchangedTracker, removeTrackerDraft } =
    useTrackerDraft({
      formState,
      formValues,
      timeFilter,
      phone: { phrases: speechPhrases },
      text: {
        phrases: textPhrases,
        messageFilters: messageFilters,
        globalSettings: nearness,
      },
    })
  const [showDraftAlert, setShowDraftAlert] = useState(hasDraft)

  const initialMasterSettings = tracker?.masterSettings ?? null
  const [toggledMasterTracker, setToggledMasterTracker] = useState<boolean>(
    !!initialMasterSettings ?? false,
  )

  const orgOptions = useMemo(() => {
    return (userOrganizations ?? [])
      .filter((o) => o.id !== orgUid)
      .map<SelectOption>((x) => ({
        value: x.id,
        label: x.name,
      }))
  }, [userOrganizations, orgUid])

  const restrictedOrgOptions = useMemo(() => {
    return (orgTargets ?? [])
      .filter((o) => o !== orgUid)
      .map<SelectOption>((x) => ({
        value: x,
        label: x,
      }))
  }, [orgTargets, orgUid])

  const [selectedOrgUids, setSelectedOrgUids] = useState<string[]>(
    orgTargets ?? [],
  )
  const [masterTrackerPreset, setMasterTrackerPreset] = useState<MasterPreset>(
    tracker?.masterSettings?.preset ?? 'Private',
  )

  const handleToggleMaster = useCallback(
    (toggled: boolean) => {
      setToggledMasterTracker(toggled)
      onMasterTrackerToggle(toggled)
    },
    [onMasterTrackerToggle],
  )

  const previewAccessRequestModel: PreviewAccessRequestModel = {
    uid: formValues.uid,
    permissionPreset: formValues.permissionPreset,
    permissionPresetUserUid: formValues.permissionPresetUserUid,
    acl: formValues.acl,
    folderUid: formValues.folderUid ?? undefined,
  }
  const { isPreviewAccessLoading, previewAccessResponse, validationError } =
    usePreviewAccess({
      previewAccessRequestModel,
      createPreviewAccessRequest: trackersAPI.getPreviewAccess,
    })
  const setTrackerValues = (tracker?: unknown): void => {
    const validFieldNames = new Set([
      ...Object.keys(control._fields),
      'acl',
      'permissionPreset',
      'permissionPresetUserUid',
    ])
    Object.entries(tracker as TrackerFormModel).forEach(([field, value]) => {
      if (field === 'uid') return
      if (field === 'phrases') return
      if (value === undefined) return
      if (!validFieldNames.has(field)) return
      setValue(field as keyof TrackerFormModel, value)
    })

    const trackerFormModel = tracker as TrackerFormModel | undefined
    if (!trackerFormModel) return

    const speech = trackerFormModel.speech
    if (speech == null) {
      setPhraseFields([])
      setTimeFilter(DEFAULT_TIME_FILTER)
    } else if (typeof speech === 'object') {
      const phrases = speech.phrases
      if (isArray(phrases)) {
        setPhraseFields(phrases)
      }
      const timeFilter = speech.timeFilter
      if (
        timeFilter &&
        'kind' in timeFilter &&
        timeFilter.kind in TimeFilterKind
      ) {
        setTimeFilter(timeFilter)
      }
    }

    const text = trackerFormModel.text
    if (text == null) {
      setTextPhrases([])
      setMessageFilters(null)
    } else if (typeof text === 'object') {
      if (isArray(text.phrases)) {
        setTextPhrases(text.phrases)
      }
      if (text.messageFilters !== null) {
        setMessageFilters(text.messageFilters)
      }
    }
  }

  const handleSelectOrg = (option: OnChangeValue<SelectOption, true>): void => {
    if (option == null) return
    const ids = option.map((o) => o.value)
    setSelectedOrgUids(ids)
    setValue('masterSettings.targets', ids)
  }

  const initialOrgValue = useMemo(
    () =>
      selectedOrgUids.reduce<SelectOption[]>((memo, orgUid) => {
        const knownOrg = orgOptions?.find((org) => org.value === orgUid)
        const unknownOrg = restrictedOrgOptions.find(
          (org) => org.value === orgUid,
        )
        if (knownOrg) {
          if (memo.indexOf(knownOrg) === -1) memo.push(knownOrg)
        } else if (unknownOrg) {
          if (memo.indexOf(unknownOrg) === -1) memo.push(unknownOrg)
        }
        return memo
      }, []),
    [selectedOrgUids, orgOptions, restrictedOrgOptions],
  )

  const selectedPreset = useMemo(() => {
    return presetOptions.find((x) => x.value === masterTrackerPreset)
  }, [presetOptions, masterTrackerPreset])

  const importTracker = async (): Promise<void> => {
    try {
      analytics.event('tracker-editor_importTrackerFromClipboard')
      const text = await navigator.clipboard.readText()
      const maybeTracker = JSON.parse(text) as unknown

      setTrackerValues(maybeTracker)

      const trackerFormModel = maybeTracker as TrackerFormModel | undefined
      if (trackerFormModel) {
        const hasSpeechPhrases =
          (trackerFormModel.speech?.phrases?.length ?? 0) > 0
        const hasTextPhrases = (trackerFormModel.text?.phrases?.length ?? 0) > 0
        if (hasSpeechPhrases && !hasTextPhrases) {
          setTabsIndex(0)
        } else if (!hasSpeechPhrases && hasTextPhrases) {
          setTabsIndex(1)
        }

        // Set nearness settings if they exist
        if (trackerFormModel.text?.globalSettings) {
          setNearness(trackerFormModel.text.globalSettings)
        }
      }

      toast({
        status: 'success',
        title: t`Tracker was successfully imported from clipboard`,
      })
    } catch (_error) {
      toast({
        status: 'error',
        title: t`Couldn't import tracker`,
        description: t`Make sure that you have copied a tracker to the clipboard.`,
      })
    }
  }

  const exportTracker = (): void => {
    analytics.event('tracker-editor_exportTrackerToClipboard')

    // we need to remove the ACL and permission parts, else new trackers are hidden when they are created on different orgs
    // biome-ignore lint/suspicious/noExplicitAny: legacy
    const tracker = getValues() as any
    tracker.acl = undefined
    tracker.private = undefined
    tracker.permissionPreset = undefined
    tracker.permissionPresetUserUid = undefined
    tracker.folderUid = undefined

    copy(
      JSON.stringify(
        {
          ...tracker,
          speech: {
            phrases: speechPhrases,
            timeFilter: timeFilter,
            speakerId: tracker.speech?.speakerId,
          },
          text: {
            phrases: textPhrases,
            messageFilters: messageFilters,
            globalSettings: nearness,
          },
        },
        undefined,
        2,
      ),
    )
    toast({
      status: 'info',
      title: t`Tracker copied to clipboard`,
    })
  }

  const removeSavedTracker = (): void => {
    removeTrackerDraft()
    unchangedTracker.current = undefined
  }

  useEffectOnce(() => {
    if (hasDraft && savedTrackerDraft) {
      analytics.event('tracker-editor_loaded_saved_draft')
      setTrackerValues(savedTrackerDraft)
      if (
        savedTrackerDraft.text?.phrases !== null &&
        savedTrackerDraft.text?.phrases
      ) {
        setTextPhrases(savedTrackerDraft.text?.phrases)
      }
      loadedDraftToastIdRef.current = toast({
        status: 'success',
        title: t`Draft restored`,
        description: t`You are editing your latest draft`,
      })
    }
  })

  const handleSelectPreset = (
    option: OnChangeValue<SelectOption, false>,
  ): void => {
    if (option === null) return
    const value = option.value
    setMasterTrackerPreset(value as MasterPreset)
    setValue('masterSettings.preset', value as MasterPreset)
  }

  useEffect(() => {
    onPhrasesListChange(speechPhrases.length > 0, textPhrases.length > 0)

    folderData?.masterSettings !== null
      ? onFolderChange(true)
      : onFolderChange(false)
  }, [
    onFolderChange,
    speechPhrases.length,
    folderData?.masterSettings,
    onPhrasesListChange,
    textPhrases.length,
  ])

  return (
    <chakra.form
      mb={32} // This is only to allocate additional room for the phrase-nearness popover dialogs which unfortunately sometimes is partly outside of the screen.
      onSubmit={handleSubmit((formData) => {
        const comparableObject = {
          preset: tracker?.masterSettings?.preset,
          targets: Object.keys(tracker?.masterSettings?.targets ?? {}),
        }
        const changedMasterSettings = isEqual(
          comparableObject,
          formData.masterSettings,
        )
          ? undefined
          : { preset: masterTrackerPreset, targets: selectedOrgUids }
        const masterSettingsToPost = toggledMasterTracker
          ? changedMasterSettings
          : null

        const phrases = [...speechPhrases].sort((a, b) =>
          a.value.localeCompare(b.value, conversationLanguage, {
            sensitivity: 'base',
          }),
        )

        const speechToSubmit =
          formData?.speech && (phrases?.length ?? 0) !== 0
            ? {
                phrases: phrases,
                timeFilter: timeFilter,
                speakerId: formData.speech.speakerId,
              }
            : null
        onSubmit({
          ...formData,
          name: formData.name.trimEnd(),
          speech: speechToSubmit,
          masterSettings: masterSettingsToPost,
          text: currentUser.permissions.text
            ? textPhrases.length > 0
              ? {
                  phrases: textPhrases.map((phrase) => phrase.trim()) || null,
                  messageFilters: messageFilters,
                  globalSettings: nearness,
                }
              : null
            : undefined,
        })
        removeSavedTracker()
      })}
    >
      <Flex flexDirection={{ base: 'column', md: 'row-reverse' }}>
        <Box flex={1}>
          {renderHeaderActions?.(
            formState,
            duplicateSpeechPhrases.hasDuplicates ||
              duplicateTextPhrases.hasDuplicates,
            hasPhraseWithOrphanedQuote,
            isPreviewAccessLoading,
            validationError !== undefined,
            importTracker,
            exportTracker,
            removeSavedTracker,
          )}
        </Box>
        <Flex>
          <NameField />
        </Flex>
      </Flex>

      {showDraftAlert && (
        <Alert
          status="warning"
          p="4"
          borderLeft="4px gold solid"
          mt="8"
          borderRadius="sm"
          bgGradient={[
            'linear(172deg, rgba(244, 255, 5, 0) 19.58%, rgba(253, 99, 210, 0.032) 46.97%, rgba(56, 255, 231, 0) 84.06%)',
            'linear(204.75deg, rgba(255, 65, 5, 0) 17.4%, rgba(255, 218, 121, 0.0145) 46.85%, rgba(58, 243, 255, 0.03) 59.14%, rgba(56, 207, 255, 0) 82.39%)',
            'linear(175.78deg, #FFFFFF 3.66%, #FAFAFA 96.79%)',
          ]}
          boxShadow="md"
        >
          <Flex justify="space-between" w="full">
            <Flex gap="4" align="center">
              <MdWarning size="1.5em" color="gold" />
              <AlertTitle fontWeight="medium">
                <Trans>Draft: </Trans>{' '}
                <AlertDescription as="span" fontWeight="normal">
                  <Trans>You have unsaved changes on this tracker.</Trans>
                </AlertDescription>
              </AlertTitle>
              <Button
                onClick={() => {
                  removeSavedTracker()
                  setTrackerValues(formState.defaultValues)
                  setShowDraftAlert(false)
                  if (loadedDraftToastIdRef.current)
                    toast.close(loadedDraftToastIdRef.current)
                }}
              >
                <Trans>Discard changes</Trans>
              </Button>
            </Flex>

            <CloseButton onClick={() => setShowDraftAlert(false)} />
          </Flex>
        </Alert>
      )}
      <Grid
        mt={10}
        templateColumns={
          enableMasterTracker && currentUser.permissions.editMasterTracker
            ? 'repeat(3, 1fr)'
            : 'repeat(2, 1fr)'
        }
      >
        <Stack
          spacing={2}
          shouldWrapChildren
          pr={8}
          borderRight="1px"
          borderColor="gray.300"
        >
          <DescriptionField />
        </Stack>
        <Stack
          spacing={3}
          shouldWrapChildren
          px={8}
          borderRight={
            enableMasterTracker && currentUser.permissions.editMasterTracker
              ? '1px'
              : 'none'
          }
          borderColor={
            enableMasterTracker && currentUser.permissions.editMasterTracker
              ? 'gray.300'
              : 'none'
          }
        >
          <FolderField isMasterTrackerToggled={toggledMasterTracker} />
          <TagsField />
          <SharingAndEditRights
            formValues={formValues}
            setValue={setValue}
            previewAccessResponse={previewAccessResponse}
            validationError={validationError}
            folderData={folderData}
          />
        </Stack>
        {enableMasterTracker && currentUser.permissions.editMasterTracker && (
          <Stack pl={8}>
            <MasterColumn
              orgTargets={orgTargets}
              initialOrgValue={initialOrgValue}
              orgOptions={orgOptions}
              presetOptions={presetOptions}
              selectedPreset={selectedPreset}
              toggledMaster={toggledMasterTracker}
              setToggledMaster={handleToggleMaster}
              handleSelectOrg={handleSelectOrg}
              renderPreset={getMasterPreset}
              handleSelectPreset={handleSelectPreset}
              MasterFolderContent={
                <MasterFolderContent folderData={folderData} />
              }
            />
          </Stack>
        )}
      </Grid>
      <Box mt={8}>
        {/* For the preview we should always look as far back as possible
        And there is limit on 1000 conversations */}
        <FilterPeriodProvider
          defaultPeriod={new FixedPeriodDefinition(new Date(0), new Date())}
        >
          <SegmentStatesProvider>
            <DictionaryWordProvider>
              <Tabs isLazy index={tabsIndex} onChange={setTabsIndex}>
                <TabList>
                  <IconTab
                    onClick={() => analytics.event('phone_tab_click')}
                    icon={<MdPhone />}
                  >
                    <Trans>Phone</Trans>
                  </IconTab>
                  <Tooltip
                    isDisabled={enableText && currentUser.permissions.text}
                    label={t`In development`}
                    hasArrow
                    placement="top"
                  >
                    <IconTab
                      isDisabled={!(enableText && currentUser.permissions.text)}
                      onClick={() => analytics.event('email_tab_click')}
                      icon={<MdEmail />}
                    >
                      <Trans>Text</Trans>
                    </IconTab>
                  </Tooltip>
                </TabList>
                <TabPanels
                  bg="white"
                  border="1px solid"
                  borderColor="gray.200"
                  borderBottomLeftRadius="md"
                  borderBottomRightRadius="md"
                >
                  <TabPanel p={'0'}>
                    <Phrases
                      value={timeFilter}
                      onTimeFilterChange={setTimeFilter}
                    />
                  </TabPanel>
                  <TabPanel p={'0'}>
                    <Box
                      p={4}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          // Prevent form submission
                          e.preventDefault()
                        }
                      }}
                    >
                      <PhrasesSimpleCaseView
                        words={textPhrases}
                        onWordChange={setTextPhrases}
                        nearness={nearness}
                        onNearnessChange={setNearness}
                        messageFilters={messageFilters}
                        onMessageFiltersChange={(newMessageFilters) => {
                          setMessageFilters(newMessageFilters)
                          onMessageFiltersChange(newMessageFilters)
                        }}
                      />
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </DictionaryWordProvider>
          </SegmentStatesProvider>
        </FilterPeriodProvider>
      </Box>

      <Flex justify="flex-end" gap="8" mt={4}>
        <Collapse in={duplicateSpeechPhrases.hasDuplicates}>
          <Text color="danger" maxW="28em" textAlign="right" fontSize="sm">
            {duplicatePhraseErrorMsg()}
          </Text>
        </Collapse>
        <Collapse in={duplicateTextPhrases.hasDuplicates}>
          <Text color="danger" maxW="30em" textAlign="right" fontSize="sm">
            {duplicateEmailPhraseErrorMsg()}
          </Text>
        </Collapse>
        <Collapse in={hasPhraseWithOrphanedQuote}>
          <Text color="danger" maxW="28em" textAlign="right" fontSize="sm">
            {orphanedQuoteErrorMsg()}
          </Text>
        </Collapse>
      </Flex>

      <Flex as="footer" mt={8}>
        {renderFooterActions?.(
          formState,
          duplicateSpeechPhrases.hasDuplicates ||
            duplicateTextPhrases.hasDuplicates,
          hasPhraseWithOrphanedQuote,
          isPreviewAccessLoading,
          validationError !== undefined,
          removeSavedTracker,
        )}
      </Flex>
    </chakra.form>
  )
}
