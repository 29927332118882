import { CustomPropDropdownValue } from '@capturi/api-organization'
import { OnChangeValue, SelectOption } from '@capturi/ui-select'
import React from 'react'

import { FilterCriteriaSelect } from '../../../components/PhoneFilter/components/PhoneSegmentBuilder'

import { getLocalizedCustomPropLabel } from '@capturi/ui-components'
import { CustomPropFilterProps } from './types'

type CustomPropSelectSelectOption = SelectOption & {
  option?: CustomPropDropdownValue
}

export function CustomPropSelect({
  settings,
  locale,
  ...props
}: CustomPropFilterProps<'Dropdown'>): React.ReactElement {
  const isMultiSelect = props.options?.isMulti ?? true

  const { value, options } = React.useMemo(() => {
    const createOption = (
      o: CustomPropDropdownValue,
    ): CustomPropSelectSelectOption => ({
      label: getLocalizedCustomPropLabel(o.label, locale) ?? '?',
      value: o.value,
      option: o,
    })

    const options = settings.values.map(createOption)
    return {
      options,
      value: props.value?.map((x) => {
        const option = options.find((o) => o.value === x)
        if (option?.option != null) {
          return createOption(option.option)
        }
        return {
          label: '?',
          value: '',
          option: undefined,
        }
      }),
    }
  }, [settings.values, props.value, locale])

  const handleChange = (
    options: OnChangeValue<CustomPropSelectSelectOption, boolean>,
  ): void => {
    if (options == null) {
      return
    }
    if (Array.isArray(options)) {
      const value = (options as CustomPropSelectSelectOption[]).map(
        (o) => o.value,
      )
      props.setValue?.(value)
    } else {
      const value = (options as CustomPropSelectSelectOption).value
      props.setValue?.([value])
    }

    if (!isMultiSelect) {
      props.onClose?.()
    }
  }

  return (
    <FilterCriteriaSelect
      options={options}
      value={value}
      onChange={handleChange}
      onClose={props.onClose}
      isMulti={isMultiSelect}
    />
  )
}
