import { useCurrentUser } from '@capturi/core'
import { ErrorBoundary } from '@capturi/react-utils'
import { Spinner } from '@capturi/ui-components'
import React from 'react'

export { default as routes } from './routes'

const UserCoaching = React.lazy(() => import('../Coaching/UserCoaching'))

const MyPage: React.FC = () => {
  const currentUser = useCurrentUser()
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<Spinner display="block" m="5rem auto" />}>
        <UserCoaching userUid={currentUser.id} />
      </React.Suspense>
    </ErrorBoundary>
  )
}

export default MyPage
