import request, { RequestOptions, ResponseError } from '@capturi/request'
import queryString from 'query-string'
import useSWR, {
  Key,
  SWRConfiguration,
  SWRResponse,
  mutate as globalMutate,
} from 'swr'
// biome-ignore lint/suspicious/noExplicitAny: legacy
type FilterQueryModel = { [key: string]: any } | null | undefined
type GetFilterQueryModel = () => FilterQueryModel

type RequestOptionsParam = RequestOptions | (() => RequestOptions | null) | null

export function useAPI<Data = unknown, Error = ResponseError>(
  requestOptionsParam: RequestOptionsParam,
  swrConfig?: SWRConfiguration<Data, Error>,
): SWRResponse<Data, Error>
export function useAPI<Data = unknown, Error = ResponseError>(
  requestOptionsParam: RequestOptionsParam,
  getFilterQueryModel?: GetFilterQueryModel,
  swrConfig?: SWRConfiguration<Data, Error>,
): SWRResponse<Data, Error>
export function useAPI<Data = unknown, Error = ResponseError>(
  requestOptionsParam: RequestOptionsParam,
  param2?: GetFilterQueryModel | SWRConfiguration<Data, Error>,
  param3?: SWRConfiguration<Data, Error>,
): SWRResponse<Data, Error> {
  let swrConfig: SWRConfiguration<Data, Error> | undefined
  let getFilterQueryModel: GetFilterQueryModel | undefined
  if (typeof param2 === 'function') {
    // Overload 2
    getFilterQueryModel = param2
    swrConfig = param3
  } else {
    // Overload 1
    swrConfig = param2
  }
  swrConfig = swrConfig ?? {}

  const requestOptions =
    typeof requestOptionsParam === 'function'
      ? requestOptionsParam()
      : requestOptionsParam

  let key: Key = null
  let mergedConfig: SWRConfiguration = {}
  if (requestOptions) {
    const isPostReq = requestOptions?.method === 'post'

    const filterQueryModel = getFilterQueryModel?.() ?? {}

    const createConfigResult = isPostReq
      ? createPostConfig(requestOptions, filterQueryModel)
      : createGetConfig(requestOptions, filterQueryModel)

    key = createConfigResult.key
    mergedConfig = {
      ...swrConfig, // user specific config
      ...createConfigResult.config, // request method specific config
    }
  }
  return useSWR<Data, Error>(key, mergedConfig)
}

type CreateConfig = {
  key: Key
  config: SWRConfiguration
}

function createGetConfig(
  requestOptions: RequestOptions,
  filterQueryModel: FilterQueryModel = {},
): CreateConfig {
  const { url, ...options } = requestOptions
  const key = queryString.stringifyUrl({
    url,
    query: {
      ...(options.query ?? {}),
      ...filterQueryModel,
    },
  })
  return { key, config: {} }
}

function createPostConfig(
  requestOptions: RequestOptions,
  filterQueryModel: FilterQueryModel = {},
): CreateConfig {
  const { url, ...options } = requestOptions
  const urlKey = queryString.stringifyUrl({
    url,
    query: options.query ?? {},
  })
  const key = [urlKey, JSON.stringify(filterQueryModel)]
  return {
    key,
    config: {
      fetcher: () => {
        return request({
          ...requestOptions,
          json: filterQueryModel,
        })
      },
    },
  }
}

export async function mutateAPI<T = unknown>(
  requestOptions: RequestOptions,
): Promise<T | undefined> {
  const key = queryString.stringifyUrl({
    url: requestOptions.url,
    query: requestOptions.query ?? {},
  })
  return await globalMutate(key)
}
