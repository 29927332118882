import analytics from '@capturi/analytics'
import {
  Card,
  CardContent,
  PlaySnippetsButton,
  formatSeconds,
} from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Flex,
  HStack,
  Skeleton,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react'
import { Plural, Trans, t } from '@lingui/macro'
import React, { useMemo, useState } from 'react'

import { KeyTopicsDrawer } from '../../../components/KeyTopics/Drawer'
import EmptyWidget from '../FailedStates/EmptyWidget'
import { AnimateChangeInHeight } from '../Shared/AnimateHeight'
import SharedDigest from '../Shared/SharedDigest'
import { useAverageDuration } from '../hooks/useAverageDuration'
import { KeyTopicsList } from './components/KeyTopicsList'
import { TopTimeConsumersWidgetReadySelector } from './selector'

export const TopTimeConsumersWidgetCardSkeleton: React.FC = () => {
  return (
    <Card mt={2} h="514px">
      <CardContent h="100%">
        <Flex alignItems="stretch" gap={4} h="100%" minH="298px">
          <Flex alignItems="flex-start" direction="column" w="518px" gap="4">
            <VStack w="100%" alignItems="flex-start" gap={1}>
              <Skeleton height="20px" width="15%" />
              <Skeleton height="14px" width="40%" />
            </VStack>
            <Flex gap="8" flexDir="row" alignItems="flex-end" h="100%" w="100%">
              <Skeleton flexGrow={1} mt={2} w="100%" flexShrink="1" h="100%" />
              <Skeleton flexGrow={1} mt={2} w="100%" flexShrink="1" h="70%" />
              <Skeleton flexGrow={1} mt={2} w="100%" flexShrink="1" h="50%" />
              <Skeleton flexGrow={1} mt={2} w="100%" flexShrink="1" h="40%" />
              <Skeleton flexGrow={1} mt={2} w="100%" flexShrink="1" h="30%" />
            </Flex>
          </Flex>
          <Box
            backgroundColor="gray.200"
            position="relative"
            w="1px"
            top="0"
            bottom="0"
          />
          <Flex
            alignItems="flex-start"
            flexDirection="column"
            flexGrow={1}
            gap={3}
          >
            <VStack w="100%" alignItems="flex-start" gap={1}>
              <Skeleton height="20px" width="15%" />
              <Skeleton height="14px" width="40%" />
            </VStack>
            <VStack w="100%" h="100%" flexGrow="1" alignItems="flex-start">
              <Skeleton height="81px" width="100%" />
              <Skeleton height="100%" width="100%" />
            </VStack>
          </Flex>
        </Flex>
      </CardContent>
    </Card>
  )
}

export const NoTopTimeConsumersWidgetCard: React.FC = () => {
  return (
    <Card mt={2}>
      <CardContent>
        <VStack>
          <TopTimeConsumersWidgetHeading />
          <EmptyWidget
            heading={t`No long conversations`}
            body={t`With the current selection there isn't any conversations that has the double length of the average duration.`}
          />
        </VStack>
      </CardContent>
    </Card>
  )
}

const TopTimeConsumersWidgetHeading: React.FC = () => {
  return (
    <VStack alignItems="stretch" gap={0} w="100%">
      <HStack gap={1}>
        <Text
          fontSize="sm"
          fontWeight="medium"
          lineHeight="5"
          textColor="gray.600"
          textTransform="uppercase"
        >
          <Trans># of long conversations</Trans>
        </Text>
      </HStack>
      <Text fontSize="xs" color="gray.600">
        <Trans>
          We define long conversations as those minimum 2X the average.
        </Trans>
      </Text>
    </VStack>
  )
}
export const TopTimeConsumersWidgetCard: React.FC<{
  keyTopicsSessionUid: string | undefined
  data: TopTimeConsumersWidgetReadySelector
}> = ({ keyTopicsSessionUid, data }) => {
  const [openKeyTopicsDrawer] = useModal(KeyTopicsDrawer)

  const { data: averageDuration } = useAverageDuration()

  const [selectedKeyTopicId, setSelectedKeyTopicId] = useState<
    string | undefined
  >(data?.keyTopicClusters[0]?.id)

  if (
    data &&
    data.keyTopicClusters.length > 0 &&
    selectedKeyTopicId === undefined
  ) {
    setSelectedKeyTopicId(data.keyTopicClusters[0].id)
  }

  const selectedKeyTopic = useMemo(
    () =>
      data?.keyTopicClusters.find((topic) => topic.id === selectedKeyTopicId),
    [data, selectedKeyTopicId],
  )

  if (data.keyTopicClusters.length === 0) {
    return <NoTopTimeConsumersWidgetCard />
  }

  return (
    <Card mt={2}>
      <CardContent>
        <Flex alignItems="stretch" gap={4} h="100%" minH="298px">
          <VStack w="518px">
            <TopTimeConsumersWidgetHeading />
            <KeyTopicsList
              data={data.keyTopicClusters}
              selectedKeyTopicId={selectedKeyTopicId}
              setSelectedKeyTopicId={setSelectedKeyTopicId}
            />
          </VStack>
          <Box
            backgroundColor="gray.200"
            position="relative"
            w="1px"
            top="0"
            bottom="0"
          />
          <VStack alignItems="flex-start" flex="1" w="100%">
            <Flex gap="8" justifyContent="space-between" w="100%">
              <Flex flexDir="column" w="200px" flexGrow="1">
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  textColor="gray.800"
                  textTransform="uppercase"
                >
                  {selectedKeyTopic?.label}
                </Text>
                <Text
                  fontSize="xs"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  textColor="gray.600"
                  w="100%"
                >
                  {selectedKeyTopic?.keyTopics
                    ?.slice(
                      1,
                      5,
                    ) /* Skip first topic and stop at the fifth, as it's in label */
                    .map((topic) => topic.name)
                    .join(', ')}
                  {selectedKeyTopic &&
                    selectedKeyTopic?.keyTopics.length > 5 && (
                      <Trans>, etc.</Trans>
                    )}
                </Text>
              </Flex>
              <PlaySnippetsButton
                onClick={(e) => {
                  e.stopPropagation()
                  if (!(selectedKeyTopic && keyTopicsSessionUid)) return
                  openKeyTopicsDrawer({
                    topicId: selectedKeyTopic.id,
                    sessionUid: keyTopicsSessionUid,
                    referenceBenchmark: data.benchmark,
                    clusterName: selectedKeyTopic.label,
                    topics: selectedKeyTopic.keyTopics,
                  })
                  analytics.event('top_time_consumers_drawer_opened')
                }}
                label={t`Show details`}
                withLabel
              />
            </Flex>
            <Flex flexDirection="column" h="100%" gap={2}>
              <Box
                flexGrow={0}
                borderColor="gray.200"
                borderWidth="1px"
                borderRadius={4}
                py={3}
                px={4}
              >
                <Text fontSize="sm" fontWeight="medium">
                  <Trans>Summary</Trans>
                </Text>
                {selectedKeyTopic && (
                  <Text fontSize="md" textColor="gray.600">
                    <Plural
                      value={selectedKeyTopic.amount}
                      one={
                        <Trans>
                          You have 1 long conversation within the topic{' '}
                          <chakra.span fontWeight="medium">
                            {selectedKeyTopic.label}
                          </chakra.span>
                          .
                        </Trans>
                      }
                      other={
                        <Trans>
                          You have # long conversations within the topic{' '}
                          <chakra.span fontWeight="medium">
                            {selectedKeyTopic.label}
                          </chakra.span>
                          .
                        </Trans>
                      }
                    />
                    {averageDuration && (
                      <>
                        {' '}
                        <Trans>
                          These conversations are minimum twice as long as your
                          average of{' '}
                          <chakra.span fontWeight="medium">
                            {formatSeconds(averageDuration)} mins
                          </chakra.span>
                          .
                        </Trans>
                      </>
                    )}
                  </Text>
                )}
              </Box>
              <AnimateChangeInHeight>
                <Box flexGrow={1} position="relative" w="100%" h="100%">
                  <SharedDigest
                    keyTopicsSessionUid={keyTopicsSessionUid}
                    keyTopicClusterId={selectedKeyTopicId}
                    keyTopicClusterLabel={t`Input to swifter resolution of long calls about ${selectedKeyTopic?.label}?`}
                    widgetType="topTimeConsumers"
                  />
                </Box>
              </AnimateChangeInHeight>
            </Flex>
          </VStack>
        </Flex>
      </CardContent>
    </Card>
  )
}
