import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import {
  ButtonGroup,
  ContentPlaceholder,
  PageHeading,
} from '@capturi/ui-components'
import { Box, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ButtonLink } from 'components/Link'
import { CreateTrackerButton } from 'pages/TrackersPage/components/TrackerButtons'
import useTrendsRoutes from 'pages/TrendsV2/routes'
import React from 'react'

type Props = { isAdminOrTeamLead: boolean; canEditTracker: boolean }

const EmptyPlaceholder: React.FC<Props> = ({
  canEditTracker,
  isAdminOrTeamLead,
}) => {
  const trendsRoutes = useTrendsRoutes()
  return (
    <Box>
      <PageHeading>
        <Trans>Trackers</Trans>
      </PageHeading>
      <ContentPlaceholder.Container mt="10vh">
        <ContentPlaceholder.Image as={Icon_EmptyState} />
        <ContentPlaceholder.Heading fontSize="2xl">
          <Trans>It&apos;s a bit empty here!</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Text>
            <Trans>Trackers are the core of Capturi.</Trans>
          </Text>
          {isAdminOrTeamLead ? (
            <Text mt={4}>
              <Trans>
                Hurry up and set up your first tracker. Either from scratch or
                based on Capturi&apos;s intelligent trends based on your
                conversation data.
              </Trans>
            </Text>
          ) : (
            <Text mt={4}>
              <Trans>No trackers have been created yet</Trans>
            </Text>
          )}
        </ContentPlaceholder.Body>
        {isAdminOrTeamLead && canEditTracker ? (
          <ContentPlaceholder.Footer>
            <ButtonGroup>
              <ButtonLink to={trendsRoutes.liveTrends()}>
                <Trans>Go to trends</Trans>
              </ButtonLink>
              <CreateTrackerButton>
                <Trans>Add tracker</Trans>
              </CreateTrackerButton>
            </ButtonGroup>
          </ContentPlaceholder.Footer>
        ) : null}
      </ContentPlaceholder.Container>
    </Box>
  )
}

export default EmptyPlaceholder
