import { ModalCloseButton } from '@capturi/ui-components'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Stack,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'

import { BaseConfirmDialogProps } from './useConfirm'

export interface ConfirmationOptions {
  colorScheme?: string
  title?: string
  description?: string
  cancelText?: string
  confirmText?: string
}

export type ConfirmationDialogProps = BaseConfirmDialogProps &
  ConfirmationOptions

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  onClose,
  onConfirm,
  colorScheme = 'red',
  title = t`Are you sure?`,
  description = '',
  cancelText = t`Cancel`,
  confirmText = t`Confirm`,
}) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null)
  return (
    <AlertDialog isOpen onClose={onClose} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{title}</AlertDialogHeader>
        <ModalCloseButton />
        <AlertDialogBody>{description}</AlertDialogBody>
        <AlertDialogFooter>
          <Stack isInline spacing={4} justify="flex-end">
            <Button ref={cancelRef} onClick={onClose}>
              {cancelText}
            </Button>
            <Button colorScheme={colorScheme} onClick={onConfirm}>
              {confirmText}
            </Button>
          </Stack>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
