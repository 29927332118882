class ResponseError extends Error {
  error: string
  statusCode: number
  cause?: unknown
  constructor(
    error: string,
    statusCode: number,
    message: string,
    cause?: unknown,
  ) {
    super(message)
    this.error = error
    this.statusCode = statusCode
    this.message = message
    this.cause = cause
  }
}

/**
 * The backend return multiple different kinds of error messages
 * So we try to normalize them
 */
export const normalizeError = (
  name: string,
  statusCode: number,
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  errorObj: any,
  cause: unknown,
): ResponseError => {
  const errorName = `${statusCode}: ${name}`
  let errorMessage = ''
  if (typeof errorObj === 'string') {
    errorMessage = errorObj
  } else if (errorObj != null && typeof errorObj === 'object') {
    if (
      typeof errorObj.Error === 'string' &&
      typeof errorObj.Details === 'string'
    ) {
      errorMessage = `${errorObj.Error} - ${errorObj.Details}`
    } else {
      if (typeof errorObj.title === 'string')
        errorMessage = `${errorMessage} ${errorObj.title}`

      if (typeof errorObj.message === 'string')
        errorMessage = `${errorMessage} ${errorObj.message}`
    }
  }
  return new ResponseError(errorName, statusCode, errorMessage, cause)
}

export default ResponseError
