import { Speaker } from '@capturi/core'
import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'

const wordNotDetected = msg`The word/sentence was not detected`

export const speakers = {
  [Speaker.All]: 'All',
  [Speaker.Employee]: 'Employee',
  [Speaker.Customer]: 'Customer',
}

export const dataBasisMsgs = {
  trackerDetected: {
    [Speaker.Employee]: (
      // biome-ignore lint/style/useDefaultParameterLast: <explanation>
      hits = 0,
      // biome-ignore lint/style/useDefaultParameterLast: <explanation>
      conversationCount = 0,
      trackerName: string,
    ): MessageDescriptor =>
      msg`(In ${hits} out of ${conversationCount} conversations "${trackerName}" was detected)`,
    [Speaker.Customer]: (
      // biome-ignore lint/style/useDefaultParameterLast: <explanation>
      hits = 0,
      // biome-ignore lint/style/useDefaultParameterLast: <explanation>
      conversationCount = 0,
      trackerName: string,
    ): MessageDescriptor =>
      msg`(In ${hits} out of ${conversationCount} conversations "${trackerName}" was detected)`,
    [Speaker.All]: (
      // biome-ignore lint/style/useDefaultParameterLast: <explanation>
      hits = 0,
      // biome-ignore lint/style/useDefaultParameterLast: <explanation>
      conversationCount = 0,
      trackerName: string,
    ): MessageDescriptor =>
      msg`(In ${hits} out of ${conversationCount} conversations "${trackerName}" was detected)`,
  },
  wordDetected: (
    // biome-ignore lint/style/useDefaultParameterLast: <explanation>
    hits = 0,
    // biome-ignore lint/style/useDefaultParameterLast: <explanation>
    conversationCount = 0,
    trackerWord: string,
  ): MessageDescriptor =>
    hits === 0
      ? wordNotDetected
      : msg`In ${hits} out of ${conversationCount} conversations, the word/sentence "${trackerWord}" was detected`,
}
