import { Spinner } from '@capturi/ui-components'
import { Flex } from '@chakra-ui/react'
import React from 'react'

const TabLoadingIndicator: React.FC = () => {
  return (
    <Flex alignItems="center" justifyContent="center" marginTop="10%">
      <Spinner />
    </Flex>
  )
}

export default TabLoadingIndicator
