export const configurations = [
  {
    label: 'A',
    color: 'segments.primary',
  },
  {
    label: 'B',
    color: 'segments.secondary',
  },
  {
    label: 'C',
    color: 'segments.tertiary',
  },
]
