import { Box, Flex, HStack, Icon, IconButton, useTheme } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { FC } from 'react'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'

type PaginationProps = {
  currentPage: number
  totalPages: number
  onPageChange: (pageIndex: number) => void
}

export const Pagination: FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const theme = useTheme()

  const handleNextPage = (): void => {
    if (currentPage < totalPages - 1) {
      onPageChange(currentPage + 1)
    }
  }

  const handlePreviousPage = (): void => {
    if (currentPage > 0) {
      onPageChange(currentPage - 1)
    }
  }

  const handlePageClick = (pageIndex: number): void => {
    onPageChange(pageIndex)
  }

  return (
    <Flex gap="0" align="center">
      <IconButton
        isDisabled={currentPage < 1}
        variant="ghost"
        pos="relative"
        size="xs"
        aria-label="previous page button"
        onClick={handlePreviousPage}
        icon={<Icon as={MdNavigateBefore} boxSize="20px !important" />}
        _hover={{ bg: 'none', transform: 'scale(1.1)' }}
        transition="all 0.2s ease-in-out"
      />
      <HStack gap="1">
        {Array.from({ length: totalPages }).map((_, idx) => (
          <Flex
            align="center"
            justify="center"
            key={idx}
            w="2"
            h={2}
            borderRadius="999"
            bg="none"
            transition="all 0.2s ease-in-out"
            onClick={() => handlePageClick(idx)}
            cursor="pointer"
            css={css`
              &:hover > div {
                background: ${theme.colors.gray[800]};
              }
            `}
          >
            <Box
              key={idx}
              w={idx === currentPage ? 2 : 1}
              h={1}
              borderRadius="999"
              bg={idx === currentPage ? 'gray.800' : 'gray.400'}
              transition="all 0.2s ease-in-out"
              _hover={{ transform: 'scale(1.2)' }}
            />
          </Flex>
        ))}
      </HStack>
      <IconButton
        isDisabled={currentPage >= totalPages - 1}
        variant="ghost"
        size="xs"
        pos="relative"
        aria-label="next page button"
        onClick={handleNextPage}
        icon={<Icon as={MdNavigateNext} boxSize="20px !important" />}
        _hover={{ bg: 'none', transform: 'scale(1.1)' }}
        transition="all 0.2s ease-in-out"
      />
    </Flex>
  )
}
