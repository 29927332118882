import { Icon, IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { RiPlayList2Fill } from 'react-icons/ri'
import { Button } from '../Button'

export type PlaySnippetsButtonProps = Omit<IconButtonProps, 'aria-label'> & {
  label: string
  withLabel?: boolean
}

export const PlaySnippetsButton: React.FC<PlaySnippetsButtonProps> = ({
  label,
  size,
  colorScheme = 'primary',
  variant = 'lifted-icon',
  withLabel = false,
  ...props
}) => {
  return (
    <>
      {withLabel ? (
        <Button
          borderRadius="999"
          rightIcon={<Icon as={RiPlayList2Fill} boxSize="12px !important" />}
          primary
          size={size ? size : 'sm'}
          {...props}
        >
          {label}
        </Button>
      ) : (
        <Tooltip label={label} hasArrow placement="top" openDelay={300}>
          <IconButton
            aria-label={label}
            isRound
            icon={<Icon as={RiPlayList2Fill} boxSize="12px !important" />}
            variant={variant}
            colorScheme={colorScheme}
            size={size ? size : 'xs'}
            {...props}
          />
        </Tooltip>
      )}
    </>
  )
}
