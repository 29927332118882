import { GrantedLevel } from '@capturi/core'
import { Box } from '@chakra-ui/react'
import React from 'react'

import { RoleEntry, TeamEntry, UserEntry } from '../domain'
import { AclRoleRow } from './AclRoleRow'
import { AclTeamRow } from './AclTeamRow'
import { AclUserRow } from './AclUserRow'

export const AclEntryRow: React.FC<{
  entry: UserEntry | TeamEntry | RoleEntry
  handleChangeGrantedLevel: (
    entry: UserEntry | TeamEntry | RoleEntry,
    level: GrantedLevel,
  ) => void
  handleRemove: (entry: UserEntry | TeamEntry | RoleEntry) => void
}> = ({ entry, handleChangeGrantedLevel, handleRemove }) => {
  const handleChangeGrantedLevelCallback = React.useCallback(
    (level: GrantedLevel) => {
      handleChangeGrantedLevel(entry, level)
    },
    [entry, handleChangeGrantedLevel],
  )

  const handleRemoveCallback = React.useCallback(() => {
    handleRemove(entry)
  }, [entry, handleRemove])

  switch (entry.type) {
    case 'user':
      return (
        <Box
          borderRadius={4}
          key={entry.userUid}
          _hover={{ bg: 'gray.100' }}
          w="100%"
        >
          <AclUserRow
            userEntry={entry}
            onChangeGrantedLevel={handleChangeGrantedLevelCallback}
            onRemove={handleRemoveCallback}
          />
        </Box>
      )
    case 'team':
      return (
        <Box borderRadius={4} key={entry.teamUid} _hover={{ bg: 'gray.100' }}>
          <AclTeamRow
            teamEntry={entry}
            onChangeGrantedLevel={handleChangeGrantedLevelCallback}
            onRemove={handleRemoveCallback}
          />
        </Box>
      )
    case 'role':
      return (
        <Box borderRadius={4} key={entry.role} _hover={{ bg: 'gray.100' }}>
          <AclRoleRow
            roleEntry={entry}
            onChangeGrantedLevel={handleChangeGrantedLevelCallback}
            onRemove={handleRemoveCallback}
          />
        </Box>
      )
  }
}
