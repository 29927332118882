import { useOrganization } from '@capturi/stores'
import { useCallback, useMemo } from 'react'

import { useKeyTopicConversations } from '../../KeyTopics/hooks/useKeyTopicConversations'

type Props = {
  keyTopicsSessionUid: string | undefined
  selectedKeyTopicId: string | undefined
}

export const useWordCloud = ({
  keyTopicsSessionUid,
  selectedKeyTopicId,
}: Props) => {
  const { conversationLanguage } = useOrganization()
  const compare = useCallback(Intl.Collator(conversationLanguage).compare, [])

  const { data: topicsClusterData } = useKeyTopicConversations({
    sessionUid: keyTopicsSessionUid,
    keyTopicClusterId: selectedKeyTopicId,
    body: {
      pageSize: 1,
      keyTopics: [],
      phrases: [],
      onlyRepeatCalls: false,
    },
  })
  const wordCloud = topicsClusterData?.pages[0]?.wordCloud
  const trendWordCloud = topicsClusterData?.pages[0]?.trendWordCloud

  const notablePhrases = useMemo(() => {
    if (!trendWordCloud) return []
    return Object.entries(trendWordCloud)
      .sort(([keyA, valueA], [keyB, valueB]) => {
        if (valueA === valueB) {
          return compare(keyA, keyB)
        }
        return valueB - valueA
      })
      .slice(0, 2)
      .map(([key]) => key)
  }, [trendWordCloud, compare])

  return useMemo(
    () => ({
      wordCloud,
      trendWordCloud,
      notablePhrases,
    }),
    [wordCloud, trendWordCloud, notablePhrases],
  )
}
