import {
  Acl,
  AclEntry,
  GrantedLevel,
  RolePermissionEntry,
  TeamPermissionEntry,
  UserPermissionEntry,
} from '@capturi/core'
import { ContentPlaceholder, Emoji, Spinner } from '@capturi/ui-components'
import { Flex, Text, VStack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useMemo } from 'react'

import { useAcl } from '../../hooks/useAcl'
import { AclSection } from './AclSection'
import { RoleEntry, TeamEntry, UserEntry } from './domain'

const entriesAreEqual = (
  a: AclEntry,
  b: UserEntry | TeamEntry | RoleEntry,
): boolean => {
  if (a.type !== b.type) return false
  if (a.type === 'user' && b.type === 'user') {
    return a.userUid === b.userUid
  }
  if (a.type === 'team' && b.type === 'team') {
    return a.teamUid === b.teamUid
  }
  if (a.type === 'role' && b.type === 'role') {
    return a.role === b.role
  }
  return false
}

const NoContent: React.FC = () => (
  <ContentPlaceholder.Container mt={10}>
    <ContentPlaceholder.Heading>
      <Emoji symbol="🧑‍🚀" label="cosmonaut" fontSize="4xl" />
    </ContentPlaceholder.Heading>
    <ContentPlaceholder.Body>
      <Text fontSize="md" color="gray.600">
        <Trans>Add a user, team or role...</Trans>
      </Text>
    </ContentPlaceholder.Body>
  </ContentPlaceholder.Container>
)

const AclView: React.FC<{
  acl: Acl
  onChange: (acl: Acl) => void
}> = ({ acl, onChange }) => {
  const {
    usersByGrantedRole,
    getUserByUid,
    getTeamByUid,
    usersByTeam,
    isLoading,
  } = useAcl()

  const roleEntries: RoleEntry[] = useMemo(() => {
    const roleEntries = acl.filter(
      (item) => item.type === 'role',
    ) as RolePermissionEntry[]
    return roleEntries.map((entry) => {
      return {
        ...entry,
        users: usersByGrantedRole[entry.role],
      }
    })
  }, [acl, usersByGrantedRole])

  const teamEntries: TeamEntry[] = useMemo(() => {
    const teamEntries = acl.filter(
      (item) => item.type === 'team',
    ) as TeamPermissionEntry[]
    return teamEntries.map((entry) => {
      return {
        ...entry,
        name: getTeamByUid(entry.teamUid)?.name ?? '',
        users: usersByTeam[entry.teamUid],
      }
    })
  }, [acl, getTeamByUid, usersByTeam])

  const userEntries: UserEntry[] = useMemo(() => {
    const userEntries = acl.filter(
      (item) => item.type === 'user',
    ) as UserPermissionEntry[]
    return userEntries.map((userEntry) => {
      return {
        ...userEntry,
        userDetails: getUserByUid(userEntry.userUid),
      }
    })
  }, [acl, getUserByUid])

  const handleChangeGrantedLevel = React.useCallback(
    (entry: UserEntry | TeamEntry | RoleEntry, grantedLevel: GrantedLevel) => {
      const aclEntry = acl.find((item) => entriesAreEqual(item, entry))
      if (aclEntry) {
        aclEntry.level = grantedLevel
        onChange([...acl])
      }
    },
    [acl, onChange],
  )

  const handleRemove = React.useCallback(
    (entry: UserEntry | TeamEntry | RoleEntry) => {
      const newAcl = acl.filter(
        (item) => entriesAreEqual(item, entry) === false,
      )
      onChange(newAcl)
    },
    [acl, onChange],
  )

  if (isLoading) {
    return (
      <Flex justifyContent="center" w="100%">
        <Spinner />
      </Flex>
    )
  }

  if (acl.length === 0) {
    return <NoContent />
  }

  return (
    <VStack align="start" spacing={2} w="100%" overflowY="scroll">
      <AclSection
        title={t`Roles`}
        entries={roleEntries}
        handleChangeGrantedLevel={handleChangeGrantedLevel}
        handleRemove={handleRemove}
      />
      <AclSection
        title={t`Teams`}
        entries={teamEntries}
        handleChangeGrantedLevel={handleChangeGrantedLevel}
        handleRemove={handleRemove}
      />
      <AclSection
        title={t`Users`}
        entries={userEntries}
        handleChangeGrantedLevel={handleChangeGrantedLevel}
        handleRemove={handleRemove}
      />
    </VStack>
  )
}

export default AclView
