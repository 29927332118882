import analytics from '@capturi/analytics'

export enum NotificationEvent {
  LoadMore = 'notificationCenter_loadMore',
  MarkAllAsRead = 'notificationCenter_markAllAsRead',
  MarkSpecificAsRead = 'notificationCenter_markSpecificAsRead',
}

export const log = (event: NotificationEvent): void => {
  analytics.event(event)
}
