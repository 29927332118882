import { Store } from '@tanstack/react-store'

import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'

export const plottableWordsAtom = atomFamily((_param: string) =>
  atom(new Array<string>()),
)
export const plottableUsersAtom = atomFamily((_param: string) =>
  atom(new Array<string>()),
)
export const plottableTeamsAtom = atomFamily((_param: string) =>
  atom(new Array<string>()),
)

export const timeSeriesDataTabIndexStore = new Store<number>(0)

export type TrackersListSortBy = 'alphabetically' | 'hitrate'

export const trackersListSortByStore = new Store<TrackersListSortBy>(
  'alphabetically',
)
