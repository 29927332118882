import { GrantedRole, Role } from '@capturi/core'
import { Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'

import {
  localizePluralGrantedRole,
  localizePluralRole,
  localizeSingularGrantedRole,
  localizeSingularRole,
} from '../utils'

export const RoleView: React.FC<
  | {
      grantedRole: GrantedRole
      role?: never
    }
  | {
      role: Role
      grantedRole?: never
    }
> = ({ role, grantedRole }) => {
  return (
    <div>
      <Text m="0" fontSize="sm" fontWeight="medium">
        {grantedRole
          ? localizePluralGrantedRole(grantedRole)
          : role && localizePluralRole(role)}
      </Text>
      <Text color="gray.600" fontSize="sm">
        {t`Everyone with the ${
          grantedRole
            ? localizeSingularGrantedRole(grantedRole)
            : role && localizeSingularRole(role)
        } role assigned
       `}
      </Text>
    </div>
  )
}
