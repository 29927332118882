import { DefaultFallbackComponent } from '@capturi/react-utils'
import { Spinner } from '@capturi/ui-components'
import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { AnimatePresence, motion } from 'framer-motion'
import { FC, useCallback, useMemo, useState } from 'react'

import { useConversationDetailsDrawer } from '../../../../components/ConversationDetailsDrawer'
import { Pagination } from '../../components/Pagination'
import { useLongConversationExamples } from '../hooks/useLongConversationExamples'
import { LongConversationsExampleListItem } from './LongConversationsExampleListItem'

const itemsPerPage = 3

const variants = {
  enter: (direction: string) => {
    return {
      x: direction === 'right' ? 1536 : -1536,
      zIndex: 1,
      opacity: 0,
      width: '100%',
    }
  },
  center: {
    x: 0,
    zIndex: 1,
    opacity: 1,
    width: '100%',
  },
  exit: (direction: string) => {
    return {
      x: direction === 'left' ? 1536 : -1536,
      opacity: 0,
      zIndex: 1,
      width: '100%',
    }
  },
}

export const LongConversationsExampleList: FC = () => {
  const {
    data: sentimentSummaries,
    isLoading,
    error,
  } = useLongConversationExamples()
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [direction, setDirection] = useState<string>('right')

  const summariesToShow = useMemo(
    () =>
      sentimentSummaries?.slice(
        currentPage * itemsPerPage,
        currentPage * itemsPerPage + itemsPerPage,
      ),
    [sentimentSummaries, currentPage],
  )

  const openConversationDetailsDrawer = useConversationDetailsDrawer()
  const handleSummaryClick = useCallback(
    (summaryUid: string) => {
      openConversationDetailsDrawer({
        uid: summaryUid,
        showOverlay: true,
      })
    },
    [openConversationDetailsDrawer],
  )

  if (error) return <DefaultFallbackComponent error={error} />

  if (isLoading) {
    return (
      <HStack justify="center" w="100%" p={3}>
        <Spinner color="primary.500" />
      </HStack>
    )
  }

  if (!summariesToShow) {
    return (
      <HStack justify="center" w="100%" p={3}>
        <Text fontSize="sm" fontWeight="medium" mb={2}>
          <Trans>No examples to display. Try again.</Trans>
        </Text>
      </HStack>
    )
  }

  const handlePageChange = (pageIndex: number): void => {
    setDirection(pageIndex > currentPage ? 'right' : 'left')
    setCurrentPage(pageIndex)
  }

  const totalPages = Math.ceil((sentimentSummaries?.length || 0) / itemsPerPage)

  return (
    <VStack alignItems="end">
      <Flex w="100%" gap="4" minH="182px">
        <AnimatePresence initial={false} mode="popLayout" custom={direction}>
          {summariesToShow?.map((summary, index) => {
            const originalIndex = currentPage * itemsPerPage + index + 1
            return (
              <Flex
                flex="0 0 calc( 33.3333% - 10.7px)"
                w="20px"
                key={summary.uid}
              >
                <motion.div
                  key={currentPage}
                  custom={direction}
                  variants={variants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{
                    ease: 'easeInOut',
                    duration: 0.7,
                  }}
                >
                  <HStack
                    align="stretch"
                    h="100%"
                    w="100%"
                    key={summary.uid}
                    gap={0}
                  >
                    <LongConversationsExampleListItem
                      summary={summary}
                      currentIndex={originalIndex}
                      onSummaryClick={handleSummaryClick}
                    />
                  </HStack>
                </motion.div>
              </Flex>
            )
          })}
        </AnimatePresence>
      </Flex>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </VStack>
  )
}
