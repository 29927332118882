import { isDateString } from './isDateString'

const URL_ENCODED_DATE_REGEX =
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d%3A[0-5]\d%3A[0-5]\d(?:\.\d+)?Z?)/

const DAY_IN_MS = 1_440_000

export const updateDatesInUrl = (url: string, msToSub = 0): string => {
  if (!msToSub) return url

  const urlsParts = url.split(URL_ENCODED_DATE_REGEX)

  return urlsParts.reduce((memo, val) => {
    if (val.match(URL_ENCODED_DATE_REGEX)) {
      const date = new Date(val.replaceAll('%3A', ':')) //W hen we get the date it is url encoded, so we "decode" it
      const timeInThePast = new Date(new Date(date).getTime() - msToSub)
      return memo + timeInThePast.toJSON().replaceAll(':', '%3A') // and when we are done, we reencode the url again
    }
    return memo + val
  }, '')
}

/**
 * When we create a demo org, we load conversations for a short period of time,
 * usually one month, to make it look like the conversation is from today
 * add the difference in time from when the conversations was loaded to now
 * So when the user request data from today, it i actually X days old
 */
const customReviver = (
  _key: string,
  value: string,
  msToAdd = 0,
): string | number | Date => {
  if (isDateString(value)) {
    if (msToAdd === 0) {
      return new Date(value)
    }
    const originalDate = new Date(value)

    //If the original day is in the weekend, there isn't any conversations
    //So we merge that day with a non weekend, so we don't get any empty days
    if (originalDate.getDay() === 0) {
      return new Date(originalDate.getTime() + msToAdd + DAY_IN_MS)
    }
    if (originalDate.getDay() === 6) {
      return new Date(originalDate.getTime() + msToAdd + 2 * DAY_IN_MS)
    }
    return new Date(originalDate.getTime() + msToAdd)
  }
  return value
}

export async function parseJSON<T>(
  response: Response,
  msToSub = 0,
): Promise<T> {
  if (response.status === 204 || response.status === 205) {
    return null as unknown as T
  }
  const respAsText = await response.text()
  if (respAsText) {
    return JSON.parse(respAsText, (key, value) =>
      customReviver(key, value, msToSub),
    )
  }
  return null as unknown as T
}

/**
 * When we create a demo org, we load conversations for a short period of time,
 * usually one month, to make it look like the conversation is from today
 * add the difference in time from when the conversations was loaded to now
 * So when the user gets data from XX months ago,it looks like it is from today
 */
const customReplacer = (_key: string, value: unknown, msToSub = 0): unknown => {
  if (isDateString(value)) {
    const timeInThePast = new Date(
      new Date(value as string).getTime() - msToSub,
    )

    return timeInThePast.toJSON()
  }
  return value
}

export const stringifyJSON = (value: unknown, msToAdd = 0): string => {
  if (msToAdd === 0) {
    return JSON.stringify(value)
  }
  return JSON.stringify(
    value,
    (key, val) => customReplacer(key, val, msToAdd),
    '',
  )
}
