import { Team, useTeams } from '@capturi/core'
import { User, useUsers } from '@capturi/stores'
import { useMemo } from 'react'

import { groupUsers } from '../utils'

type UsersDict = Record<string, User[]>

interface UseAclResponse {
  isLoading: boolean

  usersByRole: UsersDict
  usersByGrantedRole: UsersDict
  getUserByUid: (id?: string) => User | undefined
  getTeamByUid: (uid: string) => Team | undefined
  usersByTeam: UsersDict
}

export function useAcl(): UseAclResponse {
  const { users, getUserByUid, isLoading: isLoadingUsers } = useUsers()
  const { getTeamByUid, isLoading: isLoadingTeams } = useTeams()

  return useMemo(() => {
    const { usersByGrantedRole, usersByRole, usersByTeamUid } =
      groupUsers(users)

    return {
      isLoading: isLoadingUsers || isLoadingTeams,
      usersByRole: usersByRole,
      usersByGrantedRole: usersByGrantedRole,
      getUserByUid: getUserByUid,
      getTeamByUid: getTeamByUid,
      usersByTeam: usersByTeamUid,
    }
  }, [getTeamByUid, getUserByUid, isLoadingTeams, isLoadingUsers, users])
}
