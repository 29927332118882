import { apiRequest } from './request'
import { RequestOptions } from './types'

async function downloadBlob(
  input: string | RequestOptions,
  name: string,
): Promise<void> {
  //Download file to download
  let url: string | undefined
  let options = {}
  if (typeof input === 'string') {
    url = input
  } else {
    const { url: roUrl, ...roOptions } = input
    url = roUrl
    options = roOptions
  }

  const response = await apiRequest(url, options)
  const blob = await response.blob()

  const a = document.createElement('a')
  a.style.display = 'none'
  document.body.appendChild(a)
  a.href = window.URL.createObjectURL(blob)
  a.setAttribute('download', name)
  a.click()

  // Cleanup
  window.URL.revokeObjectURL(a.href)
  document.body.removeChild(a)
}

export default downloadBlob
