import { useEffect, useMemo } from 'react'
import { OrgType, organizationStore } from './organizationStore'

export const useOrganization = (
  isDisabled?: boolean,
): {
  uid: string
  name: string
  conversationLanguage: string
  organizationType: OrgType
  demoOrgSettings: {
    newestConversationDate: Date
  } | null
  uiLanguage: string
  isSucceeded: boolean
} => {
  const {
    fetch,
    conversationLanguage,
    organizationType,
    demoOrgSettings,
    uid,
    name,
    uiLanguage,
    isSucceeded,
  } = organizationStore((state) => ({
    conversationLanguage: state.conversationLanguage,
    demoOrgSettings: state.demoOrgSettings,
    features: state.features,
    uid: state.uid,
    name: state.name,
    organizationType: state.organizationType,
    uiLanguage: state.uiLanguage,
    fetch: state.fetch,
    isSucceeded: state.isSucceeded,
  }))

  // biome-ignore lint/correctness/useExhaustiveDependencies: fetch is stable
  useEffect(() => {
    if (!isDisabled) {
      fetch()
    }
  }, [isDisabled])

  return useMemo(
    () => ({
      uid,
      name,
      conversationLanguage,
      organizationType,
      demoOrgSettings,
      uiLanguage,
      isSucceeded,
    }),
    [
      uid,
      name,
      conversationLanguage,
      organizationType,
      demoOrgSettings,
      uiLanguage,
      isSucceeded,
    ],
  )
}
