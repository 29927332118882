import { Button } from '@capturi/ui-components'
import {
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { useFormState } from 'react-hook-form'
import { MdContentCopy, MdMoreHoriz, MdSystemUpdateAlt } from 'react-icons/md'

const FormActions: React.FC<{
  onCancel: () => void
  onDelete?: () => void
  isLoading: boolean
  isSaving: boolean
  isSaveDisabled?: boolean
  isDeleting?: boolean
  isDeleteDisabled?: boolean
  showDeleteButton?: boolean
  deleteTooltip?: string
  saveTooltip?: string
  onExportTracker?: () => void
  onImportTracker?: () => Promise<void>
}> = ({
  onCancel,
  onDelete,
  isLoading = false,
  isSaving,
  isSaveDisabled,
  isDeleting,
  isDeleteDisabled,
  showDeleteButton = false,
  deleteTooltip,
  saveTooltip,
  onExportTracker,
  onImportTracker,
}) => {
  const { isSubmitting } = useFormState()
  return (
    <Flex w="100%">
      {showDeleteButton && (
        <Tooltip label={deleteTooltip} placement="top">
          <Button
            colorScheme="red"
            onClick={() => {
              onDelete?.()
            }}
            isDisabled={isDeleteDisabled || isDeleting || isSaving}
            isLoading={isDeleting}
          >
            <Trans>Delete</Trans>
          </Button>
        </Tooltip>
      )}
      <HStack spacing={2} justify="flex-end" ml="auto">
        {(onImportTracker || onExportTracker) && (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              fontSize="xl"
              icon={<MdMoreHoriz />}
            />
            <MenuList>
              {onExportTracker && (
                <MenuItem
                  onClick={onExportTracker}
                  icon={<MdSystemUpdateAlt />}
                >
                  <Trans>Copy tracker to clipboard</Trans>
                </MenuItem>
              )}
              {onImportTracker && (
                <MenuItem onClick={onImportTracker} icon={<MdContentCopy />}>
                  <Trans>Import tracker from clipboard</Trans>
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        )}
        <Button
          secondary
          type="button"
          onClick={onCancel}
          isDisabled={isSaving || isDeleting}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Tooltip
          label={saveTooltip}
          isDisabled={!isSaveDisabled}
          placement="top"
          hasArrow
        >
          <Button
            primary
            type="submit"
            isLoading={isLoading || isSubmitting || isSaving}
            isDisabled={isSaveDisabled || isDeleting}
          >
            <Trans>Save tracker</Trans>
          </Button>
        </Tooltip>
      </HStack>
    </Flex>
  )
}

export default FormActions
