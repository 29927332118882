import { useLingui } from '@lingui/react'
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
} from 'date-fns'
import React from 'react'

const RelativeTimeFormatOptions: Intl.RelativeTimeFormatOptions = {
  localeMatcher: 'best fit',
  numeric: 'auto',
  style: 'narrow',
}

const RelativeTimeFormat: React.FC<{ date: Date }> = ({ date }) => {
  const { i18n } = useLingui()
  const output = React.useMemo(() => {
    const rtf: Intl.RelativeTimeFormat = new Intl.RelativeTimeFormat(
      i18n.locale,
      RelativeTimeFormatOptions,
    )

    const now = new Date()
    const diffInMonths = differenceInMonths(date, now)
    const diffInWeeks = differenceInWeeks(date, now)
    const diffInDays = differenceInDays(date, now)
    const diffInHours = differenceInHours(date, now)
    const diffInMinutes = differenceInMinutes(date, now)
    const diffInSeconds = differenceInSeconds(date, now)

    let formatValue: number = diffInSeconds
    let formatUnit: Intl.Unit = 'second'

    if (Math.abs(diffInMonths) > 0) {
      formatValue = diffInMonths
      formatUnit = 'month'
    } else if (Math.abs(diffInWeeks) > 0) {
      formatValue = diffInWeeks
      formatUnit = 'week'
    } else if (Math.abs(diffInDays) > 0) {
      formatValue = diffInDays
      formatUnit = 'day'
    } else if (Math.abs(diffInHours) > 0) {
      formatValue = diffInHours
      formatUnit = 'hour'
    } else if (Math.abs(diffInMinutes) > 0) {
      formatValue = diffInMinutes
      formatUnit = 'minute'
    }
    return rtf.format(formatValue, formatUnit)
  }, [date, i18n.locale])
  return <span>{output}</span>
}

export default RelativeTimeFormat
