import ResponseError from './responseError'

export const getErrorObject = (
  error: unknown,
  fallbackError = Error('unknown error'),
): Error => {
  if (error instanceof Error) {
    return error
  }

  return fallbackError
}
export const getErrorMessage = (
  error: unknown,
  messagesByStatusCode: { [code: number]: string } = {},
): string | null => {
  try {
    if (error instanceof ResponseError) {
      const statusCode = error.statusCode
      if (statusCode !== undefined && messagesByStatusCode[statusCode]) {
        return messagesByStatusCode[statusCode]
      }
      return error.message
    }
  } catch {
    // continues
  }
  if (error instanceof Error) {
    return error.message
  }
  return null
}
