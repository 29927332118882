// import { spacing } from './spacing'
import { theme } from '@chakra-ui/theme'

const sizes = {
  ...theme.sizes,
  container: {
    ...theme.sizes.container,
    '2xl': '1536px',
  },
}

export default sizes
