import { useCurrentUser } from '@capturi/core'
import { useCallback } from 'react'
import { useLocalStorage } from 'react-use'

export function useLastSelectedTracker(): {
  lastSelectedTracker: string | undefined
  setLastSelectedTracker: (uid: string | undefined) => void
  resetLastSelecetedTracker: () => void
} {
  const currentUser = useCurrentUser()
  const [lastSelectedTracker, setLastSelectedTracker] = useLocalStorage<string>(
    `lastSelectedTracker_${currentUser.organizationId}`,
  )

  const resetLastSelecetedTracker = useCallback(() => {
    setLastSelectedTracker('')
  }, [setLastSelectedTracker])

  return {
    lastSelectedTracker,
    setLastSelectedTracker,
    resetLastSelecetedTracker,
  }
}
