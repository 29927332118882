import { Box, BoxProps, HeadingProps, Text, TextProps } from '@chakra-ui/react'
import React from 'react'

import { Description, SectionHeading } from '../Typography'

const Card: React.FC<BoxProps> = (props) => (
  <Box
    boxShadow="lg"
    border="1px"
    borderColor="gray.200"
    borderRadius="md"
    {...props}
  />
)

const CardContent: React.FC<BoxProps> = (props) => <Box p={4} {...props} />

const CardHeader: React.FC<HeadingProps> = (props) => (
  <SectionHeading fontFamily="body" fontWeight="medium" mb={0} {...props} />
)

const CardMeta: React.FC<TextProps> = (props) => <Description {...props} />

const CardDescription: React.FC<TextProps> = (props) => (
  <Text mt={2} {...props} />
)

export { Card, CardContent, CardHeader, CardMeta, CardDescription }
