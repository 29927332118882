import { Box, BoxProps, forwardRef } from '@chakra-ui/react'

const Table = forwardRef<
  {
    layout?: 'fixed' | 'auto'
  } & BoxProps,
  'table'
>(({ layout, ...restProps }, ref) => (
  <Box
    ref={ref}
    as="table"
    width="100%"
    table-layout={layout ?? 'auto'}
    {...restProps}
  />
))

const Td = forwardRef<BoxProps, 'td'>((props, ref) => (
  <Box
    ref={ref}
    as="td"
    px={4}
    py={2}
    borderTop="1px"
    borderTopColor="border.light"
    {...props}
  />
))

const Tr = forwardRef<BoxProps, 'tr'>((props, ref) => (
  <Box
    ref={ref}
    as="tr"
    transition={'background-color 0.3s ease'}
    _hover={{
      backgroundColor: 'gray.50',
    }}
    {...props}
  />
))

const Th = forwardRef<BoxProps, 'th'>((props, ref) => (
  <Box
    ref={ref}
    as="th"
    textAlign="left"
    py={2}
    px={4}
    fontWeight="medium"
    {...props}
  />
))

export { Table, Th, Tr, Td }
