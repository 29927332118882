export default {
  baseStyle: {
    transition: 'all 0.15s ease-out',
    cursor: 'pointer',
    textDecoration: 'none',
    outline: 'none',
    color: 'inherit',
    _hover: {
      textDecoration: 'underline',
    },
    _focus: {
      boxShadow: 'outline',
    },
  },
  variants: {
    primary: {
      textDecoration: 'none',
      fontWeight: 'bold',
      color: 'primary.500',
    },
    secondary: {
      textDecoration: 'underline',
    },
    unstyled: {
      textDecoration: 'none',
      outline: 'none',
      color: 'inherit',
      _hover: {
        textDecoration: 'none',
      },
      _focus: {
        boxShadow: 'none',
      },
    },
  },
}
