import request from '@capturi/request'
import { useToast } from '@capturi/ui-components'
import { plural, t } from '@lingui/macro'
import constate from 'constate'
import { useMemo } from 'react'
import { useSWRConfig } from 'swr'

import { api as apiFactory, useAPI } from '../api'
import { NotificationEvent, log } from '../events'

const api = apiFactory()

interface Notifications {
  /**
   * Number of unread messages
   */
  unreadCount: number
  /**
   * Mark all notifications as read
   */
  markAllAsRead: () => Promise<void>
  /**
   * Mark a list of notifications as read
   * @param uids a list of notification uids
   */
  markSpecificAsRead: (uids: string[]) => Promise<void>
  /**
   * Mark a list of comment notifications as read
   * @param commentUids a list of comment uids
   */
  markCommentNotificationsAsRead: (commentUids: string[]) => Promise<void>
  /**
   * Mark a list of audio snippet notifications as read
   * @param audioSnippetUids a list of audio snippet uids
   */
  markAudioSnippetNotificationsAsRead: (
    audioSnippetUids: string[],
  ) => Promise<void>
}

type UnreadCountResponseModel = {
  unreadNotificationCount: number
}

const swrOptions = {
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
  refreshInterval: 15000,
  suspense: false,
}

const revalidateNotificationsCache = (
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  cache: Map<string, any>,
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  mutate: (key: string) => Promise<any>,
): void => {
  /**
   * FIXME: this is a hack to revalidate all SWR cache keys related to
   * the notification list pages. Currently, I can't find another way to
   * access the cache keys for the individual pages here.
   */
  const [endpoint] = api.getNotifications()
  Array.from(cache.keys()).forEach((key) => {
    // Test cache keys to see if they match the api.getNotifications() endpoint
    if (key.startsWith(endpoint.split('?')[0]) && key.includes('pageNumber')) {
      // Trigger revalidation
      mutate(key)
    }
  })
}

const useNotifications = (): Notifications => {
  const { data: unreadCountResp, mutate: mutateUnreadCount } =
    useAPI<UnreadCountResponseModel>((api) => api.getUnreadCount(), swrOptions)
  const { mutate: swrCacheMutate, cache } = useSWRConfig()
  if (!(cache instanceof Map)) {
    throw new Error(
      'useNotifications requires the cache provider to be a Map instance',
    )
  }

  const errorToast = useToast({
    title: t`An error occurred`,
    status: 'error',
    duration: 5000,
    isClosable: true,
  })

  return useMemo(() => {
    const { unreadNotificationCount = 0 } = unreadCountResp ?? {}

    const markAllAsRead = async (): Promise<void> => {
      try {
        // Call endpoint
        await request.post(...api.markAllAsRead())
        // Update caches and trigger revalidation
        mutateUnreadCount({ unreadNotificationCount: 0 })
        revalidateNotificationsCache(cache, swrCacheMutate)
        // log event
        log(NotificationEvent.MarkAllAsRead)
      } catch (_e) {
        errorToast({
          description: t`Notifications were not marked as read`,
        })
      }
    }

    const markSpecificAsRead = async (uids: string[]): Promise<void> => {
      if (uids.length === 0) return
      try {
        // Call endpoint
        await request.post(...api.markSpecificAsRead(uids))
        // trigger cache revalidation
        mutateUnreadCount()
        revalidateNotificationsCache(cache, swrCacheMutate)
        // log event
        log(NotificationEvent.MarkSpecificAsRead)
      } catch (_e) {
        errorToast({
          description: plural(uids.length, {
            one: 'Notification was not marked as read',
            other: 'Notifications were not marked as read',
          }),
        })
      }
    }

    const markCommentNotificationsAsRead = async (
      commentUids: string[],
    ): Promise<void> => {
      if (commentUids.length === 0) return
      try {
        // Call endpoint
        await request.post(...api.markCommentNotificationsAsRead(commentUids))
        // trigger cache revalidation
        mutateUnreadCount()
        revalidateNotificationsCache(cache, swrCacheMutate)
      } catch (_e) {
        //
      }
    }

    const markAudioSnippetNotificationsAsRead = async (
      audioSnippetUids: string[],
    ): Promise<void> => {
      if (audioSnippetUids.length === 0) return
      try {
        // Call endpoint
        await request.post(
          ...api.markAudioSnippetNotificationsAsRead(audioSnippetUids),
        )
        // trigger cache revalidation
        mutateUnreadCount()
        revalidateNotificationsCache(cache, swrCacheMutate)
      } catch (_e) {
        //
      }
    }

    return {
      unreadCount: unreadNotificationCount,
      markAllAsRead,
      markSpecificAsRead,
      markCommentNotificationsAsRead,
      markAudioSnippetNotificationsAsRead,
    }
  }, [unreadCountResp, mutateUnreadCount, cache, swrCacheMutate, errorToast])
}

const [NotificationsProvider, useNotificationsContext] =
  constate(useNotifications)

export { NotificationsProvider, useNotificationsContext }
