import { useMemo } from 'react'
import { Benchmark } from './types'

type CombinedScores = {
  rootScore: { name: string; uid: string; avgScore: number }
  score: { name: string; uid: string; avgScore: number }
}

type UseBenchmarkScores = {
  combinedScores: CombinedScores[]
  sumOfScores: number
}
export const useBenchmarkScores = (
  scores: Benchmark['scores'],
  referenceScores: Benchmark['scores'],
): UseBenchmarkScores => {
  return useMemo(() => {
    const sumOfScores = scores.reduce(
      (total, score) => total + score.avgScore,
      0,
    )
    const combinedScores = referenceScores.reduce<CombinedScores[]>(
      (memo, score) => {
        scores.forEach((s) => {
          if (s.uid === score.uid) {
            memo.push({
              score: { uid: s.uid, name: s.name, avgScore: s.avgScore },
              rootScore: {
                uid: score.uid,
                name: score.name,
                avgScore: score.avgScore,
              },
            })
          }
        })
        return memo
      },
      [],
    )
    return { combinedScores, sumOfScores }
  }, [scores, referenceScores])
}
