import { InfiniteData } from '@tanstack/react-query'
import { useCallback, useMemo, useState } from 'react'
import { KeyTopicConversationClusterResponse } from '../../../pages/KeyTopics/hooks/types'

const askAiLoadMoreStep = 5
export const useAskAiConversations = ({
  conversationData,
  fetchNextPage,
  hasNextPageInConversationData,
  askAiQuery,
}: {
  conversationData:
    | InfiniteData<KeyTopicConversationClusterResponse>
    | undefined
  fetchNextPage: () => void
  hasNextPageInConversationData: boolean | undefined
  askAiQuery: string
}) => {
  const totalConversations = useMemo(() => {
    return (
      conversationData?.pages.reduce(
        (acc, curr) => acc + curr.conversations.length,
        0,
      ) ?? 0
    )
  }, [conversationData])

  const [aiConversationsToShow, setAiConversationsToShow] =
    useState(totalConversations)

  const handleSetAskAiQuery = useCallback(
    (query: string) => {
      if (query.length > 0) {
        setAiConversationsToShow(
          Math.min(askAiLoadMoreStep, totalConversations),
        )
      } else {
        setAiConversationsToShow(totalConversations)
      }
    },
    [totalConversations],
  )

  const hasNextPageAssumingAskAiQueryMode = useMemo(() => {
    // if not in ask-ai mode, we just fall back to regular hasNextPage
    if (askAiQuery.length === 0) return hasNextPageInConversationData

    // otherwise we follow load more logic for ask-ai mode
    // if there are more regular conversations, we can load more in ask-ai
    if (aiConversationsToShow < totalConversations) return true

    // if we have reached the limit, we need to load more conversationData, if possible
    return hasNextPageInConversationData
  }, [
    askAiQuery,
    aiConversationsToShow,
    hasNextPageInConversationData,
    totalConversations,
  ])

  const handleLoadMore = useCallback(async () => {
    if (!hasNextPageAssumingAskAiQueryMode) return

    if (askAiQuery.length > 0) {
      // if we have room in totalConversations, we just add askAiLoadMoreStep
      // if not, we fetch the next page first
      if (
        aiConversationsToShow + askAiLoadMoreStep > totalConversations &&
        hasNextPageInConversationData
      ) {
        await fetchNextPage()
      }

      setAiConversationsToShow((prev) => prev + askAiLoadMoreStep)
    } else {
      await fetchNextPage()
    }
  }, [
    askAiQuery,
    totalConversations,
    aiConversationsToShow,
    fetchNextPage,
    hasNextPageInConversationData,
    hasNextPageAssumingAskAiQueryMode,
  ])

  return useMemo(
    () => ({
      askAiQuery,
      setAskAiQuery: handleSetAskAiQuery,
      conversationsToShow:
        askAiQuery.length > 0 ? aiConversationsToShow : totalConversations,
      handleLoadMore,
      hasNextPage: hasNextPageAssumingAskAiQueryMode,
    }),
    [
      askAiQuery,
      aiConversationsToShow,
      handleLoadMore,
      handleSetAskAiQuery,
      totalConversations,
      hasNextPageAssumingAskAiQueryMode,
    ],
  )
}
