import { Button } from '@capturi/ui-components'
import { ButtonGroup, Flex, Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'

import { IsNotMenu } from './IsNotMenu'
import { MultiSelectFooterProps } from './MultiSelectFooter'

export type InvertibleMultiSelectFooterProps = MultiSelectFooterProps & {
  entityName: string
  isNot: boolean
  onChangeIsNot: (isNot: boolean) => void
}

export const InvertibleMultiSelectFooter: React.FC<
  InvertibleMultiSelectFooterProps
> = ({
  onReset,
  onSubmit,
  onSelectAllOptions,
  resetText = t`Reset`,
  submitText = t`OK`,
  isSubmitDisabled = false,
  selectAllText,
  formatSelectedCount,
  selectedCount,
  entityName,
  isNot,
  onChangeIsNot,
  ...props
}) => {
  return (
    <Flex
      direction="column-reverse"
      boxShadow={'0 -4px 8px -6px rgba(0,0,0,0.2)'}
    >
      <Flex
        justify="space-between"
        align="center"
        px={2}
        pb={1}
        pt={2}
        zIndex={1}
        borderTop="1px solid"
        borderColor="gray.200"
        {...props}
      >
        {selectedCount != null && formatSelectedCount && (
          <Text px={2} fontSize="sm">
            {formatSelectedCount?.(selectedCount)}
          </Text>
        )}

        <ButtonGroup size="sm">
          {typeof onReset === 'function' && (
            <Button onClick={onReset} secondary variant="ghost">
              {resetText}
            </Button>
          )}
          {typeof onSubmit === 'function' && (
            <Button
              isDisabled={isSubmitDisabled}
              onClick={onSubmit}
              primary
              variant="ghost"
            >
              {submitText}
            </Button>
          )}
        </ButtonGroup>
      </Flex>
      <Flex justify="space-between" align="center" zIndex={1} p={2}>
        {selectAllText && (
          <Button onClick={onSelectAllOptions} secondary variant="ghost" mr="2">
            {selectAllText}
          </Button>
        )}
        <IsNotMenu
          entityName={entityName}
          isNot={isNot}
          onChangeIsNot={onChangeIsNot}
        />
      </Flex>
    </Flex>
  )
}
