import noop from 'lodash/noop'
import React, { useState } from 'react'

import { PeriodDefinition, useFilterPeriodContext } from '../../period'
import { DateRangeDialog } from './components/DateRangeDialog'
import { FilterPeriodEvent, logEvent } from './events'

type FilterPeriodSelectContextType = {
  value?: PeriodDefinition
  setValue: React.Dispatch<React.SetStateAction<PeriodDefinition>>
  applyValue: (value?: PeriodDefinition, cb?: () => void) => void
}

export const FilterPeriodSelectContext =
  React.createContext<FilterPeriodSelectContextType>({
    setValue: noop,
    applyValue: noop,
  })

export const FilterPeriodSelectContainer: React.FC<{
  onSelectPeriod?: (period: PeriodDefinition) => void
  usePortal?: boolean
}> = ({ onSelectPeriod, usePortal }) => {
  const { periodDef, setPeriodDef } = useFilterPeriodContext()
  const [value, setValue] = useState<PeriodDefinition>(periodDef)

  React.useEffect(() => {
    setValue(periodDef)
  }, [periodDef])

  const applyValue = (pd?: PeriodDefinition, cb?: () => void): void => {
    const newValue = pd ?? value
    setPeriodDef(newValue)
    logEvent(FilterPeriodEvent.SetDatePeriod, {
      name: newValue.name,
      dayCount: newValue.create(new Date()).days(),
    })
    cb?.()
    onSelectPeriod?.(newValue)
  }

  const context: FilterPeriodSelectContextType = {
    value,
    setValue,
    applyValue,
  }

  return (
    <FilterPeriodSelectContext.Provider value={context}>
      <DateRangeDialog usePortal={usePortal} />
    </FilterPeriodSelectContext.Provider>
  )
}
