import { useFeatureFlags } from '@capturi/feature-flags'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { KeyTopicsPage } from './KeyTopicsPage'

export { routes } from './routes'

const KeyTopicsRouter: FC = () => {
  const { aiFeatures } = useFeatureFlags()

  if (!aiFeatures) {
    return null
  }

  return (
    <Routes>
      <Route path="/*" element={<KeyTopicsPage />} />
    </Routes>
  )
}

export default KeyTopicsRouter
