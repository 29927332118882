import { SentimentSpeaker } from '@capturi/api-sentiment'
import constate from 'constate'
import qs from 'query-string'
import React from 'react'
import useSyncSearchParam from 'router/useSyncSearchParam'

export type SpeakerType = Exclude<SentimentSpeaker, 'Combined'>

export const QUERY_KEY = 'speaker'

type Props = {
  initialValue:
    | SpeakerType
    | ((valueFromUrl: SentimentSpeaker | undefined) => SpeakerType)
}

function useSpeaker({
  initialValue,
}: Props): [SpeakerType, (speaker: SpeakerType) => void] {
  const state = React.useState(() => {
    if (typeof initialValue === 'function') {
      return initialValue(readSpeakerFromUrl())
    }
    return initialValue
  })

  useSyncSearchParam(QUERY_KEY, state[0])
  return state
}

const [SpeakerProvider, useSpeakerContext] = constate(useSpeaker)

export { SpeakerProvider, useSpeakerContext }

function readSpeakerFromUrl(
  queryKey: string = QUERY_KEY,
  urlQuery: string = window.location.search,
): SentimentSpeaker | undefined {
  const parsedQuery = qs.parse(urlQuery)
  let speakerParam = parsedQuery[queryKey]
  if (Array.isArray(speakerParam)) {
    speakerParam = speakerParam[0]
  }
  if (speakerParam == null) {
    return undefined
  }
  return speakerParam as SentimentSpeaker
}
