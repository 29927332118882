import analytics from '@capturi/analytics'
import { Tracker } from '@capturi/api-conversations'
import { ConversationResponseModel } from '@capturi/api-conversations'
import { BaseTracker } from '@capturi/api-trackers'

import { Button, Flex, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Hit } from 'components/ConversationDetails/Audio/types'
import { useTrackerHitUtils } from 'components/ConversationDetails/Hooks/UseTrackerHitUtils'
import React, { useEffect } from 'react'

import TrackerMetadata from '../TrackerMetadata'
import { TrackerTableContentsResult } from '../useTrackerTableContents'
import OverviewTrackerContent from './OverviewTrackerContent'

type Props = {
  hitMap: Map<string, TrackerMetadata>
  combined: { trackerHit: Tracker; tracker: BaseTracker | undefined }[]
  contents: TrackerTableContentsResult
  conversation: ConversationResponseModel
  selectedTrackerUid: string | null

  setHits: (hits: Hit[]) => void
  onNavigateToTracker: (selectedTrackerUid: string | null) => void
}

const OverviewTrackerList: React.FC<Props> = ({
  hitMap,
  combined,
  contents,
  selectedTrackerUid,
  setHits,
  onNavigateToTracker,
  conversation,
}) => {
  const { convertResultToHit, shapeForHit, colorForHit } = useTrackerHitUtils()

  const { uid: conversationUid, dateTime } = conversation

  useEffect(() => {
    const selectedHits = contents.results.map((hit) => {
      return convertResultToHit(
        hit,
        selectedTrackerUid != null,
        shapeForHit(hit, contents),
        colorForHit(hit, contents),
        false,
      )
    })
    const extraHits = contents.extraResults.map((hit) => {
      return convertResultToHit(
        hit,
        false,
        shapeForHit(hit, contents),
        colorForHit(hit, contents),
        true,
      )
    })

    const hits = [...extraHits, ...selectedHits]
    setHits?.(hits)
  }, [
    selectedTrackerUid,
    contents,
    convertResultToHit,
    setHits,
    shapeForHit,
    colorForHit,
  ])

  return (
    <Flex flexWrap="wrap" gap={2}>
      {combined.length > 0 ? (
        combined.map((item) => (
          <Button
            height={6}
            p={2}
            key={item.tracker?.uid}
            borderRadius="12px"
            size="xs"
            onClick={() => {
              onNavigateToTracker(item.tracker?.uid ?? null)
              analytics.event('conversationDetails_trackerList_playAudio', {
                isWordSearch: false,
                conversationUid,
                date: dateTime.toUTCString(),
              })
            }}
          >
            <OverviewTrackerContent
              tracker={item.tracker}
              key={item.tracker?.uid}
              trackerHit={item.trackerHit}
              hitMap={hitMap}
            />
          </Button>
        ))
      ) : (
        <Text fontSize="sm" fontWeight="medium">
          <Trans>No results</Trans>
        </Text>
      )}
    </Flex>
  )
}

export default OverviewTrackerList
