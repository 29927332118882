import { ResponseError } from '@capturi/request'
import { Button, ContentPlaceholder } from '@capturi/ui-components'
import { Center } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

const SessionCreationFailed: React.FC<{
  error: ResponseError
  startNewSession: () => void
}> = ({ error, startNewSession }) => {
  const isTooManyRequestsError = error.statusCode === 429
  return (
    <ContentPlaceholder.Container mt={20}>
      <ContentPlaceholder.Heading>
        {isTooManyRequestsError ? (
          <Trans>Request Limit Reached</Trans>
        ) : (
          <Trans>Session Creation Failed</Trans>
        )}
      </ContentPlaceholder.Heading>
      <ContentPlaceholder.Body>
        {isTooManyRequestsError ? (
          <Trans>
            Unfortunately, you've reached the request limit. Please wait 1
            minute and try again.
          </Trans>
        ) : (
          <Trans>
            Something went wrong while creating a new session. Please try again.
          </Trans>
        )}
      </ContentPlaceholder.Body>
      <ContentPlaceholder.Footer>
        <Center>
          <Button primary onClick={startNewSession}>
            <Trans>Update</Trans>
          </Button>
        </Center>
      </ContentPlaceholder.Footer>
    </ContentPlaceholder.Container>
  )
}

export default SessionCreationFailed
