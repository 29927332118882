import {
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  IconButton,
  useEditableControls,
} from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { t } from '@lingui/macro'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { MdCheck, MdClose, MdEdit } from 'react-icons/md'

const EditableControls: React.FC<FlexProps> = (props) => {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls()

  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm" {...props}>
      <IconButton
        icon={<MdCheck />}
        aria-label={t`Save changes`}
        {...getSubmitButtonProps()}
      />
      <IconButton
        icon={<MdClose />}
        aria-label={t`Cancel changes`}
        {...getCancelButtonProps()}
      />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center" {...props}>
      <IconButton
        size="sm"
        icon={<MdEdit />}
        aria-label={t`Edit`}
        {...getEditButtonProps()}
      />
    </Flex>
  )
}

export const NameField: React.FC = () => {
  const FIELD_NAME = 'name'

  const { formState } = useFormContext()
  const fieldError = formState.errors[FIELD_NAME]
  const isInvalid = fieldError != null

  const inputRef = React.useRef<HTMLInputElement>(null)
  React.useEffect(() => {
    if (inputRef.current && inputRef.current.getAttribute('hidden') == null) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 100)
    }
  }, [])

  return (
    <FormControl isRequired isInvalid={isInvalid}>
      <Controller
        name={FIELD_NAME}
        render={({ field }) => {
          return (
            <Editable
              fontSize="2xl"
              display="flex"
              alignItems="center"
              placeholder={t`Tracker name`}
              startWithEditView={!field.value}
              {...field}
            >
              <EditablePreview
                color={!field.value ? 'gray.400' : 'inherit'}
                whiteSpace="nowrap"
                overflow="hidden"
                maxW="500px"
              />
              <EditableInput ref={inputRef} pl={2} size={40} />
              <EditableControls ml={4} />
            </Editable>
          )
        }}
      />
      <ErrorMessage
        name={FIELD_NAME}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  )
}
