import { useUsers } from '@capturi/stores'
import { Tooltip } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

type Props = {
  userUids: string[]
  children: ReactNode
}

const CaseAvatarTooltip: React.FC<Props> = ({ userUids, children }) => {
  const { getUserByUid } = useUsers()
  const renderUsernames = (userUid: string): string => {
    const { name } = getUserByUid(userUid)
    return name
  }

  return (
    <Tooltip
      label={userUids.map((uid) => (
        <React.Fragment key={uid}>
          {renderUsernames(uid)}
          <br />
        </React.Fragment>
      ))}
      placement="top"
      hasArrow
    >
      {children}
    </Tooltip>
  )
}

export default CaseAvatarTooltip
