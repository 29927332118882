import { PhoneFilterValues } from '@capturi/api-filters'

import { adjustPeriodForDSTOverlap } from './dstAdjustments'

import { toSavedFilterValues } from '../mappers'
import { Period, PeriodDefinition, parseFixedDateTimePeriod } from '../period'

// biome-ignore lint/suspicious/noExplicitAny: legacy
export type SearchParamsObject = { [key: string]: any }

// Filter away null, undefined and empty arrays from object
// biome-ignore lint/suspicious/noExplicitAny: legacy
function sanitizeObject(obj: any): SearchParamsObject {
  return Object.entries(obj).reduce<SearchParamsObject>(
    (memo, [key, value]) => {
      if (value == null) {
        return memo
      }
      if (typeof value === 'string' && value.length === 0) {
        return memo
      }
      if (Array.isArray(value) && value.length === 0) {
        return memo
      }
      memo[key] = value
      return memo
    },
    {},
  )
}

function periodDefinitionToSearchParams(periodDefinition: PeriodDefinition): {
  fromInclusive: string
  toInclusive: string
} {
  // Add period definition
  const parsedPeriod = parseFixedDateTimePeriod(periodDefinition?.name)

  const [fromDate, toDate] =
    parsedPeriod?.name.split('::').map((str) => str) ?? []

  const now = new Date()

  const selectedFromDate = new Date(fromDate)
  const selectedToDate = new Date(toDate)

  const period = !(isValidDate(fromDate) || isValidDate(toDate))
    ? periodDefinition?.create(now)
    : new Period(selectedFromDate, selectedToDate)

  return createPeriodParams(period)
}

export function createPeriodParams(period: Period): {
  fromInclusive: string
  toInclusive: string
} {
  const adjustedPeriod = adjustPeriodForDSTOverlap(period)
  return {
    fromInclusive: adjustedPeriod.fromInclusive.toISOString().replace(/Z$/, ''),
    toInclusive: adjustedPeriod.toInclusive.toISOString().replace(/Z$/, ''),
  }
}

function isValidDate(dateString: string): boolean {
  return !Number.isNaN(Date.parse(dateString))
}

export function toFilterSearchParams(
  // biome-ignore lint/style/useDefaultParameterLast: <explanation>
  state: Partial<PhoneFilterValues> | undefined = {},
  periodDefinition: PeriodDefinition,
): SearchParamsObject {
  // Transform internal state to request model
  const model = toSavedFilterValues(state)
  // Clean model
  const sanitizedModel = sanitizeObject(model)

  return {
    ...sanitizedModel,
    ...periodDefinitionToSearchParams(periodDefinition),
  }
}
