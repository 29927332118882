import { t } from '@lingui/macro'
import React from 'react'
import { FilterValueLabel } from '../../components/FilterValueLabel'

export const QualityAssuranceValueLabel: React.FC<{ value: boolean }> = ({
  value,
}) => {
  const label = value === null ? '' : value ? t`True` : t`False`
  return <FilterValueLabel name={t`Reviewed`} value={`${label}`} />
}
