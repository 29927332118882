import { useTeams } from '@capturi/core'
import { CellProps, ColumnWithLooseAccessor } from 'react-table'

import { Cell } from '../components/Cell'
import { ColumnDefinition, DataType } from '../types'

export default function teamColumn<T extends DataType>(
  c: ColumnDefinition<T>,
  // biome-ignore lint/suspicious/noExplicitAny: legacy
): ColumnWithLooseAccessor<any> {
  return {
    id: c.id ?? 'teamUid',
    accessor: c.getValue,
    width: c.width ?? 200,
    Cell: function TeamCell({ row }: CellProps<T, string>) {
      const { getTeamByUid } = useTeams()
      const teamName = getTeamByUid(row.values.teamUid)?.name
      return <Cell>{teamName ?? row.values.teamUid}</Cell>
    },
  }
}
