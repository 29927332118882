import { RequestOptions } from '@capturi/request'

import { baseQuery, controller } from '../constants'

export const conversationInsightsAPI = {
  getSpeakerDistributionStatsForConversation: (
    conversationUid: string,
  ): RequestOptions => ({
    url: `${controller}/speaker-distribution/conversation/${conversationUid}`,
    query: baseQuery,
    method: 'get',
  }),
}
