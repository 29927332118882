import {
  Segment,
  createPeriodParams,
  segmentConfigurations,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import request, { ResponseError } from '@capturi/request'
import { UseQueryOptions, useQueries } from '@tanstack/react-query'
import { StringifiableRecord } from 'query-string'
import { useMemo } from 'react'

export function useTextSegments<T>({
  url,
  query,
  refetchInterval = false,
}: {
  url: string
  query?: StringifiableRecord
  refetchInterval?: number | false
}): { refetch: () => void; segments: Segment<T>[] } {
  const { textSegmentStates, getIndexForState } = useSegmentStatesContext()
  const { periodDef } = useFilterPeriodContext()

  const responses = useQueries({
    queries: textSegmentStates.map<UseQueryOptions<T, ResponseError>>(
      (textSegmentState) => ({
        queryKey: [url, textSegmentState.values, periodDef, query],
        queryFn: (): Promise<T> =>
          request.post(url, {
            query: {
              'api-version': '3.3',
              ...query,
            },
            json: {
              ...textSegmentState.values,
              ...createPeriodParams(periodDef.create(new Date())),
            },
          }),
        refetchInterval: refetchInterval,
      }),
    ),
  })

  return useMemo(() => {
    const refetch = (): void => {
      responses.forEach((x) => x.refetch())
    }

    const segments: Segment<T>[] = responses.map((x, index) => {
      const segmentIndex = getIndexForState(textSegmentStates[index])
      return {
        color: segmentConfigurations[segmentIndex].color,
        label: segmentConfigurations[segmentIndex].label,
        data: x.data ? x.data : null,
      }
    })

    return {
      refetch,
      segments,
    }
  }, [responses, getIndexForState, textSegmentStates])
}
