import analytics from '@capturi/analytics'
import { Tracker } from '@capturi/api-conversations'
import { BaseTracker, isTrackerPrivate } from '@capturi/api-trackers'
import ShapeMark from '@capturi/sharing/src/components/ShapeMarks/ShapeMark'
import { Box, HStack, Icon, Text, useTheme } from '@chakra-ui/react'
import { getColor } from '@chakra-ui/theme-tools'
import { t } from '@lingui/macro'
import React from 'react'
import { MdVisibilityOff } from 'react-icons/md'

import TrackerMetadata from '../TrackerMetadata'

const TrackerItem: React.FC<{
  trackerHit: Tracker
  tracker: BaseTracker | undefined
  hitMap: Map<string, TrackerMetadata>
  onSelectTracker: (tracker: Tracker) => void
  selectedTrackerUid: string | null
}> = ({ trackerHit, tracker, hitMap, onSelectTracker, selectedTrackerUid }) => {
  const handleTrackerClick = React.useCallback(() => {
    analytics.event('analytics_Conversation_selectTracker')
    onSelectTracker(trackerHit)
  }, [onSelectTracker, trackerHit])
  const theme = useTheme()
  const handleGetColorValue = React.useCallback(
    (color: string) => {
      return getColor(theme, color)
    },
    [theme],
  )
  return (
    <HStack
      key={trackerHit.uid}
      align="baseline"
      spacing={2}
      textTransform="capitalize"
      cursor="pointer"
      fontWeight="normal"
      onClick={handleTrackerClick}
      color={trackerHit.uid === selectedTrackerUid ? 'primary.500' : ''}
    >
      <Box transform="translateY(4px)">
        <ShapeMark
          color={hitMap.get(trackerHit.uid)?.color}
          shape={hitMap.get(trackerHit.uid)?.shape}
          outline={false}
          getColorValue={handleGetColorValue}
        />
      </Box>
      {tracker && isTrackerPrivate(tracker) && (
        <Box transform="translateY(2px)">
          <Icon as={MdVisibilityOff} />
        </Box>
      )}

      <Text wordBreak="break-word">{`${
        tracker?.name ?? t`Tracker not found`
      } (${hitMap.get(trackerHit.uid)?.count ?? 0})`}</Text>
    </HStack>
  )
}

export default TrackerItem
