import {
  Box,
  Button,
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLabelProps,
  TagLeftIcon,
  TagProps,
  TagRightIcon,
  Wrap,
  chakra,
  useControllableState,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useRef, useState } from 'react'

import { useCopyToClipboard } from 'react-use'

export type ChipsItemRenderer = (
  value: string,
  words: string[],
  onRemove: () => void,
  index: number,
  values: string[],
) => React.ReactElement

export type ChipsInputProps = {
  value: string[]
  hasDuplicates?: boolean
  onChange?: (values: string[]) => void
  onValuesAdded?: (newValues: string[]) => void
  onClearValue?: () => void
  onValueRemoved?: (removedValue: string, index: number) => void
  removeLatestDuplicates?: () => string[]
  onClipboardCopy?: (clipboardValue: string, values: string[]) => void
  allowUpperCase?: boolean
  inputPlaceholderText?: string
  maxSentenceLength?: number | null
  renderItem?: ChipsItemRenderer
  autofocus?: boolean
}

function extractWords(sentence: string): string[] {
  return sentence.replace(/\s\s+/g, ' ').split(' ').filter(Boolean)
}

function splitSentences(
  maxSentenceLength: number | null,
  sentence: string,
): string[] {
  if (maxSentenceLength === null) {
    return [sentence]
  }

  const words = extractWords(sentence)
  const results: string[] = []
  while (words.length) {
    results.push(words.splice(0, maxSentenceLength).join(' '))
  }
  return results
}

export const ChipsInput: React.FC<ChipsInputProps> = ({
  value: valueProp,
  onChange,
  onValuesAdded,
  onValueRemoved,
  removeLatestDuplicates,
  hasDuplicates,
  onClearValue,
  onClipboardCopy,
  renderItem,
  allowUpperCase = false,
  inputPlaceholderText,
  maxSentenceLength = 4,
  autofocus = false,
}) => {
  const [values, setValues] = useControllableState({
    value: valueProp,
    defaultValue: [],
    onChange,
  })

  const [text, setText] = useState<string>('')
  const [, copy] = useCopyToClipboard()
  const inputRef = useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (autofocus && inputRef.current !== null) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    }
  }, [autofocus])

  function handleAddValues(newValues: string | string[]): void {
    const newValuesArray = (
      Array.isArray(newValues) ? newValues : [newValues]
    ).flatMap((v) => splitSentences(maxSentenceLength, v))
    onValuesAdded?.(newValuesArray)
    setValues((values) => values.concat(newValuesArray))
  }

  function handleRemoveValue(index: number): void {
    onValueRemoved?.(values[index], index)
    setValues((values) => {
      const newValues = [...values]
      newValues.splice(index, 1)
      return newValues
    })
  }

  function handleClearValue(): void {
    onClearValue?.()
    setValues([])
  }

  function handleLatestDuplicates(): void {
    const newStateWithoutLastetDuplicates = removeLatestDuplicates?.()
    if (newStateWithoutLastetDuplicates)
      setValues(newStateWithoutLastetDuplicates)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const text = allowUpperCase
      ? event.target.value
      : event.target.value.toLowerCase()
    setText(text)

    const words = text.trimStart().split(' ')
    if (maxSentenceLength && words.length > maxSentenceLength) {
      handleAddValues(text)
      setText('')
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>): void => {
    event.preventDefault()
    const text = event.clipboardData.getData('Text')
    handleAddValues(text.split(','))
  }

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    const { key } = event
    if ((key === 'Enter' || key === 'Tab' || key === ',') && text) {
      event.preventDefault()
      handleAddValues(text)
      setText('')
    }
  }
  const handleBlur = (): void => {
    if (text) {
      handleAddValues(text)
      setText('')
    }
  }

  return (
    <Flex
      onClick={() => inputRef.current?.focus()}
      minH="120px"
      justify="space-between"
      flexDirection="column"
      padding={2}
      border="solid 1px"
      borderColor="border.light"
      borderRadius="md"
      tabIndex={0}
      _focusWithin={{
        borderColor: 'outlineColor',
        boxShadow: 'outline',
      }}
    >
      <Wrap spacing={2}>
        {values.map((x, index) =>
          typeof renderItem === 'function' ? (
            <React.Fragment key={index}>
              {renderItem(
                x,
                extractWords(x),
                () => handleRemoveValue(index),
                index,
                values,
              )}
            </React.Fragment>
          ) : (
            <ChipItem key={index}>
              <ChipLabel>{x}</ChipLabel>
              <ChipRemoveButton onClick={() => handleRemoveValue(index)} />
            </ChipItem>
          ),
        )}
        <chakra.input
          m={1}
          py={0}
          px={1}
          minW="100px"
          h={5}
          outline={0}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          placeholder={inputPlaceholderText}
          ref={inputRef}
          onPaste={handlePaste}
          onBlur={handleBlur}
          value={text}
          fontSize="sm"
        />
      </Wrap>
      {values.length > 0 && (
        <Box alignSelf="flex-end">
          {hasDuplicates && (
            <Button
              pos="relative"
              zIndex="100"
              size="xs"
              variant="ghost"
              fontWeight="normal"
              aria-label={t`Remove duplicates`}
              onClick={handleLatestDuplicates}
            >
              <Trans>Remove duplicates</Trans>
            </Button>
          )}
          <Button
            pos="relative"
            size="xs"
            variant="ghost"
            fontWeight="normal"
            aria-label={t`Clear all`}
            onClick={handleClearValue}
          >
            <Trans>Clear all</Trans>
          </Button>
          <Button
            pos="relative"
            size="xs"
            variant="ghost"
            fontWeight="normal"
            aria-label={t`Copy all`}
            onClick={() => {
              const clipboardValue = values.join(', ')
              copy(clipboardValue)
              onClipboardCopy?.(clipboardValue, values)
            }}
          >
            <Trans>Copy all</Trans>
          </Button>
        </Box>
      )}
    </Flex>
  )
}

export const ChipItem = React.forwardRef<HTMLSpanElement, TagProps>(
  function ChipItemWithRef(props, ref) {
    return (
      <Tag
        ref={ref}
        variant="subtle"
        fontWeight="normal"
        rounded="full"
        alignSelf="center"
        h={5}
        {...props}
      />
    )
  },
)

export const ChipLabel: React.FC<TagLabelProps> = (props) => {
  return <TagLabel lineHeight="inherit" {...props} />
}

export const ChipRemoveButton = TagCloseButton
export const ChipLeftIcon = TagLeftIcon
export const ChipRightIcon = TagRightIcon
