/**
 * Mostly a copy of `useAudio` from `react-use` but the possibility to turn off
 * `timeupdate` and `progress` events in order to be able to use this hook in a provider
 * without constantly triggering updates down through the component tree.
 *
 * Original:
 * https://github.com/streamich/react-use/blob/master/src/useAudio.ts
 */

/* eslint-disable */
import * as React from 'react'

import { useEffect, useRef } from 'react'

import { useSetState } from 'react-use'

const parseTimeRanges = (ranges: TimeRanges) => {
  const result: { start: number; end: number }[] = []

  for (let i = 0; i < ranges.length; i++) {
    result.push({
      start: ranges.start(i),
      end: ranges.end(i),
    })
  }

  return result
}

export interface HTMLMediaProps
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  extends React.AudioHTMLAttributes<any>,
    // biome-ignore lint/suspicious/noExplicitAny: legacy
    React.VideoHTMLAttributes<any> {
  src: string
}

export interface HTMLMediaState {
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  buffered: any[]
  duration: number
  paused: boolean
  muted: boolean
  time: number
  volume: number
}

export interface HTMLMediaControls {
  play: () => Promise<void> | void
  pause: () => void
  mute: () => void
  unmute: () => void
  volume: (volume: number) => void
  seek: (time: number) => void
}

type Options = {
  disableTimeUpdates?: boolean
  disableProgressUpdates?: boolean
}

const defaultOptions: Required<Options> = {
  disableTimeUpdates: false,
  disableProgressUpdates: false,
}

const useAudio = (
  elOrProps: HTMLMediaProps | React.ReactElement<HTMLMediaProps>,
  optionsArg: Options = {},
): [
  React.ReactElement<HTMLMediaProps>,
  HTMLMediaState,
  HTMLMediaControls,
  { current: HTMLAudioElement | null },
] => {
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  let element: React.ReactElement<any> | undefined
  let props: HTMLMediaProps

  const options = {
    ...defaultOptions,
    ...optionsArg,
  }

  if (React.isValidElement(elOrProps)) {
    element = elOrProps
    props = element.props
  } else {
    props = elOrProps as HTMLMediaProps
  }

  const [state, setState] = useSetState<HTMLMediaState>({
    buffered: [],
    time: 0,
    duration: 0,
    paused: true,
    muted: false,
    volume: 1,
  })
  const ref = useRef<HTMLAudioElement | null>(null)

  const wrapEvent = (
    // biome-ignore lint/suspicious/noExplicitAny: legacy
    userEvent?: (event: React.SyntheticEvent<any, Event>) => void,
    // biome-ignore lint/suspicious/noExplicitAny: legacy
    proxyEvent?: (event: React.SyntheticEvent<any, Event>) => void,
  ) => {
    // biome-ignore lint/suspicious/noExplicitAny: legacy
    return (event: React.SyntheticEvent<any, Event>) => {
      try {
        proxyEvent?.(event)
      } finally {
        userEvent?.(event)
      }
    }
  }

  const onPlay = () => setState({ paused: false })
  const onPause = () => setState({ paused: true })
  const onVolumeChange = () => {
    const el = ref.current
    if (!el) {
      return
    }
    setState({
      muted: el.muted,
      volume: el.volume,
    })
  }
  const onDurationChange = () => {
    const el = ref.current
    if (!el) {
      return
    }
    const { duration, buffered } = el
    setState({
      duration: Math.floor(duration * 10000) / 10000,
      buffered: parseTimeRanges(buffered),
    })
  }
  const onTimeUpdate = () => {
    const el = ref.current
    if (!el) {
      return
    }
    setState({ time: el.currentTime })
  }
  const onProgress = () => {
    const el = ref.current
    if (!el) {
      return
    }
    setState({ buffered: parseTimeRanges(el.buffered) })
  }

  if (element) {
    element = React.cloneElement(element, {
      controls: false,
      ...props,
      ref,
      onPlay: wrapEvent(props.onPlay, onPlay),
      onPause: wrapEvent(props.onPause, onPause),
      onVolumeChange: wrapEvent(props.onVolumeChange, onVolumeChange),
      onDurationChange: wrapEvent(props.onDurationChange, onDurationChange),
      ...(!options.disableProgressUpdates
        ? {
            onProgress: wrapEvent(props.onProgress, onProgress),
          }
        : {}),
      ...(!options.disableTimeUpdates
        ? {
            onTimeUpdate: wrapEvent(props.onTimeUpdate, onTimeUpdate),
          }
        : {}),
    })
  } else {
    element = React.createElement('audio', {
      controls: false,
      ...props,
      ref,
      onPlay: wrapEvent(props.onPlay, onPlay),
      onPause: wrapEvent(props.onPause, onPause),
      onVolumeChange: wrapEvent(props.onVolumeChange, onVolumeChange),
      onDurationChange: wrapEvent(props.onDurationChange, onDurationChange),
      ...(!options.disableProgressUpdates
        ? {
            onProgress: wrapEvent(props.onProgress, onProgress),
          }
        : {}),
      ...(!options.disableTimeUpdates
        ? {
            onTimeUpdate: wrapEvent(props.onTimeUpdate, onTimeUpdate),
          }
        : {}),
      // biome-ignore lint/suspicious/noExplicitAny: legacy
    } as any) // TODO: fix this typing.
  }

  // Some browsers return `Promise` on `.play()` and may throw errors
  // if one tries to execute another `.play()` or `.pause()` while that
  // promise is resolving. So we prevent that with this lock.
  // See: https://bugs.chromium.org/p/chromium/issues/detail?id=593273
  let lockPlay = false

  const controls = {
    play: () => {
      const el = ref.current
      if (!el) {
        return undefined
      }

      if (!lockPlay) {
        const promise = el.play()
        const isPromise = typeof promise === 'object'

        if (isPromise) {
          lockPlay = true
          const resetLock = () => {
            lockPlay = false
          }
          promise.then(resetLock, resetLock)
        }

        return promise
      }
      return undefined
    },
    pause: () => {
      const el = ref.current
      if (el && !lockPlay) {
        return el.pause()
      }
    },
    seek: (time: number) => {
      const el = ref.current
      if (!el || state.duration === undefined) {
        return
      }
      const t = Math.min(state.duration, Math.max(0, time))
      el.currentTime = t
    },
    volume: (volume: number) => {
      const el = ref.current
      if (!el) {
        return
      }
      const v = Math.min(1, Math.max(0, volume))
      el.volume = v
      setState({ volume: v })
    },
    mute: () => {
      const el = ref.current
      if (!el) {
        return
      }
      el.muted = true
    },
    unmute: () => {
      const el = ref.current
      if (!el) {
        return
      }
      el.muted = false
    },
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy
  useEffect(() => {
    const el = ref.current

    if (!el) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(
          'useAudio() ref to <audio> element is empty at mount. ' +
            'It seem you have not rendered the audio element, which it ' +
            'returns as the first argument const [audio] = useAudio(...).',
        )
      }
      return
    }

    setState({
      volume: el.volume,
      muted: el.muted,
      paused: el.paused,
    })

    // Start media, if autoPlay requested.
    if (props.autoPlay && el.paused) {
      controls.play()
    }
  }, [props.src])

  return [element, state, controls, ref]
}

export default useAudio
