import { ErrorBoundary } from '@capturi/react-utils'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import ActivityPage from './activity'
import InteractionPage from './interaction'
import { getRoutes } from './routes'
import SentimentPage from './sentiment'
import Loader from './shared/components/Loader'
import { TimeResolutionProvider } from './shared/contexts/ResolutionContext'
import TrackersPage from './trackers'
import TrackerDetails from './trackers/TrackerDetails'

const pageRoutes = getRoutes(false)

const Analytics: React.FC = () => {
  return (
    <TimeResolutionProvider>
      <ErrorBoundary>
        <React.Suspense fallback={Loader}>
          <Routes>
            <Route path="/">
              <Route
                index
                element={
                  <Navigate to={getRoutes(true).trackers.main()} replace />
                }
              />
              <Route path={pageRoutes.activity} element={<ActivityPage />} />
              <Route
                path={pageRoutes.interaction}
                element={<InteractionPage />}
              />
              <Route path={pageRoutes.sentiment} element={<SentimentPage />} />
              <Route
                path={pageRoutes.trackers.main()}
                element={<TrackersPage />}
              >
                <Route path=":uid" element={<TrackerDetails />} />
              </Route>
            </Route>
          </Routes>
        </React.Suspense>
      </ErrorBoundary>
    </TimeResolutionProvider>
  )
}

export default Analytics
