import { t } from '@lingui/macro'
import React from 'react'
import { MdInbox } from 'react-icons/md'

import { CustomFieldFilterItem } from '../useTextFilter'
import { BaseMultiTextFilter } from './BaseMultiTextFilter'
import { BasePopoverSelectFilter } from './BasePopoverSelectFilter'
import {
  TextFilterComponentProps,
  TextFilterPopoverComponentProps,
} from './types'

const SPLIT_CHARACTERS = new Set(['Enter', 'Tab'])
const PASTE_SPLIT_REGEX = /\t|\n/

export const NonTrackedCustomFieldFilter: React.FC<
  TextFilterPopoverComponentProps<CustomFieldFilterItem> & { field: string }
> = (props) => {
  return (
    <BaseMultiTextFilter
      {...props}
      entityName={props.field ?? t`Value(s)`}
      placeholder={t`Use tab to split`}
      hint={t`You can also paste in a list of values`}
      onChangeValue={(item) => {
        props.onChangeValue({
          ...item,
          filterType: 'customFieldFilters',
          field: props.field,
        })
      }}
      splitCharactersSet={SPLIT_CHARACTERS}
      pasteSplitRegex={PASTE_SPLIT_REGEX}
    />
  )
}
export function createNonTrackedCustomFieldFilter(
  field: string,
): React.FC<TextFilterPopoverComponentProps<CustomFieldFilterItem>> {
  return (props) => {
    return <NonTrackedCustomFieldFilter {...props} field={field} />
  }
}

export const TrackedCustomFieldFilter: React.FC<
  TextFilterComponentProps<CustomFieldFilterItem> & {
    field: string
  }
> = (props) => {
  return (
    <BasePopoverSelectFilter
      {...props}
      filter={{ name: 'CustomFields', field: props.field }}
      singularEntityName={props.field ?? ''}
      pluralEntityName={props.field ?? ''}
      iconComponent={MdInbox}
      onChangeValue={(item) => {
        props.onChangeValue({
          ...item,
          filterType: 'customFieldFilters',
          field: props.field,
        })
      }}
    />
  )
}
