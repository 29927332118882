import { useToast as ChakraUseToast, UseToastOptions } from '@chakra-ui/react'
import noop from 'lodash/noop'
import React, { useMemo } from 'react'

import { ToastWithAction } from './ToastWithAction'

export type { UseToastOptions }

const defaultOptions: UseToastOptions = {
  duration: 5000,
  isClosable: true,
  variant: 'solid',
  position: 'bottom',
}

/**
 * Reexport of Chakra's useToast with some default options set.
 */
export const useToast: typeof ChakraUseToast = (options) => {
  const mergedOptions: UseToastOptions = useMemo(() => {
    if (options === undefined) return defaultOptions
    return {
      ...defaultOptions,
      ...options,
    }
  }, [options])
  return ChakraUseToast(mergedOptions)
}

interface UseToastWithActionOptions extends Omit<UseToastOptions, 'render'> {
  actionText?: string
  onActionClick?: React.MouseEventHandler<HTMLButtonElement>
}

interface UseToastWithActionReturnType
  extends Pick<
    ReturnType<typeof ChakraUseToast>,
    'close' | 'closeAll' | 'update' | 'isActive'
  > {
  (options?: UseToastWithActionOptions): string | number | undefined
}

/**
 * A toast with a CTA
 */
export function useActionToast(
  initialOptions?: UseToastWithActionOptions,
): UseToastWithActionReturnType {
  const chakraToastBaseOptions = useMemo(
    () => ({
      ...defaultOptions,
      ...initialOptions,
    }),
    [initialOptions],
  )
  const toast = ChakraUseToast(chakraToastBaseOptions)

  const showToastWithAction = React.useCallback(
    (opts: UseToastWithActionOptions = {}) => {
      const options = {
        ...defaultOptions,
        ...initialOptions,
        actionText: initialOptions?.actionText ?? '',
        onActionClick: initialOptions?.onActionClick ?? noop,
        ...opts,
      }
      return toast({
        ...chakraToastBaseOptions,
        render: function RenderActionToast(props): React.ReactElement {
          return (
            <ToastWithAction
              {...props}
              variant={options.variant}
              status={options.status}
              title={options.title}
              description={options.description}
              isClosable={options.isClosable}
              actionText={options.actionText}
              onActionClick={options.onActionClick}
            />
          )
        },
      })
    },
    [chakraToastBaseOptions, initialOptions, toast],
  )

  const result = Object.assign(showToastWithAction, {
    close: toast.close,
    closeAll: toast.closeAll,
    update: toast.update,
    isActive: toast.isActive,
  })
  return result
}
