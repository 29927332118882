import { useStore } from '@tanstack/react-store'
import React, { useCallback } from 'react'
import { modalStore } from './store'

export const ModalRenderer: React.FC = () => {
  const modals = useStore(modalStore)

  const handleClose = useCallback((uid: string) => {
    modalStore.setState((modals) => modals.filter((m) => m.uid !== uid))
  }, [])

  return (
    <>
      {modals.map(({ uid, Component, props }) => (
        <dialog open={true} key={uid}>
          <Component
            {...props}
            onClose={() => handleClose(uid)}
            isOpen
            onSubmit={(val: unknown) => {
              props.onSubmit?.(val)
              handleClose(uid)
            }}
          />
        </dialog>
      ))}
    </>
  )
}
