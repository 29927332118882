import { I18n, i18n as globalI18n } from '@lingui/core'
import { plural, t } from '@lingui/macro'

export const formatSecondsToShortMS = (
  seconds: number,
  i18n: I18n = globalI18n,
) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  const mText = `${minutes}${i18n._('m')}`
  const sText = `${remainingSeconds.toFixed(0)}${i18n._('s')}`

  if (seconds < 60) {
    return sText
  }
  if (seconds < 1) {
    return `0${i18n._('s')}`
  }
  return `${mText} ${sText}`
}

export const formatSecondsToShortHM = (
  seconds: number,
  i18n: I18n = globalI18n,
) => {
  const hours = Math.floor(seconds / (60 * 60))
  const remainingMinutes = Math.floor((seconds % (60 * 60)) / 60)

  const hText = `${hours}${i18n._('h')}`
  const mText = `${remainingMinutes}${i18n._('m')}`

  if (seconds < 60 * 60) {
    return mText
  }
  if (seconds < 60) {
    return `0${i18n._('m')}`
  }
  return `${hText} ${mText}`
}

export const formatSecondsToLongDHMS = (seconds: number): string => {
  const d = Math.floor(seconds / (24 * 3600))
  const h = Math.floor((seconds % (24 * 3600)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor(seconds % 60)

  const dText = `${d} ${plural(d, {
    one: 'day',
    other: 'days',
  })}`

  const hText = `${h} ${plural(h, {
    one: 'hour',
    other: 'hours',
  })}`

  const mText = `${m} ${plural(m, {
    one: 'minute',
    other: 'minutes',
  })}`

  const sText = `${s} ${plural(s, {
    one: 'second',
    other: 'seconds',
  })}`

  return [d > 0 && dText, h > 0 && hText, m > 0 && mText, s > 0 && sText]
    .filter(Boolean)
    .join(` ${t`and`} `)
}

export const formatSecondsToShortDHM = (seconds: number): string => {
  const d = Math.floor(seconds / (24 * 3600))
  const h = Math.floor((seconds % (24 * 3600)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)

  const dText = `${d}${t`d`}`
  const hText = `${h}${t`h`}`
  const mText = `${m}${t`m`}`

  if (d > 1) {
    return dText
  }
  if (h > 1) {
    return hText
  }
  return mText
}

export const formatSecondsToLongHMS = (
  seconds: number,
  i18n: I18n = globalI18n,
): string => {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor(seconds / 60) % 60
  const s = Math.floor(seconds) % 60

  const hText = h > 1 ? t(i18n)`${h} hours` : t(i18n)`${h} hour`
  const mText = m > 1 ? t(i18n)`${m} minutes` : t(i18n)`${m} minute`
  const sText = s > 1 ? t(i18n)`${s} seconds` : t(i18n)`${s} second`

  return [h > 0 && hText, m > 0 && mText, s > 0 && sText]
    .filter(Boolean)
    .join(` ${t`and`} `)
}

const padTokens = (x: number): string => x.toString().padStart(2, '0')
const zeroHoursRegex = /^00:/

export const formatSeconds = (time = 0): string => {
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor(time / 60) % 60
  const seconds = Math.floor(time) % 60

  return [hours, minutes, seconds]
    .map(padTokens)
    .join(':')
    .replace(zeroHoursRegex, '')
}
