import { useUsers } from '@capturi/stores'
import { AvatarGroup } from '@chakra-ui/react'
import UserAvatar from 'components/UserAvatar'
import React, { ReactNode } from 'react'

import CaseAvatarTooltip from './CaseAvatarTooltip'

type Props = {
  userUids: string[]
  size: string
}

const CasesAvatarGroup: React.FC<Props> = ({ userUids, size }) => {
  const { getUserByUid } = useUsers()

  const renderUserAvatar = (userUid: string): ReactNode => {
    const { name, profileImage } = getUserByUid(userUid)

    return (
      <UserAvatar
        key={userUid}
        name={name}
        profileImage={profileImage}
        size={size}
      />
    )
  }

  return (
    <CaseAvatarTooltip userUids={userUids}>
      <AvatarGroup size={size} max={4}>
        {userUids.map(renderUserAvatar)}
      </AvatarGroup>
    </CaseAvatarTooltip>
  )
}

export default CasesAvatarGroup
