import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import Link from 'components/Link'
import React from 'react'

const READ_MORE_LINK =
  'https://intercom.help/capturi-29d02d47ff57/da/articles/4465616-sadan-inviterer-du-brugere-til-at-bruge-capturi'

export const NoAccessModal: React.FC<BaseModalComponentProps> = ({
  isOpen = false,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader>
              <Trans>No access</Trans>
            </ModalHeader>
          </Flex>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Text>
                <Trans>
                  Unfortunately, you do not yet have access to Capturi.
                </Trans>
              </Text>
              <Text>
                <Trans>
                  Read{' '}
                  <Link to={READ_MORE_LINK} variant="primary">
                    here
                  </Link>{' '}
                  how to gain access.
                </Trans>
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
