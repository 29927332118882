import analytics from '@capturi/analytics'
import { Box, Flex, List, ListItem, Progress, Text } from '@chakra-ui/react'
import React, { useCallback } from 'react'

type KeyTopicsListItemProps = {
  id: string
  label: string
  percentage: number
  isSelected: boolean
  onClick: (id: string) => void
}

const KeyTopicsListItem: React.FC<KeyTopicsListItemProps> = React.memo(
  ({ id, label, percentage, isSelected, onClick }) => {
    return (
      <ListItem
        borderColor={isSelected ? 'curiousBlue.300' : 'transparent'}
        borderWidth="1px"
        borderRadius="lg"
        onClick={() => onClick(id)}
        px={4}
        pb={isSelected ? '4' : '3'}
        pt={isSelected ? '3' : '2'}
        mb={isSelected ? '3' : '1'}
        mt={isSelected ? '0' : '1'}
        overflow="hidden"
        position="relative"
        transition="all 250ms ease-out"
        boxShadow={
          isSelected
            ? '1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset, -1px -1px 2px 0px rgba(223, 223, 223, 0.50) inset, -5px 5px 10px 0px rgba(223, 223, 223, 0.20), 5px -5px 10px 0px rgba(223, 223, 223, 0.20), -5px -5px 10px 0px rgba(255, 255, 255, 0.90), 5px 5px 13px 0px rgba(223, 223, 223, 0.90)'
            : ''
        }
        background={
          isSelected ? 'linear-gradient(135deg, #FFF 0%, #EAEAEA 100%)' : ''
        }
        cursor="pointer"
        _hover={{
          bg: isSelected ? '' : 'gray.100',
        }}
      >
        <Box
          w="4px"
          position="absolute"
          left={0}
          top={0}
          bottom={0}
          backgroundColor={isSelected ? 'curiousBlue.500' : 'transparent'}
        />
        <Flex justifyContent="space-between">
          <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
            {label}
          </Text>
          <Text>{percentage.toFixed()}%</Text>
        </Flex>
        <Progress
          borderRadius="3px"
          value={percentage}
          size="xs"
          colorScheme="primary"
        />
      </ListItem>
    )
  },
)

type KeyTopicsListProps = {
  data: {
    id: string
    label: string
    percentage: number
  }[]
  selectedKeyTopicId: string | undefined
  setSelectedKeyTopicId: (id: string) => void
}

export const KeyTopicsList: React.FC<KeyTopicsListProps> = React.memo(
  ({ data, selectedKeyTopicId, setSelectedKeyTopicId }) => {
    const handleClick = useCallback(
      (id: string) => {
        setSelectedKeyTopicId(id)
        analytics.event('top_drivers_topic_selected')
      },
      [setSelectedKeyTopicId],
    )
    return (
      <List w={60}>
        {data.map(({ id, label, percentage }) => (
          <KeyTopicsListItem
            key={id}
            id={id}
            label={label}
            percentage={percentage}
            isSelected={selectedKeyTopicId === id}
            onClick={handleClick}
          />
        ))}
      </List>
    )
  },
)
