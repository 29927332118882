import {
  CaseListIncludingTrackerHitsResponseModel,
  useCasesIncludingTrackerHits,
} from '@capturi/api-cases'
import {
  TextFilterValues,
  mapTextFilterValuesToRequestModel,
} from '@capturi/api-filters'
import { BaseTracker } from '@capturi/api-trackers'
import {
  FilterPeriodProvider,
  PeriodDefinition,
  useFilterPeriodContext,
} from '@capturi/filters'
import { useUsers } from '@capturi/stores'
import { Highlight } from '@capturi/ui-components'
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  HStack,
  IconButton,
  List,
  ListItem,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import React, { useCallback, useMemo, FC, memo } from 'react'
import { MdLaunch } from 'react-icons/md'
import { useTimeout } from 'react-use'

import { useCaseDetailsDrawer } from '../../../../components/CaseDetailsDrawer'

const dateFormat: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

type DataProps = {
  tracker: BaseTracker
  phrase?: string
  filterState: TextFilterValues
  periodDef: PeriodDefinition
}

export type EmailTrackerExamplesDrawerProps = DataProps &
  Omit<DrawerProps, 'children'>

export const EmailTrackerExamplesDrawer: React.FC<
  EmailTrackerExamplesDrawerProps
> = ({ tracker, phrase, filterState, periodDef, isOpen, onClose }) => {
  const [isReady] = useTimeout(500)
  return (
    <FilterPeriodProvider defaultPeriod={periodDef}>
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottom="1px"
            borderBottomColor="gray.200"
            lineHeight={1.4}
            display="flex"
            alignItems="center"
            pr={10}
            pl={4}
          >
            <Box flex="1">
              <Text fontSize="sm" color="textMuted">
                <Trans>Conversations</Trans>
              </Text>
              <Text>{tracker.name}</Text>
            </Box>
          </DrawerHeader>
          <DrawerBody overflow="hidden" p={0}>
            <DrawerBodyContent
              filter={filterState}
              tracker={tracker}
              phrase={phrase}
              forceIsLoading={!isReady()}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </FilterPeriodProvider>
  )
}

const DrawerBodyContent: React.FC<{
  filter: TextFilterValues
  tracker: BaseTracker
  forceIsLoading?: boolean
  phrase?: string
}> = ({ filter, tracker, phrase, forceIsLoading = false }) => {
  const { period } = useFilterPeriodContext()

  const filterRequestModel = useMemo(
    () => mapTextFilterValuesToRequestModel(filter),
    [filter],
  )
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useCasesIncludingTrackerHits({
      trackerUid: tracker.uid,
      phrase,
      filter: filterRequestModel,
      period,
    })

  const handleLoadMore = useCallback((): void => {
    fetchNextPage()
  }, [fetchNextPage])

  return (
    <Box h="full" overflowX="hidden">
      {isLoading || forceIsLoading ? (
        <VStack gap={4} mx={4} mt={4}>
          <Skeleton borderRadius="base" height="55px" w="100%" />
          <Skeleton borderRadius="base" height="55px" w="100%" />
          <Skeleton borderRadius="base" height="55px" w="100%" />
          <Skeleton borderRadius="base" height="55px" w="100%" />
          <Skeleton borderRadius="base" height="55px" w="100%" />
        </VStack>
      ) : (
        <>
          <List px={4}>
            {(data?.pages ?? []).map((d) =>
              d?.map((c) =>
                c.messages.map((m) => (
                  <CaseTrackerHits
                    key={`${c.uid}-${m.uid}`}
                    caseUid={c.uid}
                    trackerUid={tracker.uid}
                    subject={c.subject}
                    message={m}
                    trackerHits={c.trackerHits}
                  />
                )),
              ),
            )}
          </List>

          <Flex my={4} justify="center" w="100%">
            <Button
              onClick={handleLoadMore}
              isDisabled={!hasNextPage || isFetchingNextPage}
              isLoading={isFetchingNextPage}
              borderRadius="md"
              size="sm"
            >
              {!hasNextPage ? (
                <Trans>No more tickets</Trans>
              ) : (
                <Trans>Load more</Trans>
              )}
            </Button>
          </Flex>
        </>
      )}
    </Box>
  )
}

type CaseTrackerHitsProps = {
  caseUid: string
  trackerUid: string
  subject: string
  message: CaseListIncludingTrackerHitsResponseModel['messages'][0]
  trackerHits: CaseListIncludingTrackerHitsResponseModel['trackerHits']
}

const CaseTrackerHits: FC<CaseTrackerHitsProps> = memo(
  ({ caseUid, trackerUid, subject, message, trackerHits }) => {
    const { getUserByUid } = useUsers()
    const openCaseDetailsDrawer = useCaseDetailsDrawer()

    const trackerHitsUids = useMemo(
      () => new Set(trackerHits.map((x) => x.trackerUid)),
      [trackerHits],
    )

    const flattenedMessageHits = useMemo(() => {
      const messageTrackerHits = message.trackerHits?.filter((x) => {
        return trackerHitsUids.has(x.trackerUid)
      })
      return messageTrackerHits?.flatMap((x) => {
        return Object.keys(x.phrases).flatMap((key) => {
          const phrases = x.phrases[key]
          return phrases.map((phrase) => {
            return {
              ...phrase,
              trackerUid: x.trackerUid,
              key,
            }
          })
        })
      })
    }, [message.trackerHits, trackerHitsUids])

    const hits = useMemo(() => {
      return (
        flattenedMessageHits?.reduce<TrackerMessageHit[]>((acc, x) => {
          if (x.field === 'Subject') {
            acc.push({
              key: `subject-${x.from}-${x.to}`,
              text: extractText(message.subject, x.from, x.to),
              from: x.from,
              to: x.to,
              trackerUid: x.trackerUid,
              messageUid: message.uid,
            })
          } else if (x.field === 'Text') {
            acc.push({
              key: `text-${x.from}-${x.to}`,
              text: extractText(message.text, x.from, x.to),
              from: x.from,
              to: x.to,
              trackerUid: x.trackerUid,
              messageUid: message.uid,
            })
          }
          return acc
        }, []) ?? []
      )
    }, [flattenedMessageHits, message.subject, message.text, message.uid])

    const handleOpenCaseDetailsDrawer = useCallback(() => {
      openCaseDetailsDrawer({
        caseUid,
        trackerUid,
      })
    }, [openCaseDetailsDrawer, caseUid, trackerUid])

    const { name, email } = message.userUid
      ? getUserByUid(message.userUid)
      : message.from

    return (
      <Card
        border="1px"
        borderRadius="base"
        borderColor="gray.300"
        shadow="md"
        cursor="pointer"
        overflow="hidden"
        my={4}
      >
        <CardHeader
          bg="gray.200"
          px={4}
          py={2}
          onClick={handleOpenCaseDetailsDrawer}
          data-group
          _hover={{ bg: 'gray.300' }}
        >
          <VStack align="start" gap={0}>
            <HStack justifyContent="space-between" w="100%">
              <Text
                fontSize="md"
                fontWeight="medium"
                _groupHover={{ textDecoration: 'underline' }}
              >
                {subject}
              </Text>
              <Text
                _groupHover={{ visibility: 'hidden' }}
                fontSize="sm"
                textAlign="right"
                textColor="gray.600"
              >
                {name}
              </Text>
              <IconButton
                aria-label={t`Go to conversation`}
                display="none"
                _groupHover={{ display: 'block' }}
                _hover={{ background: 'none' }}
                icon={<MdLaunch />}
                size="2xs"
                textColor="gray.600"
                variant="ghost"
              />
            </HStack>
            <HStack
              justifyContent="space-between"
              fontSize="sm"
              textColor="gray.600"
              w="100%"
            >
              <Text noOfLines={1} flexShrink={0}>
                {`${message.type} | ${i18n.date(message.created, dateFormat)}`}
              </Text>
              <Text noOfLines={1}>{email}</Text>
            </HStack>
          </VStack>
        </CardHeader>

        <CardBody p={0}>
          <List>
            {hits.map((hit) => (
              <TrackerHitListItem
                key={hit.key}
                hit={hit}
                caseUid={caseUid}
                openCaseDetailsDrawer={openCaseDetailsDrawer}
              />
            ))}
          </List>
        </CardBody>
      </Card>
    )
  },
)

type TrackerHitListItemProps = {
  hit: TrackerMessageHit
  caseUid: string
  openCaseDetailsDrawer: (options: {
    caseUid: string
    trackerUid: string
    messageUid: string
  }) => void
}

const TrackerHitListItem: FC<TrackerHitListItemProps> = memo(
  ({ hit, caseUid, openCaseDetailsDrawer }) => {
    const handleClick = useCallback(() => {
      openCaseDetailsDrawer({
        caseUid,
        trackerUid: hit.trackerUid,
        messageUid: hit.messageUid,
      })
    }, [openCaseDetailsDrawer, caseUid, hit.trackerUid, hit.messageUid])

    return (
      <ListItem
        borderBottom="1px"
        borderColor="gray.200"
        onClick={handleClick}
        p={4}
        _hover={{ background: 'gray.50' }}
        _last={{
          borderBottom: '0px',
        }}
      >
        {hit.text}
      </ListItem>
    )
  },
)

type TrackerMessageHit = {
  key: string
  trackerUid: string
  messageUid: string
  text: React.ReactNode
  from: number
  to: number
}

function extractText(
  textOrNull: string | null,
  from: number,
  to: number,
): React.ReactNode {
  const text = textOrNull || ''
  const textArr = [...text]
  const preText = textArr.slice(from - 30, from).join('')
  const highlightedText = textArr.slice(from, to + 1).join('')
  const postText = textArr.slice(to + 1, to + 50 + 1).join('')
  return (
    <Text noOfLines={1}>
      {preText}
      <Highlight>{highlightedText}</Highlight>
      {postText}
    </Text>
  )
}
