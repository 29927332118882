import request from '@capturi/request'

import { Tracker } from '../types'
import { noop } from '../utils'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: due to the error "Could not find a declaration file for module './loadIntercom'" when building declarations for '@capturi/filter'
import loadIntercom from './loadIntercom'

export type IntercomConfig = {
  token?: string
  config?: Intercom_.IntercomSettings
}

export default function init({
  token,
  config = {},
}: IntercomConfig): Tracker | undefined {
  if (!token) return
  // Load Intercom JS API
  loadIntercom(token)
  // Initialize Intercom
  window.Intercom('boot', { ...config, app_id: token })
  const tracker: Tracker = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    event: () => {},
    identify: async (_id, user) => {
      try {
        const { hmac } = await request.get<{ hmac: string }>(
          'authentication/me/hmac/intercom',
        )
        const { name, email, role, organizationId = '', ownerUid } = user ?? {}
        window.Intercom?.('update', {
          email,
          name,
          user_hash: hmac,
          user_id: ownerUid,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          company: {
            id: organizationId,
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          role,
        })
      } catch {
        // Doesn't matter
      }
    },
    modalview: noop,
    pageview: () => window.Intercom('update'),
    reset: () => window.Intercom('shutdown'),
    record: () => noop,
  }
  return tracker
}
