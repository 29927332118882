import { CustomNumberPropDropdownOption } from '@capturi/api-organization'
import { OnChangeValue, SelectOption } from '@capturi/ui-select'
import React from 'react'

import { FilterCriteriaSelect } from '../../../components/PhoneFilter/components/PhoneSegmentBuilder'

import {
  getLocalizedCustomPropLabel,
  isRangeMatch,
} from '@capturi/ui-components'
import { CustomNumberPropFilterProps } from './types'

type CustomNumberPropSelectSelectOption = SelectOption & {
  filter: CustomNumberPropDropdownOption
}

export function CustomNumberPropSelect({
  settings,
  locale,
  ...props
}: CustomNumberPropFilterProps<'Dropdown'>): React.ReactElement {
  const { value, options } = React.useMemo(() => {
    const options = settings.filters.map((x) => {
      return {
        label: getLocalizedCustomPropLabel(x.label, locale) ?? '?',
        value: [x.min, x.max].join('::'),
        filter: x,
      }
    })
    return {
      options,
      value: options.find((x) => isRangeMatch(props.value, x.filter)),
    }
  }, [settings.filters, props.value, locale])

  const handleChange = (
    option: OnChangeValue<CustomNumberPropSelectSelectOption, false>,
  ): void => {
    if (option?.filter) {
      const { min, max } = option.filter
      props.setValue?.({ min, max })
      props.onClose?.()
    }
  }

  return (
    <FilterCriteriaSelect
      options={options}
      value={value}
      onChange={handleChange}
    />
  )
}
