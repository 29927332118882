import { Box } from '@chakra-ui/react'
import React from 'react'

import { Color, DefaultColor } from './Color'
import { DefaultShape, Shape } from './Shape'
import Circle from './Shapes/Circle'
import Diamond from './Shapes/Diamond'
import Hexagon from './Shapes/Hexagon'
import Pentagon from './Shapes/Pentagon'
import Triangle from './Shapes/Triangle'

type ShapeMarkProps = {
  color?: Color
  shape?: Shape
  outline: boolean
  isDimmed?: boolean
  getColorValue: (color: Color) => string
}

const ShapeMark: React.FC<ShapeMarkProps> = (props) => {
  const {
    color = DefaultColor,
    shape = DefaultShape,
    outline,
    isDimmed,
    getColorValue,
  } = props

  return (
    <Box opacity={isDimmed ? 0.3 : 1}>
      <ShapeElement
        outline={outline}
        colorValue={getColorValue(color)}
        shape={shape}
      />
    </Box>
  )
}

const ShapeElement: React.FC<{
  outline: boolean
  colorValue: string
  shape: Shape
}> = ({ outline, colorValue, shape }) => {
  if (shape == null) {
    return <Diamond color={colorValue} outline={outline} />
  }

  switch (shape) {
    case 'Circle':
      return <Circle color={colorValue} outline={outline} />
    case 'Triangle':
      return <Triangle color={colorValue} outline={outline} />
    case 'Diamond':
      return <Diamond color={colorValue} outline={outline} />
    case 'Hexagon':
      return <Hexagon color={colorValue} outline={outline} />
    case 'Pentagon':
      return <Pentagon color={colorValue} outline={outline} />
  }
}

export default React.memo(ShapeMark)
