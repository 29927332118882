import {
  GroupBase,
  PopoutSelect,
  ReactSelectProps,
  SelectOption,
  SelectOptionBase,
  createFilter,
} from '@capturi/ui-select'
import { Plural, t } from '@lingui/macro'
import React from 'react'

const defaultFilter = createFilter({})

export type FilterCriteriaSelectProps<
  Option extends SelectOptionBase = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = ReactSelectProps<Option, IsMulti, Group> & {
  onClose?: () => void
  showFooter?: boolean
}

export function FilterCriteriaSelect<
  Option extends SelectOptionBase = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  placeholder,
  onChange,
  id,
  isMulti,
  value,
  options: optionsProp,
  onClose,
  components,
  showFooter = isMulti,
  ...props
}: FilterCriteriaSelectProps<Option, IsMulti, Group>): React.ReactElement {
  let options = optionsProp
  if (isMulti) {
    const enhanceSelectedOption = (option: Option): Option => {
      return {
        ...option,
        isSelectedGroupOption: true,
      }
    }
    const selectedGroup: GroupBase<Option> = {
      label: t`Selected`,
      options:
        value == null
          ? []
          : Array.isArray(value)
            ? value.map(enhanceSelectedOption)
            : [enhanceSelectedOption(value as Option)],
    }
    const allOptionsGroup: GroupBase<Option> = {
      label: t`Options`,
      options: optionsProp as Option[],
    }
    options = [selectedGroup, allOptionsGroup] as Group[]
  }

  const filterOption = (
    option: {
      label: string
      value: string
      data: SelectOption
    },
    rawInput: string,
  ): boolean => {
    // Do not filter options in the the selected options group
    if (option.data.isSelectedGroupOption === true) return true
    // Otherwise just use deafult filtering
    return defaultFilter(option, rawInput)
  }

  return (
    <PopoutSelect
      placeholder={placeholder ?? t`Search...`}
      id={id}
      onChange={onChange}
      onSubmit={onClose}
      resetText={t`Reset`}
      selectAllText={t`Select all`}
      value={value}
      options={options}
      isMulti={isMulti}
      formatSelectedCount={(count: string | number) => (
        <Plural value={count} one={'1 selected'} other={'# selected'} />
      )}
      closeMenuOnSelect={false}
      noOptionsMessage={() => t`No options`}
      components={components}
      formatGroupLabel={formatGroupLabel}
      filterOption={filterOption}
      showFooter={showFooter}
      {...props}
    />
  )
}

const formatGroupLabel = (
  data: GroupBase<SelectOptionBase>,
): React.ReactElement => (
  <div>
    <span>
      {data.label} ({data.options.length})
    </span>
  </div>
)
