import { SpeakerTrackerWordHitsResponseModel } from '@capturi/api-insights'
import {
  Channel,
  PhoneSegmentState,
  Segment,
  SegmentState,
  TextSegmentState,
} from '@capturi/filters'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

export type ItemDatum = {
  value: number
  valuePct: number
  stateIndex: number
}

export type ItemData = {
  item: string
  segments: Segment<ItemDatum>[]
}

export type TextTrackerPhraseHitsResponseModel = {
  cases: number
  phrases: {
    casesWithHit: number
    casesWithHitPercent: number
    phrase: string
  }[]
}

export type WordPhraseTargetHitsModel = {
  channel: Channel
  targets: number
  items: {
    targetsWithHit: number
    targetsWithHitPercent: number
    item: string
  }[]
}

function convertTextTrackerPhraseHitToWordPhraseTargetHit(
  textTrackerPhraseHit: TextTrackerPhraseHitsResponseModel | null,
): WordPhraseTargetHitsModel | null {
  return textTrackerPhraseHit
    ? {
        channel: 'email',
        targets: textTrackerPhraseHit.cases,
        items: textTrackerPhraseHit.phrases.map((phrase) => ({
          targetsWithHit: phrase.casesWithHit,
          targetsWithHitPercent: phrase.casesWithHitPercent,
          item: phrase.phrase,
        })),
      }
    : null
}

function convertSpeakerTrackerWordHitToWordPhraseTargetHit(
  speakerTrackerWordHit: SpeakerTrackerWordHitsResponseModel | null,
): WordPhraseTargetHitsModel | null {
  return speakerTrackerWordHit
    ? {
        channel: 'phone',
        targets: speakerTrackerWordHit.conversations,
        items: speakerTrackerWordHit.words.map((word) => ({
          targetsWithHit: word.conversationsWithHit,
          targetsWithHitPercent: word.conversationsWithHitPercent,
          item: word.trackerWord,
        })),
      }
    : null
}

type ChannelSegment =
  | {
      channel: 'phone'
      index: number
      segment: Segment<SpeakerTrackerWordHitsResponseModel>
    }
  | {
      channel: 'email'
      index: number
      segment: Segment<TextTrackerPhraseHitsResponseModel>
    }

export function convertSegmentsToWordPhraseSegments(
  phoneSegmentStates: PhoneSegmentState[],
  phoneSegments: Segment<SpeakerTrackerWordHitsResponseModel>[],
  textSegmentStates: TextSegmentState[],
  textSegments: Segment<TextTrackerPhraseHitsResponseModel>[],
  getIndexForState: (state: SegmentState) => number,
): Segment<WordPhraseTargetHitsModel>[] {
  const segments = [
    ...phoneSegments.map<ChannelSegment>((x, index) => ({
      channel: 'phone',
      index: getIndexForState(phoneSegmentStates[index]),
      segment: x,
    })),
    ...textSegments.map<ChannelSegment>((x, index) => ({
      channel: 'email',
      index: getIndexForState(textSegmentStates[index]),
      segment: x,
    })),
  ]
  // ensure segments are sorted by state.index, important for ItemDatum
  const sortedSegments = segments.sort((a, b) => a.index - b.index)
  return sortedSegments.map<Segment<WordPhraseTargetHitsModel>>((x) => {
    return {
      ...x.segment,
      channel: x.channel,
      data:
        x.channel === 'phone'
          ? convertSpeakerTrackerWordHitToWordPhraseTargetHit(x.segment.data)
          : convertTextTrackerPhraseHitToWordPhraseTargetHit(x.segment.data),
    }
  })
}

export function useWordPhraseHits(
  segments: Segment<WordPhraseTargetHitsModel>[],
): ItemData[] {
  return useMemo(() => {
    const allItems = new Set<{ channel: Channel; item: string }>(
      segments.flatMap((s) => {
        if (s.data != null) {
          const data = s.data // extra const fixes null linter warning
          return data.items.map((h) => ({
            item: h.item,
            channel: data.channel,
          }))
        }
        return []
      }),
    )

    const hitMaps = segments.map((s, index) => {
      return (s.data?.items ?? []).reduce<{ [key: string]: ItemDatum }>(
        (acc, x) => {
          acc[x.item] = {
            value: x.targetsWithHit,
            valuePct: x.targetsWithHitPercent / 100,
            stateIndex: index,
          }
          return acc
        },
        {},
      )
    })

    const segmentData = [...allItems].reduce<{ [key: string]: ItemData }>(
      (acc, item) => {
        acc[item.item] = {
          segments: segments.reduce<Segment<ItemDatum>[]>((acc, s, i) => {
            const map = hitMaps[i][item.item]
            if (map != null) {
              acc.push({
                color: s.color,
                label: s.label,
                data: map,
              })
            }
            return acc
          }, []),
          item: item.item,
        }
        return acc
      },
      {},
    )

    const data = orderBy(
      Object.values(segmentData),
      [(x) => x.segments[0]?.data?.value, (x) => x.item],
      ['desc', 'asc'],
    )

    return data
  }, [segments])
}
