import constate from 'constate'
import qs from 'query-string'
import React from 'react'
import useSyncSearchParam from 'router/useSyncSearchParam'

const QUERY_KEY = 'trackerUid'

function useTrackerState(): [string | undefined, (uid: string) => void] {
  const state = React.useState(() => {
    // read query param initially and set state
    const parsedQuery = qs.parse(window.location.search)
    let value = parsedQuery[QUERY_KEY]
    if (Array.isArray(value)) {
      value = value[0]
    }

    if (value == null) {
      return undefined
    }
    return value
  })

  useSyncSearchParam(QUERY_KEY, state[0])
  return state
}

const [TrackerProvider, useTrackerContext] = constate(useTrackerState)

export { TrackerProvider, useTrackerContext }
