import { SYSTEM_PRECISION_PHRASE_LENGTH } from 'pages/TrackersPage/constants'
import { useCallback } from 'react'

import { useDuplicateSpeechPhrases } from '../../usePhraseFields'
import { useDictionaryWordContext } from './useDictionaryWordStatus'
import { useOrphanedQuotes } from './useOphranedQuotes'

export function usePhraseValidation(): (phrase: string) => {
  hasUnknownWords: boolean
  isDuplicatePhrase: boolean
  isShortPhrase: boolean
  hasOrphanedQuote: boolean
  shortPhraseLengthThreshold: number
} {
  const { isDuplicate } = useDuplicateSpeechPhrases()
  const { hasUnknownWords } = useDictionaryWordContext()
  const { hasOrphanedQuote } = useOrphanedQuotes()
  return useCallback(
    (phrase: string) => ({
      hasUnknownWords: hasUnknownWords(phrase),
      isDuplicatePhrase: isDuplicate(phrase),
      hasOrphanedQuote: hasOrphanedQuote(phrase),
      isShortPhrase:
        0 < phrase.length && phrase.length < SYSTEM_PRECISION_PHRASE_LENGTH,
      shortPhraseLengthThreshold: SYSTEM_PRECISION_PHRASE_LENGTH,
    }),
    [hasUnknownWords, isDuplicate, hasOrphanedQuote],
  )
}
