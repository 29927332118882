import analytics from '@capturi/analytics'
import { useAiQuestions } from '@capturi/api-ask-ai'
import { Button } from '@capturi/ui-components'
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
  useTheme,
  useToast,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans, t } from '@lingui/macro'
import React, { useRef, useState } from 'react'
import {
  MdBookmark,
  MdBookmarkAdd,
  MdCheckCircle,
  MdClose,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdSearch,
  MdSend,
} from 'react-icons/md'
import { useToggle } from 'react-use'

import { useQuestionsLocalStorage } from '../../../hooks/usePersonalQuestions'

type AskAiCardProps = {
  initialAskAiQuery: string
  setAskAiQuery: (query: string) => void
}

export const AskAiCardSkeleton: React.FC = () => {
  return (
    <Card
      borderColor="gray.200"
      borderRadius="4px"
      borderWidth={1}
      boxShadow="none"
    >
      <CardHeader
        fontSize="sm"
        fontWeight="medium"
        px={2}
        pt={2}
        pb={1}
        textColor="gray.600"
      >
        <Skeleton height="20px" width="40px" />
      </CardHeader>
      <CardBody px={2} pt={0} pb={2}>
        <HStack>
          <Skeleton height="32px" width="100%" borderRadius="4px" />
          <Skeleton height="32px" width="40px" borderRadius="4px" />
        </HStack>
        <HStack mt={2.5} justify="space-between">
          <Skeleton height="20px" width="140px" />
          <Skeleton height="20px" width="50px" />
        </HStack>
      </CardBody>
    </Card>
  )
}

export const AskAiCard: React.FC<AskAiCardProps> = ({
  initialAskAiQuery,
  setAskAiQuery,
}) => {
  const toast = useToast()
  const theme = useTheme()

  const [inputValue, setInputValue] = useState<string>(initialAskAiQuery)
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    questions: personalQuestions,
    addQuestion,
    deleteQuestion,
  } = useQuestionsLocalStorage()

  const [isPersonalQuestionsOpen, togglePersonalQuestions] = useToggle(false)

  const { data: aiQuestions } = useAiQuestions()

  return (
    <Card
      borderColor="gray.200"
      borderRadius="4px"
      borderWidth={1}
      boxShadow="none"
    >
      <CardHeader
        fontSize="sm"
        fontWeight="medium"
        px={2}
        pt={2}
        pb={1}
        textColor="gray.600"
      >
        Ask AI
      </CardHeader>
      <CardBody px={2} py={0}>
        <HStack>
          <InputGroup
            size="sm"
            borderColor="transparent"
            boxShadow="md"
            css={css`&:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -1px;
            border-radius: 4px;
            opacity: 0.5;
            background: ${theme.colors.aiGradient};
          }`}
          >
            <Input
              ref={inputRef}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              backgroundColor="white"
              borderRadius="4px"
              placeholder={t`Did the agent resolve the enquiry?`}
              size="sm"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const trimmedValue = e.currentTarget.value.trim()
                  setAskAiQuery(trimmedValue)
                  analytics.event('askAi_submitButton_key_topics', {
                    question: trimmedValue,
                  })
                }
              }}
            />
            {inputValue.trim().length > 0 && (
              <InputRightElement>
                <Icon
                  as={MdBookmarkAdd}
                  cursor="pointer"
                  color="gray.800"
                  boxSize="18px"
                  onClick={() => {
                    const trimmedValue = inputRef.current?.value.trim()
                    if (!trimmedValue) {
                      return
                    }

                    addQuestion(trimmedValue)
                    analytics.event('save_personal_question', {
                      question: trimmedValue,
                    })

                    if (!isPersonalQuestionsOpen) {
                      togglePersonalQuestions()
                    }

                    toast({
                      title: t`Personal question saved`,
                      status: 'success',
                      icon: <Icon as={MdCheckCircle} boxSize={6} />,
                    })
                  }}
                />
              </InputRightElement>
            )}
          </InputGroup>
          <IconButton
            aria-label={t`Ask personal question`}
            colorScheme="primary"
            icon={<MdSend />}
            onClick={() => {
              const trimmedValue = inputRef.current?.value.trim() ?? ''
              setAskAiQuery(trimmedValue)
              analytics.event('askAi_submitButton_key_topics', {
                question: trimmedValue,
              })
            }}
          />
        </HStack>
        <HStack mt={2} justify="space-between">
          <Button onClick={togglePersonalQuestions} variant="unstyled">
            <HStack align="center" gap="1px">
              <Trans>Predefined questions</Trans>
              <Icon
                as={
                  isPersonalQuestionsOpen
                    ? MdKeyboardArrowUp
                    : MdKeyboardArrowDown
                }
                ml={1}
              />
            </HStack>
          </Button>
          <Button
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.value = ''
              }
              setInputValue('')
              setAskAiQuery('')
            }}
            isDisabled={inputValue.length === 0}
            primary
            variant="link"
          >
            <Trans>Reset</Trans>
          </Button>
        </HStack>
        <Collapse in={isPersonalQuestionsOpen}>
          <Wrap pb={2}>
            {personalQuestions.map((question, index) => (
              <WrapItem key={`${index}-${question}`}>
                <Tooltip label={question} hasArrow placement="top">
                  <Button
                    borderRadius="4px"
                    leftIcon={<MdBookmark />}
                    size="xs"
                    maxW="300px"
                    overflow="hidden"
                    onClick={() => {
                      const trimmedQuestion = question.trim()
                      if (inputRef.current) {
                        inputRef.current.value = trimmedQuestion
                      }
                      setInputValue(trimmedQuestion)
                      setAskAiQuery(trimmedQuestion)
                      analytics.event('submit_personal_question_key_topics', {
                        question: trimmedQuestion,
                      })
                    }}
                  >
                    <Text
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {question}
                    </Text>
                    <Icon
                      ml={1}
                      as={MdClose}
                      boxSize={4}
                      onClick={(e) => {
                        e.stopPropagation()
                        deleteQuestion(question)
                        analytics.event('remove_personal_question', {
                          question: question,
                        })
                      }}
                    />
                  </Button>
                </Tooltip>
              </WrapItem>
            ))}
            {aiQuestions?.map((question) => (
              <WrapItem key={`${question.uid}`}>
                <Button
                  key={question.uid}
                  onClick={() => {
                    if (inputRef.current) {
                      inputRef.current.value = question.question
                    }
                    setInputValue(question.question)
                    setAskAiQuery(question.question)
                    analytics.event(
                      'askAi_predefinedQuestion_submit_key_topics',
                      {
                        predefinedQuestion: question.question,
                      },
                    )
                  }}
                  size="xs"
                  leftIcon={<MdSearch />}
                >
                  {question.name}
                </Button>
              </WrapItem>
            ))}
          </Wrap>
        </Collapse>
      </CardBody>
    </Card>
  )
}
