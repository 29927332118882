import analytics from '@capturi/analytics'

export enum Event {
  ChangeSpeakerView = 'analytics_Trackers_ChangeSpeakerView',
  // Tracker employee bar chart
  UserTooltip_ViewTrackerExamples = 'analytics_Trackers_UserTooltip_ViewTrackerExamples',
  UserTooltip_ViewTrackerConversations = 'analytics_Trackers_UserTooltip_ViewTrackerConversations',
  UserTooltip_ViewNotTrackerConversations = 'analytics_Trackers_UserTooltip_ViewNotTrackerConversations',
  WordTooltip_ViewTrackerExamples = 'analytics_Trackers_WordTooltip_ViewTrackerExamples',
  // Tracker examples drawer
  TrackerExamples_PlayAudio = 'analytics_Trackers_TrackerExamples_PlayAudio',
  TrackerExamples_GoToConversation = 'analytics_Trackers_TrackerExamples_GoToConversation',
  // Time series chart
  TimeSeries_ChangeResolution = 'analytics_Trackers_TimeSeries_ChangeResolution',
  // Tracker words tab
  TrackerWord_ViewPhoneTrackerExamples = 'analytics_Trackers_TrackerWord_ViewTrackerExamples',
  TrackerWord_PlotTimeSeries = 'analytics_Trackers_TrackerWord_PlotTimeSeries',
  TrackerWord_ViewEmailTrackerExamples = 'analytics_Trackers_TrackerWord_ViewEmailTrackerExamples',
  // Tracker users tab
  TrackerUser_ViewPhoneTrackerExamples = 'analytics_Trackers_TrackerUser_ViewTrackerExamples',
  TrackerUser_ViewEmailTrackerExamples = 'analytics_Trackers_TrackerUser_ViewEmailTrackerExamples',
  TrackerUser_PlotTimeSeries = 'analytics_Trackers_TrackerUser_PlotTimeSeries',
  TrackerUser_ViewPhoneTrackerConversations = 'analytics_Trackers_TrackerUser_ViewTrackerConversations',
  TrackerUser_ViewNotTrackerConversations = 'analytics_Trackers_TrackerUser_ViewNotTrackerConversations',

  // Tracker teams tab
  TrackerTeam_ViewPhoneTrackerExamples = 'analytics_Trackers_TrackerTeam_ViewTrackerExamples',
  TrackerTeam_ViewEmailTrackerExamples = 'analytics_Trackers_TrackerTeam_ViewEmailTrackerExamples',
  TrackerTeam_PlotTimeSeries = 'analytics_Trackers_TrackerTeam_PlotTimeSeries',
  TrackerTeam_ViewTrackerConversations = 'analytics_Trackers_TrackerTeam_ViewTrackerConversations',
  TrackerTeam_ViewNotTrackerConversations = 'analytics_Trackers_TrackerTeam_ViewNotTrackerConversations',

  FilterTrackersByTag = 'analytics_Trackers_FilterTrackersByTag',
  CreateNewTrackerBtn_Clicked = 'analytics_Trackers_CreateNewTrackerBtn_Clicked',
  // Tracker folders
  TrackerFolder_Deleted = 'trackerFolder_deleted',
  TrackerFolder_EditOpened = 'editTrackerFolder_open',
  // Trackers
  TrackerAnalysis_MovedToFolder = 'tracker-analysis_TrackerFolderSelected',
}

export function logEvent(
  event: Event,
  data: Record<string, string | number> = {},
): void {
  analytics.event(event, {
    ...data,
  })
}
