export function getShouldPrintEvents(): boolean {
  try {
    return localStorage.getItem('printEvents') === 'true'
  } catch (_e) {
    return false
  }
}

export function noop(): void {
  // do nothing
}
