import { useShortcut } from '@shopify/react-shortcuts'
import { useAudioContext } from './AudioProvider'

const useAudioShortcut = (): void => {
  const { isPlaying, pause, playbackContext, play } = useAudioContext()

  useShortcut([' '], () => {
    // NOTE: useShortcut has options object as second parameter with an "ignoreInput" param
    // but the options does not take effect.

    if (isPlaying) pause()
    else if (playbackContext) play(undefined, false, playbackContext)
    // ignoreInput?: boolean - a boolean that lets you opt out of swallowing the key event and let it propagate
  })
}

export default useAudioShortcut
