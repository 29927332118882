import {
  addMonths,
  endOfDay,
  endOfQuarter,
  endOfYear,
  startOfDay,
  startOfMinute,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subDays,
  subHours,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns'

import { PeriodDefinition } from './period'
import {
  endOfLastMonth,
  endOfLastQuarter,
  endOfLastWeek,
  endOfYesterday,
  startOfLastMonth,
  startOfLastQuarter,
  startOfLastWeek,
  startOfLastXDays,
  startOfLastXHours,
  startOfWeek,
  startOfYesterday,
  yesterday,
} from './utils'

export enum NamedPeriod {
  Today = 'Today',
  Yesterday = 'Yesterday',
  CurrentWeek = 'CurrentWeek',
  CurrentMonth = 'CurrentMonth',
  CurrentQuarter = 'CurrentQuarter',
  CurrentYear = 'CurrentYear',
  LastQuarter = 'LastQuarter',
  Last7Days = 'Last7Days',
  Last14Days = 'Last14Days',
  Last30Days = 'Last30Days',
  Last90Days = 'Last90Days',
  Last3Months = 'Last3Months',
  Last12Months = 'Last12Months',
  LastHour = 'LastHour',
  Last2Hours = 'Last2Hours',
  Last4Hours = 'Last4Hours',
  Last24Hours = 'Last24Hours',
  LastWeek = 'LastWeek',
  LastMonth = 'LastMonth',
}

export const PeriodDefinitions: Record<NamedPeriod, PeriodDefinition> = {
  [NamedPeriod.Today]: new PeriodDefinition(
    NamedPeriod.Today,
    startOfDay,
    endOfDay,
  ),
  [NamedPeriod.Yesterday]: new PeriodDefinition(
    NamedPeriod.Yesterday,
    startOfYesterday,
    endOfYesterday,
  ),
  [NamedPeriod.Last7Days]: new PeriodDefinition(
    NamedPeriod.Last7Days,
    startOfLastXDays(7),
    endOfDay,
  ),
  [NamedPeriod.Last14Days]: new PeriodDefinition(
    NamedPeriod.Last14Days,
    startOfLastXDays(14),
    endOfDay,
  ),
  [NamedPeriod.Last30Days]: new PeriodDefinition(
    NamedPeriod.Last30Days,
    startOfLastXDays(30),
    endOfDay,
  ),
  [NamedPeriod.Last90Days]: new PeriodDefinition(
    NamedPeriod.Last90Days,
    startOfLastXDays(90),
    endOfDay,
  ),
  [NamedPeriod.Last3Months]: new PeriodDefinition(
    NamedPeriod.Last3Months,
    (referenceDate) => startOfDay(addMonths(yesterday(referenceDate), -3)),
    endOfDay,
  ),
  [NamedPeriod.Last12Months]: new PeriodDefinition(
    NamedPeriod.Last12Months,
    (referenceDate) => startOfDay(addMonths(yesterday(referenceDate), -12)),
    endOfDay,
  ),
  [NamedPeriod.CurrentWeek]: new PeriodDefinition(
    NamedPeriod.CurrentWeek,
    startOfWeek,
    endOfDay,
  ),
  [NamedPeriod.CurrentMonth]: new PeriodDefinition(
    NamedPeriod.CurrentMonth,
    startOfMonth,
    endOfDay,
  ),
  [NamedPeriod.CurrentQuarter]: new PeriodDefinition(
    NamedPeriod.CurrentQuarter,
    startOfQuarter,
    endOfQuarter,
  ),
  [NamedPeriod.CurrentYear]: new PeriodDefinition(
    NamedPeriod.CurrentYear,
    startOfYear,
    endOfDay,
  ),
  [NamedPeriod.LastQuarter]: new PeriodDefinition(
    NamedPeriod.LastQuarter,
    startOfLastQuarter,
    endOfLastQuarter,
  ),
  [NamedPeriod.LastHour]: new PeriodDefinition(
    NamedPeriod.LastHour,
    startOfLastXHours(1),
    startOfMinute,
  ),
  [NamedPeriod.Last2Hours]: new PeriodDefinition(
    NamedPeriod.Last2Hours,
    startOfLastXHours(2),
    startOfMinute,
  ),
  [NamedPeriod.Last4Hours]: new PeriodDefinition(
    NamedPeriod.Last4Hours,
    startOfLastXHours(4),
    startOfMinute,
  ),
  [NamedPeriod.Last24Hours]: new PeriodDefinition(
    NamedPeriod.Last24Hours,
    startOfLastXHours(24),
    startOfMinute,
  ),
  [NamedPeriod.LastWeek]: new PeriodDefinition(
    NamedPeriod.LastWeek,
    startOfLastWeek,
    endOfLastWeek,
  ),
  [NamedPeriod.LastMonth]: new PeriodDefinition(
    NamedPeriod.LastMonth,
    startOfLastMonth,
    endOfLastMonth,
  ),
}

export const PreviousPeriodDefinitions: Record<NamedPeriod, PeriodDefinition> =
  {
    [NamedPeriod.Today]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.Today}`,
      startOfYesterday,
      endOfYesterday,
    ),
    [NamedPeriod.Yesterday]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.Yesterday}`,
      (referenceDate) => startOfYesterday(yesterday(referenceDate)),
      (referenceDate) => endOfYesterday(yesterday(referenceDate)),
    ),
    [NamedPeriod.Last7Days]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.Last7Days}`,
      startOfLastXDays(14),
      (referenceDate) => endOfDay(subWeeks(referenceDate, 1)),
    ),
    [NamedPeriod.Last14Days]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.Last14Days}`,
      startOfLastXDays(28),
      (referenceDate) => endOfDay(subWeeks(referenceDate, 2)),
    ),
    [NamedPeriod.Last30Days]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.Last30Days}`,
      startOfLastXDays(60),
      (referenceDate) => endOfDay(subDays(referenceDate, 30)),
    ),
    [NamedPeriod.Last90Days]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.Last90Days}`,
      startOfLastXDays(180),
      (referenceDate) => endOfDay(subDays(referenceDate, 90)),
    ),
    [NamedPeriod.Last3Months]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.Last3Months}`,
      (referenceDate) => startOfDay(subMonths(yesterday(referenceDate), 6)),
      (referenceDate) => endOfDay(subMonths(referenceDate, 3)),
    ),
    [NamedPeriod.Last12Months]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.Last12Months}`,
      (referenceDate) => startOfDay(subMonths(yesterday(referenceDate), 24)),
      (referenceDate) => endOfDay(subMonths(referenceDate, 12)),
    ),
    [NamedPeriod.CurrentWeek]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.CurrentWeek}`,
      startOfLastWeek,
      endOfLastWeek,
    ),
    [NamedPeriod.CurrentMonth]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.CurrentMonth}`,
      startOfLastMonth,
      endOfLastMonth,
    ),
    [NamedPeriod.CurrentQuarter]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.CurrentQuarter}`,
      startOfLastQuarter,
      endOfLastQuarter,
    ),
    [NamedPeriod.CurrentYear]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.CurrentYear}`,
      (referenceDate) => startOfYear(subYears(referenceDate, 1)),
      (referenceDate) => endOfYear(subYears(referenceDate, 1)),
    ),
    [NamedPeriod.LastQuarter]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.LastQuarter}`,
      (referenceDate) => startOfQuarter(subQuarters(referenceDate, 2)),
      (referenceDate) => endOfQuarter(subQuarters(referenceDate, 2)),
    ),
    [NamedPeriod.LastHour]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.LastHour}`,
      (referenceDate) => startOfLastXHours(1)(subHours(referenceDate, 1)),
      (referenceDate) => startOfMinute(subHours(referenceDate, 1)),
    ),
    [NamedPeriod.Last2Hours]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.Last2Hours}`,
      (referenceDate) => startOfLastXHours(2)(subHours(referenceDate, 2)),
      (referenceDate) => startOfMinute(subHours(referenceDate, 2)),
    ),
    [NamedPeriod.Last4Hours]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.Last4Hours}`,
      (referenceDate) => startOfLastXHours(4 + 4)(referenceDate),
      (referenceDate) => startOfMinute(subHours(referenceDate, 4)),
    ),
    [NamedPeriod.Last24Hours]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.Last24Hours}`,
      (referenceDate) => startOfLastXHours(24)(subHours(referenceDate, 24)),
      (referenceDate) => startOfMinute(subHours(referenceDate, 24)),
    ),
    [NamedPeriod.LastWeek]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.LastWeek}`,
      (referenceDate) => startOfLastWeek(subWeeks(referenceDate, 1)),
      (referenceDate) => endOfLastWeek(subWeeks(referenceDate, 1)),
    ),
    [NamedPeriod.LastMonth]: new PeriodDefinition(
      `PreviousTo-${NamedPeriod.LastMonth}`,
      (referenceDate) => startOfLastMonth(subMonths(referenceDate, 1)),
      (referenceDate) => endOfLastMonth(subMonths(referenceDate, 1)),
    ),
  }
