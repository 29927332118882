import { Highlight, Join, Spinner } from '@capturi/ui-components'
import { Box } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import React, { ReactNode } from 'react'
export const FilterValueLabel: React.FC<{
  name: string
  value: unknown
  isLoading?: boolean
  crossedOut?: boolean
  inverted?: boolean
}> = ({ name, value, isLoading = false, inverted = false }) => {
  let filterValue: ReactNode
  if (isEmpty(value)) {
    filterValue = '?'
  } else if (Array.isArray(value)) {
    filterValue = <Join values={value}>{(value) => value}</Join>
  } else {
    filterValue = value ? String(value) : ''
  }

  return (
    <Box as="span">
      <Highlight>{name} </Highlight>
      {inverted ? (
        <Trans>
          is <Highlight>NOT</Highlight>
        </Trans>
      ) : (
        <Trans>is</Trans>
      )}{' '}
      {isLoading ? (
        <Spinner
          as="span"
          size="xs"
          verticalAlign="middle"
          color="blackAlpha.300"
          emptyColor="blackAlpha.100"
          ml={1}
        />
      ) : (
        filterValue
      )}
    </Box>
  )
}
