import { DirectionPosition, DirectionValue } from '@capturi/api-filters'
import { Highlight } from '@capturi/ui-components'
import { InvertibleMultiSelectFooter, OnChangeValue } from '@capturi/ui-select'
import {
  Box,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { Plural, Trans, t } from '@lingui/macro'
import React, { useCallback, useMemo } from 'react'
import { MdCompareArrows } from 'react-icons/md'

import {
  FilterCriteriaButton,
  FilterCriteriaSelect,
} from '../../PhoneFilter/components/PhoneSegmentBuilder'
import { DirectionFilterItem } from '../useTextFilter'

const localizeDirectionValue = (value: DirectionValue): string => {
  switch (value) {
    case DirectionValue.Inbound:
      return t`Inbound`
    case DirectionValue.Outbound:
      return t`Outbound`
    case DirectionValue.AutoReply:
      return t`Auto reply`
  }
}

type FilterOption = {
  label: string
  value: DirectionValue
}

type InitialDirectionFilterProps = {
  item: DirectionFilterItem
  onChangeValue: (item: DirectionFilterItem) => void
  onClose: () => void
  onRemove: (itemUid: string) => void
  isOpen: boolean
  onOpen: (itemUid: string) => void
}

export const InitialDirectionFilter: React.FC<InitialDirectionFilterProps> = ({
  isOpen,
  onClose,
  item,
  onChangeValue,
  onOpen,
  onRemove,
}) => {
  const options: FilterOption[] = useMemo(
    () =>
      Object.values(DirectionValue).map((value) => ({
        label: localizeDirectionValue(value),
        value: value,
      })),
    [],
  )

  const values = useMemo(
    () =>
      item.values
        ? item.values.map((value) => ({
            label: localizeDirectionValue(value),
            value: value,
          }))
        : undefined,
    [item.values],
  )

  const handleChange = useCallback(
    (option: OnChangeValue<FilterOption, true>): void => {
      onChangeValue({
        uid: item.uid,
        filterType: 'directionFilters',
        position: DirectionPosition.First,
        values: option.map((x) => x.value),
        inverted: item.inverted,
      })
    },
    [item.inverted, item.uid, onChangeValue],
  )

  return (
    <Popover placement="bottom-start" isOpen={isOpen} onClose={onClose} isLazy>
      {({ isOpen, onClose }) => (
        <>
          <Box display="inline-block">
            <PopoverTrigger>
              <FilterCriteriaButton
                size="sm"
                maxWidth={'20rem'}
                onReset={() => onRemove(item.uid)}
                onClick={() => onOpen(item.uid)}
                isActive={isOpen}
                hasValue={!!values?.length}
                leftIcon={
                  <Icon boxSize="20px !important" as={MdCompareArrows} />
                }
              >
                <Highlight mr="1">
                  <Trans>Initial direction</Trans>
                </Highlight>
                {item.inverted ? (
                  <Trans>
                    is <Highlight>NOT</Highlight>
                  </Trans>
                ) : (
                  <Trans>is</Trans>
                )}
                {` ${values?.map((v) => v.label).join(', ')}`}
              </FilterCriteriaButton>
            </PopoverTrigger>
          </Box>

          <PopoverContent w={400}>
            <FilterCriteriaSelect
              onClose={onClose}
              options={options}
              value={values}
              onChange={handleChange}
              autoFocus={true}
              isMulti={true}
              showFooter={false}
            />
            <InvertibleMultiSelectFooter
              entityName={t`Initial direction(s)`}
              isNot={item.inverted}
              onChangeIsNot={(isNot) => {
                onChangeValue({
                  ...item,
                  inverted: isNot,
                })
              }}
              onReset={() => handleChange([])}
              onSubmit={onClose}
              onSelectAllOptions={() => handleChange(options)}
              selectAllText={t`Select all`}
              selectedCount={values?.length || 0}
              formatSelectedCount={(count: string | number) => (
                <Plural value={count} one="1 selected" other="# selected" />
              )}
            />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
