import { t } from '@lingui/macro'
import React from 'react'
import { MdInbox } from 'react-icons/md'

import { MultiValueFilterItem } from '../useTextFilter'
import { BasePopoverSelectFilter } from './BasePopoverSelectFilter'
import { TextFilterComponentProps } from './types'

export const StatusFilter: React.FC<
  TextFilterComponentProps<MultiValueFilterItem> & {
    isOpen: boolean
    onOpen: (itemUid: string) => void
  }
> = (props) => {
  return (
    <BasePopoverSelectFilter
      {...props}
      filter={{ name: 'Status' }}
      singularEntityName={t`Status`}
      pluralEntityName={t`Status(es)`}
      iconComponent={MdInbox}
      onChangeValue={(item) => {
        props.onChangeValue({
          ...item,
          filterType: 'statusFilters',
        })
      }}
    />
  )
}
