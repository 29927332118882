import { Button } from '@capturi/ui-components'
import { Box, ButtonGroup, Flex, FlexProps, Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'

export type MultiSelectFooterProps = {
  onReset?: () => void
  onSubmit?: () => void
  onSelectAllOptions?: () => void
  resetText?: string
  submitText?: string
  isSubmitDisabled?: boolean
  selectAllText?: string
  selectedCount?: number
  formatSelectedCount?: (count: number) => React.ReactElement
} & Omit<FlexProps, 'children'>

export const MultiSelectFooter: React.FC<MultiSelectFooterProps> = ({
  onReset,
  onSubmit,
  onSelectAllOptions,
  resetText = t`Reset`,
  submitText = t`OK`,
  isSubmitDisabled = false,
  selectAllText,
  formatSelectedCount,
  selectedCount,
  ...props
}) => {
  return (
    <Flex
      justify="space-between"
      align="center"
      pl={2}
      pr={1}
      pb={1}
      pt={2}
      zIndex={1}
      boxShadow={'0 -4px 8px -6px rgba(0,0,0,0.2)'}
      {...props}
    >
      <Box>
        <Flex align="center">
          {selectAllText && (
            <Button
              onClick={onSelectAllOptions}
              secondary
              variant="ghost"
              mr="2"
            >
              {selectAllText}
            </Button>
          )}
          {selectedCount != null && formatSelectedCount && (
            <Text fontSize="sm">{formatSelectedCount?.(selectedCount)}</Text>
          )}
        </Flex>
      </Box>
      <ButtonGroup size="sm">
        {typeof onReset === 'function' && (
          <Button onClick={onReset} secondary variant="ghost">
            {resetText}
          </Button>
        )}
        {typeof onSubmit === 'function' && (
          <Button
            isDisabled={isSubmitDisabled}
            onClick={onSubmit}
            primary
            variant="ghost"
          >
            {submitText}
          </Button>
        )}
      </ButtonGroup>
    </Flex>
  )
}
