import analytics from '@capturi/analytics'
import { CurrentUser, UserPermissions } from '@capturi/core'
import {
  Box,
  Flex,
  Menu,
  MenuItem,
  Switch,
  Text,
  Tooltip,
  chakra,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { MdInfoOutline } from 'react-icons/md'

type Props = {
  user: CurrentUser
  permissions: UserPermissions
  inProgress: boolean
  togglePermission: (key: keyof UserPermissions, value: boolean) => void
}

export const UnrestrictedAccess: React.FC<Props> = ({
  user,
  permissions,
  inProgress,
  togglePermission,
}) => {
  return (
    <Menu>
      <Flex>
        {user.isAdmin && (
          <MenuItem
            as="div"
            px={0}
            display="flex"
            justifyContent="space-between"
            onClick={() => {
              togglePermission('editEverything', !permissions.editEverything)
              analytics.event(
                permissions.editEverything
                  ? 'enabled_edit_everything'
                  : 'disabled_edit_everything',
              )
            }}
          >
            <Text fontWeight="500">
              <Trans>Unrestricted access</Trans>
              <Box mx={1} as="span">
                ⭐
              </Box>
            </Text>
            <Tooltip
              label={
                <>
                  <Text>
                    <Trans>
                      Gain access and edit rights to everything no matter the
                      share settings. This means you get access to all users’
                      private trackers, dashboards etc.
                    </Trans>
                  </Text>
                  <Text mt={3}>
                    <Trans>
                      Tip: We suggest you only use this as a temporary setting
                      when you need clean up your organization or support other
                      users.
                    </Trans>
                  </Text>
                </>
              }
              placement="top"
              hasArrow
            >
              <Box as="span" mt={-3}>
                <MdInfoOutline />
              </Box>
            </Tooltip>
            <chakra.span ml={2}>
              <Switch
                colorScheme="segments.primary"
                p={1}
                isDisabled={inProgress}
                onChange={(e) =>
                  togglePermission('editEverything', e.currentTarget.checked)
                }
                isChecked={permissions.editEverything}
              />
            </chakra.span>
          </MenuItem>
        )}
      </Flex>
    </Menu>
  )
}
