import analytics from '@capturi/analytics'
import { useTheme } from '@capturi/ui-theme'
import { Box, Flex, HStack, IconButton, Text, useToast } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { t } from '@lingui/macro'
import React from 'react'
import { MdOutlineThumbDown, MdOutlineThumbUp } from 'react-icons/md'

type Props = {
  conversationUid?: string
  content: {
    header?: {
      title?: string | JSX.Element
      topics?: string
      subTitle?: string
    }
    body: string
  }
}

const AiContentCard: React.FC<Props> = ({ content, conversationUid }) => {
  const theme = useTheme()
  const toast = useToast()
  return (
    <Flex
      minH={16}
      css={css`&:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          margin: -1px;
          border-radius: ${content.header ? '4px' : 'inherit'};
          opacity: 0.5;
          background: ${theme.colors.aiGradient};
        }`}
    >
      <Box
        bg="white"
        pl={4}
        pr={1}
        pb={3}
        pt={2}
        fontSize="sm"
        w="100%"
        borderRadius={content.header ? '4px' : 'inherit'}
      >
        {content.header && (
          <>
            <HStack justifyContent="space-between" align="start">
              <Box>
                <Text color="gray.600" fontWeight="medium">
                  {content.header.title}
                </Text>
                {content.header.topics && (
                  <Text fontSize="md" mb={3}>
                    {content.header.topics}
                  </Text>
                )}
              </Box>
              <Box>
                <IconButton
                  aria-label="Thumbs up"
                  fontSize="8px"
                  icon={<MdOutlineThumbUp />}
                  size="xs"
                  variant="ghost"
                  color="gray.500"
                  onClick={() => {
                    analytics.event('Conv_Overview_Ai_ThumbsUp', {
                      summary: content.body,
                      conversationUid: conversationUid,
                    })
                    toast({
                      title: t`Thank you for the feedback`,
                      description: t`You are helping us to improve future AI features`,
                      status: 'success',
                      duration: 4000,
                      isClosable: true,
                    })
                  }}
                />
                <IconButton
                  aria-label="Thumbs down"
                  fontSize="8px"
                  icon={<MdOutlineThumbDown />}
                  size="xs"
                  variant="ghost"
                  color="gray.500"
                  onClick={() => {
                    analytics.event('Conv_Overview_Ai_ThumbsDown', {
                      summary: content.body,
                      conversationUid: conversationUid,
                    })
                    toast({
                      title: t`Thank you for the feedback`,
                      description: t`You are helping us to improve future AI features`,
                      status: 'success',
                      duration: 4000,
                      isClosable: true,
                    })
                  }}
                />
              </Box>
            </HStack>
            <Box>
              <Box>
                <Text color="gray.600" fontWeight="medium" as="span">
                  {content.header.subTitle}
                </Text>
                <Text fontSize="md" whiteSpace="pre-wrap">
                  {content.body}
                </Text>
              </Box>
            </Box>
          </>
        )}

        {!content.header && (
          <Text whiteSpace="pre-wrap" fontSize="md">
            {content.body}
          </Text>
        )}
      </Box>
    </Flex>
  )
}

export default AiContentCard
