import {
  Button,
  HeadingCaption,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Modal as _Modal,
} from '@capturi/ui-components'
import { Flex, Stack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { ReactElement, RefObject } from 'react'

type Props = {
  inProgress?: boolean
  isValid?: boolean
  onClose: () => void
  onSuccess?: () => void
  onSubmit: () => void
  submitText: ReactElement
  cancelText?: ReactElement
  header: ReactElement
  subheader?: ReactElement
  children: ReactElement
  initialFocusRef: RefObject<HTMLElement>
}

const Modal: React.FC<Props> = (props) => {
  const {
    inProgress,
    isValid = true,
    onClose,
    onSubmit,
    initialFocusRef,
    submitText,
    cancelText = <Trans>Cancel</Trans>,
    header,
    subheader,
    children,
  } = props

  return (
    <_Modal
      isOpen={true}
      onClose={onClose}
      initialFocusRef={initialFocusRef}
      closeOnEsc={!inProgress}
    >
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader>
              {header}
              <HeadingCaption color="textMuted" fontSize="md">
                {subheader}
              </HeadingCaption>
            </ModalHeader>
          </Flex>
          {!inProgress && <ModalCloseButton />}
          <ModalBody>{children}</ModalBody>

          <ModalFooter>
            <Stack isInline spacing={4}>
              <Button secondary isDisabled={inProgress} onClick={onClose}>
                {cancelText}
              </Button>
              <Button
                primary
                isDisabled={!isValid}
                isLoading={inProgress}
                mr={3}
                onClick={onSubmit}
              >
                {submitText}
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </_Modal>
  )
}

export default Modal
