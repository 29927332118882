import { Acl, PermissionPreset } from '@capturi/core'

export function getAcl<
  T extends {
    acl?: Acl | null
    permissionPreset?: PermissionPreset
  },
>(partialState: Partial<T>, state: T, cureentUserUid: string): Acl | null {
  if (!partialState.permissionPreset)
    return partialState.acl || state.acl || null
  if (partialState.permissionPreset !== 'Custom') return []
  return partialState.permissionPreset === 'Custom' && state.acl?.length
    ? state.acl
    : [
        { level: 'Edit', role: 'Administrator', type: 'role' },
        { level: 'Edit', role: 'TeamLead', type: 'role' },
        { level: 'Edit', role: 'User', type: 'role' },
        {
          level: 'Edit',
          userUid: cureentUserUid,
          type: 'user',
        },
      ]
}

export function getPermissionPreset<
  T extends { permissionPreset: PermissionPreset },
>(partialState: Partial<T>, state: T, currentUserUid: string): string | null {
  const preset = partialState.permissionPreset ?? state.permissionPreset
  return preset === 'Locked' || preset === 'Private' ? currentUserUid : null
}
