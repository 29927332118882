import { ConversationResponseModel, Tracker } from '@capturi/api-conversations'
import { Box, Divider, Stack } from '@chakra-ui/react'
import React from 'react'

import TrackerMetadata from '../TrackerMetadata'
import Folder from './Folder'
import TrackerItem from './TrackerItem'
import { useTableOfContents } from './useTableOfContents'

type Props = {
  trackerHits: NonNullable<ConversationResponseModel['trackerHits']>
  hitMap: Map<string, TrackerMetadata>
  onSelectTracker: (tracker: Tracker) => void
  selectedTrackerUid: string | null
}

const TableOfContents: React.FC<Props> = ({
  trackerHits,
  hitMap,
  onSelectTracker,
  selectedTrackerUid,
}) => {
  const { items, root } = useTableOfContents(trackerHits, hitMap)

  return (
    <Stack spacing={0}>
      <Box pb={4} pr={2}>
        {items.map((group) => (
          <Folder
            key={group.uid}
            item={group}
            hitMap={hitMap}
            onSelectTracker={onSelectTracker}
            selectedTrackerUid={selectedTrackerUid}
          />
        ))}
      </Box>
      {root.length > 0 && (
        <Box>
          {items.length > 0 && <Divider w="80px" mt={2} mb={2} />}
          <Stack spacing={4} pt={2}>
            {root.map((item) => {
              return (
                <TrackerItem
                  key={item.trackerHit.uid}
                  trackerHit={item.trackerHit}
                  tracker={item.tracker}
                  hitMap={hitMap}
                  onSelectTracker={onSelectTracker}
                  selectedTrackerUid={selectedTrackerUid}
                />
              )
            })}
          </Stack>
        </Box>
      )}
    </Stack>
  )
}

export default TableOfContents
