import React from 'react'

import { configurations } from '../../segmentConfigurations'
import {
  MultiSegmentContainer,
  MultiSegmentContainerProps,
} from './MultiSegmentContainer'

export type SingleSegmentContainerProps = Omit<
  MultiSegmentContainerProps,
  'segmentProfiles'
> & {
  color?: string
  label?: string
}

const profile = configurations[0]

export const SingleSegmentContainer: React.FC<SingleSegmentContainerProps> = ({
  color = profile.color,
  label = profile.label,
  allowReselectionWhenReadOnly = false,
  ...props
}) => {
  const profilesRef = React.useRef([{ color, label }])
  return (
    <MultiSegmentContainer
      segmentProfiles={profilesRef.current}
      allowReselectionWhenReadOnly={allowReselectionWhenReadOnly}
      {...props}
    />
  )
}
