import { useSegmentStatesContext } from '@capturi/filters'
import { DefaultFallbackComponent } from '@capturi/react-utils'
import {
  Card,
  CardContent,
  formatSecondsToLongDHMS,
  formatSecondsToShortHM,
  formatSecondsToShortMS,
} from '@capturi/ui-components'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Skeleton,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react'
import { Trans, plural } from '@lingui/macro'
import { FC, memo } from 'react'

import SessionFailedProcessing from '../../../components/KeyTopics/FailedStates/SessionFailedProcessing'
import SessionNotFound from '../../../components/KeyTopics/FailedStates/SessionNotFound'
import { useKeyTopics } from '../../../hooks/useKeyTopics'
import { Benchmark } from '../../../pages/KeyTopics/hooks/types'
import { AnimateChangeInHeight } from '../Shared/AnimateHeight'
import {
  RepeatCallsWidgetCard,
  RepeatCallsWidgetCardSkeleton,
} from './RepeatCallsWidgetCard'
import { RepeatCallsWidgetReadySelector, selector } from './selector'

type Props = {
  keyTopicsSessionUid: string | undefined
  startNewSession: () => void
}

const RepeatCallsWidgetSkeleton: FC = () => {
  return (
    <Box height="689px">
      <Skeleton height="21px" width="250px" mb="4" />
      <Skeleton mt="4" width="70%" />
      <VStack alignItems="stretch" spacing={4}>
        <RepeatCallsWidgetCardSkeleton />
      </VStack>
    </Box>
  )
}

const RepeatCallsReadyWidget: FC<{
  keyTopicsSessionUid: string | undefined
  data: RepeatCallsWidgetReadySelector
}> = ({ keyTopicsSessionUid, data }) => {
  const { phoneSegmentStates } = useSegmentStatesContext()
  const repeatCallFilterValue = phoneSegmentStates[0].values.repeatCalls[0]
    ? phoneSegmentStates[0].values.repeatCalls[0].value
    : undefined

  const firstItem = data.displayedKeyTopicClusters[0]
  return (
    <AnimateChangeInHeight overflowYVisble>
      <Flex
        flexDir="column"
        minH={data.displayedKeyTopicClusters.length > 0 ? '689px' : undefined}
      >
        <Heading
          fontSize="2xl"
          textShadow="textExtended"
          data-stonly="Repeat calls"
          width="fit-content"
        >
          <Trans>Repeat calls</Trans>
        </Heading>
        <Flex>
          {data.benchmark.repeatCalls && (
            <Text flex="1" fontSize="sm" color="gray.600">
              {data.repeatCallSettings ? (
                <Trans>
                  <chakra.span fontWeight="medium">
                    {data.benchmark.amount > 0 &&
                      (
                        data.benchmark.repeatCalls.amount /
                        data.benchmark.amount
                      ).toLocaleString(undefined, {
                        style: 'percent',
                        maximumFractionDigits: 1,
                      })}
                  </chakra.span>{' '}
                  of your calls are customers repeat calling within{' '}
                  {repeatCallFilterValue ? (
                    <>
                      {repeatCallFilterValue / 86400}{' '}
                      {plural(repeatCallFilterValue / 86400, {
                        one: 'day',
                        other: 'days',
                      })}
                    </>
                  ) : (
                    <>
                      {formatSecondsToLongDHMS(
                        data.repeatCallSettings.maxDistance,
                      )}
                    </>
                  )}
                  .
                </Trans>
              ) : (
                <Trans>
                  <chakra.span fontWeight="medium">
                    {data.benchmark.amount > 0 &&
                      (
                        data.benchmark.repeatCalls?.amount /
                        data.benchmark.amount
                      ).toLocaleString(undefined, {
                        style: 'percent',
                        maximumFractionDigits: 1,
                      })}
                  </chakra.span>{' '}
                  of your calls are customers repeat calling.
                </Trans>
              )}{' '}
              <Trans>
                You spent{' '}
                {(data.benchmark.repeatCalls.sumDuration / 3600).toFixed(0)}{' '}
                hours over the period handling these{' '}
                {data.benchmark.repeatCalls.amount} repeat calls.
              </Trans>
              {firstItem && (
                <>
                  {' '}
                  <Trans>
                    Highest repeat call share is within{' '}
                    <chakra.span fontWeight="medium">
                      "{firstItem.label}" at{' '}
                      {(firstItem.repeatCallsShare * 100).toFixed(0)}%
                    </chakra.span>
                    .
                  </Trans>
                </>
              )}
            </Text>
          )}
        </Flex>
        <VStack alignItems="stretch" flex="1 1 auto" mt={2} gap={2}>
          <RepeatCallsWidgetLiveCards benchmark={data.benchmark} />
          <RepeatCallsWidgetCard
            isRepeatCallFilterApplied={!!repeatCallFilterValue}
            keyTopicsSessionUid={keyTopicsSessionUid}
            data={data}
          />
        </VStack>
      </Flex>
    </AnimateChangeInHeight>
  )
}

export const RepeatCallsWidget: FC<Props> = ({
  keyTopicsSessionUid,
  startNewSession,
}) => {
  const { data, isLoading, error } = useKeyTopics({
    select: selector,
  })

  if (error) return <DefaultFallbackComponent error={error} />
  if (data?.status === 'failed')
    return <SessionFailedProcessing startNewSession={startNewSession} />
  if (data?.status === 'gone') {
    return <SessionNotFound startNewSession={startNewSession} />
  }
  if (isLoading || !data || data.status === 'loading') {
    return <RepeatCallsWidgetSkeleton />
  }

  return (
    <RepeatCallsReadyWidget
      keyTopicsSessionUid={keyTopicsSessionUid}
      data={data}
    />
  )
}

const RepeatCallsWidgetLiveCards: FC<{
  benchmark: Benchmark
}> = memo(({ benchmark }) => {
  if (!benchmark.repeatCalls) return null
  return (
    <Grid templateColumns="1fr 2fr" gap={4} textAlign="left">
      <Card
        flex="1"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
      >
        <CardContent py={4} px={6}>
          <Text fontSize="lg" fontWeight="medium">
            <Trans>Repeat calls</Trans>
          </Text>
          <HStack mt={3} alignItems="baseline">
            <Text fontSize="1.75rem" fontWeight="medium">
              {benchmark.amount > 0 &&
                (
                  benchmark.repeatCalls?.amount / benchmark.amount
                ).toLocaleString(undefined, {
                  style: 'percent',
                  maximumFractionDigits: 1,
                })}
            </Text>
            <Text color="gray.600">
              <Trans>
                ({benchmark.repeatCalls?.amount}/{benchmark.amount})
                conversations
              </Trans>
            </Text>
          </HStack>
        </CardContent>
      </Card>
      <Card
        flex="1"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
        p={0}
      >
        <CardContent p={0} h="100%">
          <Grid h="100%" templateColumns="1fr 1px 1fr">
            <GridItem py={4} px={6}>
              <Text fontSize="lg" fontWeight="medium">
                <Trans>Avg. duration per repeat call</Trans>
              </Text>
              <Text fontSize="1.75rem" fontWeight="medium" mt={3}>
                {formatSecondsToShortMS(benchmark.repeatCalls.avgDuration)}
              </Text>
            </GridItem>
            <GridItem h="100%" w="1px" bg="gray.200" />
            <GridItem py={3} px={4}>
              <Text fontSize="lg" fontWeight="medium">
                <Trans>Time spent on repeat calls</Trans>
              </Text>
              <Text fontSize="1.75rem" fontWeight="medium" mt={3}>
                {formatSecondsToShortHM(benchmark.repeatCalls.sumDuration)}
              </Text>
            </GridItem>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
})
