import analytics from '@capturi/analytics'
import { BaseTracker, useTracker } from '@capturi/api-trackers'
import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { ColorLabel } from '@capturi/charts'
import {
  PhoneSegmentState,
  TextSegmentState,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import { useScrollable } from '@capturi/react-utils'
import {
  Caption,
  ContentPlaceholder,
  Highlight,
  PlaySnippetsButton,
} from '@capturi/ui-components'
import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import TrackerAccordion from './components/TrackerAccordion'
import {
  useEmailTrackerExamplesDrawer,
  usePhoneTrackerExamplesDrawer,
} from './drawer'
import TimeDataContainer from './time-series-chart/TimeDataContainer'
import { useSegmentOverview } from './useSegmentsOverview'
import { formatHitRate, isProcessingInCurrentPeriod } from './utils'

const TrackerDetails: React.FC = () => {
  const { uid } = useParams()
  const ref = useRef(null)
  const pageHeight = useScrollable(ref, 32)

  const [shouldPollForUpdates, setShouldPollForUpdates] = useState(false)
  const { data: tracker, isLoading: trackerIsLoading } = useTracker(uid, {
    shouldPollForUpdates,
  })

  const { getSegments } = useSegmentOverview()
  const { states } = useSegmentStatesContext()

  const openPhoneExamplesDrawer = usePhoneTrackerExamplesDrawer()
  const openEmailExamplesDrawer = useEmailTrackerExamplesDrawer()
  const { period, periodDef } = useFilterPeriodContext()

  useEffect(() => {
    const processingTrackersInCurrentPeriod =
      tracker &&
      isProcessingInCurrentPeriod({
        isProcessing: tracker.isProcessing,
        processingProgress: tracker.processingProgress,
        isTextProcessing: tracker.isTextProcessing,
        textProcessingProgress: tracker.textProcessingProgress,
        periodFrom: period.from,
      })

    if (processingTrackersInCurrentPeriod && !shouldPollForUpdates) {
      setShouldPollForUpdates(true)
    }
    if (!processingTrackersInCurrentPeriod && shouldPollForUpdates) {
      // Tracker is no longer dirty, and polling should stop
      setShouldPollForUpdates(false)
    }
  }, [period.from, shouldPollForUpdates, tracker])

  // if the selected tracker doesn't exists, can happen if a tracker gets deleted
  //TODO: make something nice
  if (!(tracker || trackerIsLoading)) {
    return (
      <ContentPlaceholder.Container mb="20vh">
        <ContentPlaceholder.Image as={Icon_EmptyState} />
        <ContentPlaceholder.Heading>
          <Trans>Whoopsy! 👻</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>
            Tracker doesn&apos;t exist. Either it has been deleted, access has
            been changed or the URL is wrong. Try selecting another tracker.
          </Trans>
        </ContentPlaceholder.Body>
        <ContentPlaceholder.Footer />
      </ContentPlaceholder.Container>
    )
  }

  const segments = getSegments(tracker?.uid as string)

  const onShowPhoneExamples = (
    tracker: BaseTracker,
    state: PhoneSegmentState,
  ): void => {
    analytics.event('analytics_Trackers_ViewTrackerExamples')
    openPhoneExamplesDrawer({
      tracker,
      filterState: {
        ...state.values,
        trackers: [
          ...state.values.trackers,
          {
            uids: [tracker.uid],
          },
        ],
      },
      periodDef,
    })
  }

  const onShowEmailExamples = (
    tracker: BaseTracker,
    state: TextSegmentState,
  ): void => {
    analytics.event('analytics_Trackers_ViewTrackerEmailExamples')
    openEmailExamplesDrawer({
      tracker,
      filterState: {
        ...state.values,
      },
      periodDef,
    })
  }

  return (
    <Box
      flex="1"
      sx={{ width: 0 }}
      height={pageHeight}
      ref={ref}
      overflowY="auto"
    >
      {tracker && (
        <Box key={tracker.uid} pl={8} pt={0} pr={0}>
          <TrackerAccordion tracker={tracker} />
          <Box flex={1}>
            <Grid
              templateColumns="repeat(auto-fit, minmax(250px, 1fr))"
              columnGap={2}
            >
              {tracker.description && (
                <Box gap={4} py={2}>
                  <Text>
                    <Highlight>
                      <Trans>Description</Trans>
                    </Highlight>
                  </Text>
                  <Text fontSize="sm" color="textMuted">
                    {tracker.description}
                  </Text>
                </Box>
              )}
              <Grid
                templateColumns="auto auto auto"
                gap={2}
                autoFlow="row dense"
                justifyItems="start"
                alignItems="center"
                justifySelf="end"
              >
                {segments.map((s, index) => (
                  <Fragment key={s.label}>
                    <Flex gap="2" align="center">
                      <ColorLabel color={s.color} />
                      <Highlight>
                        {formatHitRate(s.data?.hitPercentage)}
                      </Highlight>
                    </Flex>
                    <Caption color="textMuted">
                      <Trans>
                        ({s.data?.hits ?? 0}/{s.data?.totalTargets ?? 0}{' '}
                        conversations)
                      </Trans>
                    </Caption>
                    <PlaySnippetsButton
                      label={t`View conversations`}
                      onClick={() => {
                        const state = states[index]
                        if (state.channel === 'phone') {
                          onShowPhoneExamples(tracker, state)
                        } else {
                          onShowEmailExamples(tracker, state)
                        }
                      }}
                    />
                  </Fragment>
                ))}
              </Grid>
            </Grid>
          </Box>
          <TimeDataContainer
            tracker={tracker}
            segmentsOverview={getSegments(tracker.uid)}
          />
        </Box>
      )}
    </Box>
  )
}

export default TrackerDetails
