type Option = {
  label: string
  value: string
}

const getMissingOptions = <T extends Option>(
  selectedValues: string[],
  options: T[],
  label?: string,
): T[] =>
  selectedValues.reduce<T[]>((total, current) => {
    if (!options.some((o) => o.value === current)) {
      total.push({
        label: label ?? current,
        value: current,
      } as T)
    }
    return total
  }, [])

export default getMissingOptions
