import { Range } from '@capturi/api-filters'

export function toDuration(
  minInput?: number | null,
  maxInput?: number | null,
): Range | undefined {
  if ((minInput == null || minInput === 0) && maxInput == null) return undefined

  const min = minInput == null || minInput === 0 ? undefined : minInput
  const max = maxInput == null ? undefined : maxInput

  if (min === undefined && max === undefined) return undefined

  return { min, max }
}
