import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { ResponseError } from '@capturi/request'
import { ContentPlaceholder } from '@capturi/ui-components'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

const ConversationLoadingError: React.FC<{ error: ResponseError }> = ({
  error,
}) => {
  let status: number | undefined = error.statusCode
  if (error instanceof ResponseError) {
    status = error.statusCode
  }

  const message = {
    title: t`An error occurred`,
    description: t`An error occurred while preparing the conversation for you`,
  }
  if (status === 403) {
    message.title = t`Access Denied`
    message.description = t`You do not have permission to access this conversation.`
  }
  if (status === 400 || status === 404 || status === 201) {
    return (
      <ContentPlaceholder.Container>
        <ContentPlaceholder.Image as={Icon_EmptyState} />
        <ContentPlaceholder.Heading>
          <Trans>Puff... No conversation in sight 🔭</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>
            The conversation might have been deleted or never existed. Have a
            look at all of your other lovely conversations.
          </Trans>
        </ContentPlaceholder.Body>
      </ContentPlaceholder.Container>
    )
  }

  return (
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
      height="200px"
      bg="gray.200"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        {message.title}
      </AlertTitle>
      <AlertDescription maxWidth="sm">{message.description}</AlertDescription>
    </Alert>
  )
}

export default ConversationLoadingError
