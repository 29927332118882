import { Description } from '@capturi/ui-components'
import {
  Box,
  BoxProps,
  Checkbox,
  Heading,
  HeadingProps,
  Stack,
} from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import { Column, useFlexLayout, useRowSelect, useTable } from 'react-table'
import { usePrevious, useUpdateEffect } from 'react-use'

import { ColumnDefinition, DataType } from './types'
import useColumns from './useColumns'

export const DataGridTitle: React.FC<HeadingProps> = (props) => {
  return <Heading as="h3" mb={2} {...props} />
}

export const DataGridDescription: React.FC<BoxProps> = (props) => {
  return <Description mb={2} {...props} />
}

export const DataGridRefContext =
  React.createContext<React.RefObject<HTMLDivElement> | null>(null)

type SelectedRowsMap = Record<string, boolean>

export type DataGridColumn<T extends DataType> = ColumnDefinition<T> | Column<T>

export type DataGridProps<T extends DataType> = {
  data: T[]
  columns: DataGridColumn<T>[]
  children?: React.ReactNode
  selectableRows?: boolean
  rowPaddingY?: number
  showRowDivider?: boolean
  onSelectedRowsChange?: (rows: T[]) => void
  initialSelectedRows?: SelectedRowsMap | (() => SelectedRowsMap)
  isRowSelectable?: (row: T) => boolean
}

const rowStyleProps = (
  isSelected: boolean,
  isSelectable = true,
  showRowDivider = true,
): BoxProps => {
  return {
    borderBottom: showRowDivider ? '1px' : undefined,
    borderBottomColor: isSelected ? 'gray.200' : 'gray.100',
    bg: isSelected ? 'gray.100' : 'inherit',
    _hover: isSelectable
      ? {
          bg: 'gray.100',
        }
      : undefined,
  }
}

function DataGrid_<T extends DataType>({
  data,
  columns: columnsProp,
  children,
  selectableRows = false,
  onSelectedRowsChange,
  initialSelectedRows: initialSelectedRowsProp,
  isRowSelectable,
  rowPaddingY = 2,
  showRowDivider,
}: DataGridProps<T>): React.ReactElement {
  const dataGridRef = useRef<HTMLDivElement>(null)

  // FIXME: useTable typing issues if not cast to Column<any>[] here
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  const columns = useColumns(columnsProp) as Column<any>[]

  const [initialSelectedRows] = useState<Record<string, boolean>>(() => {
    if (initialSelectedRowsProp === undefined) return {}
    return typeof initialSelectedRowsProp === 'function'
      ? initialSelectedRowsProp()
      : initialSelectedRowsProp
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: initialSelectedRows,
      },
      autoResetSelectedRows: false,
    },
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      if (selectableRows) {
        // Add a checkbox column
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            width: 24,
            Cell: function SelectRowCell({ row }) {
              const { checked, onChange } = row.getToggleRowSelectedProps()
              return (
                <Box
                  display="flex"
                  h={6}
                  alignItems="center"
                  onClick={(e) => {
                    // Stop event from propagating to the row
                    e.stopPropagation()
                  }}
                >
                  <Checkbox
                    isChecked={checked}
                    onChange={onChange}
                    isDisabled={isRowSelectable?.(row.original as T) === false}
                  />
                </Box>
              )
            },
          },
          ...columns,
        ])
      }
    },
  )

  const previousSelectRowsCount = usePrevious(selectedFlatRows.length)
  useUpdateEffect(() => {
    if (!selectableRows) return
    if (previousSelectRowsCount === selectedFlatRows.length) return
    onSelectedRowsChange?.(selectedFlatRows.map((x) => x.original as T))
  }, [
    selectableRows,
    onSelectedRowsChange,
    previousSelectRowsCount,
    selectedFlatRows,
  ])

  return (
    <Box minW="300px" width="100%" overflowX="auto" zIndex={0}>
      {children}
      <Box width="100%" position="relative" zIndex={0} {...getTableProps()}>
        <Box as="section" ref={dataGridRef} position="relative">
          <DataGridRefContext.Provider value={dataGridRef}>
            <Box as="header">
              {headerGroups.map((headerGroup) => (
                // biome-ignore lint/correctness/useJsxKeyInIterable: spred in
                <Stack
                  isInline
                  spacing={2}
                  px={2}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => {
                    const headerProps = column.getHeaderProps()
                    if (column.width === 'auto') {
                      headerProps.style = {
                        ...(headerProps.style ?? {}),
                        width: '100%',
                      }
                    }
                    // biome-ignore lint/correctness/useJsxKeyInIterable: spread
                    return <Box {...headerProps}>{column.render('Header')}</Box>
                  })}
                </Stack>
              ))}
            </Box>
            <Box as="main" {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                const _isRowSelectable =
                  isRowSelectable?.(row.original as T) !== false
                return (
                  // biome-ignore lint/correctness/useJsxKeyInIterable: spread
                  <Stack
                    isInline
                    spacing={2}
                    py={rowPaddingY}
                    px={2}
                    {...row.getRowProps()}
                    {...rowStyleProps(
                      row.isSelected,
                      selectableRows && _isRowSelectable,
                      showRowDivider,
                    )}
                    cursor="pointer"
                    onClick={() => {
                      if (selectableRows && _isRowSelectable) {
                        row.toggleRowSelected()
                      }
                    }}
                  >
                    {row.cells.map((cell) => {
                      const cellProps = cell.getCellProps()
                      if (cell.column.width === 'auto') {
                        cellProps.style = {
                          ...(cellProps.style ?? {}),
                          width: '100%',
                        }
                      }
                      // biome-ignore lint/correctness/useJsxKeyInIterable: spread
                      return <Box {...cellProps}>{cell.render('Cell')}</Box>
                    })}
                  </Stack>
                )
              })}
            </Box>
          </DataGridRefContext.Provider>
        </Box>
      </Box>
    </Box>
  )
}

export const DataGrid = React.memo(DataGrid_) as typeof DataGrid_
