import { useTheme } from '@capturi/ui-theme'
import { Box, Flex, List, ListItem, Skeleton, Text } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'

import Markdown, { RuleType } from 'markdown-to-jsx'
import React, { PropsWithChildren, useMemo } from 'react'
import { KeySubTopicCluster } from '../../../KeyTopics/hooks/types'
import { AnimateChangeInHeight } from '../../Shared/AnimateHeight'
import { useKeyTopicsDigest } from '../../hooks/useKeyTopicsDigest'

const ALLOWED_RULES = new Set([
  RuleType.blockQuote,
  RuleType.breakLine,
  RuleType.newlineCoalescer,
  RuleType.orderedList,
  RuleType.unorderedList,
  RuleType.paragraph,
  RuleType.text,
  RuleType.textBolded,
  RuleType.textEmphasized,
  RuleType.textStrikethroughed,
  RuleType.unorderedList,
])

const DigestWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme()
  return (
    <Flex
      css={css`&:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0.5;
    border-radius: 4px;
    background: ${theme.colors.aiGradient};
}`}
      borderRadius={4}
      w="100%"
      h="100%"
    >
      <Box
        bg="white"
        pt={3}
        pb={2}
        px={4}
        m="1px"
        fontSize="sm"
        w="100%"
        borderRadius={4}
      >
        <AnimateChangeInHeight>{children}</AnimateChangeInHeight>
      </Box>
    </Flex>
  )
}

const DigestSkeleton: React.FC = () => {
  return (
    <DigestWrapper>
      <Flex gap="2" flexDir="column">
        <Skeleton height="16px" width="70%" borderRadius="999" />
        <Flex mb="4" gap="2" flexDir="column">
          <Flex gap="1" flexDir="row" align="center">
            <Skeleton height="3" width="3" />
            <Skeleton height="18px" width="40%" borderRadius="999" />
          </Flex>
          <Skeleton height="12px" width="100%" borderRadius="999" />
          <Skeleton height="12px" width="100%" borderRadius="999" />
          <Skeleton height="12px" width="20%" borderRadius="999" />
        </Flex>
        <Flex mb="4" gap="2" flexDir="column">
          <Flex gap="1" flexDir="row" align="center">
            <Skeleton height="3" width="3" />
            <Skeleton height="18px" width="40%" borderRadius="999" />
          </Flex>
          <Skeleton height="12px" width="100%" borderRadius="999" />
          <Skeleton height="12px" width="20%" borderRadius="999" />
        </Flex>
        <Flex mb="2" gap="2" flexDir="column">
          <Flex gap="1" flexDir="row" align="center">
            <Skeleton height="3" width="3" />
            <Skeleton height="18px" width="40%" borderRadius="999" />
          </Flex>
          <Skeleton height="12px" width="100%" borderRadius="999" />
          <Skeleton height="12px" width="100%" borderRadius="999" />
          <Skeleton height="12px" width="20%" borderRadius="999" />
        </Flex>
      </Flex>
    </DigestWrapper>
  )
}

const KeyTopicDigest: React.FC<{
  keyTopicsSessionUid: string | undefined
  keyTopicClusterId: string | undefined
  keyTopicClusterLabel: string | undefined
  keySubTopicClusters: KeySubTopicCluster[] | undefined
}> = ({
  keyTopicsSessionUid,
  keyTopicClusterId,
  keyTopicClusterLabel,
  keySubTopicClusters,
}) => {
  const { colors: themeColors } = useTheme()
  const colors = [
    themeColors.segmentPrimary,
    themeColors.segmentSecondary,
    themeColors.segmentTertiary,
    themeColors.segmentQuaternary,
    themeColors.segmentQuinary,
  ]
  const keySubTopicClusterIds = keySubTopicClusters?.map((c) => c.id) ?? []
  const { data, isLoading } = useKeyTopicsDigest({
    keyTopicsSessionUid,
    keyTopicClusterId,
    keySubTopicClusterIds,
  })

  const keySubTopicIdToDigest = useMemo(() => {
    return data?.digests?.reduce<Record<string, string>>((acc, digest) => {
      if (!digest.digest) return acc
      acc[digest.keySubTopicId] = digest.digest
      return acc
    }, {})
  }, [data])

  if (isLoading) return <DigestSkeleton />

  if (
    keySubTopicIdToDigest === undefined ||
    keySubTopicClusters === undefined ||
    keySubTopicClusters.length === 0
  ) {
    return null
  }

  return (
    <DigestWrapper>
      <Text fontSize="sm" fontWeight="medium" mb={2}>
        <Trans>
          What are some initiative suggestions, that can potentially reduce{' '}
          {keyTopicClusterLabel} calls?
        </Trans>
      </Text>
      <List spacing={2}>
        {keySubTopicClusters.map((cluster, index) => (
          <ListItem key={cluster.id}>
            <Flex direction="row">
              <Box
                flexShrink={0}
                backgroundColor={colors[index % colors.length]}
                borderRadius="2px"
                mt="0.3rem"
                mr={2}
                width="8px"
                height="8px"
              />
              <Flex direction="column">
                <Text fontSize="sm" fontWeight="medium">
                  {cluster.keySubTopics[0]?.name}
                </Text>
                <Box ml={0} fontWeight="regular" whiteSpace="pre-wrap">
                  <Markdown
                    options={{
                      disableParsingRawHTML: true,
                      overrides: {
                        ol: {
                          component: Box,
                          props: {
                            as: 'ol',
                            marginLeft: '4',
                          },
                        },
                      },
                      renderRule: (next, node) => {
                        //We only allow a subset of types
                        if (ALLOWED_RULES.has(node.type)) {
                          return next()
                        }

                        //Typescript fix
                        //Not all types have a text, so we return an empty string if they dont
                        return (
                          (node as unknown as { text?: string | undefined })
                            .text || ''
                        )
                      },
                    }}
                  >
                    {keySubTopicIdToDigest[cluster.id]}
                  </Markdown>
                </Box>
              </Flex>
            </Flex>
          </ListItem>
        ))}
      </List>
      <Text fontStyle="italic" color="gray.500" mt={4}>
        <Trans>
          The recommendations are AI-generated and based on a random selection
          of conversations.
        </Trans>
      </Text>
    </DigestWrapper>
  )
}

export default KeyTopicDigest
