import analytics from '@capturi/analytics'
import { Button } from '@capturi/ui-components'
import { Flex, HStack, Heading } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { getRoutes } from 'pages/analytics'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ConversationsSentimentList from './components/ConversationsSentimentList'
import { useSentimentSummaries } from './hooks/useSentimentSummaries'

const ConversationsSentimentWidget: React.FC = () => {
  const pageRoutes = getRoutes(true)
  const navigate = useNavigate()
  const { data, isLoading } = useSentimentSummaries()

  if (!data?.length && !isLoading) {
    return null
  }

  return (
    <Flex flexDir="column">
      <HStack justify="space-between">
        <Heading
          fontSize="2xl"
          textShadow="textExtended"
          mb={3}
          data-stonly="sentiment_conversations"
          width="fit-content"
        >
          <Trans>Latest positive and negative conversations</Trans>
        </Heading>
        <Button
          onClick={() => {
            analytics.event('sentiment_conversations_explore_more')
            navigate(pageRoutes.sentiment)
          }}
          primary
          size="xs"
          variant="link"
        >
          <Trans>Explore more</Trans>
        </Button>
      </HStack>
      <ConversationsSentimentList />
    </Flex>
  )
}

export default ConversationsSentimentWidget
