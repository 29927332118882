import { ErrorBoundary } from '@capturi/react-utils'
import { Spinner } from '@capturi/ui-components'
import React from 'react'
import { Route, Routes, useSearchParams } from 'react-router-dom'

import { MostRecentDashboardAndFolderProvider } from './ListPage/useMostRecentDashboardAndFolderUid'

export { default as routes } from './routes'

const ListPage = React.lazy(() => import('./ListPage/ListPage'))
const DashboardPage = React.lazy(() => import('./DashboardPage'))
const DashboardViewPage = React.lazy(() => import('./DashboardViewPage'))

const DashboardRouter: React.FC = () => {
  const [searchParams] = useSearchParams()
  // iOS app adds 'responsive' search param with no value when it opens dashboards:
  const responsive = searchParams.has('responsive')
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<Spinner display="block" m="5rem auto" />}>
        <MostRecentDashboardAndFolderProvider>
          <Routes>
            <Route path="/" element={<ListPage />} />
            <Route
              path=":uid"
              element={
                responsive ? (
                  <DashboardViewPage responsive />
                ) : (
                  <DashboardPage />
                )
              }
            />
            <Route path=":uid/edit" element={<DashboardPage />} />
          </Routes>
        </MostRecentDashboardAndFolderProvider>
      </React.Suspense>
    </ErrorBoundary>
  )
}

export default DashboardRouter
