import { transparentize } from '@chakra-ui/theme-tools'
// biome-ignore lint/suspicious/noExplicitAny: legacy
type Dict = Record<string, any>

function goldOutline(
  backgroundColor: string,
  outlineColor: string,
  boxShadowColor: string,
): Dict {
  return {
    background: `linear-gradient(${backgroundColor}, ${backgroundColor}) padding-box, ${outlineColor} border-box`,
    boxShadow: `0px 2px 0px 0px ${boxShadowColor}`,
  }
}

export default {
  variants: {
    'form-select-field': (props: Dict): Dict => {
      const { outline } = props.theme.components.Button.variants
      const baseProps = outline(props)
      return {
        ...baseProps,
        color: 'black',
        fontWeight: 'normal',
        '.clear': {
          boxSize: '30px',
          color: 'gray.200',
          padding: '6px',
        },
        '.open-indicator': {
          boxSize: '20px',
        },
        '.placeholder': {
          color: 'gray.500',
        },
        _focus: {
          ...baseProps._focus,
          '.clear': {
            color: 'gray.500',
          },
        },
        _hover: {
          ...baseProps._hover,
          '.clear': {
            color: 'gray.500',
          },
        },
      }
    },
    'gold-outline': (props: Dict): Dict => {
      const { solid } = props.theme.components.Button.variants
      const baseProps = solid(props)
      const colors = props.theme.colors
      return {
        ...baseProps,
        border: '1px solid transparent',
        ...goldOutline(colors.gray[100], colors.accents.gold, colors.gray[300]),
        _hover: goldOutline(
          colors.gray[200],
          colors.accents.gold,
          colors.gray[400],
        ),
        _active: goldOutline(
          colors.gray[300],
          colors.accents.gold,
          colors.gray[500],
        ),
      }
    },
    lifted: (props: Dict): Dict => {
      const { solid } = props.theme.components.Button.variants
      const baseProps = solid(props)
      return {
        ...baseProps,
        boxShadow: `0px 12px 14px -11px ${transparentize(
          `${props.colorScheme}.500`,
          0.5,
        )(props.theme)}`,
      }
    },
    'lifted-icon': (props: Dict): Dict => {
      const { solid } = props.theme.components.Button.variants
      const baseProps = solid(props)
      return {
        ...baseProps,
        boxShadow: `0px 4px 10px -3px ${transparentize(
          `${props.colorScheme}.500`,
          0.5,
        )(props.theme)}`,
      }
    },
    'bottom-bordered-lifted': (props: Dict): Dict => {
      const { theme, colorScheme } = props
      const liftedIcon = theme.components.Button.variants['lifted-icon']
      const baseProps = liftedIcon(props)
      return {
        ...baseProps,
        borderBottom: '2px solid',
        borderBottomColor: `${colorScheme}.600`,
      }
    },
  },
  sizes: {
    xs: {
      svg: {
        boxSize: '14px',
      },
    },
    sm: {
      svg: {
        boxSize: '18px',
      },
    },
    md: {
      svg: {
        boxSize: '20px',
      },
    },
  },
  baseStyle: {
    fontWeight: 'medium',
  },
}
