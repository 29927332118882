import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'

export const notifications = {
  enabled: (): MessageDescriptor =>
    msg`Notifications are enabled for this segment.`,
  disabled: (): MessageDescriptor =>
    msg`Notifications are disabled for this segment.`,
  savedFilterNotSelected: (): MessageDescriptor =>
    msg`The filter must be saved as a segment to enable notifications.`,
}
