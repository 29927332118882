import analytics from '@capturi/analytics'
import { DefaultFallbackComponent } from '@capturi/react-utils'
import { formatSeconds } from '@capturi/ui-components'
import {
  Box,
  Flex,
  Heading,
  Skeleton,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC, useCallback } from 'react'

import SessionFailedProcessing from '../../../components/KeyTopics/FailedStates/SessionFailedProcessing'
import SessionNotFound from '../../../components/KeyTopics/FailedStates/SessionNotFound'
import { useLongConversationKeyTopics } from '../../../hooks/useKeyTopics'
import { AnimateChangeInHeight } from '../Shared/AnimateHeight'
import { useAverageDuration } from '../hooks/useAverageDuration'

import { useHomePageIntersectionTracking } from '../Shared/useHomePageIntersectionTracking'
import {
  NoTopTimeConsumersWidgetCard,
  TopTimeConsumersWidgetCard,
  TopTimeConsumersWidgetCardSkeleton,
} from './TopTimeConsumersWidgetCard'
import { selector } from './selector'

type Props = {
  keyTopicsSessionUid: string | undefined
  startNewSession: () => void
}

const TopTimeConsumersWidgetSkeleton: FC = () => {
  return (
    <Box>
      <Skeleton height="21px" width="250px" mb="1" />
      <Skeleton height="14px" width="70%" mb="1" />
      <VStack alignItems="stretch" spacing={4}>
        <TopTimeConsumersWidgetCardSkeleton />
      </VStack>
    </Box>
  )
}

const TopTimeConsumersWidgetContent: FC<Props> = ({
  keyTopicsSessionUid,
  startNewSession,
}) => {
  const { data, isLoading, error } = useLongConversationKeyTopics({
    select: selector,
  })

  const { data: averageDuration, isLoading: isAverageDurationLoading } =
    useAverageDuration()

  if (error) return <DefaultFallbackComponent error={error} />
  if (data?.status === 'failed')
    return <SessionFailedProcessing startNewSession={startNewSession} />
  if (data?.status === 'gone') {
    return <SessionNotFound startNewSession={startNewSession} />
  }
  if (isLoading || !data || data.status === 'loading')
    return <TopTimeConsumersWidgetSkeleton />

  const firstItem = data.status === 'ready' && data.keyTopicClusters[0]
  return (
    <AnimateChangeInHeight overflowYVisble>
      <Flex flexDir="column" minH={firstItem ? '384px' : undefined}>
        <Heading
          fontSize="2xl"
          textShadow="textExtended"
          data-stonly="Top time consumers"
          width="fit-content"
        >
          <Trans>Top time consumers</Trans>
        </Heading>
        {isAverageDurationLoading ? (
          <Skeleton />
        ) : (
          <Text fontSize="sm" color="gray.600">
            <Trans>
              Your{' '}
              <chakra.span fontWeight="medium">
                average conversation is {formatSeconds(averageDuration)} mins.
              </chakra.span>{' '}
              We define long conversations as those minimum 2X the average.{' '}
            </Trans>{' '}
            {firstItem && (
              <Trans>
                Majority ({firstItem.amount}) of your long conversations are in{' '}
                <chakra.span fontWeight="medium">{firstItem.label}</chakra.span>{' '}
                and related topics.
              </Trans>
            )}
          </Text>
        )}
        <Box flex="1 1 auto">
          {data.status === 'empty' ? (
            <NoTopTimeConsumersWidgetCard />
          ) : (
            <TopTimeConsumersWidgetCard
              keyTopicsSessionUid={keyTopicsSessionUid}
              data={data}
            />
          )}
        </Box>
      </Flex>
    </AnimateChangeInHeight>
  )
}

const TopTimeConsumersWidgetWithTracking: FC<Props> = (props) => {
  const trackWidgetViewed = useCallback(() => {
    analytics.event('top_time_consumers_widget_viewed')
  }, [])

  const ref = useHomePageIntersectionTracking({
    onIntersect: trackWidgetViewed,
  })

  return (
    <Box ref={ref}>
      <TopTimeConsumersWidgetContent {...props} />
    </Box>
  )
}

export const TopTimeConsumersWidget: FC<Props> = (props) => {
  return <TopTimeConsumersWidgetWithTracking {...props} />
}
