// biome-ignore lint/suspicious/noExplicitAny: legacy
type Dict = Record<string, any>

function variantBordered(): Dict {
  return {
    tr: {
      '&:last-of-type': {
        td: {
          borderBottom: '0px',
        },
      },
    },
    td: {
      borderBottom: '1px solid',
      borderColor: 'gray.300',
    },
    th: {
      borderTop: '0.5px solid',
      borderBottom: '1px solid',
      borderColor: 'gray.300',
      color: 'gray.600',
      textTransform: 'none',
      paddingTop: '0.75rem',
      paddingBottom: '0.75rem',
    },
  }
}

function variantConversationHits(): Dict {
  return {
    th: {
      borderTop: '0.5px solid',
      borderBottom: '1px solid',
      borderColor: 'gray.300',
      color: 'gray.600',
      textTransform: 'none',
      paddingTop: '0.75rem',
      paddingBottom: '0.75rem',
    },

    td: {
      paddingTop: '0.6875rem',
      paddingBottom: '0.6875rem',
      paddingLeft: 0,
      paddingRight: 0,
      _first: {
        paddingRight: '1rem',
      },
      '&:nth-of-type(2)': {
        paddingRight: '0.5rem',
      },
      _last: {
        paddingLeft: 2,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  }
}

function variantKeyTopics(): Dict {
  return {
    table: {
      borderCollapse: 'separate',
      borderSpacing: '0',
      borderRadius: '0.25rem',
    },
    thead: {
      tr: {
        '&:first-of-type': {
          borderTop: '1px solid',
          borderLeft: '1px solid',
          borderRight: '1px solid',
          borderTopColor: 'gray.300',
          borderTopLeftRadius: '0.25rem',
          borderTopRightRadius: '0.25rem',
          th: {
            paddingTop: '0.25rem',
            paddingBottom: '0.25rem',
          },
          'th:first-of-type': {
            borderTopLeftRadius: '0.25rem',
            borderLeft: '1px solid',
            borderLeftColor: 'gray.300',
          },
          'th:last-of-type': {
            borderTopRightRadius: '0.25rem',
            borderRight: '1px solid',
            borderRightColor: 'gray.300',
          },
        },
      },
    },
    tbody: {
      tr: {
        'td:first-of-type': {
          borderLeft: '1px solid',
          borderLeftColor: 'gray.300',
        },
        'td:last-of-type': {
          borderRight: '1px solid',
          borderRightColor: 'gray.300',
        },
        '&:last-of-type': {
          'td:first-of-type': {
            borderBottomLeftRadius: '0.25rem',
            borderLeft: '1px solid',
            borderLeftColor: 'gray.300',
          },
          'td:last-of-type': {
            borderBottomRightRadius: '0.25rem',
            borderRight: '1px solid',
            borderRightColor: 'gray.300',
          },
        },
      },
    },
    td: {
      borderBottom: '1px solid',
      borderBottomColor: 'gray.300',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      paddingLeft: '0.75rem',
      paddingRight: '0.75rem',
      '&:last-of-type': {
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
      },
    },
    th: {
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: 'gray.300',
      color: 'gray.600',
      textTransform: 'none',
      paddingLeft: '0.75rem',
      paddingRight: '0.25rem',
      '&:last-of-type': {
        paddingLeft: '0',
        paddingRight: '0.5rem',
      },
    },
  }
}

function variantKeyTopicsOther(): Dict {
  return {
    ...variantKeyTopics(),
    table: {
      ...variantKeyTopics().table,
      boxShadow: 'base',
    },
    td: {
      ...variantKeyTopics().td,
      borderTop: '1px solid',
      borderTopColor: 'gray.300',
      '&:first-of-type': {
        borderTopLeftRadius: '0.25rem',
      },
      '&:last-of-type': {
        borderTopRightRadius: '0.25rem',
      },
    },
  }
}

export default {
  baseStyle: {
    th: {
      fontFamily: 'body',
    },
  },
  variants: {
    bordered: variantBordered,
    'conversation-hits': variantConversationHits,
    'key-topics': variantKeyTopics,
    'key-topics-other': variantKeyTopicsOther,
  },
}
