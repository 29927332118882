import { theme } from '@capturi/ui-theme'
import { HTMLChakraProps, chakra, useMergeRefs } from '@chakra-ui/react'
import React, { useCallback, useEffect, useRef } from 'react'

export type InputProps = Omit<
  HTMLChakraProps<'input'>,
  'onChange' | 'value' | 'size'
> & {
  value: number | undefined
  onChange: (val: number) => void
  step?: number
  size?: 'sm' | 'md' | 'lg'
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  function InputWithRef(
    { value, onChange, step = 5, size = 'md', ...props },
    ref,
  ) {
    const inputRef = useRef<HTMLInputElement>(null)
    const mergedRefs = useMergeRefs(ref, inputRef)

    const isValidKey = (event: React.KeyboardEvent): boolean => {
      if (event.key == null) return true
      const isModifierKey =
        event.ctrlKey || event.altKey || event.metaKey || event.shiftKey
      if (isModifierKey) {
        return true
      }
      if (['Backspace', 'Tab'].includes(event.key)) return true
      return /\d/.test(event.key)
    }

    const formatValue = (value: number | undefined): string => {
      if (value === undefined) return ''
      return `${value}`.padStart(2, '0')
    }

    const increment = useCallback(() => {
      onChange((value ?? 0) + step)
    }, [value, step, onChange])

    const decrement = useCallback(() => {
      onChange((value ?? 0) - step)
    }, [value, step, onChange])

    useEffect(() => {
      const element = inputRef.current
      if (element == null) return

      const listener = (event: WheelEvent): void => {
        if (document.activeElement !== element) return
        event.preventDefault()
        if (event.deltaY < 0) {
          increment()
        } else if (event.deltaY > 0) {
          decrement()
        }
      }

      element.addEventListener('wheel', listener)
      return () => {
        element?.removeEventListener('wheel', listener)
      }
    }, [increment, decrement])

    const sizeProps = theme.components.Input.sizes[size].field
    return (
      <chakra.input
        ref={mergedRefs}
        w="2em"
        {...sizeProps}
        px="2px"
        _focus={{ bg: 'primary.100', outline: 0 }}
        value={formatValue(value)}
        onChange={(e) => {
          const val = e.target.value.substr(-2)
          onChange(val === '' ? 0 : Number.parseInt(val))
        }}
        onKeyDown={(e) => {
          if (!isValidKey(e)) {
            e.preventDefault()
          }
          if (e.key === 'ArrowUp') {
            e.preventDefault()
            increment()
          }
          if (e.key === 'ArrowDown') {
            e.preventDefault()
            decrement()
          }
        }}
        placeholder="——"
        {...props}
      />
    )
  },
)

export default Input
