import { extendTheme, withDefaultSize } from '@chakra-ui/react'
import { Theme as DefaultTheme } from '@chakra-ui/theme'

import colors from './colors'
// Component style overrides
import Alert from './components/alert'
import Avatar from './components/avatar'
import Button from './components/button'
import Checkbox from './components/checkbox'
import Drawer from './components/drawer'
import Heading from './components/heading'
import Input from './components/input'
import Link from './components/link'
import Menu from './components/menu'
import Modal from './components/modal'
import Popover from './components/popover'
import Progress from './components/progress'
import Radio from './components/radio'
import Switch from './components/switch'
import Table from './components/table'
import Tabs from './components/tabs'
import Tag from './components/tag'
import Textarea from './components/textarea'
import Tooltip from './components/tooltip'
// Foundational style overrides
import breakpoints from './foundations/breakpoints'
import fontSizes from './foundations/fontSizes'
import fonts from './foundations/fonts'
import shadows from './foundations/shadows'
import sizes from './foundations/sizes'
// Global style overrides
import styles from './styles'

type CustomTheme = {
  colors: typeof colors
  shadows: typeof shadows
}

export type CapturiTheme = DefaultTheme & CustomTheme

const overrides = {
  breakpoints,
  styles,
  colors,
  fonts,
  fontSizes,
  shadows,
  sizes,
  components: {
    Alert,
    Avatar,
    Button,
    Checkbox,
    Drawer,
    Heading,
    Input,
    Link,
    Menu,
    Modal,
    Popover,
    Progress,
    Radio,
    Switch,
    Table,
    Tabs,
    Tag,
    Textarea,
    Tooltip,
  },
}

const extendedTheme = extendTheme(
  overrides,
  withDefaultSize({
    size: 'sm',
    components: ['Button', 'Badge', 'Tag'],
  }),
)

export default extendedTheme
