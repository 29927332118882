import { i18n } from '@lingui/core'

const numberFormat: Intl.NumberFormatOptions = {
  style: 'decimal',
  maximumFractionDigits: 0,
}

const format = (val: number): string => i18n.number(val, numberFormat)

export default format
