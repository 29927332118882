import React from 'react'
import {
  ErrorBoundaryPropsWithComponent,
  ErrorBoundaryPropsWithRender,
  FallbackProps,
  ErrorBoundary as ReactErrorBoundary,
  useErrorHandler,
  withErrorBoundary as withReactErrorBoundary,
} from 'react-error-boundary'

import DefaultFallbackComponent from './DefaultFallbackComponent'

type Props = Partial<ErrorBoundaryPropsWithRender> & {
  children?: React.ReactNode
}

const DefaultComponent =
  DefaultFallbackComponent as React.ComponentType<FallbackProps>

const ErrorBoundary: React.FC<Props> = (props) => {
  return (
    <ReactErrorBoundary
      fallbackRender={(fallbackProps: FallbackProps) => (
        <DefaultFallbackComponent {...fallbackProps} />
      )}
      {...props}
    />
  )
}

const ErrorBoundaryWithFallbackComponent: React.FC<
  ErrorBoundaryPropsWithComponent & {
    children?: React.ReactNode
  }
> = ({ FallbackComponent, ...restProps }) => {
  return (
    <ReactErrorBoundary FallbackComponent={FallbackComponent} {...restProps} />
  )
}

function withErrorBoundary<P>(
  ComponentToDecorate: React.ComponentType<P>,
  errorBoundaryProps: Partial<ErrorBoundaryPropsWithComponent> = {},
): React.ComponentType<P> {
  return withReactErrorBoundary(ComponentToDecorate, {
    FallbackComponent: DefaultComponent,
    ...errorBoundaryProps,
  })
}

export {
  ErrorBoundary,
  ErrorBoundaryWithFallbackComponent,
  withErrorBoundary,
  DefaultFallbackComponent,
  useErrorHandler,
}
export type { FallbackProps }
