import { addMinutes, max as getMaxDate, min as getMinDate } from 'date-fns'
import { DateBucket } from '../types'

/**
 * If the selected date period overlaps entering/leaving daylight savings we will have skewed date buckets, i.e. dates after entering or leaving DST which no longer have a midnight time component.
 * We can adjust these buckets by looking at the timezone offset for each bucket and comparing it
 * to the initial timezone offset used to fetch the data.
 */
export function adjustForDSTOverlaps(
  buckets: DateBucket[],
  initialOffset: number,
): DateBucket[] {
  return buckets.map((x) => {
    x.date = adjustDateForDSTOverlap(x.date, initialOffset)
    return x
  })
}

export function adjustDateForDSTOverlap(
  date: Date,
  initialOffset: number,
): Date {
  const offset = date.getTimezoneOffset() * -1
  const diff = initialOffset - offset
  if (diff !== 0) {
    return addMinutes(date, diff)
  }
  return date
}

export function getDateExtremes(data: DateBucket[]): [Date, Date] {
  const dates = data.map((x) => x.date)
  return [getMinDate(dates), getMaxDate(dates)]
}
