import { PlaySnippetsButton, Spinner } from '@capturi/ui-components'
import { Flex } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'

export const NUMBER_OF_PHRASES = 1000

export const PreviewTrackerHitsPlayButton: React.FC<{
  hits: number
  isFetching?: boolean
  onViewConversations: () => void
}> = ({ hits, isFetching, onViewConversations }) => {
  return (
    <>
      {isFetching ? (
        <Flex align="center" justify="flex-end" minH={6} ml={2}>
          <Spinner w="24px" h="24px" delay={0} />
        </Flex>
      ) : (
        <PlaySnippetsButton
          ml={2}
          disabled={hits <= 0}
          label={t`View conversations`}
          onClick={() => onViewConversations()}
        />
      )}
    </>
  )
}
