import WavesBackgroundFileUrl from '@capturi/assets/images/waves_bgAnimated.svg'
import { Box } from '@chakra-ui/react'
import React from 'react'

const WavesBackground: React.FC = () => {
  return (
    <Box pos="absolute" bottom={0} left={0} right={0} aria-hidden width="100vw">
      <WavesBackgroundFileUrl />
    </Box>
  )
}

export default WavesBackground
