import analytics from '@capturi/analytics'
import { useSummaries } from '@capturi/api-summaries'
import {
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import { EmptyStateIcon } from '@capturi/react-utils'
import { Button, ContentPlaceholder, Emoji } from '@capturi/ui-components'
import {
  Box,
  Divider,
  Flex,
  HStack,
  Heading,
  IconButton,
  Skeleton,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useCallback, useRef } from 'react'
import { MdArrowUpward } from 'react-icons/md'

import { SummaryItem } from './SummaryItem'

const PAGE_LIMIT = 10

export const SummaryOverview: React.FC = () => {
  const segmentState = useFirstPhoneSegmentState()
  const { period } = useFilterPeriodContext()
  const filter = segmentState.values

  const scrollToRef = useRef<HTMLDivElement | null>(null)

  const {
    isLoading,
    error,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useSummaries(filter, period, PAGE_LIMIT, true)

  const handleLoadMoreClicked = useCallback((): void => {
    fetchNextPage()

    analytics.event('summaryList_loadMore')
  }, [fetchNextPage])

  const isEmpty = !data?.pages?.[0].length

  if (isEmpty) {
    return (
      <Flex mt={2} direction="column" alignItems="center">
        <Emoji symbol="👀" fontSize="70px" />
        <Text fontSize="lg">
          <Trans>No results</Trans>
        </Text>
        <Text fontSize="sm" color="textMuted" textAlign="center">
          <Trans>
            Try to increase the period up in the right corner or make your
            search terms broader
          </Trans>
        </Text>
      </Flex>
    )
  }

  if (error) {
    return (
      <ContentPlaceholder.Container mt={20}>
        <EmptyStateIcon />
        <ContentPlaceholder.Heading>
          <Trans>Whoops! There has been an error</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>Try reloading the page</Trans>
        </ContentPlaceholder.Body>
        <ContentPlaceholder.Footer>
          <Button primary onClick={() => location.reload()}>
            <Trans>Reload page</Trans>
          </Button>
        </ContentPlaceholder.Footer>
      </ContentPlaceholder.Container>
    )
  }

  return (
    <Box w="960px" m="0 auto" height="100%" pb={8}>
      <VStack gap="1" h="100%">
        <HStack
          ref={scrollToRef}
          w="100%"
          alignItems="center"
          minH="28px"
          justifyContent="space-between"
        >
          <Heading fontSize="xl" w="121px">
            <Trans>Summaries</Trans>
          </Heading>
          <Text fontSize="xs" color="gray.400">
            <Trans>Generated by Capturi AI</Trans>
          </Text>
        </HStack>

        <Box h="100%" w="100%">
          {isLoading ? (
            <>
              <Skeleton h="28px" w="185px" />
              <Skeleton h="28px" w="185px" />
              <Skeleton h="28px" w="185px" />
              <Skeleton h="28px" w="185px" />
            </>
          ) : (
            <>
              {(data?.pages ?? []).map((d, idx) =>
                d.map((c, index) => {
                  return (
                    <React.Fragment key={c.uid}>
                      <SummaryItem summaryData={c} />
                      {index + 1 === d.length &&
                        data.pages.length > idx + 1 && (
                          <Flex
                            flexDir="row"
                            alignItems="center"
                            position="relative"
                            py={6}
                            mt="-16px"
                            gap={4}
                          >
                            <Divider borderColor="gray.300" />
                            <Text as="span" color="gray.600" flex="1 0 auto">
                              <Trans>Page {idx + 2}</Trans>
                            </Text>
                            <Divider borderColor="gray.300" />
                          </Flex>
                        )}
                    </React.Fragment>
                  )
                }),
              )}
            </>
          )}
        </Box>
        <Button
          onClick={handleLoadMoreClicked}
          isDisabled={!hasNextPage || isFetchingNextPage}
          isLoading={isFetchingNextPage}
        >
          {!hasNextPage ? (
            <Trans>No more conversations</Trans>
          ) : (
            <Text>
              <Trans>Load more</Trans>
            </Text>
          )}
        </Button>
        {data?.pages.length > 1 && (
          <Tooltip label={t`Scroll to top`} hasArrow placement="top">
            <IconButton
              onClick={() =>
                scrollToRef?.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'end',
                })
              }
              mt={4}
              aria-label={t`Thumbs down`}
              rounded="100%"
              color="gray.800"
              icon={<MdArrowUpward />}
              size="md"
            />
          </Tooltip>
        )}
      </VStack>
    </Box>
  )
}
