import { MessageFilters, TextGlobalSettings } from '@capturi/api-trackers'
import {
  ChipItem,
  ChipLabel,
  ChipRemoveButton,
  ChipsInput,
  ChipsItemRenderer,
  FormLabel,
} from '@capturi/ui-components'
import {
  Box,
  Button,
  Center,
  Collapse,
  Divider,
  Flex,
  HStack,
  Icon,
  Text,
  Tooltip,
  VStack,
  chakra,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans, t } from '@lingui/macro'
import React, { useCallback, useState } from 'react'
import {
  MdClose,
  MdMyLocation,
  MdSpeakerNotes,
  MdSpeakerNotesOff,
} from 'react-icons/md'

import { duplicatePhraseTooltipMsg } from '../../../messages'
import { useDuplicateTextPhrases } from '../../../usePhraseFields'
import CaseMessageFiltersFields from '../../CaseMessageFiltersFields'
import { logEvent } from '../logEvent'

type ActiveMenuProps = {
  near: boolean
  notNear: boolean
}
const TextPhrasesSimpleView: React.FC<{
  words: string[]
  onWordChange: (words: string[]) => void
  nearness: TextGlobalSettings
  onNearnessChange: (nearness: TextGlobalSettings) => void
  messageFilters: MessageFilters | null
  onMessageFiltersChange: (messageFilters: MessageFilters | null) => void
}> = ({
  words,
  onWordChange,
  nearness,
  onNearnessChange,
  messageFilters,
  onMessageFiltersChange,
}) => {
  const { hasDuplicates, isDuplicate } = useDuplicateTextPhrases()
  const [activeMenu, setActiveMenu] = useState<ActiveMenuProps>({
    near: !!nearness.near.length,
    notNear: !!nearness.notNear.length,
  })
  const handleValuesAdded = (newValues: string[]): void => {
    newValues.forEach((value) => {
      logEvent('addCasePhraseField', false, {
        numberOfWords: value.split(' ').length,
      })
    })
    onWordChange([...words, ...newValues.map((v) => v.trim())])
  }

  const handleNearValuesAdded = (newValues: string[]): void => {
    newValues.forEach((value) => {
      logEvent('addNearPhraseField', false, {
        numberOfWords: value.split(' ').length,
      })
    })
    onNearnessChange({
      near: [...nearness.near, ...newValues],
      notNear: [...nearness.notNear],
    })
  }

  const handleNotNearValuesAdded = (newValues: string[]): void => {
    newValues.forEach((value) => {
      logEvent('addNotNearPhraseField', false, {
        numberOfWords: value.split(' ').length,
      })
    })
    onNearnessChange({
      near: [...nearness.near],
      notNear: [...nearness.notNear, ...newValues],
    })
  }

  const toggleMenu = (key: string): void => {
    setActiveMenu({
      ...activeMenu,
      [key]: true,
    })
  }

  const handleNearValueRemoved = (value: string): void => {
    onNearnessChange({
      near: nearness.near.filter((x) => x !== value),
      notNear: [...nearness.notNear],
    })
  }

  const handleNotNearValueRemoved = (value: string): void => {
    onNearnessChange({
      near: [...nearness.near],
      notNear: nearness.notNear.filter((x) => x !== value),
    })
  }

  const handleValueRemoved = (_value: string, index: number): void => {
    const newArray = [...words]
    newArray.splice(index, 1)
    onWordChange(newArray)
  }

  const emptyPhrasesField = (): void => {
    onWordChange([])
  }
  const emptyNearField = (): void => {
    onNearnessChange({
      near: [],
      notNear: [...nearness.notNear],
    })
  }
  const emptyNotNearField = (): void => {
    onNearnessChange({
      near: [...nearness.near],
      notNear: [],
    })
  }

  const removeLatestPhraseFieldsDuplicates = (): string[] => {
    const uniqueWords = [...new Set(words)]
    onWordChange(uniqueWords)
    return uniqueWords
  }

  const renderItem: ChipsItemRenderer = useCallback(
    (word, words, onRemove) => {
      const hasDuplicates = isDuplicate(word)
      return (
        <ChipItem colorScheme={hasDuplicates ? 'red' : 'gray'} variant="subtle">
          <Tooltip
            label={hasDuplicates ? duplicatePhraseTooltipMsg() : null}
            isDisabled={!hasDuplicates}
            hasArrow
            openDelay={300}
          >
            <Box>
              <ChipLabel>
                {words.map((word, i) => {
                  return (
                    <chakra.span
                      key={i}
                      css={css`
                        :not(:last-child) {
                          ::after {
                            content: ' ';
                          }
                        }
                      `}
                    >
                      {word}
                    </chakra.span>
                  )
                })}
              </ChipLabel>
            </Box>
          </Tooltip>
          <ChipRemoveButton onClick={() => onRemove()} />
        </ChipItem>
      )
    },
    [isDuplicate],
  )

  return (
    <Flex>
      <VStack w="100%">
        <CaseMessageFiltersFields
          messageFilters={messageFilters}
          onMessageFiltersChange={onMessageFiltersChange}
        />
        <Divider orientation="horizontal" h={1} />
        <Flex w="100%">
          <Box flex="70%" py={2}>
            <FormLabel
              data-stonly="tracker_phrases--simple-view"
              leftIcon={<MdMyLocation />}
            >
              <Trans>Track these words/phrases</Trans>
              <Text color="gray.600" fontSize="sm">
                <Trans>
                  It is not necessary to write several inflections of the same
                  word.
                </Trans>
              </Text>
            </FormLabel>

            <ChipsInput
              value={words}
              onValuesAdded={handleValuesAdded}
              onValueRemoved={handleValueRemoved}
              onClearValue={emptyPhrasesField}
              hasDuplicates={hasDuplicates}
              removeLatestDuplicates={removeLatestPhraseFieldsDuplicates}
              inputPlaceholderText={t`Add word/phrase`}
              renderItem={renderItem}
              maxSentenceLength={null}
            />
          </Box>
          <Center mx={8}>
            <Divider h="100%" orientation="vertical" />
          </Center>
          <Box flex="30%">
            <Box>
              <FormLabel p={0} m={0}>
                <Trans>Add context</Trans>
              </FormLabel>
              <Text color="gray.600" fontSize="sm" mb={4}>
                <Trans>
                  Add words/phrases that should or shouldn’t be mentioned near
                  in order for the tracker to hit.
                </Trans>
              </Text>
            </Box>
            <Button
              mb={2}
              cursor="pointer"
              onClick={() => toggleMenu('near')}
              display={activeMenu.near ? 'none' : 'flex'}
            >
              <Icon as={MdSpeakerNotes} />
              <Text ml={1}>
                <Trans>Add words that should be near</Trans>
              </Text>
            </Button>
            <Collapse in={activeMenu.near}>
              <HStack alignItems="flex-start">
                <FormLabel p={0} m={0} fontSize="small">
                  <Trans>
                    The tracker MUST appear near at least one of these
                    words/phrases
                  </Trans>
                </FormLabel>
                <Icon
                  cursor="pointer"
                  as={MdClose}
                  onClick={() => {
                    setActiveMenu({ ...activeMenu, near: false })
                    onNearnessChange({ near: [], notNear: nearness.notNear })
                  }}
                />
              </HStack>
              <Box minH="5em" mb={4}>
                <ChipsInput
                  value={nearness.near}
                  onValuesAdded={handleNearValuesAdded}
                  onValueRemoved={handleNearValueRemoved}
                  onClearValue={emptyNearField}
                  hasDuplicates={false}
                  removeLatestDuplicates={removeLatestPhraseFieldsDuplicates}
                  inputPlaceholderText={t`Add word/phrase`}
                  renderItem={renderItem}
                  maxSentenceLength={null}
                />
              </Box>
            </Collapse>
            <Button
              mb={2}
              size="sm"
              cursor="pointer"
              onClick={() => toggleMenu('notNear')}
              display={activeMenu.notNear ? 'none' : 'flex'}
            >
              <Icon as={MdSpeakerNotesOff} />
              <Text ml={1}>
                <Trans>Add words that should NOT be near</Trans>
              </Text>
            </Button>
            <Collapse in={activeMenu.notNear}>
              <HStack alignItems="flex-start">
                <FormLabel p={0} m={0} fontSize="small">
                  <Trans>
                    The tracker may NOT appear near at least one of these
                    words/phrases
                  </Trans>
                </FormLabel>
                <Icon
                  cursor="pointer"
                  as={MdClose}
                  onClick={() => {
                    setActiveMenu({ ...activeMenu, notNear: false })
                    onNearnessChange({ near: nearness.near, notNear: [] })
                  }}
                />
              </HStack>
              <Box minH="5em" mb={4}>
                <ChipsInput
                  value={nearness.notNear}
                  onValuesAdded={handleNotNearValuesAdded}
                  onValueRemoved={handleNotNearValueRemoved}
                  onClearValue={emptyNotNearField}
                  hasDuplicates={false}
                  removeLatestDuplicates={removeLatestPhraseFieldsDuplicates}
                  inputPlaceholderText={t`Add word/phrase`}
                  renderItem={renderItem}
                  maxSentenceLength={null}
                />
              </Box>
            </Collapse>
          </Box>
        </Flex>
      </VStack>
    </Flex>
  )
}

export default TextPhrasesSimpleView
