import {
  SpeakerTrackerHitsResponseModel,
  insightsAPI,
} from '@capturi/api-insights'
import {
  Segment,
  createPeriodParams,
  segmentConfigurations,
  useFetchPhoneSegments,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import request, { ResponseError } from '@capturi/request'
import { UseQueryOptions, useQueries } from '@tanstack/react-query'
import { useMemo, useRef } from 'react'
import isEqual from 'react-fast-compare'

import { POLL_INTERVAL } from './constants'

export type TrackerStats = {
  hits: number
  hitPercentage: number
  totalTargets: number
}

export type TextTrackerHitsResponseModel = {
  cases: number
  trackers: {
    casesWithHit: number
    casesWithHitPercent: number
    trackerUid: string
  }[]
}

type ChannelSegment =
  | {
      channel: 'phone'
      index: number
      segment: Segment<SpeakerTrackerHitsResponseModel>
    }
  | {
      channel: 'email'
      index: number
      segment: Segment<TextTrackerHitsResponseModel>
    }

function useStableValue<T>(value: T): T {
  const ref = useRef<T>(value)

  if (!isEqual(ref.current, value)) {
    ref.current = value
  }

  return ref.current
}

export const useSegmentOverview = (
  shouldPollForUpdates?: boolean,
): {
  revalidate: () => void
  isLoading: boolean
  error: ResponseError | null | undefined
  getSegments: (trackerUid: string) => Segment<TrackerStats>[]
  segments: (
    | Segment<SpeakerTrackerHitsResponseModel>
    | Segment<TextTrackerHitsResponseModel>
  )[]
} => {
  const {
    segments: phoneSegments,
    error,
    revalidate,
    isLoading,
  } = useFetchPhoneSegments<SpeakerTrackerHitsResponseModel>(
    () => insightsAPI.getSpeakerTrackerHits(),
    {
      refetchInterval: shouldPollForUpdates ? POLL_INTERVAL : false,
    },
  )

  const { periodDef } = useFilterPeriodContext()
  const { phoneSegmentStates, textSegmentStates, getIndexForState } =
    useSegmentStatesContext()
  const textSegmentsResponses = useQueries({
    queries: textSegmentStates.map(
      (filter): UseQueryOptions<TextTrackerHitsResponseModel, Error> => ({
        queryKey: ['insights', 'trackers', 'text', filter.values, periodDef],
        queryFn: () =>
          request.post<TextTrackerHitsResponseModel>(
            'insights/trackers/text?api-version=3.3',
            {
              json: {
                ...filter.values,
                ...createPeriodParams(periodDef.create(new Date())),
              },
            },
          ),
        refetchInterval: shouldPollForUpdates ? POLL_INTERVAL : false,
      }),
    ),
  })
  const textSegmentsResults = useMemo(() => {
    return textSegmentsResponses.map(({ isLoading, error, data }, index) => ({
      index,
      isLoading,
      error,
      data,
    }))
  }, [textSegmentsResponses])

  const stablePhoneSegments = useStableValue(phoneSegments)
  const stableTextSegments = useStableValue(textSegmentsResults)

  const { sortedSegments, segmentHitsByUid } = useMemo(() => {
    const textSegments: Segment<TextTrackerHitsResponseModel>[] =
      stableTextSegments.map((x) => {
        const segmentIndex = getIndexForState(textSegmentStates[x.index])
        return {
          color: segmentConfigurations[segmentIndex].color,
          label: segmentConfigurations[segmentIndex].label,
          data: x.data ? x.data : null,
        }
      })

    const segments = [
      ...stablePhoneSegments.map<ChannelSegment>((x, index) => ({
        channel: 'phone',
        index: getIndexForState(phoneSegmentStates[index]),
        segment: x,
      })),
      ...textSegments.map<ChannelSegment>((x, index) => ({
        channel: 'email',
        index: getIndexForState(textSegmentStates[index]),
        segment: x,
      })),
    ]

    const sortedSegments = segments.sort((a, b) => a.index - b.index)

    const segmentHitsByUid = new Map<string, Segment<TrackerStats>[]>()

    // Fill out memo with empty data
    sortedSegments.forEach((channelSegment) => {
      channelSegment.segment.data?.trackers.forEach((d) => {
        if (!segmentHitsByUid.has(d.trackerUid)) {
          segmentHitsByUid.set(
            d.trackerUid,
            sortedSegments.map((channelSegment) => ({
              color: channelSegment.segment.color,
              label: channelSegment.segment.label,
              data: {
                hits: 0,
                hitPercentage: 0,
                totalTargets: 0,
              },
            })),
          )
        }
      })
    })

    // Now go through sortedSegments and fill in the existing data
    sortedSegments.forEach((channelSegment) => {
      if (
        channelSegment.segment.data == null ||
        channelSegment.segment.data.trackers.length === 0
      ) {
        return
      }

      if (channelSegment.channel === 'phone') {
        channelSegment.segment.data.trackers.forEach((d) => {
          const c = segmentHitsByUid.get(d.trackerUid)
          if (!c) return
          c[channelSegment.index].data = {
            hits: d.conversationsWithHit,
            hitPercentage: d.conversationsWithHitPercent / 100,
            totalTargets: channelSegment.segment.data?.conversations ?? 0,
          }
        })
      } else if (channelSegment.channel === 'email') {
        channelSegment.segment.data.trackers.forEach((d) => {
          const c = segmentHitsByUid.get(d.trackerUid)
          if (!c) return
          c[channelSegment.index].data = {
            hits: d.casesWithHit,
            hitPercentage: d.casesWithHitPercent / 100,
            totalTargets: channelSegment.segment.data?.cases ?? 0,
          }
        })
      }
    })
    return { sortedSegments, segmentHitsByUid }
  }, [
    stablePhoneSegments,
    phoneSegmentStates,
    stableTextSegments,
    textSegmentStates,
    getIndexForState,
  ])

  return useMemo(() => {
    return {
      isLoading,
      revalidate,
      error,
      getSegments: (trackerUid: string) =>
        segmentHitsByUid.get(trackerUid) || [],
      segments: sortedSegments.map((x) => x.segment),
    }
  }, [segmentHitsByUid, sortedSegments, isLoading, revalidate, error])
}
