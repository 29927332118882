import { Button } from '@capturi/ui-components'
import {
  Box,
  Fade,
  Flex,
  Icon,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { ReactElement, useState } from 'react'
import { MdArrowBackIos, MdWarning } from 'react-icons/md'

import { FilterCriteriaComponentBaseProps } from '../../components/PhoneFilter/components/PhoneSegmentBuilder'

type CustomerInputProps = FilterCriteriaComponentBaseProps<string[]> & {
  initialFocusRef?: React.RefObject<HTMLInputElement>
}

const CUSTOMERS_TO_SHOW = 20

export function CustomerInput(props: CustomerInputProps): React.ReactElement {
  const inputRef = props.initialFocusRef
  const [state, setState] = useState<string[]>(props.value || [])
  const [attemptedDuplicate, setAttemptedDuplicate] = useState<boolean>(false)
  const [newPhrase, setNewPhrase] = useState<string>('')

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>): void => {
    event.preventDefault()
    const text = event.clipboardData.getData('Text')

    const numbers = text.split(/,|\s/) // we split a , and any whitespace
    const s = new Set(state)
    const filteredNumbers = numbers.filter(
      (n, index) => n && !s.has(n) && numbers.indexOf(n) === index,
    )

    const newState = [...state, ...filteredNumbers]
    setState(newState)
    setNewPhrase('')
    inputRef?.current?.focus()
  }

  const handleAdd = (): void => {
    if (state.includes(newPhrase)) {
      setAttemptedDuplicate(true)
      setNewPhrase('')
      return
    }
    if (newPhrase) {
      setState([...state, newPhrase])
      setAttemptedDuplicate(false)
      setNewPhrase('')
    }
  }
  const handleOk = (): void => {
    if (state.includes(newPhrase)) {
      setAttemptedDuplicate(true)
      setNewPhrase('')
      return
    }
    if (newPhrase) {
      setState([...state, newPhrase])
      setAttemptedDuplicate(false)
      setNewPhrase('')
    }
    props.setValue?.(state)
    props.onClose?.()
  }

  const handleReset = (): void => {
    if (newPhrase) {
      setNewPhrase('')
    }
    setState([])
    props.setValue?.([])
    setAttemptedDuplicate(false)
  }

  const removePhrase = (index: number): void => {
    const newState = [...state]
    newState.splice(index, 1)
    setState(newState)
    inputRef?.current?.focus()
  }

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (newPhrase && ['Enter', ',', 'Tab', '.', ' '].includes(e.key)) {
      e.preventDefault()
      handleAdd()
    }
  }

  const renderPhrase = (phrase: string, index: number): ReactElement => {
    return (
      <Tag m="1" justifyContent="space-between" key={index} borderRadius="999">
        <TagLabel>{phrase}</TagLabel>
        <TagCloseButton onClick={() => removePhrase(index)} />
      </Tag>
    )
  }

  return (
    <Box>
      <Box p="2" pb="0">
        <Flex
          onClick={() => inputRef?.current?.focus()}
          border="solid 1px"
          borderColor="border.light"
          borderRadius="md"
          height="100%"
          transition="200ms padding ease-out"
          padding={1}
          justify="space-between"
          flexDirection="column"
          _focusWithin={{
            borderColor: 'primary.500',
            boxShadow: '0 0 0 1px var(--chakra-colors-primary-500)',
          }}
        >
          <Flex wrap="wrap" alignItems={'center'}>
            {state
              .slice(Math.max(state.length - CUSTOMERS_TO_SHOW, 0)) // this gives the last 20 items in the list
              .map(renderPhrase)}
            <Box
              as="input"
              fontSize="sm"
              px={2}
              my="6px"
              outline={0}
              placeholder={t`Phone number...`}
              ref={inputRef}
              onBlur={handleAdd}
              onKeyDown={handleKeypress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewPhrase(e.target.value)
              }
              value={newPhrase}
              onPaste={handlePaste}
            />
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          color="primary.300"
          mt="1"
        >
          <Text fontStyle="italic" color="info" fontSize="sm">
            <Trans>You can also paste in a list of phone numbers</Trans>
          </Text>
          <Text fontStyle="italic" color="info" fontSize="sm">
            <Trans>
              Add <Icon as={MdArrowBackIos} transform="rotate(90deg)" /> to
              locate numbers starting with a specific pattern. E.g.{' '}
              <Icon as={MdArrowBackIos} transform="rotate(90deg)" />
              +45
            </Trans>
          </Text>
          <Fade in={attemptedDuplicate}>
            <Text
              as="span"
              color="info"
              fontSize="xs"
              display="flex"
              alignItems="center"
            >
              <Trans>Duplicated phone numbers are not allowed</Trans>
              <Icon ml={1} as={MdWarning} boxSize="3 !important" />
            </Text>
          </Fade>
        </Flex>
      </Box>
      <Flex
        p="2"
        justifyContent="space-between"
        mt="3"
        alignItems="center"
        boxShadow="0 -4px 8px -6px rgba(0,0,0,0.2)"
      >
        {state.length > CUSTOMERS_TO_SHOW ? (
          <Text fontSize="sm">{t`Showing ${CUSTOMERS_TO_SHOW} / ${state.length}`}</Text>
        ) : (
          <Text fontSize="sm">
            {state.length} <Trans>selected</Trans>
          </Text>
        )}
        <Flex>
          <Button onClick={handleReset} variant="ghost">
            <Trans>Reset</Trans>
          </Button>
          <Button onClick={handleOk} ml="2" primary variant="ghost">
            <Trans>OK</Trans>
          </Button>
        </Flex>
      </Flex>
    </Box>
  )
}
