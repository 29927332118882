import { YAxisProps } from 'recharts'

export const roundFractionToNearest = (
  fraction: number,
  nearestFraction: number,
): number => {
  const scaledFraction = fraction * 100
  const scaledNearestFraction = nearestFraction * 100
  const scaledResult =
    Math.ceil(scaledFraction / scaledNearestFraction) * scaledNearestFraction
  const result = scaledResult / 100
  if (result === fraction) {
    return result + nearestFraction
  }
  return result
}

/**
 * default domain in recharts is [0, 'auto']
 * 'auto', however, generates som inappropriate values.
 * This is a custom implementation mimicking 'auto'
 */

export const pctAutoDomain: NonNullable<
  Exclude<
    YAxisProps['domain'],
    (
      [dataMin, dataMax]: [number, number],
      allowDataOverflow: boolean,
    ) => [number, number]
  >
> = [
  0,
  (dataMax: number): number => {
    if (dataMax === 0) return 1
    if (dataMax < 0.01) return 0.01
    if (dataMax < 0.1) return roundFractionToNearest(dataMax, 0.02)
    if (dataMax < 0.5) return roundFractionToNearest(dataMax, 0.05)
    return Math.min(1, roundFractionToNearest(dataMax, 0.1))
  },
]
