import { conversationsAPI } from '@capturi/api-conversations'
import { ResponseError, downloadBlob } from '@capturi/request'
import { useToast } from '@capturi/ui-components'
import { t } from '@lingui/macro'
import { useCallback } from 'react'

const useDownloadTranscription = (
  conversationUid: string,
): ((anonymize: boolean) => Promise<void>) => {
  const toast = useToast()

  const handleDownloadTranscription = useCallback(
    async (anonymize: boolean) => {
      if (!conversationUid) {
        return
      }

      try {
        toast({
          title: anonymize
            ? t`Downloading anonymized transcription`
            : t`Downloading transcription`,
          status: 'info',
        })
        await downloadBlob(
          conversationsAPI.downloadTranscription(conversationUid, anonymize),
          `transcription-${conversationUid.slice(0, 4)}${
            anonymize ? '-anonymized' : ''
          }.txt`,
        )
        toast({
          title: t`Download complete`,
          status: 'success',
        })
      } catch (error) {
        toast({
          title: t`Download failed`,
          status: 'error',
          description:
            error instanceof ResponseError ? error.message : undefined,
        })
      }
    },
    [toast, conversationUid],
  )

  return handleDownloadTranscription
}

export default useDownloadTranscription
