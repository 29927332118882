import request, { ResponseError } from '@capturi/request'
import {
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryResult,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query'

import { InaccessibleSavedTextFilter, SavedTextFilter } from '../../models'
import { url, cacheKey } from './constants'

export type SavedTextFilterResponse = {
  filters: SavedTextFilter[]
}

type InaccessibleSavedTextFilterResponse = {
  filters: (SavedTextFilter | InaccessibleSavedTextFilter)[]
}

const selectorOnlyAccessible = (
  data: SavedTextFilterResponse,
): SavedTextFilter[] => {
  return data.filters.filter((d) => d.accessLevel !== 'None')
}

const selectorAll = (
  data: InaccessibleSavedTextFilterResponse,
): (SavedTextFilter | InaccessibleSavedTextFilter)[] => {
  return data.filters
}

const selectorOneAccessible =
  (
    filterUid: string | null | undefined,
  ): ((data: SavedTextFilterResponse) => SavedTextFilter | undefined) =>
  (data) => {
    return filterUid != null
      ? data.filters.find(
          (f) => f.uid === filterUid && f.accessLevel !== 'None',
        )
      : undefined
  }

export const useSavedTextFilter = ({
  uid,
  options,
}: {
  uid: string | null | undefined
  options?: Partial<
    UseQueryOptions<
      SavedTextFilterResponse,
      ResponseError,
      SavedTextFilter | undefined
    >
  >
}): UseQueryResult<SavedTextFilter | undefined, ResponseError> =>
  useQuery({
    queryKey: [cacheKey],
    queryFn: () =>
      request.get<SavedTextFilterResponse>(`${url}&includeInaccessible=true`),
    select: selectorOneAccessible(uid),
    enabled: !!uid,
    ...options,
  })

export const useSavedTextFilters = (): UseSuspenseQueryResult<
  SavedTextFilter[],
  ResponseError
> =>
  useSuspenseQuery({
    queryKey: [cacheKey],
    queryFn: () =>
      request.get<SavedTextFilterResponse>(`${url}&includeInaccessible=true`),
    select: selectorOnlyAccessible,
  })

export const useAllSavedTextFilters = ({
  enabled = true,
}: {
  enabled?: boolean
} = {}): UseQueryResult<
  (SavedTextFilter | InaccessibleSavedTextFilter)[],
  ResponseError
> =>
  useQuery({
    queryKey: [cacheKey],
    queryFn: () =>
      request.get<InaccessibleSavedTextFilterResponse>(
        `${url}&includeInaccessible=true`,
      ),
    select: selectorAll,
    enabled,
  })
