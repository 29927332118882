import {
  FlexProps,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tooltip,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { MdInfo } from 'react-icons/md'

const ProcessingTag: React.FC<
  FlexProps & { label?: string; tooltipLabel?: string }
> = ({ label = t`Processing`, tooltipLabel, ...props }) => {
  return (
    <Tooltip label={tooltipLabel} isDisabled={!tooltipLabel} hasArrow>
      <Tag size="sm" bg="warning" color="white" alignItems="center" {...props}>
        <TagLeftIcon as={MdInfo} mr={1} />
        <TagLabel lineHeight={1}>{label}</TagLabel>
      </Tag>
    </Tooltip>
  )
}

export default ProcessingTag
