import { Button } from '@capturi/ui-components'
import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Text,
  useTheme,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans, t } from '@lingui/macro'
import { FC, memo, useEffect, useState } from 'react'
import { MdSync } from 'react-icons/md'

const KeyTopicsSummaryExample: FC<{
  title: string
  description: string
}> = ({ title, description }) => {
  const theme = useTheme()
  return (
    <Card
      height="100%"
      minH={32}
      css={css`&:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -1px;
        border-radius: inherit;
        opacity: 0.5;
        background: ${theme.colors.aiGradient};
      }`}
    >
      <CardHeader
        pl={4}
        pr={1}
        pb={0}
        pt={4}
        fontSize="lg"
        fontWeight="medium"
        color="gray.600"
      >
        {title}
      </CardHeader>
      <CardBody pl={4} pr={1} pb={6} pt={2} fontSize="sm">
        {description}
      </CardBody>
    </Card>
  )
}

const sample3ItemsFromArray = (array: string[]): string[] => {
  if (array.length <= 3) {
    return array
  }

  const pickedIndices = new Set<number>()
  while (pickedIndices.size < 3) {
    pickedIndices.add(Math.floor(Math.random() * array.length))
  }
  return Array.from(pickedIndices).map((index) => array[index])
}

export const KeyTopicsExamples: FC<{ examples: string[] }> = memo(
  ({ examples }) => {
    const [shuffledExamples, setShuffledExamples] = useState<string[]>(() =>
      sample3ItemsFromArray(examples),
    )

    useEffect(() => {
      setShuffledExamples(sample3ItemsFromArray(examples))
    }, [examples])

    const shuffleExamples = () => {
      setShuffledExamples(sample3ItemsFromArray(examples))
    }

    return (
      <Flex direction="column" id="examples" my={6} gap={2}>
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize="sm" fontWeight="medium">
            <Trans>Key topic summary examples</Trans>
          </Text>
          <Button leftIcon={<MdSync />} size="xs" onClick={shuffleExamples}>
            <Trans>Shuffle</Trans>
          </Button>
        </Flex>
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          {shuffledExamples.map((example, index) => (
            <GridItem key={`${index}-${example.substring(0, 30)}`}>
              <KeyTopicsSummaryExample
                title={t`Example ${index + 1}`}
                description={example}
              />
            </GridItem>
          ))}
        </Grid>
      </Flex>
    )
  },
)
