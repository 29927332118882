import { Description, FormLabel } from '@capturi/ui-components'
import { Box } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { MdMyLocation } from 'react-icons/md'

type Props = {
  MaxWordsPerPhrase: number
  dataStonly: string
}

const PhrasesLabel: React.FC<Props> = ({ MaxWordsPerPhrase, dataStonly }) => {
  return (
    <Box>
      <FormLabel
        htmlFor="tracker_phrases_cases"
        leftIcon={<MdMyLocation />}
        data-stonly={dataStonly}
        mb={0}
      >
        <Trans>Track these words/phrases</Trans>
      </FormLabel>
      <Description fontSize="sm" mb={4}>
        <Trans>
          Max {MaxWordsPerPhrase} words per phrase. It is not necessary to write
          several inflections of the same word.
        </Trans>
      </Description>
    </Box>
  )
}

export default PhrasesLabel
