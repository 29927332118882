import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

export type JoinProps<T> = {
  values: T[]
  children: (item: T) => React.ReactNode
  separator?:
    | string
    | ((isLastSeparator: boolean, itemIndex: number) => React.ReactNode)
} & Omit<BoxProps, 'children'>

export function Join<T>({
  values,
  children: renderItem,
  separator = ', ',
  ...boxProps
}: JoinProps<T>): React.ReactElement {
  const separatorFn =
    typeof separator === 'function' ? separator : () => separator
  return (
    <Box as="span" {...boxProps}>
      {values.map((x, i) => (
        <React.Fragment key={i}>
          {renderItem(x)}
          {i < values.length - 2 && separatorFn(false, i)}
          {i === values.length - 2 && separatorFn(true, i)}
        </React.Fragment>
      ))}
    </Box>
  )
}
