import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'

export const AnimateChangeInHeight: React.FC<{
  children: React.ReactNode
  overflowYVisble?: boolean | null
}> = ({ children, overflowYVisble }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [height, setHeight] = useState<number | 'auto'>('auto')

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        // We only have one entry, so we can use entries[0].
        const observedHeight = entries[0].contentRect.height
        setHeight(observedHeight)
      })

      resizeObserver.observe(containerRef.current)

      return () => {
        // Cleanup the observer when the component is unmounted
        resizeObserver.disconnect()
      }
    }
  }, [])

  return (
    <motion.div
      style={{
        height,
        overflowY: overflowYVisble ? 'visible' : 'hidden',
      }}
      animate={{ height }}
      transition={{
        type: 'spring',
        duration: 0.25,
        bounce: 0.1,
        when: 'afterChildren',
      }}
    >
      <div ref={containerRef}>{children}</div>
    </motion.div>
  )
}
