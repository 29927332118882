import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Flex, Link, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

type Props = BaseModalComponentProps
const READ_MORE_LINK = 'https://capturi.stonly.com/kb/da/brugerstyring-224082'

const LinkButton: React.FC<ButtonProps & { href: string }> = (props) => {
  return <Button as={Link} textDecoration="none" {...props} />
}

const AuthorizationErrorModal: React.FC<Props> = ({ onClose }) => {
  return (
    <Modal isOpen={true} onClose={onClose} closeOnOverlayClick={true}>
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader>
              <Trans>No access to this organization</Trans>
            </ModalHeader>
          </Flex>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              <Trans>
                You do not have access to this organization. You have been
                redirected to your last used organization.
              </Trans>
            </Text>
          </ModalBody>

          <ModalFooter>
            <LinkButton href={READ_MORE_LINK}>
              <Trans>Read more about authorization</Trans>
            </LinkButton>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default AuthorizationErrorModal
