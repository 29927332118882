import { Spinner as ChakraSpinner, SpinnerProps } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

const Spinner: React.FC<
  SpinnerProps & {
    delay?: number
  }
> = ({ delay = 500, ...restProps }) => {
  const [show, setShow] = useState(delay === 0)

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), delay)
    return (): void => {
      clearTimeout(timer)
    }
  }, [delay])

  if (show) {
    return (
      <ChakraSpinner
        speed="0.65s"
        emptyColor="gray.200"
        color="primary.500"
        {...restProps}
      />
    )
  }
  return null
}

export default Spinner
