import { useMyOrganizations } from '@capturi/api-me'
import { AuthService } from '@capturi/auth'
import { useCurrentUser } from '@capturi/core'
import {
  Box,
  Flex,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useCallback, useMemo, useRef } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'

import OrganizationChooserList from './OrganizationChooserList'

// import { useMyOrganizations } from 'ca'

const OrganizationChooser: React.FC = () => {
  const initialRef = useRef<HTMLInputElement>(null)
  const user = useCurrentUser({ suspense: true })

  const { data: organizations = [] } = useMyOrganizations()

  const currentOrg = useMemo(
    () => organizations.find((org) => org.id === user.organizationId),
    [user.organizationId, organizations],
  )
  const handleChangeOrg = useCallback(async (orgUid: string): Promise<void> => {
    await AuthService.changeOrganization(orgUid)
  }, [])

  const hasMultipleOrgs = organizations.length > 1

  if (!hasMultipleOrgs) return null

  return (
    <Popover isLazy initialFocusRef={initialRef} placement="bottom-end">
      <PopoverTrigger>
        <Box
          as="button"
          outline={0}
          display="block"
          pr="2"
          borderRadius="lg"
          background="none"
          mr="4"
          ml="2"
          mb="4"
          border="1px solid"
          borderColor="gray.300"
          p="2"
          _active={{
            bg: 'gray.300',
          }}
          _hover={{
            bg: 'gray.200',
          }}
        >
          <Flex gap="2">
            <Box
              display="inline-block"
              w="4px"
              alignSelf="stretch"
              background="workspace.sidebar.darkbg"
              borderRadius="999px"
            />
            <VStack alignItems="start" gap="0" flexGrow="1">
              <Text fontSize="xs" color="gray.600">
                <Trans>Organisation</Trans>
              </Text>
              <Text
                fontSize="sm"
                noOfLines={1}
                textAlign="left"
                wordBreak="break-all"
                color="gray.800"
              >
                {currentOrg?.name}
              </Text>
            </VStack>
            <Icon
              as={MdKeyboardArrowDown}
              color="gray.800"
              boxSize="5"
              my="auto"
            />
          </Flex>
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <OrganizationChooserList
            inputRef={initialRef}
            current={currentOrg?.id || ''}
            orgs={organizations}
            handleChangeOrg={handleChangeOrg}
          />
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default OrganizationChooser
