import analytics from '@capturi/analytics'
import {
  SavedTextFilter,
  filtersAPI,
  useCreateSavedTextFilter,
  useUpdateSavedTextFilter,
} from '@capturi/api-filters'
import {
  Acl,
  PermissionPreset,
  PreviewAccessRequestModel,
  PreviewAccessResponse,
  useCurrentUser,
} from '@capturi/core'
import {
  PersonsWithAccess,
  ShareAndEditOptions,
  usePreviewAccess,
} from '@capturi/sharing'
import { Button } from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Divider,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import noop from 'lodash/noop'
import React from 'react'

import { getAcl, getPermissionPreset } from '../../utils/permissionHelpers'
import { Form, TitleInput } from './shared'

function getPreviewAccessWarningText(
  previewAccessResponse: PreviewAccessResponse | undefined,
): string | undefined {
  switch (previewAccessResponse) {
    case 'NoAccess':
      return t`*You will no longer be able to view the segment when you apply`
    case 'Editable':
      return undefined
    case 'Viewable':
      return t`*You will no longer be able to edit the segment when you apply because you set your own permission to “viewer” `
  }
}

export type CreateEditSegmentPayload = {
  uid: string
  name: string
  values: SavedTextFilter['values'] | undefined
  folderUid: string | null
  permissionPreset: PermissionPreset
  permissionPresetUserUid: string | null
  acl: Acl | null
}

export type CreateUpdateSegmentDialogOptions = {
  filter?: SavedTextFilter
  state?: SavedTextFilter['values']
  onFilterCreated?: (savedFilter: SavedTextFilter) => void
  onFilterUpdated?: (savedFilter: SavedTextFilter) => void
}

type CreateSegmentDialogProps = CreateUpdateSegmentDialogOptions &
  BaseModalComponentProps<CreateEditSegmentPayload>

export type CreateSegmentModel = CreateEditSegmentPayload & {
  hasWarnings?: boolean
}

export const CreateUpdateSavedTextFilterDialog: React.FC<
  CreateSegmentDialogProps
> = ({
  onClose,
  filter: filterProps,
  state: filterValues,
  onFilterCreated,
  onFilterUpdated,
}) => {
  const titleRef = React.useRef<HTMLInputElement>(null)
  const currentUser = useCurrentUser()
  const {
    mutate: updateFilter,
    isPending: isUpdateLoading,
    error: updateSaveFilterError,
  } = useUpdateSavedTextFilter()
  const { mutate: createFilter } = useCreateSavedTextFilter()

  const toast = useToast()

  // State
  const initialValues: CreateSegmentModel = {
    uid: filterProps?.uid ?? '',
    name: filterProps?.name ?? '',
    values: filterProps?.values ?? filterValues,
    folderUid: filterProps?.folderUid ?? null,
    permissionPreset: filterProps?.permissionPreset ?? 'Private',
    permissionPresetUserUid: filterProps?.permissionPresetUserUid ?? null,
    acl: filterProps?.acl ?? [],
  }
  const [state, _setState] = React.useState<CreateSegmentModel>(initialValues)

  const localizedEntityName = t`Segment`
  const showsACL = state.permissionPreset === 'Custom'

  const setState = React.useCallback(
    (partialState: Partial<CreateSegmentModel>): void => {
      _setState((state) => ({
        ...state,
        ...partialState,
        acl: getAcl(partialState, state, currentUser.id),
        permissionPresetUserUid: getPermissionPreset(
          partialState,
          state,
          currentUser.id,
        ),
      }))
    },
    [currentUser.id],
  )

  const previewAccessRequestModel: PreviewAccessRequestModel = {
    uid: filterProps?.uid,
    permissionPreset: state.permissionPreset,
    permissionPresetUserUid: state.permissionPresetUserUid,
    acl: state.acl,
    folderUid: null,
  }
  const { isPreviewAccessLoading, previewAccessResponse, validationError } =
    usePreviewAccess({
      previewAccessRequestModel,
      createPreviewAccessRequest: filtersAPI.previewAccess,
    })

  if (
    previewAccessResponse &&
    state.hasWarnings !==
      ['NoAccess', 'Viewable'].includes(previewAccessResponse)
  ) {
    setState({
      hasWarnings: ['NoAccess', 'Viewable'].includes(previewAccessResponse),
    })
  }
  const modalHeader = filterProps ? t`Edit Segment` : t`Create new segment`
  const submitButtonText = filterProps ? t`Save Segment` : t`Create Segment`

  const handleCreateOrUpdateFilter = (): void => {
    analytics.event('segmentPermissions', { state })

    const { hasWarnings: _hasWarnings, ...fields } = state
    if (filterProps) {
      updateFilter(
        { uid: filterProps.uid, filter: fields },
        {
          onSuccess: (result) => {
            toast({
              title: t`Segment saved`,
              status: 'success',
            })
            onFilterUpdated?.(result)
            onClose()
          },
          onError: (error) => {
            toast({
              title: t`An error occurred`,
              description: error.message,
              status: 'error',
            })
          },
        },
      )
    } else {
      createFilter(fields, {
        onSuccess: (result) => {
          toast({
            title: t`Segment saved`,
            status: 'success',
          })
          onFilterCreated?.(result)
          onClose()
        },
        onError: (error) => {
          toast({
            title: t`An error occurred`,
            description: error.message,
            status: 'error',
          })
        },
      })
    }
  }

  return (
    <Modal
      isOpen
      onClose={isUpdateLoading ? noop : onClose}
      initialFocusRef={titleRef}
      size={state.permissionPreset === 'Custom' ? '4xl' : 'lg'}
    >
      <ModalOverlay>
        <ModalContent>
          <Form onSubmit={handleCreateOrUpdateFilter}>
            <ModalCloseButton isDisabled={isUpdateLoading} />
            <ModalHeader>{modalHeader}</ModalHeader>
            <ModalBody>
              <Grid
                templateColumns={{
                  base: '1fr',
                  md: showsACL ? '1fr 1px 1fr' : '1fr',
                }}
                gap={4}
              >
                <Stack
                  borderRight={{
                    base: 'none',
                    md: showsACL
                      ? '1px solid var(--chakra-colors-gray-200)'
                      : 'none',
                  }}
                  pr={{
                    base: 0,
                    md: showsACL ? 8 : 0,
                  }}
                  pb={{ base: 2, md: 0 }}
                  spacing={4}
                >
                  <TitleInput
                    inputRef={titleRef}
                    value={state.name}
                    onChange={(value) => setState({ name: value })}
                  />
                  <ShareAndEditOptions
                    permissionPreset={state.permissionPreset}
                    onChange={(permissionPreset) =>
                      setState({ permissionPreset })
                    }
                    localizedEntityName={localizedEntityName}
                  />
                </Stack>
                <Divider />
                {showsACL && (
                  <Flex maxH={{ base: '660px', md: '430px' }}>
                    <PersonsWithAccess
                      acl={state.acl || []}
                      onChange={(acl) => setState({ acl: acl })}
                    />
                  </Flex>
                )}
              </Grid>
            </ModalBody>
            <ModalFooter justifyContent="space-between">
              <Text fontSize="sm" color="warning">
                {updateSaveFilterError?.message}
                {getPreviewAccessWarningText(previewAccessResponse)}
              </Text>
              <Stack isInline spacing={4}>
                <Button
                  type="button"
                  onClick={onClose}
                  isDisabled={isUpdateLoading}
                >
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  primary
                  isDisabled={validationError !== undefined}
                  isLoading={isUpdateLoading || isPreviewAccessLoading}
                  type="submit"
                >
                  {submitButtonText}
                </Button>
              </Stack>
            </ModalFooter>
          </Form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
