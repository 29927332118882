import analytics from '@capturi/analytics'
import { Box, Flex, List, ListItem, Text } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import { useMeasure } from 'react-use'

type KeyTopicsListItemProps = {
  id: string
  amount: number
  maxAmount: number
  label: string
  isSelected: boolean
  onClick: (id: string) => void
}

const topPadding = 21
const bottomPadding = 21

const KeyTopicsListItem: React.FC<KeyTopicsListItemProps> = ({
  id,
  amount,
  maxAmount,
  label,
  isSelected,
  onClick,
}) => {
  const [ref, { width, height }] = useMeasure<HTMLDivElement>()

  const [measurementsReady, setMeasurementsReady] = React.useState(false)
  React.useEffect(() => {
    if (width !== 0 && height !== 0) {
      setMeasurementsReady(true)
    }
  }, [width, height])

  const usableHeight = height - topPadding - bottomPadding
  const heightPercentage = (amount / maxAmount) * 100
  const barHeight = (usableHeight * heightPercentage) / 100

  const amountTextTop = height - barHeight - topPadding - bottomPadding

  return (
    <ListItem
      borderColor={isSelected ? 'curiousBlue.300' : 'transparent'}
      borderWidth="1px"
      borderRadius="lg"
      onClick={() => onClick(id)}
      px={4}
      pb={3}
      pt={4}
      overflow="hidden"
      position="relative"
      boxShadow={
        isSelected
          ? '1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset, -1px -1px 2px 0px rgba(223, 223, 223, 0.50) inset, -5px 5px 10px 0px rgba(223, 223, 223, 0.20), 5px -5px 10px 0px rgba(223, 223, 223, 0.20), -5px -5px 10px 0px rgba(255, 255, 255, 0.90), 5px 5px 13px 0px rgba(223, 223, 223, 0.90)'
          : ''
      }
      background={
        isSelected ? 'linear-gradient(135deg, #FFF 0%, #EAEAEA 100%)' : ''
      }
      _hover={{
        bg: isSelected ? '' : 'gray.100',
      }}
      cursor="pointer"
      transition="all 250ms ease-out"
      maxW={28}
    >
      <Box
        position="absolute"
        left={0}
        right={0}
        bottom={0}
        backgroundColor={isSelected ? 'curiousBlue.500' : 'transparent'}
        h="6px"
      />
      <Flex
        flexDirection="column"
        ref={ref}
        alignItems="stretch"
        gap={1}
        position="relative"
        w="100%"
        h="100%"
      >
        {measurementsReady && (
          <>
            <Box
              position="absolute"
              left="0"
              right="0"
              top={`${amountTextTop}px`}
              flex={0}
              w="100%"
            >
              <Text align="center" fontWeight="medium" noOfLines={1} w="100%">
                {amount}
              </Text>
            </Box>
            <Box
              id="bar"
              position="absolute"
              backgroundColor="segmentPrimary"
              borderRadius={3}
              h={`${barHeight}px`}
              bottom={`${bottomPadding}px`}
              left={0.5}
              right={0.5}
            />
            <Flex
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              alignItems="center"
            >
              <Text
                align="center"
                fontSize="xs"
                fontWeight="medium"
                lineHeight="5"
                noOfLines={1}
                w="100%"
              >
                {label}
              </Text>
            </Flex>
          </>
        )}
      </Flex>
    </ListItem>
  )
}

type KeyTopicsListProps = {
  data: {
    id: string
    label: string
    amount: number
  }[]
  selectedKeyTopicId: string | undefined
  setSelectedKeyTopicId: (id: string) => void
}

export const KeyTopicsList: React.FC<KeyTopicsListProps> = React.memo(
  ({ data, selectedKeyTopicId, setSelectedKeyTopicId }) => {
    const maxAmount = Math.max(...data.map((item) => item.amount))
    const handleClick = useCallback(
      (id: string) => {
        setSelectedKeyTopicId(id)
        analytics.event('top_time_consumers_topic_selected')
      },
      [setSelectedKeyTopicId],
    )
    return (
      <List
        gap={2}
        h="100%"
        w="100%"
        display="grid"
        gridAutoFlow="column"
        gridAutoColumns="1fr"
        overflowX="auto"
      >
        {data.map(({ id, label, amount }) => (
          <KeyTopicsListItem
            key={id}
            id={id}
            label={label}
            amount={amount}
            maxAmount={maxAmount}
            isSelected={selectedKeyTopicId === id}
            onClick={handleClick}
          />
        ))}
      </List>
    )
  },
)
