import analytics from '@capturi/analytics'
import { t } from '@lingui/macro'
import { lighten, mix } from 'color2k'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { Topic, TopicsNode } from './types'
import { KeyTopicsReadyResponse } from './types'

const mixAmount = 0.3
const originalColors = [
  mix('#216D97', '#ffffffff', mixAmount),
  mix('#709966', '#ffffffff', mixAmount),
  mix('#F59F00', '#ffffffff', mixAmount),
  mix('#E47710', '#ffffffff', mixAmount),
  mix('#B33A33', '#ffffffff', mixAmount),
  mix('#1A3A3A', '#ffffffff', mixAmount),
  mix('#657B81', '#ffffffff', mixAmount),
  mix('#676B37', '#ffffffff', mixAmount),
  mix('#89A083', '#ffffffff', mixAmount),
  mix('#997E66', '#ffffffff', mixAmount),
]

type UseKeyTopicsState = {
  localizedNodeEntityName: string
  atRootLevel: boolean
  atExploreSubtopic: boolean
  colors: string[]
  centerColor: string | undefined
  currentTopicsData: TopicsNode
}

type UseKeyTopics = UseKeyTopicsState & {
  topicsDataById: { [key: string]: TopicsNode }
  selectNode: (nodeId: string) => void
  goBack: () => void
  canGoBack: boolean
  exploreSubtopics: () => void
}

const mapTopicsToTooltipItems = (topics: Topic[]) =>
  topics
    .filter((t) => t.amount !== 0)
    .sort((a, b) => b.amount - a.amount)
    .slice(0, 6)
    .map((topic, index) => (index < 5 ? topic.name : '...'))

const mapKeyTopicsReadyResponseToTopicsNode = (
  data: Omit<KeyTopicsReadyResponse, 'status'>,
): TopicsNode => ({
  id: 'root',
  topicId: '0',
  label: '',
  amount:
    data.keyTopicClusters.reduce((total, item) => total + item.amount, 0) ?? 0,
  percentage: 0,
  amountVsExpectedDiff: 0,
  significant: false,
  other: false,
  benchmark: data.benchmark,
  rootCauseExamples: data.rootCauseExamples,
  children: data.keyTopicClusters
    .map((keyTopic) => ({
      ...keyTopic,
      id: keyTopic.other
        ? `t-cluster-other-${keyTopic.id}`
        : `t-cluster-${keyTopic.id}`,
      benchmark: keyTopic.benchmark,
      topicId: keyTopic.id,
      label: keyTopic.other
        ? t`Other`
        : keyTopic.keyTopics[0]?.name ?? keyTopic.label,
      amountVsExpectedDiff: keyTopic.amount - keyTopic.expected,
      keySubTopicClusters: undefined,
      tooltipItems: mapTopicsToTooltipItems(keyTopic.keyTopics),
      topics: keyTopic.keyTopics,
      rootCauseExamples: keyTopic.rootCauseExamples,
      children: keyTopic.keySubTopicClusters
        .map((subTopic) => ({
          ...subTopic,
          id: subTopic.other
            ? `sub-cluster-other-${subTopic.id}`
            : `sub-cluster-${keyTopic.id}-${subTopic.id}`,
          subTopicId: subTopic.id,
          topicId: keyTopic.id,
          label: subTopic.other
            ? t`Other`
            : subTopic.keySubTopics[0]?.name ?? subTopic.label,
          amountVsExpectedDiff: subTopic.amount - subTopic.expected,
          topics: subTopic.keySubTopics,
          children: undefined,
          tooltipItems: mapTopicsToTooltipItems(subTopic.keySubTopics),
        }))
        .sort((a, b) => (a.other ? 1 : b.other ? -1 : b.amount - a.amount)),
    }))
    .sort((a, b) => (a.other ? 1 : b.other ? -1 : b.amount - a.amount)),
})

const initialStateWithTopicsData = (
  topicsData: TopicsNode,
): UseKeyTopicsState => ({
  atRootLevel: true,
  atExploreSubtopic: false,
  localizedNodeEntityName: t`Key Topic clusters`,
  colors: originalColors,
  centerColor: undefined,
  currentTopicsData: topicsData,
})

const createColorsArray = (length: number): string[] => {
  return Array.from({ length }).map(
    (_, index) => originalColors[index % originalColors.length],
  )
}

export const useKeyTopicsSession = (
  data: Omit<KeyTopicsReadyResponse, 'status'>,
): UseKeyTopics => {
  const allTopicsData: TopicsNode = useMemo(
    () => mapKeyTopicsReadyResponseToTopicsNode(data),
    [data],
  )

  const [keyTopicsState, setKeyTopicsState] = useState<UseKeyTopicsState>(
    initialStateWithTopicsData(allTopicsData),
  )

  const goToRootLevel = useCallback(() => {
    setKeyTopicsState(initialStateWithTopicsData(allTopicsData))
  }, [allTopicsData])

  // if allTopicsData changes, reset to root level
  useEffect(() => {
    setKeyTopicsState(initialStateWithTopicsData(allTopicsData))
  }, [allTopicsData])

  return useMemo(() => {
    const topicsDataById = Object.fromEntries(
      keyTopicsState.currentTopicsData.children?.map((node) => [
        node.id,
        node,
      ]) ?? [],
    )

    const selectNode = (nodeId: string) => {
      const node = topicsDataById[nodeId]
      if (
        node &&
        node.children !== undefined &&
        keyTopicsState.currentTopicsData.children !== undefined
      ) {
        const index = keyTopicsState.currentTopicsData.children.findIndex(
          (child) => child.id === node.id,
        )
        let colors: string[] = originalColors
        let centerColor: string | undefined = undefined
        if (index !== -1) {
          // clicked on a topic cluster
          const currentColors = keyTopicsState.colors // makes linter happy
          const nodeColor = currentColors[index % currentColors.length]
          const childrenColor = lighten(nodeColor, 0.1)
          colors = new Array(node.children?.length ?? 0).fill(childrenColor)
          centerColor = nodeColor
        }

        setKeyTopicsState((prevState) => ({
          ...prevState,
          currentTopicsData: node,
          atRootLevel: false,
          atExploreSubtopic: false,
          localizedNodeEntityName: t`Detailed topic clusters`,
          colors,
          centerColor,
        }))
      }
    }

    const exploreSubtopics = () => {
      const allSubTopics = allTopicsData.children?.flatMap(
        (keyTopic) =>
          keyTopic.children?.map((keySubtopic) => ({
            ...keySubtopic,
            id: `${keyTopic.id}-${keySubtopic.id}`,
            children: undefined,
          })) ?? [],
      )
      const newTopicsData = {
        id: 'root',
        topicId: '0',
        label: '',

        amount:
          allSubTopics?.reduce((total, item) => total + item.amount, 0) ?? 0,
        percentage: 0,
        children: allSubTopics,
        benchmark: allTopicsData.benchmark,
        amountVsExpectedDiff: 0,
        significant: false,
        other: false,
        rootCauseExamples: allTopicsData.rootCauseExamples,
      }
      setKeyTopicsState((prevState) => ({
        ...prevState,
        currentTopicsData: newTopicsData,
        atRootLevel: false,
        atExploreSubtopic: true,
        colors: createColorsArray(newTopicsData.children?.length ?? 0),
        centerColor: undefined,
        localizedNodeEntityName: t`Sub topic clusters`,
      }))
      analytics.event('key_topics_explore_subtopics')
    }

    return {
      atRootLevel: keyTopicsState.atRootLevel,
      localizedNodeEntityName: keyTopicsState.localizedNodeEntityName,
      currentTopicsData: keyTopicsState.currentTopicsData,
      atExploreSubtopic: keyTopicsState.atExploreSubtopic,
      topicsDataById,
      selectNode,
      goBack: goToRootLevel,
      canGoBack: !keyTopicsState.atRootLevel,
      exploreSubtopics,
      colors: keyTopicsState.colors,
      centerColor: keyTopicsState.centerColor,
    }
  }, [keyTopicsState, keyTopicsState.colors, goToRootLevel, allTopicsData])
}
