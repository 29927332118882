import { Heading, HeadingProps, Text, TextProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

export const PageHeading = forwardRef<HTMLHeadingElement, HeadingProps>(
  function PageHeadingWithRef(props, ref) {
    return <Heading ref={ref} as="h1" size="2xl" {...props} />
  },
)

export const SectionHeading = forwardRef<HTMLHeadingElement, HeadingProps>(
  function SectionHeadingWithRef(props, ref) {
    return <Heading ref={ref} as="h2" size="lg" mb={4} {...props} />
  },
)

export const HeadingCaption = forwardRef<HTMLParagraphElement, TextProps>(
  function HeadingCaptionWithRef(props, ref) {
    return <Text ref={ref} fontSize="lg" {...props} />
  },
)
