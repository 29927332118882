import { Box, Grid, Skeleton, SkeletonProps, Stack } from '@chakra-ui/react'
import range from 'lodash/range'
import React from 'react'

const TabsSkeleton: React.FC<SkeletonProps> = ({
  isLoaded,
  children,
  ...restProps
}) => {
  if (isLoaded) {
    return (
      <Skeleton isLoaded {...restProps}>
        {children}
      </Skeleton>
    )
  }
  return (
    <Grid mt={8} templateColumns="200px 1fr" columnGap={8}>
      <Stack spacing={4}>
        {range(5).map((i) => (
          <Skeleton h={16} key={i} />
        ))}
      </Stack>
      <Box py={4}>
        <Skeleton h={4} w="32%" mb={2} />
        <Skeleton h={2} w="48%" />
        <Grid
          mt={8}
          templateColumns="24px 200px 1fr"
          gap={2}
          alignItems="center"
          {...restProps}
        >
          {[90, 80, 50, 60, 40].map((w) => (
            <React.Fragment key={w}>
              <Skeleton boxSize={6} borderRadius="full" />
              <Skeleton h={4} />
              <Skeleton h={4} ml={2} w={`${w}%`} />
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Grid>
  )
}

export default TabsSkeleton
