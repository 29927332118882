import {
  BaseTracker,
  useAllTrackers,
  useTrackerByUid,
} from '@capturi/api-trackers'
import { InvertibleMultiSelectFooter } from '@capturi/ui-select'
import { Plural, t } from '@lingui/macro'
import React, { useCallback } from 'react'

import { TrackerSelect } from '../../../filters'
import { BaseTrackerState } from '../../../types'
import { FilterValueLabel } from '../../FilterValueLabel'
import { TrackerFilterItem } from '../useTextFilter'
import { TextFilterPopoverComponentProps } from './types'

const BaseTrackersValueLabel: React.FC<{
  name: string
  inverted?: boolean
  uids?: string[] | null
}> = ({ name, inverted, uids }) => {
  const { data: trackers, isLoading } = useAllTrackers()
  const trackersByUid = useTrackerByUid(trackers)
  const names = (uids ?? []).map((uid) => trackersByUid?.[uid].name)
  return (
    <FilterValueLabel
      isLoading={isLoading}
      name={name}
      inverted={inverted}
      value={names}
    />
  )
}

export const TrackersValueLabel: React.FC<{
  inverted: boolean
  uids?: string[] | null
}> = ({ inverted, uids }) => (
  <BaseTrackersValueLabel
    name={inverted ? t`Tracker not detected` : t`Tracker`}
    uids={uids}
  />
)

export const TrackersSelectFilterComponent: React.FC<
  TextFilterPopoverComponentProps<TrackerFilterItem>
> = ({ item, onChangeValue, onClose }) => {
  const { data: trackers } = useAllTrackers()
  const allOptions = trackers?.filter(
    (t): t is BaseTracker => t.accessLevel !== 'None',
  )

  const value = {
    uids: item.uids ?? [],
  }
  const handleSetValue = useCallback(
    (newValue: BaseTrackerState | undefined) => {
      onChangeValue({
        ...item,
        uids: newValue?.uids ?? null,
      })
    },
    [item, onChangeValue],
  )

  const handleResetValue = useCallback(() => {
    onChangeValue({
      ...item,
      uids: [],
    })
  }, [item, onChangeValue])

  const handleIsNotChange = useCallback(
    (isNot: boolean) => {
      onChangeValue({
        ...item,
        inverted: isNot,
      })
    },
    [item, onChangeValue],
  )

  return (
    <>
      <TrackerSelect
        value={value}
        onClose={onClose}
        setValue={handleSetValue}
        resetValue={handleResetValue}
        options={{ isMulti: true }}
        showFooter={false}
      />
      <InvertibleMultiSelectFooter
        entityName={t`Tracker(s)`}
        isNot={item.inverted}
        onChangeIsNot={handleIsNotChange}
        onReset={handleResetValue}
        onSubmit={onClose}
        onSelectAllOptions={() =>
          onChangeValue({
            ...item,
            uids: allOptions?.map((t) => t.uid) ?? [],
          })
        }
        selectAllText={t`Select all`}
        selectedCount={item.uids?.length || 0}
        formatSelectedCount={(count: string | number) => (
          <Plural value={count} one={'1 selected'} other={'# selected'} />
        )}
      />
    </>
  )
}
