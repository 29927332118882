import { useMotionConfig } from '@capturi/nivo-core'
import { useTransition } from '@react-spring/web'
import {
  ArcTransitionMode,
  TransitionExtra,
  useArcTransitionMode,
} from './arcTransitionMode'
import { interpolateArc } from './interpolateArc'
import { DatumWithArc } from './types'

/**
 * This hook can be used to animate a group of arcs,
 * if you want to animate a single arc,
 * please have a look at the `useAnimatedArc` hook.
 */
export const useArcsTransition = <
  Datum extends DatumWithArc,
  ExtraProps = unknown,
>(
  data: Datum[],
  mode: ArcTransitionMode = 'innerRadius',
  extra?: TransitionExtra<Datum, ExtraProps>,
) => {
  const { animate, config: springConfig } = useMotionConfig()

  const phases = useArcTransitionMode<Datum, ExtraProps>(mode, extra)

  const transition = useTransition<
    Datum,
    {
      progress: number
      startAngle: number
      endAngle: number
      innerRadius: number
      outerRadius: number
    } & ExtraProps
  >(data, {
    keys: (datum) => datum.id,
    initial: phases.update,
    from: phases.enter,
    enter: phases.update,
    update: phases.update,
    leave: phases.leave,
    config: springConfig,
    immediate: !animate,
  })

  return {
    transition,
    interpolate: interpolateArc,
  }
}
