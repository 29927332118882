import { Box } from '@chakra-ui/react'
import { CellProps, ColumnWithLooseAccessor } from 'react-table'

import { MultiValueCell } from '../components/Cell'
import { ButtonColumn, ColumnDefinition, DataType } from '../types'

export default function buttonColumn<T extends DataType>(
  c: ColumnDefinition<T>,
  // biome-ignore lint/suspicious/noExplicitAny: legacy
): ColumnWithLooseAccessor<any> {
  const { render, id = 'button', width = 32 } = c as ButtonColumn<T>
  return {
    id,
    accessor: (d: T) => d.segments,
    width,
    Cell: function ButtonCell({ row, column }: CellProps<T, string>) {
      const segments = row.values.button as T['segments']
      return (
        <MultiValueCell values={segments} alignRight={column.alignRight}>
          {(s, i) => (
            <Box key={s.label} onClick={(e) => e.stopPropagation()}>
              {render(row.original, i)}
            </Box>
          )}
        </MultiValueCell>
      )
    },
    alignRight: true,
  }
}
