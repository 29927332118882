import analytics from '@capturi/analytics'
import { useCurrentUser } from '@capturi/core'
import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

import coachingRoutes from '../../pages/Coaching/routes'
import Link, { LinkProps } from '../Link'

const UserCoachingLink: React.FC<
  { uid?: string | null } & Omit<LinkProps, 'to'>
> = ({ children, uid, ...props }) => {
  const currentUser = useCurrentUser()

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.stopPropagation()
    analytics.event('navigateToUserPageByAvatarClick')
  }

  if (uid != null && currentUser.isAdmin) {
    return (
      <Link to={coachingRoutes.user(uid)} onClick={handleClick} {...props}>
        {children}
      </Link>
    )
  }

  return (
    <Box as="span" {...(props as BoxProps)}>
      {children as React.ReactElement}
    </Box>
  )
}

export default UserCoachingLink
