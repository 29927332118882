import { Button, RadioButton, RadioButtonGroup } from '@capturi/ui-components'
import { Box, FormControl, FormLabel, HStack, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { useEffectOnce } from 'react-use'
import { FilterCriteriaComponentBaseProps } from '../../components/PhoneFilter'

export type RecordedTracksProps = FilterCriteriaComponentBaseProps<number[]>

export const Speakers: React.FC<RecordedTracksProps> = (props) => {
  useEffectOnce(() => {
    if (props.value?.length === 0) props.setValue?.([1])
  })
  return (
    <Box p={2}>
      <FormControl>
        <Box p={2}>
          <FormLabel>
            <Trans>Speakers</Trans>
          </FormLabel>
          <RadioButtonGroup
            value={`${props.value}`}
            onChange={(e) => {
              if (e === '1') {
                props.setValue?.([1])
              } else {
                props.setValue?.([2])
              }
            }}
          >
            <RadioButton value="1">
              <Trans>Only agent</Trans>
            </RadioButton>
            <RadioButton value="2">
              <Trans>Agent and customer</Trans>
            </RadioButton>
          </RadioButtonGroup>
        </Box>
        <HStack
          pt={3}
          mb={3}
          mt={4}
          justify="flex-end"
          boxShadow="0px -4px 8px -6px rgba(0, 0, 0, 0.20)"
        >
          <Button
            onClick={() => props.onClose?.()}
            size="xs"
            primary
            variant="ghost"
          >
            <Text>
              <Trans>OK</Trans>
            </Text>
          </Button>
        </HStack>
      </FormControl>
    </Box>
  )
}
