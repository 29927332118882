import { PhoneFilterValues } from '@capturi/api-filters'
import request, { ResponseError } from '@capturi/request'
import {
  InfiniteData,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query'

import { SummariesResponseModel, Summary } from '../models'

const getNextPageParam = (lastPage: SummariesResponseModel) =>
  lastPage.continuationToken

const select = (data: InfiniteData<SummariesResponseModel>) => ({
  pages: data.pages.map((page) => page.summaries),
  pageParams: [],
})

export const useSummaries = (
  filter: PhoneFilterValues | undefined,
  period: { from: Date; to: Date },
  pageLimit: number,
  enabled: boolean,
): UseInfiniteQueryResult<InfiniteData<Summary[]>, ResponseError> => {
  return useInfiniteQuery({
    queryKey: ['summaries', filter, period.from, period.to, pageLimit],
    queryFn: async ({ pageParam }) =>
      await request.post<SummariesResponseModel>(
        'conversations/summaries?api-version=3.3',
        {
          json: pageParam
            ? { continuationToken: pageParam }
            : {
                ...filter,
                limit: pageLimit,
                fromInclusive: period.from,
                toInclusive: period.to,
                sortDirection: 1,
              },
        },
      ),
    getNextPageParam,
    initialPageParam: null,
    select,
    enabled,
    refetchOnWindowFocus: false,
  })
}
