import { Badge, HStack, Text } from '@chakra-ui/react'
import React from 'react'
import { TagsTooltip } from '../components/TagsTooltip'

type UserPermissionSectionProps = {
  entries: string[]
  collapsedEntries: string[]
  title: string
}

export const UserPermissionsSection: React.FC<UserPermissionSectionProps> = ({
  entries,
  collapsedEntries,
  title,
}) => {
  return (
    <HStack gap={1} alignItems="center" flexWrap="wrap" maxW="360px">
      {entries.length > 0 && (
        <Text
          textAlign="left"
          color="gray.600"
          fontSize="xs"
          mt={2}
          width="100%"
        >
          {title}
        </Text>
      )}
      {entries.map((name, index) => (
        <Badge
          className="item"
          px={2}
          borderRadius="4px"
          marginInlineStart="0rem !important"
          backgroundColor="gray.300"
          fontWeight="normal"
          textTransform="none"
          key={index}
        >
          {name}
        </Badge>
      ))}
      {entries.length === 5 && (
        <TagsTooltip entries={collapsedEntries} isAccordion={false} />
      )}
    </HStack>
  )
}
