import { t } from '@lingui/macro'
import identity from 'lodash/identity'
import { DataType, RangeFormatterConfig, RenderValueType } from './types'

export function valueToPercent(
  value: number,
  min: number,
  max: number,
): number {
  return ((value - min) * 100) / (max - min)
}

export const defaultRangeFormatterConfig: RangeFormatterConfig = {
  lt: (value, renderValue) => t`${renderValue(value)} or less`,
  gt: (value, renderValue) => t`${renderValue(value)} or more`,
  btw: (min, max, renderValue) =>
    t`Between ${renderValue(min)} and ${renderValue(max)}`,
}

type CreateRangeFormatter = (
  config: RangeFormatterConfig,
) => (min?: number, max?: number, renderValue?: RenderValueType) => string

export const createRangeFormatter: CreateRangeFormatter = (config) => {
  const mergedConfig: RangeFormatterConfig = {
    ...defaultRangeFormatterConfig,
    ...config,
  }
  return (
    min?: number,
    max?: number,
    renderValue: RenderValueType = identity,
  ): string => {
    if (min == null && max != null) {
      return mergedConfig.lt?.(max, renderValue) ?? ''
    }
    if (min != null && max == null) {
      return mergedConfig.gt?.(min, renderValue) ?? ''
    }
    if (min != null && max != null) {
      return mergedConfig.btw?.(min, max, renderValue) ?? ''
    }
    return ''
  }
}

export function getDataValueRange<T extends DataType>(
  data: T[],
  selector: (val: T['segments'][number]) => number | undefined = identity,
  baseRange?: [number, number],
): [number, number] {
  const rangeMax = baseRange?.[1]

  const values = data.flatMap((d) => {
    return d.segments.map((b) => selector(b) ?? 0)
  })
  if (rangeMax !== undefined) {
    values.push(rangeMax)
  }
  if (values.length === 0) {
    return [0, 1]
  }
  const minValue = 0
  const maxValue = Math.max(...values)
  return [minValue, maxValue]
}
