import { TimeResolution } from '@capturi/core'
import { RequestOptions } from '@capturi/request'

import { baseQuery, controller } from '../constants'

const basePath = `${controller}/scores`

export const scoresInsightsAPI = {
  getScoreSummaries: (): RequestOptions => ({
    url: basePath,
    query: baseQuery,
    method: 'post',
  }),
  getScoreSummary: (uid: string): RequestOptions => ({
    url: `${basePath}/${uid}`,
    query: baseQuery,
    method: 'post',
  }),
  getScoreDistribution: (uid: string): RequestOptions => ({
    url: `${basePath}/${uid}/distribution`,
    query: baseQuery,
    method: 'post',
  }),
  getScoreParameters: (uid: string): RequestOptions => ({
    url: `${basePath}/${uid}/parameters`,
    query: baseQuery,
    method: 'post',
  }),
  getUsersWithScore: (uid: string): RequestOptions => ({
    url: `${basePath}/${uid}/users`,
    query: baseQuery,
    method: 'post',
  }),
  getTeamsWithScore: (uid: string): RequestOptions => ({
    url: `${basePath}/${uid}/teams`,
    query: baseQuery,
    method: 'post',
  }),
  getScoreTimeSeries: (
    uid: string,
    resolution: TimeResolution,
    offset: number,
  ): RequestOptions => ({
    url: `${basePath}/${uid}/time-series`,
    query: {
      ...baseQuery,
      resolution,
      offset,
    },
    method: 'post',
  }),
}
