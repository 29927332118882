import { TimeResolution } from '@capturi/core'
import React from 'react'
import * as ReactIs from 'react-is'
import {
  Area,
  AreaProps,
  CartesianGridProps,
  ComposedChart,
  Customized,
  LabelList,
  CartesianGrid as RCCartesianGrid,
  Line as RCLine,
  ReferenceLine as RCReferenceLine,
  ReferenceLineProps as RCReferenceLineProps,
  Tooltip as RCTooltip,
  XAxis as RCXAxis,
  YAxis as RCYAxis,
  YAxisProps as RCYAxisProps,
  TooltipProps,
  XAxisProps,
} from 'recharts'

import { formatSeconds } from '@capturi/ui-components'
import ReferenceLineLabel from '../components/ReferenceLineLabel'
import { Theme, useChartTheme } from '../themes'
import { DateBucket, LineDescriptor, ReferenceLineDescriptor } from '../types'
import { pctAutoDomain } from '../utils'
import {
  BackgroundGradient,
  GradientDef,
  GradientLine,
} from './gradient-definitions'
import * as timeSeriesTickFormatters from './tickFormatters'
import { getDateExtremes } from './utils'

// biome-ignore lint/suspicious/noExplicitAny: legacy
type CustomChartComponent<P = any> = React.ComponentType<P> & {
  ccName?: string
}

interface CustomChartElement {
  type?: CustomChartComponent
  key: string | number | null
}

export function isChartElement<T>(
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  element: any,
  ccNames: string | string[],
): element is React.ReactElement<T> {
  const ccName = (element as CustomChartElement).type?.ccName
  if (!ccName) return false
  return (
    React.isValidElement<T>(element) &&
    ([] as string[]).concat(ccNames).indexOf(ccName) !== -1
  )
}

type ComposedChartProps = React.ComponentProps<typeof ComposedChart>

type TimeSeriesConfig = {
  resolution: TimeResolution
  minDate: Date
  maxDate: Date
}

export type TimeSeriesChartProps = Omit<ComposedChartProps, 'data'> & {
  data: DateBucket[]
  resolution?: TimeResolution
}

const defaultMargin: ComposedChartProps['margin'] = {
  top: 8,
  right: 4,
  bottom: 4,
  left: 4,
}

const handlerMap = {
  PrimaryLine: (props: PrimaryLineProps): React.ReactNode => {
    const { line, ...rest } = props
    return (
      <Area
        id={line.id}
        type="monotone"
        dataKey={line.getValue}
        name={line.label}
        stroke={`url(#gradient-line-${line.color})`}
        strokeWidth={2}
        dot={false}
        activeDot={{ r: 4 }}
        fill={`url(#gradient-fill-${line.color})`}
        // biome-ignore lint/suspicious/noExplicitAny: legacy
        {...(rest as any)}
      />
    )
  },
  Line: (props: LineProps, theme: Theme): React.ReactNode => {
    const { line, ...rest } = props
    return (
      <RCLine
        id={line.id}
        type="monotone"
        dataKey={line.getValue}
        name={line.label}
        stroke={theme.getPrimaryColor(line.color)}
        strokeDasharray={line.variant}
        strokeWidth={1}
        dot={false}
        activeDot={{ r: 4 }}
        // labels won't always render due to a bug with `isAnimationFinished` internal to `recharts`
        // https://github.com/recharts/recharts/issues/2235
        isAnimationActive={false}
        // biome-ignore lint/suspicious/noExplicitAny: legacy
        {...(rest as any)}
      >
        <LabelList
          // biome-ignore lint/suspicious/noExplicitAny: legacy
          valueAccessor={(_: any, i: number) => {
            // Only show label on the very first tick
            return i === 0 ? line.label : undefined
          }}
          fill={theme.getPrimaryColor(line.color)}
          position="insideBottomLeft"
          fontSize={10}
        />
      </RCLine>
    )
  },
  ReferenceLine: (props: ReferenceLineProps, theme: Theme): React.ReactNode => {
    const { line, ...rest } = props
    return (
      line.y != null && (
        <RCReferenceLine
          key={line.label}
          y={line.y}
          stroke={`${theme.referenceLineColor}`}
          label={
            <ReferenceLineLabel
              value={line.y}
              formatValue={line.formatValue}
              bg={theme.referenceLineColor}
              color={theme.getPrimaryColor(line.color)}
            />
          }
          {...rest}
        />
      )
    )
  },
  XAxis: (
    props: XAxisProps,
    theme: Theme,
    config: TimeSeriesConfig,
  ): React.ReactNode => {
    const { resolution, minDate, maxDate } = config
    return (
      <RCXAxis
        dataKey="date"
        tickFormatter={timeSeriesTickFormatters.xAxis(
          resolution,
          minDate,
          maxDate,
        )}
        stroke={theme.gridColor}
        tick={{ fill: theme.gridTextColor }}
        {...props}
      />
    )
  },
  YAxis: (props: YAxisProps, theme: Theme): React.ReactNode => {
    const { valueType, ...rest } = props
    let {
      tickFormatter = timeSeriesTickFormatters.yAxis.value ?? formatSeconds,
      domain,
    } = props
    if (valueType === 'percent') {
      tickFormatter = timeSeriesTickFormatters.yAxis.percent
      domain = pctAutoDomain
    }
    return (
      <RCYAxis
        tickFormatter={tickFormatter}
        domain={domain}
        stroke={theme.gridColor}
        tick={{ fill: theme.gridTextColor }}
        width={50}
        {...rest}
      />
    )
  },
  CartesianGrid: (props: CartesianGridProps, theme: Theme): React.ReactNode => {
    return (
      <RCCartesianGrid
        horizontal={false}
        stroke={theme.gridColor}
        strokeDasharray={4}
        // biome-ignore lint/suspicious/noExplicitAny: legacy
        {...(props as any)}
      />
    )
  },
  Tooltip: (props: TooltipProps<number, string>): React.ReactNode => {
    return <RCTooltip labelStyle={{ fontWeight: 'bold' }} {...props} />
  },
}

export const TimeSeriesChart: React.FC<TimeSeriesChartProps> = ({
  children: childrenProp,
  data,
  margin: marginProp,
  resolution = 'Days',
  ...rechartsProps
}) => {
  const margin = {
    ...defaultMargin,
    ...marginProp,
  }
  const [minDate, maxDate] = getDateExtremes(data)
  const timeSeriesProps: TimeSeriesConfig = { minDate, maxDate, resolution }

  const theme = useChartTheme()
  const children = React.Children.toArray(childrenProp)

  // Extract primary lines from children in order to generate area gradients for each line
  const segmentLines = children.reduce<PrimaryLineProps[]>((memo, c) => {
    if (isChartElement(c, 'PrimaryLine')) {
      memo.push(c.props)
    }
    return memo
  }, [])

  return (
    <ComposedChart data={data} margin={margin} {...rechartsProps}>
      <defs>
        {segmentLines.map((s) => (
          <GradientDef
            key={`${s.line.id}-${s.line.color}`}
            id={`gradient-fill-${s.line.color}`}
            hexColor={theme.getPrimaryColor(s.line.color)}
          />
        ))}
        {segmentLines.map((s) => (
          <GradientLine
            key={`${s.line.id}-${s.line.color}`}
            id={`gradient-line-${s.line.color}`}
            hexColor={theme.getPrimaryColor(s.line.color)}
            lightHexColor={theme.getSecondaryColor(s.line.color)}
          />
        ))}
      </defs>
      <Customized
        key="background-gradient"
        component={<BackgroundGradientComponent />}
      />
      {React.Children.map(childrenProp, (c) => {
        if (!ReactIs.isElement(c)) return null
        // Translate custom components into recharts components
        if (isChartElement<PrimaryLineProps>(c, 'PrimaryLine')) {
          return handlerMap.PrimaryLine(c.props)
        }
        if (isChartElement<LineProps>(c, 'Line')) {
          return handlerMap.Line(c.props, theme)
        }
        if (isChartElement<ReferenceLineProps>(c, 'ReferenceLine')) {
          return handlerMap.ReferenceLine(c.props, theme)
        }
        if (isChartElement<XAxisProps>(c, 'XAxis')) {
          return handlerMap.XAxis(c.props, theme, timeSeriesProps)
        }
        if (isChartElement<YAxisProps>(c, 'YAxis')) {
          return handlerMap.YAxis(c.props, theme)
        }
        if (isChartElement<CartesianGridProps>(c, 'CartesianGrid')) {
          return handlerMap.CartesianGrid(c.props, theme)
        }
        if (isChartElement<TooltipProps<number, string>>(c, 'Tooltip')) {
          return handlerMap.Tooltip(c.props)
        }
        return c
      })}
    </ComposedChart>
  )
}

export const BackgroundGradientComponent: React.FC<{
  offset?: {
    top: number
    bottom: number
    left: number
    right: number
    width: number
    height: number
  }
}> = ({ offset }) => {
  if (!offset) return null
  const { top, left, width, height } = offset
  const halftHeight = height / 2
  const x = left
  const y = top + halftHeight
  return (
    <>
      <defs>
        <BackgroundGradient id="bg-gradient" />
      </defs>
      <rect
        x={x}
        y={y}
        width={width}
        height={halftHeight}
        fill={'url(#bg-gradient)'}
      />
    </>
  )
}

export interface PrimaryLineProps extends Omit<AreaProps, 'dataKey'> {
  line: LineDescriptor
}

export const PrimaryLine: CustomChartComponent<PrimaryLineProps> = () => null
PrimaryLine.ccName = 'PrimaryLine'

export interface LineProps extends RCReferenceLineProps {
  line: LineDescriptor
}

export const Line: CustomChartComponent<LineProps> = () => null
Line.ccName = 'Line'

export interface ReferenceLineProps extends RCReferenceLineProps {
  line: ReferenceLineDescriptor
}

export const ReferenceLine: CustomChartComponent<ReferenceLineProps> = () =>
  null
ReferenceLine.ccName = 'ReferenceLine'

export interface YAxisProps extends RCYAxisProps {
  valueType?: 'value' | 'percent'
}

export const YAxis: CustomChartComponent<YAxisProps> = () => null
YAxis.ccName = 'YAxis'

export const XAxis: CustomChartComponent<XAxisProps> = () => null
XAxis.ccName = 'XAxis'

export const CartesianGrid: CustomChartComponent<CartesianGridProps> = () =>
  null
CartesianGrid.ccName = 'CartesianGrid'

export const Tooltip: CustomChartComponent<TooltipProps<number, string>> = () =>
  null
Tooltip.ccName = 'Tooltip'
