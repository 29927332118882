const Counter = (
  init = 0,
): {
  next(): number
} => {
  return {
    // biome-ignore lint/style/noParameterAssign: legacy
    next: () => ++init,
  }
}

export default Counter
