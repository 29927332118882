import { Trans, t } from '@lingui/macro'
import React from 'react'
import { IconType } from 'react-icons'
import {
  MdEdit,
  MdLockOutline,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md'

export type Option = {
  value: string
  label: string
  category: 'shared' | 'notShared'
  icon: IconType
  description: React.ReactElement
}

export function getSharingOptions(localizedEntityName: string): Option[] {
  return [
    {
      icon: MdVisibility,
      value: 'EveryoneCanView',
      label: t`Everyone can view`,
      category: 'shared',
      description: (
        <>
          <Trans>
            View: Everyone
            <br />
            Edit: Users with permission to create {localizedEntityName}
          </Trans>
        </>
      ),
    },
    {
      icon: MdVisibilityOff,
      value: 'Private',
      label: t`Private`,
      category: 'notShared',
      description: (
        <>
          <Trans>
            View: You
            <br />
            Edit: You
          </Trans>
        </>
      ),
    },
    {
      icon: MdLockOutline,
      value: 'Locked',
      label: t`Only you can edit`,
      category: 'shared',
      description: (
        <>
          <Trans>
            View: Everyone
            <br />
            Edit: You
          </Trans>
        </>
      ),
    },
    {
      icon: MdEdit,
      value: 'Custom',
      label: t`Custom sharing rules`,
      category: 'shared',
      description: <Trans>Customized rules for who can view and edit</Trans>,
    },
  ]
}
