import {
  Box,
  Divider,
  Flex,
  Skeleton,
  SkeletonCircle,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

const TrackersSkeletonRow: React.FC = () => {
  return (
    <Tr>
      <Td>
        <Flex alignItems="center" gap={2}>
          <SkeletonCircle h={6} w={6} />
          <Skeleton h={5} w={10} />
        </Flex>
      </Td>
      <Td>
        <Skeleton h={5} w={56} />
      </Td>
      <Td>
        <Skeleton h={5} w={16} />
      </Td>
      <Td>
        <Skeleton h={5} w={20} />
      </Td>
      <Td w={8} minH={6} />
    </Tr>
  )
}

const TrackersSkeleton: React.FC = () => {
  return (
    <Box ml={2}>
      <Stack isInline spacing={[6, null, null, 8]}>
        <Box minW="16em" maxW="16em" flex="0 0 auto">
          <Box h={7}>
            <Skeleton w={10} h={5} />
          </Box>
          <Divider w="100%" mb={2} />

          <Stack pb={4} pr={2} gap={4}>
            <Skeleton pr={2} w="90%" h={5} />
            <Skeleton pr={2} w="90%" h={5} />
            <Skeleton pr={2} w="90%" h={5} />
            <Skeleton pr={2} w="90%" h={5} />
            <Skeleton pr={2} w="90%" h={5} />
          </Stack>
        </Box>

        <Box flex="1">
          <Table variant="conversation-hits">
            <Thead fontSize="xs" fontWeight="medium">
              <Tr>
                <Th>
                  <Trans>Play</Trans>
                </Th>
                <Th>
                  <Trans>Tracker</Trans>
                </Th>
                <Th>
                  <Trans>Word</Trans>
                </Th>
                <Th>
                  <Trans>Speaker</Trans>
                </Th>
                <Th w={8} />
              </Tr>
            </Thead>
            <Tbody>
              <TrackersSkeletonRow />
              <TrackersSkeletonRow />
              <TrackersSkeletonRow />
              <TrackersSkeletonRow />
            </Tbody>
          </Table>
        </Box>
      </Stack>
    </Box>
  )
}

export default TrackersSkeleton
