import { WordScore } from '@capturi/api-conversations'
import { useOrganization, useSingleUser } from '@capturi/stores'
import { Avatar, Box, Flex, HStack, Text } from '@chakra-ui/react'
import { select } from '@lingui/macro'
import UserAvatar from 'components/UserAvatar'
import React from 'react'

const SentimentOverview: React.FC<{
  userUid: string
  sentiment: { wordScore: WordScore }
}> = ({ userUid, sentiment }) => {
  const { organizationType } = useOrganization()
  const { name, profileImage } = useSingleUser(userUid)
  return (
    <HStack>
      <HStack
        border="1px solid"
        borderColor="gray.200"
        borderRadius="4px"
        flex={1}
        p={3}
      >
        <HStack justifyContent="space-between" flex={1}>
          <Flex align="center" gap="1">
            <UserAvatar name={name} profileImage={profileImage} size="xs" />
            <Text
              ml={1}
              maxW="120px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {name.split(' ')[0]}
            </Text>
          </Flex>
          <Box>
            <Text
              fontWeight="medium"
              color={
                sentiment.wordScore.user === 'High'
                  ? 'segments.secondary.500'
                  : sentiment.wordScore.user === 'Low'
                    ? 'warning'
                    : 'initial'
              }
            >
              {sentiment.wordScore.user}
            </Text>
          </Box>
        </HStack>
      </HStack>
      <HStack
        border="1px solid"
        borderColor="gray.200"
        borderRadius="4px"
        flex={1}
        p={3}
      >
        <HStack justifyContent="space-between" flex={1}>
          <Flex align="center">
            <Avatar size="xs" />
            <Text ml={1}>
              {select(organizationType, {
                public: 'Citizen',
                other: 'Customer',
              })}
            </Text>
          </Flex>
          <Box>
            <Text
              fontWeight="medium"
              color={
                sentiment.wordScore.other === 'High'
                  ? 'segments.secondary.500'
                  : sentiment.wordScore.other === 'Low'
                    ? 'warning'
                    : 'initial'
              }
            >
              {sentiment.wordScore.other}
            </Text>
          </Box>
        </HStack>
      </HStack>
    </HStack>
  )
}

export default SentimentOverview
