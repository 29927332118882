import { Speaker } from '@capturi/core'
import { useOrganization } from '@capturi/stores'
import {
  FormLabel,
  RadioButton,
  RadioButtonGroup,
} from '@capturi/ui-components'
import { FormControl, FormErrorMessage } from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { Trans, select, t } from '@lingui/macro'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { MdPerson } from 'react-icons/md'

export const SpeakerField: React.FC<{
  name?: string
  isRequired?: boolean
}> = ({ name = 'speech.speakerId', isRequired }) => {
  const { organizationType } = useOrganization()
  const { formState } = useFormContext()
  const isInvalid = formState.errors[name] != null
  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel
        htmlFor="tracker_speaker"
        leftIcon={<MdPerson />}
        data-stonly="tracker_speaker"
      >
        <Trans>Mentioned by</Trans>
      </FormLabel>
      <Controller
        name={name}
        render={({ field }) => {
          return (
            <RadioButtonGroup
              id="tracker_speaker"
              {...field}
              onChange={(value) => {
                field.onChange(Number(value))
              }}
            >
              <RadioButton value={Speaker.All}>
                <Trans>All</Trans>
              </RadioButton>
              <RadioButton value={Speaker.Employee}>
                <Trans>Employee</Trans>
              </RadioButton>
              <RadioButton value={Speaker.Customer}>
                {select(organizationType, {
                  public: 'Citizen',
                  other: 'Customer',
                })}
              </RadioButton>
            </RadioButtonGroup>
          )
        }}
        rules={{
          required: isRequired ? t`Please select a speaker` : false,
        }}
      />
      <ErrorMessage
        name={name}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  )
}
