import analytics from '@capturi/analytics'
import { HStack, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useState } from 'react'
import RapidSearchButton from './Components/RapidSearchButton'
import { RapidSearchInput } from './Components/RapidSearchInput'

type Props = {
  setWords: (words: string[]) => void
  words: string[]
  isLoading: boolean
}

export const RapidSearch: React.FC<Props> = ({
  setWords,
  words,
  isLoading,
}) => {
  const [newWord, setNewWord] = useState<string>('')
  const handleAdd = (): void => {
    const trimmedWord = newWord.trim()
    if (trimmedWord === '' || words.includes(trimmedWord)) {
      setNewWord('')
      return
    }
    setWords([...words, trimmedWord])
    setNewWord('')
    analytics.event('rapidSearch_submit', { words: [...words, trimmedWord] })
  }
  const [focused, setFocused] = useState(false)

  const onFocus = () => {
    setFocused(true)
  }
  const onBlur = () => {
    setFocused(false)
  }

  return (
    <>
      <VStack
        gap={0}
        pos="relative"
        borderRadius="4px"
        border="1px solid"
        borderColor={focused ? 'primary.500' : 'gray.200'}
        _focusVisible={{ borderRight: 'none' }}
      >
        <HStack alignItems="stretch" w="100%" gap={0} justify="flex-end">
          <RapidSearchButton
            focused={focused}
            direction="right"
            onAdd={handleAdd}
          />
          <RapidSearchInput
            isFocused={focused}
            onFocus={onFocus}
            onBlur={onBlur}
            words={words}
            newWord={newWord}
            setNewWord={setNewWord}
            setWords={setWords}
            onAdd={handleAdd}
            isLoading={isLoading}
          />
        </HStack>
      </VStack>
      {words.length > 0 && (
        <Text fontSize="sm" mt={1} fontStyle="italic">
          <Trans>
            Showing conversations where the above phrases have been mentioned
          </Trans>
        </Text>
      )}
    </>
  )
}
