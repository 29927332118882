import { Flex, IconButton, Text } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import React from 'react'
import { CaptionProps, useNavigation } from 'react-day-picker'
import { MdArrowBack, MdArrowForward } from 'react-icons/md'

const NavBar: React.FC<CaptionProps> = () => {
  i18n.date
  const { goToMonth, nextMonth, previousMonth, currentMonth } = useNavigation()
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <IconButton
        aria-label={
          (previousMonth &&
            i18n.date(previousMonth, {
              month: 'long',
            })) ??
          ''
        }
        icon={<MdArrowBack />}
        size="sm"
        onClick={() => previousMonth && goToMonth(previousMonth)}
        isRound
      />
      <Text fontSize="md" fontWeight="medium">
        {i18n.date(currentMonth, { month: 'long', year: 'numeric' })}
      </Text>
      <IconButton
        aria-label={
          (nextMonth &&
            i18n.date(nextMonth, {
              month: 'long',
            })) ??
          ''
        }
        icon={<MdArrowForward />}
        size="sm"
        onClick={() => nextMonth && goToMonth(nextMonth)}
        isRound
      />
    </Flex>
  )
}

export default NavBar
