import {
  MessageFilters,
  TextGlobalSettings,
  TimeFilterKind,
} from '@capturi/api-trackers'
import { useOrganization } from '@capturi/stores'
import { MutableRefObject, useRef } from 'react'
import isEqual from 'react-fast-compare'
import { FormState } from 'react-hook-form'
import { useDebounce, useLocalStorage } from 'react-use'

import { PhraseState, TrackerFormModel } from './types'

type UseTrackerReturn = {
  hasDraft: boolean
  savedTrackerDraft?: TrackerFormModelWithTimestamp
  removeTrackerDraft: () => void
  unchangedTracker: MutableRefObject<TrackerFormModelWithTimestamp | undefined>
}

export type TrackerFormModelWithTimestamp = TrackerFormModel & {
  timestamp: string
}

export const useTrackerDraft = (options: {
  formState: FormState<TrackerFormModel>
  formValues: TrackerFormModel
  timeFilter: {
    kind: TimeFilterKind
    seconds: number | undefined
    secondsEnd: number | null
  }
  phone: { phrases: PhraseState[] }
  text?: {
    phrases: string[] | null
    messageFilters: MessageFilters | null
    globalSettings: TextGlobalSettings
  } | null
}): UseTrackerReturn => {
  const isNewTracker = options.formState.defaultValues?.uid === undefined
  const org = useOrganization()

  const trackerKey = isNewTracker
    ? org.uid
    : options.formState.defaultValues?.uid

  const [savedTrackerDraft, setSavedTrackerDraft, removeTrackerDraft] =
    useLocalStorage<TrackerFormModelWithTimestamp>(
      `saved-tracker-${trackerKey}`,
    )
  const unchangedTracker = useRef<TrackerFormModelWithTimestamp | undefined>(
    savedTrackerDraft,
  )
  const previousValues = useRef({
    formValues: options.formValues,
    phrases: options.phone.phrases,
    text: options.text?.phrases,
    timeFilter: { ...options.timeFilter },
  })

  const hasDraft = unchangedTracker.current !== undefined

  useDebounce(
    () => {
      if (
        !(
          isEqual(options.formValues, previousValues.current.formValues) &&
          isEqual(options.phone.phrases, previousValues.current.phrases) &&
          isEqual(options.text?.phrases, previousValues.current.text) &&
          isEqual(options.timeFilter, previousValues.current.timeFilter)
        )
      ) {
        setSavedTrackerDraft({
          ...options.formValues,
          speech: options.formValues.speech
            ? {
                phrases: options.phone.phrases,
                timeFilter: options.timeFilter,
                speakerId: options.formValues.speech?.speakerId,
              }
            : undefined,
          text: options.text,
          timestamp: new Date().toUTCString(),
        })
        previousValues.current = {
          formValues: options.formValues,
          phrases: options.phone.phrases,
          text: options.text?.phrases,
          timeFilter: { ...options.timeFilter },
        }
      } else if (
        isEqual(options.formValues, options.formState.defaultValues) &&
        isEqual(
          options.phone.phrases,
          options.formState.defaultValues?.speech?.phrases,
        ) &&
        isEqual(options.text?.phrases, options.formState.defaultValues?.text) &&
        isEqual(
          options.timeFilter,
          options.formState.defaultValues?.speech?.timeFilter,
        )
      ) {
        removeTrackerDraft()
      }
    },
    2000,
    [
      options.formValues,
      options.phone.phrases,
      options.text?.phrases,
      options.timeFilter,
    ],
  )

  return {
    hasDraft,
    savedTrackerDraft,
    unchangedTracker,
    removeTrackerDraft,
  }
}
