import analytics from '@capturi/analytics'
import {
  MessageFilters,
  MessagePosition,
  Tracker,
  TrackerFormModel,
  useDeleteTracker,
  useTracker,
  useTrackerDeps,
  useUpdateTracker,
} from '@capturi/api-trackers'
import { Speaker, useCurrentUser } from '@capturi/core'
import { usePageTitle } from '@capturi/react-utils'
import { ResponseError } from '@capturi/request'
import { Description, useActionToast, useToast } from '@capturi/ui-components'
import { useConfirm } from '@capturi/use-confirm'
import { useModal } from '@capturi/use-modal'
import { Flex } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { DependentsInfoDialog } from 'components/DependentsInfoDialog'
import NotFoundPage from 'pages/NotFoundPage'
import { getRoutes } from 'pages/analytics'
import qs from 'query-string'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import FormActions from '../components/FormActions'
import TrackerDescriptionItem from '../components/TrackerDescriptionItem'
import { TrackerEditor, TrackerEditorProvider } from '../editor'
import { useLastSelectedTracker } from '../hooks/useLastSelectedTracker'
import { default as trackerActionRoutes } from '../routes'

type LocationState = {
  redirectPath: string | undefined
}

function parseLocationState(arg: unknown): LocationState {
  const result: LocationState = {
    redirectPath: undefined,
  }
  if (arg == null) {
    return result
  }
  const state = arg as Partial<LocationState>

  if (typeof state.redirectPath === 'string') {
    result.redirectPath = state.redirectPath
  }
  return result
}

type EditTrackerPageProps = {
  uid: string
}

const EditTrackerPage: React.FC<EditTrackerPageProps> = ({ uid }) => {
  const location = useLocation()
  const { redirectPath } = parseLocationState(location.state)
  const navigate = useNavigate()
  const toast = useToast()
  const currentUser = useCurrentUser()

  const viewOnAnalyticsToast = useActionToast({
    status: 'success',
    actionText: t`View on analytics page`,
  })

  const confirm = useConfirm()
  const [openTrackerDependentsDialog] = useModal(DependentsInfoDialog)
  const { fetchTrackerDependants } = useTrackerDeps()
  const [shouldPollForUpdates, setShouldPollForUpdates] = React.useState(false)
  const [isMasterFolder, setIsMasterFolder] = React.useState<boolean>(false)
  const [hasPhrases, setHasPhrases] = React.useState<boolean>(false)
  const [hasTextPhrases, setHasTextPhrases] = React.useState<boolean>(false)
  const [masterTrackerPreset, setMasterTrackerPreset] =
    React.useState<boolean>(false)
  const { data: tracker } = useTracker(uid, { shouldPollForUpdates })
  const { mutate: updateTracker } = useUpdateTracker()
  const { mutate: deleteTracker, isPending: isDeleting } = useDeleteTracker()
  const [messageFilters, setMessageFilters] =
    React.useState<MessageFilters | null>(
      tracker?.text?.messageFilters ?? {
        messageTypes: ['Inbound', 'Outbound'],
        messageFields: ['Text'],
        messagePosition: MessagePosition.Any,
      },
    )
  const { resetLastSelecetedTracker } = useLastSelectedTracker()

  React.useEffect(() => {
    if (
      (tracker?.isProcessing || tracker?.isTextProcessing) &&
      !shouldPollForUpdates
    ) {
      setShouldPollForUpdates(true)
    }
    if (
      !(tracker?.isProcessing || tracker?.isTextProcessing) &&
      shouldPollForUpdates
    ) {
      setShouldPollForUpdates(false)
    }
  }, [tracker, shouldPollForUpdates])

  const handleDeleteTracker = (): void => {
    deleteTracker(uid, {
      onSuccess: () => {
        toast({ title: t`Tracker deleted`, status: 'success' })
        analytics.event('tracker_deleted')
        resetLastSelecetedTracker()
        navigate(
          qs.stringifyUrl({
            url: getRoutes().trackers.main(),
          }),
          { replace: true },
        )
      },
      onError: (error) => {
        toast({
          title: t`Ouch, we could not delete the tracker`,
          description:
            error.statusCode === 409
              ? t`The tracker cannot be deleted while calculation of statistics is ongoing`
              : error.message,
          status: 'error',
        })
      },
    })
  }

  const closeEditor = React.useCallback(
    (originalOrUpdatedTracker?: Tracker, replaceHistory = false): void => {
      if (redirectPath) {
        const { url, query } = qs.parseUrl(redirectPath, { parseNumbers: true })
        /**
         * Use the `speaker` from the redirect path unless the `speaker` of the
         * tracker was changed and there is a mismatch between the redirect path
         * `speaker` and the tracker `speaker.
         **/
        let speaker = query.speaker as Speaker | null
        if (
          originalOrUpdatedTracker?.speech != null &&
          originalOrUpdatedTracker.speech.speakerId !== Speaker.All &&
          originalOrUpdatedTracker.speech.speakerId !== speaker
        ) {
          speaker = originalOrUpdatedTracker.speech.speakerId
        }

        /**
         * Use the `tag` from the redirect path unless the `tag` of the
         * tracker was changed and the redirect path `tag` is not included in
         * the trackers tags.
         **/
        let tag = query.tag as string | null
        if (
          tag != null &&
          originalOrUpdatedTracker !== undefined &&
          !originalOrUpdatedTracker.tags.includes(tag)
        ) {
          tag = null
        }

        const redirectQuery = {
          ...query,
          ...(originalOrUpdatedTracker
            ? {
                trackerUid: originalOrUpdatedTracker.uid,
                speaker: speaker,
                tag: tag,
              }
            : {}),
        }
        navigate(
          qs.stringifyUrl({
            url,
            query: redirectQuery,
          }),
        )
      } else {
        navigate(trackerActionRoutes.root, { replace: replaceHistory })
      }
    },
    [navigate, redirectPath],
  )

  const onSaveTracker = React.useCallback(
    (formData: TrackerFormModel): void => {
      if (formData.uid) {
        updateTracker(
          {
            uid: formData.uid,
            tracker: formData,
          },

          {
            onError: (error) => {
              if (error instanceof ResponseError) {
                toast({
                  title:
                    formData.masterSettings?.targets.length === 0
                      ? t`Missing organization for master tracker`
                      : error.name,
                  description:
                    formData.masterSettings?.targets.length === 0
                      ? t`You need to select at least one organization for the master tracker in order to save.`
                      : error.message,
                  status: 'error',
                })
              }
            },
            onSuccess: () => {
              analytics.event('tracker-editor_TrackerUpdated', formData)

              if (redirectPath) {
                toast({
                  title: t`"${formData.name}" updated`,
                  status: 'success',
                })
              } else {
                viewOnAnalyticsToast({
                  title: t`"${formData.name}" updated`,
                  onActionClick: () => {
                    navigate(
                      qs.stringifyUrl({
                        url: getRoutes().trackers.main(),
                        query: {
                          speaker: formData.speech?.speakerId,
                          trackerUid: formData.uid,
                        },
                      }),
                    )
                    viewOnAnalyticsToast.closeAll()
                  },
                })
              }
              closeEditor(tracker)
            },
          },
        )
      }
    },
    [
      closeEditor,
      tracker,
      updateTracker,
      redirectPath,
      toast,
      viewOnAnalyticsToast,
      navigate,
    ],
  )

  const onDeleteTracker = async (uid: string, name: string): Promise<void> => {
    const { dependents } = await fetchTrackerDependants(uid)
    const hasDependents = dependents?.length

    if (hasDependents) {
      openTrackerDependentsDialog({
        heading: t`The tracker cannot be deleted`,
        description: t`Remove references to this tracker from the following segments, dashboards and scores before it can be deleted.`,
        dependents,
      })
      return
    }
    try {
      const description = tracker?.masterSettings
        ? t`NOTE: The tracker will NOT be deleted in the chosen external organisations. If you wish to remove the tracker from those, you will need to delete it separately within each organisation.`
        : t`Are you sure, you want to delete the tracker "${name}"?`
      const title = tracker?.masterSettings
        ? t`Delete this master tracker?`
        : t`Are you sure?`
      await confirm({
        title: title,
        description: description,
        cancelText: t`Cancel`,
        confirmText: t`Delete`,
      })
      handleDeleteTracker()
    } catch {
      // confirm dialog cancelled
      return
    }
  }

  if (!currentUser.permissions.editTracker) return <NotFoundPage />

  if (tracker == null) {
    return null
  }

  return (
    <TrackerEditor
      onSubmit={onSaveTracker}
      onPhrasesListChange={(hasSpeechPhrases, hasTextPhrases) => {
        setHasPhrases(hasSpeechPhrases || hasTextPhrases)
        setHasTextPhrases(hasTextPhrases)
      }}
      onMessageFiltersChange={setMessageFilters}
      onFolderChange={(value) => setIsMasterFolder(value)}
      onMasterTrackerToggle={(value) => setMasterTrackerPreset(value)}
      renderHeaderActions={(
        { isSubmitting },
        hasDuplicatePhrases,
        hasPhraseWithOrphanedQuote,
        isPreviewAccessLoading,
        hasPreviewAccessValidationError,
        importTracker,
        exportTracker,
        removeSavedTracker,
      ) => {
        return (
          <Flex direction="column">
            <FormActions
              isLoading={isPreviewAccessLoading}
              isDeleting={isDeleting}
              isSaving={isSubmitting}
              isSaveDisabled={
                hasDuplicatePhrases ||
                hasPhraseWithOrphanedQuote ||
                hasPreviewAccessValidationError ||
                (masterTrackerPreset && !isMasterFolder) ||
                !hasPhrases ||
                (!(
                  messageFilters?.messageFields?.length &&
                  messageFilters?.messageTypes?.length
                ) &&
                  hasTextPhrases)
              }
              saveTooltip={
                !hasPhrases
                  ? t`A minimum of one word/phrase for either phone or text is required to save the tracker.`
                  : !messageFilters?.messageTypes?.length && hasTextPhrases
                    ? t`Please select at least one type under 'Mentioned in'.`
                    : !messageFilters?.messageFields?.length && hasTextPhrases
                      ? t`Please select at least one checkbox under 'Active in'.`
                      : undefined
              }
              onCancel={() => {
                removeSavedTracker?.()
                closeEditor()
              }}
              onImportTracker={importTracker}
              onExportTracker={exportTracker}
            />
            <Description fontSize="sm" textAlign="right" mt={2}>
              <TrackerDescriptionItem
                userUid={tracker.createdByUserUid}
                date={tracker.created}
                label={t`Created`}
              />
            </Description>
            <Description fontSize="sm" textAlign="right">
              <TrackerDescriptionItem
                userUid={tracker.updatedByUserUid}
                date={tracker.updated}
                label={t`Updated`}
              />
            </Description>
          </Flex>
        )
      }}
      renderFooterActions={(
        { isSubmitting },
        hasDuplicatePhrases,
        hasPhraseWithOrphanedQuote,
        isPreviewAccessLoading,
        hasPreviewAccessValidationError,
        removeSavedTracker,
      ) => {
        return (
          <Flex mb={4} w="100%">
            <FormActions
              isLoading={isPreviewAccessLoading}
              isDeleting={isDeleting}
              isDeleteDisabled={
                tracker.isProcessing || tracker.isTextProcessing
              }
              deleteTooltip={
                tracker.isProcessing || tracker.isTextProcessing
                  ? t`It is not possible to delete the tracker as recent changes are still being processed`
                  : undefined
              }
              saveTooltip={
                !hasPhrases
                  ? t`A minimum of one word/phrase for either phone or text is required to save the tracker.`
                  : !messageFilters?.messageTypes?.length && hasTextPhrases
                    ? t`Please select at least one type under 'Mentioned in'.`
                    : !messageFilters?.messageFields?.length && hasTextPhrases
                      ? t`Please select at least one checkbox under 'Active in'.`
                      : undefined
              }
              onDelete={() => {
                removeSavedTracker?.()
                onDeleteTracker(tracker.uid, tracker.name)
              }}
              showDeleteButton
              isSaving={isSubmitting}
              isSaveDisabled={
                hasDuplicatePhrases ||
                hasPhraseWithOrphanedQuote ||
                hasPreviewAccessValidationError ||
                (masterTrackerPreset && !isMasterFolder) ||
                !hasPhrases ||
                (!(
                  messageFilters?.messageFields?.length &&
                  messageFilters?.messageTypes?.length
                ) &&
                  hasTextPhrases)
              }
              onCancel={() => {
                removeSavedTracker?.()
                closeEditor()
              }}
            />
          </Flex>
        )
      }}
    />
  )
}

export default function EditTrackerPageContainer(): React.ReactElement {
  const { uid } = useParams()
  const { data: tracker } = useTracker(uid)

  usePageTitle(t`Edit tracker: ${tracker?.name}`)
  const currentUser = useCurrentUser()

  //This cannot happen, react router will only load this route if there is a param
  if (!uid) return <div />
  return (
    <>
      {tracker && (
        <TrackerEditorProvider
          initialValues={(createFormModel) =>
            createFormModel(currentUser.id, tracker)
          }
        >
          <EditTrackerPage uid={uid} />
        </TrackerEditorProvider>
      )}
    </>
  )
}
