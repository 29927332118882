import { PhraseState } from '../types'

import { atom } from 'jotai'
import { selectAtom } from 'jotai/utils'

export const speechPhraseFieldsAtom = atom<PhraseState[]>([])

export const selectDuplicateSpeechPhrasesAtom = selectAtom(
  speechPhraseFieldsAtom,
  (phraseFields) => {
    const seenPhrases = new Set<string>()
    const duplicates = new Set<string>()
    phraseFields.forEach((phrase) => {
      if (seenPhrases.has(phrase.value)) {
        duplicates.add(phrase.value)
      }
      seenPhrases.add(phrase.value)
    })
    return duplicates
  },
)
