import { AuthService } from '@capturi/auth'
import request, { ResponseError } from '@capturi/request'
import { UseQueryResult, useQuery } from '@tanstack/react-query'

export const useMyOrganizations = (): UseQueryResult<
  { id: string; name: string }[] | undefined,
  ResponseError
> =>
  useQuery({
    queryKey: ['me', 'organizations'],
    queryFn: () => request.get(AuthService.listOrganizationsURL),
  })
