import analytics from '@capturi/analytics'
import { ComputedDatum, ResponsiveSunburst } from '@capturi/nivo-sunburst'
import { useTheme } from '@capturi/ui-theme'
import { Box, Button, Flex, IconButton, Text } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans, t } from '@lingui/macro'
import { lighten } from 'color2k'
import { FC, MouseEvent, memo, useCallback, useMemo } from 'react'
import { MdArrowBack, MdTravelExplore } from 'react-icons/md'

import { GraphTopicsNode } from './hooks/mapTopicsNodeToGraphData'

const EmptyTooltipComponent = () => {
  return <div />
}

const TopicsGraphComponent: FC<{
  graphData: GraphTopicsNode | undefined
  colors: string[]
  centerColor?: string
  totalAmount: number
  graphNodeById: Record<string, GraphTopicsNode>
  showTooltip?: boolean
  showCentralControls?: boolean
  hoveredNodeId: string | null
  setHoveredNodeId: (id: string | null) => void
  selectNode: (node: GraphTopicsNode) => void
  exploreSubtopics: () => void
  sessionUid?: string
  goBack: () => void
  canGoBack: boolean
}> = ({
  graphData,
  colors,
  centerColor,
  totalAmount,
  graphNodeById,
  showTooltip = true,
  showCentralControls = true,
  hoveredNodeId,
  setHoveredNodeId,
  selectNode,
  exploreSubtopics,
  sessionUid,
  goBack,
  canGoBack,
}) => {
  const theme = useTheme()
  const colorsWithHover = useMemo(() => {
    if (hoveredNodeId === null || graphData?.children === undefined) {
      return colors
    }

    // find hovered currentTopicsData
    const hoveredNodeIndex = graphData.children.findIndex(
      (child) => child.id === hoveredNodeId,
    )
    if (hoveredNodeIndex === -1) {
      return colors
    }

    const currentColors = colors
    return Array.from({
      length: graphData.children?.length ?? 0,
    }).map((_node, index) => {
      const nodeColor = currentColors[index % currentColors.length]
      return index === hoveredNodeIndex
        ? nodeColor
        : lighten(nodeColor, centerColor !== undefined ? 0.1 : 0.25)
    })
  }, [hoveredNodeId, graphData?.children, colors, centerColor])

  const CustomTooltipComponent = ({
    value,
    color,
    data,
  }: ComputedDatum<GraphTopicsNode>) => {
    const node = graphNodeById[data.id]
    return (
      <div
        style={{
          paddingTop: '0.75rem',
          paddingBottom: '0.75rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          background: 'white',
          minWidth: '10rem',
          maxWidth: '20rem',
          borderRadius: '0.25rem',
          boxShadow:
            '0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            {!canGoBack && (
              <div
                style={{
                  height: '8px',
                  width: '8px',
                  borderRadius: '2px',
                  backgroundColor: color,
                }}
              />
            )}
            <div style={{ color: theme.colors.gray[600], fontWeight: 500 }}>
              <Trans>Conversations</Trans>
            </div>
          </div>
          <div style={{ fontWeight: 500 }}>{value}</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            alignItems: 'flex-start',
            width: '100%',
            marginTop: '8px',
          }}
        >
          <div style={{ fontSize: '12px', fontWeight: 500 }}>
            <Trans>Topics in group:</Trans>
          </div>

          {node?.tooltipItems?.map((childTopic) => (
            <div key={childTopic} style={{ fontSize: '12px' }}>
              {childTopic}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const handleOnClick = useCallback(
    (datum: ComputedDatum<GraphTopicsNode>, _event: MouseEvent) => {
      selectNode(datum.data)
      analytics.event('key_topics_open_cluster_sunburst', {
        datum,
        sessionUid,
      })
    },
    [sessionUid, selectNode],
  )

  const handleOnMouseEnter = useCallback(
    (datum: ComputedDatum<GraphTopicsNode>, _event: MouseEvent) => {
      setHoveredNodeId(datum.data.id)
    },
    [setHoveredNodeId],
  )

  const handleOnMouseLeave = useCallback(
    (_datum: ComputedDatum<GraphTopicsNode>, _event: MouseEvent) => {
      setHoveredNodeId(null)
    },
    [setHoveredNodeId],
  )

  if (!graphData) {
    return null
  }

  return (
    <Box
      h="591px"
      width="591px"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      css={css`
        g {
          g {
            text {
              font-weight: 900;
            }
          }
        }
      `}
    >
      <Box position="relative" h="591px" width="591px">
        <Box
          position="absolute"
          width="100%"
          height="100%"
          borderRadius="50%"
          bg={centerColor}
        />
        <Box
          position="absolute"
          h="591px"
          width="591px"
          css={css`
            g {
              g {
                path {
                  cursor: pointer;
                }
              }
            }
        `}
        >
          <ResponsiveSunburst
            arcLabel="data.graphLabel"
            arcLabelsSkipAngle={20}
            arcLabelsTextColor="white"
            borderColor="white"
            borderWidth={2}
            colors={colorsWithHover}
            colorBy="id"
            data={graphData}
            enableArcLabels
            id="id"
            innerRadius={0.5}
            motionConfig="default"
            onClick={handleOnClick}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            transitionMode="innerRadius"
            value="amount"
            tooltip={
              showTooltip ? CustomTooltipComponent : EmptyTooltipComponent
            }
            layers={['arcs', 'arcLabels']}
          />
        </Box>
      </Box>
      {showCentralControls && (
        <TopicsGraphCentralControlsMemoized
          totalAmount={totalAmount}
          graphData={graphData}
          canGoBack={canGoBack}
          goBack={goBack}
          exploreSubtopics={exploreSubtopics}
          centerColor={centerColor}
        />
      )}
    </Box>
  )
}

const TopicsGraphCentralControls: FC<{
  totalAmount: number
  graphData: GraphTopicsNode
  canGoBack: boolean
  goBack: () => void
  exploreSubtopics: () => void
  centerColor?: string
}> = ({
  exploreSubtopics,
  totalAmount,
  graphData,
  canGoBack,
  goBack,
  centerColor,
}) => {
  return (
    <Box
      position="absolute"
      width="30%"
      height="30%"
      padding="30%"
      borderRadius="50%"
      transform="translate(-50%, -50%)"
      top="50%"
      left="50%"
    >
      <Flex
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        flexDirection="column"
        alignItems="center"
        gap={0}
        color={centerColor ? 'white' : 'black'}
      >
        {canGoBack && graphData.label && (
          <Text
            fontSize="2xl"
            fontWeight="medium"
            noOfLines={1}
            mb={4}
            wordBreak="break-all"
          >
            {graphData.graphLabel}
          </Text>
        )}
        <Text
          fontSize={canGoBack && graphData.label ? '4xl' : '6xl'}
          fontWeight="medium"
          lineHeight={canGoBack && graphData.label ? '6' : '10'}
        >
          {totalAmount}
        </Text>
        <Text
          fontSize={canGoBack && graphData.label ? '2xl' : '4xl'}
          color={centerColor ? 'white' : 'gray.600'}
        >
          <Trans>conversations</Trans>
        </Text>

        {canGoBack ? (
          <IconButton
            aria-label={t`Go back`}
            icon={<MdArrowBack />}
            color={centerColor ? 'white' : 'gray.800'}
            _hover={{ backgroundColor: 'transparent' }}
            fontSize="5xl"
            mt={4}
            onClick={goBack}
            size="lg"
            variant="ghost"
          />
        ) : (
          <Button
            leftIcon={<MdTravelExplore size="sm" />}
            onClick={exploreSubtopics}
            mt="4"
            size="xs"
          >
            <Trans>Explore subtopics</Trans>
          </Button>
        )}
      </Flex>
    </Box>
  )
}

const TopicsGraphCentralControlsMemoized = memo(TopicsGraphCentralControls)

export const TopicsGraph = memo(TopicsGraphComponent)

const skeletonData: GraphTopicsNode = {
  id: 'root',
  label: 'Root',
  graphLabel: 'Root',
  amount: 0,
  children: Array.from({ length: 10 }, (_, i) => ({
    id: `child-${i}`,
    label: '',
    graphLabel: '',
    amount: 10 - i,
    children: [],
    topicId: '0',
    other: false,
  })),

  topicId: '0',
}

export const TopicsGraphSkeleton: FC = () => {
  return (
    <TopicsGraph
      graphData={skeletonData}
      colors={[
        '#eeeeeeff', // 1
        '#eeeeeeff', // 2
        '#eeeeeeff', // 3
        '#eeeeee99', // 4
        '#eeeeee80', // 5
        '#eeeeee4d', // 6
        '#eeeeee1a', // 7
        '#eeeeee00', // 8
        '#eeeeee00', // 9
        '#eeeeee00', // 10
      ]}
      centerColor={undefined}
      totalAmount={0}
      graphNodeById={{}}
      showTooltip={false}
      showCentralControls={false}
      hoveredNodeId={null}
      setHoveredNodeId={() => {}}
      selectNode={() => {}}
      exploreSubtopics={() => {}}
      goBack={() => {}}
      canGoBack={false}
    />
  )
}
