import { organizationAPI } from '@capturi/api-organization'
import { useAPI } from '@capturi/api-utils'
import { OnChangeValue, SelectOption } from '@capturi/ui-select'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import {
  FilterCriteriaComponentBaseProps,
  FilterCriteriaSelect,
} from '../../components/PhoneFilter/components/PhoneSegmentBuilder'
import { defaultSWRConfig } from '../constants'
import getMissingOptions from '../getMissingOptions'

export type LabelSelectConfig = {
  isMulti?: boolean
}

export type LabelSelectProps = FilterCriteriaComponentBaseProps<
  string[],
  LabelSelectConfig
>

export function LabelSelect(props: LabelSelectProps): React.ReactElement {
  const { data } = useAPI<{ values: string[] }>(
    organizationAPI.getLabels(),
    defaultSWRConfig,
  )

  const isMultiSelect = props.options?.isMulti ?? true

  const { options, value } = React.useMemo(() => {
    const currentStateValue = props.value ?? []

    const options = (data?.values ?? [])
      .filter((x) => !isEmpty(x))
      .sort((a, b) => a.localeCompare(b))
      .map((x) => ({
        value: x,
        label: x,
      }))

    const value = currentStateValue.map((c) => ({ value: c, label: c }))

    // If current value is not part of the options then find them, and add them later
    const missingOptions = getMissingOptions(currentStateValue, options)

    return {
      options: missingOptions.length
        ? [...missingOptions, ...options]
        : options,
      value,
    }
  }, [data, props.value])

  const handleChange = (option: OnChangeValue<SelectOption, boolean>): void => {
    if (option == null) {
      props.resetValue?.()
      return
    }
    const options = (Array.isArray(option) ? option : [option]) as NonNullable<
      OnChangeValue<SelectOption, true>
    >
    props.setValue?.(options.map((x) => x.value))
    if (!isMultiSelect) {
      props.onClose?.()
    }
  }

  return (
    <FilterCriteriaSelect
      options={options}
      value={value}
      onChange={handleChange}
      isMulti={isMultiSelect}
      onClose={props.onClose}
      autoFocus={true}
    />
  )
}
