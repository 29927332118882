import { useCurrentUser } from '@capturi/core'
import {
  FilterPeriodSelectContainer,
  SingleSegmentContainer,
  useFilterDefinitions,
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import { EmptyStateIcon, usePageTitle } from '@capturi/react-utils'
import { getErrorMessage } from '@capturi/request'
import {
  Button,
  ContentPlaceholder,
  Emoji,
  PageHeading,
} from '@capturi/ui-components'
import {
  Box,
  Divider,
  Flex,
  HStack,
  Heading,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useRef, useState } from 'react'
import { MdArrowUpward } from 'react-icons/md'

import { useSummaries } from '@capturi/api-summaries'
import { AskAiAnswer } from './Components/AskAIAnswer'
import QuestionInput from './Components/QuestionInput'

const PAGE_LIMIT = 5

const AskAIPage: React.FC = () => {
  usePageTitle('Ask AI')

  const scrollToRef = useRef<HTMLDivElement | null>(null)
  const toast = useToast()

  const { period } = useFilterPeriodContext()

  const [customQuestion, setCustomQuestion] = useState<string>('')

  const segmentState = useFirstPhoneSegmentState()

  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  const {
    data: summaries,
    isLoading,
    isPending,
    isFetchingNextPage,
    error,
    hasNextPage,
    refetch: fetchSummaries,
    fetchNextPage,
  } = useSummaries(segmentState.values, period, PAGE_LIMIT, false)

  const handleSummaries = async (question: string): Promise<void> => {
    setCustomQuestion(question.trim())
    try {
      await fetchSummaries()
    } catch (error) {
      const errorMessage = getErrorMessage(error)
      toast({
        title: t`Something went wrong when loading summaries. Please try again.`,
        description: errorMessage,
        status: 'error',
      })
    }
  }

  const handleNextPage = (): void => {
    fetchNextPage()
  }

  const isEmpty = summaries?.pages?.[0].length === 0

  if (error) {
    return (
      <ContentPlaceholder.Container mt={20}>
        <EmptyStateIcon />
        <ContentPlaceholder.Heading>
          <Trans>Whoops! There has been an error</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>Try reloading the page</Trans>
        </ContentPlaceholder.Body>
        <ContentPlaceholder.Footer>
          <Button primary onClick={() => location.reload()}>
            <Trans>Reload page</Trans>
          </Button>
        </ContentPlaceholder.Footer>
      </ContentPlaceholder.Container>
    )
  }

  return (
    <Flex flexDir="column" w="100%" overflowY="hidden" h="100%">
      <Flex
        className="conversations-container"
        flexDir="column"
        h="100%"
        overflowY="auto"
        w="100%"
        gap={4}
      >
        <Box mb={4}>
          <Flex align="start" justify="space-between" mb={6}>
            <Box>
              <PageHeading mb={6}>
                <Trans>Ask AI</Trans>
              </PageHeading>
              <SingleSegmentContainer
                disabledChannels={['email']}
                filterDefinitions={filterDefinitions}
              />
            </Box>
            <HStack>
              <FilterPeriodSelectContainer />
            </HStack>
          </Flex>
        </Box>
        <Heading fontSize="4xl" textAlign="center">
          <Trans>Ask each conversation...</Trans>
        </Heading>
        <Box mx="auto" w="680px">
          <QuestionInput
            handleSubmit={handleSummaries}
            hasPostedState={!isPending}
          />
        </Box>
        {isEmpty ? (
          <Flex mt={2} direction="column" alignItems="center">
            <Emoji symbol="👀" fontSize="70px" />
            <Text fontSize="lg">
              <Trans>No results</Trans>
            </Text>
            <Text fontSize="sm" color="textMuted" textAlign="center">
              <Trans>
                Try to increase the period up in the right corner or make your
                search terms broader
              </Trans>
            </Text>
          </Flex>
        ) : (
          !isPending && (
            <Flex
              flexDir="column"
              gap={3}
              w="960px"
              m="0 auto"
              height="100%"
              h="100%"
            >
              <Box w="960px" m="0 auto" height="100%" pb={8}>
                <VStack gap="1" h="100%">
                  <HStack
                    ref={scrollToRef}
                    w="100%"
                    alignItems="center"
                    minH="28px"
                    justifyContent="space-between"
                  >
                    <Heading fontSize="2xl" w="121px">
                      <Trans>Answers</Trans>
                    </Heading>
                    <Text fontSize="xs" color="gray.400">
                      <Trans>Generated by Capturi AI</Trans>
                    </Text>
                  </HStack>
                  <Box h="100%" w="100%" textAlign="center">
                    {isLoading ? (
                      <Spinner color="primary.500" mb={3} />
                    ) : (
                      <>
                        {(summaries?.pages ?? []).map((d, idx) =>
                          d.map((c, index) => {
                            return (
                              <React.Fragment key={c.uid}>
                                <AskAiAnswer
                                  summaryData={c}
                                  question={customQuestion}
                                />
                                {index + 1 === d.length &&
                                  summaries &&
                                  summaries?.pages.length > idx + 1 && (
                                    <Flex
                                      flexDir="row"
                                      alignItems="center"
                                      position="relative"
                                      py={6}
                                      mt="-16px"
                                      gap={4}
                                    >
                                      <Divider borderColor="gray.300" />
                                      <Text
                                        as="span"
                                        color="gray.600"
                                        flex="1 0 auto"
                                      >
                                        <Trans>Page {idx + 2}</Trans>
                                      </Text>
                                      <Divider borderColor="gray.300" />
                                    </Flex>
                                  )}
                              </React.Fragment>
                            )
                          }),
                        )}
                      </>
                    )}
                  </Box>
                  <Text
                    fontSize="xs"
                    fontStyle="italic"
                    color="gray.500"
                    mb={2}
                  >
                    <Trans>
                      Note, Ask AI can make mistakes. Always check important
                      information and do not rely solely on answers when making
                      decisions.
                    </Trans>
                  </Text>
                  <Button
                    onClick={handleNextPage}
                    isDisabled={!hasNextPage || isFetchingNextPage}
                    isLoading={isFetchingNextPage}
                  >
                    {!hasNextPage ? (
                      <Trans>No more conversations</Trans>
                    ) : (
                      <Text>
                        <Trans>Load more</Trans>
                      </Text>
                    )}
                  </Button>
                  {summaries?.pages.length && summaries?.pages.length > 1 && (
                    <Tooltip label={t`Scroll to top`} hasArrow placement="top">
                      <IconButton
                        onClick={() =>
                          scrollToRef?.current?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                          })
                        }
                        mt={4}
                        aria-label={t`Thumbs down`}
                        rounded="100%"
                        color="gray.800"
                        icon={<MdArrowUpward />}
                        size="md"
                      />
                    </Tooltip>
                  )}
                </VStack>
              </Box>
            </Flex>
          )
        )}
      </Flex>
    </Flex>
  )
}

export default AskAIPage
