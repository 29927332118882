import { tagAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleInterpolation,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'
import { mode, transparentize } from '@chakra-ui/theme-tools'

const variantSubtle: SystemStyleFunction = (props) => {
  const { colorScheme: c, theme } = props
  if (c !== 'gray') {
    return theme.components.Badge.variants.subtle(props)
  }
  const darkBg = transparentize(`${c}.200`, 0.16)(theme)
  return {
    bg: mode(`${c}.200`, darkBg)(props),
    color: mode(`${c}.800`, `${c}.200`)(props),
  }
}

const variants: Record<string, PartsStyleInterpolation<typeof parts>> = {
  subtle: (props) => ({
    container: variantSubtle(props),
  }),
}

export default {
  parts: ['container'],
  variants,
  baseStyle: {
    container: {
      boxShadow: 'sm',
    },
  },
}
