import { Checkbox, Text } from '@chakra-ui/react'
import React from 'react'

type Props = {
  data: {
    label: string
    value: string
  }
  isLoading: boolean
  onCheck: (el: string) => void
  onUncheck: (el: string) => void
  isChecked: (el: string) => boolean
}

const CsvCheckbox: React.FC<Props> = ({
  data,
  isLoading,
  onCheck,
  onUncheck,
  isChecked,
}) => {
  return (
    <Checkbox
      isChecked={isChecked(data.value)}
      isDisabled={isLoading}
      onChange={(e) => {
        if (e.target.checked) {
          onCheck(data.value)
        } else {
          onUncheck(data.value)
        }
      }}
    >
      <Text>{data.label}</Text>
    </Checkbox>
  )
}

export default CsvCheckbox
