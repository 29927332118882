import { Box, SpinnerProps } from '@chakra-ui/react'
import React from 'react'
import Spinner from '../Spinner'

type Props = {
  overlay?: boolean
  spinnerDelay?: number
} & SpinnerProps

export const PageLoader: React.FC<Props> = ({
  overlay = false,
  spinnerDelay = 1000,
  thickness = '3px',
  size = 'lg',
  ...restProps
}) => {
  return (
    <>
      {overlay && (
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          backgroundColor="whiteAlpha.500"
        />
      )}
      <Box
        position="absolute"
        top={'50%'}
        left={'50%'}
        style={{ transform: 'translate(-50%, -50%)' }}
        {...restProps}
      >
        <Spinner delay={spinnerDelay} thickness={thickness} size={size} />
      </Box>
    </>
  )
}
