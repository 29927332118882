import { t } from '@lingui/macro'
import React from 'react'

import { MultiValueFilterItem } from '../useTextFilter'
import { BaseMultiTextFilter } from './BaseMultiTextFilter'
import { TextFilterPopoverComponentProps } from './types'

export const CaseIdFilterComponent: React.FC<
  TextFilterPopoverComponentProps<MultiValueFilterItem>
> = (props) => {
  return (
    <BaseMultiTextFilter
      {...props}
      entityName={t`Ticket ID(s)`}
      placeholder={t`Ticket IDs`}
      hint={t`You can also paste in a list of Ticket IDs`}
      onChangeValue={(item) => {
        props.onChangeValue({
          ...item,
          filterType: 'externalUidFilters',
        })
      }}
    />
  )
}
