import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Modal as _Modal,
  ModalOverlay as _ModalOverlay,
} from '@chakra-ui/react'
import type {
  ModalContentProps,
  ModalOverlayProps,
  ModalProps,
} from '@chakra-ui/react'
import React, { ReactElement } from 'react'

const Modal: React.FC<ModalProps> = (props) => {
  return <_Modal closeOnOverlayClick={false} closeOnEsc={true} {...props} />
}

const ModalOverlay = (props: ModalOverlayProps): ReactElement => (
  <_ModalOverlay zIndex={1400} {...props} />
)

export {
  Modal,
  ModalOverlay,
  // Chakra re-exports
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
}

export type { ModalProps, ModalContentProps }
