import { AccessLevel } from '@capturi/api-shared'
import {
  BaseTracker,
  TrackerListItem,
  useAllTrackerFolders,
  useAllTrackers,
} from '@capturi/api-trackers'
import { Speaker } from '@capturi/core'
import { useOrganization } from '@capturi/stores'
import {
  CheckboxOption,
  OnChangeValue,
  OptionProps,
  SelectOption,
  components,
  createFilter,
} from '@capturi/ui-select'
import { Box, Flex, Text } from '@chakra-ui/react'
import { Trans, select, t } from '@lingui/macro'
import React, { useCallback } from 'react'

import {
  FilterCriteriaComponentBaseProps,
  FilterCriteriaSelect,
  FilterCriteriaSelectProps,
} from '../../components/PhoneFilter/components/PhoneSegmentBuilder'
import { BaseTrackerState } from '../../types'
import getMissingOptions from '../getMissingOptions'

export type TrackerSelectConfig = {
  isMulti?: boolean
}

export type TrackerSelectProps = FilterCriteriaComponentBaseProps<
  BaseTrackerState,
  TrackerSelectConfig
> & { showFooter?: boolean }

type TrackerSelectOption<IncludeInaccessible extends boolean> = SelectOption & {
  tracker?: TrackerListItem<IncludeInaccessible>
  accessLevel?: AccessLevel
  speaker?: string
}

const localizeSpeaker = (orgType: string, speaker: Speaker): string => {
  const msgs: Record<Speaker, string> = {
    [Speaker.All]: t`All`,
    [Speaker.Employee]: t`Employee`,
    [Speaker.Customer]: select(orgType, {
      public: 'Citizen',
      other: 'Customer',
    }),
  }
  return msgs[speaker ?? Speaker.All]
}

const localizeTrackerSpeaker = (
  trackerListItem: TrackerListItem<true> | undefined,
  orgType: string,
): string | undefined => {
  if (trackerListItem === undefined) return ''
  if ('speech' in trackerListItem && trackerListItem.speech) {
    return localizeSpeaker(orgType, trackerListItem.speech.speakerId)
  }
  return undefined
}

const defaultFilter = createFilter({})

type BaseTrackerSelectProps<IsMulti extends boolean> = Omit<
  FilterCriteriaSelectProps<TrackerSelectOption<true>, IsMulti>,
  'onChange'
> & {
  onChange: (value: string[]) => void
}

function BaseTrackerSelect<IsMulti extends boolean>({
  value: valueProp,
  onChange,
  ...restProps
}: BaseTrackerSelectProps<IsMulti>): React.ReactElement {
  const { organizationType } = useOrganization()
  const Option: React.ComponentType<
    OptionProps<TrackerSelectOption<true>, IsMulti>
  > = (props) => {
    const { label, accessLevel, speaker, trackerFolderName } = props.data
    return (
      <components.Option {...props}>
        <CheckboxOption isSelected={props.isSelected}>
          <Flex>
            <Box flexGrow={1} overflow="hidden">
              <Text
                color={accessLevel === 'None' ? 'warning' : 'inherit'}
                display="inline-block"
              >
                <Text as="span">{label}</Text>
              </Text>
              <Flex flexDirection="row">
                <Flex justify="space-between" flexDirection="column">
                  {trackerFolderName && (
                    <Text fontSize="xs" color="textMuted">
                      <Trans>Folder</Trans>: {trackerFolderName}
                    </Text>
                  )}

                  {!!speaker && (
                    <Text fontSize="xs" color="textMuted">
                      <Trans>Speaker</Trans>: {speaker}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </CheckboxOption>
      </components.Option>
    )
  }

  const { data: trackers } = useAllTrackers()
  const { data: folders } = useAllTrackerFolders()

  const { options, value } = React.useMemo(() => {
    const trackerMap = new Map(
      trackers?.map((tracker) => [tracker.uid, tracker]),
    )
    const currentStateValues: TrackerSelectOption<true>[] = Array.isArray(
      valueProp,
    )
      ? valueProp
      : valueProp
        ? [valueProp]
        : []

    const trackerFolderMap = new Map(
      folders?.map((folder) => [folder.uid, folder.title]),
    )
    const options =
      trackers
        ?.filter((t): t is BaseTracker => t.accessLevel !== 'None')
        .reduce<TrackerSelectOption<false>[]>((memo, t) => {
          const folderName =
            'folderUid' in t && t.folderUid
              ? trackerFolderMap.get(t.folderUid)
              : undefined

          memo.push({
            tracker: t,
            value: t.uid,
            label: t.name,
            trackerFolderName: folderName,
            accessLevel: t.accessLevel,
            speaker: localizeTrackerSpeaker(t, organizationType),
          })

          return memo
        }, []) ?? []

    const values: TrackerSelectOption<false>[] = currentStateValues.map((x) => {
      const option = trackerMap.get(x.value)
      if (option) {
        return {
          label: option.name,
          value: option.uid,
          accessLevel: option.accessLevel,
          speaker: localizeTrackerSpeaker(option, organizationType),
        }
      }
      return {
        value: x.value,
        label: x.label,
        speaker: localizeTrackerSpeaker(x.tracker, organizationType),
        x,
      }
    })

    // If current value is not part of the options then find them, and add them later
    const missingOptions = getMissingOptions(
      currentStateValues.map((v) => v.value),
      options,
    ).map((o) => ({
      value: o.value,
      label: trackerMap.get(o.value)?.name || o.value,
      speaker: undefined,
      accessLevel: trackerMap.get(o.value)?.accessLevel,
    }))

    // add missing values if there are any
    return {
      options: missingOptions.length
        ? [...missingOptions, ...options]
        : options,
      value: values,
    }
  }, [folders, organizationType, trackers, valueProp])

  const handleChange = (
    option: OnChangeValue<TrackerSelectOption<true>, IsMulti>,
  ): void => {
    if (option == null) {
      return
    }
    if (Array.isArray(option)) {
      const value = (option as TrackerSelectOption<true>[]).map((o) => o.value)
      onChange(value)
    } else {
      const value = (option as TrackerSelectOption<true>).value
      onChange([value])
    }
  }

  return (
    <FilterCriteriaSelect
      {...restProps}
      options={options}
      value={value}
      onChange={handleChange}
      components={{
        Option,
      }}
    />
  )
}

export function TrackerSelect(props: TrackerSelectProps): React.ReactElement {
  const { organizationType } = useOrganization()
  const isMultiSelect = props.options?.isMulti ?? true
  const handleChange = useCallback(
    (uids: string[]) => {
      if (uids.length === 0) {
        props.resetValue?.()
      } else {
        props.setValue?.({ uids })
      }
      if (!isMultiSelect) {
        props.onClose?.()
      }
    },
    [isMultiSelect, props],
  )

  const filterOption = useCallback(
    (
      option: {
        label: string
        value: string
        data: TrackerSelectOption<true>
      },
      rawInput: string,
    ): boolean => {
      const speaker = localizeTrackerSpeaker(
        option.data.tracker,
        organizationType,
      )

      const searchTerm = rawInput.toLowerCase().trim()
      const matchedTrackerFolder = option.data.trackerFolderName
        ?.toLowerCase()
        .includes(searchTerm)

      const matchedSpeaker = !!speaker?.toLowerCase().includes(searchTerm)

      // Otherwise just use default filtering
      return !!(
        defaultFilter(option, rawInput) ||
        matchedSpeaker ||
        matchedTrackerFolder
      )
    },
    [organizationType],
  )

  const value: TrackerSelectOption<true>[] | undefined = props.value?.uids.map(
    (x) => ({
      value: x,
      label: x,
    }),
  )

  return (
    <BaseTrackerSelect
      value={value}
      onChange={handleChange}
      isMulti={isMultiSelect}
      onClose={props.onClose}
      autoFocus={true}
      filterOption={filterOption}
      showFooter={props.showFooter}
    />
  )
}

export const NotTrackerSelect = TrackerSelect
