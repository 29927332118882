import { Speaker } from '@capturi/core'
import { useOrganization } from '@capturi/stores'
import merge from 'lodash/merge'
import React from 'react'
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form'

export type PhraseNearnessFormModel = {
  phrases: string[]
  speakerId: Speaker
  secondsBefore: number
  secondsAfter: number
}

export type PhraseNearnessFormProps = {
  onSubmit?: SubmitHandler<PhraseNearnessFormModel>
  onSubmitError?: SubmitErrorHandler<PhraseNearnessFormModel>
  defaultValues?:
    | Partial<PhraseNearnessFormModel>
    | (() => Partial<PhraseNearnessFormModel> | undefined)
  children?: React.ReactNode
}

const DEFAULT_DISTANCE = 5
const defaultValues: PhraseNearnessFormModel = {
  phrases: [],
  speakerId: Speaker.All,
  secondsBefore: DEFAULT_DISTANCE,
  secondsAfter: DEFAULT_DISTANCE,
}

export const PhraseNearnessForm: React.FC<PhraseNearnessFormProps> = (
  props,
) => {
  const { uiLanguage } = useOrganization()
  const formMethods = useForm<PhraseNearnessFormModel>({
    defaultValues: merge(
      {},
      defaultValues,
      typeof props.defaultValues === 'function'
        ? props.defaultValues()
        : props.defaultValues,
    ),
  })
  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={(e) => {
          /**
           * This is a nested form within the tracker editor form so we need to
           * prevent the submit event from bubbling up and submitting the tracker editor form
           */
          e.stopPropagation()
          formMethods.handleSubmit((data, e) => {
            data.phrases = Array.from(new Set(data.phrases)).sort((a, b) => {
              return a.localeCompare(b, uiLanguage, {
                sensitivity: 'base',
              })
            })
            props.onSubmit?.(data, e)
          }, props.onSubmitError)(e)
        }}
      >
        {props.children}
      </form>
    </FormProvider>
  )
}
