import { useDebounce } from '@capturi/react-utils'
import constate from 'constate'
import qs from 'query-string'
import React from 'react'
import useSyncSearchParam from 'router/useSyncSearchParam'

const QUERY_KEY = 'q'

function useSearchTerm(): [string | undefined, (uid: string) => void] {
  const state = React.useState(() => {
    const parsedQuery = qs.parse(window.location.search)
    let value = parsedQuery[QUERY_KEY]
    if (Array.isArray(value)) {
      value = value[0]
    }
    if (value == null) {
      return undefined
    }
    return value
  })
  const debouncedState = useDebounce(state[0], 1000)
  useSyncSearchParam(QUERY_KEY, debouncedState)
  return state
}

const [SearchTermProvider, useSearchTermContext] = constate(useSearchTerm)

export { SearchTermProvider, useSearchTermContext }
