import analytics, { EventParams } from '@capturi/analytics'

export enum FilterPeriodEvent {
  SetDatePeriod = 'filters_setDatePeriod',
}

export const logEvent = (
  event: FilterPeriodEvent,
  params?: EventParams,
): void => {
  analytics.event(event, params)
}
