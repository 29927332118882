import request, { ResponseError } from '@capturi/request'
import { UseQueryResult, useQuery } from '@tanstack/react-query'

export type Filter = {
  name: 'Inbox' | 'Status' | 'Tags' | 'CustomFields'
  field?: string
}

type KeysResponse = { keys: { key: string; tracked: boolean }[] }
type ValuesResponse = { values: string[] }

export const useFilterValues = <T = ValuesResponse>(
  filter: Filter,
): UseQueryResult<T, ResponseError> =>
  useQuery({
    queryKey: ['text', 'filter-values', filter.name, filter.field],
    queryFn: () =>
      request.get<T>(
        filter.field
          ? `organization/text/filter-values/${filter.name}/${filter.field}`
          : `organization/text/filter-values/${filter.name}`,
        {
          query: { 'api-version': '3.3' },
        },
      ),
    refetchOnWindowFocus: false,
  })

export const useCustomFieldsKeys = (): UseQueryResult<
  KeysResponse,
  ResponseError
> => useFilterValues({ name: 'CustomFields' })
