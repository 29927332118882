import {
  ConversationsInclTrackerHitsResponseModel,
  useConversationsIncludingTrackerHits,
} from '@capturi/api-conversations'
import { PhoneFilterValues } from '@capturi/api-filters'
import { BaseTracker } from '@capturi/api-trackers'
import {
  AudioProvider,
  formatTime,
  useAudioContext,
  useAudioShortcut,
} from '@capturi/audio'
import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import {
  FilterPeriodProvider,
  PeriodDefinition,
  SegmentStatesProvider,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import { useSingleUser } from '@capturi/stores'
import { List, ListItemMenu, MenuList } from '@capturi/ui-components'
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { useConversationDetailsDrawer } from 'components/ConversationDetailsDrawer'
import OptionalAudioPlayerActions from 'components/OptionalAudioPlayerActions'
import { PlayListItem } from 'components/PlayList'
import {
  FalseRecognitionMenuItem,
  useMarkAsFalseRecognition,
} from 'features/trackers/markAsFalseRecognition'
import React from 'react'
import { MdLaunch } from 'react-icons/md'
import { useTimeout } from 'react-use'

import IsReviewedCheckmark from '../../../Conversations/ConversationsPage/isReviewedCheckmark.svg'
import { Event, logEvent } from '../events'

const dateFormat: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

type FetchOptions = {
  excludeDeletedConversations?: boolean
}

type DataProps = {
  tracker: BaseTracker
  filterState: PhoneFilterValues
  periodDef: PeriodDefinition
} & FetchOptions

export type PhoneTrackerExamplesDrawerProps = DataProps &
  Omit<DrawerProps, 'children'>

export const PhoneTrackerExamplesDrawer: React.FC<
  PhoneTrackerExamplesDrawerProps
> = ({ tracker, filterState = {}, periodDef, isOpen, onClose }) => {
  const [isReady] = useTimeout(500)
  return (
    <FilterPeriodProvider defaultPeriod={periodDef}>
      <SegmentStatesProvider
        initialState={{
          channel: 'phone',
          state: {
            values: filterState,
            savedFilter: undefined,
          },
        }}
      >
        <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader
              borderBottom="1px"
              borderBottomColor="gray.200"
              lineHeight={1.4}
              display="flex"
              alignItems="center"
              pr={10}
              pl={4}
            >
              <Box flex="1">
                <Text fontSize="sm" color="textMuted">
                  <Trans>Audio snippets</Trans>
                </Text>
                <Text fontSize="1rem">{tracker.name}</Text>
              </Box>
            </DrawerHeader>
            <DrawerBody overflow="hidden" p={0}>
              {isReady() && <DrawerBodyContent tracker={tracker} />}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </SegmentStatesProvider>
    </FilterPeriodProvider>
  )
}

const DrawerBodyContent: React.FC<{ tracker: BaseTracker } & FetchOptions> = ({
  tracker,
}) => {
  const segmentState = useFirstPhoneSegmentState()

  const {
    data,
    error,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useConversationsIncludingTrackerHits(segmentState.getFilterRequestModel())

  const isLoadingInitialData = !(data || error)

  const handleLoadMore = (): void => {
    fetchNextPage()
  }

  return (
    <AudioProvider>
      <Box h="full" overflowX="hidden">
        <List disablePadding>
          {(data?.pages ?? []).map((d) =>
            d?.conversations.map((c) => (
              <ConversationTrackerHits
                key={c.uid}
                tracker={tracker}
                onOccurrenceChanged={refetch}
                {...c}
              />
            )),
          )}
        </List>
        {!isLoadingInitialData && (
          <Flex my={4} justify="center" w="100%">
            <Button
              onClick={handleLoadMore}
              isDisabled={!hasNextPage || isFetchingNextPage}
              isLoading={isFetchingNextPage}
              borderRadius="md"
              size="sm"
            >
              {!hasNextPage ? (
                <Trans>No more conversations</Trans>
              ) : (
                <Trans>Load more</Trans>
              )}
            </Button>
          </Flex>
        )}
      </Box>
    </AudioProvider>
  )
}

type ConversationWithTrackerHitsProps =
  ConversationsInclTrackerHitsResponseModel['conversations'][0] & {
    tracker: BaseTracker
    onOccurrenceChanged: () => void
  }

type ConversationTrackerHit = {
  id: string
  phrase: string
  timestamp: number
  isIgnoredPhrase: boolean
}

const ConversationTrackerHits = React.memo<ConversationWithTrackerHitsProps>(
  ({
    uid,
    dateTime,
    userUid,
    customer,
    duration,
    subject,
    trackerHits,
    ignoredPhrases,
    tracker,
    deleted,
    qaIsReviewed,
    hasAudio,
    onOccurrenceChanged,
  }) => {
    const currentUser = useCurrentUser()
    const { getTime, pause, playbackContext } = useAudioContext()
    useAudioShortcut()
    const { _Velkommen_trackerExampleCommentPrefixFeature } = useFeatureFlags()
    const user = useSingleUser(userUid)

    const { addContextError, addRecognitionError } = useMarkAsFalseRecognition(
      uid,
      {
        onSettled: () => {
          /**
           * FIXME: temporary hack to make sure that `trackerHits` property
           * on the conversation has been updated.
           */
          setTimeout(() => onOccurrenceChanged(), 1000)
        },
      },
    )

    const timelineHits = React.useMemo(() => {
      if (trackerHits.length === 0) return []
      const trackerHit = trackerHits.find((x) => x.uid === tracker.uid)
      if (trackerHit === undefined) return []
      const { words } = trackerHit
      return words
        .flatMap((w) => {
          return w.timeline.map<ConversationTrackerHit>((t) => {
            const isIgnoredPhrase =
              ignoredPhrases.find((ignoredPhrase) => {
                const timeAndPhraseMatch =
                  ignoredPhrase.timeOffset === t.when &&
                  ignoredPhrase.phrase === w.word
                if (!timeAndPhraseMatch) return false
                if (ignoredPhrase.reason === 'Context') {
                  return ignoredPhrase.trackerUid === tracker.uid
                }
                return true
              }) !== undefined
            return {
              id: `${w.word}:${t.when}`,
              phrase: w.word,
              timestamp: t.when,
              isIgnoredPhrase,
            }
          })
        })
        .sort((a, b) => a.timestamp - b.timestamp)
    }, [trackerHits, tracker, ignoredPhrases])

    const openConversationDetailsDrawer = useConversationDetailsDrawer()

    const onGoToConversation = (): void => {
      logEvent(Event.TrackerExamples_GoToConversation)

      React.startTransition(() => {
        let initialAudioTimestamp: number | undefined
        if (playbackContext?.conversationUid === uid) {
          initialAudioTimestamp = getTime()
          pause()
        }
        openConversationDetailsDrawer({
          uid,
          initialAudioTimestamp,
        })
      })
    }

    const showTrackerHitMenu =
      (currentUser.isAdmin || currentUser.isTeamLead) &&
      currentUser.permissions.playback
    return (
      <Box
        key={uid}
        border={playbackContext?.conversationUid === uid ? '1px solid' : 'none'}
        borderColor={
          playbackContext?.conversationUid === uid ? 'primary.500' : 'none'
        }
        mb="24px"
        boxShadow="md"
        borderBottomRadius="8px"
        borderTopRadius="2px"
        mx="2"
      >
        <Flex
          pl={4}
          pr={2}
          py={2}
          bg="gray.200"
          borderBottom="1px solid"
          borderColor="gray.300"
          borderTopRadius="2px"
        >
          <Box flex="1">
            <Flex justify="space-between">
              <HStack gap={0}>
                <Text fontSize="md" fontWeight="medium">
                  {subject}
                </Text>
                {currentUser.permissions.qualityAssurance && qaIsReviewed && (
                  <Icon boxSize="5" as={IsReviewedCheckmark} />
                )}
              </HStack>
              <IconButton
                aria-label={t`Go to conversation`}
                title={t`Go to conversation`}
                variant="ghost"
                size="xs"
                icon={<MdLaunch />}
                onClick={onGoToConversation}
              />
            </Flex>
            <Flex
              fontSize="sm"
              color="gray.600"
              align="center"
              justify="space-between"
            >
              <Text as="span" flexShrink={0}>{`${i18n.date(
                dateTime,
                dateFormat,
              )} | ${user.name}`}</Text>
              <Text as="span" flexShrink={0}>
                {customer}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <List pb={0} pt={0}>
          {timelineHits.map((hit) => (
            <PlayListItem
              key={hit.id}
              isDisabled={
                !currentUser.permissions.playback ||
                deleted ||
                hit.isIgnoredPhrase
              }
              hasAudio={hasAudio}
              timestamp={hit.timestamp}
              rollbackSeconds={3}
              context={{ conversationUid: uid, phrase: hit.phrase }}
              onPlay={() => {
                logEvent(Event.TrackerExamples_PlayAudio, {
                  date: dateTime.toUTCString(),
                  uid,
                })
              }}
              showAudioPlayer
              additionalActions={(props) => (
                <OptionalAudioPlayerActions
                  {...props}
                  duration={duration}
                  conversationUid={uid}
                  getCreateCommentProps={() => {
                    if (_Velkommen_trackerExampleCommentPrefixFeature) {
                      return {
                        initialTime: hit.timestamp,
                        hiddenTextPrefix: `<${tracker.name}; ${hit.phrase}>: `,
                      }
                    }
                    return {}
                  }}
                  getCreateSnippetProps={() => {
                    return {
                      initialTitle: tracker.name,
                    }
                  }}
                />
              )}
            >
              <Flex
                color={hit.isIgnoredPhrase ? 'textMuted' : 'inherit'}
                align="center"
                justify="space-between"
                flex={1}
              >
                <Text flex={1}>
                  <em>{hit.phrase}</em>
                </Text>
                <Text flex={0} ml={4} color="textMuted" fontSize="sm">
                  {formatTime(hit.timestamp)}
                </Text>
              </Flex>
              {/* Tooltips around `MenuItem`s will be positioned incorrectly (due to menu open animation) unless `autoSelect` is */}
              {showTrackerHitMenu && !hit.isIgnoredPhrase && (
                <ListItemMenu autoSelect={false} isLazy isDisabled={!hasAudio}>
                  <MenuList>
                    <FalseRecognitionMenuItem.RecognitionError
                      isDisabled={deleted}
                      onClick={() => {
                        addRecognitionError([hit.phrase], hit.timestamp)
                      }}
                    />
                    <FalseRecognitionMenuItem.WrongContext
                      isDisabled={deleted}
                      trackerName={tracker.name}
                      onClick={() =>
                        addContextError(
                          [hit.phrase],
                          hit.timestamp,
                          tracker.uid,
                        )
                      }
                    />
                  </MenuList>
                </ListItemMenu>
              )}
            </PlayListItem>
          ))}
        </List>
      </Box>
    )
  },
)
