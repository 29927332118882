import React from 'react'

type ListContextType = {
  dense: boolean
  disableGutters: boolean
}

const ListContext = React.createContext<ListContextType>({
  dense: false,
  disableGutters: false,
})

export default ListContext
