export default {
  variants: {
    'sharing-search': {
      container: {
        borderColor: 'gray.200',
        width: '18px',
        height: '18px',
        fontSize: '7px',
      },
      excessLabel: {
        fontSize: '7px',
        width: '18px',
        height: '18px',
        transform: 'translateX(2px)',
      },
      label: {
        fontSize: '7px',
      },
    },
  },
}
