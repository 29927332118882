import request, { RequestOptions, getErrorObject } from '@capturi/request'
import { useLatest, useMountedState } from 'react-use'

import { trackersAPI } from '@capturi/api-trackers'
import noop from 'lodash/noop'
import uniq from 'lodash/uniq'
import React from 'react'
import { PhraseState } from '../../types'

type SimilarWordsResponse = {
  similarWords: string[]
}

export interface UseSimilarWordsResult {
  similarWords: string[]
  updateSimilarWords: () => void
}

function fetchData<T>(
  requestOptions: RequestOptions,
): [Promise<T>, () => void] {
  const controller = new AbortController()
  const { signal } = controller
  const promise = request<T>({
    ...requestOptions,
    signal,
  })
  return [promise, controller.abort.bind(controller)]
}

function areEqual(p1: string[], p2: string[]): boolean {
  const normalize = (arr: string[]): string[] =>
    arr.sort().filter((x) => x.trim().length > 0)
  const s1 = normalize(p1)
  const s2 = normalize(p2)
  return s1.length === s2.length && s1.every((x, i) => x === s2[i])
}

export function useSimilarWords(fields: PhraseState[]): UseSimilarWordsResult {
  const isMounted = useMountedState()
  const [similarWords, setSimilarWords] = React.useState<string[]>([])
  const abortRequestRef = React.useRef<() => void>(noop)
  const latestPhrasesRef = React.useRef<string[]>([])

  const phraseFieldsRef = useLatest(fields)

  const updateSimilarWords = React.useCallback(async () => {
    const sanitizedPhrases = uniq(
      phraseFieldsRef.current
        .map((x) => x.value.trim())
        // remove empty values
        .filter((x) => x.length > 0),
    )

    if (sanitizedPhrases.length === 0) {
      // Return if we do not have any phrases
      return
    }
    if (areEqual(sanitizedPhrases, latestPhrasesRef.current)) {
      // Return if nothing has changed
      return
    }
    try {
      // Abort ongoing request
      abortRequestRef.current()
      const [promise, abort] = fetchData<SimilarWordsResponse>(
        trackersAPI.getSimilarWords(sanitizedPhrases),
      )
      abortRequestRef.current = abort
      const resp = await promise
      if (isMounted()) {
        setSimilarWords(resp.similarWords)
        latestPhrasesRef.current = sanitizedPhrases.slice()
      }
    } catch (e) {
      const error = await getErrorObject(e)
      if (error.name === 'AbortError') {
        // nevermind, on purpose
        return
      }
    }
  }, [phraseFieldsRef, isMounted])

  // biome-ignore lint/correctness/useExhaustiveDependencies: Update similar words every time the phrases are added or deleted
  React.useEffect(() => {
    // Update similar words every time the phrases are added or deleted
    updateSimilarWords()
  }, [updateSimilarWords, fields])

  return {
    similarWords,
    // Update explicitly, e.g. in a phrase field input onBlur callback
    updateSimilarWords,
  }
}

export const SimilarWordsContext = React.createContext<UseSimilarWordsResult>({
  similarWords: [],
  updateSimilarWords: noop,
})

export const useSimilarWordsContext = (): UseSimilarWordsResult =>
  React.useContext(SimilarWordsContext)
