import { Tracker } from '../types'
import { noop } from '../utils'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: due to the error "Could not find a declaration file for module './loadStonlyTracker'"
import loadStonlyTracker from './loadStonly'

export type StonlyConfig = {
  token: string
}

// Build can't find type in seperate stonly.d.ts file in same folder (search of better options)
declare global {
  interface Window {
    stonlyTrack: (
      identify: string,
      action: string,
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      params?: Record<string, any>,
    ) => void
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    StonlyWidget: (method: string, param?: any, extra?: any) => void
    STONLY_WID: string
  }
}

export default function init({ token }: StonlyConfig): Tracker | undefined {
  if (!token) return
  // Load Stonly JS API's - (Tracker | Widget | Event)
  loadStonlyTracker(token)

  const tracker: Tracker = {
    event: (eventKey) => window.stonlyTrack('track', eventKey),
    identify: (_, user) => {
      if (user) {
        const {
          download = false,
          editDashboard = false,
          editLibrary = false,
          editScore = false,
          editSegment = false,
          editTracker = false,
          organization = false,
          playback = false,
          viewReports = false,
        } = user.permissions

        window.stonlyTrack('identify', user.ownerUid, {
          userUid: user.ownerUid,
          role: user.role,
          download: download,
          editDashboardRight: editDashboard,
          playbackRight: playback,
          editLibraryRight: editLibrary,
          editScoreRight: editScore,
          editSegmentRight: editSegment,
          editTrackerRight: editTracker,
          reportsRight: viewReports,
          organization: organization,
        })
      }
    },
    modalview: noop,
    pageview: () => noop,
    reset: () => noop,
    record: () => noop,
  }

  return tracker
}
