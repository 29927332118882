import { GrantedRole, Role, roleToGrantedRole } from '@capturi/core'
import { User } from '@capturi/stores'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

export const localizeSingularRole = (role: Role): string => {
  switch (role) {
    case 'user':
      return t`User`
    case 'teamlead':
      return t`Team lead`
    case 'administrator':
      return t`Administrator`
    default:
      return role
  }
}

export const localizePluralRole = (role: Role): string => {
  switch (role) {
    case 'user':
      return t`Users`
    case 'teamlead':
      return t`Team leads`
    case 'administrator':
      return t`Administrators`
    default:
      return role
  }
}

export const localizeSingularGrantedRole = (role: GrantedRole): string => {
  switch (role) {
    case 'User':
      return t`User`
    case 'TeamLead':
      return t`Team lead`
    case 'Administrator':
      return t`Administrator`
    case 'API':
      return t`API`
  }
}

export const localizePluralGrantedRole = (role: GrantedRole): string => {
  switch (role) {
    case 'User':
      return t`Users`
    case 'TeamLead':
      return t`Team leads`
    case 'Administrator':
      return t`Administrators`
    case 'API':
      return t`API`
  }
}

type GroupedUsers = {
  usersByRole: Record<Role, User[]>
  usersByGrantedRole: Record<GrantedRole, User[]>
  usersByTeamUid: Record<string, User[]>
}

export const groupUsers = (users: User[]): GroupedUsers =>
  users.reduce<GroupedUsers>(
    ({ usersByGrantedRole, usersByRole, usersByTeamUid }, user) => {
      if (user.teamUid) {
        const teamList = usersByTeamUid[user.teamUid] || []
        teamList.push(user)
        usersByTeamUid[user.teamUid] = teamList
      }
      if (user.role) {
        usersByRole[user.role].push(user)
        const gratedRole = roleToGrantedRole(user.role as Role)
        if (gratedRole) usersByGrantedRole[gratedRole].push(user)
      }

      return { usersByGrantedRole, usersByRole, usersByTeamUid }
    },
    {
      usersByRole: { user: [], teamlead: [], administrator: [] },
      usersByGrantedRole: {
        User: [],
        TeamLead: [],
        Administrator: [],
        API: [],
      },
      usersByTeamUid: {},
    },
  )

export function getPercentage(
  count: number,
  totalCount: number,
): string | null {
  const percentage = count / totalCount
  if (Number.isNaN(percentage)) return null

  const formattedPercent = i18n.number(percentage, {
    style: 'percent',
    minimumFractionDigits: 1,
  })
  return formattedPercent
}

export function formatArray(array: string[]): string[] {
  const joinedEntries = array.join(', ')
  const splitEntries = joinedEntries.split(' ')

  return splitEntries
}
