import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@capturi/ui-components'
import { Divider, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

import { i18n } from '@lingui/core'
import { ValidationError } from '../hooks/usePreviewAccess'
import {
  PreviewAccessWarningText,
  ValidationErrorText,
} from './WarningsAndErrors'

export const SharingModalFormContent: React.FC<{
  modalHeader: string
  createdBy?: string | null
  updatedBy?: string | null
  created?: Date | null
  updated?: Date | null

  submitButtonText: string
  isPreviewAccessLoading: boolean
  previewAccessWarningText: string | undefined
  validationError: ValidationError | string | undefined
  onClose: () => void
  children?: React.ReactNode
}> = ({
  modalHeader,
  createdBy,
  updatedBy,
  created,
  updated,
  submitButtonText,
  isPreviewAccessLoading,
  previewAccessWarningText,
  validationError,
  onClose,
  children,
}) => {
  return (
    <>
      <ModalHeader>{modalHeader}</ModalHeader>
      <Divider />
      <ModalBody>{children}</ModalBody>
      <Divider />
      <ModalFooter>
        <Flex alignItems="flex-start" justifyContent="space-between" w="100%">
          <HStack spacing={4} justify="space-between" flex={1}>
            <VStack align="start" gap={0}>
              <VStack gap={0} color="gray.600" fontSize="xs" align="start">
                {created && (
                  <Text>
                    <Trans>Created by</Trans> {createdBy}{' '}
                    {i18n.date(created, { dateStyle: 'long' })}
                  </Text>
                )}
                {updated && (
                  <Text>
                    <Trans>Updated by</Trans> {updatedBy}{' '}
                    {i18n.date(updated, { dateStyle: 'long' })}
                  </Text>
                )}
              </VStack>
              {previewAccessWarningText ||
                (validationError && (
                  <VStack gap={0} mt={3} align="start">
                    <PreviewAccessWarningText
                      warningText={previewAccessWarningText}
                    />
                    <ValidationErrorText validationError={validationError} />
                  </VStack>
                ))}
            </VStack>
            <Flex>
              <Button type="button" onClick={onClose}>
                <Trans>Cancel</Trans>
              </Button>
              <Button
                primary
                type="submit"
                isDisabled={validationError !== undefined}
                isLoading={isPreviewAccessLoading}
              >
                {submitButtonText}
              </Button>
            </Flex>
          </HStack>
        </Flex>
      </ModalFooter>
    </>
  )
}
