import { ConversationResponseModel } from '@capturi/api-conversations'
import {
  useTrackerByUid,
  useTrackerFolders,
  useTrackers,
} from '@capturi/api-trackers'
import { useOrganization } from '@capturi/stores'
import React, { useCallback } from 'react'

import TrackerMetadata from '../TrackerMetadata'
import { ListItems } from './domain'

export function useTableOfContents(
  trackerHits: NonNullable<ConversationResponseModel['trackerHits']>,
  hitMap: Map<string, TrackerMetadata>,
): {
  items: ListItems[]
  root: ListItems['trackers']
  combined: ListItems['trackers']
} {
  const { data: folders } = useTrackerFolders()
  const { data: trackers } = useTrackers()
  const { conversationLanguage } = useOrganization()
  const compare = useCallback(Intl.Collator(conversationLanguage).compare, [])
  const trackersByUid = useTrackerByUid(trackers)

  const trackersByFolderUid = React.useMemo(() => {
    return trackerHits.reduce<Map<string | null, ListItems['trackers']>>(
      (memo, trackerHit) => {
        const tracker = trackersByUid?.[trackerHit.uid]
        const key = tracker?.folderUid || null
        const a = memo.get(key) || []
        a.push({ trackerHit, tracker })
        memo.set(key, a)
        return memo
      },
      new Map(),
    )
  }, [trackerHits, trackersByUid])

  const items = React.useMemo(
    () =>
      (folders ?? [])
        .reduce<ListItems[]>((memo, f) => {
          if (!trackersByFolderUid.has(f.uid)) return memo
          const trackers = (trackersByFolderUid.get(f.uid) || [])?.sort(
            (a, b) =>
              compare(
                a.tracker?.name ?? a.trackerHit.uid,
                b.tracker?.name ?? a.trackerHit.uid,
              ),
          )
          const hitCount = trackers.reduce((acc, curr) => {
            const trackerHit = curr.trackerHit
            return acc + (hitMap.get(trackerHit.uid)?.count ?? 0)
          }, 0)

          memo.push({
            title: f.title,
            uid: f.uid,
            hitCount: hitCount,
            trackers: trackers,
          })
          return memo
        }, [])
        .sort((a, b) => compare(a.title, b.title)),
    [compare, folders, hitMap, trackersByFolderUid],
  )

  const root = React.useMemo(
    () =>
      (trackersByFolderUid.get(null) || []).sort((a, b) =>
        compare(
          a.tracker?.name ?? a.trackerHit.uid,
          b.tracker?.name ?? b.trackerHit.uid,
        ),
      ),
    [compare, trackersByFolderUid],
  )

  const combined = React.useMemo(() => {
    const folderTrackers = items.flatMap(
      (t) => trackersByFolderUid.get(t.uid) ?? [],
    )
    return [...root, ...folderTrackers]
  }, [root, items, trackersByFolderUid])

  return { items, root, combined }
}
