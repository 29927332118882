import { useCallback, useEffect, useRef } from 'react'
import { modalStore } from './store'

/**
 * Callback types provided for descriptive type-hints
 */
export type ShowModal<P extends BaseModalComponentProps> = (
  props?: Omit<P, keyof BaseModalComponentProps> & {
    onClose?: () => void
    onSubmit?: P['onSubmit']
  },
) => void

export type HideModal = () => void

// biome-ignore lint/suspicious/noExplicitAny: :'(
export type BaseModalComponentProps<TResult = any> = {
  onClose: () => void
  onSubmit?: (result: TResult) => void
  /**
   * @deprecated isOpen is always true
   */
  isOpen?: boolean
}

export const useModal = <T extends BaseModalComponentProps>(
  component: React.FunctionComponent<T>,
): [ShowModal<T>, HideModal] => {
  const uid = useRef(crypto.randomUUID())

  //When we open a modal it is added to the modal store
  //and rendered in the ModalRendere component
  const onOpen: ShowModal<T> = useCallback(
    (props) => {
      modalStore.setState((modals) => [
        ...modals,
        { uid: uid.current, Component: component, props },
      ])
    },
    [component],
  )

  //To close the modal we remove ot from the modal store
  const onClose: HideModal = useCallback(() => {
    modalStore.setState((modals) => modals.filter((m) => m.uid !== uid.current))
  }, [])

  useEffect(() => {
    // to make sure we close the modal, when the component creating it is no longer
    return onClose
  }, [onClose])

  return [onOpen, onClose]
}
