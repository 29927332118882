import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@capturi/ui-components'
import { FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { ChangeEvent, FormEvent, useRef, useState } from 'react'

import { LibraryFolderPermissions, NewFolder } from '../types'
import FolderPermissions from './FolderPermissions'

type Props = {
  isOpen?: boolean
  onClose: () => void
  onSubmit: (folder: NewFolder) => void
} & Omit<ModalProps, 'children' | 'isOpen' | 'onClose'>

const CreateFolderDialog: React.FC<Props> = ({
  isOpen = true,
  onClose,
  onSubmit,
  ...modalProps
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const nameInputRef = useRef<HTMLInputElement>(null)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [permissions, setPermissions] =
    useState<LibraryFolderPermissions | null>(null)

  const handleCreate = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (formRef.current?.checkValidity() === false) {
      return
    }
    onSubmit({ name, description, permissions })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={nameInputRef}
      {...modalProps}
    >
      <ModalOverlay>
        <ModalContent>
          <form ref={formRef} onSubmit={handleCreate}>
            <ModalHeader>
              <Trans>Create playlist</Trans>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={4}>
                <FormControl isRequired>
                  <FormLabel htmlFor="folderName">
                    <Trans>Name</Trans>
                  </FormLabel>
                  <Input
                    ref={nameInputRef}
                    id="folderName"
                    placeholder={t`Name`}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                      setName(e.target.value)
                    }
                    value={name}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="folderDesc">
                    <Trans>Description</Trans>
                  </FormLabel>
                  <Input
                    id="folderDesc"
                    placeholder={t`Description`}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                      setDescription(e.target.value)
                    }
                    value={description}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="folderPermissions">
                    <Trans>Access to the playlist</Trans>
                  </FormLabel>
                  <FolderPermissions
                    value={permissions}
                    onChange={setPermissions}
                    fixCurrentUser
                    fixCurrentUserRole
                    id="folderPermissions"
                  />
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Stack isInline spacing={4} justify="flex-end">
                <Button type="button" secondary onClick={onClose}>
                  <Trans>Cancel</Trans>
                </Button>
                <Button type="submit" primary>
                  <Trans>Create</Trans>
                </Button>
              </Stack>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default CreateFolderDialog
