export function sanitizeFilter<T extends object>(
  widgetFilter: T,
): Partial<T> | undefined {
  if (!widgetFilter) return

  return Object.entries(widgetFilter).reduce<Partial<T>>(
    (memo, [key, value]) => {
      if (value == null || value === '') {
        return memo
      }

      if (Array.isArray(value)) {
        if (value.length === 0) return memo
        if (typeof value[0] === 'object') {
          const newValue = value.map((o) => sanitizeFilter(o))
          /* TODO: Fix type - keys can't be used to partial indexing of memo */
          // @ts-ignore
          memo[key] = newValue
          return memo
        }
      }

      if (typeof value === 'object' && !Array.isArray(value)) {
        const sanitizedValue = sanitizeFilter(value)

        if (!sanitizedValue || Object.keys(sanitizedValue).length === 0)
          return memo
        // @ts-ignore
        memo[key] = sanitizedValue
        return memo
      }

      memo[key as keyof T] = value
      return memo
    },
    {},
  )
}
