import {
  ConversationTimeSeriesBaseEntry,
  ConversationTimeSeriesResponse,
  TrackerEmployeeTimeSeriesResponse,
  TrackerTeamTimeSeriesResponse,
  TrackerWordTimeSeriesResponse,
  insightsAPI,
} from '@capturi/api-insights'
import { BaseTracker } from '@capturi/api-trackers'
import {
  CartesianGrid,
  DashedLineVariants,
  DateBucket,
  Line,
  LineChartSkeleton,
  LineDescriptor,
  MultiChannelTimeSeriesTooltip,
  PrimaryLine,
  ReferenceLine,
  ReferenceLineDescriptor,
  TimeSeriesChart,
  XAxis,
  YAxis,
  adjustDateForDSTOverlap,
} from '@capturi/charts'
import { TimeResolution, useCurrentUser, useTeams } from '@capturi/core'
import {
  Segment,
  createPeriodParams,
  segmentConfigurations,
  useFetchMultiSegments,
  useFetchSegments,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import request, { ResponseError } from '@capturi/request'
import { useUsers } from '@capturi/stores'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@capturi/ui-components'
import { Box, HStack, Stack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import {
  UseQueryOptions,
  UseQueryResult,
  useQueries,
} from '@tanstack/react-query'
import { useStore } from '@tanstack/react-store'
import { isWeekend } from 'date-fns'
import noop from 'lodash/noop'
import TeamFilterRequiredMessage from 'pages/analytics/shared/components/TeamFilterRequiredMessage'
import React, { useCallback, useMemo } from 'react'
import { ResponsiveContainer, Tooltip } from 'recharts'

import { useAtomValue } from 'jotai'
import ResolutionButtons from '../../shared/components/ResolutionButtons'
import { useTimeResolutionContext } from '../../shared/contexts/ResolutionContext'
import useOnlyWeekdaysToggle from '../../shared/hooks/useOnlyWeekdaysToggle'
import { POLL_INTERVAL } from '../constants'
import { Event, logEvent } from '../events'
import { useRevalidateData } from '../hooks/useRevalidateData'
import {
  plottableTeamsAtom,
  plottableUsersAtom,
  plottableWordsAtom,
  timeSeriesDataTabIndexStore,
} from '../state'
import { TrackerStats } from '../useSegmentsOverview'
import {
  formatHitRate,
  isProcessingInCurrentPeriod,
  roundHitRate,
} from '../utils'
import TeamHits from './TeamHits'
import UserHits from './UserHits'
import WordHits from './WordHits'

type Props = {
  segmentsOverview: Segment<TrackerStats>[]
  tracker: BaseTracker
}

export type CaseTimeSeriesBaseEntry = {
  cases: number
  casesWithHit: number
  casesWithHitPercent: number
  dateTime: Date
}

export type CaseTimeSeriesResponse = {
  series: CaseTimeSeriesBaseEntry[]
}

/* Text phrases */

export type TextTrackerPhraseTimeSeriesEntry = CaseTimeSeriesBaseEntry & {
  phrase: string
}

export type TextTrackerPhraseTimeSeriesResponse = {
  series: TextTrackerPhraseTimeSeriesEntry[]
}

type TextTrackerPhraseTimeSeriesModel = {
  source: string
  phrase: string
  color: string
  label: string
  series: TextTrackerPhraseTimeSeriesEntry[]
}

/* Text users */

export type TextTrackerUserTimeSeriesEntry = CaseTimeSeriesBaseEntry & {
  userUid: string
}

export type TextTrackerUserTimeSeriesResponse = {
  series: TextTrackerUserTimeSeriesEntry[]
}

type TextTrackerUserTimeSeriesModel = {
  source: string
  userUid: string
  color: string
  label: string
  series: TextTrackerUserTimeSeriesEntry[]
}

/* Text teams */

export type TextTrackerTeamTimeSeriesEntry = CaseTimeSeriesBaseEntry & {
  teamUid: string
}

export type TextTrackerTeamTimeSeriesResponse = {
  series: TextTrackerTeamTimeSeriesEntry[]
}

type TextTrackerTeamTimeSeriesModel = {
  source: string
  teamUid: string
  color: string
  label: string
  series: TextTrackerTeamTimeSeriesEntry[]
}

function groupTimeSeriesResponseBySource<T, U>(
  sourceQueries: {
    source: string
    segmentColor: string
    segmentLabel: string
    requestOptions: UseQueryOptions<T, ResponseError, U>
  }[],
  response: UseQueryResult<T, ResponseError>[],
): {
  [source: string]: {
    isLoading: boolean
    refetch: () => void
    segments: Segment<T>[]
    error?: Error | null
    source: string
  }
} {
  return response.reduce<{
    [source: string]: {
      isLoading: boolean
      refetch: () => void
      segments: Segment<T>[]
      error?: Error | null
      source: string
    }
  }>((memo, result, i) => {
    if (sourceQueries[i] === undefined) {
      return memo
    }
    const { source, segmentColor, segmentLabel } = sourceQueries[i]

    if (memo[source] === undefined) {
      memo[source] = {
        source,
        segments: [],
        isLoading: false,
        refetch: noop,
        error: undefined,
      }
    }
    memo[source].segments.push({
      color: segmentColor,
      label: segmentLabel,
      data: result.isSuccess ? result.data : null,
    })
    memo[source].isLoading = result.isFetching
    memo[source].refetch = result.refetch
    memo[source].error = result.error

    return memo
  }, {})
}

const TimeDataContainer: React.FC<Props> = ({ tracker, segmentsOverview }) => {
  const currentUser = useCurrentUser()
  const tabIndex = useStore(timeSeriesDataTabIndexStore)
  const handleTabIndexChange = useCallback((index: number) => {
    timeSeriesDataTabIndexStore.setState(() => index)
  }, [])

  const secondaryChartType: 'words' | 'users' | 'teams' = (() => {
    if (currentUser.isAdminOrTeamLead) {
      if (tabIndex === 1) {
        return 'users'
      }
      if (tabIndex === 2) {
        return 'teams'
      }
    }
    return 'words'
  })()

  const { states, textSegmentStates, getIndexForState } =
    useSegmentStatesContext()

  const { resolution, setResolution } = useTimeResolutionContext()
  const { periodDef, period } = useFilterPeriodContext()
  const tzOffset = period.from.getTimezoneOffset() * -1

  const { getUserByUid } = useUsers()
  const { getTeamByUid } = useTeams()
  const [plotOnlyWeekdays, OnlyWeekdaysToggle] = useOnlyWeekdaysToggle()

  const plottableTeams = useAtomValue(plottableTeamsAtom(tracker.uid))
  const plottableWords = useAtomValue(plottableWordsAtom(tracker.uid))
  const plottableUsers = useAtomValue(plottableUsersAtom(tracker.uid))

  const onResolutionChange = (resolution: TimeResolution): void => {
    setResolution(resolution)
    logEvent(Event.TimeSeries_ChangeResolution, {
      period: periodDef.name,
    })
  }

  const processingInCurrentPeriod = isProcessingInCurrentPeriod({
    isProcessing: tracker.isProcessing,
    processingProgress: tracker.processingProgress,
    isTextProcessing: tracker.isTextProcessing,
    textProcessingProgress: tracker.textProcessingProgress,
    periodFrom: period.from,
  })

  // Fetch segment data for tracker
  const {
    segments,
    revalidate: revalidateTrackerHitsTimeSeries,
    isLoading,
    isEnabled,
  } = useFetchSegments<ConversationTimeSeriesResponse>(
    () =>
      insightsAPI.getSpeakerTrackerHitsTimeSeries(
        tracker.uid,
        resolution,
        tzOffset,
      ),
    { refetchInterval: processingInCurrentPeriod ? POLL_INTERVAL : false },
  )

  // Fetch segment data for all selected words
  const wordTimeSeriesData =
    useFetchMultiSegments<TrackerWordTimeSeriesResponse>(
      plottableWords,
      (word) =>
        insightsAPI.getWordHitsTimeSeries(
          tracker.uid,
          word,
          resolution,
          tzOffset,
        ),
      {
        // Do not fetch data unless on words tab
        enabled: secondaryChartType === 'words',
        refetchInterval:
          processingInCurrentPeriod && currentUser.isAdminOrTeamLead
            ? POLL_INTERVAL
            : false,
      },
    )

  // Fetch segment data for all selected users
  const userTimeSeriesData =
    useFetchMultiSegments<TrackerEmployeeTimeSeriesResponse>(
      plottableUsers,
      (userUid) =>
        insightsAPI.getUserHitsTimeSeries(
          tracker.uid,
          userUid,
          resolution,
          tzOffset,
        ),
      {
        // Do not fetch data unless on words tab
        enabled: secondaryChartType === 'users',
        refetchInterval:
          processingInCurrentPeriod && currentUser.isAdminOrTeamLead
            ? POLL_INTERVAL
            : false,
      },
    )

  // Fetch segment data for all selected teams
  const teamTimeSeriesData =
    useFetchMultiSegments<TrackerTeamTimeSeriesResponse>(
      plottableTeams,
      (teamUid) =>
        insightsAPI.getTeamHitsTimeSeries(
          tracker.uid,
          teamUid,
          resolution,
          tzOffset,
        ),
      {
        // Do not fetch data unless on words tab
        enabled: secondaryChartType === 'teams',
        refetchInterval:
          processingInCurrentPeriod && currentUser.isAdminOrTeamLead
            ? POLL_INTERVAL
            : false,
      },
    )

  // Fetch case data
  const textTimeSeriesData = useQueries({
    queries: textSegmentStates.map<UseQueryOptions<CaseTimeSeriesResponse>>(
      (filter) => ({
        queryKey: [
          'insights/trackers/text',
          tracker.uid,
          'time-series',
          filter.values,
          periodDef,
          resolution,
          tzOffset,
        ],
        queryFn: (): Promise<CaseTimeSeriesResponse> =>
          request.post(`insights/trackers/text/${tracker.uid}/time-series`, {
            query: {
              resolution: resolution,
              offset: tzOffset,
              'api-version': '3.3',
            },
            json: {
              ...filter.values,
              ...createPeriodParams(periodDef.create(new Date())),
            },
          }),
        refetchInterval: processingInCurrentPeriod ? POLL_INTERVAL : false,
      }),
    ),
  })

  const textSegments: Segment<CaseTimeSeriesResponse>[] =
    textTimeSeriesData.map((x, index) => {
      const segmentIndex = getIndexForState(textSegmentStates[index])
      return {
        color: segmentConfigurations[segmentIndex].color,
        label: segmentConfigurations[segmentIndex].label,
        data: x.data ? x.data : null,
      }
    })

  const textPhrasesSourceQueries: Array<{
    source: string
    segmentColor: string
    segmentLabel: string
    requestOptions: UseQueryOptions<
      TextTrackerPhraseTimeSeriesResponse,
      ResponseError,
      TextTrackerPhraseTimeSeriesModel
    >
  }> = plottableWords.flatMap((phrase) =>
    textSegmentStates.map((filter, segmentStateIndex) => {
      const segmentIndex = getIndexForState(
        textSegmentStates[segmentStateIndex],
      )
      return {
        source: phrase,
        phrase: phrase,
        segmentColor: segmentConfigurations[segmentIndex].color,
        segmentLabel: segmentConfigurations[segmentIndex].label,
        requestOptions: {
          queryKey: [
            'insights',
            'trackers',
            'text',
            tracker.uid,
            'phrases',
            phrase,
            filter.values,
            periodDef,
            resolution,
            tzOffset,
          ],
          queryFn: (): Promise<TextTrackerPhraseTimeSeriesResponse> =>
            request.post<TextTrackerPhraseTimeSeriesResponse>(
              `insights/trackers/text/${tracker.uid}/phrases/${phrase}/time-series`,
              {
                query: {
                  resolution: resolution,
                  offset: tzOffset,
                  'api-version': '3.3',
                },
                json: {
                  ...filter.values,
                  ...createPeriodParams(periodDef.create(new Date())),
                },
              },
            ),
          select: (
            data: TextTrackerPhraseTimeSeriesResponse,
          ): TextTrackerPhraseTimeSeriesModel => ({
            source: phrase,
            phrase: phrase,
            color: segmentConfigurations[segmentIndex].color,
            label: segmentConfigurations[segmentIndex].label,
            series: data.series,
          }),
          enabled: secondaryChartType === 'words',
          refetchInterval:
            processingInCurrentPeriod && currentUser.isAdminOrTeamLead
              ? POLL_INTERVAL
              : false,
        },
      }
    }),
  )

  const textPhrasesTimeSeriesResponse: UseQueryResult<
    TextTrackerPhraseTimeSeriesModel,
    ResponseError
  >[] = useQueries({
    queries: textPhrasesSourceQueries.map((query) => query.requestOptions),
  })

  const textPhrasesGroupedBySource = groupTimeSeriesResponseBySource(
    textPhrasesSourceQueries,
    textPhrasesTimeSeriesResponse,
  )
  const textPhrasesTimeSeriesData = Object.values(textPhrasesGroupedBySource)

  /* TEXT USERS */

  const textUserHitsSourceQueries: Array<{
    source: string
    segmentColor: string
    segmentLabel: string
    requestOptions: UseQueryOptions<
      TextTrackerUserTimeSeriesResponse,
      ResponseError,
      TextTrackerUserTimeSeriesModel
    >
  }> = plottableUsers.flatMap((userUid) =>
    textSegmentStates.map((filter, segmentStateIndex) => {
      const segmentIndex = getIndexForState(
        textSegmentStates[segmentStateIndex],
      )
      return {
        source: userUid,
        userUid: userUid,
        segmentColor: segmentConfigurations[segmentIndex].color,
        segmentLabel: segmentConfigurations[segmentIndex].label,
        requestOptions: {
          queryKey: [
            'insights',
            'trackers',
            'text',
            tracker.uid,
            'users',
            userUid,
            filter.values,
            periodDef,
            resolution,
            tzOffset,
          ],
          queryFn: (): Promise<TextTrackerUserTimeSeriesResponse> =>
            request.post<TextTrackerUserTimeSeriesResponse>(
              `insights/trackers/text/${tracker.uid}/users/${userUid}/time-series`,
              {
                query: {
                  resolution: resolution,
                  offset: tzOffset,
                  'api-version': '3.3',
                },
                json: {
                  ...filter.values,
                  ...createPeriodParams(periodDef.create(new Date())),
                },
              },
            ),
          select: (
            data: TextTrackerUserTimeSeriesResponse,
          ): TextTrackerUserTimeSeriesModel => ({
            source: userUid,
            userUid: userUid,
            color: segmentConfigurations[segmentIndex].color,
            label: segmentConfigurations[segmentIndex].label,
            series: data.series,
          }),
          enabled: secondaryChartType === 'users',
          refetchInterval:
            processingInCurrentPeriod && currentUser.isAdminOrTeamLead
              ? POLL_INTERVAL
              : false,
        },
      }
    }),
  )

  const textUserHitsTimeSeriesResponse: UseQueryResult<
    TextTrackerUserTimeSeriesModel,
    ResponseError
  >[] = useQueries({
    queries: textUserHitsSourceQueries.map((query) => query.requestOptions),
  })

  const textUserHitsGroupedBySource = groupTimeSeriesResponseBySource(
    textUserHitsSourceQueries,
    textUserHitsTimeSeriesResponse,
  )
  const textUserHitsTimeSeriesData = Object.values(textUserHitsGroupedBySource)

  /* TEXT TEAMS */

  const textTeamHitsSourceQueries: Array<{
    source: string
    segmentColor: string
    segmentLabel: string
    requestOptions: UseQueryOptions<
      TextTrackerTeamTimeSeriesResponse,
      ResponseError,
      TextTrackerTeamTimeSeriesModel
    >
  }> = plottableTeams.flatMap((teamUid) =>
    textSegmentStates.map((filter, segmentStateIndex) => {
      const segmentIndex = getIndexForState(
        textSegmentStates[segmentStateIndex],
      )
      return {
        source: teamUid,
        teamUid: teamUid,
        segmentColor: segmentConfigurations[segmentIndex].color,
        segmentLabel: segmentConfigurations[segmentIndex].label,
        requestOptions: {
          queryKey: [
            'insights',
            'trackers',
            'text',
            tracker.uid,
            'teams',
            teamUid,
            filter.values,
            periodDef,
            resolution,
            tzOffset,
          ],
          queryFn: (): Promise<TextTrackerTeamTimeSeriesResponse> =>
            request.post<TextTrackerTeamTimeSeriesResponse>(
              `insights/trackers/text/${tracker.uid}/teams/${teamUid}/time-series`,
              {
                query: {
                  resolution: resolution,
                  offset: tzOffset,
                  'api-version': '3.3',
                },
                json: {
                  ...filter.values,
                  ...createPeriodParams(periodDef.create(new Date())),
                },
              },
            ),
          select: (
            data: TextTrackerTeamTimeSeriesResponse,
          ): TextTrackerTeamTimeSeriesModel => ({
            source: teamUid,
            teamUid: teamUid,
            color: segmentConfigurations[segmentIndex].color,
            label: segmentConfigurations[segmentIndex].label,
            series: data.series,
          }),
          enabled: secondaryChartType === 'teams',
          refetchInterval:
            processingInCurrentPeriod && currentUser.isAdminOrTeamLead
              ? POLL_INTERVAL
              : false,
        },
      }
    }),
  )

  const textTeamHitsTimeSeriesResponse: UseQueryResult<
    TextTrackerTeamTimeSeriesModel,
    ResponseError
  >[] = useQueries({
    queries: textTeamHitsSourceQueries.map((query) => query.requestOptions),
  })

  const textTeamHitsGroupedBySource = groupTimeSeriesResponseBySource(
    textTeamHitsSourceQueries,
    textTeamHitsTimeSeriesResponse,
  )
  const textTeamHitsTimeSeriesData = Object.values(textTeamHitsGroupedBySource)

  useRevalidateData(() => {
    if (isEnabled) revalidateTrackerHitsTimeSeries()
    wordTimeSeriesData.forEach((x) => (x.isEnabled ? x.revalidate() : noop()))
    userTimeSeriesData.forEach((x) => (x.isEnabled ? x.revalidate() : noop()))
    teamTimeSeriesData.forEach((x) => (x.isEnabled ? x.revalidate() : noop()))
    textTimeSeriesData.forEach((x) => x.refetch())
    textPhrasesTimeSeriesData.forEach((x) => x.refetch())
    textUserHitsTimeSeriesData.forEach((x) => x.refetch())
    textTeamHitsTimeSeriesData.forEach((x) => x.refetch())
  })

  const results = React.useMemo(() => {
    const buckets: Record<string, DateBucket> = {}

    const getOrCreateBucket = (date: Date): DateBucket => {
      const bucketKey = date.toISOString()
      if (buckets[bucketKey] === undefined) {
        buckets[bucketKey] = {
          date,
          words: {},
          users: {},
          teams: {},
          phrases: {},
        }
      }
      return buckets[bucketKey]
    }

    function addSegmentDataToBucket<
      T extends CaseTimeSeriesBaseEntry | ConversationTimeSeriesBaseEntry,
    >(
      segments: Segment<{
        series: T[]
      } | null>[],
      getHitPercent: (x: T) => number | null,
      nestedProperty?: string,
    ): void {
      segments.forEach((s) => {
        ;(s.data?.series ?? []).forEach((x) => {
          const date = adjustDateForDSTOverlap(x.dateTime, tzOffset)

          // Omit weekend data?
          if (
            plotOnlyWeekdays &&
            isWeekend(date) &&
            (resolution === 'Hours' || resolution === 'Days')
          ) {
            return
          }

          const segmentKey = s.label
          const segmentColor = s.color

          const { dateTime, ...restData } = x
          const hitPercent = getHitPercent(x)
          const value = hitPercent == null ? null : hitPercent / 100

          const bucket = getOrCreateBucket(date)
          const datum = {
            value,
            segmentColor,
            ...restData,
          }

          let root = bucket
          if (nestedProperty !== undefined) {
            root = bucket[nestedProperty] ?? {}
            root[segmentKey] = root[segmentKey] ?? []
          }

          if (Array.isArray(root[segmentKey])) {
            root[segmentKey] = [...root[segmentKey], datum]
          } else {
            root[segmentKey] = datum
          }
        })
      })
    }

    function addConversationSegmentDataToBucket(
      segments: Segment<{ series: ConversationTimeSeriesBaseEntry[] } | null>[],
      nestedProperty?: string,
    ): void {
      addSegmentDataToBucket(
        segments,
        (x) => x.conversationsWithHitPercent,
        nestedProperty,
      )
    }

    function addTextSegmentDataToBucket(
      segments: Segment<{ series: CaseTimeSeriesBaseEntry[] } | null>[],
      nestedProperty?: string,
    ): void {
      addSegmentDataToBucket(
        segments,
        (x) => x.casesWithHitPercent,
        nestedProperty,
      )
    }

    // Add primary segment data
    addConversationSegmentDataToBucket(segments)

    addTextSegmentDataToBucket(textSegments)

    // Add word data

    wordTimeSeriesData.forEach((wordData) => {
      addConversationSegmentDataToBucket(wordData.segments, 'words')
    })

    textPhrasesTimeSeriesData.forEach((phraseData) => {
      addTextSegmentDataToBucket(phraseData.segments, 'phrases')
    })

    // Add user data
    userTimeSeriesData.forEach((userData) => {
      addConversationSegmentDataToBucket(userData.segments, 'users')
    })

    textUserHitsTimeSeriesData.forEach((userData) => {
      addTextSegmentDataToBucket(userData.segments, 'users')
    })

    // Add team data
    teamTimeSeriesData.forEach((teamData) => {
      addConversationSegmentDataToBucket(teamData.segments, 'teams')
    })

    textTeamHitsTimeSeriesData.forEach((teamData) => {
      addTextSegmentDataToBucket(teamData.segments, 'teams')
    })

    // Main lines

    const segmentLines = segments.reduce<LineDescriptor[]>((memo, s) => {
      // Only add lines when we also have data. Charts will do weird aninmations otherwise.
      if (s.data == null) return memo
      memo.push({
        id: `primary:phone-${s.label}:${s.color}`,
        color: s.color,
        label: s.label,
        getValue: (d) => {
          const value = d[s.label]?.value
          if (value == null) return null
          return roundHitRate(value)
        },
      })

      return memo
    }, [])

    const textSegmentLines = textSegments.reduce<LineDescriptor[]>(
      (memo, s) => {
        // Only add lines when we also have data. Charts will do weird aninmations otherwise.
        if (s.data == null) return memo
        memo.push({
          id: `primary:text-${s.label}:${s.color}`,
          color: s.color,
          label: s.label,
          getValue: (d) => {
            const value = d[s.label]?.value
            if (value == null) return null
            return roundHitRate(value)
          },
        })
        return memo
      },
      [],
    )

    // Words and phrases

    const trackerWords = Object.keys(tracker.speech?.phrasesSettings ?? {})
    const wordLines = wordTimeSeriesData.flatMap<LineDescriptor>(
      (wordData, i) => {
        const variant = DashedLineVariants[i % DashedLineVariants.length]
        return wordData.segments
          .filter(() => trackerWords.includes(wordData.source))
          .map((s) => ({
            id: `secondary:word-${wordData.source}:${s.label}:${s.color}`,
            color: s.color,
            label: wordData.source,
            getValue: (d) => {
              const value = d.words[s.label]?.[i]?.value
              if (value == null) return null
              return roundHitRate(value)
            },
            variant,
          }))
      },
    )

    const phraseSegmentLines =
      textPhrasesTimeSeriesData.flatMap<LineDescriptor>((phraseData, i) => {
        const variant = DashedLineVariants[i % DashedLineVariants.length]
        return phraseData.segments
          .filter(() => tracker.text?.phrases?.includes(phraseData.source))
          .map((s) => ({
            id: `secondary:textPhrase-${phraseData.source}:${s.label}:${s.color}`,
            color: s.color,
            label: phraseData.source,
            getValue: (d) => {
              const value = d.phrases[s.label]?.[i]?.value
              if (value == null) return null
              return roundHitRate(value)
            },
            variant,
          }))
      })

    // Users

    const phoneUserLines = userTimeSeriesData.flatMap<LineDescriptor>(
      (userData, i) => {
        const variant = DashedLineVariants[i % DashedLineVariants.length]
        return userData.segments
          .filter((s) =>
            s.data?.series.some((series) => series.conversations > 0),
          )
          .map((s) => {
            const userUid = userData.source
            return {
              id: `secondary:phone-user-${userUid}:${s.label}:${s.color}`,
              color: s.color,
              label: getUserByUid(userUid).name,
              getValue: (d) => {
                const value = d.users[s.label]?.[i]?.value
                if (value == null) return null
                return roundHitRate(value)
              },
              variant,
            }
          })
      },
    )

    const textUserLines = textUserHitsTimeSeriesData.flatMap<LineDescriptor>(
      (userData, i) => {
        const variant = DashedLineVariants[i % DashedLineVariants.length]
        return userData.segments
          .filter((s) => s.data?.series.some((series) => series.cases > 0))
          .map((s) => {
            const userUid = userData.source
            return {
              id: `secondary:email-user-${userUid}:${s.label}:${s.color}`,
              color: s.color,
              label: getUserByUid(userUid).name,
              getValue: (d) => {
                const value = d.users[s.label]?.[i]?.value
                if (value == null) return null
                return roundHitRate(value)
              },
              variant,
            }
          })
      },
    )

    const userLines = [...phoneUserLines, ...textUserLines]

    // Teams

    const phoneTeamLines = teamTimeSeriesData.flatMap<LineDescriptor>(
      (teamData, i) => {
        const variant = DashedLineVariants[i % DashedLineVariants.length]
        return teamData.segments
          .filter((s) =>
            s.data?.series.some((series) => series.conversations > 0),
          )
          .map((s) => {
            const teamUid = teamData.source
            return {
              id: `secondary:phone-team-${teamUid}:${s.label}:${s.color}`,
              color: s.color,
              label: getTeamByUid(teamUid)?.name ?? teamUid,
              getValue: (d) => {
                const value = d.teams[s.label]?.[i]?.value
                if (value == null) return null
                return roundHitRate(value)
              },
              variant,
            }
          })
      },
    )

    const textTeamLines = textTeamHitsTimeSeriesData.flatMap<LineDescriptor>(
      (teamData, i) => {
        const variant = DashedLineVariants[i % DashedLineVariants.length]
        return teamData.segments
          .filter((s) => s.data?.series.some((series) => series.cases > 0))
          .map((s) => {
            const teamUid = teamData.source
            return {
              id: `secondary:text-team-${teamUid}:${s.label}:${s.color}`,
              color: s.color,
              label: getTeamByUid(teamUid)?.name ?? teamUid,
              getValue: (d) => {
                const value = d.teams[s.label]?.[i]?.value
                if (value == null) return null
                return roundHitRate(value)
              },
              variant,
            }
          })
      },
    )
    const teamLines = [...phoneTeamLines, ...textTeamLines]

    return {
      dataset: Object.values(buckets),
      wordLines,
      userLines,
      teamLines,
      segmentLines,
      textSegmentLines,
      phraseSegmentLines,
    }
  }, [
    segments,
    textSegments,
    wordTimeSeriesData,
    textPhrasesTimeSeriesData,
    userTimeSeriesData,
    textUserHitsTimeSeriesData,
    teamTimeSeriesData,
    textTeamHitsTimeSeriesData,
    tracker.speech?.phrasesSettings,
    tracker.text?.phrases,
    tzOffset,
    plotOnlyWeekdays,
    resolution,
    getUserByUid,
    getTeamByUid,
  ])

  const referenceLines = useMemo<ReferenceLineDescriptor[]>(() => {
    return segmentsOverview.map((x) => ({
      color: x.color,
      label: x.label,
      y: roundHitRate(x.data?.hitPercentage ?? 0),
    }))
  }, [segmentsOverview])

  const primaryLines = [...results.segmentLines, ...results.textSegmentLines]
  let secondaryLines: LineDescriptor[]
  if (secondaryChartType === 'words') {
    secondaryLines = [...results.wordLines, ...results.phraseSegmentLines]
  } else if (secondaryChartType === 'users') {
    secondaryLines = results.userLines
  } else {
    secondaryLines = results.teamLines
  }

  const containsTeamUids = states.some((s) =>
    s.channel === 'email'
      ? s.values.teamFilters?.some((filter) => (filter.values?.length ?? 0) > 0)
      : s.values.teamUids.length > 0,
  )

  return (
    <>
      {currentUser.isTeamLead && !containsTeamUids ? (
        <TeamFilterRequiredMessage />
      ) : (
        <Stack spacing={8} mt={4}>
          <Box>
            <HStack spacing={4} mb={2}>
              <ResolutionButtons
                resolution={resolution}
                setResolution={onResolutionChange}
              />
              {OnlyWeekdaysToggle}
            </HStack>
            <Box w="100%" h="18rem" mt={4}>
              <LineChartSkeleton isLoaded={!isLoading}>
                <ResponsiveContainer width="100%" height="100%" minWidth={100}>
                  <TimeSeriesChart
                    data={results.dataset}
                    resolution={resolution}
                    margin={{
                      right: 50,
                    }}
                  >
                    <CartesianGrid />
                    <Tooltip
                      content={
                        <MultiChannelTimeSeriesTooltip
                          segmentStates={states.map((_state, index) => ({
                            label: segmentConfigurations[index].label,
                            color: segmentConfigurations[index].color,
                          }))}
                          secondaryType={secondaryChartType}
                          resolution={resolution}
                          formatValue={(value, name, d) => {
                            const formatHitRateValue = formatHitRate(value)
                            const bucket = d[name]
                            let hits: number
                            let total: number
                            if ('conversations' in bucket) {
                              hits = d[name].conversationsWithHit
                              total = d[name].conversations
                              return t`${formatHitRateValue} (${hits} out of ${total} conversations)`
                            }
                            if ('cases' in bucket) {
                              hits = d[name].casesWithHit
                              total = d[name].cases
                              return t`${formatHitRateValue} (${hits} out of ${total} tickets)`
                            }
                            return formatHitRateValue
                          }}
                          formatSecondaryValue={formatHitRate}
                        />
                      }
                    />
                    <XAxis dataKey="date" />
                    <YAxis valueType="percent" />
                    {primaryLines.map((line) => (
                      <PrimaryLine key={line.id} line={line} />
                    ))}
                    {secondaryLines.map((line) => (
                      <Line key={line.id} line={line} />
                    ))}
                    {referenceLines.map((line) => (
                      <ReferenceLine key={line.label} line={line} />
                    ))}
                  </TimeSeriesChart>
                </ResponsiveContainer>
              </LineChartSkeleton>
            </Box>
          </Box>

          <Tabs index={tabIndex} onChange={handleTabIndexChange} isLazy>
            <TabList flex="1">
              <Tab>
                <Trans>Word/sentence</Trans>
              </Tab>
              <Tab>
                <Trans>Employees</Trans>
              </Tab>
              <Tab>
                <Trans>Teams</Trans>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel px={0}>
                {tracker && <WordHits tracker={tracker} />}
              </TabPanel>
              <TabPanel px={0}>
                {tracker && <UserHits tracker={tracker} />}
              </TabPanel>
              <TabPanel px={0}>
                {tracker && <TeamHits tracker={tracker} />}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      )}
    </>
  )
}

export default TimeDataContainer
