import analytics from '@capturi/analytics'
import { ConversationResponseModel } from '@capturi/api-conversations'
import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { formatTime, useAudioContext } from '@capturi/audio'
import { Speaker, useCurrentUser } from '@capturi/core'
import { useOrganization, useUsers } from '@capturi/stores'
import { ContentPlaceholder, PlayButton, Spinner } from '@capturi/ui-components'
import {
  Box,
  Flex,
  Popover,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Trans, select, t } from '@lingui/macro'
import React from 'react'
import { MdTrackChanges } from 'react-icons/md'

import hasNearnessSettings from '../../../utils/hasNearnessSettings'
import { TrackerTableContentsResult } from '../Trackers/useTrackerTableContents'
import NearnessPopoverContent from './NearnessPopoverContent'

const NoSearchResultsPlaceholder: React.FC = () => {
  return (
    <>
      <ContentPlaceholder.Container mt="2vh">
        <ContentPlaceholder.Image as={Icon_EmptyState} />
        <ContentPlaceholder.Heading>
          <Trans>No matches!</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>
            Try other inflections of the phrase or searching for something else.
          </Trans>
        </ContentPlaceholder.Body>
      </ContentPlaceholder.Container>
    </>
  )
}

type TrackerContentsResultsListProps = {
  conversation: ConversationResponseModel
  contents: TrackerTableContentsResult
}

const TrackerSearchResultsList: React.FC<TrackerContentsResultsListProps> = ({
  conversation,
  contents,
}) => {
  const { uid, userUid: salesPersonUid, dateTime } = conversation
  const isPlayable = !conversation.deleted
  const { isPlaying, play, pause, playbackContext, setPlaybackContext } =
    useAudioContext(`/playback/audio/${uid}`, {
      rollbackSeconds: 3,
    })

  const { organizationType } = useOrganization()
  const currentUser = useCurrentUser()
  const { getUserByUid } = useUsers()
  const handlePlay = (timestamp: number, hitId: string): void => {
    if (!currentUser.permissions.playback) return
    if (isPlaying && hitId === playbackContext?.hitId) {
      pause()
    } else {
      play(timestamp, true)
      setPlaybackContext({
        ...playbackContext,
        hitId: hitId,
      })
      analytics.event('conversationDetails_trackerList_playAudio', {
        isWordSearch: true,
        uid,
        date: dateTime.toUTCString(),
      })
    }
  }

  return (
    <Box flex="1">
      {contents.isLoading ? (
        <Spinner display="block" m="1rem auto" />
      ) : contents.hasNoResults ? (
        <NoSearchResultsPlaceholder />
      ) : (
        <Table>
          <Thead fontSize="xs" fontWeight="medium">
            <Tr>
              <Th>
                {isPlayable ? <Trans>Play</Trans> : <Trans>Timestamp</Trans>}
              </Th>
              <Th>
                <Trans>Word</Trans>
              </Th>
              <Th>
                <Trans>Speaker</Trans>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {contents.results.map((x) => {
              const id = `${x.trackerUid}:${x.words.join('-')}:${x.timestamp}:${
                x.speakerId
              }`
              const isTrackPlaying = isPlaying && playbackContext?.hitId === id
              const isIgnored = conversation?.ignoredPhrases.some(
                (ignoredPhrase) => {
                  const timeAndPhraseMatch =
                    ignoredPhrase.timeOffset === x.timestamp &&
                    x.words.includes(ignoredPhrase.phrase)
                  if (!timeAndPhraseMatch) return false
                  if (ignoredPhrase.reason === 'Context') {
                    return ignoredPhrase.trackerUid === x.trackerUid
                  }
                  return true
                },
              )

              return (
                <Tr
                  key={id}
                  cursor={isPlayable ? 'pointer' : 'cursor'}
                  onClick={() => handlePlay(x.timestamp, id)}
                  aria-label={t`Play audio`}
                  role="group"
                  opacity={isIgnored ? 0.4 : 1}
                  pointerEvents={isIgnored ? 'none' : 'auto'}
                >
                  <Td>
                    <Flex alignItems="center">
                      {isPlayable && (
                        <PlayButton
                          isDisabled={
                            !(currentUser.permissions.playback && isPlayable) ||
                            isIgnored
                          }
                          isPlaying={isTrackPlaying}
                          mr="4"
                        />
                      )}
                      <Text>{formatTime(x.timestamp)}</Text>
                    </Flex>
                  </Td>
                  <Td userSelect="none" pointerEvents="none">
                    <Flex align="center" wrap="wrap" flexDirection="row">
                      {x.words.map((word, index, elements) => {
                        const nearnessObject =
                          x.phrasesSettings &&
                          Object.keys(x.phrasesSettings).length > 0
                            ? x.phrasesSettings[word]
                            : { near: null, notNear: null }
                        return (
                          <Box key={index}>
                            <Text as="span">{word}</Text>
                            {hasNearnessSettings(nearnessObject) && (
                              <Popover trigger="hover">
                                <PopoverTrigger>
                                  <Box pointerEvents="auto" as="span">
                                    <MdTrackChanges />
                                  </Box>
                                </PopoverTrigger>
                                <NearnessPopoverContent
                                  phrasesSetting={nearnessObject}
                                />
                              </Popover>
                            )}
                            {index !== elements.length - 1 && (
                              <Text as="span" pr="1">
                                {','}
                              </Text>
                            )}
                          </Box>
                        )
                      })}
                    </Flex>
                  </Td>
                  <Td>
                    {x.speakerUserUid || x.speakerId === Speaker.Employee ? (
                      <Text>
                        {getUserByUid(x.speakerUserUid || salesPersonUid).name}
                      </Text>
                    ) : (
                      select(organizationType, {
                        public: 'Citizen',
                        other: 'Customer',
                      })
                    )}
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      )}
    </Box>
  )
}

export default TrackerSearchResultsList
