import { Icon, Placement } from '@chakra-ui/react'
import React from 'react'
import { MdMoreVert } from 'react-icons/md'

import { Menu, MenuButton } from '../Menu'
import { NamedCuiComponent } from '../cui'
import {
  ListItemSecondaryAction,
  ListItemSecondaryActionProps as SecondaryActionProps,
} from './ListItemSecondaryAction'

export type ListItemMenuProps = {
  icon?: React.ReactElement
  placement?: Placement
  autoSelect?: boolean
  isDisabled?: boolean
  isLazy?: boolean
} & SecondaryActionProps

export const ListItemMenu = React.forwardRef<HTMLDivElement, ListItemMenuProps>(
  function ListItemMenu(props, ref) {
    const {
      icon = <Icon as={MdMoreVert} boxSize={5} />,
      showSecondaryAction = false,
      placement,
      autoSelect,
      isDisabled,
      isLazy,
      children,
      ...restProps
    } = props
    const child = React.Children.only(children)
    return (
      <Menu placement={placement} autoSelect={autoSelect} isLazy={isLazy}>
        {({ isOpen }) => (
          <ListItemSecondaryAction
            ref={ref}
            zIndex={isOpen ? 1 : 'auto'}
            opacity={(isOpen || showSecondaryAction) && !isDisabled ? 1 : 0}
            pointerEvents={isDisabled ? 'none' : 'initial'}
            _groupHover={{
              opacity: 1,
            }}
            _groupFocus={{
              opacity: 1,
            }}
            {...restProps}
          >
            <MenuButton color="gray.600" display="block">
              {icon}
            </MenuButton>
            {child}
          </ListItemSecondaryAction>
        )}
      </Menu>
    )
  },
) as NamedCuiComponent<ListItemMenuProps, HTMLDivElement>

ListItemMenu.cuiName = 'ListItemMenu'
