import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Flex,
  UseToastOptions,
} from '@chakra-ui/react'
import React from 'react'

const statuses = {
  info: 'primary',
  warning: 'orange',
  success: 'green',
  error: 'red',
  loading: 'primary',
}

export type ToastWithActionProps = {
  onClose: () => void
  actionText: string
  onActionClick: React.MouseEventHandler<HTMLButtonElement>
} & Pick<
  UseToastOptions,
  'variant' | 'status' | 'title' | 'description' | 'isClosable' | 'id'
>

export const ToastWithAction: React.FC<ToastWithActionProps> = ({
  variant,
  status,
  id,
  title,
  description,
  actionText,
  onActionClick,
  onClose,
  isClosable = true,
}) => {
  return (
    <Alert
      status={status}
      variant={variant}
      id={id?.toString()}
      alignItems="start"
      borderRadius="md"
      boxShadow="lg"
      paddingRight={8}
      textAlign="left"
      width="auto"
    >
      <AlertIcon />
      <Flex flex="1" align="start">
        <Flex>
          <Box mr={4}>
            {title && <AlertTitle>{title}</AlertTitle>}
            {description && (
              <AlertDescription display="block">{description}</AlertDescription>
            )}
          </Box>
        </Flex>
        <Button
          ml="auto"
          variant="solid"
          colorScheme={statuses[status ?? 'info']}
          onClick={onActionClick}
          textTransform="uppercase"
          fontWeight="medium"
          flexShrink={0}
        >
          {actionText}
        </Button>
      </Flex>
      {isClosable && (
        <CloseButton
          size="sm"
          onClick={onClose}
          position="absolute"
          right={1}
          top={1}
        />
      )}
    </Alert>
  )
}
