import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'
import { MdArrowDownward } from 'react-icons/md'

type Props = {
  icon?: React.ComponentType
  isSortedDesc?: boolean
} & BoxProps

const SortDirectionArrow: React.FC<Props> = ({
  icon = MdArrowDownward,
  isSortedDesc,
  ...restProps
}) => {
  return (
    <Box
      as={icon}
      transform={`rotate(${isSortedDesc ? 0 : 180}deg)`}
      transition="all 150ms ease"
      mx={1}
      {...restProps}
    />
  )
}

export default SortDirectionArrow
