import {
  Box,
  HStack,
  Progress,
  ProgressProps,
  Skeleton,
  Text,
  Tooltip,
} from '@chakra-ui/react'

import React, { forwardRef } from 'react'

const TRANSITION = 'all 200ms ease-out'
export const ListItem = React.memo(
  forwardRef<
    HTMLDivElement,
    {
      isSelected: boolean
      onClick: () => void
      color: string
      children: React.ReactElement
    }
  >(({ children, color, isSelected, onClick }, ref) => {
    return (
      <Box
        _first={{
          borderTopRadius: '5px',
        }}
        _last={{
          borderBottomRadius: '5px',
        }}
        _notLast={{
          borderBottom: 'none',
        }}
        ref={ref}
        role="group"
        transition={TRANSITION}
        cursor="pointer"
        borderColor={'chakra-border-color'}
        color={isSelected ? color : undefined}
        borderWidth="1px"
        borderStyle="solid"
        py="2"
        paddingInline="4"
        background={isSelected ? 'white' : 'gray.50'}
        onClick={onClick}
        boxShadow={isSelected ? `${color} 1px 0px 0px 0px inset` : undefined}
        borderLeftColor={isSelected ? color : undefined}
      >
        {children}
      </Box>
    )
  }),
)

export const Title: React.FC<{ label: string }> = React.memo(({ label }) => {
  return (
    <Tooltip label={label} openDelay={300} closeOnScroll>
      <Box
        fontSize="sm"
        pr={6}
        lineHeight={1.8}
        width="100%"
        noOfLines={1}
        wordBreak="break-all"
        marginInlineStart={0}
      >
        <Box as="span">
          <HStack spacing={1}>
            <Text noOfLines={1} wordBreak="break-all">
              {label}
            </Text>
          </HStack>
        </Box>
      </Box>
    </Tooltip>
  )
})

export const CustomProgress: React.FC<
  ProgressProps & {
    isSelected: boolean
    isLoading: boolean
  }
> = React.memo(({ min, max, value, isSelected, isLoading, ...props }) => {
  return (
    <Skeleton
      height={isSelected ? '4px' : '2px'}
      isLoaded={!isLoading}
      color="white"
      fadeDuration={1}
    >
      <Progress
        isAnimated
        value={value}
        min={min}
        max={max}
        transition={TRANSITION}
        mt={'2px'}
        h={'2px'}
        transform={isSelected ? 'scale(1, 2)' : undefined}
        bg="gray.300"
        colorScheme={'segments.primary'}
        {...props}
      />
    </Skeleton>
  )
})
