import { useConversation } from '@capturi/api-conversations'
import { usePageTitle } from '@capturi/react-utils'
import { ResponseError } from '@capturi/request'
import { useSingleUser } from '@capturi/stores'
import { t } from '@lingui/macro'
import React, { ComponentType } from 'react'

import { ConversationPageView } from '../../pages/Conversations/routes'
import ConversationView from './ConversationView'
import Header from './Header'
import HeaderSkeleton from './Header/HeaderSkeleton'
import ConversationLoadingError from './components/ConversationLoadingError'

interface ConversationDetailsProps {
  uid: string
  initialAudioTimestamp?: number
  initialTab?: ConversationPageView
  separateHeader?: boolean
  HeaderWrapper?: ComponentType<{ children: React.ReactNode }>
  onTabOpen?: (tab: ConversationPageView) => void
  navigateToConversation: (uid: string, tabName?: ConversationPageView) => void
  isSidebarView: boolean
}

const ConversationDetails: React.FC<ConversationDetailsProps> = ({
  uid,
  initialAudioTimestamp,
  initialTab,
  separateHeader = false,
  HeaderWrapper = ({ children }) => <>{children}</>,
  onTabOpen,
  navigateToConversation,
  isSidebarView,
}) => {
  const { data: conversation, isLoading, error } = useConversation(uid)
  usePageTitle(t`Conversation: ${conversation?.subject}`)
  const user = useSingleUser(conversation?.userUid)

  if (error) {
    return <ConversationLoadingError error={error} />
  }
  // the backend returns 201 for non existing conversations
  if (!isLoading && conversation == null) {
    return (
      <ConversationLoadingError
        error={new ResponseError('No content', 201, 'Conversation not Found')}
      />
    )
  }

  return (
    <ConversationView
      conversationUid={uid}
      conversation={conversation}
      isConversationLoading={isLoading}
      initialAudioTimestamp={initialAudioTimestamp}
      initialTab={initialTab}
      user={user}
      header={
        isLoading || conversation == null ? (
          <HeaderSkeleton />
        ) : HeaderWrapper ? (
          <HeaderWrapper>
            <Header
              conversation={conversation}
              user={user}
              showGoToConversation={isSidebarView}
            />
          </HeaderWrapper>
        ) : (
          <Header
            conversation={conversation}
            user={user}
            showGoToConversation={isSidebarView}
          />
        )
      }
      separateHeader={separateHeader}
      onTabOpen={onTabOpen}
      navigateToConversation={navigateToConversation}
    />
  )
}

export default ConversationDetails
