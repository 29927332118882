import { Tracker } from '../types'
import { noop } from '../utils'

export function info(
  eventType: string,
  action?: string,
  ...args: unknown[]
): void {
  /* eslint-disable no-console */
  console.groupCollapsed(
    `%c[@capturi/analytics] %c${eventType} %c${action ?? ''}`,
    'color: #004EFC',
    'color: #6787ff',
    'color: inherit',
  )
  // biome-ignore lint/suspicious/noConsoleLog: <explanation>
  console.log(...args)
  console.groupEnd()
  /* eslint-enable no-console */
}

export default function init(): Tracker {
  const tracker: Tracker = {
    event: (action, ...args) => info('event', action, ...args),
    identify: (id, ...args) => info('identify', id, ...args),
    modalview: (modalName, ...args) => info('modalview', modalName, ...args),
    pageview: () =>
      info(
        'pageview',
        window.location.href.substring(window.location.origin.length),
      ),
    reset: () => info('reset'),
    record: () => noop,
  }
  return tracker
}
