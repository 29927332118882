import { Box, BoxProps, useColorMode } from '@chakra-ui/react'
import React from 'react'

const UnreadMarker: React.FC<BoxProps> = (props) => {
  const { colorMode } = useColorMode()
  const borderColor = { light: 'white', dark: 'gray.800' }
  return (
    <Box
      border="0.2em solid"
      borderColor={borderColor[colorMode]}
      rounded="full"
      boxSize="1em"
      bg="primary.500"
      transition="opacity 200ms ease"
      {...props}
    />
  )
}

export default UnreadMarker
