import { StoreApi, createStore } from 'zustand'
import { devtools } from 'zustand/middleware'

interface ExpandedFoldersState {
  folderUids: Set<string>
  has: (folderUid: string) => boolean
  toggle: (folderUid: string) => void
  reset: () => void
}

const createExpandedFoldersStore = (
  name: string,
): StoreApi<ExpandedFoldersState> =>
  createStore<ExpandedFoldersState>()(
    devtools(
      (set, get) => ({
        folderUids: new Set<string>(),
        has: (folderUid: string) => {
          return get().folderUids.has(folderUid)
        },
        toggle: (folderUid: string) => {
          set(
            (state: ExpandedFoldersState) => {
              const folderUids = new Set(state.folderUids)
              if (folderUids.has(folderUid)) {
                folderUids.delete(folderUid)
              } else {
                folderUids.add(folderUid)
              }
              return { folderUids: folderUids }
            },
            false,
            'useExpandedFolders.toggle',
          )
        },
        reset: () => {
          set(
            () => ({
              folderUids: new Set<string>(),
            }),
            true,
            'useExpandedFolders.reset',
          )
        },
      }),
      { name },
    ),
  )

export default createExpandedFoldersStore
