import { Card, CardBody, StyleProps } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

export const DrawerBenchmarkCard: React.FC<
  PropsWithChildren & StyleProps & { bodyBottomPadding?: number }
> = ({ children, bodyBottomPadding = 4, ...restProps }) => {
  return (
    <Card
      maxH="124px"
      shadow="none"
      border="1px solid"
      borderColor="gray.200"
      {...restProps}
    >
      <CardBody
        pl={4}
        pr={4}
        pb={bodyBottomPadding}
        pt={2}
        h="100%"
        overflowY="auto"
      >
        {children}
      </CardBody>
    </Card>
  )
}
