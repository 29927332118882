import analytics from '@capturi/analytics'
import { Button, Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { MdBookmark, MdClose } from 'react-icons/md'

type Props = {
  questions: string[]
  handleDeleteQuestion: (state: string) => void
  setState: (state: string) => void
  handleSubmit: (state: string) => void
}

const PersonalQuestions: React.FC<Props> = ({
  questions,
  handleDeleteQuestion,
  setState,
  handleSubmit,
}) => {
  if (!questions.length) return

  return (
    <Flex gap={2} flexWrap="wrap" w="100%">
      {questions.map((q, i) => (
        <Tooltip key={`${i}-${q}`} label={q} hasArrow placement="top">
          <Button
            leftIcon={<MdBookmark />}
            size="xs"
            maxW="300px"
            overflow="hidden"
            onClick={() => {
              setState(q)
              handleSubmit(q)
              analytics.event('submit_personal_question', {
                question: q,
              })
            }}
          >
            <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {q}
            </Text>
            <Icon
              ml={1}
              as={MdClose}
              boxSize={4}
              onClick={(e) => {
                handleDeleteQuestion(q)
                analytics.event('remove_personal_question', {
                  question: q,
                })
                e.stopPropagation()
              }}
            />
          </Button>
        </Tooltip>
      ))}
    </Flex>
  )
}

export default PersonalQuestions
