import analytics, { EventParams } from '@capturi/analytics'

export enum Event {
  TrackerCreated = 'tracker-editor_TrackerCreated',
  TrackerUpdated = 'tracker-editor_TrackerUpdated',
  TrackerNotFoundImpression = 'tracker-editor_TrackerNotFoundImpression',
  TrackerFolderCreated = 'tracker-editor_TrackerFolderCreated',
  TrackerFolderSelected = 'tracker-editor_TrackerFolderSelected',
  TrackerFolderCleared = 'tracker-editor_TrackerFolderCleared',
}

export function logEvent(event: Event, data?: EventParams): void {
  analytics.event(event, data)
}
