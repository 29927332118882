import { Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

import { PhraseState } from '../../types'
import { useDictionaryWordContext } from './useDictionaryWordStatus'

export const UnknownWordsMessage: React.FC<{
  fields: PhraseState[]
}> = ({ fields }) => {
  const { getUnknownWords } = useDictionaryWordContext()

  const hasUnknownWords = React.useMemo(() => {
    return getUnknownWords(fields.map((x) => x.value)).length > 0
  }, [fields, getUnknownWords])

  if (!hasUnknownWords) {
    return null
  }
  return (
    <Text
      ml="auto"
      pl={8}
      fontSize="xs"
      color="primary.500"
      maxW="44em"
      textAlign="right"
    >
      *{' '}
      <Trans>
        You have added a new word to our dictionary. We will update our speech
        recognition engine as soon as possible so the tracker will be able to
        recognize the word in your conversations.
      </Trans>
    </Text>
  )
}
