import analytics from '@capturi/analytics'
import {
  InaccessibleSavedTextFilter,
  SavedTextFilter,
} from '@capturi/api-filters'
import { ErrorBoundary } from '@capturi/react-utils'
import { Box, Popover, PopoverContent, PopoverProps } from '@chakra-ui/react'
import React from 'react'

import { SavedTextFilterPopoverContent } from './SavedTextFilterButton'

type SavedTextFilterPopoverProps = {
  savedFilter: SavedTextFilter | InaccessibleSavedTextFilter | undefined
  onChangeSavedFilter: (
    savedFilter: SavedTextFilter | InaccessibleSavedTextFilter,
  ) => void
} & PopoverProps

const SavedTextFilterPopover: React.FC<SavedTextFilterPopoverProps> = ({
  savedFilter,
  onChangeSavedFilter,
  ...restProps
}) => {
  return (
    <Popover isLazy closeOnBlur={false} {...restProps}>
      {({ onClose }) => (
        <PopoverContent>
          <ErrorBoundary>
            <React.Suspense fallback={<Box h={8} />}>
              <SavedTextFilterPopoverContent
                value={savedFilter}
                onChange={(filter) => {
                  onChangeSavedFilter(filter)
                  analytics.event('segment_trackers_noView')
                  onClose()
                }}
              />
            </React.Suspense>
          </ErrorBoundary>
        </PopoverContent>
      )}
    </Popover>
  )
}

export default SavedTextFilterPopover
