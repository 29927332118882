import { AccessLevel } from '@capturi/api-shared'
import { Acl, Nullable, PermissionPreset } from '@capturi/core'

/**
 * Represents a saved text filter model with some metadata and a set of values
 */

export type SavedTextFilter = {
  uid: string
  organizationUid: string

  createdByUserUid: string
  created: Date
  updatedByUserUid: string
  updated: Date

  name: string
  values: Nullable<TextFilterValues>

  acl: Acl | null
  permissionPreset: PermissionPreset
  folderUid: string | null
  permissionPresetUserUid: string
  accessLevel: AccessLevel
}

export type InaccessibleSavedTextFilter = {
  uid: string
  organizationUid: string
  name: string
  accessLevel: 'None'
}

export type MultiValueFilter = { values: string[] | null; inverted: boolean }

export const DirectionPosition = {
  First: 'First',
  Last: 'Last',
} as const
export type DirectionPosition =
  (typeof DirectionPosition)[keyof typeof DirectionPosition]

export const DirectionValue = {
  Inbound: 'Inbound',
  Outbound: 'Outbound',
  AutoReply: 'AutoReply',
} as const
export type DirectionValue =
  (typeof DirectionValue)[keyof typeof DirectionValue]

export const DurationType = {
  Inbound: 'Inbound',
  Outbound: 'Outbound',
  AutoReply: 'AutoReply',
  All: 'All',
} as const
export type DurationType = (typeof DurationType)[keyof typeof DurationType]

export const DurationComparator = {
  Eq: 'Eq',
  Lt: 'Lt',
  Gt: 'Gt',
} as const
export type DurationComparator =
  (typeof DurationComparator)[keyof typeof DurationComparator]

export const SenderType = {
  Domain: 'Domain',
  Email: 'Email',
} as const
export type SenderType = (typeof SenderType)[keyof typeof SenderType]

export const SenderPosition = {
  First: 'First',
  Last: 'Last',
} as const
export type SenderPosition =
  (typeof SenderPosition)[keyof typeof SenderPosition]

export type TextFilterValues = {
  directionFilters: {
    position: DirectionPosition
    values: DirectionValue[] | null
    inverted: boolean
  }[]
  durationFilters: {
    value: number
    type: DurationType
    comparator: DurationComparator
  }[]
  emailsFilters: MultiValueFilter[]
  externalUidFilters: MultiValueFilter[]
  inboxFilters: MultiValueFilter[]
  senderFilters: {
    type: SenderType
    position: SenderPosition
    values: string[] | null
    inverted: boolean
  }[]
  statusFilters: MultiValueFilter[]
  tagFilters: MultiValueFilter[]
  teamFilters: MultiValueFilter[]
  userFilters: MultiValueFilter[]
  trackers: {
    uids: string[] | null
    phrase: string | null
    inverted: boolean
  }[]
  customFieldFilters: {
    field: string
    values: string[] | null
    inverted: boolean
  }[]
}

export type TextFilterValuesRequestModel = Partial<TextFilterValues>

export function mapTextFilterValuesToRequestModel(
  values: TextFilterValues,
): TextFilterValuesRequestModel {
  const trackersWithUids = values.trackers.filter(
    (x) => x.uids && x.uids.length > 0,
  )
  return Object.keys(values).reduce<TextFilterValuesRequestModel>(
    (result, key) => {
      switch (key) {
        case 'directionFilters': // handling it separately to make TypeScript happy
          if (values[key].length > 0) {
            result[key] = values[key]
          }
          break
        case 'durationFilters':
          if (values[key].length > 0) {
            result[key] = values[key]
          }
          break
        case 'senderFilters':
          if (values[key].length > 0) {
            result[key] = values[key]
          }
          break
        case 'emailsFilters':
        case 'externalUidFilters':
        case 'inboxFilters':
        case 'statusFilters':
        case 'tagFilters':
        case 'teamFilters':
        case 'userFilters':
          if (values[key].length > 0) {
            result[key] = values[key]
          }
          break
        case 'trackers':
          if (trackersWithUids.length > 0) result[key] = trackersWithUids
          break
        case 'customFieldFilters':
          if (values[key].length > 0) {
            result[key] = values[key]
          }
          break
      }
      return result
    },
    {},
  )
}
