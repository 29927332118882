import { PreviewAccessRequestModel } from '@capturi/core'
import { RequestOptions } from '@capturi/request'

import { CreateFilterRequestModel, UpdateFilterRequestModel } from './models'

const DEFAULT_API_VERSION = '3.3'

const controller = 'filters'
const baseQuery = {
  'api-version': DEFAULT_API_VERSION,
}

export const filtersAPI = {
  getFilters: (includeInaccessible = false): RequestOptions => ({
    url: `${controller}`,
    query: { ...baseQuery, includeInaccessible },
  }),
  getFilter: (uid: string): RequestOptions => ({
    url: `${controller}/${uid}`,
    query: baseQuery,
  }),
  createFilter: (model: CreateFilterRequestModel): RequestOptions => ({
    url: `${controller}`,
    method: 'post',
    json: model,
    query: baseQuery,
  }),
  updateFilter: (
    uid: string,
    model: UpdateFilterRequestModel,
  ): RequestOptions => ({
    url: `${controller}/${uid}`,
    method: 'patch',
    json: model,
    query: baseQuery,
  }),
  deleteFilter: (uid: string): RequestOptions => ({
    url: `${controller}/${uid}`,
    method: 'delete',
    query: baseQuery,
  }),
  getDependents: (uid: string): RequestOptions => ({
    url: `${controller}/${uid}/dependents`,
    query: baseQuery,
  }),
  previewAccess: (model: PreviewAccessRequestModel) => ({
    url: `${controller}/preview-access`,
    method: 'post',
    json: model,
    query: baseQuery,
  }),
}

export const textFiltersAPI = {
  getDependents: (uid: string): RequestOptions => ({
    url: `${controller}/text/${uid}/dependents`,
    query: baseQuery,
  }),
  previewAccess: (model: PreviewAccessRequestModel) => ({
    url: `${controller}/text/preview-access`,
    method: 'post',
    json: model,
    query: baseQuery,
  }),
}
