import {
  Acl,
  AclEntry,
  Role,
  grantedRoleToRole,
  roleToGrantedRole,
} from '@capturi/core'
import { Box, Divider, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

import AclView from './Acl/AclView'
import {
  SearchSelect,
  SearchSelectOption,
  useSharingScopeOptions,
} from './Search'

const searchSelectOption = (
  aclEntry: AclEntry,
  maps: {
    roleMap: Map<Role, SearchSelectOption>
    teamMap: Map<string, SearchSelectOption>
    userMap: Map<string, SearchSelectOption>
  },
): SearchSelectOption | undefined => {
  if (aclEntry.type === 'everyone') {
    return undefined
  }
  const { roleMap, teamMap, userMap } = maps
  if (aclEntry.type === 'user') {
    return userMap.get(aclEntry.userUid)
  }
  if (aclEntry.type === 'team') {
    return teamMap.get(aclEntry.teamUid)
  }
  if (aclEntry.type === 'role') {
    const grantedRole = grantedRoleToRole(aclEntry.role)
    return grantedRole && roleMap.get(grantedRole)
  }
}

export const PersonsWithAccess: React.FC<{
  acl: Acl
  isDisabled?: boolean
  onChange: (acl: Acl) => void
}> = ({ acl, isDisabled = false, onChange }) => {
  const { maps, selectOptions, isLoading } = useSharingScopeOptions(acl)

  const selectedOptions = React.useMemo(
    () =>
      acl.reduce((acc, aclEntry) => {
        const option = searchSelectOption(aclEntry, maps)
        // Fixes bug in react-select when scroll jumps to
        // the last identical selected option. We add an arbitrary
        // { scroll_fix: true } so that the options are different.
        // https://trello.com/c/1FiK4kDy/4020-capturi-sharing-searchselect-scrolling-bug
        const optionWithExtra = option && { ...option, scroll_fix: true }
        if (optionWithExtra !== undefined) acc.push(optionWithExtra)
        return acc
      }, [] as SearchSelectOption[]),
    [acl, maps],
  )

  const handleNewItemsSelected = React.useCallback(
    (options: SearchSelectOption[]): void => {
      const newItems = options.reduce((acc, option) => {
        const searchResult = option.searchResult
        if (searchResult.type === 'User') {
          acc.push({
            type: 'user',
            userUid: searchResult.item.uid,
            level: searchResult.level,
          })
        } else if (searchResult.type === 'Role') {
          const grantedRole = roleToGrantedRole(searchResult.item.role)
          if (grantedRole == null) return acc

          acc.push({
            type: 'role',
            role: grantedRole,
            level: searchResult.level,
          })
        } else if (searchResult.type === 'Team') {
          acc.push({
            type: 'team',
            teamUid: searchResult.item.team.uid,
            level: searchResult.level,
          })
        }
        return acc
      }, [] as Acl)
      onChange(newItems)
    },
    [onChange],
  )

  return (
    <VStack spacing={2} w="100%" align="stretch" maxH="100%">
      <Text size="md" fontWeight="medium">
        <Trans>Persons with access</Trans>
      </Text>
      <SearchSelect
        selectOptions={selectOptions}
        isDisabled={isDisabled}
        isLoading={isLoading}
        value={selectedOptions}
        onSelect={handleNewItemsSelected}
      />
      {acl.length > 0 && (
        <Box w="100%">
          <Divider mt={2} />
        </Box>
      )}
      <AclView acl={acl} onChange={onChange} />
    </VStack>
  )
}
