import { Score } from '@capturi/api-scoring'
import { formatGoalRange } from '@capturi/scoring'
import { useTheme } from '@capturi/ui-theme'
import {
  Box,
  Flex,
  FlexProps,
  Grid,
  Icon,
  Tooltip,
  chakra,
} from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'
import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'
import sumBy from 'lodash/sumBy'
import React from 'react'
import { MdFlag } from 'react-icons/md'

import { ScoreParameterResult } from '../useBreakdown'

const StackedBarContext = React.createContext({
  max: 10,
})

export const StackedBar: React.FC<{
  hits: ScoreParameterResult[]
  misses: ScoreParameterResult[]
  maxScore: number
  goal?: Score['goal']
}> = ({ hits, misses, maxScore, goal }) => {
  const hitsScoreTotal = sumBy(hits, (x) => x.score)
  const missesWeightTotal = sumBy(misses, (x) => x.parameter.weight)
  const totalSum = hitsScoreTotal + missesWeightTotal
  const templateColumns = hits
    .concat(misses)
    .map((x) => `${x.parameter.weight}fr`)
    .join(' ')

  const { min, max } = goal ?? {}
  const left = min != null ? (min / totalSum) * 100 : 0
  const right =
    max != null ? ((totalSum - Math.min(totalSum, max)) / totalSum) * 100 : 0

  return (
    <StackedBarContext.Provider value={{ max: totalSum }}>
      <Box pt="32px" pos="relative">
        {goal != null && (
          <Box pos="absolute" h="20px" w="100%" top={0}>
            <Box
              pos="absolute"
              bottom={0}
              left={`${left}%`}
              right={`${right}%`}
              h="6px"
              bg="success"
            >
              <chakra.span
                pos="absolute"
                left="50%"
                transform="translate(-50%, -100%)"
                whiteSpace="nowrap"
                fontSize="sm"
                lineHeight={1}
                color="textMuted"
              >
                <Icon as={MdFlag} mr={1} verticalAlign="middle" />
                <chakra.span>
                  <Trans>Goal</Trans>:
                </chakra.span>
                <chakra.span textTransform="lowercase" ml="0.35em">
                  {formatGoalRange(goal?.min, goal?.max)}
                </chakra.span>
              </chakra.span>
            </Box>
          </Box>
        )}
        <Box position="relative" h={4}>
          <Grid templateColumns={templateColumns} h="100%">
            {hits.map((p) => (
              <Tooltip
                label={p.filter.label}
                hasArrow
                placement="top"
                key={p.parameter.uid}
                css={css`
                  span {
                    color: white;
                  }
                `}
              >
                <Bar color={p.color}>
                  <Icon as={p.filter.icon} color="white" boxSize="0.75em" />
                </Bar>
              </Tooltip>
            ))}
            {misses.map((p) => (
              <Bar
                key={p.parameter.uid}
                color={p.color}
                sx={{
                  border: '1px',
                  borderStyle: 'dashed',
                  borderColor: 'gray.500',
                  borderRightWidth: 0,
                  _last: {
                    borderRightWidth: '1px',
                  },
                }}
              >
                <Icon as={p.filter.icon} color="gray.500" boxSize="0.75em" />
              </Bar>
            ))}
          </Grid>
          <Marker value={maxScore} />
        </Box>
      </Box>
    </StackedBarContext.Provider>
  )
}

export const Bar = React.forwardRef<
  HTMLDivElement,
  { color: string } & FlexProps
>(function BarWithRef({ color, ...props }, ref) {
  const theme = useTheme()
  return (
    <Flex
      ref={ref}
      bg={color}
      align="center"
      px={1}
      boxShadow={`0px 4px 10px -3px ${transparentize(color, 0.5)(theme)}`}
      {...props}
    />
  )
})

export const Marker: React.FC<{
  value: number
}> = ({ value }) => {
  const { max } = React.useContext(StackedBarContext)
  const x = (value / max) * 100
  return (
    <Box pos="absolute" left={`${x}%`} top="0" bottom="0">
      <Box
        pos="absolute"
        width={0}
        borderLeft="2px"
        borderRight="2px"
        borderColor="whiteAlpha.500"
        top="0"
        bottom="0"
        transform="translateX(-50%)"
      />
      <Box
        pos="absolute"
        width={0}
        borderLeft="2px"
        borderColor="gray.600"
        top="-4px"
        bottom="-4px"
        transform="translateX(-50%)"
      />
      <Box transform="translate(-50%, 100%)" fontWeight="medium">
        {value}
      </Box>
    </Box>
  )
}
