import { useScrollable } from '@capturi/react-utils'
import request from '@capturi/request/src/request'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Code, Flex, IconButton, Switch } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import React, { useRef, useState } from 'react'
import { MdCopyAll } from 'react-icons/md'

type Props = {
  externalUid: string
  integrationType: string
} & BaseModalComponentProps

const RawCase: React.FC<Props> = ({
  onClose,
  externalUid,
  integrationType,
}) => {
  const [extraRaw, setExtraRaw] = useState<boolean>(false)
  const { data, error, isLoading } = useQuery<unknown, Error>({
    queryKey: ['rawCase', integrationType, externalUid, extraRaw],
    queryFn: () =>
      request.get(
        `importer/${integrationType.toLowerCase()}/case/${externalUid}?raw=${extraRaw}`,
      ),
  })
  const handleCopy = () => {
    navigator.clipboard.writeText(JSON.stringify(data, undefined, 3))
  }
  const ref = useRef(null)
  const height = useScrollable(ref, 100)

  return (
    <Modal isOpen onClose={onClose} size="full">
      <ModalOverlay>
        <ModalContent ref={ref}>
          <Flex justifyContent="space-between">
            <ModalHeader>
              <Flex alignItems="center">
                <IconButton
                  variant="ghost"
                  colorScheme="primary"
                  aria-label="copy"
                  icon={<MdCopyAll />}
                  onClick={handleCopy}
                />
                {externalUid}
              </Flex>
            </ModalHeader>

            <Flex alignItems="center" marginRight="80px">
              🩸 Extra Raw
              <Switch
                colorScheme="red"
                p={1}
                isDisabled={isLoading}
                onChange={(e) => setExtraRaw(e.currentTarget.checked)}
                isChecked={extraRaw}
              />
            </Flex>
            <ModalCloseButton />
          </Flex>
          <ModalBody
            maxHeight={`${height}px`}
            height={`${height}px`}
            whiteSpace="break-spaces"
            overflow="scroll"
          >
            {error && (
              <Code>
                {error.name} {error.message}
              </Code>
            )}
            {isLoading && <Code>Loading</Code>}
            <Code width="100%">
              {!!data && JSON.stringify(data, undefined, 3)}
            </Code>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default RawCase
