import { Button, ButtonProps } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { MdTravelExplore } from 'react-icons/md'

type Props = {
  focused: boolean
  //Direction from which the input field appears.
  direction: 'left' | 'right'
  onAdd: () => void
} & ButtonProps

const RapidSearchButton: React.FC<Props> = ({ direction, onAdd }) => {
  const rapidSearchStyleProps = {
    borderLeft:
      direction === 'left'
        ? '1px solid var(--chakra-colors-gray-200)'
        : 'inherit',
    borderRight:
      direction === 'right'
        ? '1px solid var(--chakra-colors-gray-200)'
        : 'inherit',
    borderLeftRadius: direction === 'left' ? 'none' : '4px',
    borderRightRadius: direction === 'right' ? 'none' : '4px',
  }

  return (
    <Button
      onClick={onAdd}
      leftIcon={<MdTravelExplore />}
      bg="accents.lightBackground.horizontal"
      fontFamily="body"
      size="md"
      variant="outline"
      borderRadius="4px"
      transitionDuration="unset"
      border="none"
      h="auto"
      minH={10}
      {...rapidSearchStyleProps}
    >
      <Trans>Search</Trans>
    </Button>
  )
}

export default RapidSearchButton
