// All breakpoints have default Chakra values,
// except 1.5xl, which is used for sidebar
export default {
  sm: '30em', // 480px
  md: '48em', // 768px
  lg: '62em', // 992px
  xl: '80em', // 1280px
  '1.5xl': '85em', // 1359px
  '2xl': '96em', // 1536px
}
