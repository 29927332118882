import { useAllTrackers } from '@capturi/api-trackers'
import { Tooltip } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import { BaseTrackerState } from '../../types'

export const TrackerTooltipWrapper: React.FC<{
  state: BaseTrackerState
  children?: React.ReactNode
}> = ({ state, children }) => {
  const { data: trackers, error } = useAllTrackers()

  const hasValues = !isEmpty(state?.uids)
  const isLoading = hasValues && !trackers && !error
  if (isLoading) return <>{children}</>

  const trackersArr = Array.from(trackers ?? [])
  const hasPrivateTrackers = (state?.uids ?? []).some((uid) => {
    const tracker = trackersArr.find((x) => x.uid === uid)
    return tracker && tracker.accessLevel === 'None'
  })
  if (!hasPrivateTrackers) return <>{children}</>

  const tooltipText = t`You don’t have view rights to one or more trackers.`
  return (
    <Tooltip
      label={tooltipText}
      aria-label={tooltipText}
      hasArrow={true}
      placement="top"
    >
      {children}
    </Tooltip>
  )
}
