import analytics, { EventParams } from '@capturi/analytics'
import {
  ConversationResponseModel,
  IgnoreTrackerPhraseReason,
  conversationsAPI,
} from '@capturi/api-conversations'
import request, { ResponseError } from '@capturi/request'
import {
  MenuItem,
  MenuItemProps,
  useActionToast,
  useToast,
} from '@capturi/ui-components'
import { Icon, Tooltip, UseToastOptions } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import * as React from 'react'
import { MdBlurOff, MdLocationDisabled } from 'react-icons/md'

enum MarkAsFalseRecognitionEvent {
  MarkAsFalseRecognitionClicked = 'mark-as-false-recognition__MarkAsFalseRecognitionClicked',
  Toast_UndoClicked = 'mark-as-false-recognition__Toast_UndoClicked',
}

function logEvent(
  event: MarkAsFalseRecognitionEvent,
  reason: IgnoreTrackerPhraseReason,
  data?: EventParams,
): void {
  analytics.event(event, {
    reason,
    ...(data ?? {}),
  })
}

export interface UseMarkAsFalseRecognition {
  addRecognitionError(phrases: string[], timestamp: number): Promise<void>
  addContextError(
    phrases: string[],
    timestamp: number,
    trackerUid: string,
  ): Promise<void>
}

export interface MarkAsFalseRecognitionOptions {
  onSettled(data?: ConversationResponseModel, error?: ResponseError): void
}

export function useMarkAsFalseRecognition(
  conversationUid: string,
  options: MarkAsFalseRecognitionOptions,
): UseMarkAsFalseRecognition {
  const successOptions: UseToastOptions = React.useMemo(
    () => ({
      status: 'success',
      duration: 7000,
      actionText: t`Undo`,
    }),
    [],
  )
  const successToast = useActionToast(successOptions)

  const errorOptions: UseToastOptions = React.useMemo(
    () => ({
      status: 'error',
      duration: 3000,
      title: t`Sorry!`,
    }),
    [],
  )
  const errorToast = useToast(errorOptions)

  const undoErrorOptions: UseToastOptions = React.useMemo(
    () => ({
      status: 'error',
      title: t`Sorry!`,
      description: t`We failed to undo your changes`,
    }),
    [],
  )
  const undoErrorToast = useToast(undoErrorOptions)

  const { onSettled } = options
  const handleSettled = React.useCallback(
    (data?: ConversationResponseModel, error?: ResponseError) => {
      onSettled(data, error)
    },
    [onSettled],
  )

  const addRecognitionError = React.useCallback(
    async (phrases: string[], timeOffset: number): Promise<void> => {
      try {
        const resp = await request<ConversationResponseModel>(
          conversationsAPI.createIgnoredTrackerPhrasesEntry(
            conversationUid,
            phrases.map((phrase) => ({
              phrase,
              timeOffset,
              reason: 'FalseRecognition',
            })),
          ),
        )

        const undoToastId = successToast({
          description: t`"${phrases.join(', ')}" marked as "recognition error"`,
          onActionClick: async () => {
            logEvent(
              MarkAsFalseRecognitionEvent.Toast_UndoClicked,
              'FalseRecognition',
            )
            try {
              const resp = await request<ConversationResponseModel>(
                conversationsAPI.removeIgnoredTrackerPhrasesEntry(
                  conversationUid,
                  phrases.map((phrase) => ({
                    phrase,
                    timeOffset,
                  })),
                ),
              )
              if (undoToastId !== undefined) {
                successToast.close(undoToastId)
              }
              handleSettled(resp)
            } catch (error) {
              undoErrorToast()
              const e = error instanceof ResponseError ? error : undefined
              handleSettled(undefined, e)
            }
          },
        })
        handleSettled(resp)
      } catch (error) {
        errorToast({
          description: t`We failed to mark "${phrases.join(
            ', ',
          )}" as a recognition error.`,
        })
        const e = error instanceof ResponseError ? error : undefined
        handleSettled(undefined, e)
      }
    },
    [conversationUid, errorToast, handleSettled, successToast, undoErrorToast],
  )

  const addContextError = React.useCallback(
    async (
      phrases: string[],
      timeOffset: number,
      trackerUid: string,
    ): Promise<void> => {
      try {
        const resp = await request<ConversationResponseModel>(
          conversationsAPI.createIgnoredTrackerPhrasesEntry(
            conversationUid,
            phrases.map((phrase) => ({
              phrase,
              timeOffset,
              reason: 'Context',
              trackerUid,
            })),
          ),
        )

        const undoToastId = successToast({
          description: t`"${phrases.join(
            ', ',
          )}" marked as "recognized in the wrong context"`,
          onActionClick: async () => {
            logEvent(MarkAsFalseRecognitionEvent.Toast_UndoClicked, 'Context')
            try {
              const resp = await request<ConversationResponseModel>(
                conversationsAPI.removeIgnoredTrackerPhrasesEntry(
                  conversationUid,
                  phrases.map((phrase) => ({
                    phrase,
                    timeOffset,
                  })),
                ),
              )
              if (undoToastId !== undefined) {
                successToast.close(undoToastId)
              }
              handleSettled(resp)
            } catch (error) {
              undoErrorToast()
              const e = error instanceof ResponseError ? error : undefined
              handleSettled(undefined, e)
            }
          },
        })
        handleSettled(resp)
      } catch (error) {
        errorToast({
          description: t`We failed to mark "${phrases.join(
            ', ',
          )}" as recognized in the wrong context.`,
        })
        const e = error instanceof ResponseError ? error : undefined
        handleSettled(undefined, e)
      }
    },
    [conversationUid, errorToast, handleSettled, successToast, undoErrorToast],
  )

  return React.useMemo(
    () => ({
      addRecognitionError,
      addContextError,
    }),
    [addContextError, addRecognitionError],
  )
}

const MarkAsRecognitionErrorMenuItem: React.FC<MenuItemProps> = ({
  onClick,
  ...props
}) => {
  const handleIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      logEvent(
        MarkAsFalseRecognitionEvent.MarkAsFalseRecognitionClicked,
        'FalseRecognition',
      )
      onClick?.(event)
    },
    [onClick],
  )

  return (
    <Tooltip
      label={t`Upon selection, the occurrence will be removed from all trackers containing this phrase`}
      placement="left"
      hasArrow
    >
      <MenuItem
        icon={<Icon as={MdLocationDisabled} boxSize="0.75rem" />}
        onClick={handleIconClick}
        {...props}
      >
        <Trans>Mark as recognition error</Trans>
      </MenuItem>
    </Tooltip>
  )
}

const MarkAsWrongContextMenuItem: React.FC<
  MenuItemProps & { trackerName?: string }
> = ({ trackerName, onClick, ...props }) => {
  const handleIconClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      logEvent(
        MarkAsFalseRecognitionEvent.MarkAsFalseRecognitionClicked,
        'Context',
      )
      onClick?.(event)
    },
    [onClick],
  )

  return (
    <Tooltip
      label={t`Upon selection, the occurrence will only be removed from the tracker "${trackerName}"`}
      placement="left"
      hasArrow
    >
      <MenuItem
        icon={<Icon as={MdBlurOff} boxSize="0.75rem" />}
        onClick={handleIconClick}
        {...props}
      >
        <Trans>Recognized in the wrong context</Trans>
      </MenuItem>
    </Tooltip>
  )
}

export const FalseRecognitionMenuItem = {
  RecognitionError: MarkAsRecognitionErrorMenuItem,
  WrongContext: MarkAsWrongContextMenuItem,
}
