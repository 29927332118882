import { Color, Shape } from '@capturi/sharing'
import { Text } from '@chakra-ui/react'
import { ReactElement, ReactNode } from 'react'

const renderWord = ({
  key,
  word,
  bgColor,
}: {
  key: number | string
  word: string
  bgColor?: string
}): ReactElement => {
  return (
    <Text
      key={key}
      as="span"
      color={bgColor ? 'white' : undefined}
      bg={bgColor}
      borderRadius="4px"
      px="2px"
      mx="-2px"
    >
      {word}
    </Text>
  )
}

export type Hit = {
  from: number
  to: number
  trackerUid: string
  color: Color
  shape: Shape
  field: string
}

// removes overlaps and keep the longest
export const removeOverlap = (hits: Hit[]): Hit[] => {
  return hits.reduce<Hit[]>((memo, current, index) => {
    const prev = memo.at(-1)
    //If it is the first one, we always add it
    if (!prev) {
      memo.push(current)
      return memo
    }
    //If the current start value is between the last from-to
    //There is overlap
    const hasOverlap = current.from >= prev.from && current.to <= prev.to
    if (hasOverlap) {
      const prevLength = prev.to - prev.from
      const currentLength = current.to - current.from
      //If the current on is longe that the last one, we replace it
      const isCurrentLongest = currentLength > prevLength
      if (isCurrentLongest) {
        memo[index] = current
      }
    } else {
      memo.push(current)
    }
    return memo
  }, [])
}

export const createTextNodes = (
  hits: Hit[],
  fullText: string | null,
): ReactNode[] => {
  if (!fullText) return []
  if (hits.length === 0) return [renderWord({ key: 1, word: fullText })]
  // to handle emojies, we need to count Unicode units and not UTF-16 units
  // so we split it into an array to do that, and then joins when we are done counting
  const textArray = [...fullText]

  return hits.reduce<ReactNode[]>((memo, hit, index, arr) => {
    const isLastHit = index === arr.length - 1
    //This only happens in case the first word is detected
    const lastIndexEnd = index >= 1 ? arr[index - 1].to : -1
    //Non hit sentences
    const nonHitPhrase = textArray.slice(lastIndexEnd + 1, hit.from).join('')
    if (nonHitPhrase)
      memo.push(
        renderWord({
          key: index + nonHitPhrase,
          word: nonHitPhrase,
        }),
      )
    //actually adding the hit
    const hitPhrase = textArray.slice(hit.from, hit.to + 1).join('')
    memo.push(
      renderWord({
        key: index + hitPhrase,
        word: hitPhrase,
        bgColor: hit.color,
      }),
    )
    if (isLastHit) {
      const lastIndexEnd = arr[index].to || 0
      const phrase = textArray
        .slice(lastIndexEnd + 1, textArray.length)
        .join('')
      memo.push(
        renderWord({
          key: index + phrase,
          word: phrase,
        }),
      )
    }
    return memo
  }, [])
}
