import analytics from '@capturi/analytics'
import { useTheme } from '@capturi/ui-theme'
import {
  Box,
  Flex,
  List,
  ListItem,
  Skeleton,
  Text,
  chakra,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'
import Markdown, { RuleType } from 'markdown-to-jsx'

import React, { PropsWithChildren } from 'react'
import { useSharedDigest } from './useSharedDigest'

const ALLOWED_RULES = new Set([
  RuleType.blockQuote,
  RuleType.breakLine,
  RuleType.newlineCoalescer,
  RuleType.orderedList,
  RuleType.unorderedList,
  RuleType.paragraph,
  RuleType.text,
  RuleType.textBolded,
  RuleType.textEmphasized,
  RuleType.textStrikethroughed,
  RuleType.unorderedList,
])

const DigestWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme()
  return (
    <Flex
      css={css`&:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0.5;
    border-radius: 4px;
    background: ${theme.colors.aiGradient};
}`}
      borderRadius={4}
      w="100%"
      h="100%"
    >
      <Box
        bg="white"
        pt={3}
        pb={2}
        px={4}
        m="1px"
        fontSize="sm"
        w="100%"
        borderRadius={4}
      >
        {children}
      </Box>
    </Flex>
  )
}

const DigestSkeleton: React.FC = () => {
  return (
    <DigestWrapper>
      <Flex gap="2" flexDir="column">
        <Skeleton height="16px" width="70%" borderRadius="999" />
        <Flex mb="4" gap="2" flexDir="column">
          <Flex gap="1" flexDir="row" align="center">
            <Skeleton height="3" width="3" />
            <Skeleton height="18px" width="40%" borderRadius="999" />
          </Flex>
          <Skeleton height="12px" width="100%" borderRadius="999" />
          <Skeleton height="12px" width="100%" borderRadius="999" />
          <Skeleton height="12px" width="20%" borderRadius="999" />
        </Flex>
        <Flex mb="4" gap="2" flexDir="column">
          <Flex gap="1" flexDir="row" align="center">
            <Skeleton height="3" width="3" />
            <Skeleton height="18px" width="40%" borderRadius="999" />
          </Flex>
          <Skeleton height="12px" width="100%" borderRadius="999" />
          <Skeleton height="12px" width="100%" borderRadius="999" />
          <Skeleton height="12px" width="20%" borderRadius="999" />
        </Flex>
        <Flex mb="2" gap="2" flexDir="column">
          <Flex gap="1" flexDir="row" align="center">
            <Skeleton height="3" width="3" />
            <Skeleton height="18px" width="40%" borderRadius="999" />
          </Flex>
          <Skeleton height="12px" width="100%" borderRadius="999" />
          <Skeleton height="12px" width="100%" borderRadius="999" />
          <Skeleton height="12px" width="20%" borderRadius="999" />
        </Flex>
      </Flex>
    </DigestWrapper>
  )
}

export const SharedDigestContent: React.FC<{
  digest: string
  keyTopicClusterLabel: string | undefined
}> = ({ digest, keyTopicClusterLabel }) => {
  return (
    <DigestWrapper>
      <Text fontSize="sm" fontWeight="medium" mb={2}>
        {keyTopicClusterLabel}
      </Text>
      <List spacing={2}>
        <ListItem>
          <Flex direction="row">
            <Flex direction="column" gap="4" whiteSpace="break-spaces">
              <Markdown
                options={{
                  disableParsingRawHTML: true,
                  overrides: {
                    ol: {
                      component: Box,
                      props: {
                        as: 'ol',
                        marginLeft: '4',
                      },
                    },
                    ul: {
                      component: Box,
                      props: {
                        as: 'ul',
                        marginLeft: '4',
                        marginBottom: '4',
                      },
                    },
                    strong: {
                      component: chakra.span,
                      props: {
                        fontWeight: '500',
                        display: 'inline-block',
                      },
                    },
                    p: {
                      component: Text,
                      props: { marginBottom: '2' },
                    },
                  },
                  renderRule: (next, node) => {
                    //We only allow a subset of types
                    if (ALLOWED_RULES.has(node.type)) {
                      return next()
                    }

                    //Typescript fix
                    //Not all types have a text, so we return an empty string if they dont
                    return (
                      (node as unknown as { text?: string | undefined }).text ||
                      ''
                    )
                  },
                }}
              >
                {digest}
              </Markdown>
              <Text fontStyle="italic" color="gray.500">
                <Trans>
                  The recommendations are AI-generated and based on a random
                  selection of conversations.
                </Trans>
              </Text>
            </Flex>
          </Flex>
        </ListItem>
      </List>
    </DigestWrapper>
  )
}

const SharedDigest: React.FC<{
  keyTopicsSessionUid: string | undefined
  keyTopicClusterId: string | undefined
  keyTopicClusterLabel: string | undefined
  widgetType: 'topChanges' | 'topTimeConsumers' | 'repeatCalls'
}> = ({
  keyTopicsSessionUid,
  keyTopicClusterId,
  keyTopicClusterLabel,
  widgetType,
}) => {
  const { data, isLoading } = useSharedDigest({
    keyTopicsSessionUid,
    keyTopicClusterId,
    widgetType: widgetType,
  })

  if (isLoading) return <DigestSkeleton />

  if (data?.digest == null) {
    return (
      <DigestWrapper>
        <Text fontSize="sm" fontWeight="medium" mb={2}>
          <Trans>
            No information to display. Try again or navigate to another topic.
          </Trans>
        </Text>
      </DigestWrapper>
    )
  }

  if (data?.digest === null) {
    analytics.event('digest_failed', { widgetType: widgetType })
  }
  return (
    <SharedDigestContent
      digest={data.digest}
      keyTopicClusterLabel={keyTopicClusterLabel}
    />
  )
}

export default SharedDigest
