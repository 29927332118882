export type CacheItemListener<T> = (item: T | undefined) => void

export class CacheMonitor<T> {
  private cache: Map<string, T>
  private listeners: { [key: string]: CacheItemListener<T>[] } = {}

  constructor(cache: Map<string, T>) {
    this.cache = cache
  }

  public attach(key: string, listener: CacheItemListener<T>): void {
    if (key.trim().length === 0) return
    if (!this.listeners[key]) {
      this.listeners[key] = []
    }
    this.listeners[key].push(listener)
    this.notify(key)
  }

  public detach(key: string, listenerToRemove: CacheItemListener<T>): void {
    if (!this.listeners[key]) {
      return
    }
    this.listeners[key] = this.listeners[key].filter(
      (listener) => listener !== listenerToRemove,
    )
  }

  public setItem(phrase: string, item: T): void {
    this.cache.set(phrase, item)
    this.notify(phrase)
  }

  private notify(key: string): void {
    ;(this.listeners[key] ?? []).forEach((listener) =>
      listener(this.cache.get(key)),
    )
  }
}
