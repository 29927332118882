import { TimeResolution } from '@capturi/core'
import { RadioButton, RadioButtonGroup } from '@capturi/ui-components'
import { t } from '@lingui/macro'
import React from 'react'

const getOptions = (): {
  label: string
  value: TimeResolution
}[] => [
  {
    label: t`Hours`,
    value: 'Hours',
  },
  {
    label: t`Days`,
    value: 'Days',
  },
  {
    label: t`Weeks`,
    value: 'Weeks',
  },
  {
    label: t`Months`,
    value: 'Months',
  },
]

type Props = {
  resolution: TimeResolution
  setResolution: (res: TimeResolution) => void
}

const ResolutionButtons: React.FC<Props> = ({ resolution, setResolution }) => {
  return (
    <RadioButtonGroup
      name="resolution"
      onChange={setResolution}
      value={resolution}
    >
      {getOptions().map((o) => (
        <RadioButton key={o.value} value={o.value}>
          {o.label}
        </RadioButton>
      ))}
    </RadioButtonGroup>
  )
}

export default ResolutionButtons
