import { Flex, FlexProps } from '@chakra-ui/react'
import React from 'react'

export const SidebarGroup: React.FC<FlexProps> = ({ children, ...props }) => {
  return (
    <Flex gridRowGap="1px" direction="column" {...props}>
      {children}
    </Flex>
  )
}
