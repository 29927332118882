import { Box, HStack, Text } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { FC, memo, useCallback } from 'react'
import { useMeasure } from 'react-use'
import { Cell, Pie, PieChart, PieLabelRenderProps } from 'recharts'

import { useTheme } from '@capturi/ui-theme'
import { mix } from 'color2k'

type HalfCircleDonutChartProps = {
  data: { label: string; percentage: number }[] | undefined
  innerRadius: number
  outerRadius: number
}

const mixAmount = 0.3

export const HalfCircleDonutChartWithLegend: React.FC<
  HalfCircleDonutChartProps
> = ({ data, innerRadius, outerRadius }) => {
  const { colors: themeColors } = useTheme()
  const colors = [
    themeColors.segmentPrimary,
    themeColors.segmentSecondary,
    themeColors.segmentTertiary,
    themeColors.segmentQuaternary,
    themeColors.segmentQuinary,
  ]
  const chartColors = colors.map((color) => mix(color, '#ffffffff', mixAmount))
  const legendPosition = useCallback(
    (index: number) => {
      const legendItemCount = data?.length ?? 0
      const legendItemAngle = Math.min(0.2, Math.PI / legendItemCount)
      const legendRadius = outerRadius + 15
      const angle = 0.4 + index * legendItemAngle
      const x = Math.cos(angle) * legendRadius
      const y = outerRadius + Math.sin(angle) * legendRadius
      return { x, y }
    },
    [data, outerRadius],
  )

  const [ref, { width: containerWidth }] = useMeasure<HTMLDivElement>()

  if (data === undefined) {
    return null
  }

  return (
    <Box w="100%" h="100%" position="relative" ref={ref}>
      <HalfCircleDonutChart
        data={data}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        colors={colors}
      />
      {data.map((item, index) => {
        const { x, y } = legendPosition(index)
        const hStackWidth = containerWidth - x
        return (
          <HStack
            id="legend-container"
            gap={2}
            key={item.label}
            alignItems="center"
            position="absolute"
            left={x}
            top={y}
            w={`${hStackWidth}px`}
          >
            <Box
              width="8px"
              height="8px"
              backgroundColor={colors[index % chartColors.length]}
              borderRadius="2px"
            />
            <Text
              id="legend-label"
              noOfLines={1}
              fontSize="sm"
              flex="1"
              wordBreak="break-all"
            >
              {item.label}
            </Text>
          </HStack>
        )
      })}
    </Box>
  )
}

const renderPieChartLabel: FC<PieLabelRenderProps> = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  percent,
}) => {
  if (
    typeof cx !== 'number' ||
    typeof cy !== 'number' ||
    startAngle === undefined ||
    endAngle === undefined ||
    typeof innerRadius !== 'number' ||
    typeof outerRadius !== 'number' ||
    percent === undefined
  ) {
    return null
  }

  const midAngle = (startAngle + endAngle) / 2
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180))
  const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180))

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      fontWeight="500"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

export const HalfCircleDonutChart: FC<{
  data: { label: string; percentage: number }[]
  innerRadius: number
  outerRadius: number
  colors: string[]
}> = memo(({ data, innerRadius, outerRadius, colors }) => {
  const chartColors = colors.map((color) => mix(color, '#ffffffff', mixAmount))

  return (
    <Box
      css={css`
      .recharts-wrapper svg {
        overflow: inherit !important;
      }
    `}
    >
      <PieChart width={outerRadius * 2} height={outerRadius * 2}>
        <defs>
          <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
            <feDropShadow
              dx="0"
              dy="2"
              stdDeviation="2"
              floodColor="rgba(0, 0, 0, 0.2)"
            />
          </filter>
        </defs>
        <Pie
          dataKey="percentage"
          startAngle={90}
          endAngle={-90}
          data={data}
          cx={0}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          stroke="white"
          labelLine={false}
          label={renderPieChartLabel}
          animationBegin={100}
          animationDuration={500}
          animationEasing="ease-out"
          filter="url(#shadow)"
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${entry.label}-${index}`}
              fill={chartColors[index % chartColors.length]}
            />
          ))}
        </Pie>
      </PieChart>
    </Box>
  )
})
