let isLoaded = false

/**
 * Load Intercom JS bundle
 * @param {string} APP_ID
 * @returns Intercom_.IntercomStatic | undefined
 */
export default function loadIntercom(APP_ID) {
  if (isLoaded) return
  isLoaded = true

  // https://developers.intercom.com/installing-intercom/docs/basic-javascript#section-step-1-include-intercom-js-library
  /* eslint-disable */
  ;(function () {
    var w = window
    var ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      var d = document
      var i = function () {
        i.c(arguments)
      }
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      w.Intercom = i
      var l = function () {
        var s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = 'https://widget.intercom.io/widget/' + APP_ID
        var x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
      } else if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  })()
  /* eslint-enable */
  return window.Intercom
}
