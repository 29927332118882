import analytics, { EventParams } from '@capturi/analytics'

export enum FilterEvent {
  SetDatePeriod = 'filters_setDatePeriod',
  // Filter values
  SetFilterCriteriaValue = 'filters_setFilterCriteriaValue',
  ResetFilterCriteriaValue = 'filters_resetFilterCriteriaValue',
  // Add/remove
  AddFilterCriteria = 'filters_addFilterCriteria',
  RemoveFilterCriteria = 'filters_removeFilterCriteria',
  // Filter context
  LoadFilterContext = 'filters_loadFilterContext',
  ClearFilterContext = 'filters_clearFilterContext',
  // Segments
  AddSegment = 'filters_addSegment',
  RemoveSegment = 'filter_removeSegment',
  // Subscriptions
  Subscribe = 'filters_subscribe',
  UnSubscribe = 'filters_unsubscribe',
  // CRUD
  CreateFilter = 'filters_createFilter',
  SaveFilter = 'filters_saveFilter',
  // Export
  ExportCSV = 'filters_exportCSV',
  // Tracker in filter
  useTracker = 'filters_useTracker',
}

export const logEvent = (event: FilterEvent, params?: EventParams): void => {
  analytics.event(event, params)
}
