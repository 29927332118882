import { AccessLevel } from '@capturi/api-shared'
import request, { ResponseError } from '@capturi/request'
import {
  UseQueryResult,
  UseSuspenseQueryResult,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query'

import { InacessibleTrackerFolder, TrackerFolder } from '../models'
import { url, cacheKey } from './constants'

export type TrackerFolderResponse = {
  folders: TrackerFolder[]
}
type InaccessibleTrackerFolderResponse = {
  folders: (TrackerFolder | InacessibleTrackerFolder)[]
}
const compare = Intl.Collator().compare

const selectorOneAccessible =
  (
    folderUid: string | undefined,
  ): ((data: TrackerFolderResponse) => TrackerFolder | undefined) =>
  (data) => {
    return folderUid !== undefined
      ? data.folders.find(
          (f) => f.uid === folderUid && f.accessLevel !== 'None',
        )
      : undefined
  }

export const useTrackerFolder = (
  folderUid: string | undefined,
): UseQueryResult<TrackerFolder | undefined, ResponseError> =>
  useQuery({
    queryKey: [cacheKey],
    queryFn: () =>
      request.get<TrackerFolderResponse>(`${url}&includeInaccessible=true`),
    select: selectorOneAccessible(folderUid),
    enabled: !!folderUid,
  })

const selectorWithAccessLevel =
  (
    accessLevel?: AccessLevel,
  ): ((data: TrackerFolderResponse) => TrackerFolder[]) =>
  (data) => {
    return data.folders
      .filter((d) => {
        if (accessLevel === undefined) {
          return d.accessLevel !== 'None'
        }
        return d.accessLevel === accessLevel
      })
      .sort((a, b) => compare(a.title, b.title))
  }
const selectorAll = (
  data: InaccessibleTrackerFolderResponse,
): (TrackerFolder | InacessibleTrackerFolder)[] => {
  return data.folders.sort((a, b) => compare(a.title, b.title))
}

// By default it returns accessible trackers (both accessLevel === 'View' and 'Edit')
export const useTrackerFolders = (
  accessLevel?: AccessLevel,
): UseSuspenseQueryResult<TrackerFolder[], ResponseError> =>
  useSuspenseQuery({
    queryKey: [cacheKey],
    queryFn: () =>
      request.get<TrackerFolderResponse>(`${url}&includeInaccessible=true`),
    select: selectorWithAccessLevel(accessLevel),
    staleTime: 60_000, // 1 minute
  })

export const useAllTrackerFolders = (): UseSuspenseQueryResult<
  (TrackerFolder | InacessibleTrackerFolder)[],
  ResponseError
> =>
  useSuspenseQuery({
    queryKey: [cacheKey],
    queryFn: () =>
      request.get<InaccessibleTrackerFolderResponse>(
        `${url}&includeInaccessible=true`,
      ),
    select: selectorAll,
  })
