import { GrantedLevel } from '@capturi/core'
import { Button } from '@capturi/ui-components'
import { OnChangeValue, PopoutSelect, SelectOption } from '@capturi/ui-select'
import { Icon, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { FiChevronDown } from 'react-icons/fi'

const GrantedLevelOptions: GrantedLevel[] = ['View', 'Edit', 'Block']

const formatOption = (team: GrantedLevel): SelectOption => {
  switch (team) {
    case 'View':
      return { value: 'View', label: t`View only` }
    case 'Edit':
      return { value: 'Edit', label: t`View and edit` }
    case 'Block':
      return { value: 'Block', label: t`Can’t view or edit` }
  }
}

const GrantedLevelSelectContent: React.FC<{
  value: GrantedLevel
  onChange: (value: GrantedLevel) => void
  onClose: () => void
}> = ({ onClose, value, onChange }) => {
  const handleChange = React.useCallback(
    (newValue: OnChangeValue<SelectOption, false>) => {
      if (newValue == null) {
        return
      }
      onChange(newValue.value as GrantedLevel)
      onClose()
    },
    [onChange, onClose],
  )

  return (
    <PopoutSelect
      value={formatOption(value)}
      onChange={handleChange}
      options={GrantedLevelOptions.map(formatOption)}
      isSearchable={false}
    />
  )
}

const GrantedLevelSelect: React.FC<{
  value: GrantedLevel
  onChange: (value: GrantedLevel) => void
}> = ({ value, onChange }) => {
  return (
    <Popover placement="bottom-start" size="xs">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              minW="155px"
              w="auto"
              justifyContent="space-between"
              variant="outline"
              px="8px"
              py="5px"
              rightIcon={<Icon as={FiChevronDown} boxSize="20px" />}
              fontWeight="normal"
            >
              {formatOption(value).label}
            </Button>
          </PopoverTrigger>
          <PopoverContent maxW="40">
            <GrantedLevelSelectContent
              value={value}
              onChange={onChange}
              onClose={onClose}
            />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

export default GrantedLevelSelect
