import React from 'react'

import ShadowFilter from './ShadowFilter'

const PentagonWithOutline: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#shadow)">
        <path
          d="M7.41221 1.42705C7.7627 1.17241 8.2373 1.17241 8.58779 1.42705L14.0696 5.40983C14.4201 5.66447 14.5668 6.11584 14.4329 6.52786L12.339 12.9721C12.2051 13.3842 11.8212 13.6631 11.388 13.6631H4.61205C4.17882 13.6631 3.79486 13.3842 3.66099 12.9721L1.56712 6.52786C1.43324 6.11584 1.5799 5.66447 1.93039 5.40983L7.41221 1.42705Z"
          fill={color}
        />
        <path
          d="M7.41221 1.42705C7.7627 1.17241 8.2373 1.17241 8.58779 1.42705L14.0696 5.40983C14.4201 5.66447 14.5668 6.11584 14.4329 6.52786L12.339 12.9721C12.2051 13.3842 11.8212 13.6631 11.388 13.6631H4.61205C4.17882 13.6631 3.79486 13.3842 3.66099 12.9721L1.56712 6.52786C1.43324 6.11584 1.5799 5.66447 1.93039 5.40983L7.41221 1.42705Z"
          stroke="white"
        />
      </g>
      <ShadowFilter />
    </svg>
  )
}

const PentagonNoOutline: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#shadow)">
        <path
          d="M5.41222 0.427051C5.7627 0.172407 6.2373 0.172407 6.58779 0.427051L10.1675 3.02786C10.518 3.28251 10.6646 3.73388 10.5308 4.1459L9.16344 8.3541C9.02957 8.76612 8.64561 9.04508 8.21238 9.04508H3.78762C3.35439 9.04508 2.97043 8.76612 2.83656 8.3541L1.46923 4.1459C1.33536 3.73388 1.48202 3.28251 1.8325 3.02786L5.41222 0.427051Z"
          fill={color}
        />
      </g>
      <ShadowFilter />
    </svg>
  )
}

const Pentagon: React.FC<{ color: string; outline: boolean }> = ({
  color,
  outline,
}) => {
  return outline ? (
    <PentagonWithOutline color={color} />
  ) : (
    <PentagonNoOutline color={color} />
  )
}

export default React.memo(Pentagon)
