import {
  addDays,
  endOfWeek as dateFnsEndOfWeek,
  startOfWeek as dateFnsStartOfWeek,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  startOfDay,
  startOfMinute,
  startOfMonth,
  startOfQuarter,
  subHours,
  subMonths,
  subQuarters,
  subWeeks,
} from 'date-fns'

export const yesterday = (referenceDate: Date): Date =>
  addDays(referenceDate, -1)

export const startOfYesterday = (referenceDate: Date): Date =>
  startOfDay(yesterday(referenceDate))

export const endOfYesterday = (referenceDate: Date): Date =>
  endOfDay(yesterday(referenceDate))

export const startOfLastXDays =
  (days: number): ((d: Date) => Date) =>
  (referenceDate: Date): Date =>
    startOfDay(addDays(referenceDate, -days + 1))

export const startOfLastXHours =
  (hours: number): ((d: Date) => Date) =>
  (referenceDate: Date): Date =>
    startOfMinute(subHours(referenceDate, hours))

export const startOfWeek = (referenceDate: Date): Date =>
  dateFnsStartOfWeek(referenceDate, { weekStartsOn: 1 })

export const endOfWeek = (referenceDate: Date): Date =>
  dateFnsEndOfWeek(referenceDate, { weekStartsOn: 1 })

export const startOfLastWeek = (referenceDate: Date): Date =>
  startOfWeek(subWeeks(referenceDate, 1))

export const endOfLastWeek = (referenceDate: Date): Date =>
  endOfWeek(startOfLastWeek(referenceDate))

export const startOfLastMonth = (referenceDate: Date): Date =>
  startOfMonth(subMonths(referenceDate, 1))

export const endOfLastMonth = (referenceDate: Date): Date =>
  endOfMonth(startOfLastMonth(referenceDate))

export const startOfLastQuarter = (referenceDate: Date): Date =>
  subQuarters(startOfQuarter(referenceDate), 1)

export const endOfLastQuarter = (referenceDate: Date): Date =>
  endOfQuarter(subQuarters(referenceDate, 1))
