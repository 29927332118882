import { RequestOptions } from '@capturi/request'

const DEFAULT_API_VERSION = '3.3'

const controller = 'organization'
const baseQuery = {
  'api-version': DEFAULT_API_VERSION,
}

export const organizationAPI = {
  getSubjects: (): RequestOptions => ({
    url: `${controller}/speech/filter-values/Subject`,
    query: baseQuery,
  }),
  getLabels: (): RequestOptions => ({
    url: `${controller}/speech/filter-values/Labels`,
    query: baseQuery,
  }),
}
