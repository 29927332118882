import { PhoneFilterValues, SavedPhoneFilter } from '@capturi/api-filters'
import pick from 'lodash/pick'
import { useCallback, useMemo } from 'react'

import { useSegmentFilterKeys } from '../../definitions'
import { toSavedFilterValues } from '../../mappers'
import { useFilterPeriodContext } from '../../period'
import { configurations } from '../../segmentConfigurations'
import { useSegmentStatesContext } from '../../state/segment-state/useSegmentStates'
import {
  hasUnsavedChanges as _hasUnsavedChanges,
  hasFilterValues,
} from '../../utils/filterState'
import {
  SearchParamsObject,
  toFilterSearchParams,
} from '../../utils/toFilterSearchParams'

const emptyState = {}

export function useFirstPhoneSegmentState(): {
  values: PhoneFilterValues | undefined
  activeValues: PhoneFilterValues | undefined
  savedFilter?: SavedPhoneFilter
  hasValues: boolean
  hasUnsavedChanges: boolean
  color: string
  label: string
  setFilterState: (state: Partial<PhoneFilterValues>) => void
  getFilterRequestModel: () => SearchParamsObject
  getSubFilterRequestModel: () => SearchParamsObject
  toSavedFilterValues: () => PhoneFilterValues
} {
  const { phoneSegmentStates: states, updateState } = useSegmentStatesContext()

  const { color, label } = configurations[0]
  const { activeKeys } = useSegmentFilterKeys()
  const { periodDef } = useFilterPeriodContext()

  const state = states[0] || emptyState
  const { values, savedFilter, subFilterState } = state
  const hasValues = useMemo(() => hasFilterValues(values), [values])

  const activeValues = useMemo(() => {
    return pick(values, activeKeys)
  }, [values, activeKeys])

  const hasUnsavedChanges = useMemo(() => _hasUnsavedChanges(state), [state])

  const getFilterRequestModel = useCallback((): SearchParamsObject => {
    return toFilterSearchParams(activeValues, periodDef)
  }, [activeValues, periodDef])

  const getSubFilterRequestModel = useCallback((): SearchParamsObject => {
    return toFilterSearchParams(subFilterState?.values, periodDef)
  }, [periodDef, subFilterState?.values])

  const setFilterState = useCallback(
    (state: Partial<PhoneFilterValues>): void => {
      updateState({
        state: {
          channel: 'phone',
          values: state,
        },
        index: 0,
      })
    },
    [updateState],
  )

  return {
    values,
    savedFilter: savedFilter as SavedPhoneFilter,
    activeValues,
    hasValues,
    hasUnsavedChanges,
    color,
    label,
    setFilterState,
    getFilterRequestModel,
    getSubFilterRequestModel,
    toSavedFilterValues: () => toSavedFilterValues(values),
  }
}
