import { createPatchPayload } from '@capturi/api-shared'
import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { mutate } from 'swr'

import { trackersAPI } from '../api'
import {
  CreateTrackerFolderPayload,
  TrackerFolder,
  TrackerResponseModel,
} from '../models'
import { url, cacheKey } from './constants'
import { TrackerFolderResponse } from './useTrackerFolders'

export const useCreateTrackerFolder = (): UseMutationResult<
  TrackerFolder,
  ResponseError,
  CreateTrackerFolderPayload,
  ResponseError
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (model: CreateTrackerFolderPayload) =>
      request.post<TrackerFolder>(url, { json: model }),
    onSuccess: (newFolder) => {
      queryClient.setQueryData<TrackerFolderResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { folders: [newFolder] }
        }
        return { folders: [newFolder, ...oldData.folders] }
      })
    },
  })
}

type UpdateTrackerFolderPayload = {
  uid: string
  folder: CreateTrackerFolderPayload
}
export const useUpdateTrackerFolder = (): UseMutationResult<
  TrackerFolder,
  ResponseError,
  UpdateTrackerFolderPayload,
  ResponseError
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey],
    mutationFn: ({ uid, folder }: UpdateTrackerFolderPayload) => {
      const oldData = queryClient.getQueryData<TrackerFolderResponse>([
        cacheKey,
      ])

      const oldFolder = oldData?.folders.find((folder) => folder.uid === uid)

      const payload = createPatchPayload<CreateTrackerFolderPayload>(
        oldFolder,
        folder,
      )

      return request.patch<TrackerFolder>(
        `trackers/folders/${uid}?api-version=3.3`,
        {
          json: payload,
        },
      )
    },
    onSuccess: (newFolder) => {
      queryClient.setQueryData<TrackerFolderResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { folders: [newFolder] }
        }
        return {
          folders: oldData.folders.map((f) =>
            f.uid === newFolder.uid ? newFolder : f,
          ),
        }
      })
    },
  })
}
export const useDeleteFolder = (): UseMutationResult<
  TrackerFolder,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey],
    mutationFn: (folderUid: string) =>
      request.delete<TrackerFolder>(
        `trackers/folders/${folderUid}?api-version=3.3`,
      ),
    onSuccess: (_, folderUid) => {
      queryClient.setQueryData<TrackerFolderResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { folders: [] }
        }

        //Would be nicer to move trackers this to react query as well
        mutate<TrackerResponseModel<false>>(
          trackersAPI.getTrackers(),
          (data) => {
            if (!data) return data
            const trackersWithoutFolder = data.trackers.map((t) =>
              t.folderUid === folderUid ? { ...t, folderUid: null } : t,
            )
            return { trackers: trackersWithoutFolder }
          },
        )

        return {
          folders: oldData.folders.filter((f) => f.uid !== folderUid),
        }
      })
    },
  })
}
