import {
  Acl,
  AclEntry,
  PermissionPreset,
  PreviewAccessRequestModel,
  PreviewAccessResponse,
} from '@capturi/core'
import request, { RequestOptions } from '@capturi/request'
import { t } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'

export type ValidationError = 'EmptyACL' | 'NoEditors'
export function getValidationErrorText(
  validationError: ValidationError | string,
): string {
  switch (validationError) {
    case 'EmptyACL':
      return t`*You need to choose at least one user, team or role`
    case 'NoEditors':
      return t`*You need at least one editor`
    default:
      return validationError
  }
}

const validateACL = (
  permissionPreset: PermissionPreset,
  acl: Acl | null,
): ValidationError | undefined => {
  if (permissionPreset === 'Custom') {
    if (acl == null || acl.length === 0) {
      return 'EmptyACL'
    }
    if (!acl.some((aclEntry: AclEntry) => aclEntry.level === 'Edit')) {
      return 'NoEditors'
    }
  }
  return undefined
}

export type UsePreviewAccessProps = {
  previewAccessRequestModel: PreviewAccessRequestModel
  createPreviewAccessRequest: (
    previewAccessRequestModel: PreviewAccessRequestModel,
  ) => RequestOptions
}

export interface UsePreviewAccessResult {
  previewAccessResponse: PreviewAccessResponse | undefined
  isPreviewAccessLoading: boolean
  validationError: ValidationError | string | undefined
}

export function usePreviewAccess(
  props: UsePreviewAccessProps,
): UsePreviewAccessResult {
  const { previewAccessRequestModel, createPreviewAccessRequest } = props
  const validationError = validateACL(
    previewAccessRequestModel.permissionPreset,
    previewAccessRequestModel.acl,
  )

  const previewAccessRequest = createPreviewAccessRequest(
    previewAccessRequestModel,
  )
  const { data, error, isFetching } = useQuery<PreviewAccessResponse, Error>({
    enabled:
      validationError === undefined &&
      previewAccessRequestModel.permissionPreset === 'Custom',
    queryKey: ['preview-access', previewAccessRequest],
    queryFn: () => request(previewAccessRequest),
  })

  return {
    previewAccessResponse: validationError === undefined ? data : undefined,
    isPreviewAccessLoading: isFetching,
    validationError: validationError || error?.message,
  }
}
