import analytics from '@capturi/analytics'
import { Box, Flex, Icon, List, ListItem, Text, VStack } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'
import React, { useCallback } from 'react'
import { MdArrowDropDown, MdArrowDropUp, MdCampaign } from 'react-icons/md'

type KeyTopicsListItemProps = {
  id: string
  amount: number
  amountVsExpectedDiff: number
  label: string
  significant: boolean
  isSelected: boolean
  onClick: (id: string) => void
}

const KeyTopicsListItem: React.FC<KeyTopicsListItemProps> = React.memo(
  ({
    id,
    amount,
    amountVsExpectedDiff,
    label,
    significant,
    isSelected,
    onClick,
  }) => {
    return (
      <ListItem
        borderColor={isSelected ? 'curiousBlue.300' : 'gray.200'}
        borderWidth="1px"
        borderRadius="lg"
        onClick={() => onClick(id)}
        px={4}
        pb={3}
        pt={2}
        overflow="hidden"
        position="relative"
        boxShadow={
          isSelected
            ? '1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset, -1px -1px 2px 0px rgba(223, 223, 223, 0.50) inset, -5px 5px 10px 0px rgba(223, 223, 223, 0.20), 5px -5px 10px 0px rgba(223, 223, 223, 0.20), -5px -5px 10px 0px rgba(255, 255, 255, 0.90), 5px 5px 13px 0px rgba(223, 223, 223, 0.90)'
            : ''
        }
        background={
          isSelected ? 'linear-gradient(135deg, #FFF 0%, #EAEAEA 100%)' : ''
        }
        _hover={{
          bg: isSelected ? '' : 'gray.100',
        }}
        cursor="pointer"
        transition="all 250ms ease-out"
      >
        <Box
          position="absolute"
          left={0}
          top={0}
          bottom={0}
          backgroundColor={isSelected ? 'curiousBlue.500' : 'transparent'}
          w="4px"
        />
        <VStack alignItems="stretch" gap={0} w="100%">
          <Flex alignItems="center" justifyContent="space-between">
            <Text
              fontSize="md"
              fontWeight="medium"
              lineHeight="5"
              noOfLines={1}
            >
              {label}
            </Text>
            <Flex alignItems="center">
              {amountVsExpectedDiff > 0 ? <Icon as={MdArrowDropUp} /> : null}
              {amountVsExpectedDiff < 0 ? <Icon as={MdArrowDropDown} /> : null}
              <Text
                color="gray.600"
                fontSize="lg"
                fontWeight="medium"
                lineHeight="5"
              >
                {Math.abs(amountVsExpectedDiff)}
              </Text>
            </Flex>
          </Flex>

          <Flex justifyContent="space-between">
            <Text fontSize="sm" noOfLines={1}>
              <Trans>{amount.toFixed()} conversations</Trans>
            </Text>

            {significant && <Icon as={MdCampaign} ml={1} />}
          </Flex>
        </VStack>
      </ListItem>
    )
  },
)

type KeyTopicsListItem = {
  id: string
  label: string
  amount: number
  amountVsExpectedDiff: number
  significant: boolean
}

type KeyTopicsListProps = {
  considerableChangesItems: KeyTopicsListItem[]
  normalItems: KeyTopicsListItem[]
  selectedKeyTopicId: string | undefined
  setSelectedKeyTopicId: (id: string) => void
}

export const KeyTopicsList: React.FC<KeyTopicsListProps> = React.memo(
  ({
    considerableChangesItems,
    normalItems,
    selectedKeyTopicId,
    setSelectedKeyTopicId,
  }) => {
    const handleSelectTopic = useCallback(
      (id: string) => {
        setSelectedKeyTopicId(id)
        analytics.event('top_changes_topic_selected')
      },
      [setSelectedKeyTopicId],
    )
    const handleSelectConsiderableChangeTopic = useCallback(
      (id: string) => {
        setSelectedKeyTopicId(id)
        analytics.event('top_changes_considerable_changes_topic_selected')
      },
      [setSelectedKeyTopicId],
    )
    return (
      <>
        {considerableChangesItems.length > 0 && (
          <Box position="relative" w="100%">
            <Flex
              css={css`&:before {
                      content: '';
                      position: absolute;
                      top: 0;
                      right: 0;
                      bottom: 0;
                      left: 0;
                      z-index: -1;
                      opacity: 0.5;
                      border-radius: 8px;
                      background: linear-gradient(180deg, #FFD929 0%, #FAAE0A 100%);
                  }`}
              borderRadius="4px"
              w="100%"
              h="100%"
            >
              <Box bg="white" py={3} px={3} m="2px" w="100%" borderRadius="7px">
                <Box
                  position="absolute"
                  top="-8px"
                  left="50%"
                  maxW="80%"
                  transform="translateX(-50%)"
                  backgroundColor="white"
                  px={2}
                >
                  <Text
                    fontSize="xs"
                    fontWeight="medium"
                    textTransform="uppercase"
                    isTruncated
                    css={{
                      background:
                        'linear-gradient(180deg, #FFD929 0%, #FAAE0A 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    <Trans>Considerable changes</Trans>
                  </Text>
                </Box>
                <List
                  spacing={2}
                  data-stonly="considerable-changes-key-topics-list"
                >
                  {considerableChangesItems.map(
                    ({
                      id,
                      label,
                      amount,
                      amountVsExpectedDiff,
                      significant,
                    }) => (
                      <KeyTopicsListItem
                        key={id}
                        id={id}
                        label={label}
                        amount={amount}
                        amountVsExpectedDiff={amountVsExpectedDiff}
                        significant={significant}
                        isSelected={selectedKeyTopicId === id}
                        onClick={handleSelectConsiderableChangeTopic}
                      />
                    ),
                  )}
                </List>
              </Box>
            </Flex>
          </Box>
        )}
        <List
          spacing={2}
          w="16.5rem"
          px={2}
          data-stonly="top-changes-key-topics-list"
        >
          {normalItems.map(
            ({ id, label, amount, amountVsExpectedDiff, significant }) => (
              <KeyTopicsListItem
                key={id}
                id={id}
                label={label}
                amount={amount}
                amountVsExpectedDiff={amountVsExpectedDiff}
                significant={significant}
                isSelected={selectedKeyTopicId === id}
                onClick={handleSelectTopic}
              />
            ),
          )}
        </List>
      </>
    )
  },
)
