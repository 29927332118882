import analytics from '@capturi/analytics'
import {
  ConversationResponseModel,
  useMutateConversation,
} from '@capturi/api-conversations'
import { PlaybackContext, formatTime, useAudioContext } from '@capturi/audio'
import { CurrentUser, Speaker, useCurrentUser } from '@capturi/core'
import { useHover } from '@capturi/react-utils'
import { User, useOrganization, useUsers } from '@capturi/stores'
import {
  ContentPlaceholder,
  Menu,
  MenuButton,
  MenuList,
  PlayButton,
  Spinner,
} from '@capturi/ui-components'
import {
  Box,
  Flex,
  Icon,
  Popover,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Trans, select, t } from '@lingui/macro'
import PrivateTrackerTag from 'components/PrivateTrackerTag'
import {
  FalseRecognitionMenuItem,
  useMarkAsFalseRecognition,
} from 'features/trackers/markAsFalseRecognition'
import React, { useRef } from 'react'
import { MdMoreVert, MdTrackChanges } from 'react-icons/md'

import hasNearnessSettings from '../../../utils/hasNearnessSettings'
import {
  Result,
  TrackerTableContentsResult,
} from '../Trackers/useTrackerTableContents'
import NearnessPopoverContent from './NearnessPopoverContent'

const NoResultsPlaceholder: React.FC = () => {
  return (
    <>
      <ContentPlaceholder.Container mt={8} size="md">
        <ContentPlaceholder.Heading>
          <Trans>No trackers detected on this conversation</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>
            None of the created trackers were recognized in this conversation.
          </Trans>
        </ContentPlaceholder.Body>
      </ContentPlaceholder.Container>
    </>
  )
}

type TrackerContentsResultsListProps = {
  conversation: ConversationResponseModel
  contents: TrackerTableContentsResult
}

const TrackerContentsResultsList: React.FC<TrackerContentsResultsListProps> = ({
  conversation,
  contents,
}) => {
  const { uid: conversationUid, dateTime } = conversation

  const { isPlaying, play, pause, playbackContext, setPlaybackContext } =
    useAudioContext(`/playback/audio/${conversationUid}`, {
      rollbackSeconds: 3,
    })

  const currentUser = useCurrentUser()
  const permissions = currentUser.permissions

  const handlePlay = React.useCallback(
    (result: Result, id: string) => {
      if (!permissions.playback) return
      if (isPlaying && id === playbackContext?.hitId) {
        pause()
      } else {
        play(result.timestamp, true)

        setPlaybackContext({
          ...playbackContext,
          hitId: id,
        })

        analytics.event('conversationDetails_trackerList_playAudio', {
          isWordSearch: false,
          conversationUid,
          date: dateTime.toUTCString(),
        })
      }
    },
    [
      conversationUid,
      permissions.playback,
      dateTime,
      isPlaying,
      pause,
      play,
      playbackContext,
      setPlaybackContext,
    ],
  )

  const { mutate } = useMutateConversation(conversationUid)
  const handleSettled = React.useCallback(
    (data: ConversationResponseModel) => {
      mutate(data)
    },
    [mutate],
  )
  const { addContextError, addRecognitionError } = useMarkAsFalseRecognition(
    conversationUid,
    {
      onSettled: handleSettled,
    },
  )
  const handleAddRecognitionError = React.useCallback(
    (result: Result) => {
      addRecognitionError(result.words, result.timestamp)
    },
    [addRecognitionError],
  )

  const handleAddContextError = React.useCallback(
    (result: Result) => {
      if (result.trackerUid == null) return
      addContextError(result.words, result.timestamp, result.trackerUid)
    },
    [addContextError],
  )

  const { getUserByUid } = useUsers()

  return (
    <Box flex="1">
      {contents.isLoading ? (
        <Spinner display="block" m="1rem auto" />
      ) : contents.hasNoResults ? (
        <NoResultsPlaceholder />
      ) : (
        <Table variant="conversation-hits">
          <Thead fontSize="xs" fontWeight="medium">
            <Tr>
              <Th>
                {conversation.hasAudio && permissions.playback ? (
                  <Trans>Play</Trans>
                ) : (
                  <Trans>Timestamp</Trans>
                )}
              </Th>
              <Th>
                <Trans>Tracker</Trans>
              </Th>
              <Th>
                <Trans>Word</Trans>
              </Th>
              <Th>
                <Trans>Speaker</Trans>
              </Th>
              <Th w={8} />
            </Tr>
          </Thead>
          <Tbody>
            {contents.results.map((x) => {
              const id = `${x.trackerUid}:${x.words.join('-')}:${x.timestamp}:${
                x.speakerId
              }`
              return (
                <MemoizedTableRow
                  key={id}
                  id={id}
                  result={x}
                  conversation={conversation}
                  currentUser={currentUser}
                  isPlaying={isPlaying}
                  playbackContext={playbackContext}
                  onPlay={handlePlay}
                  getUserByUid={getUserByUid}
                  onAddRecognitionError={handleAddRecognitionError}
                  onAddContextError={handleAddContextError}
                />
              )
            })}
          </Tbody>
        </Table>
      )}
    </Box>
  )
}

type TableRowProps = {
  id: string
  result: Result
  conversation: ConversationResponseModel
  currentUser: CurrentUser
  isPlaying: boolean
  playbackContext: PlaybackContext | null
  onPlay: (result: Result, id: string) => void
  getUserByUid: (uid: string | null | undefined) => User
  onAddRecognitionError: (result: Result) => void
  onAddContextError: (result: Result) => void
}

const TableRow: React.FC<TableRowProps> = ({
  id,
  result,
  conversation,
  currentUser,
  isPlaying,
  playbackContext,
  onPlay,
  getUserByUid,
  onAddRecognitionError,
  onAddContextError,
}) => {
  const containerRef = useRef<HTMLTableRowElement>(null)
  const isHovering = useHover(containerRef)
  const { organizationType } = useOrganization()
  const { userUid: salesPersonUid } = conversation
  const isPlayable = conversation.hasAudio && currentUser.permissions.playback

  const showTrackerHitMenu =
    (currentUser.isAdmin || currentUser.isTeamLead) &&
    currentUser.permissions.playback

  const isTrackPlaying = isPlaying && playbackContext?.hitId === id
  const isIgnored = React.useMemo(
    () =>
      conversation?.ignoredPhrases.some((ignoredPhrase) => {
        const timeAndPhraseMatch =
          ignoredPhrase.timeOffset === result.timestamp &&
          result.words.includes(ignoredPhrase.phrase)
        if (!timeAndPhraseMatch) return false
        if (ignoredPhrase.reason === 'Context') {
          return ignoredPhrase.trackerUid === result.trackerUid
        }
        return true
      }),
    [conversation, result.timestamp, result.words, result.trackerUid],
  )

  const handlePlay = React.useCallback(() => {
    onPlay(result, id)
  }, [id, onPlay, result])

  const handleAddRecognitionError = React.useCallback(() => {
    onAddRecognitionError(result)
  }, [onAddRecognitionError, result])

  const handleAddContextError = React.useCallback(() => {
    onAddContextError(result)
  }, [onAddContextError, result])

  const phraseSettingsNotEmpty =
    result.phrasesSettings && Object.keys(result.phrasesSettings).length > 0
  const emptyNearnessObject = {
    near: null,
    notNear: null,
  }
  return (
    <Tr
      ref={containerRef}
      cursor={isPlayable ? 'pointer' : 'cursor'}
      onClick={handlePlay}
      aria-label={t`Play audio`}
      role="group"
      opacity={isIgnored ? 0.4 : 1}
      pointerEvents={isIgnored ? 'none' : 'auto'}
    >
      <Td>
        <Flex alignItems="center" gap={2}>
          <PlayButton
            isDisabled={isIgnored || undefined}
            isPlaying={isTrackPlaying}
            variant="solid"
            hasAudio={conversation.hasAudio}
            isPlaybackAllowed={currentUser.permissions.playback}
          />
          {isPlayable && <Text>{formatTime(result.timestamp)}</Text>}
        </Flex>
      </Td>
      <Td>
        <Flex align="center" wrap="wrap">
          <Text>{result.trackerName}</Text>
          {result.trackerIsPrivate && <PrivateTrackerTag ml={1} />}
        </Flex>
      </Td>
      <Td userSelect="none" pointerEvents="none">
        <Flex align="center" wrap="wrap" flexDirection="row">
          {result.words.map((word, index, elements) => {
            const nearnessObject = phraseSettingsNotEmpty
              ? result.phrasesSettings?.[word] ?? emptyNearnessObject
              : emptyNearnessObject
            return (
              <Box key={index}>
                <Text as="span">{word}</Text>
                {hasNearnessSettings(nearnessObject) && (
                  <Popover trigger="hover">
                    <PopoverTrigger>
                      <Box pointerEvents="auto" as="span" display="inline">
                        <MdTrackChanges
                          style={{
                            display: 'inline',
                            marginLeft: 2,
                            marginBottom: -1,
                          }}
                        />
                      </Box>
                    </PopoverTrigger>
                    <NearnessPopoverContent phrasesSetting={nearnessObject} />
                  </Popover>
                )}
                {index !== elements.length - 1 && (
                  <Text as="span" pr="1">
                    {','}
                  </Text>
                )}
              </Box>
            )
          })}
        </Flex>
      </Td>
      <Td>
        {result.speakerUserUid || result.speakerId === Speaker.Employee ? (
          <Text>
            {getUserByUid(result.speakerUserUid || salesPersonUid).name}
          </Text>
        ) : (
          select(organizationType, {
            public: 'Citizen',
            other: 'Customer',
          })
        )}
      </Td>
      <Td w={8} minH={6}>
        {showTrackerHitMenu && isHovering ? (
          <TrackerHitMenu
            result={result}
            onAddRecognitionError={handleAddRecognitionError}
            onAddContextError={handleAddContextError}
          />
        ) : null}
      </Td>
    </Tr>
  )
}

const MemoizedTableRow = React.memo(TableRow)

type TrackerHitMenuProps = {
  result: Result
  onAddContextError: () => void
  onAddRecognitionError: () => void
}
const TrackerHitMenu = React.memo(
  ({
    result,
    onAddContextError,
    onAddRecognitionError,
  }: TrackerHitMenuProps) => {
    const stopPropagation = React.useCallback((e: React.MouseEvent) => {
      e.stopPropagation()
    }, [])

    return (
      <Menu autoSelect={false} isLazy>
        <MenuButton color="gray.600" onClick={stopPropagation}>
          <Icon as={MdMoreVert} boxSize={5} />
        </MenuButton>
        <MenuList onClick={stopPropagation}>
          <FalseRecognitionMenuItem.RecognitionError
            onClick={onAddRecognitionError}
          />
          <FalseRecognitionMenuItem.WrongContext
            trackerName={result.trackerName}
            onClick={onAddContextError}
          />
        </MenuList>
      </Menu>
    )
  },
)

export default TrackerContentsResultsList
