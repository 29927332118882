import {
  API,
  APIFactory,
  RequestTuple,
  addAPIVersion,
  createUseAPI,
} from '@capturi/api-utils'
import partial from 'lodash/partial'

import { LibraryFolderPermissions, NewFolder, NewSnippet } from './types'

const DEFAULT_API_VERSION = '3.3'

export interface SnippetLibraryAPI extends API {
  getSnippet: (uid: string) => RequestTuple
  getSnippets: (folderUid?: string) => RequestTuple
  createSnippet: (snippet: {
    snippet: NewSnippet
    sendOutOfAppNotification: boolean
    sendInAppNotification: boolean
  }) => RequestTuple
  updateSnippet: (
    uid: string,
    title: string,
    description?: string,
    folderUid?: string,
  ) => RequestTuple
  deleteSnippet: (uid: string) => RequestTuple
  getFolder: (uid: string) => RequestTuple
  getFolders: () => RequestTuple
  createFolder: (folder: NewFolder) => RequestTuple
  updateFolder: (
    uid: string,
    name: string,
    description?: string,
    permissions?: LibraryFolderPermissions | null,
  ) => RequestTuple
  deleteFolder: (uid: string) => RequestTuple
  moveFolder: (
    uid: string,
    newParentFolderUid: string | null | undefined,
  ) => RequestTuple
  getSnippetAudio: (uid: string) => RequestTuple
}

const api: APIFactory<SnippetLibraryAPI> = (
  apiVersion: string = DEFAULT_API_VERSION,
) => {
  const controller = 'library'
  const withVersion = partial(addAPIVersion, apiVersion)
  return {
    getSnippet: (uid: string) => [withVersion(`${controller}/${uid}`)],
    getSnippets: (folderUid?: string) => [
      withVersion(`${controller}`),
      folderUid !== undefined
        ? {
            method: 'get',
            searchParams: {
              'api-version': apiVersion,
              folderUid,
            },
          }
        : undefined,
    ],
    createSnippet: ({
      sendInAppNotification,
      sendOutOfAppNotification,
      snippet,
    }: {
      snippet: NewSnippet
      sendOutOfAppNotification: boolean
      sendInAppNotification: boolean
    }) => {
      return [
        withVersion(
          `${controller}?sendOutOfAppNotification=${sendOutOfAppNotification}&sendInAppNotification=${sendInAppNotification}`,
        ),
        {
          method: 'post',
          json: snippet,
        },
      ]
    },
    updateSnippet: (
      uid: string,
      title: string,
      description?: string,
      folderUid?: string,
    ) => {
      return [
        withVersion(`${controller}/${uid}`),
        {
          method: 'patch',
          json: { title, description, folderUid },
        },
      ]
    },
    deleteSnippet: (uid: string) => {
      return [
        withVersion(`${controller}/${uid}`),
        {
          method: 'delete',
        },
      ]
    },
    getFolder: (uid: string) => [withVersion(`${controller}/folders/${uid}`)],
    getFolders: () => [withVersion(`${controller}/folders`)],
    createFolder: (newFolder: NewFolder) => {
      return [
        withVersion(`${controller}/folders`),
        {
          method: 'post',
          json: {
            name: newFolder.name,
            description: newFolder.description,
            parentFolderUid: newFolder.parentFolderUid,
            permissions: newFolder.permissions,
          },
        },
      ]
    },
    updateFolder: (
      uid: string,
      name: string,
      description?: string,
      permissions?: LibraryFolderPermissions | null,
    ) => {
      return [
        withVersion(`${controller}/folders/${uid}`),
        {
          method: 'patch',
          json: {
            name,
            description,
            permissions,
          },
        },
      ]
    },
    deleteFolder: (uid: string) => {
      return [
        withVersion(`${controller}/folders/${uid}`),
        {
          method: 'delete',
        },
      ]
    },
    moveFolder: (
      uid: string,
      newParentFolderUid: string | null | undefined,
    ) => {
      return [
        withVersion(`${controller}/folders/${uid}`),
        {
          method: 'post',
          json: {
            parentFolderUid: newParentFolderUid ?? null,
          },
        },
      ]
    },
    getSnippetAudio: (uid: string) => {
      return [withVersion(`playback/library/${uid}`)]
    },
  }
}

const useAPI = createUseAPI<SnippetLibraryAPI>(api, DEFAULT_API_VERSION)

export { api, useAPI }
