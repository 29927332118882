import React from 'react'

const ShadowFilter: React.FC = () => {
  return (
    <filter id="shadow" x="-20%" width="140%" y="-20%" height="140%">
      <feDropShadow
        dx="0"
        dy="1"
        stdDeviation="0.5"
        floodColor="#216D97"
        floodOpacity="0.15"
      />
    </filter>
  )
}

export default React.memo(ShadowFilter)
