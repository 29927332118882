import { Acl, PermissionPreset, useCurrentUser } from '@capturi/core'
import { getAcl, getPermissionPreset } from '@capturi/filters'
import { PersonsWithAccess, ShareAndEditOptions } from '@capturi/sharing'
import { Button } from '@capturi/ui-components'
import {
  Divider,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

type Permissions = {
  permissionPreset: PermissionPreset
  permissionPresetUserUid: string | null
  acl: Acl | null
}

type FolderTrackerPermissionModel = {
  permissionPreset: PermissionPreset
  permissionPresetUserUid: string | null
  acl: Acl | null
  folderPermissionPreset: PermissionPreset | undefined
  folderAcl: Acl | null
}

export const PermissionDialog: React.FC<{
  header: React.ReactNode
  isOpen: boolean
  onClose: () => void
  onSave: (model: Permissions) => void
  initialPermissionPreset: PermissionPreset
  initialAcl: Acl | null
  permissionPresetUserUid: string | null
  folderPermissionPreset: PermissionPreset | undefined
  folderAcl: Acl | null | undefined
}> = ({
  header,
  isOpen = false,
  onClose,
  onSave,
  initialPermissionPreset,
  initialAcl,
  folderPermissionPreset,
  folderAcl,
  permissionPresetUserUid,
}) => {
  const currentUser = useCurrentUser()
  const localizedEntityName = t`trackers`
  const [state, _setState] = React.useState<FolderTrackerPermissionModel>(
    () => ({
      permissionPreset: initialPermissionPreset ?? 'Private',
      permissionPresetUserUid: permissionPresetUserUid ?? null,
      acl: initialAcl ?? [],
      folderPermissionPreset: folderPermissionPreset ?? undefined,
      folderAcl: folderAcl ?? [],
    }),
  )

  const setState = React.useCallback(
    (partialState: Partial<FolderTrackerPermissionModel>): void => {
      _setState((state) => ({
        ...state,
        ...partialState,
        acl: getAcl(partialState, state, currentUser.id),
        permissionPresetUserUid: getPermissionPreset(
          partialState,
          state,
          currentUser.id,
        ),
      }))
    },
    [currentUser.id],
  )

  const showsACL =
    state.permissionPreset === 'Custom' ||
    (initialPermissionPreset === 'Inherit' &&
      folderPermissionPreset === 'Custom')
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size={showsACL ? '4xl' : 'lg'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <Divider />
        <ModalBody>
          <Grid
            templateColumns={{
              base: '1fr',
              md: showsACL ? '1fr 1px 1fr' : '1fr',
            }}
            gap={4}
          >
            <Stack
              borderRight={{
                base: 'none',
                md: showsACL
                  ? '1px solid var(--chakra-colors-gray-200)'
                  : 'none',
              }}
              pr={{
                base: 0,
                md: showsACL ? 8 : 0,
              }}
              pb={{ base: 2, md: 0 }}
              spacing={4}
            >
              <ShareAndEditOptions
                permissionPreset={state.permissionPreset}
                folderPermissionPreset={folderPermissionPreset}
                onChange={(permissionPreset) => setState({ permissionPreset })}
                localizedEntityName={localizedEntityName}
              />
            </Stack>
            <Divider />
            {showsACL && (
              <Flex maxH={{ base: '660px', md: '430px' }}>
                <PersonsWithAccess
                  acl={
                    (state.permissionPreset === 'Inherit'
                      ? folderAcl
                      : state.acl) ?? []
                  }
                  isDisabled={state.permissionPreset === 'Inherit'}
                  onChange={(acl) =>
                    setState({
                      acl: acl,
                    })
                  }
                />
              </Flex>
            )}
          </Grid>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Flex alignItems="flex-start" justifyContent="space-between" w="100%">
            <Spacer />
            <Stack isInline>
              <Button type="button" onClick={onClose}>
                <Trans>Cancel</Trans>
              </Button>
              <Button
                primary
                type="button"
                onClick={() => {
                  onSave({
                    permissionPreset: state.permissionPreset,
                    permissionPresetUserUid: state.permissionPresetUserUid,
                    acl: state.acl,
                  })
                  onClose()
                }}
              >
                <Trans>Apply</Trans>
              </Button>
            </Stack>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
