const S3_BUCKET = 'capturi-profile-images'
const IMAGE_URL = 'https://images.capturi.ai/'

type Opt = {
  size?: number | null
  width?: number | null
  height?: number | null
}

const generateImageUrl = (key: string | undefined, opt?: Opt): string => {
  if (!key) return ''
  const width = opt?.size || opt?.width || null
  const height = opt?.size || opt?.height || null

  //Generates a image with the right resolution for the screen size
  const scale = window.devicePixelRatio
  const scaledWidth = width ? width * scale : null
  const scaledHeight = height ? height * scale : null

  const imageRequest = JSON.stringify({
    bucket: S3_BUCKET,
    key,
    edits: {
      resize: { width: scaledWidth, height: scaledHeight, fit: 'cover' },
    },
  })

  return IMAGE_URL + btoa(imageRequest)
}

export default generateImageUrl
