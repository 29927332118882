import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import React from 'react'
import HomePage from './HomePage'

const HomePageWrapper: React.FC = () => {
  const { aiFeatures, useEmailChannelAsDefault, hideHomepage } =
    useFeatureFlags()
  const user = useCurrentUser()
  if (
    !aiFeatures ||
    !user.isAdminOrTeamLead ||
    useEmailChannelAsDefault ||
    hideHomepage
  ) {
    return null
  }
  return <HomePage />
}

export default HomePageWrapper
