import { useAiQuestionAnswers } from '@capturi/api-ask-ai'
import { Skeleton, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

type ConversationAskAiAnswerProps = {
  conversationUid: string
  askAiQuery: string
}

export const ConversationAskAiAnswer: React.FC<
  ConversationAskAiAnswerProps
> = ({ conversationUid, askAiQuery }) => {
  const { data: answer, isFetching: isLoadingAnswer } = useAiQuestionAnswers({
    conversationUids: [conversationUid],
    question: askAiQuery,
  })

  if (isLoadingAnswer) {
    return (
      <VStack alignItems="stretch" gap={1} w="100%">
        <Skeleton height="10px" w="75%" />
        <Skeleton height="10px" w="95%" />
        <Skeleton height="10px" w="90%" />
        <Skeleton height="10px" w="80%" />
        <Skeleton height="10px" w="75%" />
      </VStack>
    )
  }

  if (!answer || answer.length === 0) {
    return (
      <Text fontSize="sm" color="gray.500">
        <Trans>No answer found</Trans>
      </Text>
    )
  }

  return <Text>{answer[0].answer}</Text>
}
