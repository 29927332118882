import { addAPIVersion, createUseAPI } from '@capturi/api-utils'
import type { API, APIFactory, RequestTuple } from '@capturi/api-utils'
import partial from 'lodash/partial'

const DEFAULT_API_VERSION = '3.3'

export interface NotificationsAPI extends API {
  /**
   * List notifications
   */
  getNotifications: (
    pageSize?: number,
    pageNumber?: number,
    includeRead?: boolean,
  ) => RequestTuple
  /**
   * Number of unread notifications
   */
  getUnreadCount: () => RequestTuple
  /**
   * Mark all notifications as read
   */
  markAllAsRead: () => RequestTuple
  /**
   * Mark list of notifications as read
   */
  markSpecificAsRead: (notificationUids: string[]) => RequestTuple
  /**
   * /notifications/mark-specific-unread
   */
  markSpecificAsUnread: (notificationUids: string[]) => RequestTuple
  /**
   * Mark list of notifications as read based on commentUids
   */
  markCommentNotificationsAsRead: (commentUids: string[]) => RequestTuple
  /**
   * Mark list of notifications as read based on audioSnippetUids
   */
  markAudioSnippetNotificationsAsRead: (
    audioSnippetUids: string[],
  ) => RequestTuple
}

export const api: APIFactory<NotificationsAPI> = (
  apiVersion: string = DEFAULT_API_VERSION,
) => {
  const controller = 'notifications'
  const withVersion = partial(addAPIVersion, apiVersion)
  return {
    getNotifications: (
      pageSize?: number,
      pageNumber?: number,
      includeRead?: boolean,
    ) => [
      withVersion(
        `${controller}?pageSize=${pageSize}&sortDirection=${1}&pageNumber=${pageNumber}&includeRead=${includeRead}`,
      ),
    ],
    getUnreadCount: () => [withVersion(`${controller}/unread-count`)],
    markAllAsRead: () => {
      return [
        withVersion(`${controller}/mark-all-read`),
        {
          method: 'post',
        },
      ]
    },
    markSpecificAsRead: (notificationUids: string[]) => {
      return [
        withVersion(`${controller}/mark-specific-read`),
        {
          method: 'post',
          json: {
            notificationUids,
          },
        },
      ]
    },
    markSpecificAsUnread: (notificationUids: string[]) => {
      return [
        withVersion(`${controller}/notifications/mark-specific-unread`),
        {
          method: 'post',
          json: {
            notificationUids,
          },
        },
      ]
    },
    markCommentNotificationsAsRead: (commentUids: string[]) => {
      return [
        withVersion('conversations/comments/mark-notifications-as-read'),
        {
          method: 'post',
          json: {
            commentUids,
          },
        },
      ]
    },
    markAudioSnippetNotificationsAsRead: (audioSnippetUids: string[]) => {
      return [
        withVersion('library/mark-notifications-as-read'),
        {
          method: 'post',
          json: {
            audioSnippetUids,
          },
        },
      ]
    },
  }
}

export const useAPI = createUseAPI<NotificationsAPI>(api, DEFAULT_API_VERSION)
