import constate from 'constate'
import qs from 'query-string'
import React from 'react'
import useSyncSearchParam from 'router/useSyncSearchParam'

const QUERY_KEY = 'tags'

function useTags(): [string[] | undefined, (tags: string[]) => void] {
  const state = React.useState(() => {
    // read query param initially and set state
    const parsedQuery = qs.parse(window.location.search)
    const value = parsedQuery[QUERY_KEY]
    if (value == null) {
      return undefined
    }
    if (typeof value === 'string') {
      return [value]
    }
    return value.filter(Boolean) as string[]
  })
  useSyncSearchParam(QUERY_KEY, state[0])
  return state
}

const [TagsProvider, useTagsContext] = constate(useTags)

export { TagsProvider, useTagsContext }
