import { Highlight } from '@capturi/ui-components'
import { Flex, Text } from '@chakra-ui/react'
import getPercentage from 'pages/TrackersPage/utils/getPercentage'
import React from 'react'

import format from '../../../utils/numberFormat'
import { PreviewTrackerHitsPlayButton } from './PreviewTrackerHitsPlayButton'

export type PreviewTrackerHitsViewProps = {
  hits: number
  total: number
  isFetching: boolean
  shortTextForm?: boolean
  onViewConversations: () => void
}
export const NUMBER_OF_PHRASES = 1000

export const PreviewTrackerHits: React.FC<PreviewTrackerHitsViewProps> = ({
  hits,
  total,
  isFetching,
  onViewConversations,
}) => {
  return (
    <Flex align="center" justify="flex-end" minH={6} py={2}>
      <Text color="gray.800" as="span" fontWeight="bold">
        {getPercentage(hits, total)}
      </Text>{' '}
      <Highlight color="gray.600">({format(hits)}</Highlight>)
      <PreviewTrackerHitsPlayButton
        hits={hits}
        isFetching={isFetching}
        onViewConversations={onViewConversations}
      />
    </Flex>
  )
}
