import analytics from '@capturi/analytics'
import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import LogoText_Negative from '@capturi/assets/images/logo/capturi_w-text_pos.svg'
import WavesBackgroundFileUrl from '@capturi/assets/images/waves_bg.svg'
import { AuthService } from '@capturi/auth'
import { getErrorObject } from '@capturi/request'
import { Button, ContentPlaceholder } from '@capturi/ui-components'
import { Box, CircularProgress, Flex, Heading, Stack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { DEFAULT_LANGUAGE, useLanguage } from 'i18n'
import React, { ReactElement, useEffect, useState } from 'react'
import { IoIosRocket } from 'react-icons/io'
import { useNavigate, useSearchParams } from 'react-router-dom'

const AddOrganization: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const inviteParam = searchParams.get('invite')

  const [addingOrgInProgress, setAddingOrgInProgress] = useState<boolean>(
    Boolean(searchParams.get('invite')),
  )
  const [newOrg, setNewOrg] = useState<string | null>(null)
  const [error, setError] = useState<Error | null>(null)

  const { language = DEFAULT_LANGUAGE } = useLanguage()
  const navigate = useNavigate()

  useEffect(() => {
    const add = async (): Promise<void> => {
      try {
        if (inviteParam) {
          const resp = await AuthService.addOrganization(inviteParam, language)
          setNewOrg(resp.org)
          setAddingOrgInProgress(false)
        }
      } catch (error) {
        const e = await getErrorObject(error)
        setError(e)

        setTimeout(() => {
          navigate('/')
        }, 5000)
      }
    }
    if (inviteParam) add()
  }, [language, inviteParam, navigate])

  const backgroundURL = `url(${WavesBackgroundFileUrl})`

  const handleStayInCurrentOrg = (): void => {
    searchParams.delete('invite')
    setSearchParams(searchParams, { replace: true })
  }

  const handleGoToNewOrg = async (): Promise<void> => {
    if (!newOrg) return
    await AuthService.changeOrganization(newOrg)
    analytics.reset()
    searchParams.delete('invite')
    setSearchParams(searchParams, { replace: true })
    window.location.href = window.location.origin
  }

  const renderContent = (): ReactElement => {
    if (error) {
      return (
        <ContentPlaceholder.Container>
          <ContentPlaceholder.Image as={Icon_EmptyState} />
          <ContentPlaceholder.Heading>
            <Trans>Redirecting to login page - </Trans>
          </ContentPlaceholder.Heading>
          <ContentPlaceholder.Heading>
            <Trans>in 5 seconds...</Trans>
          </ContentPlaceholder.Heading>
          <ContentPlaceholder.Body>
            <Trans>
              This invitation is no longer valid. Ask your administrator for a
              new invite or contact our support.
            </Trans>
          </ContentPlaceholder.Body>
        </ContentPlaceholder.Container>
      )
    }

    if (addingOrgInProgress) {
      return (
        <>
          <CircularProgress isIndeterminate size="100px" thickness="4px" />

          <Heading fontSize="4xl" mt="8">
            <Trans>Adding new organization</Trans>
          </Heading>
        </>
      )
    }

    return (
      <>
        <IoIosRocket size="100px" />
        <Heading fontSize="4xl" mt="6">
          <Trans>New organization added</Trans>
        </Heading>
        <Stack direction="row" spacing="2" mt="24">
          <Button variant="ghost" onClick={handleStayInCurrentOrg}>
            <Trans>Stay in current organization</Trans>
          </Button>
          <Button onClick={handleGoToNewOrg} primary>
            <Trans>Go to new organization</Trans>
          </Button>
        </Stack>
      </>
    )
  }

  return (
    <Flex
      justify="center"
      backgroundPosition="bottom !important"
      backgroundRepeat="no-repeat !important"
      backgroundSize="100% auto !important"
      background={backgroundURL}
    >
      <Flex
        direction="column"
        height="100vh"
        justifyContent="center"
        align="center"
        p={4}
      >
        {renderContent()}

        <Box
          as={LogoText_Negative}
          fill="gray.800"
          maxWidth="180px"
          width="20vw"
          minWidth="100px"
          pos="absolute"
          bottom={4}
          left={8}
        />
      </Flex>
    </Flex>
  )
}

export default AddOrganization
