import { Tooltip, TooltipProps } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'

export const NoAudioTooltip: React.FC<TooltipProps> = ({
  children,
  isDisabled,
}) => {
  return (
    <Tooltip
      label={t`Audio on this conversation has been removed`}
      hasArrow
      placement="top"
      isDisabled={isDisabled}
    >
      {children}
    </Tooltip>
  )
}
