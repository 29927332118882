import { RepeatCall } from '@capturi/api-filters'
import { FormControl, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

type Props = {
  onChange: (value: RepeatCall['type']) => void
  value: string
}

export const RepeatCallsRadioGroup: React.FC<Props> = ({ onChange, value }) => {
  return (
    <FormControl>
      <Text fontSize="xs" fontWeight="medium" mb={1} textTransform="uppercase">
        <Trans>Call type</Trans>
      </Text>
      <RadioGroup
        value={value}
        onChange={(e) => {
          onChange(e as RepeatCall['type'])
        }}
      >
        <VStack alignItems="flex-start" gap={1}>
          <Radio value="Causing">
            <Text>
              <Trans>Trigger calls (causing a repeat call)</Trans>
            </Text>
          </Radio>
          <Radio value="Repeat">
            <Text>
              <Trans>Repeat calls (has a prior trigger call)</Trans>
            </Text>
          </Radio>
          <Radio value="None">
            <Text>
              <Trans>
                First Call Resolution (no subsequent or prior calls)
              </Trans>
            </Text>
          </Radio>
        </VStack>
      </RadioGroup>
    </FormControl>
  )
}
