import {
  GroupBase,
  MenuProps,
  OnChangeValue,
  Select,
  createFilter,
} from '@capturi/ui-select'
import { Box } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import {
  Option,
  SearchInputGroup,
  SearchInputMenu,
} from './SearchSelectComponents'
import { SearchSelectOption } from './domain'

export const SearchSelect: React.FC<{
  selectOptions: SearchSelectOption[]
  value: SearchSelectOption[]
  isDisabled?: boolean
  isLoading: boolean
  onSelect: (items: SearchSelectOption[]) => void
}> = ({ selectOptions, value, isDisabled = false, isLoading, onSelect }) => {
  const handleOnChange = React.useCallback(
    (options: OnChangeValue<SearchSelectOption, boolean>) => {
      if (options == null) return

      if (Array.isArray(options)) {
        onSelect(options)
      } else {
        onSelect([options] as SearchSelectOption[])
      }
    },
    [onSelect],
  )

  const handleSubmit = React.useCallback(() => {
    setIsOpened(false)
  }, [])

  const handleReset = React.useCallback(() => {
    onSelect([])
    setIsOpened(false)
  }, [onSelect])

  const [isOpened, setIsOpened] = React.useState(false)

  const groupedValue: GroupBase<SearchSelectOption>[] = React.useMemo(
    () => [
      {
        options: value,
      },
      {
        options: selectOptions,
      },
    ],
    [selectOptions, value],
  )

  return (
    <Select
      options={groupedValue}
      maxMenuHeight={500}
      onChange={handleOnChange}
      placeholder={t`Add users, teams and roles`}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      filterOption={createFilter({ ignoreCase: true, ignoreAccents: false })} // ignoreAccents is off for better performance
      isClearable={false}
      isDisabled={isDisabled}
      isSearchable
      isLoading={isLoading}
      isMulti={true}
      value={value}
      onMenuOpen={() => setIsOpened(true)}
      onMenuClose={() => setIsOpened(false)}
      menuIsOpen={isOpened}
      styles={{
        group: (base) => ({
          ...base,
          paddingTop: 4,
          paddingBottom: 0,
        }),
      }}
      components={{
        Option,
        Group: SearchInputGroup,
        Menu: React.useCallback(
          (props: MenuProps<SearchSelectOption, boolean>) => (
            <SearchInputMenu
              {...props}
              onSubmit={handleSubmit}
              onReset={handleReset}
              formatSelectedCount={(count) => (
                <Box as="span">
                  {count} <Trans>chosen</Trans>
                </Box>
              )}
            />
          ),
          [handleReset, handleSubmit],
        ),
      }}
    />
  )
}
