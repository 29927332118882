import '@formatjs/intl-relativetimeformat/dist/locale-data/da'
import '@formatjs/intl-relativetimeformat/dist/locale-data/en'
import '@formatjs/intl-relativetimeformat/polyfill'

export const DEFAULT_LANGUAGE = 'da-DK'

export type LanguageOption = {
  code: string
  label: string
}

export const supportedLanguages: LanguageOption[] = [
  {
    code: 'da-DK',
    label: 'Dansk',
  },
  {
    code: 'en-US',
    label: 'English',
  },
]

export function findLanguage(language: string): LanguageOption | undefined {
  return supportedLanguages.find((x) => x.code.startsWith(language))
}

export function isSupportedLanguage(language?: string): boolean {
  if (language == null) return false
  return findLanguage(language) !== undefined
}
