import { SelectOption } from '@capturi/ui-select'
import { t } from '@lingui/macro'

export default function getMasterPreset(
  preset: SelectOption | undefined,
): string | undefined {
  switch (preset?.value) {
    case 'Private':
      return t`Private`
    case 'EveryoneCanSee':
      return t`Everyone`
    case 'Admins':
      return t`Owners and administrators`
    case 'AdminTeamLeads':
      return t`Owners, administrators and team leads`
  }
}
