import {
  MenuProps,
  OnChangeValue,
  OptionProps,
  Select,
  SelectInstance,
  SelectOption,
  components,
} from '@capturi/ui-select'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import noop from 'lodash/noop'
import React, { useContext, useRef, useState } from 'react'
import { MdAdd } from 'react-icons/md'

import { useAPI } from '../../api'
import { LibraryFolderResponseModel } from '../../types'
import { PlaylistSelectorContext } from './PlaylistSelector'

const PlaylistSelect: React.FC = () => {
  const { setIsCreateMode, setSelectState, id, defaultValue, isRequired } =
    useContext(PlaylistSelectorContext)
  const selectRef = useRef<SelectInstance<SelectOption, boolean>>(null)
  const [selectedPlaylistUid, setSelectedPlaylistUid] = useState<
    string | undefined
  >(defaultValue)

  const { data: foldersResp } = useAPI<LibraryFolderResponseModel>(
    (api) => api.getFolders(),
    {
      revalidateOnFocus: false,
    },
  )

  const options = React.useMemo(() => {
    return (foldersResp?.folders ?? [])
      .map<SelectOption>((x) => ({
        value: x.uid,
        label: x.name,
        description: x.description,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [foldersResp])

  const selectedPlaylist = React.useMemo(() => {
    return options.find((x) => x.value === selectedPlaylistUid)
  }, [options, selectedPlaylistUid])

  const handleSelectPlaylist = (
    option: OnChangeValue<SelectOption, boolean>,
  ): void => {
    if (option == null) return
    const value = (option as SelectOption).value
    setSelectedPlaylistUid(value)
    setSelectState({ uid: value })
  }

  return (
    <>
      <Select
        id={id}
        value={selectedPlaylist}
        isLoading={!foldersResp}
        options={options}
        placeholder={t`Select playlist ...`}
        onChange={handleSelectPlaylist}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        components={{
          Option,

          Menu: (
            props: MenuProps<SelectOption, boolean>,
          ): React.ReactElement => (
            <Menu {...props} onCreatePlaylist={() => setIsCreateMode(true)} />
          ),
        }}
        mRef={selectRef}
      />
      <Box position="relative">
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            width: '100%',
            // needs to have a height in order for validation popover to show in chrome
            height: '1px',
            position: 'absolute',
            top: 0,
          }}
          value={selectedPlaylistUid || ''}
          onChange={noop}
          onFocus={() => selectRef.current?.focus()}
          required={isRequired}
        />
      </Box>
    </>
  )
}

const Option: React.ComponentType<OptionProps<SelectOption, boolean>> = (
  props,
) => {
  const { label, description } = props.data
  return (
    <components.Option {...props}>
      <Box noOfLines={1} wordBreak="break-all">
        {label}
      </Box>
      <Text fontSize="xs" color="textMuted" noOfLines={1} wordBreak="break-all">
        {description}
      </Text>
    </components.Option>
  )
}

const Menu: React.ComponentType<
  {
    onCreatePlaylist: () => void
  } & MenuProps<SelectOption, boolean>
> = ({ onCreatePlaylist, ...props }) => {
  const footerHeight = 12
  return (
    <components.Menu {...props}>
      <Box pb={footerHeight} overflow="hidden">
        {props.children}
        <Flex
          as="footer"
          position="absolute"
          bottom={0}
          height={footerHeight}
          align="center"
          px={3}
          w="full"
          boxShadow="0 -4px 8px -2px rgba(0,0,0,0.1)"
        >
          <Button
            leftIcon={<MdAdd />}
            size="sm"
            variant="ghost"
            onClick={onCreatePlaylist}
          >
            <Trans>Create playlist</Trans>
          </Button>
        </Flex>
      </Box>
    </components.Menu>
  )
}

export default PlaylistSelect
