import React from 'react'

import ShadowFilter from './ShadowFilter'

const CircleWithOutline: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#shadow)">
        <circle cx="8" cy="7" r="6.5" fill={color} />
        <circle cx="8" cy="7" r="6.5" stroke="white" />
      </g>
      <ShadowFilter />
    </svg>
  )
}

const CircleNoOutline: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#shadow)">
        <circle cx="6" cy="5" r="5" fill={color} />
      </g>
      <ShadowFilter />
    </svg>
  )
}

const Circle: React.FC<{ color: string; outline: boolean }> = ({
  color,
  outline,
}) => {
  return outline ? (
    <CircleWithOutline color={color} />
  ) : (
    <CircleNoOutline color={color} />
  )
}

export default React.memo(Circle)
