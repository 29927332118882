import { GrantedLevel } from '@capturi/core'
import { Box, Divider, HStack, List, Text } from '@chakra-ui/react'
import React from 'react'

import { RoleEntry, TeamEntry, UserEntry } from './domain'
import { AclEntryRow } from './rows'

export const AclSection: React.FC<{
  title: React.ReactNode
  entries: (UserEntry | TeamEntry | RoleEntry)[]
  handleChangeGrantedLevel: (
    entry: UserEntry | TeamEntry | RoleEntry,
    level: GrantedLevel,
  ) => void
  handleRemove: (entry: UserEntry | TeamEntry | RoleEntry) => void
}> = ({ title, entries, handleChangeGrantedLevel, handleRemove }) => {
  if (entries.length === 0) return null

  return (
    <>
      <HStack alignItems="center" w="100%">
        <Text fontSize="sm" fontWeight="medium" color="gray.400">
          {title}
        </Text>
        <Box w="100%">
          <Divider w="100%" />
        </Box>
      </HStack>

      <List w="100%">
        {entries.map((entry) => {
          const key =
            entry.type === 'user'
              ? entry.userUid
              : entry.type === 'team'
                ? entry.teamUid
                : entry.role
          return (
            <AclEntryRow
              key={key}
              entry={entry}
              handleChangeGrantedLevel={handleChangeGrantedLevel}
              handleRemove={handleRemove}
            />
          )
        })}
      </List>
    </>
  )
}
