import {
  MessageFields,
  MessageFilters,
  MessagePosition,
} from '@capturi/api-trackers'
import { useFeatureFlags } from '@capturi/feature-flags'
import {
  FormLabel,
  RadioButton,
  RadioButtonGroup,
} from '@capturi/ui-components'
import {
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useEffect, useState } from 'react'
import { MdOutlineTimer } from 'react-icons/md'

type Props = {
  messageFilters: MessageFilters | null
  onMessageFiltersChange: (messageField: MessageFilters | null) => void
}

export const CaseActiveInField: React.FC<Props> = ({
  messageFilters,
  onMessageFiltersChange,
}: Props) => {
  const { isChatOrg } = useFeatureFlags()
  const [state, setState] = useState<Set<MessageFields>>(() => {
    if (isChatOrg) {
      //On chats we don't have a subject
      return new Set<MessageFields>(['Text'])
    }
    return new Set(messageFilters?.messageFields)
  })

  // When pasting tracker from clipboard, we need to re-set the state
  useEffect(() => {
    setState(new Set(messageFilters?.messageFields))
  }, [messageFilters?.messageFields])

  const handleUpdateMessageFields = (
    value: MessageFields,
    isChecked: boolean,
  ): void => {
    if (isChecked) {
      state.add(value)
    } else {
      state.delete(value)
    }
    onMessageFiltersChange(
      messageFilters?.messageFields
        ? {
            ...messageFilters,
            messageFields: [...state],
          }
        : null,
    )
  }
  return (
    <FormControl>
      <FormLabel
        htmlFor="tracker_text_messageFields"
        leftIcon={<MdOutlineTimer />}
      >
        <Trans>Active in</Trans>
      </FormLabel>
      {!isChatOrg && (
        <CheckboxGroup>
          <Stack gap={6} mb={3} direction={['column', 'row']}>
            <Checkbox
              isChecked={state.has('Subject')}
              onChange={(e) => {
                handleUpdateMessageFields('Subject', e.target.checked)
              }}
            >
              <Flex alignItems="center">
                <Text>
                  <Trans>E-mail subject</Trans>
                </Text>
              </Flex>
            </Checkbox>
            <Checkbox
              isChecked={state.has('Text')}
              onChange={(e) => {
                handleUpdateMessageFields('Text', e.target.checked)
              }}
            >
              <Flex alignItems="center">
                <Text>
                  <Trans>E-mail body text </Trans>
                </Text>
              </Flex>
            </Checkbox>
          </Stack>
        </CheckboxGroup>
      )}
      <RadioButtonGroup
        id="tracker_message-filter"
        value={messageFilters?.messagePosition}
        onChange={(v: MessagePosition) => {
          onMessageFiltersChange(
            messageFilters?.messageFields
              ? {
                  ...messageFilters,
                  messagePosition: v,
                }
              : null,
          )
        }}
      >
        <RadioButton value={MessagePosition.Any}>
          <Trans>All messages</Trans>
        </RadioButton>
        <RadioButton value={MessagePosition.First}>
          <Trans>First message</Trans>
        </RadioButton>
        <RadioButton value={MessagePosition.Last}>
          <Trans>Last message</Trans>
        </RadioButton>
      </RadioButtonGroup>
    </FormControl>
  )
}
