import { Button, RadioButton, RadioButtonGroup } from '@capturi/ui-components'
import { Box, FormControl, FormLabel, HStack, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { useEffectOnce } from 'react-use'
import { FilterCriteriaComponentBaseProps } from '../../components/PhoneFilter'

export type QualityAssuranceProps = FilterCriteriaComponentBaseProps<boolean>

export const QualityAssurance: React.FC<
  FilterCriteriaComponentBaseProps<boolean>
> = (props) => {
  useEffectOnce(() => {
    if (props.value === null) props.setValue?.(true)
  })

  return (
    <FormControl>
      <Box p={2}>
        <FormLabel>
          <Trans>Reviewed</Trans>
        </FormLabel>
        <RadioButtonGroup
          value={`${props.value}`}
          onChange={(e) => {
            if (e === 'true') {
              props.setValue?.(true)
            } else {
              props.setValue?.(false)
            }
          }}
        >
          <RadioButton value="true">
            <Trans>Reviewed</Trans>
          </RadioButton>
          <RadioButton value="false">
            <Trans>Not reviewed</Trans>
          </RadioButton>
        </RadioButtonGroup>
      </Box>
      <HStack
        pt={3}
        mb={3}
        mt={4}
        justify="flex-end"
        boxShadow="0px -4px 8px -6px rgba(0, 0, 0, 0.20)"
      >
        <Button
          onClick={() => props.onClose?.()}
          size="xs"
          primary
          variant="ghost"
        >
          <Text>
            <Trans>OK</Trans>
          </Text>
        </Button>
      </HStack>
    </FormControl>
  )
}
