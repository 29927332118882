export const basePath = '/settings/organization'

export const organisationPageViews = {
  users: 'users',
  agents: 'agents',
  teams: 'teams',
  api: 'api',
  'deletion-policy': 'deletion-policy',
  repeatCalls: 'repeat-calls',
  'audit-log': 'audit-log',
  'customer-data': 'customer-data',
  importer: 'importer',
} as const

export type OrganisationPageView =
  (typeof organisationPageViews)[keyof typeof organisationPageViews]

const routes = {
  root: basePath,
  tab: (subPath: OrganisationPageView): string => {
    return `${basePath}/${subPath}`
  },
}

export default routes
