import { i18n } from '@lingui/core'
import { plural, t } from '@lingui/macro'
import { isSameDay, isValid } from 'date-fns'

import { NamedPeriod } from './constants'
import {
  FixedDateTimePeriodDefinition,
  FixedPeriodDefinition,
  PeriodDefinition,
} from './period'

const DateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

const DateRangeSeparator = ' - '

export const formatDate = (d: Date): string => {
  if (isValid(d)) {
    return i18n.date(d, DateFormatOptions)
  }
  return ''
}

export const formatDateRange = (from: Date, to: Date): string => {
  if (isSameDay(from, to)) {
    return formatDate(from)
  }
  return formatDate(from) + DateRangeSeparator + formatDate(to)
}

export const formatCustomPeriod = (def: PeriodDefinition): string => {
  const period = def.create(new Date())
  return formatDateRange(period.from, period.to)
}

export const formatNamedPeriod = (definition: PeriodDefinition): string => {
  switch (definition.name) {
    case NamedPeriod.Today:
      return t`Today`
    case NamedPeriod.Yesterday:
      return t`Yesterday`
    case NamedPeriod.Last12Months:
      return t`Last 12 months`
    case NamedPeriod.Last3Months:
      return t`Last 3 months`
    case NamedPeriod.CurrentWeek:
      return t`Current week`
    case NamedPeriod.CurrentMonth:
      return t`Current month`
    case NamedPeriod.CurrentQuarter:
      return t`Current quarter`
    case NamedPeriod.LastQuarter:
      return t`Previous quarter`
    case NamedPeriod.CurrentYear:
      return t`Current year`
    case NamedPeriod.LastWeek:
      return t`Previous week`
    case NamedPeriod.LastMonth:
      return t`Previous month`
    default: {
      const period = definition.create(new Date())
      const hours = period.hours()
      const days = period.days()
      if (hours <= 24) {
        return plural(hours, {
          one: 'Last hour',
          other: 'Last # hours',
        })
      }
      return t`Last ${days} days`
    }
  }
}

export const formatPeriod = (definition: PeriodDefinition): string => {
  if (
    definition instanceof FixedPeriodDefinition ||
    definition instanceof FixedDateTimePeriodDefinition
  ) {
    return formatCustomPeriod(definition)
  }
  return formatNamedPeriod(definition)
}
