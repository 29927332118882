import React from 'react'

export const GradientDef: React.FC<{ hexColor: string; id: string }> = ({
  hexColor,
  id,
}) => {
  return (
    <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor={hexColor} stopOpacity={0.8} />
      <stop offset="40%" stopColor={hexColor} stopOpacity={0.2} />
      <stop offset="50%" stopColor={hexColor} stopOpacity={0.02} />
      <stop offset="60%" stopColor={hexColor} stopOpacity={0} />
    </linearGradient>
  )
}

export const GradientLine: React.FC<{
  hexColor: string
  id: string
  lightHexColor: string
}> = ({ hexColor, id, lightHexColor }) => {
  return (
    <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
      <stop offset="20%" stopColor={lightHexColor} stopOpacity={1} />
      <stop offset="90%" stopColor={hexColor} stopOpacity={1} />
    </linearGradient>
  )
}

export const BackgroundGradient: React.FC<{ id: string }> = ({ id }) => {
  return (
    <linearGradient id={id} x1="0%" y1="0%" gradientTransform="rotate(90)">
      <stop offset="0%" stopColor="#00000000" />
      <stop offset="100%" stopColor="#0000000f" />
    </linearGradient>
  )
}
