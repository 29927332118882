import {
  ConversationResponseModel,
  IgnoredTrackerPhrase,
  WordSearch,
} from '@capturi/api-conversations'
import {
  BaseTracker,
  PhraseSettings,
  isTrackerPrivate,
  useTrackerByUid,
  useTrackers,
} from '@capturi/api-trackers'
import { Speaker } from '@capturi/core'
import { Color, Colors, Shape, Shapes } from '@capturi/sharing'
import React, { useMemo } from 'react'

export interface Result {
  words: string[]
  timestamp: number
  speakerId: Speaker
  speakerUserUid?: string
  trackerName?: string
  trackerUid?: string
  trackerIsPrivate?: boolean
  phrasesSettings?: Record<string, PhraseSettings> | null
  nearnessObject?: PhraseSettings
}

export interface TrackerTableContentsResult {
  isLoading: boolean
  hasNoResults: boolean
  results: Result[]
  extraResults: Result[]
  groupedTrackerHits: Map<string, Result[]>
  trackersShapeColors?: Record<string, ShapeColor>
}

type ConversationTrackerHits = ConversationResponseModel['trackerHits']

type UseTrackerTableContents = (
  conversationTrackerHits: ConversationTrackerHits | undefined,
  selectedTrackerUid: string | null,
  ignoredPhrases: IgnoredTrackerPhrase[] | undefined,
) => TrackerTableContentsResult

type ShapeColor = {
  shape: Shape
  color: Color
}

type UseGroupedTrackerHits = (
  conversationTrackerHits: ConversationTrackerHits | undefined,
  ignoredPhrases: IgnoredTrackerPhrase[] | undefined,
  trackers: BaseTracker[] | undefined,
) => Map<string, Result[]>

const useGroupedTrackerHits: UseGroupedTrackerHits = (
  conversationTrackerHits = [],
  ignoredPhrases = [],
  trackers = [],
): Map<string, Result[]> => {
  const trackersByUid = useTrackerByUid(trackers)
  return useMemo(
    () =>
      (conversationTrackerHits ?? []).reduce<Map<string, Result[]>>(
        (resultMap, trackerHit) => {
          const tracker = trackersByUid?.[trackerHit.uid]
          const map = trackerHit.words.reduce<Map<string, Result>>(
            (acc, wordHit) => {
              const { word, timeline } = wordHit
              const isIgnored = ignoredPhrases.some(
                (ignoredPhrase) => ignoredPhrase.phrase === word,
              )
              timeline.forEach((timelineHit) => {
                const { when, speakerUserUid, speakerId } = timelineHit
                const key = `${when}-${speakerId}-${isIgnored}`
                const tempRes: Result = acc.get(key) ?? {
                  words: [],
                  timestamp: when,
                  speakerId: speakerId,
                  speakerUserUid: speakerUserUid,
                  trackerName: tracker?.name,
                  trackerUid: trackerHit.uid,
                  trackerIsPrivate: tracker && isTrackerPrivate(tracker),
                  phrasesSettings: tracker?.speech?.phrasesSettings,
                }

                tempRes.words.push(word)
                acc.set(key, tempRes)
              })

              return acc
            },
            new Map<string, Result>(),
          )

          resultMap.set(trackerHit.uid, Array.from(map.values()))
          return resultMap
        },
        new Map<string, Result[]>(),
      ),
    [conversationTrackerHits, ignoredPhrases, trackersByUid],
  )
}

type TrackerHitsListResult = {
  selectedResults: Result[]
  extraResults: Result[]
}

function generateTrackerHitsList(
  conversationTrackerHits: NonNullable<ConversationTrackerHits>,
  groupedTrackerHits: Map<string, Result[]>,
  selectedTrackerUid: string | null,
): TrackerHitsListResult {
  const allResults = conversationTrackerHits
    .flatMap((trackerHit) => {
      return groupedTrackerHits.get(trackerHit.uid) ?? []
    })
    .sort((a, b) => a.timestamp - b.timestamp)

  if (selectedTrackerUid == null) {
    return {
      selectedResults: allResults,
      extraResults: [],
    }
  }

  const selectedResults = [] as Result[]
  const extraResults = [] as Result[]
  allResults.forEach((result) => {
    result.trackerUid === selectedTrackerUid
      ? selectedResults.push(result)
      : extraResults.push(result)
  })
  return { selectedResults, extraResults }
}

const useTrackerTableContents: UseTrackerTableContents = (
  conversationTrackerHits,
  selectedTrackerUid,
  ignoredPhrases,
): TrackerTableContentsResult => {
  const { data: trackers } = useTrackers()
  const groupedTrackerHits = useGroupedTrackerHits(
    conversationTrackerHits,
    ignoredPhrases,
    trackers,
  )

  const { selectedResults, extraResults } = React.useMemo(
    () =>
      generateTrackerHitsList(
        conversationTrackerHits ?? [],
        groupedTrackerHits,
        selectedTrackerUid,
      ),
    [conversationTrackerHits, groupedTrackerHits, selectedTrackerUid],
  )
  return React.useMemo<TrackerTableContentsResult>(() => {
    const trackersShapeColors = conversationTrackerHits?.reduce<
      Record<string, ShapeColor>
    >((memo, trackerHit, index) => {
      memo[trackerHit.uid] = {
        color: Colors[index % Colors.length],
        shape: Shapes[Math.floor(index / Colors.length) % Shapes.length],
      } as ShapeColor
      return memo
    }, {})

    return {
      isLoading: false,
      hasNoResults: selectedResults.length === 0,
      results: selectedResults,
      extraResults: extraResults,
      groupedTrackerHits,
      trackersShapeColors,
    }
  }, [
    conversationTrackerHits,
    selectedResults,
    extraResults,
    groupedTrackerHits,
  ])
}

type UseTrackerTableSearchContents = (
  conversationTrackerHits: ConversationTrackerHits | undefined,
  wordSearchResult: WordSearch,
  ignoredPhrases: IgnoredTrackerPhrase[] | undefined,
) => TrackerTableContentsResult

export const useTrackerTableSearchContents: UseTrackerTableSearchContents = (
  conversationTrackerHits,
  wordSearchResult,
  ignoredPhrases,
): TrackerTableContentsResult => {
  const { data: trackers } = useTrackers()
  const groupedTrackerHits = useGroupedTrackerHits(
    conversationTrackerHits,
    ignoredPhrases,
    trackers,
  )

  return React.useMemo<TrackerTableContentsResult>(() => {
    const trackersShapeColors = conversationTrackerHits?.reduce<
      Record<string, ShapeColor>
    >((memo, trackerHit, index) => {
      memo[trackerHit.uid] = {
        color: Colors[index % Colors.length],
        shape: Shapes[Math.floor(index / Colors.length) % Shapes.length],
      } as ShapeColor
      return memo
    }, {})

    return {
      isLoading: wordSearchResult.isSearching,
      hasNoResults: wordSearchResult.hasNoResults,
      results: wordSearchResult.results,
      extraResults: [],
      groupedTrackerHits,
      trackersShapeColors,
    }
  }, [
    conversationTrackerHits,
    groupedTrackerHits,
    wordSearchResult.isSearching,
    wordSearchResult.hasNoResults,
    wordSearchResult.results,
  ])
}

export default useTrackerTableContents
