import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Skeleton,
  VStack,
} from '@chakra-ui/react'
import React from 'react'

const MessagesSkeleton: React.FC = () => {
  return (
    <Box
      mb={2}
      p={2}
      mt={8}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      h="100%"
    >
      <HStack alignItems="stretch" h="100%">
        <Card w="75%" h="100%">
          <Box
            py={6}
            px={8}
            alignItems="center"
            borderBottom="1px solid"
            borderColor="gray.200"
          >
            <HStack w="100%" gap="60px" h={8} justify="flex-end">
              <Skeleton h={5} w="85px" />
              <Skeleton h={5} w="120px" />
              <Skeleton h={5} w="120px" />
              <HStack gap={4} h={5}>
                <Skeleton h={5} w={6} />
                <Skeleton h={5} w={6} />
              </HStack>
            </HStack>
          </Box>
          <Flex
            px={3}
            py={3}
            flexDirection="column"
            bg="accents.lightBackground.default"
            h="100%"
          >
            <Flex flexDirection="column" align={'flex-start'}>
              <Box
                w="80%"
                maxW="80%"
                minW="80%"
                backgroundColor="gray.100"
                mb={6}
                borderRadius="16px"
              >
                <Card backgroundColor="gray.100">
                  <CardHeader pb={0} />
                  <CardBody minH={40} />
                </Card>
              </Box>
            </Flex>
          </Flex>
        </Card>
        <VStack px={6} mt={5} spacing={4} w="230px">
          <Skeleton height={5} width="100%" />
          <Skeleton height={5} width="100%" />
          <Skeleton height={5} width="100%" />
        </VStack>
      </HStack>
    </Box>
  )
}

export default MessagesSkeleton
