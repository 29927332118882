import {
  differenceInDays,
  differenceInHours,
  differenceInSeconds,
  endOfDay,
  isEqual,
  isSameDay,
  isValid,
  parseISO,
  startOfDay,
} from 'date-fns'
import { PeriodDefinitions } from './constants'
import { periodDefinitionToNamedPeriod } from './useFilterPeriod'

export class Period {
  from: Date
  to: Date

  constructor(from: Date, to: Date) {
    this.from = from
    this.to = to
  }

  days(): number {
    const diff = differenceInDays(this.to, this.from)
    if (isEqual(endOfDay(this.to), this.to)) {
      return diff + 1
    }
    return diff
  }

  hours(): number {
    return differenceInHours(this.to, this.from)
  }

  seconds(): number {
    return differenceInSeconds(this.to, this.from)
  }
}

export class PeriodDefinition {
  name: string
  getStartDate: (referenceDate: Date) => Date
  getEndDate: (referenceDate: Date, startDate: Date) => Date

  constructor(
    name: string,
    getStartDate: (referenceDate: Date) => Date,
    getEndDate: (referenceDate: Date, startDate: Date) => Date,
  ) {
    this.name = name
    this.getStartDate = getStartDate
    this.getEndDate = getEndDate
  }

  create(referenceDate: Date): Period {
    const startDate = this.getStartDate(referenceDate)
    const endDate = this.getEndDate(referenceDate, startDate)
    return new Period(startDate, endDate)
  }
}

export class FixedPeriodDefinition extends PeriodDefinition {
  constructor(from: Date, to: Date) {
    if (!isValid(from) || !isValid(to)) {
      throw new Error('Invalid dates provided.')
    }
    super(
      stringifyFixedPeriod(from, to),
      () => startOfDay(from),
      () => endOfDay(to),
    )
  }
}

export class FixedDateTimePeriodDefinition extends PeriodDefinition {
  constructor(from: Date, to: Date) {
    if (!isValid(from) || !isValid(to)) {
      throw new Error('Invalid dates provided.')
    }
    super(
      stringifyFixedDateTimePeriod(from, to),
      () => from,
      () => to,
    )
  }
}

export const stringifyFixedPeriod = (from: Date, to: Date): string => {
  return `${startOfDay(from).toISOString()}::${endOfDay(to).toISOString()}`
}

export const stringifyFixedDateTimePeriod = (from: Date, to: Date): string => {
  return `${from.toISOString()}::${to.toISOString()}`
}

export const parseFixedPeriod = (
  str: string,
): FixedPeriodDefinition | undefined => {
  const parsedPeriod = parseURLPeriod(str)
  if (parsedPeriod) {
    return new FixedPeriodDefinition(parsedPeriod[0], parsedPeriod[1])
  }
  return undefined
}

export const parseFixedDateTimePeriod = (
  str: string,
): FixedPeriodDefinition | undefined => {
  const parsedPeriod = parseURLPeriod(str)
  if (parsedPeriod) {
    return new FixedDateTimePeriodDefinition(parsedPeriod[0], parsedPeriod[1])
  }
  return undefined
}

export const parseURLPeriod = (str: string): [Date, Date] | undefined => {
  const splittedPeriod = str.split('::')
  if (splittedPeriod.length < 2) {
    return undefined
  }
  const [isoFrom, isoTo] = splittedPeriod

  const from = parseISO(isoFrom)
  const to = parseISO(isoTo)
  if (isValid(from) && isValid(to)) {
    return [from, to]
  }
  return undefined
}

export const apiPeriodToPeriodDefinition = (
  fromInclusive: Date,
  toInclusive: Date,
): PeriodDefinition => {
  if (isSameDay(fromInclusive, toInclusive)) {
    const periodDef = new FixedDateTimePeriodDefinition(
      fromInclusive,
      toInclusive,
    )
    return periodDef
  }

  const periodDef = new FixedPeriodDefinition(fromInclusive, toInclusive)
  const namedPeriod = periodDefinitionToNamedPeriod(fromInclusive, toInclusive)
  return namedPeriod ? PeriodDefinitions[namedPeriod] : periodDef
}

export const periodToPeriodDefinition = (period: Period): PeriodDefinition => {
  const { from, to } = period

  if (isSameDay(from, to)) {
    return new FixedDateTimePeriodDefinition(from, to)
  }
  return new FixedPeriodDefinition(from, to)
}
