import { Tracker } from '@capturi/api-conversations'
import { createExpandedFoldersStore } from '@capturi/stores'
import {
  Circle,
  Collapse,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { MdChevronRight } from 'react-icons/md'
import { useStore } from 'zustand'

import TrackerMetadata from '../TrackerMetadata'
import TrackerItem from './TrackerItem'
import { ListItems } from './domain'

const expandedFolders = createExpandedFoldersStore('expanded-trackers-folders')

const Folder: React.FC<{
  item: ListItems
  hitMap: Map<string, TrackerMetadata>
  onSelectTracker: (tracker: Tracker) => void
  selectedTrackerUid: string | null
}> = ({ item, hitMap, onSelectTracker, selectedTrackerUid }) => {
  const expandedFoldersStore = useStore(expandedFolders)
  const isExpanded = expandedFoldersStore.has(item.uid)
  const selectedTrackerIsInFolder =
    selectedTrackerUid &&
    item.trackers.some((item) => item.tracker?.uid === selectedTrackerUid)

  const handleFolderClick = React.useCallback(() => {
    expandedFoldersStore.toggle(item.uid)
  }, [expandedFoldersStore, item.uid])
  return (
    <Stack key={item.uid} spacing={2}>
      <Flex
        alignItems="center"
        color={isExpanded ? 'gray.800' : 'gray.600'}
        cursor={'pointer'}
        justifyContent="space-between"
        gap={1}
        onClick={handleFolderClick}
        pr={2}
        py={2}
      >
        <HStack>
          <Icon
            as={MdChevronRight}
            transform={isExpanded ? 'rotate(90deg)' : 'rotate(0deg)'}
            transition="all 200ms ease-out"
          />
          <Text wordBreak="break-word">
            {item.title} ({item.hitCount})
          </Text>
        </HStack>
        {selectedTrackerIsInFolder && (
          <Circle mx="8px" background="primary.500" size="8px" />
        )}
      </Flex>
      <Collapse in={isExpanded}>
        <Stack spacing={4} pl={4} pb={4}>
          {item.trackers.map((item) => {
            const trackerHit = item.trackerHit
            const tracker = item.tracker
            return (
              <TrackerItem
                key={trackerHit.uid}
                trackerHit={trackerHit}
                tracker={tracker}
                hitMap={hitMap}
                onSelectTracker={onSelectTracker}
                selectedTrackerUid={selectedTrackerUid}
              />
            )
          })}
        </Stack>
      </Collapse>
    </Stack>
  )
}

export default Folder
